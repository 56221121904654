import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearServices, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setServiceSearchText, setServiceSelectedPageSize, setServiceSelectedOrderBy,
  setServiceTappedId, addEditServiceAsync, downloadDeleteServiceAsync, downloadServicesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_SERVICES, PAGE_MODE_TABLE, RXFORM_SERVICE,
  MENUID_AUTHENTICATION_SERVICE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import ServicePage from './service.presentation';

const getInitialValues = (state) => {
  const { services, uiFunctionalPage, uiService } = state;
  const { data } = services;
  const { downloadingDeleting, tappedId } = uiService;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found)
    : { name: '', description: '' };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiService.addingEditing,
  downloading: state.uiService.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_SERVICE));
    dispatch(setServiceSearchText(''));
    dispatch(clearServices());
    dispatch(setServiceSelectedPageSize(20));
    dispatch(setServiceSelectedOrderBy(INITIAL_ORDER_BY_SERVICES));
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadServicesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setServiceSelectedPageSize(pageSize));
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SERVICE));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteServiceAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setServiceTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setServiceTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setServiceTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setServiceSelectedPageSize(pageSize));
    dispatch(clearServices());
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditServiceAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setServiceSearchText(text));
      dispatch(clearServices());
      await dispatch(downloadServicesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setServiceSelectedOrderBy(orderBy));
    dispatch(clearServices());
    dispatch(downloadServicesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditServiceAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setServiceTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicePage);
