import {
  downloadingSummaryAppVersionDistribution,
  setSummaryAppVersionDistribution,
} from '../simple-action';
import { downloadSummaryAppVersionDistribution } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryAppVersionDistribution(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryAppVersionDistribution(token);

    dispatch(setSummaryAppVersionDistribution(result));
  } finally {
    dispatch(downloadingSummaryAppVersionDistribution(false));
  }
};
