import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, withStyles,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  COLOR_DANGEROUS, COLOR_PRIMARY,
} from '../../constant';
import { AccentButton } from '../../../../component';
import CodeEditor from '../../../../component/code-editor';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  headerContainerColumn: {
    flexDirection: 'column',
    display: 'flex',
    alignContent: 'center',
  },
  headerButtonText: {
    color: COLOR_DANGEROUS,
    marginRight: 20,
  },
  headerButtonTextBlue: {
    color: COLOR_PRIMARY,
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: '24px',
      color: COLOR_PRIMARY,
    },
    padding: '16px 30px 8px 30px',
  },
  copyContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 5px',
    alignContent: 'center',
  },
  iconCopy: {
    color: COLOR_PRIMARY,
  },
}));

const CodeEditorDialog = ({
  code, classes, usefullWidthDialog, onClosePressed,
  onCopyPressed,
  visibility,
}) => (
  <Dialog
    open={visibility}
    aria-labelledby="form-dialog-title"
    maxWidth="lg"
    fullWidth={usefullWidthDialog}
    classes={{ paper: classes.paper }}
  >
    <div className={classes.headerContainer}>
      <DialogTitle className={classes.title}>
        {
              LocalizedString.annotationDesignerPage.labelJSON
          }
      </DialogTitle>
      <div className={classes.headerContainerColumn}>
        <div className={classes.copyContainer}>
          <AccentButton
            onClick={() => onClosePressed()}
            variant="text"
            caption={GlobalLocalizedString.common.buttonCaptionCancel}
            className={classes.headerButtonText}
            size="small"
          />
        </div>

        <AccentButton
          onClick={() => onCopyPressed(code)}
          variant="text"
          caption={GlobalLocalizedString.common.buttonCaptionCopy}
          className={classes.headerButtonTextBlue}
          size="small"
          endIcon={<FileCopyOutlined className={classes.iconCopy} />}
        />
      </div>
    </div>

    <DialogContent>
      <CodeEditor
        label=""
        value={code}
        readOnly
        language="json"
      />
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(CodeEditorDialog);

CodeEditorDialog.propTypes = {
  code: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,

  usefullWidthDialog: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,

  onClosePressed: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
};

CodeEditorDialog.defaultProps = {
  usefullWidthDialog: false,
};
