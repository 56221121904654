import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    auditManagementMenu: {
      title: 'Audit',
    },
    auditTrailPage: {
      title: 'Audit Trail',

      labelNo: 'No',
      labelOperation: 'Operation',
      labelServiceName: 'Service Name',
      labelEntityName: 'Entity Name',
      labelEntityId: 'Entity ID',
      labelUser: 'User',
      labelEventTime: 'Event Time',
      labelColumnName: 'Column Name',
      labelOldValue: 'Old Value',
      labelNewValue: 'New Value',
      labelDateTimeTableValues: '* The date time values shown on this table are raw values from database. They are displayed as-is (not converted to client\'s timezone)',
      labelCopied: 'Copied!',

      placeholderOperation: 'Operation',
      placeholderServiceName: 'Service Name',
      placeholderEntityName: 'Entity Name',
      placeholderEntityId: 'Entity ID',
      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderEventTime: 'Event Time',
      placeholderGroupID: 'Group ID',
      placeholderModified: 'Show only modified values',
    },
  },
  id: {
    auditManagementMenu: {
      title: 'Audit',
    },
    auditTrailPage: {
      title: 'Jejak Audit',

      labelNo: 'No',
      labelOperation: 'Operasi',
      labelServiceName: 'Nama Layanan',
      labelEntityName: 'Nama Entitas',
      labelEntityId: 'ID Entitas',
      labelUser: 'Pengguna',
      labelEventTime: 'Waktu Acara',
      labelColumnName: 'Nama Kolom',
      labelOldValue: 'Nilai Lama',
      labelNewValue: 'Nilai Baru',
      labelDateTimeTableValues: '* Tanggal dan waktu yang ditampilkan pada table adalah nilai mentah dari basis data. Semua ditampilkan begitu saja (tanpa dikonversi ke zona waktu klien)',
      labelCopied: 'Disalin!',

      placeholderOperation: 'Operasi',
      placeholderServiceName: 'Nama Layanan',
      placeholderEntityName: 'Nama Entitas',
      placeholderEntityId: 'ID Entitas',
      placeholderUser: 'Pengguna',
      placeholderEventTime: 'Waktu Acara',
      placeholderGroupID: 'ID Grup',
      placeholderModified: 'Hanya perlihatkan nilai yang diubah',
    },
  },
});

export default LocalizedString;
