import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles, Stepper, Step, StepConnector, StepLabel, withStyles,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { COLOR_BACKGROUND, COLOR_ICON_LIGHTER, COLOR_PRIMARY } from '../../constant';
import LocalizedString from '../../localization';
import AccentButton from '../../../../component/accent-button';

const Connector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: COLOR_PRIMARY,
    },
  },
  completed: {
    '& $line': {
      borderColor: COLOR_PRIMARY,
    },
  },
  line: {
    borderColor: COLOR_ICON_LIGHTER,
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  instructions: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '70vh',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  root: {
    color: COLOR_ICON_LIGHTER,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: COLOR_PRIMARY,
  },
  circle: {
    backgroundColor: COLOR_PRIMARY,
    boxShadow: '0px 4px 20px rgba(49, 135, 96, 0.45)',
    zIndex: 1,
    color: COLOR_BACKGROUND,
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CustomizedStepIcon = ({ active, completed, icon }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <div className={classes.circle}><Check /></div>
        : <div className={classes.circle}>{icon}</div>}
    </div>
  );
};

CustomizedStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.number.isRequired,
};

const FormStepper = ({
  stepContent, stepTitle, addButton, handleSubmit,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return stepContent[0];
      case 1:
        return stepContent[1];
      case 2:
        return stepContent[2];
      default:
        return stepContent[0];
    }
  };

  const onNextPress = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onBackPress = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.container}>
      <Stepper activeStep={activeStep} connector={<Connector />}>
        {stepTitle.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomizedStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={classes.instructions}>{getStepContent(activeStep)}</div>

      <div className={classes.buttonContainer}>
        <AccentButton
          disabled={activeStep === 0}
          onClick={onBackPress}
          caption={LocalizedString.common.buttonCaptionPrev}
        />

        {activeStep < stepTitle.length - 1 ? (
          <AccentButton
            onClick={handleSubmit(onNextPress)}
            caption={LocalizedString.common.buttonCaptionNext}
          />
        ) : addButton}
      </div>
    </div>
  );
};

export default FormStepper;

FormStepper.propTypes = {
  stepContent: PropTypes.arrayOf(PropTypes.node).isRequired,
  stepTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  addButton: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
};

FormStepper.defaultProps = {
  addButton: (<div />),
};
