import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearApplications, clearPermissions, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setApplicationSearchText, setApplicationSelectedPageSize,
  setApplicationSelectedPermissionName, setApplicationSelectedOrderBy, setApplicationTappedId,
  setPermissionSearchText,
  addEditApplicationAsync, downloadDeleteApplicationAsync, downloadApplicationsAsync,
  downloadPermissionsAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_APPLICATIONS, PAGE_MODE_TABLE,
  RXFORM_APPLICATION, RXFIELD_APPLICATION_PERMISSION,
  RXFIELD_APPLICATION_ICON, MENUID_PERSONALIZATION_APPLICATION,
} from '../../constant';
import { transformInitialValues, toNumber } from '../../helper';
import LocalizedString from '../../localization';
import CityPage from './application.presentation';

const getInitialValues = (state) => {
  const {
    applications, uiApplication, uiFunctionalPage,
  } = state;
  const { downloadingDeleting, tappedId } = uiApplication;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? applications.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      order: found.order.toString(),
      icon: found.iconPath,
    }) : {
      name: '',
      description: '',
      url: '',
      androidAppId: '',
      iosAppId: '',
      downloadUrl: '',
      order: '',
      permission: '',
      icon: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  permissions: Object.values(state.permissions.data).map((item) => item.name),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiApplication.addingEditing,
  downloading: state.uiApplication.downloading,
  loadingPermission: state.uiPermission.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PERSONALIZATION_APPLICATION));
    dispatch(setApplicationSearchText(''));
    dispatch(clearApplications());
    dispatch(setApplicationSelectedPageSize(20));
    dispatch(setApplicationSelectedOrderBy(INITIAL_ORDER_BY_APPLICATIONS));
    dispatch(downloadApplicationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadApplicationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setApplicationSelectedPageSize(pageSize));
    dispatch(downloadApplicationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePermissionText: async (text) => {
    try {
      dispatch(setPermissionSearchText(text));
      dispatch(clearPermissions());
      await dispatch(downloadPermissionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_APPLICATION));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteApplicationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setApplicationTappedId(''));
    dispatch(setPermissionSearchText(''));
    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setApplicationTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setPermissionSearchText(''));
    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setApplicationTappedId(id));
  },
  onIconImageSelected: (image) => {
    dispatch(change(RXFORM_APPLICATION, RXFIELD_APPLICATION_ICON, image));
  },
  onPermissionOptionSelected: (option) => {
    if (option) {
      dispatch(setApplicationSelectedPermissionName(option));
      dispatch(change(RXFORM_APPLICATION, RXFIELD_APPLICATION_PERMISSION, option));
    } else {
      dispatch(setApplicationSelectedPermissionName(''));
      dispatch(change(RXFORM_APPLICATION, RXFIELD_APPLICATION_PERMISSION, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setApplicationSelectedPageSize(pageSize));
    dispatch(clearApplications());
    dispatch(downloadApplicationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description, url, androidAppId, iosAppId, downloadUrl, order, permission,
    minAppVersion, maxAppVersion, icon,
  }) => {
    try {
      await dispatch(addEditApplicationAsync(name, description, url, androidAppId, iosAppId,
        downloadUrl, permission, toNumber(order), minAppVersion, maxAppVersion, icon));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setApplicationSearchText(text));
      dispatch(clearApplications());
      await dispatch(downloadApplicationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setApplicationSelectedOrderBy(orderBy));
    dispatch(clearApplications());
    dispatch(downloadApplicationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description, url, androidAppId, iosAppId, downloadUrl, order, permission,
    minAppVersion, maxAppVersion, icon,
  }) => {
    await dispatch(addEditApplicationAsync(name, description, url, androidAppId, iosAppId,
      downloadUrl, permission, toNumber(order), minAppVersion, maxAppVersion, icon));
  },
  onViewPressed: (id) => {
    dispatch(setApplicationTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CityPage);
