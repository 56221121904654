import { connect } from 'react-redux';
import moment from 'moment';
import {
  INITIAL_ORDER_BY_QUIZ_RESPONSES, MENUID_QUIZ_QUIZ_RESPONSE, PAGE_MODE_TABLE,
  DATE_TIME_FORMAT, MINUTE_SECOND_FORMAT, RXFIELD_QUIZ_RESPONSE_QUIZ,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  transformDropdownData, transformInitialValues, sortAsc, toMoment, debounceSearch,
} from '../../helper';
import {
  clearQuizResponses, clearProfiles, setActiveSideMenuItem, addFunctionalPageAdvancedFilterForm,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setQuizResponseSearchText, setQuizResponseSelectedOrderBy,
  setQuizResponseSelectedPageSize, setQuizResponseTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  downloadDeleteQuizResponseAsync, downloadQuizResponsesAsync,
  downloadQuizesAsync, clearQuizes, setQuizSearchText,
  setQuizAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import QuizResponsePage from './quiz-response.presentation';

const getInitialValues = (state) => {
  const {
    quizResponses, uiFunctionalPage, uiQuizResponse,
  } = state;
  const { data } = quizResponses;
  const { downloadingDetail, tappedId } = uiQuizResponse;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDetail
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      quizId: found.quiz?.id || '',
      userId: found.user?.id || '',
      quizTitle: found.quiz?.title || '',
      userName: found.user?.fullName || '',
      score: found.score || '',
      startedAt: found.startedAt ? toMoment(found.startedAt).format(DATE_TIME_FORMAT) : '',
      finishedAt: found.finishedAt ? toMoment(found.finishedAt).format(DATE_TIME_FORMAT) : '',
      duration: found.startedAt && found.finishedAt
        ? toMoment(moment(found.finishedAt).diff(moment(found.startedAt)))
          .format(MINUTE_SECOND_FORMAT).concat(` ${LocalizedString.quizResponseScreen.labelSeconds}`)
          .replace(':', ` ${LocalizedString.quizResponseScreen.labelMinutes} `) : '',
      answers: found.answers.length ? found.answers
        .sort((a, b) => sortAsc(a.question.order, b.question.order)) : [],
    }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  users: transformDropdownData(state.profiles.data),
  downloading: state.uiQuizResponse.downloading,
  loadingUser: state.uiProfile.downloading,
  dialogVisibility: state.uiFunctionalPage.visibility,
  initialValues: getInitialValues(state),
  quizes: transformDropdownData(state.quizes.data),
  loadingQuizes: state.uiQuiz.downloading,
});

const searchUserNameDebounce = debounceSearch(async (dispatch) => {
  dispatch(clearProfiles());
  try {
    await dispatch(downloadProfilesAsync(1));
  } catch (error) {
    dispatch(setAlertErrorMessage(error));
  }
});

const searchQuizDebounce = debounceSearch(async (dispatch) => {
  dispatch(clearProfiles());
  try {
    dispatch(clearQuizes());
    await dispatch(downloadQuizesAsync(1));
  } catch (error) {
    dispatch(setAlertErrorMessage(error));
  }
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(''));
    searchUserNameDebounce(dispatch);

    dispatch(setQuizAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setQuizSearchText(''));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: (navigationParams) => {
    if (navigationParams) {
      const { label, value } = navigationParams.selectedQuiz;
      dispatch(addFunctionalPageAdvancedFilterForm(RXFIELD_QUIZ_RESPONSE_QUIZ, { label, value }));
      dispatch(setAdvancedFilterDialogSelectedFilterString(navigationParams.filterString));
    }
    dispatch(setActiveSideMenuItem(MENUID_QUIZ_QUIZ_RESPONSE));
    dispatch(setQuizResponseSearchText(''));
    dispatch(clearQuizResponses());
    dispatch(setQuizResponseSelectedPageSize(20));
    dispatch(setQuizResponseSelectedOrderBy(INITIAL_ORDER_BY_QUIZ_RESPONSES));
    dispatch(downloadQuizResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/userName/, 'user.id')
      .replace(/quiz/, 'quiz.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearQuizResponses());
    dispatch(downloadQuizResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadQuizResponsesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setQuizResponseSelectedPageSize(pageSize));
    dispatch(downloadQuizResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: (text) => {
    dispatch(setProfileSearchText(text));
    searchUserNameDebounce(dispatch);
  },
  onRefresh: (pageSize) => {
    dispatch(setQuizResponseSelectedPageSize(pageSize));
    dispatch(clearQuizResponses());
    dispatch(downloadQuizResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearQuizResponses());
    dispatch(setQuizResponseSelectedOrderBy(INITIAL_ORDER_BY_QUIZ_RESPONSES));
    dispatch(downloadQuizResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setQuizResponseSearchText(text));
      dispatch(clearQuizResponses());
      await dispatch(downloadQuizResponsesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setQuizResponseSelectedOrderBy(orderBy));
    dispatch(clearQuizResponses());
    dispatch(downloadQuizResponsesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setQuizResponseTappedId(id));
    dispatch(downloadDeleteQuizResponseAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeQuizText: async (text) => {
    dispatch(setQuizAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setQuizSearchText(text));
    searchQuizDebounce(dispatch);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizResponsePage);
