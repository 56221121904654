import { reset } from 'redux-form';
import {
  addingEditingSubdistrict, clearSubdistricts, setAlertErrorMessage,
  setSubdistrictSelectedDistrictId, setFunctionalPageMode,
} from '../simple-action';
import { addSubdistrict, editSubdistrict } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_SUBDISTRICT } from '../../../constant';
import downloadSubdistrictsAsync from './downloadSubdistrictsAsync';

export default (name, code) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSubdistrict(true));

    const { token } = getState().authentication;
    const { tappedId, selectedDistrictId } = getState().uiSubdistrict;
    const { data } = getState().subdistricts;

    const found = data[tappedId];
    const district = selectedDistrictId || found.district.id;

    if (tappedId) {
      await editSubdistrict(tappedId, name, district, code, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSubdistrict(name, selectedDistrictId, code, token);
    }

    dispatch(reset(RXFORM_SUBDISTRICT));
    dispatch(clearSubdistricts());
    dispatch(downloadSubdistrictsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setSubdistrictSelectedDistrictId(''));
  } finally {
    dispatch(addingEditingSubdistrict(false));
  }
};
