import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  FILTER_TYPE_DROPDOWN, genders, PAGE_MODE_EDIT, PAGE_MODE_VIEW, PICKER_MODE_DATE,
  RXFIELD_PROFILE_SYNC_BRANCH, RXFIELD_PROFILE_SYNC_BRANCH_ID, RXFIELD_PROFILE_SYNC_COMPANY,
  RXFIELD_PROFILE_SYNC_DATE_OF_BIRTH, RXFIELD_PROFILE_SYNC_DEPARTMENT,
  RXFIELD_PROFILE_SYNC_DIVISION, RXFIELD_PROFILE_SYNC_EMAIL, RXFIELD_PROFILE_SYNC_EMPLOYEE_ID,
  RXFIELD_PROFILE_SYNC_FULL_NAME, RXFIELD_PROFILE_SYNC_GENDER, RXFIELD_PROFILE_SYNC_JOB_TITLE,
  RXFIELD_PROFILE_SYNC_MANAGER_EMPLOYEE_ID, RXFIELD_PROFILE_SYNC_PHONE, RXFORM_PROFILE_SYNC,
  RXSTATE_PROFILE_SYNC_PAGE, RXSTATE_PROFILE_SYNCS, status, STATUS_DISABLED, STATUS_ENABLED,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import { rxformValidateProfileSync } from '../../validation';

const renderDialogContent = (initialValues, branches, companies, departments, divisions, managers,
  addingEditing, loadingBranch, loadingCompany, loadingDepartment, loadingDivision, loadingManager,
  onBranchOptionSelected, onChangeBranchText, onChangeCompanyText, onChangeDepartmentText,
  onChangeDivisionText, onChangeManagerText, onCompanyOptionSelected, onManagerOptionSelected,
  pageMode) => (
    <div>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_EMPLOYEE_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderEmployeeId}
              label={LocalizedString.profileSyncScreen.placeholderEmployeeId}
              disabled={pageMode === PAGE_MODE_EDIT || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_FULL_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderFullName}
              label={LocalizedString.profileSyncScreen.placeholderFullName}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderEmail}
              label={LocalizedString.profileSyncScreen.placeholderEmail}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderPhone}
              label={LocalizedString.profileSyncScreen.placeholderPhone}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_MANAGER_EMPLOYEE_ID}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderManager}
              label={LocalizedString.profileSyncScreen.placeholderManager}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={managers}
              value={initialValues.manager.id}
              loading={loadingManager}
              onOptionSelected={onManagerOptionSelected}
              onChangeText={onChangeManagerText}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_GENDER}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderGender}
              label={LocalizedString.profileSyncScreen.placeholderGender}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={genders}
              value={initialValues.gender.label}
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_JOB_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileSyncScreen.labelJobTitle}
              label={LocalizedString.profileSyncScreen.labelJobTitle}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_DEPARTMENT}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderDepartment}
              label={LocalizedString.profileSyncScreen.placeholderDepartment}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={departments}
              value={initialValues.department}
              loading={loadingDepartment}
              onChangeText={onChangeDepartmentText}
              freeSolo
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_DIVISION}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderDivision}
              label={LocalizedString.profileSyncScreen.placeholderDivision}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={divisions}
              value={initialValues.division}
              loading={loadingDivision}
              onChangeText={onChangeDivisionText}
              freeSolo
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_COMPANY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderCompany}
              label={LocalizedString.profileSyncScreen.placeholderCompany}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={companies}
              value={initialValues.company}
              loading={loadingCompany}
              onOptionSelected={onCompanyOptionSelected}
              onChangeText={onChangeCompanyText}
              freeSolo
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_BRANCH_ID}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileSyncScreen.placeholderBranch}
              label={LocalizedString.profileSyncScreen.placeholderBranch}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={branches}
              value={initialValues.branch.id}
              loading={loadingBranch}
              onOptionSelected={onBranchOptionSelected}
              onChangeText={onChangeBranchText}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_SYNC_DATE_OF_BIRTH}
              component={renderReduxFormDateTimePickerField}
              placeholder={LocalizedString.profileSyncScreen.placeholderDateOfBirth}
              label={LocalizedString.profileSyncScreen.placeholderDateOfBirth}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              pickerMode={PICKER_MODE_DATE}
              disableFuture
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
);

const ProfileSyncPage = ({
  initialValues, branches, companies, departments, divisions, managers,
  addingEditing, downloading, enablingDisabling, isDuplicateAllowed, loadingBranch,
  loadingCompany, loadingDepartment, loadingDivision, loadingManager,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onBranchOptionSelected, onCancelAdvancedFilterPressed, onCancelPressed, onChangeBranchText,
  onChangeCompanyText, onChangeDepartmentText, onChangeDivisionText, onChangeManagerText,
  onChangePage, onChangePageSize, onCompanyOptionSelected, onConfirmEnableDisablePressed,
  onCreatePressed, onDuplicatePressed, onEditPressed, onEnableDisablePressed,
  onManagerOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  currentProfileSyncStatus, pageMode, tappedId,
}) => (
  <FunctionalPage
    data={RXSTATE_PROFILE_SYNCS}
    uiPage={RXSTATE_PROFILE_SYNC_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.profileSyncScreen.buttonCaptionEnable,
        disabled: currentProfileSyncStatus === STATUS_ENABLED || enablingDisabling,
        onPress: () => onEnableDisablePressed(tappedId,
          LocalizedString.profileSyncScreen.msgEnableConfirmation),
      },
      {
        caption: LocalizedString.profileSyncScreen.buttonCaptionDisable,
        disabled: currentProfileSyncStatus === STATUS_DISABLED || enablingDisabling,
        onPress: () => onEnableDisablePressed(tappedId,
          LocalizedString.profileSyncScreen.msgDisableConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionDuplicate,
        disabled: !isDuplicateAllowed,
        onPress: () => { onEditPressed(tappedId); onDuplicatePressed(); },
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.profileSyncScreen.labelBranch,
        field: RXFIELD_PROFILE_SYNC_BRANCH,
        type: FILTER_TYPE_DROPDOWN,
        data: branches,
        loading: loadingBranch,
        onChangeFilterText: onChangeBranchText,
        onFilterOptionSelected: (option) => onBranchOptionSelected(option, false),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.profileSyncScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.profileSyncScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.profileSyncScreen.labelEmployeeId, field: 'employeeId', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelFullName, field: 'fullName', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelEmail, field: 'email', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelManagerId, field: 'managerEmployeeId', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelJobTitle, field: 'jobTitle', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelDepartment, field: 'department', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelCompany, field: 'company', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelStatus, field: 'status', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmEnableDisablePressed}
    onCreatePressed={onCreatePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.profileSyncScreen.buttonCaptionCreateNewProfileSync}
    title={LocalizedString.profileSyncScreen.title}
    editButtonCaption={LocalizedString.profileSyncScreen.buttonCaptionEditProfileSync}
    disableDelete
    useFullWidth
    usefullWidthDialog
    createPermissionName="AUTHEXT_CREATE_PROFILE"
    editPermissionName="AUTHEXT_EDIT_PROFILE"
  >
    {renderDialogContent(initialValues, branches, companies, departments, divisions, managers,
      addingEditing, loadingBranch, loadingCompany, loadingDepartment, loadingDivision,
      loadingManager, onBranchOptionSelected, onChangeBranchText, onChangeCompanyText,
      onChangeDepartmentText, onChangeDivisionText, onChangeManagerText, onCompanyOptionSelected,
      onManagerOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PROFILE_SYNC,
  validate: rxformValidateProfileSync,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProfileSyncPage);

ProfileSyncPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  branches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  companies: PropTypes.arrayOf(SimpleDataShape).isRequired,
  departments: PropTypes.arrayOf(SimpleDataShape).isRequired,
  divisions: PropTypes.arrayOf(SimpleDataShape).isRequired,
  managers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  isDuplicateAllowed: PropTypes.bool.isRequired,
  loadingBranch: PropTypes.bool.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
  loadingDepartment: PropTypes.bool.isRequired,
  loadingDivision: PropTypes.bool.isRequired,
  loadingManager: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onBranchOptionSelected: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeBranchText: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangeDepartmentText: PropTypes.func.isRequired,
  onChangeDivisionText: PropTypes.func.isRequired,
  onChangeManagerText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCompanyOptionSelected: PropTypes.func.isRequired,
  onConfirmEnableDisablePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDuplicatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onManagerOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentProfileSyncStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
