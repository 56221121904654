import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest,
  transformQueryResult, sendGetFileRequest,
} from '../../helper';
import {
  REST_URL_ADD_EDIT_LOCATION, REST_URL_LOCATIONS, REST_URL_VIEW_DELETE_LOCATION,
  REST_URL_EVENTS, REST_URL_VIEW_DELETE_EVENT, REST_URL_ADD_EDIT_EVENT, REST_URL_SAVE_FILE,
} from './constant';

export * from '../../helper';

export const downloadLocations = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_LOCATIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadLocation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_LOCATION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteLocation = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_LOCATION.replace(/\{id\}/, id), token);
};

export const addLocation = async (name, description, token) => {
  const body = {
    name,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_LOCATION, body, token);
};

export const editLocation = async (id, name, description, token) => {
  const body = {
    id,
    name,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_LOCATION, body, token);
};

export const saveEvents = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_EVENTS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadEvents = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(REST_URL_EVENTS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEvent = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT.replace(/\{id\}/, id), token);
  return response;
};

export const deleteEvent = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT.replace(/\{id\}/, id), token);
};

export const addEvent = async (name, referenceNumber, startTime, endTime, timeZone,
  locationId, participants, description, token) => {
  const body = {
    name,
    referenceNumber,
    startTime,
    endTime,
    timeZone,
    locationId,
    participants,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_EVENT, body, token);
};

export const editEvent = async (id, name, referenceNumber, startTime, endTime, timeZone,
  locationId, participants, description, token) => {
  const body = {
    id,
    name,
    referenceNumber,
    startTime,
    endTime,
    timeZone,
    locationId,
    participants,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_EVENT, body, token);
};
