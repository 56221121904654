import {
  DOWNLOADING_NOTIFICATION_TASKS, CANCELING_NOTIFICATION_TASK,
  DOWNLOADING_DELETING_NOTIFICATION_TASK, SET_NOTIFICATION_TASK_SELECTED_PAGE_SIZE,
  SET_NOTIFICATION_TASK_TAPPED_ID, SET_NOTIFICATION_TASK_SEARCH_TEXT,
  SET_NOTIFICATION_TASK_SELECTED_ORDER_BY, SET_NOTIFICATION_TASK_DUPLICATE_DIALOG_VISIBILITY,
  DOWNLOADING_NOTIFICATION_TASK_TOTAL_PERSON_COUNT, SET_NOTIFICATION_TASK_TOTAL_PERSON_COUNT,
  SENDING_NOTIFICATION_TASK_NOTIFICATION,
} from '../action';
import { INITIAL_ORDER_BY_NOTIFICATION_TASKS } from '../../constant';

const initialState = {
  canceling: false,
  downloadingDeleting: false,
  downloading: false,
  downloadingTotalPersonCount: false,
  duplicateDialogVisibility: false,
  sendingNotification: false,
  selectedPageSize: 20,
  totalPersonCount: 0,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_NOTIFICATION_TASKS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_NOTIFICATION_TASKS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_NOTIFICATION_TASK:
      return { ...state, downloadingDeleting: action.status };
    case SET_NOTIFICATION_TASK_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_NOTIFICATION_TASK_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_NOTIFICATION_TASK_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_NOTIFICATION_TASK_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case CANCELING_NOTIFICATION_TASK:
      return { ...state, canceling: action.status };
    case SET_NOTIFICATION_TASK_DUPLICATE_DIALOG_VISIBILITY:
      return { ...state, duplicateDialogVisibility: action.status };
    case DOWNLOADING_NOTIFICATION_TASK_TOTAL_PERSON_COUNT:
      return { ...state, downloadingTotalPersonCount: action.status };
    case SET_NOTIFICATION_TASK_TOTAL_PERSON_COUNT:
      return { ...state, totalPersonCount: action.data };
    case SENDING_NOTIFICATION_TASK_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    default: return state;
  }
};
