import { reset } from 'redux-form';
import {
  addingHolidays,
  clearHolidays,
  setAlertErrorMessage,
  setHolidaySelectedFile,
} from '../simple-action';
import {
  addHoliday,
} from '../../../helper';
import {
  RXFORM_HOLIDAY,
} from '../../../constant';
import downloadHolidaysAsync from './downloadHolidaysAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingHolidays(true));

    const { token } = getState().authentication;
    const { selectedYear, selectedFile } = getState().uiHoliday;

    await addHoliday(
      selectedFile, selectedYear, token,
    );

    dispatch(setHolidaySelectedFile(''));
    dispatch(reset(RXFORM_HOLIDAY));
    dispatch(clearHolidays());
    dispatch(downloadHolidaysAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingHolidays(false));
  }
};
