import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_LOCATIONS = 'DOWNLOADING_LOCATIONS';
export const downloadingLocations = makeActionCreator(DOWNLOADING_LOCATIONS, 'status');

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const setLocations = makeActionCreator(SET_LOCATIONS, 'data');

export const ADD_LOCATION = 'ADD_LOCATION';
export const addLocation = makeActionCreator(ADD_LOCATION, 'data');

export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const clearLocations = makeActionCreator(CLEAR_LOCATIONS);

export const ADDING_EDITING_LOCATION = 'ADDING_EDITING_LOCATION';
export const addingEditingLocation = makeActionCreator(ADDING_EDITING_LOCATION, 'status');

export const DOWNLOADING_DELETING_LOCATION = 'DOWNLOADING_DELETING_LOCATION';
export const downloadingDeletingLocation = makeActionCreator(DOWNLOADING_DELETING_LOCATION, 'status');


export const SET_LOCATIONS_SELECTED_PAGE_SIZE = 'SET_LOCATIONS_SELECTED_PAGE_SIZE';
export const setLocationsSelectedPageSize = makeActionCreator(SET_LOCATIONS_SELECTED_PAGE_SIZE, 'size');

export const SET_LOCATIONS_SELECTED_ORDER_BY = 'SET_LOCATIONS_SELECTED_ORDER_BY';
export const setLocationsSelectedOrderBy = makeActionCreator(SET_LOCATIONS_SELECTED_ORDER_BY, 'order');

export const SET_LOCATION_TAPPED_ID = 'SET_LOCATION_TAPPED_ID';
export const setLocationTappedId = makeActionCreator(SET_LOCATION_TAPPED_ID, 'id');

export const SET_LOCATIONS_SEARCH_TEXT = 'SET_LOCATIONS_SEARCH_TEXT';
export const setLocationsSearchText = makeActionCreator(SET_LOCATIONS_SEARCH_TEXT, 'text');

export const DOWNLOADING_EVENTS = 'DOWNLOADING_EVENTS';
export const downloadingEvents = makeActionCreator(DOWNLOADING_EVENTS, 'status');

export const SET_EVENTS = 'SET_EVENTS';
export const setEvents = makeActionCreator(SET_EVENTS, 'data');

export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const clearEvents = makeActionCreator(CLEAR_EVENTS);

export const ADD_EVENT = 'ADD_EVENT';
export const addEvent = makeActionCreator(ADD_EVENT, 'data');

export const ADDING_EDITING_EVENT = 'ADDING_EDITING_EVENT';
export const addingEditingEvent = makeActionCreator(ADDING_EDITING_EVENT, 'status');

export const DOWNLOADING_DELETING_EVENT = 'DOWNLOADING_DELETING_EVENT';
export const downloadingDeletingEvent = makeActionCreator(DOWNLOADING_DELETING_EVENT, 'status');

export const SAVING_EVENTS = 'SAVING_EVENTS';
export const savingEvents = makeActionCreator(SAVING_EVENTS, 'status');


export const SET_EVENTS_SELECTED_PAGE_SIZE = 'SET_EVENTS_SELECTED_PAGE_SIZE';
export const setEventsSelectedPageSize = makeActionCreator(SET_EVENTS_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENTS_SELECTED_ORDER_BY = 'SET_EVENTS_SELECTED_ORDER_BY';
export const setEventsSelectedOrderBy = makeActionCreator(SET_EVENTS_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_TAPPED_ID = 'SET_EVENT_TAPPED_ID';
export const setEventTappedId = makeActionCreator(SET_EVENT_TAPPED_ID, 'id');

export const SET_EVENTS_SEARCH_TEXT = 'SET_EVENTS_SEARCH_TEXT';
export const setEventsSearchText = makeActionCreator(SET_EVENTS_SEARCH_TEXT, 'text');

export const SET_EVENT_SELECTED_TIME_ZONE = 'SET_EVENT_SELECTED_TIME_ZONE';
export const setEventSelectedTimeZine = makeActionCreator(SET_EVENT_SELECTED_TIME_ZONE, 'option');

export const SET_EVENT_SELECTED_LOCATION = 'SET_EVENT_SELECTED_LOCATION';
export const setEventSelectedLocation = makeActionCreator(SET_EVENT_SELECTED_LOCATION, 'option');
