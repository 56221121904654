import { setResyncDBUsers, resyncingDBUsers } from '../simple-action';
import { resyncDBUsers } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingDBUsers(true));

    const { token } = getState().authentication;

    const data = await resyncDBUsers(token);

    dispatch(setResyncDBUsers({}));
    dispatch(setResyncDBUsers(data));
  } finally {
    dispatch(resyncingDBUsers(false));
  }
};
