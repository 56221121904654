import {
  enablingDisablingQuizQuestion, setAlertErrorMessage, setQuizQuestionSelectedOrderBy,
  setQuizQuestionSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_QUIZ_QUESTIONS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableQuizQuestion } from '../../../helper';
import downloadQuizQuestionsAsync from './downloadQuizQuestionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingQuizQuestion(true));

    const { token } = getState().authentication;
    const { data } = getState().quizQuestions;
    const { tappedId } = getState().uiQuizQuestion;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableQuizQuestion(tappedId, status, token);

    dispatch(setQuizQuestionSelectedPageSize(20));
    dispatch(setQuizQuestionSelectedOrderBy(INITIAL_ORDER_BY_QUIZ_QUESTIONS));
    dispatch(downloadQuizQuestionsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(enablingDisablingQuizQuestion(false));
  }
};
