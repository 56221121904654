import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_WHATSAPPS, MENUID_NOTIFICATION_WHATSAPP,
  PAGE_MODE_TABLE, RXFORM_WHATSAPP,
} from '../../constant';
import { transformInitialValues } from '../../../../helper';
import LocalizedString from '../../localization';
import {
  clearWhatsapps, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setWhatsappSearchText,
  setWhatsappSelectedPageSize, setWhatsappSelectedOrderBy, setWhatsappTappedId,
  downloadWhatsappsAsync, downloadWhatsappAsync, resendWhatsappAsync,
} from '../../redux/action';
import WhatsappPage from './whatsapp.presentation';

const getInitialValues = (state) => {
  const { whatsapps, uiFunctionalPage, uiWhatsapp } = state;
  const { data } = whatsapps;
  const { downloadingDeleting, tappedId } = uiWhatsapp;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    to: '',
    message: '',
    processingStatus: '',
    notes: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiWhatsapp.downloading,
  downloadingDeleting: state.uiWhatsapp.downloadingDeleting,
  resending: state.uiWhatsapp.resending,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NOTIFICATION_WHATSAPP));
    dispatch(setWhatsappSearchText(''));
    dispatch(clearWhatsapps());
    dispatch(setWhatsappSelectedPageSize(20));
    dispatch(setWhatsappSelectedOrderBy(INITIAL_ORDER_BY_WHATSAPPS));
    dispatch(downloadWhatsappsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearWhatsapps());
    dispatch(downloadWhatsappsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_WHATSAPP));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWhatsappsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWhatsappSelectedPageSize(pageSize));
    dispatch(downloadWhatsappsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmResendPressed: () => {
    dispatch(resendWhatsappAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setWhatsappSelectedPageSize(pageSize));
    dispatch(clearWhatsapps());
    dispatch(downloadWhatsappsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResendPressed: (id) => {
    dispatch(setWhatsappTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.notificationMenu.msgResendConfirmation));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearWhatsapps());
    dispatch(setWhatsappSelectedOrderBy(INITIAL_ORDER_BY_WHATSAPPS));
    dispatch(downloadWhatsappsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWhatsappSearchText(text));
      dispatch(clearWhatsapps());
      await dispatch(downloadWhatsappsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWhatsappSelectedOrderBy(orderBy));
    dispatch(clearWhatsapps());
    dispatch(downloadWhatsappsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setWhatsappTappedId(id));
    dispatch(downloadWhatsappAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappPage);
