import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Paper, Tab, Tabs,
} from '@material-ui/core';
import {
  COLOR_ICON_LIGHTER, COLOR_PRIMARY, COLOR_HORIZONTAL_LINE,
  COLOR_BACKGROUND, COLOR_TEXT_BLACK_SECONDARY,
} from '../constant';
import { StyledTabShape } from '../type';

const useStyles = makeStyles(() => ({
  tabsContainer: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderTop: `1px solid ${COLOR_HORIZONTAL_LINE}`,
    borderLeft: `1px solid ${COLOR_HORIZONTAL_LINE}`,
    borderRight: `1px solid ${COLOR_HORIZONTAL_LINE}`,
  },
  tabContainer: {
    border: `1px solid ${COLOR_HORIZONTAL_LINE}`,
  },
  activeTabContainer: {
    backgroundColor: COLOR_BACKGROUND,
  },
  tabWrapper: {
    alignItems: 'flex-start',
  },
  activeTabText: {
    color: COLOR_PRIMARY,
  },
  inactiveTabText: {
    color: COLOR_TEXT_BLACK_SECONDARY,
  },
  activeIconContainer: {
    border: `1.5px solid ${COLOR_PRIMARY}`,
  },
  inactiveIconContainer: {
    border: `1.5px solid ${COLOR_ICON_LIGHTER}`,
  },
  iconContainer: {
    borderRadius: 3,
    padding: 2,
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  icon: {
    fontSize: '16px',
    color: COLOR_PRIMARY,
  },
  activeTabIndicator: {
    color: COLOR_PRIMARY,
  },
}));

const StyledTab = ({
  data, onTabPressed, activeTab, disabled,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.tabsContainer}>
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => onTabPressed(newValue)}
        variant="fullWidth"
        indicatorColor="primary"
        classes={{ indicator: classes.activeTabIndicator }}
      >
        {data.map((item, index) => {
          if (item.hasPermission) {
            const isActive = item.label === activeTab;
            const icon = isActive ? item.activeIcon : item.inactiveIcon;
            const ItemIconName = item.iconName;
            const IconComponent = ItemIconName
              ? (
                <span className={`${classes.iconContainer} ${isActive ? classes.activeIconContainer : classes.inactiveIconContainer}`}>
                  <ItemIconName classes={{ root: `${classes.icon}  ${isActive ? classes.activeTabText : classes.inactiveTabText}` }} />
                </span>
              )
              : (<img alt={`tab-${index}`} src={icon} width={20} height={20} />);
            return (
              <Tab
                key={item.labels}
                icon={IconComponent}
                label={item.label}
                value={item.label}
                classes={{
                  selected: classes.activeTabContainer,
                  wrapper: classes.tabWrapper,
                  textColorInherit: isActive ? classes.activeTabText : classes.inactiveTabText,
                  root: classes.tabContainer,
                }}
                disabled={disabled}
              />
            );
          }
          return null;
        })}
      </Tabs>
    </Paper>
  );
};

export default StyledTab;

StyledTab.propTypes = {
  data: PropTypes.arrayOf(StyledTabShape).isRequired,
  onTabPressed: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

StyledTab.defaultProps = {
  disabled: false,
};
