import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_GAMEQR_CATEGORIES = 'DOWNLOADING_GAMEQR_CATEGORIES';
export const downloadingGameQRCategories = makeActionCreator(DOWNLOADING_GAMEQR_CATEGORIES, 'status');

export const SET_GAMEQR_CATEGORIES = 'SET_GAMEQR_CATEGORIES';
export const setGameQRCategories = makeActionCreator(SET_GAMEQR_CATEGORIES, 'data');

export const ADD_GAMEQR_CATEGORY = 'ADD_GAMEQR_CATEGORY';
export const addGameQRCategory = makeActionCreator(ADD_GAMEQR_CATEGORY, 'data');

export const CLEAR_GAMEQR_CATEGORIES = 'CLEAR_GAMEQR_CATEGORIES';
export const clearGameQRCategories = makeActionCreator(CLEAR_GAMEQR_CATEGORIES);

export const ADDING_EDITING_GAMEQR_CATEGORY = 'ADDING_EDITING_GAMEQR_CATEGORY';
export const addingEditingGameQRCategory = makeActionCreator(ADDING_EDITING_GAMEQR_CATEGORY, 'status');

export const DOWNLOADING_DELETING_GAMEQR_CATEGORY = 'DOWNLOADING_DELETING_GAMEQR_CATEGORY';
export const downloadingDeletingGameQRCategory = makeActionCreator(DOWNLOADING_DELETING_GAMEQR_CATEGORY, 'status');


export const SET_GAMEQR_CATEGORY_SELECTED_PAGE_SIZE = 'SET_GAMEQR_CATEGORY_SELECTED_PAGE_SIZE';
export const setGameQRCategorySelectedPageSize = makeActionCreator(SET_GAMEQR_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_GAMEQR_CATEGORY_SELECTED_ORDER_BY = 'SET_GAMEQR_CATEGORY_SELECTED_ORDER_BY';
export const setGameQRCategorySelectedOrderBy = makeActionCreator(SET_GAMEQR_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_GAMEQR_CATEGORY_TAPPED_ID = 'SET_GAMEQR_CATEGORY_TAPPED_ID';
export const setGameQRCategoryTappedId = makeActionCreator(SET_GAMEQR_CATEGORY_TAPPED_ID, 'id');

export const SET_GAMEQR_CATEGORY_SEARCH_TEXT = 'SET_GAMEQR_CATEGORY_SEARCH_TEXT';
export const setGameQRCategorySearchText = makeActionCreator(SET_GAMEQR_CATEGORY_SEARCH_TEXT, 'text');

export const SET_GAMEQR_CATEGORY_SELECTED_PARENT_CATEGORY = 'SET_GAMEQR_CATEGORY_SELECTED_PARENT_CATEGORY';
export const setGameQRCategorySelectedParentCategory = makeActionCreator(SET_GAMEQR_CATEGORY_SELECTED_PARENT_CATEGORY, 'option');

export const SET_GAMEQR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_GAMEQR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setGameQRCategoryAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_GAMEQR_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_CHALLENGES = 'DOWNLOADING_CHALLENGES';
export const downloadingChallenges = makeActionCreator(DOWNLOADING_CHALLENGES, 'status');

export const SET_CHALLENGES = 'SET_CHALLENGES';
export const setChallenge = makeActionCreator(SET_CHALLENGES, 'data');

export const ADD_CHALLENGE = 'ADD_CHALLENGE';
export const addChallenge = makeActionCreator(ADD_CHALLENGE, 'data');

export const CLEAR_CHALLENGES = 'CLEAR_CHALLENGES';
export const clearChallenges = makeActionCreator(CLEAR_CHALLENGES);

export const ADDING_EDITING_CHALLENGE = 'ADDING_EDITING_CHALLENGE';
export const addingEditingChallenge = makeActionCreator(ADDING_EDITING_CHALLENGE, 'status');

export const DOWNLOADING_DELETING_CHALLENGE = 'DOWNLOADING_DELETING_CHALLENGE';
export const downloadingDeletingChallenge = makeActionCreator(DOWNLOADING_DELETING_CHALLENGE, 'status');


export const SET_CHALLENGE_SELECTED_PAGE_SIZE = 'SET_CHALLENGE_SELECTED_PAGE_SIZE';
export const setChallengeSelectedPageSize = makeActionCreator(SET_CHALLENGE_SELECTED_PAGE_SIZE, 'size');

export const SET_CHALLENGE_SELECTED_ORDER_BY = 'SET_CHALLENGE_SELECTED_ORDER_BY';
export const setChallengeSelectedOrderBy = makeActionCreator(SET_CHALLENGE_SELECTED_ORDER_BY, 'order');

export const SET_CHALLENGE_TAPPED_ID = 'SET_CHALLENGE_TAPPED_ID';
export const setChallengeTappedId = makeActionCreator(SET_CHALLENGE_TAPPED_ID, 'id');

export const SET_CHALLENGE_SEARCH_TEXT = 'SET_CHALLENGE_SEARCH_TEXT';
export const setChallengeSearchText = makeActionCreator(SET_CHALLENGE_SEARCH_TEXT, 'text');

export const SET_CHALLENGE_SELECTED_CATEGORY = 'SET_CHALLENGE_SELECTED_CATEGORY';
export const setChallengeSelectedCategory = makeActionCreator(SET_CHALLENGE_SELECTED_CATEGORY, 'option');

export const SET_CHALLENGE_SELECTED_VISIBILITY_STATUS = 'SET_CHALLENGE_SELECTED_VISIBILITY_STATUS';
export const setChallengeSelectedVisibilityStatus = makeActionCreator(SET_CHALLENGE_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU = 'SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU';
export const setChallengeSelectedAddMediaMenu = makeActionCreator(SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU, 'text');

export const SET_CHALLENGE_SELECTED_SCAN_POLICY = 'SET_CHALLENGE_SELECTED_SCAN_POLICY';
export const setChallengeSelectedScanPolicy = makeActionCreator(SET_CHALLENGE_SELECTED_SCAN_POLICY, 'option');

export const SET_CHALLENGE_SELECTED_IS_PROMOTED = 'SET_CHALLENGE_SELECTED_IS_PROMOTED';
export const setChallengeSelectedIsPromoted = makeActionCreator(SET_CHALLENGE_SELECTED_IS_PROMOTED, 'option');
