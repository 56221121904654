import { connect } from 'react-redux';
import { setColorPickerVisiblity } from '../../redux/action';
import ColorPickerField from './color-picker-field.presentation';

const mapStateToProps = (state) => ({
  hex: state.uiColorPickerField.hex,
  visibility: state.uiColorPickerField.visibility,
});

const mapDispatchToProps = (dispatch) => ({
  onFieldPressed: (status) => {
    dispatch(setColorPickerVisiblity(!status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorPickerField);
