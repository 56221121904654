import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, makeStyles, Modal } from '@material-ui/core';
import NavigationPrompt from 'react-router-navigation-prompt';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import LocalizedString from '../../localization';
import { COLOR_DANGEROUS, ROUTE_NAME_LOGIN } from '../../constant';
import AccentButton from '../accent-button';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
}));

const onConfirmPress = (callback, onConfirmPressed, history) => {
  onConfirmPressed(history);
  callback();
};

const Prompt = ({
  authenticated, onConfirmPressed, history, loggingOut,
}) => {
  const classes = useStyles();
  return (
    <NavigationPrompt
      when={(currentLoc, nextLoc) => authenticated && nextLoc
        && nextLoc.pathname === ROUTE_NAME_LOGIN && !loggingOut}
    >
      {({ isActive, onCancel, onConfirm }) => (
        <Modal open={isActive} disableBackdropClick disableEscapeKeyDown>
          <DialogContent>
            <SweetAlert
              type="success"
              show={isActive}
              title={LocalizedString.common.alertTitleConfirmation}
              openAnim={{ name: 'showSweetAlert', duration: 500 }}
              onConfirm={() => onConfirmPress(onConfirm, onConfirmPressed, history)}
              closeOnClickOutside={false}
              customButtons={(
                <div className={classes.buttonContainer}>
                  <AccentButton
                    onClick={() => onCancel()}
                    variant="text"
                    className={classes.redButton}
                    caption={LocalizedString.common.buttonCaptionNo}
                  />
                  <AccentButton
                    onClick={() => onConfirmPress(onConfirm, onConfirmPressed, history)}
                    caption={LocalizedString.common.buttonCaptionYes}
                  />
                </div>
        )}
            >
              {LocalizedString.common.msgLogoutConfirmation}
            </SweetAlert>
          </DialogContent>
        </Modal>
      )}
    </NavigationPrompt>
  );
};

export default withRouter(Prompt);

Prompt.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loggingOut: PropTypes.bool.isRequired,
};
