import { connect } from 'react-redux';
import { logoutAsync, setAlertErrorMessage } from '../../redux/action';
import Prompt from './navigation-prompt.presentation';

const mapStateToProps = (state) => ({
  authenticated: !!(state.authentication.token),
  loggingOut: state.uiLogin.loggingOut,
});

const mapDispatchToProps = (dispatch) => ({
  onConfirmPressed: (history) => {
    dispatch(logoutAsync(history.push))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Prompt);
