import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import {
  RXFIELD_EVENT_NAME, RXFORM_EVENTS, RXSTATE_EVENTS,
  RXSTATE_EVENT_PAGE, RXFIELD_EVENT_REFERENCE_NUMBER,
  PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME,
  RXFIELD_EVENT_START_TIME, RXFIELD_EVENT_END_TIME,
  RXFIELD_EVENT_TIME_ZONE, RXFIELD_EVENT_PARTICIPANTS,
  RXFIELD_EVENT_LOCATION, RXFIELD_EVENT_DESCRIPTION,
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_DATE_RANGE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  timezoneOffset,
} from '../../constant';
import { rxformValidateEvent } from '../../validation';
import { FunctionalPage } from '../../component';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormRichTextEditorField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode,
  onTimeZoneOptionSelected, onChangeDescriptionText,
  locations, onLocationOptionSelected, onChangeLocationText,
  defaultTimeZoneValue,
) => (
  <Grid container spacing={3}>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_EVENT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.eventScreen.placeholderName}
          label={LocalizedString.eventScreen.placeholderName}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_EVENT_LOCATION}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.eventScreen.placeholderLocation}
          label={LocalizedString.eventScreen.placeholderLocation}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={locations}
          onChangeText={onChangeLocationText}
          onOptionSelected={onLocationOptionSelected}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_EVENT_START_TIME}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.eventScreen.placeholderStartTime}
          label={LocalizedString.eventScreen.placeholderStartTime}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          pickerMode={PICKER_MODE_DATE_TIME}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_EVENT_TIME_ZONE}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.eventScreen.placeholderTimeZone}
          label={LocalizedString.eventScreen.placeholderTimeZone}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={timezoneOffset}
          value={defaultTimeZoneValue}
          onOptionSelected={onTimeZoneOptionSelected}
          required
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_EVENT_REFERENCE_NUMBER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.eventScreen.placeholderReferenceNumber}
          label={LocalizedString.eventScreen.placeholderReferenceNumber}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_EVENT_PARTICIPANTS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.eventScreen.placeholderParticipants}
          label={LocalizedString.eventScreen.placeholderParticipants}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          multiline
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_EVENT_END_TIME}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.eventScreen.placeholderEndTime}
          label={LocalizedString.eventScreen.placeholderEndTime}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          pickerMode={PICKER_MODE_DATE_TIME}
          required
        />
      </Grid>
    </Grid>

    <Grid item sm={12}>
      <Field
        name={RXFIELD_EVENT_DESCRIPTION}
        component={renderReduxFormRichTextEditorField}
        label={LocalizedString.eventScreen.placeholderDescription}
        disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        onChangeText={onChangeDescriptionText}
        loading={downloadingDeleting}
        required
      />
    </Grid>
  </Grid>
);

const EventPage = ({
  downloading, handleSubmit, pageMode, onEditPressed,
  addingEditing, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  downloadingDeleting, onTimeZoneOptionSelected,
  locations, onLocationOptionSelected, onChangeLocationText, downloadingLocation,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onChangeDescriptionText, initialValues, onDownloadPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_EVENTS}
    uiPage={RXSTATE_EVENT_PAGE}
    tableColumns={[
      {
        title: LocalizedString.eventScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.eventScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.eventScreen.labelLocation, field: 'location.name', sorting: !downloading },
      {
        title: LocalizedString.eventScreen.labelStartTime,
        field: 'startTime',
        sorting: !downloading,
        render: ({ startTime }) => toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      },
      {
        title: LocalizedString.eventScreen.labelEndDate,
        field: 'endTime',
        sorting: !downloading,
        render: ({ endTime }) => toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.eventScreen.labelStartDate,
        field: RXFIELD_EVENT_START_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.eventScreen.labelLocation,
        field: RXFIELD_EVENT_LOCATION,
        type: FILTER_TYPE_DROPDOWN,
        data: locations,
        loading: downloadingLocation,
        onChangeFilterText: onChangeLocationText,
        onFilterOptionSelected: onLocationOptionSelected,
        useDropdownValue: true,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    onDownloadPressed={onDownloadPressed}
    createNewButtonCaption={LocalizedString.eventScreen.buttonCaptionCreateNew}
    deleteButtonCaption={LocalizedString.eventScreen.buttonCaptionDelete}
    title={LocalizedString.eventScreen.title}
    editButtonCaption={LocalizedString.eventScreen.buttonCaptionEdit}
    enableSave
    useFullWidth
  >
    {renderDialogContent(
      addingEditing, downloadingDeleting, pageMode,
      onTimeZoneOptionSelected, onChangeDescriptionText,
      locations, onLocationOptionSelected, onChangeLocationText,
      initialValues.timeZone,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_EVENTS,
  validate: rxformValidateEvent,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventPage);

EventPage.propTypes = {
  initialValues: FormInitialValueShape,
  onAppear: PropTypes.func.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingLocation: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onTimeZoneOptionSelected: PropTypes.func.isRequired,
  onChangeDescriptionText: PropTypes.func.isRequired,
  onLocationOptionSelected: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(SimpleDataShape),
};

EventPage.defaultProps = {
  locations: [],
  downloadingLocation: false,
  initialValues: {},
};
