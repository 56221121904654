import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { FunctionalPage, SectionTitle } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXSTATE_SURVEY_RESPONSES, RXSTATE_SURVEY_RESPONSE_PAGE,
  RXFORM_SURVEY_RESPONSE, RXFIELD_CREATED_DATE, RXFIELD_SURVEY_RESPONSE_USER_NAME,
  RXFIELD_SURVEY_RESPONSE_USER_ID, RXFIELD_SURVEY_RESPONSE_SURVEY,
  RXFIELD_SURVEY_RESPONSE_SURVEY_TITLE, RXFIELD_SURVEY_RESPONSE_SURVEY_DESCRIPTION,
  FIELD_TYPES, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape, FormInitialValueShape } from '../../type';

const renderAnswerField = (item, downloadingDeleting) => {
  const isPhotoField = item.question.fieldType === FIELD_TYPES[9];
  if (isPhotoField) {
    return (
      <Field
        name={item.question.id}
        component={renderReduxFormImageInputField}
        label={item.question.label}
        placeholder={item.question.label}
        defaultValue={item.value ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${item.value}` : null}
        disabled
        useFullWidthImage
        loading={downloadingDeleting}
      />
    );
  }
  return (
    <Field
      name={item.question.id}
      component={renderReduxFormOutlinedTextField}
      label={item.question.label}
      placeholder={item.question.label}
      defaultValue={item.value}
      disabled
    />
  );
};

const renderDialogContent = (initialValues, downloadingDeleting) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_SURVEY_RESPONSE_SURVEY_TITLE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.surveyResponseScreen.placeholderSurveyTitle}
            placeholder={LocalizedString.surveyResponseScreen.placeholderSurveyTitle}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_SURVEY_RESPONSE_SURVEY_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.surveyResponseScreen.placeholderSurveyDescription}
            placeholder={LocalizedString.surveyResponseScreen.placeholderSurveyDescription}
            disabled
            multiline
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_SURVEY_RESPONSE_USER_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.surveyResponseScreen.placeholderUserID}
            placeholder={LocalizedString.surveyResponseScreen.placeholderUserID}
            disabled
            multiline
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_SURVEY_RESPONSE_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.surveyResponseScreen.placeholderUserName}
            placeholder={LocalizedString.surveyResponseScreen.placeholderUserName}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.surveyResponseScreen.labelSurveyAnswers} />
    <Grid container spacing={3}>
      <Grid item sm md>
        {initialValues.answers && initialValues.answers.map((item) => (
          <Grid key={item.id}>
            {renderAnswerField(item, downloadingDeleting)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

const SurveyResponsePage = ({
  users,
  downloading, downloadingDeleting, loadingUser,
  handleSubmit, onAppear, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  surveys, loadingSurveys, onChangeSurveyText, initialValues,
  history,
}) => {
  // eslint-disable-next-line
  useEffect(() => onAppear(history?.location?.state || ''), []);

  return (
    <FunctionalPage
      data={RXSTATE_SURVEY_RESPONSES}
      uiPage={RXSTATE_SURVEY_RESPONSE_PAGE}
      filterColumns={[
        {
          title: LocalizedString.surveyResponseScreen.placeholderCreatedDate,
          field: RXFIELD_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.surveyResponseScreen.placeholderUserName,
          field: RXFIELD_SURVEY_RESPONSE_USER_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUser,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.surveyResponseScreen.placeholderSurvey,
          field: RXFIELD_SURVEY_RESPONSE_SURVEY,
          type: FILTER_TYPE_DROPDOWN,
          data: surveys,
          loading: loadingSurveys,
          onChangeFilterText: onChangeSurveyText,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.surveyResponseScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.surveyResponseScreen.labelSurvey, field: 'survey.title', sorting: !downloading },
        { title: LocalizedString.surveyResponseScreen.labelUser, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.surveyResponseScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.surveyResponseScreen.title}
      useFullWidth
      disableCreate
      disableDelete
      disableEdit
    >
      {renderDialogContent(initialValues, downloadingDeleting)}
    </FunctionalPage>
  );
};

export default withRouter(reduxForm({
  form: RXFORM_SURVEY_RESPONSE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SurveyResponsePage));

SurveyResponsePage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  surveys: PropTypes.arrayOf(SimpleDataShape).isRequired,
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingSurveys: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onChangeSurveyText: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
