import {
  DOWNLOADING_MY_JOBS, DOWNLOADING_DELETING_MY_JOB, SET_MY_JOB_SELECTED_PAGE_SIZE,
  SET_MY_JOB_TAPPED_ID, SET_MY_JOB_SEARCH_TEXT, SET_MY_JOB_SELECTED_ORDER_BY, CANCELING_MY_JOB,
} from '../action';
import { INITIAL_ORDER_BY_MY_JOBS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  canceling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_MY_JOBS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_MY_JOBS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_MY_JOB:
      return { ...state, downloadingDeleting: action.status };
    case CANCELING_MY_JOB:
      return { ...state, canceling: action.status };
    case SET_MY_JOB_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_MY_JOB_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_MY_JOB_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_MY_JOB_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
