import { isNaN } from 'lodash';
import LocalizedString from '../../localization';
import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_COMPANY_MAPPING_ORDER, RXFIELD_COMPANY_MAPPING_PHRASE,
  RXFIELD_COMPANY_MAPPING_OPERATOR, RXFIELD_COMPANY_MAPPING_COMPANY_NAME,
  RXFIELD_COMPANY_MAPPING_ADDRESS, RXFIELD_COMPANY_MAPPING_CITY,
  RXFIELD_COMPANY_MAPPING_PROVINCE, RXFIELD_COMPANY_MAPPING_POSTAL_CODE,
  RXFIELD_COMPANY_MAPPING_PHONE,
  RXFIELD_COMPANY_MAPPING_WEBSITE,
  RXFIELD_INFO_MAPPING_MAPPING_OPERATOR, RXFIELD_INFO_MAPPING_NEW_NAME, RXFIELD_INFO_MAPPING_ORDER,
  RXFIELD_INFO_MAPPING_PHRASE, RXFIELD_INFO_MAPPING_TYPE,
} from './constant';

export const rxformValidateCompanyMapping = (values) => {
  const requiredFields = [
    RXFIELD_COMPANY_MAPPING_ORDER, RXFIELD_COMPANY_MAPPING_PHRASE,
    RXFIELD_COMPANY_MAPPING_OPERATOR, RXFIELD_COMPANY_MAPPING_COMPANY_NAME,
    RXFIELD_COMPANY_MAPPING_ADDRESS, RXFIELD_COMPANY_MAPPING_CITY,
    RXFIELD_COMPANY_MAPPING_PROVINCE, RXFIELD_COMPANY_MAPPING_POSTAL_CODE,
    RXFIELD_COMPANY_MAPPING_PHONE, RXFIELD_COMPANY_MAPPING_WEBSITE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  if (values[RXFIELD_COMPANY_MAPPING_ORDER]
    && isNaN(Number(values[RXFIELD_COMPANY_MAPPING_ORDER]))) {
    errors[RXFIELD_COMPANY_MAPPING_ORDER] = LocalizedString.common.errMsgMustaNumber;
  }
  if (values[RXFIELD_COMPANY_MAPPING_POSTAL_CODE]
    && isNaN(Number(values[RXFIELD_COMPANY_MAPPING_POSTAL_CODE]))) {
    errors[RXFIELD_COMPANY_MAPPING_POSTAL_CODE] = LocalizedString.common.errMsgMustaNumber;
  }
  return errors;
};

export const rxformValidateInfoMapping = (values) => {
  const requiredFields = [
    RXFIELD_INFO_MAPPING_MAPPING_OPERATOR,
    RXFIELD_INFO_MAPPING_NEW_NAME,
    RXFIELD_INFO_MAPPING_ORDER,
    RXFIELD_INFO_MAPPING_PHRASE,
    RXFIELD_INFO_MAPPING_TYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
