import { change } from 'redux-form';
import { uploadingConfigItemValueFile } from '../simple-action';
import { uploadConfigItemValueFile } from '../../../helper';
import { RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_VALUE } from '../../../constant';

export default (body) => async (dispatch, getState) => {
  try {
    dispatch(uploadingConfigItemValueFile(true));

    const { token } = getState().authentication;

    const data = await uploadConfigItemValueFile(body, token);
    dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_VALUE, data.path));
  } finally {
    dispatch(uploadingConfigItemValueFile(false));
  }
};
