import { reset } from 'redux-form';
import {
  addingTokenRequest, clearTokenRequests, setAlertErrorMessage,
} from '../simple-action';
import { RXFORM_TOKEN_REQUEST } from '../../../constant';
import { addTokenRequest } from '../../../helper';
import downloadTokenRequestsAsync from './downloadTokenRequestsAsync';

export default (
  quantity, validFrom, validUntil,
) => async (dispatch, getState) => {
  try {
    dispatch(addingTokenRequest(true));

    const { token } = getState().authentication;
    const { selectedVoucherId } = getState().uiTokenRequest;

    await addTokenRequest(
      selectedVoucherId, Number(quantity), validFrom, validUntil,
      token,
    );

    dispatch(reset(RXFORM_TOKEN_REQUEST));
    dispatch(clearTokenRequests());
    dispatch(downloadTokenRequestsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingTokenRequest(false));
  }
};
