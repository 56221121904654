import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_CATALOG_COLOR,
  RXFIELD_CATALOG_DESCRIPTION,
  RXFIELD_CATALOG_MEDIA,
  RXFIELD_CATALOG_MODEL,
  RXFIELD_CATALOG_TRIM,
  RXFIELD_CATALOG_YEAR,
  RXFIELD_MODEL_IMAGE,
  RXFIELD_MODEL_NAME,
} from './constant';

export const rxformValidateModel = (values) => {
  const requiredFields = [
    RXFIELD_MODEL_NAME,
    RXFIELD_MODEL_IMAGE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCar = (values) => {
  const requiredFields = [
    RXFIELD_CATALOG_COLOR,
    RXFIELD_CATALOG_DESCRIPTION,
    RXFIELD_CATALOG_MEDIA,
    RXFIELD_CATALOG_MODEL,
    RXFIELD_CATALOG_TRIM,
    RXFIELD_CATALOG_YEAR,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
