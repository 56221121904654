import {
  enablingDisablingDocumentGeneratorTemplate, setAlertErrorMessage,
  setDocumentGeneratorTemplateSelectedOrderBy, setDocumentGeneratorTemplateSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableDocumentGeneratorTemplate } from '../../../helper';
import downloadDocumentGeneratorTemplatesAsync from './downloadDocumentGeneratorTemplatesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingDocumentGeneratorTemplate(true));

    const { token } = getState().authentication;
    const { data } = getState().templates;
    const { tappedId } = getState().uiTemplate;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableDocumentGeneratorTemplate(tappedId, status, token);

    dispatch(setDocumentGeneratorTemplateSelectedPageSize(20));
    dispatch(setDocumentGeneratorTemplateSelectedOrderBy(
      INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE,
    ));
    dispatch(downloadDocumentGeneratorTemplatesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingDocumentGeneratorTemplate(false));
  }
};
