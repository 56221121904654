import { downloadingSummaryThisMonthTrends, setSummaryThisMonthTrends } from '../simple-action';
import { downloadSummaryTrends } from '../../../helper';
import { PACKET_SUMMARY_CURRENT_PERIOD, PACKET_SUMMARY_GROUPING_BY_DATE } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryThisMonthTrends(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryTrends(PACKET_SUMMARY_CURRENT_PERIOD,
      PACKET_SUMMARY_GROUPING_BY_DATE, token);

    dispatch(setSummaryThisMonthTrends(result));
  } finally {
    dispatch(downloadingSummaryThisMonthTrends(false));
  }
};
