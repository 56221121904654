import {
  addNotificationTask, downloadingDeletingNotificationTask, setFunctionalPageMode,
} from '../simple-action';
import { downloadNotificationTask } from '../../../helper';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingNotificationTask(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiNotificationTask;

    const task = await downloadNotificationTask(tappedId, token);
    dispatch(addNotificationTask(task));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingNotificationTask(false));
  }
};
