import { setResyncConfigs, resyncingConfigs } from '../simple-action';
import { resyncConfigs } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingConfigs(true));

    const { token } = getState().authentication;

    const data = await resyncConfigs(token);

    dispatch(setResyncConfigs({}));
    dispatch(setResyncConfigs(data));
  } finally {
    dispatch(resyncingConfigs(false));
  }
};
