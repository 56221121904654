import { downloadPromotedMerchant } from '../../../helper';
import {
  addPromotedMerchant, downloadingPromotedMerchant,
  setPromotedMerchantSelectedIsPromoted,
} from '../simple-action';
import { approval } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingPromotedMerchant(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPromotedMerchant;

    const promotedMerchant = await downloadPromotedMerchant(tappedId, token);

    dispatch(addPromotedMerchant(promotedMerchant));
    dispatch(setPromotedMerchantSelectedIsPromoted(promotedMerchant.isPromoted
      ? approval[0] : approval[1]));
  } finally {
    dispatch(downloadingPromotedMerchant(false));
  }
};
