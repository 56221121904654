import { connect } from 'react-redux';
import { change } from 'redux-form';
import { isEmpty } from 'lodash';
import PDFList from './pdf-list.presentation';
import {
  resetColorPicker,
  generateDocumentTemplatePreviewAsync, setAnnotationDesignJsonVisibility,
  setAlertErrorMessage, setCreateAnnotationDialogPdfType,
  setCreateAnnotationDialogVisibility, deleteAnnotationAsync,
} from '../../redux/action';
import {
  DIALOG_TYPE_ADD, PDF_TYPE_IMAGE, PDF_TYPE_QR_CODE, PDF_TYPE_TEXT,
  RXFIELD_ANNOTATION_DESIGNER_ROTATION, RXFORM_ANNOTATION_DESIGNER,
} from '../../constant';
import { clearAnnotationDesigns } from '../../redux/action/simple-action';
import LocalizedString from '../../../../localization';
import { logoutAsync } from '../../../../redux/action';
import { downloadMyConfigItemsAsync } from '../../../configuration/redux/action';

const mapStateToProps = (state) => ({
  selectedImage: state.uiAnnotationDesign.selectedImage,
  templatePdf: state.uiUploadPdf.templatePdf,
  pageMode: state.uiFunctionalPage.pageMode,
  disableClear: state.annotationDesigns.data.length === 0,
  addingEditing: state.uiAnnotationDesign.addingEditing,
  myConfigItems: state.myConfigItems,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateNewText: (title) => {
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_ADD, title));
    dispatch(setCreateAnnotationDialogPdfType(PDF_TYPE_TEXT));
    dispatch(resetColorPicker());
    dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_ROTATION, '0'));
  },
  onCreateNewQrCode: (title) => {
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_ADD, title));
    dispatch(setCreateAnnotationDialogPdfType(PDF_TYPE_QR_CODE));
    dispatch(resetColorPicker());
  },
  onCreateNewImage: (title) => {
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_ADD, title));
    dispatch(setCreateAnnotationDialogPdfType(PDF_TYPE_IMAGE));
    dispatch(resetColorPicker());
  },
  onConfirmPressed: async (message, history, myConfigItems) => {
    if (message === LocalizedString.common.msgLogoutConfirmation) {
      if (isEmpty(myConfigItems)) {
        try {
          await dispatch(downloadMyConfigItemsAsync());
        } catch (error) {
          dispatch(setAlertErrorMessage(error));
        }
      }
      dispatch(logoutAsync(history.push, message))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      dispatch(deleteAnnotationAsync())
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    }
  },
  onShowJSONPressed: () => {
    dispatch(setAnnotationDesignJsonVisibility(true));
  },
  onPreviewClicked: () => {
    dispatch(generateDocumentTemplatePreviewAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onClearClicked: () => {
    dispatch(clearAnnotationDesigns());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PDFList);
