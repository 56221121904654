import {
  ADDING_EDITING_USER, DISABLING_TOKEN, DOWNLOADING_USERS, SET_USER_SELECTED_ORDER_BY,
  SET_USER_SEARCH_TEXT, SET_USER_SELECTED_PAGE_SIZE, SET_USER_SELECTED_ROLE, SET_USER_TAPPED_ID,
  SET_USER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, RESYNCING_USER,
} from '../action';
import { INITIAL_ORDER_BY_USERS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloading: false,
  disablingToken: false,
  resyncing: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  selectedRole: [],
  orderBy: INITIAL_ORDER_BY_USERS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_USERS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_USER:
      return { ...state, addingEditing: action.status };
    case DISABLING_TOKEN:
      return { ...state, disablingToken: action.status };
    case SET_USER_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_USER_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_USER_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_USER_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_USER_SELECTED_ROLE:
      return { ...state, selectedRole: action.option };
    case SET_USER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case RESYNCING_USER:
      return { ...state, resyncing: action.status };
    default: return state;
  }
};
