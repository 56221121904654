import { downloadingSummaryPreviousYearLocations, setSummaryPreviousYearLocations } from '../simple-action';
import { downloadSummaryLocations } from '../../../helper';
import { PACKET_SUMMARY_PREVIOUS_PERIOD, PACKET_SUMMARY_GROUPING_BY_MONTH } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryPreviousYearLocations(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryLocations(PACKET_SUMMARY_PREVIOUS_PERIOD,
      PACKET_SUMMARY_GROUPING_BY_MONTH, token);

    dispatch(setSummaryPreviousYearLocations(result));
  } finally {
    dispatch(downloadingSummaryPreviousYearLocations(false));
  }
};
