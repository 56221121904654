import { reset } from 'redux-form';
import {
  addingEditingActivityType, clearActivityTypes,
  setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addActivityType, editActivityType } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_ACTIVITY_TYPE } from '../../../constant';
import downloadActivityTypesAsync from './downloadActivityTypesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingActivityType(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiActivityType;

    if (tappedId) {
      await editActivityType(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addActivityType(name, description, token);
    }

    dispatch(reset(RXFORM_ACTIVITY_TYPE));
    dispatch(clearActivityTypes());
    dispatch(downloadActivityTypesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingActivityType(false));
  }
};
