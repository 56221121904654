import {
  REST_URL_COVID_DATAS, REST_URL_ADD_EDIT_COVID_DATA,
  REST_URL_VIEW_DELETE_COVID_DATA,
  REST_URL_COVID_DATA_TOTAL_SUMMARY,
  REST_URL_COVID_DATA_TREND_SUMMARY,
  REST_URL_SAVE_FILE,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendDeleteRequest, sendPostRequest, sendPutRequest,
  transformQueryResult, convertArrToObj, sendGetFileRequest,
} from '../../helper';

export * from '../../helper';

export const downloadCovidDatas = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_COVID_DATAS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_COVID_DATAS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};


export const saveCovidData = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_COVID_DATAS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const addCovidData = async (
  date, employeeStatus, hospitalized,
  selfIsolation, death, recovered,
  vaccinated, rtAntibody, rtAntigen,
  genose, serologi, pcr, token) => {
  const body = {
    date,
    employeeStatus,
    hospitalized,
    selfIsolation,
    death,
    recovered,
    vaccinated,
    rtAntibody,
    rtAntigen,
    genose,
    serologi,
    pcr,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_COVID_DATA, body, token);
};

export const editCovidData = async (
  id, date, employeeStatus, hospitalized,
  selfIsolation, death, recovered,
  vaccinated, rtAntibody, rtAntigen,
  genose, serologi, pcr, token) => {
  const body = {
    id,
    date,
    employeeStatus,
    hospitalized,
    selfIsolation,
    death,
    recovered,
    vaccinated,
    rtAntibody,
    rtAntigen,
    genose,
    serologi,
    pcr,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_COVID_DATA, body, token);
};

export const downloadCovidData = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_COVID_DATA.replace(/\{id\}/, id),
    token);
  return response;
};

export const deleteCovidData = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_COVID_DATA.replace(/\{id\}/, id), token);
};

export const downloadCovidDataTotalSummary = async (summaryPeriod, employeeStatus, token) => {
  const url = REST_URL_COVID_DATA_TOTAL_SUMMARY
    .replace(/\{summaryPeriod\}/, summaryPeriod)
    .replace(/\{employeeStatus\}/, employeeStatus);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadCovidDataTrendSummary = async (summaryPeriod, employeeStatus, token) => {
  const url = REST_URL_COVID_DATA_TREND_SUMMARY
    .replace(/\{summaryPeriod\}/, summaryPeriod)
    .replace(/\{employeeStatus\}/, employeeStatus);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'date');
};
