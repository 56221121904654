import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    pointMenu: {
      title: 'Point',
    },
    pointPage: {
      title: 'Point',

      labelLabel: 'Label',
      labelAmount: 'Amount',
      labelUser: 'User',
      labelNo: 'No',
      labelMerchantName: 'Merchant Name',
      labelCreatedDate: 'Created Date',

      placeholderUser: 'User',
      placeholderAmount: 'Amount',
      placeholderLabel: 'Label',
      placeholderMerchantId: 'Merchant ID',
      placeholderMerchantName: 'Merchant Name',
      placeholderTags: 'Tags',
      placeholderValidUntil: 'Valid Until',
      placeholderExpiredPointAlreadyProcessed: 'Expired Point Already Processed',
      placeholderMerchantReferenceID: 'Merchant Reference ID',
      placeholderIsVoided: 'Is Voided',
      placeholderVoidedOn: 'Voided On',
      placeholderVoidReferenceId: 'Void Reference ID',
      placeholderVoidNotes: 'Void Notes',
      placeholderVoidedBy: 'Voided By',
      placeholderCreatedDate: 'Created Date',
    },
    pointSubmissionPage: {
      title: 'Point Submission',

      labelLabel: 'Label',
      labelAmount: 'Amount',
      labelUser: 'User',
      labelNo: 'No',
      labelMerchantName: 'Merchant Name',
      labelCreatedDate: 'Created Date',
      labelSubmissionStatus: 'Request Status',

      placeholderUser: 'User',
      placeholderAmount: 'Amount',
      placeholderLabel: 'Label',
      placeholderMerchantId: 'Merchant ID',
      placeholderMerchantName: 'Merchant Name',
      placeholderTags: 'Tags',
      placeholderValidUntil: 'Valid Until',
      placeholderExpiredPointAlreadyProcessed: 'Expired Point Already Processed',
      placeholderMerchantReferenceID: 'Merchant Reference ID',
      placeholderSubmissionStatus: 'Request Status',
      placeholderSubmissionStatusNotes: 'Request Status Notes',
      placeholderCallbackUrl: 'Callback URL',
      placeholderCreatedDate: 'Created Date',
    },
    purchaseRequestPage: {
      title: 'Purchase Request',

      labelLabel: 'Label',
      labelAmount: 'Amount',
      labelUser: 'User',
      labelNo: 'No',
      labelMerchantName: 'Merchant Name',
      labelCreatedDate: 'Created Date',
      labelRequestStatus: 'Request Status',
      labelProductCode: 'Product Code',
      labelProductName: 'Product Name',
      labelQuantity: 'Quantity',
      labelPrice: 'Price',
      labelTotalPrice: 'Total Price',

      placeholderAmount: 'Amount',
      placeholderLabel: 'Label',
      placeholderMerchantID: 'Merchant ID',
      placeholderMerchantName: 'Merchant Name',
      placeholderMerchantReferenceID: 'Merchant Reference ID',
      placeholderCallbackUrl: 'Callback URL',
      placeholderRequestStatus: 'Request Status',
      placeholderRequestStatusNotes: 'Request Status Notes',
      placeholderUserID: 'User ID',
      placeholderUserFullName: 'User Fullname',
      placeholderTags: 'Tags',
      placeholderCreatedDate: 'Created Date',
      placeholderItems: 'Items',
    },
    pointTransactionPage: {
      title: 'Transaction',

      labelLabel: 'Label',
      labelAmount: 'Amount',
      labelUser: 'User',
      labelNo: 'No',
      labelMerchantName: 'Merchant Name',
      labelEffectiveDate: 'Effective Date',
      labelTransactionNumber: 'Transaction Number',
      labelTransactionItems: 'Transaction Items',
      labelProductName: 'Product Name',
      labelProductCode: 'Product Code',
      labelQuantity: 'Quantity',
      labelIndividualPrice: 'Individual Price',
      labelTotalPrice: 'Total Price',

      placeholderUser: 'User',
      placeholderAmount: 'Amount',
      placeholderLabel: 'Label',
      placeholderMerchantId: 'Merchant ID',
      placeholderMerchantName: 'Merchant Name',
      placeholderTags: 'Tags',
      placeholderMerchantReferenceID: 'Merchant Reference ID',
      placeholderCreatedDate: 'Created Date',
      placeholderTransactionNumber: 'Transaction Number',
      placeholderEffectiveDate: 'Effective Date',
      placeholderType: 'Type',
      placeholderReferenceId: 'Reference ID',
    },
    achievementPage: {
      title: 'Achievement',

      labelSelectUser: 'Select a User',
      labelAchievement: 'Achievement',
      labelRank: 'Rank',
      labelBalance: 'Balance',
      labelTransactionHistory: 'Transaction History',
      labelNo: 'No',
      labelTransactionNumber: 'Transaction Number',
      labelAmount: 'Amount',
      labelLabel: 'Label',
      labelMerchantName: 'Merchant Name',
      labelTransactionType: 'Transaction Type',
      labelDate: 'Date',

      placeholderProfilePicture: 'Profile Picture',
      placeholderUsername: 'Username',
      placeholderFullName: 'Full Name',
      placeholderPhone: 'Phone',
      placeholderEmail: 'Email',
    },
    leaderBoardPage: {
      title: 'Leader Board',

      labelNo: 'No',
      labelUser: 'User',
      labelPoints: 'Points',
      labelRank: 'Rank',
      labelStartRank: 'Start Rank',
      labelCount: 'Count',
      labelStartPeriod: 'Start Period',
      labelEndPeriod: 'End Period',

      placeholderStartRank: 'Rank',
      placeholderCount: 'Count',
      placeholderStartPeriod: 'Start Period',
      placeholderEndPeriod: 'End Period',
    },
    voidPointRequestPage: {
      title: 'Void Point Request',

      labelNo: 'No',
      labelReason: 'Reason',
      labelMerchantName: 'Merchant Name',
      labelMerchantReferenceId: 'Merchant Reference ID',
      labelPointName: 'Point Name',
      labelPointAmount: 'Point Amount',
      labelRequestStatus: 'Request Status',
      labelRequestStatusNote: 'Request Status Note',
      labelCreatedDate: 'Created Date',
      labelPointId: 'Point ID',
      labelUserId: 'User ID',
      labelUserFullname: 'User Name',
      labelRequestor: 'Requestor',
      labelCallbackUrl: 'Callback URL',

      placeholderReason: 'Reason',
      placeholderMerchantName: 'Merchant Name',
      placeholderMerchantReferenceId: 'Merchant Reference ID',
      placeholderPointName: 'Point Name',
      placeholderPointAmount: 'Point Amount',
      placeholderRequestStatus: 'Request Status',
      placeholderRequestStatusNote: 'Request Status Note',
      placeholderCreatedDate: 'Created Date',
      placeholderPointId: 'Point ID',
      placeholderUserId: 'User ID',
      placeholderUserFullname: 'User Name',
      placeholderRequestor: 'Requestor',
      placeholderCallbackUrl: 'Callback URL',

      buttonCaptionCreateNew: 'Create New Void Request',
    },
  },
  id: {
    pointMenu: {
      title: 'Poin',
    },
    pointPage: {
      title: 'Poin',

      labelLabel: 'Label',
      labelUser: 'Pengguna',
      labelAmount: 'Jumlah',
      labelNo: 'No',
      labelMerchantName: 'Nama Penjual',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUser: 'Pengguna',
      placeholderAmount: 'Jumlah',
      placeholderLabel: 'Label',
      placeholderMerchantId: 'ID Penjual',
      placeholderMerchantName: 'Nama Penjual',
      placeholderTags: 'Tag',
      placeholderValidUntil: 'Berlaku Hingga',
      placeholderExpiredPointAlreadyProcessed: 'Poin Kadaluarsa Telah Diproses',
      placeholderMerchantReferenceID: 'ID Referensi Penjual',
      placeholderIsVoided: 'Telah Hangus',
      placeholderVoidedOn: 'Hangus Pada',
      placeholderVoidReferenceId: 'ID Referensi Hangus',
      placeholderVoidNotes: 'Catatan Hangus',
      placeholderVoidedBy: 'Dihanguskan Oleh',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    pointSubmissionPage: {
      title: 'Pengajuan Poin',

      labelLabel: 'Label',
      labelUser: 'Pengguna',
      labelAmount: 'Jumlah',
      labelNo: 'No',
      labelMerchantName: 'Nama Penjual',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelSubmissionStatus: 'Status Pengajuan',

      placeholderUser: 'Pengguna',
      placeholderAmount: 'Jumlah',
      placeholderLabel: 'Label',
      placeholderMerchantId: 'ID Penjual',
      placeholderMerchantName: 'Nama Penjual',
      placeholderTags: 'Tag',
      placeholderValidUntil: 'Berlaku Hingga',
      placeholderExpiredPointAlreadyProcessed: 'Poin Kadaluarsa Telah Diproses',
      placeholderMerchantReferenceID: 'ID Referensi Penjual',
      placeholderSubmissionStatus: 'Status Pengajuan',
      placeholderSubmissionStatusNotes: 'Catatan Status Pengajuan',
      placeholderCallbackUrl: 'URL Panggilan Balik',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    purchaseRequestPage: {
      title: 'Permohonan Pembelian',

      labelLabel: 'Label',
      labelAmount: 'Jumlah',
      labelUser: 'Pengguna',
      labelNo: 'No',
      labelMerchantName: 'Nama Penjual',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelRequestStatus: 'Status Permohonan',
      labelProductCode: 'Kode Produk',
      labelProductName: 'Nama Produk',
      labelQuantity: 'Jumlah',
      labelPrice: 'Harga',
      labelTotalPrice: 'Total Harga',

      placeholderAmount: 'Jumlah',
      placeholderLabel: 'Label',
      placeholderMerchantID: 'ID Penjual',
      placeholderMerchantName: 'Nama Penjual',
      placeholderMerchantReferenceID: 'ID Referensi Penjual',
      placeholderCallbackUrl: 'URL Panggilan Balik',
      placeholderRequestStatus: 'Status Permohonan',
      placeholderRequestStatusNotes: 'Nota Status Permohonan',
      placeholderUserID: 'ID Pengguna',
      placeholderUserFullName: 'Nama Lengkap Pengguna',
      placeholderTags: 'Tag',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderItems: 'Barang',
    },
    pointTransactionPage: {
      title: 'Transaksi',

      labelLabel: 'Label',
      labelUser: 'Pengguna',
      labelAmount: 'Jumlah',
      labelNo: 'No',
      labelMerchantName: 'Nama Penjual',
      labelEffectiveDate: 'Tanggal Berlaku',
      labelTransactionNumber: 'Nomor Transaksi',
      labelTransactionItems: 'Item Transaksi',
      labelProductName: 'Nama Produk',
      labelProductCode: 'Kode Produk',
      labelQuantity: 'Kuantitas',
      labelIndividualPrice: 'Harga Satuan',
      labelTotalPrice: 'Total Harga',

      placeholderUser: 'Pengguna',
      placeholderAmount: 'Jumlah',
      placeholderLabel: 'Label',
      placeholderMerchantId: 'ID Penjual',
      placeholderMerchantName: 'Nama Penjual',
      placeholderTags: 'Tag',
      placeholderMerchantReferenceID: 'ID Referensi Penjual',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderTransactionNumber: 'Nomor Transaksi',
      placeholderEffectiveDate: 'Tanggal Efektif',
      placeholderType: 'Tipe',
      placeholderReferenceId: 'ID Referensi',
    },
    achievementPage: {
      title: 'Pencapaian',

      labelSelectUser: 'Pilih Pengguna',
      labelAchievement: 'Pencapaian',
      labelRank: 'Peringkat',
      labelBalance: 'Saldo',
      labelTransactionHistory: 'Riwayat Transaksi',
      labelNo: 'No',
      labelTransactionNumber: 'Nomor Transaksi',
      labelAmount: 'Jumlah',
      labelLabel: 'Label',
      labelMerchantName: 'Nama Penjual',
      labelTransactionType: 'Tipe Transaksi',
      labelDate: 'Tanggal',

      placeholderProfilePicture: 'Foto Profil',
      placeholderUsername: 'Nama Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderPhone: 'Telepon',
      placeholderEmail: 'Email',
    },
    leaderBoardPage: {
      title: 'Papan Peringkat',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelPoints: 'Poin',
      labelRank: 'Peringkat',
      labelStartRank: 'Peringkat Awal',
      labelCount: 'Jumlah',
      labelStartPeriod: 'Periode Awal',
      labelEndPeriod: 'Periode Akhir',

      placeholderStartRank: 'Peringkat',
      placeholderCount: 'Jumlah',
      placeholderStartPeriod: 'Periode Awal',
      placeholderEndPeriod: 'Periode Akhir',
    },
    voidPointRequestPage: {
      title: 'Permohonan Pembatalan Poin',

      labelNo: 'No',
      labelReason: 'Alasan',
      labelMerchantName: 'Nama Penjual',
      labelMerchantReferenceId: 'Referensi ID Penjual',
      labelPointName: 'Nama Poin',
      labelPointAmount: 'Jumlah Poin',
      labelRequestStatus: 'Status Permohonan',
      labelRequestStatusNote: 'Catatan Status Permohonan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelPointId: 'ID Poin',
      labelUserId: 'ID Pengguna',
      labelUserFullname: 'Nama Pengguna',
      labelUser: 'Pengguna',
      labelRequestor: 'Pemohon',
      labelCallbackUrl: 'Callback URL',

      placeholderReason: 'Alasan',
      placeholderMerchantName: 'Nama Penjual',
      placeholderMerchantReferenceId: 'Referensi ID Penjual',
      placeholderPointName: 'Nama Poin',
      placeholderPointAmount: 'Jumlah Poin',
      placeholderRequestStatus: 'Status Permohonan',
      placeholderRequestStatusNote: 'Catatan Status Permohonan',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderPointId: 'ID Poin',
      placeholderUserId: 'ID Pengguna',
      placeholderUserFullname: 'Nama Pengguna',
      placeholderUser: 'Pengguna',
      placeholderRequestor: 'Pemohon',
      placeholderCallbackUrl: 'Callback URL',

      buttonCaptionCreateNew: 'Buat Permohonan Pembatalan Baru',
    },
  },
});

export default LocalizedString;
