import {
  DOWNLOADING_HISTORIES, SET_HISTORY_SELECTED_PAGE_SIZE, SET_HISTORY_TAPPED_ID,
  SET_HISTORY_SEARCH_TEXT, SET_HISTORY_SELECTED_ORDER_BY, SET_HISTORY_SELECTED_COMPANY,
  SET_HISTORY_SELECTED_DEPARTMENT, SET_HISTORY_SELECTED_DIVISION, SET_HISTORY_SELECTED_EMPLOYEE,
  SAVING_HISTORIES, SET_HISTORY_EMPLOYEE_SEARCH_TEXT, DOWNLOADING_EMPLOYEES,
  SET_HISTORY_PROFILE_DETAIL, CLEAR_HISTORY_PROFILE_DETAIL, SET_HISTORY_SELECTED_BRANCH,
} from '../action';
import { INITIAL_ORDER_BY_HISTORIES } from '../../constant';

const initialState = {
  downloading: false,
  downloadingEmployees: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  selectedCompany: '',
  selectedDepartment: '',
  selectedDivision: '',
  selectedEmployee: '',
  selectedBranch: '',
  employeeSearchText: '',
  orderBy: INITIAL_ORDER_BY_HISTORIES,
  profileDetail: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_HISTORIES:
      return { ...state, downloading: action.status };
    case SET_HISTORY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_HISTORY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_HISTORY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_HISTORY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_HISTORY_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.option };
    case SET_HISTORY_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartment: action.option };
    case SET_HISTORY_SELECTED_DIVISION:
      return { ...state, selectedDivision: action.option };
    case SET_HISTORY_SELECTED_EMPLOYEE:
      return { ...state, selectedEmployee: action.option };
    case SET_HISTORY_SELECTED_BRANCH:
      return { ...state, selectedBranch: action.option };
    case SAVING_HISTORIES:
      return { ...state, saving: action.status };
    case SET_HISTORY_EMPLOYEE_SEARCH_TEXT:
      return { ...state, employeeSearchText: action.text };
    case DOWNLOADING_EMPLOYEES:
      return { ...state, downloadingEmployees: action.status };
    case SET_HISTORY_PROFILE_DETAIL:
      return { ...state, profileDetail: action.data };
    case CLEAR_HISTORY_PROFILE_DETAIL:
      return { ...state, profileDetail: {} };
    default: return state;
  }
};
