import { addVoidPointRequest, downloadingVoidPointRequest } from '../simple-action';
import { downloadVoidPointRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingVoidPointRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoidPointRequest;

    const purchaseRequest = await downloadVoidPointRequest(tappedId, token);
    dispatch(addVoidPointRequest(purchaseRequest));
  } finally {
    dispatch(downloadingVoidPointRequest(false));
  }
};
