import {
  addPushNotification, downloadingDeletingPushNotification, setFunctionalPageMode,
} from '../simple-action';
import { downloadPushNotification } from '../../../helper';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPushNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPushNotification;

    const task = await downloadPushNotification(tappedId, token);
    dispatch(addPushNotification(task));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingPushNotification(false));
  }
};
