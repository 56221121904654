import { SET_SURVEY_QUESTION_PARENTS, CLEAR_SURVEY_QUESTION_PARENTS } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SURVEY_QUESTION_PARENTS: {
      return {
        data: action.data.data,
        meta: action.data.meta,
      };
    }
    case CLEAR_SURVEY_QUESTION_PARENTS:
      return initialState;
    default:
      return state;
  }
};
