import moment from 'moment';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { DATE_FORMAT } from '../../../attendance';
import {
  MENUID_COVID_DASHBOARD, PERIOD_FILTER, RXFORM_COVID_DASHBOARD,
  RXFIELD_COVID_DASHBOARD_EMPLOYEE_STATUS_FILTER,
  RXFIELD_COVID_DASHBOARD_SUMMARY_PERIOD_FILTER,
} from '../../constant';
import { sortAsc } from '../../helper';
import {
  setActiveSideMenuItem, setCovidDashboardActiveTab, setAlertErrorMessage,
  downloadCovidDataTotalSummaryAsync, downloadCovidDataTrendSummaryAsync,
  setCovidDashboardSelectedPeriod, setCovidDashboardSelectedEmployeeStatus,
} from '../../redux/action';
import CovidDashboardPage from './covid-dashboard.presentation';

const getInitialValues = (state) => {
  const { uiCovidDashboard } = state;
  const { selectedSummaryPeriod, selectedEmployeeStatus } = uiCovidDashboard;
  return { employeeStatus: selectedEmployeeStatus, summaryPeriod: selectedSummaryPeriod };
};

const mapStateToProps = (state) => ({
  covidTrendSummary: Object.values(state.covidTrendSummary.data)
    .sort((a, b) => sortAsc(a.date, b.date))
    .map((item) => ({ ...item, date: moment(item.date).format(DATE_FORMAT) })),
  covidTotalSummary: Object.entries(state.covidTotalSummary.data)
    .map((pair) => ({ label: pair[0], value: `${pair[1]}` })),
  activeTab: state.uiCovidDashboard.activeTab,
  downloadingTotalSummary: state.uiCovidDashboard.downloadingTotalSummary,
  downloadingTrendSummary: state.uiCovidDashboard.downloadingTrendSummary,
  initialValues: getInitialValues(state),
});
const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_COVID_DASHBOARD));
    dispatch(setCovidDashboardActiveTab(0));
    dispatch(setCovidDashboardSelectedPeriod(PERIOD_FILTER[0].value));
    dispatch(downloadCovidDataTotalSummaryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onTabPressed: (index) => {
    dispatch(setCovidDashboardActiveTab(index));
    if (index === 0) {
      dispatch(setCovidDashboardSelectedPeriod(PERIOD_FILTER[0].value));
      dispatch(downloadCovidDataTotalSummaryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
    if (index === 1) {
      dispatch(setCovidDashboardSelectedPeriod(PERIOD_FILTER[1].value));
      dispatch(downloadCovidDataTrendSummaryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onEmployeeStatusFilterOptionSelected: (option, activeTab) => {
    dispatch(setCovidDashboardSelectedEmployeeStatus(option.value));
    dispatch(change(
      RXFORM_COVID_DASHBOARD, RXFIELD_COVID_DASHBOARD_EMPLOYEE_STATUS_FILTER, option,
    ));
    if (activeTab === 0) {
      dispatch(downloadCovidDataTotalSummaryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(downloadCovidDataTrendSummaryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onSummaryPeriodFilterOptionSelected: (option, activeTab) => {
    dispatch(setCovidDashboardSelectedPeriod(option.value));
    dispatch(change(
      RXFORM_COVID_DASHBOARD, RXFIELD_COVID_DASHBOARD_SUMMARY_PERIOD_FILTER, option,
    ));
    if (activeTab === 0) {
      dispatch(downloadCovidDataTotalSummaryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(downloadCovidDataTrendSummaryAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CovidDashboardPage);
