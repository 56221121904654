import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, Box, Button, Avatar, Divider, CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { ThumbUpOutline, ThumbDownOutline, HeartOutline } from 'mdi-material-ui';
import uuid from 'uuid';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { COLOR_PRIMARY } from '../../constant';

const useStyles = makeStyles(() => ({
  commentContainer: {
    margin: '20px 0px 0px 0px',
  },
  repliesContainer: {
    margin: '0px 0px 20px 40px',
  },
  container: {
    margin: '0px 0px 10px 10px',
  },
  fullName: {
    fontWeight: 600,
    margin: '0px 10px 0px 0px',
  },
  commentText: {
    margin: '5px 0px 0px 0px',
  },
  viewReplyButton: {
    color: COLOR_PRIMARY,
    margin: '0px 0px 10px 40px',
  },
  viewMoreRepliesButton: {
    color: COLOR_PRIMARY,
    margin: '0px 0px 10px 0px',
  },
  divider: {
    margin: '10px 0px 0px 0px',
  },
  reactionContainer: {
    margin: '10px 0px 0px 0px',
  },
  reactionCount: {
    margin: '0px 0px 0px 10px',
  },
  reaction: {
    margin: '0px 10px 0px 0px',
  },
  replyContainer: {
    margin: '0px 0px 10px 0px',
  },
}));

const getTimestamp = (date) => {
  const createdDate = toMoment(date);
  const currDate = toMoment();
  const diff = createdDate.diff(currDate, 'hour');
  if (diff > -24) {
    return moment.duration(diff, 'hours').humanize(true);
  }
  const diffYear = createdDate.diff(currDate, 'year');
  if (diffYear < 0) {
    return createdDate.format('DD MMM \'YY');
  } return createdDate.format('DD MMM');
};

const CommentBox = ({
  item, token, useDivider, reactionMode,
  getRepliesAsync, getReactionsAsync,
  avatar, getUsersProfilePictureAsync,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [replies, setReplies] = useState([]);
  const [reactions, setReactions] = useState({});
  const [showReplies, setShowReplies] = useState(false);
  const [repliesDetail, setRepliesDetail] = useState({});
  const [profilePictures, setProfilePictures] = useState({});

  const getReactions = async () => {
    const reactionsNew = await getReactionsAsync(item.reactionId, token);
    setReactions(reactionsNew);
  };

  const renderReaction = () => {
    if (Object.keys(reactions).length === 0 && reactionMode !== 'none') {
      getReactions();
    }
    if (reactionMode === 'updown') {
      return (
        <Box display="flex" flexDirection="row" className={classes.reactionContainer}>
          <Box display="flex" flexDirection="row" className={classes.reaction}>
            <ThumbUpOutline />
            <Typography className={classes.reactionCount}>
              {reactions.thumbsUpCount}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <ThumbDownOutline />
            <Typography className={classes.reactionCount}>
              {reactions.thumbsDownCount}
            </Typography>
          </Box>
        </Box>
      );
    } if (reactionMode === 'like') {
      return (
        <Box display="flex" flexDirection="row" className={classes.reactionContainer}>
          <HeartOutline />
          <Typography className={classes.reactionCount}>
            {reactions.likeCount}
          </Typography>
        </Box>
      );
    } return null;
  };

  const comment = (detail, avatarSrc) => (
    <Box
      display="flex"
      flexDirection="row"
      spacing={1}
      key={uuid()}
    >
      <Avatar src={avatarSrc} />
      <Box className={classes.container}>
        <Box display="flex" flexDirection="row" spacing={1} alignItems="center">
          <Typography className={classes.fullName}>
            {detail.user.fullName}
          </Typography>

          <Typography>
            {getTimestamp(detail.createdDate)}
          </Typography>
        </Box>

        <Typography className={classes.commentText}>
          {detail.comment}
        </Typography>

        {renderReaction()}
      </Box>
    </Box>
  );

  const getReplies = async (currPage) => {
    try {
      setLoading(true);
      const response = await getRepliesAsync(item.id, token, currPage);
      const { data, ...details } = response;
      setRepliesDetail(details);
      const repliesTemp = currPage > 0 ? [...replies, ...data] : data || [];
      setReplies(repliesTemp || []);
      const responseProfilePictures = await getUsersProfilePictureAsync(repliesTemp, token);
      setProfilePictures(responseProfilePictures);
    } finally {
      setLoading(false);
    }
  };

  const onViewReplies = async () => {
    if (!showReplies && !loading) {
      if (replies.length === 0) {
        getReplies(0);
      }
      setShowReplies(true);
    } else { setShowReplies(false); }
  };

  const buttonText = showReplies ? LocalizedString.common.labelHideCommentReply
    : LocalizedString.common.labelViewCommentReply;

  const renderReplies = () => {
    if (!loading) {
      return replies.map(
        (rep) => (
          <div className={classes.replyContainer} key={uuid()}>
            {comment(rep, profilePictures[rep.user.id].profilePicture || '')}
          </div>
        ),
      );
    } return (
      <CircularProgress color="inherit" />
    );
  };

  return (
    <div className={classes.commentContainer} key={uuid()}>
      {comment(item, avatar)}

      {item.replyCount !== 0 ? (
        <Button size="small" className={classes.viewReplyButton} onClick={onViewReplies}>
          {buttonText.replace(/\{replyCount\}/, item.replyCount)}
        </Button>
      )
        : null}

      {replies.length > 0 && showReplies && (
        <div className={classes.repliesContainer}>
          {renderReplies()}

          {repliesDetail?.hasNext && (
          <Button
            size="small"
            className={classes.viewMoreRepliesButton}
            onClick={() => getReplies(repliesDetail.currentPage)}
          >
            {LocalizedString.common.labelMoreReplies}
          </Button>
          )}
        </div>
      )}

      {useDivider ? (<Divider className={classes.divider} />) : null}
    </div>
  );
};
export default CommentBox;

CommentBox.propTypes = {
  useDivider: PropTypes.bool,
  item: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  getRepliesAsync: PropTypes.func.isRequired,
  getReactionsAsync: PropTypes.func.isRequired,
  getUsersProfilePictureAsync: PropTypes.func.isRequired,
  reactionMode: PropTypes.oneOf(['none', 'like', 'updown']).isRequired,
};

CommentBox.defaultProps = {
  useDivider: true,
};
