import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    packetMenu: {
      title: 'Packet',
    },
    pickupLocationScreen: {
      title: 'Pickup Location',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      labelKeepPacketHours: 'Keep Packet (Hours)',

      buttonCaptionCreateNewPickupLocation: 'Create New Pickup Location',
      buttonCaptionEditPickupLocation: 'Edit Pickup Location',
      buttonCaptionDeletePickupLocation: 'Delete Pickup Location',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderKeepPacketHours: 'Keep Packet (Hours)',
    },
    packetTypeScreen: {
      title: 'Packet Type',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      labelKeepPacketHours: 'Keep Packet (Hours)',

      buttonCaptionCreateNewPacketType: 'Create New Packet Type',
      buttonCaptionEditPacketType: 'Edit Packet Type',
      buttonCaptionDeletePacketType: 'Delete Packet Type',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderKeepPacketHours: 'Keep Packet (Hours)',
    },
    packetScreen: {
      title: 'Packet',

      labelNo: 'No',
      labelPacketId: 'Packet ID',
      labelSender: 'Sender',
      labelRecipient: 'Recipient',
      labelPacketStatus: 'Packet Status',
      labelReceivedBy: 'Received By',
      labelValidUntil: 'Valid Until',
      labelDocumentType: 'Document Type',
      labelPickupLocation: 'Pickup Location',
      labelCreatedDate: 'Created Date',
      labelPickupPacket: 'Pickup Packet',
      labelAnotherUser: 'Another User',
      labelOthers: 'Others',

      buttonCaptionReceivePacket: 'Receive Packet',
      buttonCaptionEditPacket: 'Edit Packet',
      buttonCaptionDeletePacket: 'Delete Packet',
      buttonCaptionPickup: 'Pickup',
      buttonCaptionUploadFile: 'Upload File',
      buttonCaptionCamera: 'Camera',

      placeholderPacketId: 'Packet ID',
      placeholderSender: 'Sender',
      placeholderRecipient: 'Recipient',
      placeholderReceivedBy: 'Received By',
      placeholderPickupLocation: 'Pickup Location',
      placeholderPacketType: 'Packet Type',
      placeholderArrivalImage: 'Arrival Image',
      placeholderArrivalNotes: 'Arrival Notes',
      placeholderValidUntil: 'Valid Until',
      placeholderPacketStatus: 'Packet Status',
      placeholderPickedUpBy: 'Picked Up By',
      placeholderPickedUpByNonUser: 'Picked Up By Non User',
      placeholderHandedBy: 'Handed By',
      placeholderPickedUpTime: 'Picked Up Time',
      placeholderPickedupImage: 'Picked Up Image',
      placeholderPickedUpNotes: 'Picked Up Notes',
    },
    packetSummaryScreen: {
      title: 'Summary',

      labelThisMonth: 'This Month ({period})',
      labelPreviousMonth: 'Previous Month ({period})',
      labelThisYear: 'This Year ({period})',
      labelPreviousYear: 'Previous Year ({period})',
      labelTrends: 'Trends',
      labelTopRecipients: 'Top Recipients',
      labelPacketTypes: 'Packet Types',
      labelLocations: 'Locations',
      labelReceivedPacket: 'Received Packet',
    },
  },
  id: {
    packetMenu: {
      title: 'Manajemen Paket',
    },
    pickupLocationScreen: {
      title: 'Lokasi Pengambilan',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',
      labelKeepPacketHours: 'Simpan Paket (Jam)',

      buttonCaptionCreateNewPickupLocation: 'Buat Lokasi Pengambilan Baru',
      buttonCaptionEditPickupLocation: 'Ubah Lokasi Pengambilan',
      buttonCaptionDeletePickupLocation: 'Hapus Lokasi Pengambilan',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderKeepPacketHours: 'Simpan Paket (Jam)',
    },
    packetTypeScreen: {
      title: 'Tipe Paket',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',
      labelKeepPacketHours: 'Simpan Paket (Jam)',

      buttonCaptionCreateNewPacketType: 'Buat Tipe Paket Baru',
      buttonCaptionEditPacketType: 'Ubah Tipe Paket',
      buttonCaptionDeletePacketType: 'Hapus Tipe Paket',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderKeepPacketHours: 'Simpan Paket (Jam)',
    },
    packetScreen: {
      title: 'Packet',

      labelNo: 'No',
      labelPacketId: 'ID Paket',
      labelSender: 'Pengirim',
      labelRecipient: 'Penerima',
      labelPacketStatus: 'Status Paket',
      labelReceivedBy: 'Diterima Oleh',
      labelValidUntil: 'Berlaku Sampai Dengan',
      labelDocumentType: 'Tipe Dokumen',
      labelPickupLocation: 'Lokasi Pengambilan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelPickupPacket: 'Ambil Paket',
      labelAnotherUser: 'Pengguna Lainnya',
      labelOthers: 'Lainnya',

      buttonCaptionReceivePacket: 'Terima Paket',
      buttonCaptionEditPacket: 'Ubah Paket',
      buttonCaptionDeletePacket: 'Hapus Paket',
      buttonCaptionPickup: 'Ambil',
      buttonCaptionUploadFile: 'Unggah File',
      buttonCaptionCamera: 'Kamera',

      placeholderPacketId: 'ID Paket',
      placeholderSender: 'Pengirim',
      placeholderRecipient: 'Penerima',
      placeholderReceivedBy: 'Diterima Oleh',
      placeholderPickupLocation: 'Lokasi Pengambilan',
      placeholderPacketType: 'Tipe Paket',
      placeholderArrivalImage: 'Gambar Penerimaan',
      placeholderArrivalNotes: 'Catatan Penerimaan',
      placeholderValidUntil: 'Berlaku Sampai Dengan',
      placeholderPacketStatus: 'Status Paket',
      placeholderPickedUpBy: 'Diambil Oleh',
      placeholderPickedUpByNonUser: 'Diambil Oleh Bukan Pengguna',
      placeholderHandedBy: 'Diserahkan Oleh',
      placeholderPickedUpTime: 'Waktu Pengambilan',
      placeholderPickedupImage: 'Gambar Pengambilan',
      placeholderPickedUpNotes: 'Catatan Pengambilan',
    },
    packetSummaryScreen: {
      title: 'Rangkuman',

      labelThisMonth: 'Bulan Ini ({period})',
      labelPreviousMonth: 'Bulan Lalu ({period})',
      labelThisYear: 'Tahun Ini ({period})',
      labelPreviousYear: 'Tahun Lalu ({period})',
      labelTrends: 'Tren',
      labelTopRecipients: 'Penerima Teratas',
      labelPacketTypes: 'Tipe Paket',
      labelLocations: 'Lokasi',
      labelReceivedPacket: 'Paket Diterima',
    },
  },
});

export default LocalizedString;
