import { SET_SUMMARY_THIS_YEAR_LOCATIONS, CLEAR_SUMMARY_THIS_YEAR_LOCATIONS } from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_SUMMARY_THIS_YEAR_LOCATIONS: {
      return action.data;
    }
    case CLEAR_SUMMARY_THIS_YEAR_LOCATIONS:
      return state;
    default:
      return state;
  }
};
