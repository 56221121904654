import {
  DOWNLOADING_QUIZES, ADDING_EDITING_QUIZ, DOWNLOADING_DELETING_QUIZ,
  SET_QUIZ_SELECTED_PAGE_SIZE, SET_QUIZ_TAPPED_ID, SET_QUIZ_SEARCH_TEXT,
  SET_QUIZ_SELECTED_ORDER_BY, SET_QUIZ_SELECTED_VISIBILITY_STATUS,
  SET_QUIZ_SELECTED_QUIZ_CATEGORY_ID,
  SET_QUIZ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SAVING_QUIZ_RESULT, SENDING_QUIZ_NOTIFICATION,
  COPYING_QUIZ_DEEPLINK,
} from '../action';
import { INITIAL_ORDER_BY_QUIZES } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  sendingNotification: false,
  copying: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  selectedVisibilityStatus: '',
  selectedQuizCategoryId: '',
  orderBy: INITIAL_ORDER_BY_QUIZES,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_QUIZES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_QUIZ:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_QUIZ:
      return { ...state, downloadingDeleting: action.status };
    case SAVING_QUIZ_RESULT:
      return { ...state, saving: action.status };
    case SET_QUIZ_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_QUIZ_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_QUIZ_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_QUIZ_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_QUIZ_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.option };
    case SET_QUIZ_SELECTED_QUIZ_CATEGORY_ID:
      return { ...state, selectedQuizCategoryId: action.id };
    case SET_QUIZ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SENDING_QUIZ_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    case COPYING_QUIZ_DEEPLINK:
      return { ...state, copying: action.status };
    default: return state;
  }
};
