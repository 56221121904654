import {
  resyncingCompanyMdata, setAlertErrorMessage, setCompanySelectedOrderByMData,
  setCompanySelectedPageSizeMData,
} from '../simple-action';
import { resyncMasterDataIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_COMPANIES, MASTER_DATA_ENTITY_NAME_COMPANY } from '../../../constant';
import downloadMDataCompaniesAsync from './downloadMDataCompaniesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingCompanyMdata(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCompany;

    await resyncMasterDataIndividually(MASTER_DATA_ENTITY_NAME_COMPANY, tappedId, token);

    dispatch(setCompanySelectedPageSizeMData(20));
    dispatch(setCompanySelectedOrderByMData(INITIAL_ORDER_BY_COMPANIES));
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(resyncingCompanyMdata(false));
  }
};
