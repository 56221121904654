import { connect } from 'react-redux';
import {
  SIMPLE_DATE_FORMAT,
  INITIAL_ORDER_BY_WORKING_HOUR_ADJUSTMENTS,
  MENUID_ATTENDANCE_ADDON_WORKING_HOUR_ADJUSTMENT,
  PAGE_MODE_TABLE,
  TIME_MODE_CLOCK_IN,
  TIME_MODE_CLOCK_OUT,
  DATE_TIME_FORMAT,
} from '../../constant';
import {
  convertClockInOutHoursToMinutes, debounceSearch, toMoment, transformDropdownData,
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  clearWorkingHourAdjustments, clearWorkingHours, setActiveSideMenuItem,
  setAlertErrorMessage,
  setWorkingHourAdvancedFilterDialogSelectedFilterString, setWorkingHourSearchText,
  setWorkingHourAdjustmentSearchText, setWorkingHourAdjustmentSelectedOrderBy,
  setWorkingHourAdjustmentSelectedPageSize, setWorkingHourAdjustmentTappedId,
  downloadWorkingHourAdjustmentAsync, downloadWorkingHourAdjustmentsAsync,
  downloadWorkingHoursAsync,
  setWorkingHourAdjustmentAdvancedFilterDialogSelectedFilterString,
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  clearProfiles,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import WorkingHourAdjustmentPage from './working-hour-adjustment.presentation';
import { clearEmployees, downloadEmployeesAsync, setHistoryEmployeeSearchText } from '../../../attendance/redux/action';

const getInitialValues = (state) => {
  const {
    workingHourAdjustments, uiFunctionalPage, uiWorkingHourAdjustment,
  } = state;
  const { data } = workingHourAdjustments;
  const { downloadingDeleting, tappedId } = uiWorkingHourAdjustment;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      startDate: toMoment(found.startDate).format(SIMPLE_DATE_FORMAT) ?? '',
      endDate: toMoment(found.endDate).format(SIMPLE_DATE_FORMAT) ?? '',
      approvalStatusTime: toMoment(found.approvalStatusTime).format(DATE_TIME_FORMAT) ?? '',
      workingHourTimeZone:
              found.workingHourTimeZone !== null ? found.workingHourTimeZone : '',
      earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
        found.earliestClockInDriftTimeMinutes,
        found.startWorkingHour,
        TIME_MODE_CLOCK_IN,
      ),
      autoClockOutAfterMinutes: convertClockInOutHoursToMinutes(
        found.autoClockOutAfterMinutes,
        found.endWorkingHour,
        TIME_MODE_CLOCK_OUT,
      ),
    }) : {
    };
  return result;
};

const mapStateToProps = (state) => ({
  requesters: transformUserDropdownData(state.profiles.data),
  pics: transformUserDropdownData(state.employees.data),
  workingHours: transformDropdownData(state.workingHours.data),
  downloading: state.uiWorkingHourAdjustment.downloading,
  loadingRequester: state.uiProfile.downloading,
  loadingPic: state.uiHistory.downloadingEmployees,
  dialogVisibility: state.uiFunctionalPage.visibility,
  loadingWorkingHour: state.uiWorkingHour.downloading,
  initialValues: getInitialValues(state),
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const searchWorkingHourDebounce = debounceSearch((dispatch) => {
  dispatch(clearWorkingHours());
  dispatch(downloadWorkingHoursAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const searchPicDebounce = debounceSearch((dispatch) => {
  dispatch(clearEmployees());
  dispatch(downloadEmployeesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setWorkingHourAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setWorkingHourSearchText(''));
    dispatch(clearWorkingHours());
    dispatch(downloadWorkingHoursAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(clearEmployees());
    dispatch(downloadEmployeesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_WORKING_HOUR_ADJUSTMENT));
    dispatch(setWorkingHourAdjustmentAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setWorkingHourAdjustmentSearchText(''));
    dispatch(clearWorkingHourAdjustments());
    dispatch(setWorkingHourAdjustmentSelectedPageSize(20));
    dispatch(setWorkingHourAdjustmentSelectedOrderBy(INITIAL_ORDER_BY_WORKING_HOUR_ADJUSTMENTS));
    dispatch(downloadWorkingHourAdjustmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/requester/, 'requester.id')
      .replace(/pic/, 'approvalPicId')
      .replace(/workingHour/, 'workingHour.id');
    dispatch(setWorkingHourAdjustmentAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearWorkingHourAdjustments());
    dispatch(downloadWorkingHourAdjustmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeWorkingHourText: async (text) => {
    dispatch(setWorkingHourAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setWorkingHourSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchWorkingHourDebounce(dispatch);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWorkingHourAdjustmentsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWorkingHourAdjustmentSelectedPageSize(pageSize));
    dispatch(downloadWorkingHourAdjustmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeRequesterText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onChangePicText: async (text) => {
    dispatch(setHistoryEmployeeSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchPicDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setWorkingHourAdjustmentSelectedPageSize(pageSize));
    dispatch(clearWorkingHourAdjustments());
    dispatch(downloadWorkingHourAdjustmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearWorkingHourAdjustments());
    dispatch(setWorkingHourAdjustmentAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setWorkingHourAdjustmentSelectedOrderBy(INITIAL_ORDER_BY_WORKING_HOUR_ADJUSTMENTS));
    dispatch(downloadWorkingHourAdjustmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWorkingHourAdjustmentSearchText(text));
      dispatch(clearWorkingHourAdjustments());
      await dispatch(downloadWorkingHourAdjustmentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWorkingHourAdjustmentSelectedOrderBy(orderBy));
    dispatch(clearWorkingHourAdjustments());
    dispatch(downloadWorkingHourAdjustmentsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setWorkingHourAdjustmentTappedId(id));
    dispatch(downloadWorkingHourAdjustmentAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkingHourAdjustmentPage);
