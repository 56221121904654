import {
  addScope, clearScopes, downloadingDeletingScope, setAlertErrorMessage,
  setScopeTappedId, setFunctionalPageMode,
} from '../simple-action';
import { downloadScope, deleteScope } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadScopesAsync from './downloadScopesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingScope(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiScope;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteScope(tappedId, token);
      dispatch(setScopeTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearScopes());
      dispatch(downloadScopesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const scope = await downloadScope(tappedId, token);
      dispatch(addScope(scope));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingScope(false));
  }
};
