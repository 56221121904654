import { reset } from 'redux-form';
import {
  addingEditingPacket, clearPackets, setAlertErrorMessage, setFunctionalPageMode,
  setPacketSelectedRecipient, setPacketSelectedPacketType, setPacketSelectedPickupLocation,
} from '../simple-action';
import { addPacket, editPacket, transformLocalImagePath } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_PACKET } from '../../../constant';
import downloadPacketsAsync from './downloadPacketsAsync';

export default (sender, arrivalNotes, arrivalImage) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPacket(true));

    const { token } = getState().authentication;
    const {
      selectedRecipient, selectedPickupLocation, selectedPacketType, tappedId,
    } = getState().uiPacket;
    const { data } = getState().packets;
    const found = data[tappedId];
    const recipientProfileId = selectedRecipient.value || found.recipient.id;
    const pickupLocationId = selectedPickupLocation.value || found.pickupLocation.id;
    const packetTypeId = selectedPacketType.value || found.packetType.id;

    if (tappedId) {
      await editPacket(tappedId, sender, recipientProfileId, pickupLocationId, packetTypeId,
        arrivalNotes, transformLocalImagePath(arrivalImage), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addPacket(sender, recipientProfileId, pickupLocationId, packetTypeId,
        arrivalNotes, arrivalImage, token);
    }

    dispatch(reset(RXFORM_PACKET));
    dispatch(clearPackets());
    dispatch(downloadPacketsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setPacketSelectedRecipient(''));
    dispatch(setPacketSelectedPacketType(''));
    dispatch(setPacketSelectedPickupLocation(''));
  } finally {
    dispatch(addingEditingPacket(false));
  }
};
