import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest,
  transformQueryResult,
} from '../../helper';
import {
  REST_URL_ADD_EDIT_WFA_QUOTA, REST_URL_ADD_EDIT_WORKING_HOUR, REST_URL_ENABLE_DISABLE_WORKING_HOUR,
  REST_URL_VIEW_DELETE_WFA_QUOTA, REST_URL_VIEW_DELETE_WORKING_HOUR, REST_BASE_URL,
  REST_URL_VIEW_WORKING_HOUR_ADJUSTMENT, REST_URL_WFA_QUOTAS, REST_URL_WORKING_HOURS,
  REST_URL_WORKING_HOUR_ADJUSTMENTS, REST_URL_WFA_REQUESTS, REST_URL_VIEW_WFA_REQUEST,
  REST_URL_WFAS, REST_URL_VIEW_WFA, REST_URL_CANCEL_WFA, WFA_STATUSES, REST_URL_IMAGE_STORAGE,
  REST_URL_ATTENDANCE_CORRECTIONS, REST_URL_VIEW_ATTENDANCE_CORRECTION,
  REST_URL_HOLIDAYS, REST_URL_VIEW_HOLIDAY, REST_URL_ADD_HOLIDAY,
  REST_URL_EDIT_WFA, REST_URL_PERSONAL_HOLIDAYS, REST_URL_VIEW_PERSONAL_HOLIDAY,
  REST_URL_ADD_PERSONAL_HOLIDAY,
} from './constant';

export * from '../../helper';

export const downloadWorkingHours = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_WORKING_HOURS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_WORKING_HOURS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addWorkingHour = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_WORKING_HOUR, body, token);
};

export const editWorkingHour = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_WORKING_HOUR, body, token);
};

export const downloadWorkingHour = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_WORKING_HOUR.replace(/\{id\}/, id), token);
  return response;
};

export const deleteWorkingHour = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_WORKING_HOUR.replace(/\{id\}/, id), token);
};

export const enableDisableWorkingHour = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_WORKING_HOUR, body, token);
};

export const downloadWfaRequests = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_WFA_REQUESTS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_WFA_REQUESTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadWfaRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_WFA_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const downloadWorkingHourAdjustments = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_WORKING_HOUR_ADJUSTMENTS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_WORKING_HOUR_ADJUSTMENTS, pageNumber, pageSize, orderBy,
      searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadWorkingHourAdjustment = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_WORKING_HOUR_ADJUSTMENT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadWFAQuotas = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_WFA_QUOTAS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_WFA_QUOTAS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addWFAQuota = async (departmentName, quotaPerMonth, token) => {
  const body = { departmentName, quotaPerMonth };
  await sendPostRequest(REST_URL_ADD_EDIT_WFA_QUOTA, body, token);
};

export const editWFAQuota = async (id, departmentName, quotaPerMonth, token) => {
  const body = { id, departmentName, quotaPerMonth };
  await sendPutRequest(REST_URL_ADD_EDIT_WFA_QUOTA, body, token);
};

export const downloadWFAQuota = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_WFA_QUOTA.replace(/\{id\}/, id), token);
  return response;
};

export const deleteWFAQuota = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_WFA_QUOTA.replace(/\{id\}/, id), token);
};

export const downloadWfas = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_WFAS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_WFAS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const responseDataWithHideContextMenu = response.data.map((x) => ({
    ...x,
    hideContextMenu: !x.wfaStatus || x.wfaStatus !== WFA_STATUSES[1],
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadWfa = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_WFA.replace(/\{id\}/, id), token);
  return response;
};

export const editWfa = async (id, date, wfaStatusNotes, token) => {
  const body = { id, date, wfaStatusNotes };
  await sendPutRequest(REST_URL_EDIT_WFA, body, token);
};

export const cancelWfa = async (id, notes, token) => {
  const body = { id, notes };
  await sendPutRequest(REST_URL_CANCEL_WFA, body, token);
};

export const downloadAttendanceCorrections = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_ATTENDANCE_CORRECTIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadAttendanceCorrection = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_ATTENDANCE_CORRECTION.replace(/\{id\}/, id), token);
  const transformData = {
    ...response,
    attachment: response.attachment ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${response.attachment}` : '',
  };
  return transformData;
};

export const downloadHolidays = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_HOLIDAYS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_HOLIDAYS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadHoliday = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_HOLIDAY.replace(/\{id\}/, id), token);
  return response;
};

export const addHoliday = async (file, year, token) => {
  const body = {
    file, year,
  };

  await sendPostRequest(REST_URL_ADD_HOLIDAY, body, token);
};

export const downloadPersonalHolidays = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PERSONAL_HOLIDAYS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_PERSONAL_HOLIDAYS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPersonalHoliday = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_PERSONAL_HOLIDAY.replace(/\{id\}/, id), token);
  return response;
};

export const addPersonalHoliday = async (file, token) => {
  const body = { file };
  await sendPostRequest(REST_URL_ADD_PERSONAL_HOLIDAY, body, token);
};
