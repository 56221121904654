import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, CircularProgress,
  IconButton, Dialog, TablePagination,
} from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import {
  COLOR_PRIMARY,
  PICKER_MODE_DATE,
  SIMPLE_DATE_FORMAT,
  COLOR_BODY_TEXT,
  COLOR_BACKGROUND_SECONDARY,
  COLOR_BACKGROUND,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalizedLocalizedString from '../../../../localization';
import { AccentButton, DateTimePickerField, VerticalSpacer } from '../../../../component';
import { toMoment } from '../../helper';
import { PageTitleAndScreenNameTotalValuesShape } from '../../type';

const useStyles = makeStyles(() => ({
  w500: {
    fontWeight: '500',
  },
  mb10: {
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
    display: 'flex',
  },
  flexBig: {
    flex: 1.6,
    display: 'flex',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  tableHeader: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    fontWeight: 500,
  },
  loading: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  titleSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterButtonActions: {
    gap: '10px',
    marginRight: 8,
    marginTop: 10,
  },
  buttonOutline: {
    color: COLOR_BODY_TEXT,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
  cell: {
    padding: '8px 0px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: 500,
  },
  value: {
    overflowWrap: 'anywhere',
    fontWeight: 'bold',
    display: 'flex',
    width: '90%',
  },
}));

const renderCell = (item, index, classes, rowHeights) => (
  <div
    className={classes.cell}
    key={item.id}
    style={{
      background: (index % 2 === 0)
        ? COLOR_BACKGROUND_SECONDARY
        : COLOR_BACKGROUND,
      height: rowHeights[index] || 0,
    }}
  >
    <span>
      {item.value}
    </span>
  </div>
);

const renderFirstColumn = (
  pageTitleAndScreenNames, classes, screenPageNameCellRef,
  handleRef,
) => {
  const screenNames = pageTitleAndScreenNames.length
    ? pageTitleAndScreenNames.map((x) => x.screenPageName)
    : [];

  return (
    <div className={`${classes.flexBig} ${classes.column}`}>
      <span className={classes.tableHeader} style={{ height: 40 }}>
        {LocalizedString.analyticsScreen.labelPageTitleAndScreenNames}
      </span>
      <VerticalSpacer height={20} />
      <span className={classes.bold}>
        {LocalizedString.analyticsScreen.labelTotal}
      </span>
      <VerticalSpacer height={20} />
      {screenNames.length && screenNames.map((x, i) => (
        <div
          className={classes.cell}
          key={x}
          style={{
            background: (i % 2 === 0)
              ? COLOR_BACKGROUND_SECONDARY
              : COLOR_BACKGROUND,
          }}
          ref={(el) => handleRef(el, i)}
        >
          <span className={classes.value}>
            {x}
          </span>
        </div>
      ))}
    </div>
  );
};

const renderViewsColumn = (
  pageTitleAndScreenNames, classes, pageTitleAndScreenNameTotalValues,
  rowHeights,
) => {
  const data = pageTitleAndScreenNames.length
    ? pageTitleAndScreenNames.map((x) => ({
      id: x.screenPageName,
      value: x.views,
    }))
    : [];

  return (
    <div className={`${classes.flex1} ${classes.column}`}>
      <span className={classes.tableHeader} style={{ height: 40 }}>
        {LocalizedString.analyticsScreen.labelViews}
      </span>
      <VerticalSpacer height={20} />
      <span className={classes.bold}>
        {pageTitleAndScreenNameTotalValues.views}
      </span>
      <VerticalSpacer height={20} />
      {data.length && data.map((x, i) => renderCell(
        x, i, classes, rowHeights,
      ))}
    </div>
  );
};

const renderActiveUserColumn = (
  pageTitleAndScreenNames, classes, pageTitleAndScreenNameTotalValues,
  rowHeights,
) => {
  const data = pageTitleAndScreenNames.length
    ? pageTitleAndScreenNames.map((x) => ({
      id: x.screenPageName,
      value: x.activeUsers,
    }))
    : [];

  return (
    <div className={`${classes.flex1} ${classes.column}`}>
      <span className={classes.tableHeader} style={{ height: 40 }}>
        {LocalizedString.analyticsScreen.labelActiveUsers}
      </span>
      <VerticalSpacer height={20} />
      <span className={classes.bold}>
        {pageTitleAndScreenNameTotalValues.activeUsers}
      </span>
      <VerticalSpacer height={20} />
      {data.length && data.map((x, i) => renderCell(
        x, i, classes, rowHeights,
      ))}
    </div>
  );
};

const renderViewsPerActiveUserColumn = (
  pageTitleAndScreenNames, classes, pageTitleAndScreenNameTotalValues,
  rowHeights,
) => {
  const data = pageTitleAndScreenNames.length
    ? pageTitleAndScreenNames.map((x) => ({
      id: x.screenPageName,
      value: x.viewsPerActiveUser,
    }))
    : [];

  return (
    <div className={`${classes.flex1} ${classes.column}`}>
      <span className={classes.tableHeader} style={{ height: 40 }}>
        {LocalizedString.analyticsScreen.labelViewsPerActiveUsers}
      </span>
      <VerticalSpacer height={20} />
      <span className={classes.bold}>
        {pageTitleAndScreenNameTotalValues.viewsPerActiveUser}
      </span>
      <VerticalSpacer height={20} />
      {data.length && data.map((x, i) => renderCell(
        x, i, classes, rowHeights,
      ))}
    </div>
  );
};

const renderAverageEngagementTimePerActiveUserColumn = (
  pageTitleAndScreenNames, classes, pageTitleAndScreenNameTotalValues,
  rowHeights,
) => {
  const data = pageTitleAndScreenNames.length
    ? pageTitleAndScreenNames.map((x) => ({
      id: x.screenPageName,
      value: x.averageEngagementPerActiveUser,
    }))
    : [];

  return (
    <div className={`${classes.flex1} ${classes.column}`}>
      <span className={classes.tableHeader} style={{ height: 40 }}>
        {LocalizedString.analyticsScreen.labelAverageEngagementTimePerActiveUser}
      </span>
      <VerticalSpacer height={20} />
      <span className={classes.bold}>
        {pageTitleAndScreenNameTotalValues.averageEngagementPerActiveUser}
      </span>
      <VerticalSpacer height={20} />
      {data.length && data.map((x, i) => renderCell(
        x, i, classes, rowHeights,
      ))}
    </div>
  );
};

const PageTitleAndScreenName = ({
  pageTitleAndScreenNames, downloadingPageTitleAndScreenNames,
  onFilterIconPressed,
  pageTitleAndScreenNameDateRange,
  pageTitleAndScreenNameFilterDialogVisibility,
  onCloseDialogPressed, onResetFilterPressed, onApplyFilterPressed,
  pageTitleAndScreenNameTotalValues,
  totalPages,
}) => {
  const classes = useStyles();

  const [dateRange, setDateRange] = useState(pageTitleAndScreenNameDateRange);
  const [page, setPage] = useState(0);
  const [rowHeights, setRowHeights] = useState([]);
  const [slicedData, setSlicedData] = useState([]);
  const screenPageNameCellRef = useRef([]);
  const rowsPerPage = 9;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRef = (el, i) => {
    screenPageNameCellRef.current[i] = el;
  };

  useEffect(() => {
    setDateRange({
      from: pageTitleAndScreenNameDateRange.from,
      to: pageTitleAndScreenNameDateRange.to,
    });
  }, [pageTitleAndScreenNameDateRange]);

  useEffect(() => {
    const newSlicedData = pageTitleAndScreenNames.slice(
      page * rowsPerPage, page * rowsPerPage + rowsPerPage,
    );
    setSlicedData(newSlicedData);
  }, [page, pageTitleAndScreenNames]);

  useEffect(() => {
    const heights = screenPageNameCellRef.current.map((ref) => (ref ? ref.clientHeight : 0));
    setRowHeights(heights);
  }, [slicedData]);

  return (
    <>
      <div className={classes.titleSectionContainer}>
        <div className={classes.row}>
          <div>
            <Typography
              variant="body1"
              className={`${classes.mb10} ${classes.w500}`}
            >
              {`${LocalizedString.analyticsScreen.labelPageTitleAndScreenNames} (${toMoment(pageTitleAndScreenNameDateRange.from).format(SIMPLE_DATE_FORMAT)} - ${toMoment(pageTitleAndScreenNameDateRange.to).format(SIMPLE_DATE_FORMAT)})`}
            </Typography>
          </div>
        </div>

        <IconButton edge="end" onClick={onFilterIconPressed}>
          <Tune style={{ fill: COLOR_PRIMARY }} />
        </IconButton>
      </div>
      {
        downloadingPageTitleAndScreenNames ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
          </div>
        ) : (
          <div className={classes.flex1}>
            {renderFirstColumn(
              slicedData, classes, screenPageNameCellRef,
              handleRef,
            )}
            {renderViewsColumn(
              slicedData, classes, pageTitleAndScreenNameTotalValues,
              rowHeights,
            )}
            {renderActiveUserColumn(
              slicedData, classes, pageTitleAndScreenNameTotalValues,
              rowHeights,
            )}
            {renderViewsPerActiveUserColumn(
              slicedData, classes, pageTitleAndScreenNameTotalValues,
              rowHeights,
            )}
            {renderAverageEngagementTimePerActiveUserColumn(
              slicedData, classes, pageTitleAndScreenNameTotalValues,
              rowHeights,
            )}
          </div>
        )
      }
      <div className={`${classes.row} ${classes.flexEnd}`}>
        <TablePagination
          component="div"
          count={totalPages}
          page={page}
          onChangePage={(e, newPage) => handleChangePage(e, newPage)}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
        />
      </div>
      <Dialog
        open={pageTitleAndScreenNameFilterDialogVisibility}
        onClose={onCloseDialogPressed}
        fullWidth
        maxWidth="md"
      >
        <div style={{ padding: 40 }}>
          <Typography
            variant="body1"
            className={`${classes.w500} ${classes.flex1}`}
            style={{ marginLeft: 10, marginBottom: 8 }}
          >
            {GlobalizedLocalizedString.common.labelFilter}
          </Typography>
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelStartDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingPageTitleAndScreenNames}
            value={dateRange.from}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: date,
                to: dateRange.to,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelEndDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingPageTitleAndScreenNames}
            value={dateRange.to}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: dateRange.from,
                to: date,
              });
            }}
            InputProps={{ readOnly: true }}
          />


          <div className={`${classes.row} ${classes.flexEnd} ${classes.filterButtonActions}`}>
            <AccentButton
              onClick={onCloseDialogPressed}
              variant="text"
              caption={GlobalizedLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              size="small"
              disabled={downloadingPageTitleAndScreenNames}
            />
            <AccentButton
              onClick={onResetFilterPressed}
              variant="outlined"
              caption={GlobalizedLocalizedString.common.buttonCaptionReset}
              className={classes.buttonOutline}
              size="small"
              disabled={downloadingPageTitleAndScreenNames}
            />
            <AccentButton
              type="submit"
              disableElevation
              caption={GlobalizedLocalizedString.common.buttonCaptionApply}
              size="small"
              onClick={() => onApplyFilterPressed(
                dateRange,
              )}
              disabled={downloadingPageTitleAndScreenNames}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PageTitleAndScreenName;

PageTitleAndScreenName.propTypes = {
  pageTitleAndScreenNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageTitleAndScreenNameTotalValues: PageTitleAndScreenNameTotalValuesShape.isRequired,

  downloadingPageTitleAndScreenNames: PropTypes.bool.isRequired,
  pageTitleAndScreenNameFilterDialogVisibility: PropTypes.bool.isRequired,

  pageTitleAndScreenNameDateRange: PropTypes.object.isRequired,

  totalPages: PropTypes.number.isRequired,

  onFilterIconPressed: PropTypes.func.isRequired,
  onCloseDialogPressed: PropTypes.func.isRequired,
  onResetFilterPressed: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
};
