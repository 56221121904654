import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    shortlinkMenu: {
      title: 'Short Link',
    },
    shortlinkScreen: {
      title: 'Short Link',

      labelNo: 'No',
      labelShortUrl: 'Short URL',
      labelDescription: 'Description',
      labelUrl: 'URL',
      labelCreatedDate: 'Created Date',

      placeholderReferenceId: 'Reference ID',
      placeholderUrl: 'URL',
      placeholderShortUrl: 'Short URL',
      placeholderDescription: 'Description',

      buttonCaptionCreateNewShortlink: 'Create New Short Link',
      buttonCaptionDeleteShortlink: 'Delete Short Link',
    },
  },
  id: {
    shortlinkMenu: {
      title: 'Tautan Pendek',
    },
    shortlinkScreen: {
      title: 'Tautan Pendek',

      labelNo: 'No',
      labelShortUrl: 'URL Pendek',
      labelDescription: 'Deskripsi',
      labelUrl: 'URL',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderReferenceId: 'ID Referensi',
      placeholderUrl: 'URL',
      placeholderShortUrl: 'URL Pendek',
      placeholderDescription: 'Deskripsi',

      buttonCaptionCreateNewShortlink: 'Buat Tautan Pendek Baru',
      buttonCaptionDeleteShortlink: 'Hapus Tautan Pendek',
    },
  },
});

export default LocalizedString;
