import { addAttendanceCorrection, downloadingAttendanceCorrection } from '../simple-action';
import { downloadAttendanceCorrection } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceCorrection(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAttendanceCorrection;

    const result = await downloadAttendanceCorrection(tappedId, token);
    dispatch(addAttendanceCorrection(result));
  } finally {
    dispatch(downloadingAttendanceCorrection(false));
  }
};
