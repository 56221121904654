import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { Lock, LockOpenVariant } from 'mdi-material-ui';
import {
  BODY_FIELD_MAX_HEIGHT,
  COLOR_PRIMARY,
  HEADER_FOOTER_FIELD_MAX_HEIGHT,
  PAGE_MODE_VIEW, RXFORM_FIELD_DESCRIPTION, RXFORM_FIELD_NAME, RXFORM_FIELD_TEMPLATE_BODY,
  RXFORM_FIELD_TEMPLATE_FOOTER,
  RXFORM_FIELD_TEMPLATE_HEADER,
  RXFORM_FIELD_TEMPLATE_MARGIN_BOTTOM,
  RXFORM_FIELD_TEMPLATE_MARGIN_LEFT,
  RXFORM_FIELD_TEMPLATE_MARGIN_RIGHT,
  RXFORM_FIELD_TEMPLATE_MARGIN_TOP,
  RXFORM_FIELD_TEMPLATE_PAPER_SIZE,
  RXFORM_FIELD_TEMPLATE_SAMPLE_PAYLOAD,
  RXFORM_TEMPLATE, RXSTATE_TEMPLATE, RXSTATE_TEMPLATE_PAGE, STATUS_DISABLED, STATUS_ENABLED,
  RXFORM_FIELD_TEMPLATE_HEADER_MAX_HEIGHT,
  RXFORM_FIELD_TEMPLATE_FOOTER_MAX_HEIGHT,
  RXFORM_FIELD_TEMPLATE_HEADER_DRAW_DIVIDER_LINE,
  RXFORM_FIELD_TEMPLATE_FOOTER_DRAW_DIVIDER_LINE,
  approval,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { renderReduxFormCodeEditorField, renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import { FunctionalPage, VerticalSpacer } from '../../../configuration/component';
import { FormInitialValueShape } from '../../../../type';
import { rxformValidateDocumentTemplate } from '../../validation';
import { AccentButton } from '../../../../component';

const styles = {
  lockIcon: {
    fontSize: '20px',
    color: COLOR_PRIMARY,
  },
};

const renderLockedIcon = (rowData) => {
  const locked = rowData?.locked || false;
  if (locked) {
    return (<Lock style={styles.lockIcon} />);
  }
  return (<LockOpenVariant style={styles.lockIcon} />);
};

const renderDialogContent = (
  initialValues, downloadingDeleting, addingEditing, pageMode,
  onPreviewClicked, generatingPreview, paperSizes,
  onPaperSizeOptionSelected, downloadingPaperSizes,
  onHeaderDrawDividerLineOptionSelected,
  onFooterDrawDividerLineOptionSelected,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFORM_FIELD_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.templatePage.labelName}
            label={LocalizedString.templatePage.labelName}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFORM_FIELD_TEMPLATE_PAPER_SIZE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.templatePage.placeholderPaperSize}
            label={LocalizedString.templatePage.placeholderPaperSize}
            disabled={
              addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW
              || downloadingPaperSizes
            }
            data={paperSizes}
            onOptionSelected={onPaperSizeOptionSelected}
            value={initialValues.pageSize}
          />
        </Grid>

        <Typography>
          {LocalizedString.templatePage.labelHeader}
        </Typography>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Field
              name={RXFORM_FIELD_TEMPLATE_HEADER_DRAW_DIVIDER_LINE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.templatePage.labelDrawDividerLine}
              label={LocalizedString.templatePage.labelDrawDividerLine}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.headerDrawDividerLine}
              onOptionSelected={onHeaderDrawDividerLineOptionSelected}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Grid item>
              <Field
                name={RXFORM_FIELD_TEMPLATE_HEADER_MAX_HEIGHT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.templatePage.labelMaxHeight}
                label={LocalizedString.templatePage.labelMaxHeight}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography>
          {LocalizedString.templatePage.labelFooter}
        </Typography>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Field
              name={RXFORM_FIELD_TEMPLATE_FOOTER_DRAW_DIVIDER_LINE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.templatePage.labelDrawDividerLine}
              label={LocalizedString.templatePage.labelDrawDividerLine}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.footerDrawDividerLine}
              onOptionSelected={onFooterDrawDividerLineOptionSelected}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Grid item>
              <Field
                name={RXFORM_FIELD_TEMPLATE_FOOTER_MAX_HEIGHT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.templatePage.labelMaxHeight}
                label={LocalizedString.templatePage.labelMaxHeight}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFORM_FIELD_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.templatePage.labelDescription}
            label={LocalizedString.templatePage.labelDescription}
            multiline
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>

        <Grid container rowSpacing={2}>
          <Grid item xs={6} md={6}>
            <Grid item>
              <Field
                name={RXFORM_FIELD_TEMPLATE_MARGIN_TOP}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.templatePage.labelMarginTop}
                label={LocalizedString.templatePage.labelMarginTop}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Grid item>
              <Field
                name={RXFORM_FIELD_TEMPLATE_MARGIN_BOTTOM}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.templatePage.labelMarginBottom}
                label={LocalizedString.templatePage.labelMarginBottom}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Grid item>
              <Field
                name={RXFORM_FIELD_TEMPLATE_MARGIN_LEFT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.templatePage.labelMarginLeft}
                label={LocalizedString.templatePage.labelMarginLeft}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Grid item>
              <Field
                name={RXFORM_FIELD_TEMPLATE_MARGIN_RIGHT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.templatePage.labelMarginRight}
                label={LocalizedString.templatePage.labelMarginRight}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid item sm={12}>
      <Field
        name={RXFORM_FIELD_TEMPLATE_BODY}
        component={renderReduxFormCodeEditorField}
        placeholder={LocalizedString.templatePage.labelTemplateBody}
        label={LocalizedString.templatePage.labelTemplateBody}
        readOnly={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        required
        maxHeight={BODY_FIELD_MAX_HEIGHT}
      />
    </Grid>
    <VerticalSpacer height={10} />
    <Grid item sm={12}>
      <Field
        name={RXFORM_FIELD_TEMPLATE_HEADER}
        component={renderReduxFormCodeEditorField}
        placeholder={LocalizedString.templatePage.labelTemplateHeader}
        label={LocalizedString.templatePage.labelTemplateHeader}
        readOnly={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        maxHeight={HEADER_FOOTER_FIELD_MAX_HEIGHT}
      />
    </Grid>
    <VerticalSpacer height={10} />
    <Grid item sm={12}>
      <Field
        name={RXFORM_FIELD_TEMPLATE_FOOTER}
        component={renderReduxFormCodeEditorField}
        placeholder={LocalizedString.templatePage.labelTemplateFooter}
        label={LocalizedString.templatePage.labelTemplateFooter}
        readOnly={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        maxHeight={HEADER_FOOTER_FIELD_MAX_HEIGHT}
      />
    </Grid>
    <VerticalSpacer height={10} />
    <Grid item sm={12}>
      <Field
        name={RXFORM_FIELD_TEMPLATE_SAMPLE_PAYLOAD}
        component={renderReduxFormCodeEditorField}
        placeholder={LocalizedString.templatePage.labelSamplePayload}
        label={LocalizedString.templatePage.labelSamplePayload}
        readOnly={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        maxHeight={BODY_FIELD_MAX_HEIGHT}
        language="json"
      />
    </Grid>

    {pageMode === PAGE_MODE_VIEW && (
      <div style={{ marginTop: '20px' }}>
        <AccentButton
          onClick={() => onPreviewClicked()}
          caption={LocalizedString.templatePage.buttonCaptionPreview}
          loading={generatingPreview}
        />
      </div>
    )}
  </Grid>
);

const TemplatePage = ({
  initialValues, downloading, downloadingDeleting, enablingDisabling,
  lockingUnlocking, addingEditing, pageMode, templateStatus, tappedId,
  onAppear, onRefresh, onSearchBarTextChanged, onSortPressed, onChangePageSize,
  onChangePage, onCancelPressed, onViewPressed, onContextMenuPressed, onConfirmContextMenuPressed,
  onSavePressed, handleSubmit, onCreatePressed, onEditPressed, onDeletePressed, onSubmitPressed,
  onPreviewClicked, generatingPreview, paperSizes, onPaperSizeOptionSelected, downloadingPaperSizes,
  isRowLocked, onHeaderDrawDividerLineOptionSelected, onFooterDrawDividerLineOptionSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_TEMPLATE}
    uiPage={RXSTATE_TEMPLATE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.templatePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.templatePage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.templatePage.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.templatePage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      { title: LocalizedString.templatePage.labelLastModifiedDate, field: 'lastModifiedDate', sorting: !downloading },
      { title: '', field: 'locked', render: (rowData) => renderLockedIcon(rowData) },
    ]}
    moreMenus={[
      {
        caption: GlobalLocalizedString.common.buttonCaptionEnable,
        disabled: templateStatus === STATUS_ENABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionDisable,
        disabled: templateStatus === STATUS_DISABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgDisableConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionLock,
        disabled: isRowLocked || lockingUnlocking,
        onPress: () => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgLockConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionUnlock,
        disabled: !isRowLocked || lockingUnlocking,
        onPress: () => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgUnlockConfirmation),
      },
    ]}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onEditPressed={onEditPressed}
    onSubmitPressed={onSubmitPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    title={LocalizedString.templatePage.title}
    createNewButtonCaption={LocalizedString.templatePage.buttonCaptionCreateNewTemplate}
    editButtonCaption={LocalizedString.templatePage.buttonCaptionEditTemplate}
    deleteButtonCaption={LocalizedString.templatePage.buttonCaptionDeleteTemplate}
    editPermissionName="DOCGEN_EDIT_DOCUMENT_TEMPLATE"
    createPermissionName="DOCGEN_CREATE_DOCUMENT_TEMPLATE"
    deletePermissionName="DOCGEN_DELETE_DOCUMENT_TEMPLATE"
    useFullWidth
    disableEdit={isRowLocked}
    disableDelete={isRowLocked}
  >
    {renderDialogContent(
      initialValues, downloadingDeleting, addingEditing, pageMode,
      onPreviewClicked, generatingPreview, paperSizes,
      onPaperSizeOptionSelected, downloadingPaperSizes,
      onHeaderDrawDividerLineOptionSelected,
      onFooterDrawDividerLineOptionSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TEMPLATE,
  validate: rxformValidateDocumentTemplate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TemplatePage);

TemplatePage.propTypes = {
  paperSizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  lockingUnlocking: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  generatingPreview: PropTypes.bool.isRequired,
  downloadingPaperSizes: PropTypes.bool.isRequired,
  isRowLocked: PropTypes.bool.isRequired,
  templateStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  onAppear: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onPreviewClicked: PropTypes.func.isRequired,
  onPaperSizeOptionSelected: PropTypes.func.isRequired,
  onHeaderDrawDividerLineOptionSelected: PropTypes.func.isRequired,
  onFooterDrawDividerLineOptionSelected: PropTypes.func.isRequired,
};
