import { reset } from 'redux-form';
import {
  addingEditingArticle, clearArticles, setArticleSelectedCategory,
  setArticleSelectedVisibilityStatus,
} from '../simple-action';
import { editArticle, addArticle, toNumber } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_ARTICLES } from '../../../constant';
import { setAlertErrorMessage, setFunctionalPageMode } from '../../../../configuration/redux/action';
import downloadArticlesAsync from './downloadArticlesAsync';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

export default (title, content, publishedDate, media) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingArticle(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedCategory, selectedVisibilityStatus,
    } = getState().uiArticle;
    const { data } = getState().articles;

    const found = data[tappedId];
    const category = selectedCategory || found.category.id;
    const status = selectedVisibilityStatus || found.visibilityStatus;
    const medias = media.length > 0 ? transformMedia(media) : [];

    if (tappedId) {
      await editArticle(tappedId, title, content, publishedDate, category, status, medias, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addArticle(title, content, publishedDate, category, status, medias, token);
    }

    dispatch(reset(RXFORM_ARTICLES));
    dispatch(clearArticles());
    dispatch(downloadArticlesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setArticleSelectedCategory(''));
    dispatch(setArticleSelectedVisibilityStatus(''));
  } finally {
    dispatch(addingEditingArticle(false));
  }
};
