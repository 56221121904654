import PropTypes from 'prop-types';

export * from '../../type';

export const ScreenDataShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  publishedDate: PropTypes.string,
  viewCount: PropTypes.number,
  author: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
  }),
  visibilityStatus: PropTypes.string,
  media: PropTypes.arrayOf(PropTypes.object),
});
