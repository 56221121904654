import { ORDER_SORT_DESCENDING } from '../../constant';
import LocalizedString from './localization';

export * from '../../constant';

export const REST_URL_GAMEQR_CATEGORIES = '/gameqr/api/v1/ChallengeCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_GAMEQR_CATEGORY = '/gameqr/api/v1/ChallengeCategory/{id}';
export const REST_URL_ADD_EDIT_GAMEQR_CATEGORY = '/gameqr/api/v1/ChallengeCategory';
export const REST_URL_CHALLENGES = '/gameqr/api/v1/Challenge?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=category.name&filterString=status=enabled';
export const REST_URL_ADD_EDIT_CHALLENGE = '/gameqr/api/v1/Challenge';
export const REST_URL_VIEW_DELETE_CHALLENGE = '/gameqr/api/v1/Challenge/{id}';

export const ROUTE_NAME_GAMEQR = '/gameqr';
export const ROUTE_NAME_GAMEQR_CATEGORY = '/gameqr-category';
export const ROUTE_NAME_GAMEQR_CHALLENGE = '/challenge';

export const RXFORM_GAMEQR_CATEGORY = 'gameQRCategoryPage';
export const RXFORM_CHALLENGE = 'challengePage';

export const RXFIELD_GAMEQR_CATEGORY_PARENT_CATEGORY = 'parentCategory';
export const RXFIELD_GAMEQR_CATEGORY_STATUS = 'status';
export const RXFIELD_GAMEQR_CATEGORY_NAME = 'name';
export const RXFIELD_GAMEQR_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_GAMEQR_CATEGORY_IMAGE = 'image';
export const RXFIELD_CHALLENGE_TITLE = 'title';
export const RXFIELD_CHALLENGE_CONTENT = 'content';
export const RXFIELD_CHALLENGE_VALID_FROM = 'validFrom';
export const RXFIELD_CHALLENGE_VALID_UNTIL = 'validUntil';
export const RXFIELD_CHALLENGE_CATEGORY = 'category';
export const RXFIELD_CHALLENGE_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_CHALLENGE_MEDIA = 'media';
export const RXFIELD_CHALLENGE_CODES = 'codes';
export const RXFIELD_CHALLENGE_REACTION_ID = 'reactionId';
export const RXFIELD_CHALLENGE_TAGS = 'tags';
export const RXFIELD_CHALLENGE_SCAN_POLICY = 'scanPolicy';
export const RXFIELD_CHALLENGE_IS_PROMOTED = 'isPromoted';
export const RXFIELD_CHALLENGE_ORDER = 'order';

export const RXSTATE_GAMEQR_CATEGORIES = 'gameQRCategories';
export const RXSTATE_GAMEQR_CATEGORY_PAGE = 'uiGameQRCategory';
export const RXSTATE_CHALLENGES = 'challenges';
export const RXSTATE_CHALLENGE_PAGE = 'uiChallenge';

export const INITIAL_ORDER_BY_GAMEQR_CATEGORIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CHALLENGES = `validUntil ${ORDER_SORT_DESCENDING}`;

export const MENUID_GAMEQR = 'MENUID_GAMEQR';
export const MENUID_GAMEQR_CATEGORY = 'MENUID_GAMEQR_CATEGORY';
export const MENUID_GAMEQR_CHALLENGE = 'MENUID_GAMEQR_CHALLENGE';

export const SCAN_POLICIES = [
  { label: LocalizedString.challengePage.labelSequential, value: 'Sequential' },
  { label: LocalizedString.challengePage.labelRandom, value: 'Random' },
];
