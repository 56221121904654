import {
  downloadingAttendanceSummaryWorkLocation, setAttendanceSummaryWorkLocation,
} from '../simple-action';
import { downloadAttendanceSummaryWorkLocation, getPeriodDate } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { selectedPeriod } = getState().uiAttendanceSummary;
  try {
    dispatch(downloadingAttendanceSummaryWorkLocation(true));

    const data = await downloadAttendanceSummaryWorkLocation(
      token,
      getPeriodDate(selectedPeriod).startTime,
      getPeriodDate(selectedPeriod).endTime,
    );

    dispatch(setAttendanceSummaryWorkLocation(data));
  } finally {
    dispatch(downloadingAttendanceSummaryWorkLocation(false));
  }
};
