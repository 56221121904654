import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteForumPost, downloadForumPost } from '../../../helper';
import {
  addForumPost, clearForumPosts, downloadingDeletingForumPost, setAlertErrorMessage,
  setFunctionalPageMode, setForumPostSelectedContentFormat, setForumPostTappedId,
} from '../simple-action';
import downloadForumPostsAsync from './downloadForumPostsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingForumPost(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiForumPost;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteForumPost(tappedId, token);
      dispatch(setForumPostTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearForumPosts());
      dispatch(downloadForumPostsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadForumPost(tappedId, token);
      dispatch(addForumPost(result));
      dispatch(setForumPostSelectedContentFormat(result.contentFormat));
    }
  } finally {
    dispatch(downloadingDeletingForumPost(false));
  }
};
