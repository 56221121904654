import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { toNumber } from 'lodash';
import moment from 'moment';
import {
  clearQuizes, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setQuizSearchText, setQuizSelectedPageSize, setQuizSelectedOrderBy,
  setQuizTappedId, addEditQuizAsync, downloadDeleteQuizAsync, downloadQuizesAsync,
  setQuizCategorySearchText, clearQuizCategories, downloadQuizCategoriesAsync,
  setQuizAdvancedFilterDialogSelectedFilterString, setQuizSelectedVisibilityStatus,
  setQuizSelectedQuizCategoryId, setFunctionalPageMode, saveQuizResultAsync,
  sendQuizNotificationAsync, copyingQuizDeeplink,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_QUIZES, MENUID_QUIZ_QUIZ, PAGE_MODE_TABLE,
  RXFIELD_QUIZ_CATEGORY, DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_QUIZ_VISIBILITY_STATUS, RXFORM_QUIZ,
  ROUTE_NAME_QUIZ_RESPONSE,
} from '../../constant';
import {
  localDateToUtc, toMoment, transformDropdownData, transformInitialValues,
  sortAsc,
} from '../../helper';
import LocalizedString from '../../../../localization';
import QuizPage from './quiz.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getTop3Array = (array) => {
  const top3Array = array.sort((a, b) => sortAsc(a.rank, b.rank))
    .map((item) => {
      const newDuration = moment.duration(item.durationMilliseconds);
      return ({
        rank: item.rank,
        userName: item.user.fullName,
        score: item.score,
        duration: `${newDuration.minutes()} min ${newDuration.seconds()} sec`,
      });
    }).filter((x) => x.rank <= 3);
  const firstElement = top3Array[0];
  const newTop3Array = top3Array.slice(1);
  newTop3Array.splice(1, 0, firstElement);

  return newTop3Array;
};

const getInitialValues = (state) => {
  const { quizes, uiFunctionalPage, uiQuiz } = state;
  const { data } = quizes;
  const { downloadingDeleting, tappedId } = uiQuiz;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    validUntil: found.validUntil
      ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    validFrom: found.validFrom
      ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    category: found.category
      ? { label: found.category.name, value: found.category.id }
      : { label: '', value: '' },
    result: found.result && found.result.length > 0
      ? found.result.sort((a, b) => sortAsc(a.rank, b.rank))
        .map((item) => {
          const newDuration = moment.duration(item.durationMilliseconds);
          return ({
            rank: item.rank,
            userName: item.user.fullName,
            score: item.score,
            duration: `${newDuration.minutes()} min ${newDuration.seconds()} sec`,
          });
        }).filter((x) => x.rank > 3) : [],
    top3: found.result && found.result.length > 0 ? getTop3Array(found.result) : [],
  }) : {
    title: '',
    description: '',
    category: { label: '', value: '' },
    validFrom: '',
    validUntil: '',
    visibilityStatus: '',
    top3: [],
  };

  return result;
};

const mapStateToProps = (state) => ({
  quizCategories: transformDropdownData(state.quizCategories.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiQuiz.addingEditing,
  downloading: state.uiQuiz.downloading,
  downloadingDeleting: state.uiQuiz.downloadingDeleting,
  loadingQuizCategories: state.uiQuizCategory.downloading,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId,
  saving: state.uiQuiz.saving,
  sendingNotification: state.uiQuiz.sendingNotification,
  copying: state.uiQuiz.copying,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setQuizCategorySearchText(''));
    dispatch(clearQuizCategories());
    dispatch(downloadQuizCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setQuizAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_QUIZ_QUIZ));
    dispatch(setQuizSearchText(''));
    dispatch(clearQuizes());
    dispatch(setQuizSelectedPageSize(20));
    dispatch(setQuizSelectedOrderBy(INITIAL_ORDER_BY_QUIZES));
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/category/, 'category.id');
    dispatch(setQuizAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadQuizesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setQuizSelectedPageSize(pageSize));
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeQuizCategoryText: async (text) => {
    try {
      dispatch(setQuizCategorySearchText(text));
      dispatch(clearQuizCategories());
      await dispatch(downloadQuizCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onQuizCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizSelectedQuizCategoryId(option.value));
      dispatch(change(RXFORM_QUIZ, RXFIELD_QUIZ_CATEGORY, option));
    } else {
      dispatch(setQuizSelectedQuizCategoryId(''));
      dispatch(change(RXFORM_QUIZ, RXFIELD_QUIZ_CATEGORY, ''));
    }
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_QUIZ));
    dispatch(setQuizSelectedQuizCategoryId(''));
    dispatch(setQuizSelectedVisibilityStatus(''));
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setQuizSelectedQuizCategoryId(''));
    dispatch(setQuizSelectedVisibilityStatus(''));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteQuizAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setQuizTappedId(''));
    dispatch(setQuizCategorySearchText(''));
    dispatch(clearQuizCategories());
    dispatch(downloadQuizCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setQuizTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setQuizTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setQuizSelectedPageSize(pageSize));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setQuizAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearQuizes());
    dispatch(setQuizSelectedOrderBy(INITIAL_ORDER_BY_QUIZES));
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, description, validFrom, validUntil, durationMinutes,
  }) => {
    try {
      await dispatch(addEditQuizAsync(
        title, description, localDateToUtc(validFrom),
        localDateToUtc(validUntil), toNumber(durationMinutes),
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setQuizSearchText(text));
      dispatch(clearQuizes());
      await dispatch(downloadQuizesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setQuizSelectedOrderBy(orderBy));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, description, validFrom, validUntil, durationMinutes,
  }) => {
    await dispatch(addEditQuizAsync(
      title, description, localDateToUtc(validFrom),
      localDateToUtc(validUntil), toNumber(durationMinutes),
    ));
  },
  onViewPressed: (id) => {
    dispatch(setQuizTappedId(id));
    dispatch(downloadDeleteQuizAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_QUIZ, RXFIELD_QUIZ_VISIBILITY_STATUS, option));
    } else {
      dispatch(setQuizSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_QUIZ, RXFIELD_QUIZ_VISIBILITY_STATUS, ''));
    }
  },
  onShowParticipantPressed: (quizId, title, history) => {
    const text = `quiz.id=${quizId}`;
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    history.push({
      pathname: ROUTE_NAME_QUIZ_RESPONSE,
      state: {
        filterString: text,
        selectedQuiz: { label: title, value: quizId },
      },
    });
  },
  onDownloadPressed: () => {
    dispatch(saveQuizResultAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onSendToMePressed: () => {
    dispatch(sendQuizNotificationAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCopyPressed: (status) => {
    dispatch(copyingQuizDeeplink(!status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
