import { change } from 'redux-form';
import {
  addCalendar,
  clearCalendars,
  downloadingDeletingCalendar,
  setAlertErrorMessage,
  setFunctionalPageMode,
  setCalendarTappedId,
  setCalendarTableDataParentCalendars,
  setCalendarDays,
} from '../simple-action';
import {
  downloadCalendar, deleteCalendar, transformCalendarParentCalendarsData, sortAsc,
} from '../../../helper';
import { PAGE_MODE_TABLE, RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS, RXFORM_CALENDAR } from '../../../constant';
import downloadCalendarsAsync from './downloadCalendarsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCalendar(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCalendar;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteCalendar(tappedId, token);
      dispatch(setCalendarTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCalendars());
      dispatch(downloadCalendarsAsync(1)).catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const calendar = await downloadCalendar(tappedId, token);
      const transformedDays = calendar.days.length > 0 ? calendar.days.map((day) => ({ ...day, date: day.date.split('T')[0] })) : [];
      const sortedParents = calendar.parents.length > 0
        ? calendar.parents.sort((a, b) => sortAsc(a.order, b.order)) : [];
      dispatch(addCalendar(calendar));
      dispatch(setCalendarDays(transformedDays));
      dispatch(setCalendarTableDataParentCalendars(
        transformCalendarParentCalendarsData(sortedParents),
      ));
      dispatch(change(
        RXFORM_CALENDAR,
        RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS,
        transformCalendarParentCalendarsData(sortedParents),
      ));
    }
  } finally {
    dispatch(downloadingDeletingCalendar(false));
  }
};
