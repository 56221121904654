import {
  addApiKey, clearApiKeys, downloadingDeletingApiKey, setAlertErrorMessage,
  setFunctionalPageMode, setApiKeyTappedId,
} from '../simple-action';
import { downloadApiKey, deleteApiKey } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadApiKeysAsync from './downloadApiKeysAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingApiKey(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiApiKey;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteApiKey(tappedId, token);
      dispatch(setApiKeyTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearApiKeys());
      dispatch(downloadApiKeysAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const apikey = await downloadApiKey(tappedId, token);
      dispatch(addApiKey(apikey));
    }
  } finally {
    dispatch(downloadingDeletingApiKey(false));
  }
};
