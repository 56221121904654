import { addWhatsapp, resendingWhatsapp } from '../simple-action';
import { resendWhatsapp, downloadWhatsapp } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingWhatsapp(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWhatsapp;

    await resendWhatsapp(tappedId, token);
    const result = await downloadWhatsapp(tappedId, token);

    dispatch(addWhatsapp(result));
  } finally {
    dispatch(resendingWhatsapp(false));
  }
};
