import {
  DOWNLOADING_WORKING_HOUR_ADJUSTMENTS,
  DOWNLOADING_WORKING_HOUR_ADJUSTMENT,
  SET_WORKING_HOUR_ADJUSTMENT_SELECTED_PAGE_SIZE,
  SET_WORKING_HOUR_ADJUSTMENT_TAPPED_ID,
  SET_WORKING_HOUR_ADJUSTMENT_SEARCH_TEXT,
  SET_WORKING_HOUR_ADJUSTMENT_SELECTED_ORDER_BY,
  SET_WORKING_HOUR_ADJUSTMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_WORKING_HOUR_ADJUSTMENT_SELECTED_REQUESTER,
  SET_WORKING_HOUR_ADJUSTMENT_SELECTED_WORKING_HOUR,
} from '../action';
import { INITIAL_ORDER_BY_WORKING_HOUR_ADJUSTMENTS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_WORKING_HOUR_ADJUSTMENTS,
  selectedRequester: '',
  selectedWorkingHour: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WORKING_HOUR_ADJUSTMENTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_WORKING_HOUR_ADJUSTMENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_WORKING_HOUR_ADJUSTMENT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WORKING_HOUR_ADJUSTMENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WORKING_HOUR_ADJUSTMENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WORKING_HOUR_ADJUSTMENT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WORKING_HOUR_ADJUSTMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_WORKING_HOUR_ADJUSTMENT_SELECTED_REQUESTER:
      return { ...state, selectedRequester: action.value };
    case SET_WORKING_HOUR_ADJUSTMENT_SELECTED_WORKING_HOUR:
      return { ...state, selectedWorkingHour: action.value };
    default:
      return state;
  }
};
