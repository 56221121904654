import {
  addSurvey, clearSurveys, downloadingDeletingSurvey, setAlertErrorMessage,
  setFunctionalPageMode, setSurveyTappedId, setSurveySelectedCategoryId,
  setSurveySelectedVisibilityStatus,
} from '../simple-action';
import { downloadSurvey, deleteSurvey } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadSurveysAsync from './downloadSurveysAsync';
import { getDeepLinkScheme } from '../../../../configuration/helper';

const DEEP_LINK_PATH = 'app/survey/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSurvey(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSurvey;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSurvey(tappedId, token);
      dispatch(setSurveyTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSurveys());
      dispatch(downloadSurveysAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const survey = await downloadSurvey(tappedId, token);

      const result = await getDeepLinkScheme(survey, DEEP_LINK_PATH, tappedId, token);

      dispatch(addSurvey(result));
      dispatch(setSurveySelectedCategoryId(survey.category.id));
      dispatch(setSurveySelectedVisibilityStatus(survey.visibilityStatus));
    }
  } finally {
    dispatch(downloadingDeletingSurvey(false));
  }
};
