import { reset } from 'redux-form';
import {
  addingEditingCovidData, clearCovidDatas, setAlertErrorMessage,
  setFunctionalPageMode, setCovidDataSelectedEmployeeStatus,
} from '../simple-action';
import { addCovidData, editCovidData, toMoment } from '../../../helper';
import {
  PAGE_MODE_TABLE, RXFORM_COVID_DATA, REVERSED_ISO_DATE_FORMAT,
} from '../../../constant';
import downloadCovidDatasAsync from './downloadCovidDatasAsync';

export default (date, hospitalized, selfIsolation, death, recovered, vaccinated, rtAntibody,
  rtAntigen, genose, serologi, pcr) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCovidData(true));

    const { token } = getState().authentication;
    const { tappedId, selectedEmployeeStatus } = getState().uiCovidData;

    const selectedDate = toMoment(date).format(REVERSED_ISO_DATE_FORMAT);

    if (tappedId) {
      await editCovidData(tappedId,
        selectedDate, selectedEmployeeStatus, hospitalized,
        selfIsolation, death, recovered,
        vaccinated, rtAntibody, rtAntigen,
        genose, serologi, pcr,
        token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addCovidData(
        selectedDate, selectedEmployeeStatus, hospitalized,
        selfIsolation, death, recovered,
        vaccinated, rtAntibody, rtAntigen,
        genose, serologi, pcr, token,
      );
    }

    dispatch(reset(RXFORM_COVID_DATA));
    dispatch(clearCovidDatas());
    dispatch(downloadCovidDatasAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setCovidDataSelectedEmployeeStatus(''));
  } finally {
    dispatch(addingEditingCovidData(false));
  }
};
