import { downloadingProfileSyncs, setProfileSyncs } from '../simple-action';
import { downloadProfileSyncs, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, profileSyncs, uiProfileSync } = getState();
  const { token } = authentication;
  const { meta } = profileSyncs;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiProfileSync;
  const { filterString } = getState().uiFunctionalPage;

  try {
    dispatch(downloadingProfileSyncs(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProfileSyncs(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setProfileSyncs(list));
    }
  } finally {
    dispatch(downloadingProfileSyncs(false));
  }
};
