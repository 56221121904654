import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { isBoolean } from 'lodash';
import {
  PAGE_MODE_TABLE,
  INITIAL_ORDER_BY_INBOXES,
  RXFORM_INBOX,
  MENUID_NOTIFICATION_INBOX,
  REST_BASE_URL,
  REST_URL_IMAGE_STORAGE,
  RXFIELD_INBOX_TITLE,
  RXFIELD_INBOX_BODY,
  RXFIELD_INBOX_READ,
  RXFIELD_INBOX_FLAG,
  RXFIELD_INBOX_PAYLOAD,
  RXFIELD_INBOX_IMAGE,
  RXFIELD_INBOX_FULL_NAME,
  RXFIELD_INBOX_ANDROID_APP_ID,
  RXFIELD_INBOX_IOS_APP_ID,
  RXFIELD_INBOX_DOWNLOAD_URL,
  RXFIELD_INBOX_ICON,
  RXFIELD_INBOX_USER_ID,
} from '../../constant';
import {
  transformInitialValues,
  transformUserDropdownData,
  debounceSearch,
} from '../../helper';
import {
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setInboxSearchText,
  clearInboxes,
  setInboxSelectedPageSize,
  setInboxSelectedOrderBy,
  setInboxAdvancedFilterDialogSelectedFilterString,
  setInboxTappedId,
  downloadInboxesAsync,
  downloadInboxAsync,
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  clearProfiles,
} from '../../redux/action';
import {
  downloadProfilesAsync,
} from '../../../../redux/action';

import InboxPage from './inbox.presentation';

const getInitialValues = (state) => {
  const {
    inboxes,
    uiFunctionalPage,
    uiInbox,
  } = state;
  const { data } = inboxes;
  const { downloadingDeleting, tappedId } = uiInbox;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      [RXFIELD_INBOX_TITLE]: found?.title ? found?.title : '',
      [RXFIELD_INBOX_BODY]: found?.body ? found?.body : '',
      [RXFIELD_INBOX_READ]: isBoolean(found?.read) ? found?.read?.toString() : '',
      [RXFIELD_INBOX_FLAG]: found?.pushNotificationFlag ? found?.pushNotificationFlag : '',
      [RXFIELD_INBOX_PAYLOAD]: found?.payloadString ? found?.payloadString : '',
      [RXFIELD_INBOX_IMAGE]: found?.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : '',
      [RXFIELD_INBOX_FULL_NAME]: found?.user?.fullName ? found?.user?.fullName : '',
      [RXFIELD_INBOX_ANDROID_APP_ID]: found?.androidAppId ? found?.androidAppId : '',
      [RXFIELD_INBOX_IOS_APP_ID]: found?.iosAppId ? found?.iosAppId : '',
      [RXFIELD_INBOX_DOWNLOAD_URL]: found?.downloadUrl ? found?.downloadUrl : '',
      [RXFIELD_INBOX_ICON]: found?.iconUrl ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.iconUrl}` : '',
      [RXFIELD_INBOX_USER_ID]: found?.user?.id ? found?.user?.id : '',
    })
    : {};
  return result;
};

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapStateToProps = (state) => ({
  downloading: state.uiInbox.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.profiles.data),
  loadingUsers: state.uiProfile.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NOTIFICATION_INBOX));
    dispatch(setInboxSearchText(''));
    dispatch(clearInboxes());
    dispatch(setInboxSelectedPageSize(20));
    dispatch(
      setInboxSelectedOrderBy(
        INITIAL_ORDER_BY_INBOXES,
      ),
    );
    dispatch(downloadInboxesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/userName/, 'user.id');
    dispatch(
      setInboxAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearInboxes());
    dispatch(downloadInboxesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadInboxesAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setInboxSelectedPageSize(pageSize));
    dispatch(downloadInboxesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setInboxSelectedPageSize(pageSize));
    dispatch(clearInboxes());
    dispatch(downloadInboxesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearInboxes());
    dispatch(
      setInboxAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(
      setInboxSelectedOrderBy(
        INITIAL_ORDER_BY_INBOXES,
      ),
    );
    dispatch(downloadInboxesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setInboxSearchText(text));
      dispatch(clearInboxes());
      await dispatch(downloadInboxesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setInboxSelectedOrderBy(orderBy));
    dispatch(clearInboxes());
    dispatch(downloadInboxesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setInboxTappedId(id));
    dispatch(downloadInboxAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_INBOX));
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InboxPage);
