import {
  CROPPING_IMAGE, SET_CROP_IMAGE_AREA_PIXELS, SET_CROP_IMAGE_POSITION, SET_CROP_IMAGE_RESULT,
  SET_CROP_IMAGE_SOURCE, SET_CROP_IMAGE_ZOOM, RESET_CROP_IMAGE,
} from '../action';

const initialState = {
  cropping: false,
  crop: { x: 0, y: 0 },
  croppedAreaPixels: {},
  croppedImage: '',
  fileName: '',
  imageSrc: '',
  zoom: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CROPPING_IMAGE: {
      return { ...state, cropping: action.status };
    }
    case SET_CROP_IMAGE_POSITION: {
      return { ...state, crop: action.position };
    }
    case SET_CROP_IMAGE_AREA_PIXELS: {
      return { ...state, croppedAreaPixels: action.area };
    }
    case SET_CROP_IMAGE_RESULT: {
      return { ...state, croppedImage: action.text };
    }
    case SET_CROP_IMAGE_SOURCE: {
      return { ...state, imageSrc: action.image, fileName: action.name };
    }
    case SET_CROP_IMAGE_ZOOM: {
      return { ...state, zoom: action.number };
    }
    case RESET_CROP_IMAGE: {
      return initialState;
    }
    default: return state;
  }
};
