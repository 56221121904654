import {
  downloadingMostViewedNews, setMostViewedNews,
  clearMostViewedNews,
} from '../simple-action';
import {
  downloadMostViewedNews, getStartEndTime,
} from '../../../helper';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingMostViewedNews(true));
    const { token } = getState().authentication;
    const { selectedFilter } = getState().uiNewsDashboard;
    const startEndTime = getStartEndTime(selectedFilter);

    const response = await downloadMostViewedNews(startEndTime, token);

    dispatch(clearMostViewedNews());
    dispatch(setMostViewedNews(response));
  } finally {
    dispatch(downloadingMostViewedNews(false));
  }
};
