import {
  uploadingPDFTemplate, setPDFTemplate, setUploadingPDFVisibility, setPDFData,
} from '../simple-action';
import { uploadPDFTemplate } from '../../../helper';

export default () => async (dispatch, getState) => {
  const {
    authentication, uiUploadPdf,
  } = getState();
  const { token } = authentication;
  const { pdfUrl } = uiUploadPdf;

  try {
    dispatch(uploadingPDFTemplate(true));

    const data = await uploadPDFTemplate(
      pdfUrl,
      token,
    );

    if (data) {
      dispatch(setPDFTemplate(data.path));
      dispatch(setPDFData());
    }
  } finally {
    dispatch(uploadingPDFTemplate(false));
    dispatch(setUploadingPDFVisibility(false));
  }
};
