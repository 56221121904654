/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, IconButton, makeStyles, Dialog, DialogContent, Snackbar, Tooltip,
} from '@material-ui/core';
import {
  Download, Close, ContentCopy,
} from 'mdi-material-ui';
import {
  FunctionalPage, SectionTitle, VerticalSpacer, AccentButton,
} from '../../component';
import {
  PAGE_MODE_VIEW, RXFORM_APPROVAL,
  RXSTATE_APPROVALS, RXSTATE_APPROVAL_PAGE,
  FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_FORMAT,
  FILTER_TYPE_DROPDOWN,
  APPROVAL_STATUS,
  RXFIELD_APPROVAL_PROCESS_NAME,
  RXFIELD_APPROVAL_APPROVAL_NUMBER,
  RXFIELD_APPROVAL_TITLE,
  RXFIELD_APPROVAL_DESCRIPTION,
  RXFIELD_APPROVAL_NOTES,
  RXFIELD_APPROVAL_APPROVAL_STATUS,
  RXFIELD_APPROVAL_STATUS_CHANGED_AT,
  RXFIELD_APPROVAL_REQUESTER_ID,
  RXFIELD_APPROVAL_REQUESTER_NAME,
  RXFIELD_APPROVAL_REQUESTER_NOTES,
  RXFIELD_APPROVAL_REFERENCE_ID,
  RXFIELD_APPROVAL_PROVIDER_NAME,
  RXFIELD_APPROVAL_ALLOW_TO_ADD_ATTACHMENT,
  RXFIELD_APPROVAL_STEPS,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_APPROVAL_ATTACHMENTS,
  RXFIELD_APPROVAL_DATA,
  RXFIELD_APPROVAL_APPROVERS,
  RXFIELD_APPROVAL_COMMENTS,
  snackbarDuration,
  RXFIELD_APPROVAL_DISABLE_EMAIL_NOTIFICATION,
  RXFIELD_APPROVAL_DISABLE_APP_NOTIFICATION,
  RXFIELD_APPROVAL_RESEND_EVENT,
  RESEND_EVENT_AT_VALUES,
  RXFIELD_APPROVAL_TAG,
  FILTER_TYPE_TEXT,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormEditableTableField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import { toMoment } from '../../helper';
import { approval } from '../../../../constant';

const approversIcon = require('../../../../asset/approvers-icon.png');
const commentsIcon = require('../../../../asset/comments-icon.png');

const useStyles = makeStyles(() => ({
  approverCommentButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  resendButton: {
    padding: '5px',
    height: '24px',
    marginTop: '11px',
  },
  resendFieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const renderApproverCommentButton = (
  classes, setShowApproversPopUp, setShowCommentsPopUp, id,
  onStepActionButtonPressed, comments,
) => (
  <div className={classes.approverCommentButtonsContainer}>
    <Tooltip title={LocalizedString.approvalPage.buttonCaptionApprovers}>
      <IconButton
        onClick={() => onStepActionButtonPressed(setShowApproversPopUp, id)}
      >
        <img alt="approvers" src={approversIcon} width={24} height={24} />
      </IconButton>
    </Tooltip>
    {comments?.length ? (
      <Tooltip title={LocalizedString.approvalPage.buttonCaptionComments}>
        <IconButton
          onClick={() => onStepActionButtonPressed(setShowCommentsPopUp, id)}
        >
          <img alt="comments" src={commentsIcon} width={24} height={24} />
        </IconButton>
      </Tooltip>
    ) : null}
  </div>
);

const renderDownloadButton = (onDownloadAttachmentPressed, item) => (
  <IconButton
    onClick={() => onDownloadAttachmentPressed(item)}
  >
    <Download />
  </IconButton>
);

const renderDialogContent = (
  downloadingDetail, pageMode,
  stepsCurrentPage,
  stepsTotalCount,
  defaultStepsValue,
  classes,
  attachmentsCurrentPage,
  attachmentsTotalCount,
  defaultAttachmentsValue,
  onDownloadAttachmentPressed,
  setShowApproversPopUp,
  setShowCommentsPopUp,
  dataCurrentPage,
  dataTotalCount,
  defaultDataValue,
  onStepActionButtonPressed,
  onCopyDataValuePressed,
  showCopiedSnackbar,
  setCopiedSnackbar,
  resendingEvent,
  onResendEventOptionSelected,
  onResendEventPressed,
  selectedResendEventOption,
  tappedId,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_PROCESS_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelProcessName}
            label={LocalizedString.approvalPage.labelProcessName}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_APPROVAL_NUMBER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelApprovalNumber}
            label={LocalizedString.approvalPage.labelApprovalNumber}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelTitle}
            label={LocalizedString.approvalPage.labelTitle}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelDescription}
            label={LocalizedString.approvalPage.labelDescription}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_TAG}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelTag}
            label={LocalizedString.approvalPage.labelTag}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelNotes}
            label={LocalizedString.approvalPage.labelNotes}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_APPROVAL_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelApprovalStatus}
            label={LocalizedString.approvalPage.labelApprovalStatus}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_STATUS_CHANGED_AT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelStatusChangedAt}
            label={LocalizedString.approvalPage.labelStatusChangedAt}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid container item className={classes.resendFieldContainer}>
          <Grid item sm md>
            <Field
              name={RXFIELD_APPROVAL_RESEND_EVENT}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.approvalPage.placeholderResendEvent}
              label={LocalizedString.approvalPage.placeholderResendEvent}
              disabled={resendingEvent}
              data={RESEND_EVENT_AT_VALUES}
              onOptionSelected={onResendEventOptionSelected}
            />
          </Grid>
          <AccentButton
            caption={LocalizedString.approvalPage.buttonCaptionResendEvent}
            onClick={() => onResendEventPressed(tappedId)}
            disabled={!selectedResendEventOption}
            loading={resendingEvent}
            style={classes.resendButton}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_REQUESTER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelRequesterId}
            label={LocalizedString.approvalPage.labelRequesterId}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_REQUESTER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelRequesterName}
            label={LocalizedString.approvalPage.labelRequesterName}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_REQUESTER_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelRequesterNotes}
            label={LocalizedString.approvalPage.labelRequesterNotes}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelReferenceId}
            label={LocalizedString.approvalPage.labelReferenceId}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_PROVIDER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelProviderName}
            label={LocalizedString.approvalPage.labelProviderName}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_ALLOW_TO_ADD_ATTACHMENT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelAllowToAddAttachment}
            label={LocalizedString.approvalPage.labelAllowToAddAttachment}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_DISABLE_EMAIL_NOTIFICATION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelDisableEmailNotification}
            label={LocalizedString.approvalPage.labelDisableEmailNotification}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_DISABLE_APP_NOTIFICATION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalPage.labelDisableAppNotification}
            label={LocalizedString.approvalPage.labelDisableAppNotification}
            disabled={downloadingDetail || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.approvalPage.labelSteps} />

    <Field
      name={RXFIELD_APPROVAL_STEPS}
      component={renderReduxFormEditableTableField}
      disabled
      disableEdit
      disableDelete
      loading={downloadingDetail}
      currentPage={stepsCurrentPage}
      totalCount={stepsTotalCount}
      defaultValue={defaultStepsValue}
      tableColumns={[
        {
          title: LocalizedString.approvalPage.labelOrder, field: 'order', sorting: false, width: 40,
        },
        { title: LocalizedString.approvalPage.labelLabel, field: 'stepLabel', sorting: false },
        { title: LocalizedString.approvalPage.labelStatus, field: 'stepStatus', sorting: false },
        {
          title: LocalizedString.approvalPage.labelStartedAt,
          field: 'approvalStartedAt',
          sorting: false,
          render: ({ approvalStartedAt }) => (approvalStartedAt
            ? toMoment(approvalStartedAt).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.approvalPage.labelModifiedAt,
          field: 'statusChangedAt',
          sorting: false,
          render: ({ statusChangedAt }) => (statusChangedAt
            ? toMoment(statusChangedAt).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.approvalPage.labelNotes, field: 'notes', sorting: false },
        {
          title: '',
          field: '',
          sorting: false,
          render: ({ id, comments }) => renderApproverCommentButton(
            classes, setShowApproversPopUp, setShowCommentsPopUp, id,
            onStepActionButtonPressed, comments,
          ),
        },
      ]}
      disableToolbar
    />

    <SectionTitle title={LocalizedString.approvalPage.labelAttachments} />

    <Field
      name={RXFIELD_APPROVAL_ATTACHMENTS}
      component={renderReduxFormEditableTableField}
      disabled
      disableEdit
      disableDelete
      loading={downloadingDetail}
      currentPage={attachmentsCurrentPage}
      totalCount={attachmentsTotalCount}
      defaultValue={defaultAttachmentsValue}
      tableColumns={[
        { title: LocalizedString.approvalPage.labelLabel, field: 'label', sorting: false },
        { title: LocalizedString.approvalPage.labelFileName, field: 'fileName', sorting: false },
        {
          title: '',
          field: '',
          sorting: false,
          render: (item) => renderDownloadButton(
            onDownloadAttachmentPressed, item,
          ),
        },
      ]}
      disableToolbar
    />

    <SectionTitle title={LocalizedString.approvalPage.labelData} />

    <Field
      name={RXFIELD_APPROVAL_DATA}
      component={renderReduxFormEditableTableField}
      disabled
      disableEdit
      disableDelete
      loading={downloadingDetail}
      currentPage={dataCurrentPage}
      totalCount={dataTotalCount}
      defaultValue={defaultDataValue}
      tableLayout="fixed"
      tableColumns={[
        {
          title: LocalizedString.approvalPage.labelFieldName, field: 'fieldName', sorting: false, width: '25%',
        },
        {
          title: LocalizedString.approvalPage.labelValue,
          field: 'value',
          sorting: false,
          width: '70%',
          render: ({ value }) => {
            if (value.length > 200) {
              return `${value.slice(0, 200)}...`;
            }
            return value;
          },
        },
        {
          title: '',
          field: 'value',
          sorting: false,
          width: '5%',
          render: ({ value }) => (
            <IconButton
              size="small"
              onClick={() => { onCopyDataValuePressed(value); setCopiedSnackbar(true); }}
            >
              <ContentCopy />
            </IconButton>
          )
          ,
        },
      ]}
      disableToolbar
    />
    <Snackbar
      open={showCopiedSnackbar}
      autoHideDuration={snackbarDuration}
      message={GlobalLocalizedString.common.labelCopied}
      onClose={() => setCopiedSnackbar(false)}
    />
  </Grid>
);

const renderApproversPopUp = (
  showApproversPopUp, downloadingDetail, currentPage, totalCount, defaultValue,
  setShowApproversPopUp,
) => (
  <Dialog
    open={showApproversPopUp}
    fullWidth
    maxWidth="lg"
  >
    <DialogContent>
      <IconButton
        onClick={() => setShowApproversPopUp(false)}
      >
        <Close />
      </IconButton>
      <VerticalSpacer height={10} />
      <Field
        name={RXFIELD_APPROVAL_APPROVERS}
        component={renderReduxFormEditableTableField}
        disabled
        disableEdit
        disableDelete
        loading={downloadingDetail}
        currentPage={currentPage}
        totalCount={totalCount}
        defaultValue={defaultValue}
        tableColumns={[
          {
            title: LocalizedString.approvalPage.labelUserId, field: 'user.id', sorting: false, width: 40,
          },
          {
            title: LocalizedString.approvalPage.labelFullName, field: 'user.fullName', sorting: false, width: 80,
          },
          {
            title: LocalizedString.approvalPage.labelStatus, field: 'approvalStatus', sorting: false, width: 40,
          },
          {
            title: LocalizedString.approvalPage.labelModifiedAt,
            field: 'statusChangedAt',
            sorting: false,
            render: ({ statusChangedAt }) => (statusChangedAt
              ? toMoment(statusChangedAt).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
            width: 40,
          },
          {
            title: LocalizedString.approvalPage.labelNotes, field: 'notes', sorting: false, width: 80,
          },
        ]}
        disableToolbar
      />
    </DialogContent>
  </Dialog>
);

const renderCommentsPopUp = (
  showCommentsPopUp, downloadingDetail, currentPage, totalCount, defaultValue,
  setShowCommentsPopUp,
) => (
  <Dialog
    open={showCommentsPopUp}
    fullWidth
    maxWidth="lg"
  >
    <DialogContent>
      <IconButton
        onClick={() => setShowCommentsPopUp(false)}
      >
        <Close />
      </IconButton>
      <VerticalSpacer height={10} />
      <Field
        name={RXFIELD_APPROVAL_COMMENTS}
        component={renderReduxFormEditableTableField}
        disabled
        disableEdit
        disableDelete
        loading={downloadingDetail}
        currentPage={currentPage}
        totalCount={totalCount}
        defaultValue={defaultValue}
        tableColumns={[
          {
            title: LocalizedString.approvalPage.labelCreatedDate,
            field: 'createdDate',
            sorting: false,
            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
          { title: LocalizedString.approvalPage.labelFullName, field: 'user.fullName', sorting: false },
          { title: LocalizedString.approvalPage.labelNotes, field: 'notes', sorting: false },
        ]}
        disableToolbar
      />
    </DialogContent>
  </Dialog>
);

const ApprovalPage = ({
  downloading, downloadingDetail,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onRefresh, onSearchBarTextChanged,
  onSortPressed, onViewPressed,
  pageMode,
  loadingRequester, requesters, onChangeRequesterText,
  loadingProviderNames, providerNames,
  loadingProcessNames, processNames,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onResetAdvancedFilterPressed, initialValues, onDownloadAttachmentPressed,
  onStepActionButtonPressed, onCopyDataValuePressed,
  approvers, comments, resendingEvent,
  onResendEventOptionSelected,
  onResendEventPressed,
  selectedResendEventOption,
  onConfirmResendEventPressed,
  tappedId,
}) => {
  const classes = useStyles();

  const [showApproversPopUp, setShowApproversPopUp] = useState(false);
  const [showCommentsPopUp, setShowCommentsPopUp] = useState(false);
  const [showCopiedSnackbar, setCopiedSnackbar] = useState(false);

  return (
    <div>
      <FunctionalPage
        data={RXSTATE_APPROVALS}
        uiPage={RXSTATE_APPROVAL_PAGE}
        tableColumns={[
          {
            title: LocalizedString.approvalPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.approvalPage.labelProcessName, field: 'processName', sorting: !downloading },
          { title: LocalizedString.approvalPage.labelApprovalNumber, field: 'approvalNumber', sorting: !downloading },
          { title: LocalizedString.approvalPage.labelTitle, field: 'title', sorting: !downloading },
          { title: LocalizedString.approvalPage.labelRequester, field: 'requester.fullName', sorting: !downloading },
          { title: LocalizedString.approvalPage.labelApprovalStatus, field: 'approvalStatus', sorting: !downloading },
          {
            title: LocalizedString.approvalPage.labelCreatedDate,
            field: 'createdDate',
            sorting: !downloading,
            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.approvalPage.labelCreatedDate,
            field: 'createdDate',
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_FORMAT,
          },
          {
            title: LocalizedString.approvalPage.labelStatusChangedAt,
            field: 'statusChangedAt',
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_FORMAT,
          },
          {
            title: LocalizedString.approvalPage.labelApprovalStatus,
            field: 'approvalStatus',
            type: FILTER_TYPE_DROPDOWN,
            data: APPROVAL_STATUS,
          },
          {
            title: LocalizedString.approvalPage.labelRequester,
            field: 'requester',
            type: FILTER_TYPE_DROPDOWN,
            loading: loadingRequester,
            data: requesters,
            onChangeFilterText: onChangeRequesterText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.approvalPage.labelProviderName,
            field: 'providerName',
            type: FILTER_TYPE_DROPDOWN,
            loading: loadingProviderNames,
            data: providerNames,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.approvalPage.labelAllowToAddAttachment,
            field: 'allowToAddAttachments',
            type: FILTER_TYPE_DROPDOWN,
            data: approval,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.approvalPage.labelDisableAppNotification,
            field: 'disableEmailNotification',
            type: FILTER_TYPE_DROPDOWN,
            data: approval,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.approvalPage.labelDisableEmailNotification,
            field: 'disableAppNotification',
            type: FILTER_TYPE_DROPDOWN,
            data: approval,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.approvalPage.labelProcessName,
            field: 'processName',
            type: FILTER_TYPE_DROPDOWN,
            loading: loadingProcessNames,
            data: processNames,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.approvalPage.labelTag,
            field: RXFIELD_APPROVAL_TAG,
            type: FILTER_TYPE_TEXT,
          },
        ]}
        handleSubmit={handleSubmit}
        onAppear={onAppear}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onRefresh={onRefresh}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        useFullWidth
        disableCreate
        disableEdit
        disableDelete
        title={LocalizedString.approvalPage.title}
        useTwoColumnFilterDialog
        onConfirmDeletePressed={onConfirmResendEventPressed}
      >
        {renderDialogContent(
          downloadingDetail, pageMode,
          1,
      initialValues?.steps?.length || 0,
      initialValues.steps,
      classes,
      1,
      initialValues?.attachments?.length || 0,
      initialValues.attachments,
      onDownloadAttachmentPressed,
      setShowApproversPopUp,
      setShowCommentsPopUp,
      1,
      initialValues?.data?.length || 0,
      initialValues.data,
      onStepActionButtonPressed,
      onCopyDataValuePressed,
      showCopiedSnackbar,
      setCopiedSnackbar,
      resendingEvent,
      onResendEventOptionSelected,
      onResendEventPressed,
      selectedResendEventOption,
      tappedId,
        )}
      </FunctionalPage>
      {renderApproversPopUp(
        showApproversPopUp,
        downloadingDetail,
        1,
        approvers?.length || 0,
        approvers,
        setShowApproversPopUp,
      )}
      {renderCommentsPopUp(
        showCommentsPopUp,
        downloadingDetail,
        1,
        comments?.length || 0,
        comments,
        setShowCommentsPopUp,
      )}
    </div>
  );
};

export default reduxForm({
  form: RXFORM_APPROVAL,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ApprovalPage);

ApprovalPage.propTypes = {
  requesters: PropTypes.arrayOf(SimpleDataShape).isRequired,
  providerNames: PropTypes.arrayOf(SimpleDataShape).isRequired,
  processNames: PropTypes.arrayOf(SimpleDataShape).isRequired,
  approvers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  comments: PropTypes.arrayOf(SimpleDataShape).isRequired,

  initialValues: FormInitialValueShape.isRequired,

  downloading: PropTypes.bool.isRequired,
  downloadingDetail: PropTypes.bool.isRequired,
  loadingRequester: PropTypes.bool.isRequired,
  loadingProviderNames: PropTypes.bool.isRequired,
  loadingProcessNames: PropTypes.bool.isRequired,
  resendingEvent: PropTypes.bool.isRequired,

  pageMode: PropTypes.string.isRequired,
  selectedResendEventOption: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,

  onChangeRequesterText: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onDownloadAttachmentPressed: PropTypes.func.isRequired,
  onStepActionButtonPressed: PropTypes.func.isRequired,
  onCopyDataValuePressed: PropTypes.func.isRequired,
  onResendEventOptionSelected: PropTypes.func.isRequired,
  onResendEventPressed: PropTypes.func.isRequired,
  onConfirmResendEventPressed: PropTypes.func.isRequired,
};
