import { transformSearchText } from '../../../../../helper';
import { downloadDocumentGeneratorDocuments } from '../../../helper';
import { downloadingDocumentGeneratorDocuments, setDocumentGeneratorDocuments } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, documents, uiDocument,
  } = getState();
  const { token } = authentication;
  const { meta } = documents;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiDocument;

  try {
    dispatch(downloadingDocumentGeneratorDocuments(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const list = await downloadDocumentGeneratorDocuments(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setDocumentGeneratorDocuments(list));
    }
  } finally {
    dispatch(downloadingDocumentGeneratorDocuments(false));
  }
};
