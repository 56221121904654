import {
  lockingUnlockingDocumentGeneratorTemplate, setAlertErrorMessage,
  setDocumentGeneratorTemplateSelectedOrderBy, setDocumentGeneratorTemplateSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE } from '../../../constant';
import { lockUnlockDocumentGeneratorTemplate } from '../../../helper';
import downloadDocumentGeneratorTemplatesAsync from './downloadDocumentGeneratorTemplatesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(lockingUnlockingDocumentGeneratorTemplate(true));

    const { token } = getState().authentication;
    const { data } = getState().templates;
    const { tappedId } = getState().uiTemplate;

    const status = !data[tappedId].locked;

    await lockUnlockDocumentGeneratorTemplate(tappedId, status, token);

    dispatch(setDocumentGeneratorTemplateSelectedPageSize(20));
    dispatch(setDocumentGeneratorTemplateSelectedOrderBy(
      INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE,
    ));
    dispatch(downloadDocumentGeneratorTemplatesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(lockingUnlockingDocumentGeneratorTemplate(false));
  }
};
