import { downloadingForumParentCategories, setForumParentCategories } from '../simple-action';
import { downloadForumParentCategories, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_FORUM_CATEGORIES } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, forumParentCategories, uiForumCategory,
  } = getState();
  const { token } = authentication;
  const { meta, data } = forumParentCategories;
  const { forumParentCategorySearchBarText } = uiForumCategory;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingForumParentCategories(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadForumParentCategories(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_FORUM_CATEGORIES,
      transformSearchText(forumParentCategorySearchBarText),
      token,
    );

    if (list) {
      dispatch(setForumParentCategories(list));
    }
  } finally {
    dispatch(downloadingForumParentCategories(false));
  }
};
