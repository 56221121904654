import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    faqManagementMenu: {
      title: 'FAQ',
    },
    faqCategoryPage: {
      title: 'FAQ Category',

      labelFaqCategoryName: 'Name',
      labelDescription: 'Description',
      labelNo: 'No',

      buttonCaptionCreateNewFaqCategory: 'Create New FAQ Category',
      buttonCaptionEditFaqCategory: 'Edit FAQ Category',
      buttonCaptionDeleteFaqCategory: 'Delete FAQ Category',

      placeholderName: 'Name',
      placeholderFaqCategoryDescription: 'Description',
      placeholderCategory: 'Category',
    },
    faqPage: {
      title: 'FAQ',

      placeholderQuestion: 'Question',
      placeholderShortAnswer: 'Short Answer',
      placeholderDetailedAnswer: 'Detailed Answer',
      placeholderOrder: 'Order',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderCategory: 'Category',

      labelNo: 'No',
      labelQuestion: 'Question',
      labelShortAnswer: 'Short Answer',
      labelOrder: 'Order',
      labelVisibilityStatus: 'Status',
      labelCategory: 'Category',

      buttonCaptionCreateNewFaq: 'Create New FAQ',
      buttonCaptionDeleteFaq: 'Delete FAQ',
      buttonCaptionEditFaq: 'Edit FAQ',
    },
  },
  id: {
    faqManagementMenu: {
      title: 'Manajemen FAQ',
    },
    faqCategoryPage: {
      title: 'Kategori FAQ',

      labelFaqCategoryName: 'Nama',
      labelDescription: 'Deskripsi',
      labelNo: 'No',

      buttonCaptionCreateNewFaqCategory: 'Buat Kategori FAQ Baru',
      buttonCaptionEditFaqCategory: 'Edit Kategori FAQ',
      buttonCaptionDeleteFaqCategory: 'Hapus Kategori FAQ',

      placeholderName: 'Nama',
      placeholderFaqCategoryDescription: 'Deskripsi',
    },
    faqPage: {
      title: 'FAQ',

      placeholderQuestion: 'Pertanyaan',
      placeholderShortAnswer: 'Jawaban Singkat',
      placeholderDetailedAnswer: 'Jawaban Detil',
      placeholderOrder: 'Urutan',
      placeholderVisibilityStatus: 'Status Visibilitas',
      placeholderCategory: 'Kategori',

      labelNo: 'No',
      labelQuestion: 'Pertanyaan',
      labelShortAnswer: 'Jawaban Singkat',
      labelOrder: 'urutan',
      labelVisibilityStatus: 'Status',
      labelCategory: 'Kategori',

      buttonCaptionCreateNewFaq: 'Buat FAQ Baru',
      buttonCaptionDeleteFaq: 'Hapus FAQ',
      buttonCaptionEditFaq: 'Edit FAQ',
    },
  },
});

export default LocalizedString;
