import {
  DOWNLOADING_CITIES, ADDING_EDITING_CITY, DOWNLOADING_DELETING_CITY,
  SET_CITY_SELECTED_PAGE_SIZE, SET_CITY_TAPPED_ID, SET_CITY_SEARCH_TEXT,
  SET_CITY_SELECTED_ORDER_BY, SET_CITY_SELECTED_PROVINCE_ID, SAVING_CITIES,
  SET_CITY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, ENABLING_DISABLING_CITY,
  RESYNCING_CITY,
} from '../action';
import { INITIAL_ORDER_BY_CITIES } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  resyncing: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedProvinceId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_CITIES,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CITIES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_CITY:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_CITY:
      return { ...state, downloadingDeleting: action.status };
    case SET_CITY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CITY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CITY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CITY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CITY_SELECTED_PROVINCE_ID:
      return { ...state, selectedProvinceId: action.id };
    case SAVING_CITIES:
      return { ...state, saving: action.status };
    case SET_CITY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_CITY:
      return { ...state, enablingDisabling: action.status };
    case RESYNCING_CITY:
      return { ...state, resyncing: action.status };
    default: return state;
  }
};
