import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, PAGE_MODE_TABLE, PAGE_MODE_VIEW,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE,
  RXFIELD_VOID_POINT_REQUEST_CALLBACK_URL, RXFIELD_VOID_POINT_REQUEST_MERCHANT_NAME,
  RXFIELD_VOID_POINT_REQUEST_MERCHANT_REFERENCE_ID, RXFIELD_VOID_POINT_REQUEST_POINT,
  RXFIELD_VOID_POINT_REQUEST_POINT_ID, RXFIELD_VOID_POINT_REQUEST_POINT_AMOUNT,
  RXFIELD_VOID_POINT_REQUEST_POINT_NAME, RXFIELD_VOID_POINT_REQUEST_REASON,
  RXFIELD_VOID_POINT_REQUEST_REQUEST_STATUS, RXFIELD_VOID_POINT_REQUEST_REQUEST_STATUS_NOTES,
  RXFIELD_VOID_POINT_REQUEST_USER, RXFIELD_VOID_POINT_REQUEST_USER_FULLNAME,
  RXFIELD_VOID_POINT_REQUEST_USER_ID, RXFORM_VOID_POINT_REQUEST, RXSTATE_POINT_VOID_POINT_REQUESTS,
  RXSTATE_POINT_VOID_POINT_REQUEST_PAGE, SUBMISSION_STATUS_VALUES,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateVoidPointRequest } from '../../validation';

const renderDialogContent = (
  addingEditing, pageMode, points, initialValues,
  loadingPoint, onChangePointText, onPointOptionSelected,
) => {
  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_REASON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderReason}
            label={LocalizedString.voidPointRequestPage.labelReason}
            disabled={addingEditing}
            multiline
            required
          />
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_POINT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderPointId}
            label={LocalizedString.voidPointRequestPage.labelPointId}
            data={points}
            value={initialValues.point}
            loading={loadingPoint}
            onChangeText={onChangePointText}
            onOptionSelected={onPointOptionSelected}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_MERCHANT_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderMerchantReferenceId}
            label={LocalizedString.voidPointRequestPage.labelMerchantReferenceId}
            disabled={addingEditing}
            required
          />
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_CALLBACK_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderCallbackUrl}
            label={LocalizedString.voidPointRequestPage.labelCallbackUrl}
            multiline
            disabled={addingEditing}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_REASON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderReason}
            label={LocalizedString.voidPointRequestPage.labelReason}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderMerchantName}
            label={LocalizedString.voidPointRequestPage.labelMerchantName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_MERCHANT_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderMerchantReferenceId}
            label={LocalizedString.voidPointRequestPage.labelMerchantReferenceId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderUserId}
            label={LocalizedString.voidPointRequestPage.labelUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderUserFullname}
            label={LocalizedString.voidPointRequestPage.labelUserFullname}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_REQUEST_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderRequestStatus}
            label={LocalizedString.voidPointRequestPage.labelRequestStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_REQUEST_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderRequestStatusNote}
            label={LocalizedString.voidPointRequestPage.labelRequestStatusNote}
            multiline
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_CALLBACK_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderCallbackUrl}
            label={LocalizedString.voidPointRequestPage.labelCallbackUrl}
            multiline
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_POINT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderPointId}
            label={LocalizedString.voidPointRequestPage.labelPointId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_POINT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderPointName}
            label={LocalizedString.voidPointRequestPage.labelPointName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOID_POINT_REQUEST_POINT_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidPointRequestPage.placeholderPointAmount}
            label={LocalizedString.voidPointRequestPage.labelPointAmount}
            disabled
          />
        </Grid>

      </Grid>
    </Grid>
  );
};

const VoidPointRequestPage = ({
  onAppear, downloading, pageMode, initialValues,
  onCancelPressed, handleSubmit,
  onChangePage, onSearchBarTextChanged,
  onChangePageSize, onRefresh,
  onSortPressed, onViewPressed,
  onSubmitPressed, addingEditing,
  users, onChangeUserText, loadingUser,
  onAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onCreatePressed,
  loadingPoint, points, onChangePointText,
  onPointOptionSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_POINT_VOID_POINT_REQUESTS}
    uiPage={RXSTATE_POINT_VOID_POINT_REQUEST_PAGE}
    filterColumns={[
      {
        title: LocalizedString.voidPointRequestPage.labelCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voidPointRequestPage.labelPointId,
        field: RXFIELD_VOID_POINT_REQUEST_POINT,
        type: FILTER_TYPE_DROPDOWN,
        data: points,
        loading: loadingPoint,
        onChangeFilterText: onChangePointText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voidPointRequestPage.labelMerchantName,
        field: RXFIELD_VOID_POINT_REQUEST_MERCHANT_NAME,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.voidPointRequestPage.labelRequestStatus,
        field: RXFIELD_VOID_POINT_REQUEST_REQUEST_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: SUBMISSION_STATUS_VALUES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voidPointRequestPage.labelUserFullname,
        field: RXFIELD_VOID_POINT_REQUEST_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.voidPointRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.voidPointRequestPage.labelReason, field: 'reason', sorting: !downloading },
      { title: LocalizedString.voidPointRequestPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
      { title: LocalizedString.voidPointRequestPage.labelPointName, field: 'point.label', sorting: !downloading },
      { title: LocalizedString.voidPointRequestPage.labelPointAmount, field: 'point.amount', sorting: !downloading },
      { title: LocalizedString.voidPointRequestPage.labelRequestStatus, field: 'requestStatus', sorting: !downloading },
      { title: LocalizedString.voidPointRequestPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.voidPointRequestPage.title}
    createNewButtonCaption={LocalizedString.voidPointRequestPage.buttonCaptionCreateNew}
    onSubmitPressed={onSubmitPressed}
    onCreatePressed={onCreatePressed}
    useFullWidth
    disableDelete
    disableEdit
  >
    {renderDialogContent(addingEditing, pageMode, points, initialValues,
      loadingPoint, onChangePointText, onPointOptionSelected)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_VOID_POINT_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidateVoidPointRequest,
})(VoidPointRequestPage);

VoidPointRequestPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  points: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingPoint: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangePointText: PropTypes.func.isRequired,
  onPointOptionSelected: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
