import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormImageInputField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT,
  RXSTATE_INBOX_PAGE,
  RXSTATE_INBOXES,
  RXFORM_INBOX,
  RXFIELD_INBOX_TITLE,
  RXFIELD_INBOX_BODY,
  RXFIELD_INBOX_READ,
  RXFIELD_INBOX_FLAG,
  RXFIELD_INBOX_PAYLOAD,
  RXFIELD_INBOX_IMAGE,
  RXFIELD_INBOX_FULL_NAME,
  RXFIELD_INBOX_ANDROID_APP_ID,
  RXFIELD_INBOX_IOS_APP_ID,
  RXFIELD_INBOX_DOWNLOAD_URL,
  RXFIELD_INBOX_ICON,
  RXFIELD_INBOX_USER_ID,
  FILTER_TYPE_SWITCH,
  approval,
  RXFIELD_INBOX_URL,
} from '../../constant';

const renderDialogContent = (
  defaultImageValue, defaultIconValue,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderTitle}
            label={LocalizedString.inboxPage.placeholderTitle}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_BODY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderBody}
            label={LocalizedString.inboxPage.placeholderBody}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_READ}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderRead}
            label={LocalizedString.inboxPage.placeholderRead}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_FLAG}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderFlag}
            label={LocalizedString.inboxPage.placeholderFlag}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderUrl}
            label={LocalizedString.inboxPage.placeholderUrl}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_PAYLOAD}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderPayload}
            label={LocalizedString.inboxPage.placeholderPayload}
            disabled
            multiline
          />
        </Grid>
        {defaultImageValue
          ? (
            <Grid item>
              <Field
                name={RXFIELD_INBOX_IMAGE}
                component={renderReduxFormImageInputField}
                label={LocalizedString.inboxPage.labelImage}
                disabled
                onImageSelected={() => {}}
                onBlur={(e) => e.preventDefault()}
                defaultValue={defaultImageValue}
                useAvatarWithoutCropper
                fullImageWidth="100%"
                useCropper={false}
              />
            </Grid>
          )
          : null}
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderUserID}
            label={LocalizedString.inboxPage.placeholderUserID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_FULL_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderFullName}
            label={LocalizedString.inboxPage.placeholderFullName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_ANDROID_APP_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderAndroidAppId}
            label={LocalizedString.inboxPage.placeholderAndroidAppId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_IOS_APP_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderIosAppId}
            label={LocalizedString.inboxPage.placeholderIosAppId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_DOWNLOAD_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.inboxPage.placeholderDownloadUrl}
            label={LocalizedString.inboxPage.placeholderDownloadUrl}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INBOX_ICON}
            component={renderReduxFormImageInputField}
            label={LocalizedString.inboxPage.labelImage}
            disabled
            onImageSelected={() => {}}
            onBlur={(e) => e.preventDefault()}
            defaultValue={defaultIconValue}
            useAvatarWithoutCropper
            fullImageWidth="100%"
            useCropper={false}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const InboxPage = ({
  onAppear, downloading, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText,
}) => (
  <FunctionalPage
    data={RXSTATE_INBOXES}
    uiPage={RXSTATE_INBOX_PAGE}
    tableColumns={[
      {
        title: LocalizedString.inboxPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.inboxPage.labelFullName, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.inboxPage.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.inboxPage.labelRead, field: 'read', sorting: !downloading },
      {
        title: LocalizedString.inboxPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.inboxPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.inboxPage.labelUser,
        field: 'userName',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.inboxPage.labelRead,
        field: 'read',
        type: FILTER_TYPE_SWITCH,
        data: approval,
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.inboxPage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues.image, initialValues.iconUrl)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_INBOX,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InboxPage);

InboxPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,

  initialValues: FormInitialValueShape.isRequired,

  downloading: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
};
