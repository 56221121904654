import { REST_URL_AUDIT_TRAILS, REST_URL_VIEW_AUDIT_TRAIL } from './constant';
import { buildQueryParams, sendGetRequest, transformQueryResult } from '../../helper';

export * from '../../helper';

export const downloadAuditTrails = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_AUDIT_TRAILS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_AUDIT_TRAILS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadAuditTrail = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_AUDIT_TRAIL.replace(/\{id\}/, id), token);
  return response;
};
