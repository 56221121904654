import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE, RXFIELD_POINT_SUBMISSION_AMOUNT,
  RXFIELD_POINT_SUBMISSION_CALLBACK_URL, RXFIELD_POINT_SUBMISSION_LABEL,
  RXFIELD_POINT_SUBMISSION_MERCHANT_ID, RXFIELD_POINT_SUBMISSION_MERCHANT_NAME,
  RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID, RXFIELD_POINT_SUBMISSION_REQUEST_STATUS,
  RXFIELD_POINT_SUBMISSION_REQUEST_STATUS_NOTES, RXFIELD_POINT_SUBMISSION_TAGS,
  RXFIELD_POINT_SUBMISSION_USER, RXFIELD_POINT_SUBMISSION_VALID_UNTIL, RXFORM_POINT_SUBMISSION,
  RXSTATE_POINT_SUBMISSIONS, RXSTATE_POINT_SUBMISSION_PAGE, SUBMISSION_STATUS_VALUES,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_USER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderUser}
          label={LocalizedString.pointSubmissionPage.placeholderUser}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID}
          label={LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_LABEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderLabel}
          label={LocalizedString.pointSubmissionPage.placeholderLabel}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_REQUEST_STATUS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderSubmissionStatus}
          label={LocalizedString.pointSubmissionPage.placeholderSubmissionStatus}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_REQUEST_STATUS_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderSubmissionStatusNotes}
          label={LocalizedString.pointSubmissionPage.placeholderSubmissionStatusNotes}
          multiline
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderAmount}
          label={LocalizedString.pointSubmissionPage.placeholderAmount}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_MERCHANT_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderMerchantId}
          label={LocalizedString.pointSubmissionPage.placeholderMerchantId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_MERCHANT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderMerchantName}
          label={LocalizedString.pointSubmissionPage.placeholderMerchantName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_VALID_UNTIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderValidUntil}
          label={LocalizedString.pointSubmissionPage.placeholderValidUntil}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_TAGS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderTags}
          label={LocalizedString.pointSubmissionPage.placeholderTags}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_SUBMISSION_CALLBACK_URL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointSubmissionPage.placeholderCallbackUrl}
          label={LocalizedString.pointSubmissionPage.placeholderCallbackUrl}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const PointSubmissionPage = ({
  users, downloading, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_POINT_SUBMISSIONS}
    uiPage={RXSTATE_POINT_SUBMISSION_PAGE}
    filterColumns={[
      {
        title: LocalizedString.pointSubmissionPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderValidUntil,
        field: RXFIELD_POINT_SUBMISSION_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderMerchantName,
        field: RXFIELD_POINT_SUBMISSION_MERCHANT_NAME,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID,
        field: RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderUser,
        field: RXFIELD_POINT_SUBMISSION_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderSubmissionStatus,
        field: RXFIELD_POINT_SUBMISSION_REQUEST_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: SUBMISSION_STATUS_VALUES,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.pointSubmissionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.pointSubmissionPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelAmount, field: 'amount', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelLabel, field: 'label', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelSubmissionStatus, field: 'requestStatus', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.pointSubmissionPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_POINT_SUBMISSION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PointSubmissionPage);

PointSubmissionPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
