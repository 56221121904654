import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  makeStyles,
} from '@material-ui/core';
import {
  Document, pdfjs, Page,
} from 'react-pdf';
import {
  COLOR_HORIZONTAL_LINE,
  RXFORM_UPLOAD_PDF,
  COLOR_BACKGROUND,
} from '../../constant';
import { AccentButton } from '../../../../component';
import LocalizedString from '../../localization';
import { PAGE_MODE_VIEW } from '../../../../constant';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    padding: 10,
    margin: 10,
    backgroundColor: COLOR_BACKGROUND,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0px',
  },
  buttonUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px',
  },
  buttonUpload: {
    borderRadius: 10,
    alignSelf: 'stretch',
  },
  buttonUploadForm: {
    borderRadius: 10,
    alignSelf: 'stretch',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  horizontalLine: {
    width: '100%',
    border: `1px solid ${COLOR_HORIZONTAL_LINE}`,
    margin: '20px 0px',
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
  },
  page: {
    width: '100%',
  },
}));

const UploadPDFPage = ({
  onAppear, onDocumentLoadSuccess, pageMode,
  onDocumentLoadError, templatePdf, totalPages,
  uploadingPdfTemplate, onFileChange, addingEditing,
  downloadingDeleting,
}) => {
  const classes = useStyles();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    onAppear();
    // eslint-disable-next-line
  }, [onAppear]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const renderPages = () => {
    if (totalPages > 0) {
      return Array.from(new Array(totalPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} className={classes.page} />
      ));
    }
    return (
      <p>{LocalizedString.annotationDesignerPage.labelNoPage}</p>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <AccentButton
          onClick={handleClick}
          caption={LocalizedString.annotationDesignerPage.buttonCaptionUploadTemplate}
          style={classes.buttonUpload}
          disabled={
            uploadingPdfTemplate
            || pageMode === PAGE_MODE_VIEW
            || addingEditing
            || downloadingDeleting
          }
        />
        <input
          id="file-upload"
          type="file"
          onChange={(e) => onFileChange(e)}
          accept=".pdf"
          ref={hiddenFileInput}
          className={classes.input}
        />
      </div>
      <div className={classes.horizontalLine} />
      {
        !!templatePdf
        && (
        <div className={classes.pdfContainer}>
          <Document
            file={templatePdf}
            onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf.numPages)}
            onLoadError={(error) => onDocumentLoadError(error)}
          >
            {renderPages()}
          </Document>
        </div>
        )
      }

    </div>
  );
};

export default reduxForm({
  form: RXFORM_UPLOAD_PDF,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(UploadPDFPage);

UploadPDFPage.propTypes = {
  onAppear: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onDocumentLoadSuccess: PropTypes.func.isRequired,
  onDocumentLoadError: PropTypes.func.isRequired,

  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  uploadingPdfTemplate: PropTypes.bool.isRequired,

  templatePdf: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,

  totalPages: PropTypes.number.isRequired,
};
