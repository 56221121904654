import {
  sendingNotifications, setBranchSearchText, setCompanySearchText, setDepartmentSearchText,
  setDivisionSearchText, setNotificationSelectedBranch, setNotificationSelectedCompany,
  setNotificationSelectedDepartment, setNotificationSelectedDivision, setTotalPersonCount,
} from '../simple-action';
import { sendNotification } from '../../../helper';
import {
  FILTERED_RECIPIENTS_BRANCH_TAG, FILTERED_RECIPIENTS_COMPANIES_TAG,
  FILTERED_RECIPIENTS_DEPARTMENTS_TAG, FILTERED_RECIPIENTS_DIVISIONS_TAG,
  FILTERED_RECIPIENTS_STATUS_ENABLED_TAG, RECIPIENT_TYPE_PROFILE,
} from '../../../constant';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(sendingNotifications(true));

    const { token } = getState().authentication;
    const {
      selectedBranch, selectedCompany, selectedDepartment, selectedDivision,
    } = getState().notification;
    const { title, body, url } = values;

    const branchesTag = selectedBranch
      ? `${FILTERED_RECIPIENTS_BRANCH_TAG}${selectedBranch}` : '';
    const companiesTag = selectedCompany
      ? `${FILTERED_RECIPIENTS_COMPANIES_TAG}${selectedCompany}` : '';
    const divisionsTag = selectedDivision
      ? `${FILTERED_RECIPIENTS_DIVISIONS_TAG}${selectedDivision}` : '';
    const departmentsTag = selectedDepartment
      ? `${FILTERED_RECIPIENTS_DEPARTMENTS_TAG}${selectedDepartment}` : '';

    const filteredRecipients = `${FILTERED_RECIPIENTS_STATUS_ENABLED_TAG}${branchesTag}${companiesTag}${divisionsTag}${departmentsTag}`;

    await sendNotification(RECIPIENT_TYPE_PROFILE, encodeURIComponent(filteredRecipients), title,
      body, url, token);

    dispatch(setBranchSearchText(''));
    dispatch(setCompanySearchText(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setDivisionSearchText(''));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setNotificationSelectedDepartment(''));
    dispatch(setNotificationSelectedDivision(''));
    dispatch(setTotalPersonCount(0));
  } finally {
    dispatch(sendingNotifications(false));
  }
};
