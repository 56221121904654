import { reset } from 'redux-form';
import {
  addingEditingDistrict, clearDistricts, setAlertErrorMessage, setDistrictSelectedCityId,
  setFunctionalPageMode,
} from '../simple-action';
import { addDistrict, editDistrict } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_DISTRICT } from '../../../constant';
import downloadDistrictsAsync from './downloadDistrictsAsync';

export default (name, code) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingDistrict(true));

    const { token } = getState().authentication;
    const { tappedId, selectedCityId } = getState().uiDistrict;
    const { data } = getState().districts;

    const found = data[tappedId];
    const city = selectedCityId || found.city.id;

    if (tappedId) {
      await editDistrict(tappedId, name, city, code, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addDistrict(name, selectedCityId, code, token);
    }

    dispatch(reset(RXFORM_DISTRICT));
    dispatch(clearDistricts());
    dispatch(downloadDistrictsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setDistrictSelectedCityId(''));
  } finally {
    dispatch(addingEditingDistrict(false));
  }
};
