import {
  DOWNLOADING_API_KEYS, ADDING_EDITING_API_KEY, DOWNLOADING_DELETING_API_KEY,
  SET_API_KEY_SELECTED_PAGE_SIZE, SET_API_KEY_TAPPED_ID, SET_API_KEY_SEARCH_TEXT,
  SET_API_KEY_SELECTED_ORDER_BY, COPY_API_TOKEN, SET_API_KEY_SELECTED_OWNER_APP_NAME,
  SET_API_KEY_SELECTED_CONSUMER_APP_NAME, SET_API_KEY_SELECTED_SCOPE,
  SET_API_KEY_SELECTED_ADVANCED_FILTER_OWNER_APP_NAME,
  SET_API_KEY_SELECTED_ADVANCED_FILTER_CONSUMER_APP_NAME,
  SET_API_KEY_SELECTED_ADVANCED_FILTER_SCOPE,
} from '../action';
import { INITIAL_ORDER_BY_API_KEYS } from '../../constant';

const initialState = {
  addingEditing: false,
  copyStatus: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_API_KEYS,
  selectedOwnerAppName: null,
  selectedConsumerAppName: null,
  selectedScope: null,
  selectedAdvancedFilterOwnerAppName: [],
  selectedAdvancedFilterConsumerAppName: [],
  selectedAdvancedFilterScope: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_API_KEYS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_API_KEY:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_API_KEY:
      return { ...state, downloadingDeleting: action.status };
    case SET_API_KEY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_API_KEY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_API_KEY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_API_KEY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case COPY_API_TOKEN:
      return { ...state, copyStatus: action.status };
    case SET_API_KEY_SELECTED_OWNER_APP_NAME:
      return { ...state, selectedOwnerAppName: action.option };
    case SET_API_KEY_SELECTED_CONSUMER_APP_NAME:
      return { ...state, selectedConsumerAppName: action.option };
    case SET_API_KEY_SELECTED_SCOPE:
      return { ...state, selectedScope: action.option };
    case SET_API_KEY_SELECTED_ADVANCED_FILTER_OWNER_APP_NAME:
      return { ...state, selectedAdvancedFilterOwnerAppName: action.option };
    case SET_API_KEY_SELECTED_ADVANCED_FILTER_CONSUMER_APP_NAME:
      return { ...state, selectedAdvancedFilterConsumerAppName: action.option };
    case SET_API_KEY_SELECTED_ADVANCED_FILTER_SCOPE:
      return { ...state, selectedAdvancedFilterScope: action.option };
    default: return state;
  }
};
