import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardMedia, Container, IconButton, InputAdornment, makeStyles, Typography,
  CircularProgress, Button,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { AlertBox, AccentButton } from '../../component';
import {
  COLOR_ICON, COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY,
  RXFIELD_USERNAME, RXFIELD_PASSWORD, RXFORM_LOGIN_SCREEN,
  OAUTH_PROVIDER_PARAM,
  OAUTH_MICROSOFT_REDIRECT_URI,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import { rxformValidateLogin } from '../../validation';

const logo = require('../../asset/logo.png');
const background = require('../../asset/login/background.png');
const microsoftLogo = require('../../asset/microsoft-icon.png');

const useStyles = makeStyles(() => ({
  container: {
    background: `url(${background})`,
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  innerContainer: {
    maxWidth: 500,
    minWidth: 275,
  },
  card: {
    justifyContent: 'center',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 350,
    maxHeight: 500,
    borderRadius: 10,
    boxShadow: '0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 1px rgba(63, 63, 68, 0.05)',
    padding: '40px 0px 40px 0px',
  },
  topField: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  logo: {
    width: 155,
    marginBottom: 15,
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    minWidth: 250,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  providerOAuthLogo: {
    width: 30,
  },
  buttonProviderOAuth: {
    minWidth: 250,
    borderRadius: 50,
    padding: 4,
    marginTop: 16,
  },
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const openMicrosoftOAuthUrl = (oAuthMicrosoftUrl) => {
  window.open(oAuthMicrosoftUrl, '_self');
};

const renderLoginWithMicrosoftButton = (
  showButton, classes, oauthClientId,
  loggingIn, loggingInWithMicrosoft,
  oAuthMicrosoftUrl,
) => {
  if (loggingInWithMicrosoft) {
    return (
      <div className={classes.activityIndicator}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (showButton) {
    return (
      <Button
        variant="outlined"
        className={classes.buttonProviderOAuth}
        onClick={() => openMicrosoftOAuthUrl(oAuthMicrosoftUrl)}
        disabled={loggingIn || loggingInWithMicrosoft}
      >
        <CardMedia
          component="img"
          className={classes.providerOAuthLogo}
          image={microsoftLogo}
        />
        {LocalizedString.loginScreen.buttonCaptionLoginWithMicrosoft}
      </Button>
    );
  }
  return null;
};

const LoginScreen = ({
  loggingIn, handleSubmit, onLoginPressed, oAuthMicrosoftEnabled, oauthClientId,
  loggingInWithMicrosoft, myConfigItems, downloadingMyConfigItems,
  onAppear, onAppearWithAuthCode, oAuthMicrosoftUrl,
}) => {
  const [mount, setMount] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const oAuthProvider = searchParams.get(OAUTH_PROVIDER_PARAM);

  const microsoftSearchParams = new URLSearchParams(window.location.href.replace(OAUTH_MICROSOFT_REDIRECT_URI, ''));
  const microsoftOAuthCode = microsoftSearchParams.get('code');

  const classes = useStyles();
  useEffect(() => {
    if (!mount) {
      setMount(true);
      onAppear();
      onAppearWithAuthCode(oAuthProvider, microsoftOAuthCode);
    }
  }, [
    onAppear, onAppearWithAuthCode, mount, oAuthProvider,
    microsoftOAuthCode,
  ]);
  useEffect(() => {
    if (isEmpty(myConfigItems) && !downloadingMyConfigItems) {
      onAppear();
    }
  }, [myConfigItems, downloadingMyConfigItems, onAppear]);

  return (
    <div className={classes.container}>
      <Container className={classes.innerContainer} fixed>
        <Card className={classes.card} raised>
          <span className={classes.topField}>
            <CardMedia
              component="img"
              className={classes.logo}
              image={logo}
            />
            <Typography variant="body1">
              {LocalizedString.loginScreen.labelAdminDashboard}
            </Typography>
          </span>

          <form onSubmit={handleSubmit(onLoginPressed)} className={classes.form}>
            <Field
              name={RXFIELD_USERNAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.loginScreen.placeholderUsername}
              label={LocalizedString.loginScreen.labelUsername}
              disabled={loggingIn}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled edge="end">
                      <Person style={{ color: COLOR_ICON }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              name={RXFIELD_PASSWORD}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.loginScreen.labelPassword}
              label={LocalizedString.loginScreen.labelPassword}
              disabled={loggingIn}
              secureTextEntry
              required
            />

            <AccentButton
              type="submit"
              className={classes.button}
              loading={loggingIn}
              disableElevation
              caption={LocalizedString.loginScreen.buttonCaptionLogin}
            />
            {renderLoginWithMicrosoftButton(
              oAuthMicrosoftEnabled, classes, oauthClientId,
              loggingIn, loggingInWithMicrosoft,
              oAuthMicrosoftUrl,
            )}
            <AlertBox />
          </form>
        </Card>
      </Container>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_LOGIN_SCREEN,
  validate: rxformValidateLogin,
})(LoginScreen);

LoginScreen.propTypes = {
  myConfigItems: PropTypes.object.isRequired,
  downloadingMyConfigItems: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  oAuthMicrosoftEnabled: PropTypes.bool.isRequired,
  loggingInWithMicrosoft: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onLoginPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAppearWithAuthCode: PropTypes.func.isRequired,

  oauthClientId: PropTypes.string.isRequired,
  oAuthMicrosoftUrl: PropTypes.string.isRequired,

};
