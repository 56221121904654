import { sendingSurveyNotification } from '../simple-action';
import { sendSurveyNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(sendingSurveyNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSurvey;

    await sendSurveyNotification(tappedId, token);
  } finally {
    dispatch(sendingSurveyNotification(false));
  }
};
