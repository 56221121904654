import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearAppVersions, clearDeviceManufacturers, clearDeviceModels, clearOsNames, clearOsVersions,
  clearTimeZones, clearTokens, clearUsers, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setAppVersionSearchText, setDeviceManufacturerSearchText, setDeviceModelSearchText,
  setOsNameSearchText, setOsVersionSearchText, setTimeZoneSearchText, setTokenSearchText,
  setTokenSelectedPageSize, setTokenSelectedOrderBy, setTokenTappedId,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, setUserTappedId,
  disablingTokenAsync, downloadAppVersionsAsync, downloadDeviceManufacturersAsync,
  downloadDeviceModelsAsync, downloadOsNamesAsync, downloadOsVersionsAsync, downloadTimeZonesAsync,
  downloadUsersAsync, downloadTokensAsync, downloadTokenTotalPersonCountAsync,
  sendTokenNotificationAsync,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_TOKENS, PAGE_MODE_TABLE, RXFORM_TOKEN,
  MENUID_AUTHENTICATION_TOKEN,
} from '../../constant';
import {
  debounceSearch, toMoment, transformInitialValues, transformUserDropdownData,
} from '../../helper';
import LocalizedString from '../../localization';
import TokenPage from './token.presentation';

const REGEX_CONTAIN_SPECIFIC_WORD = (x) => new RegExp(`${x}[^ ]* (.*)`);

const transformDropdownData = (data, parentData) => data.map((x) => {
  const foundParent = parentData.filter((y) => x.match(REGEX_CONTAIN_SPECIFIC_WORD(y)));
  const parent = foundParent.length > 1 ? foundParent[foundParent.length - 1] : foundParent;

  return ({
    label: x,
    value: x.match(REGEX_CONTAIN_SPECIFIC_WORD(parent))[1],
  });
});

const getInitialValues = (state) => {
  const {
    tokens, uiFunctionalPage, uiToken, uiUser,
  } = state;
  const { data } = tokens;
  const { downloadingDeleting, tappedId } = uiToken;
  const { disablingToken } = uiUser;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting && !disablingToken
    ? data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      validUntil: toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      lastActivity: toMoment(found.lastActivity).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      device: `${found.deviceManufacturer} ${found.deviceModel}`,
      os: `${found.osName} ${found.osVersion}`,
      appVersion: found.appVersion,
      username: found.user ? found.user.username : '',
      fullName: found.user ? found.user.fullName : '',
    }) : {
      tokenKey: '',
      username: '',
      fullname: '',
      validUntil: '',
      lastActivity: '',
      device: '',
      deviceId: '',
      os: '',
      appVersion: '',
      timeZone: '',
      acceptLanguage: '',
      fcmToken: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  appVersions: state.appVersions,
  deviceManufacturers: state.deviceManufacturers,
  deviceModels: transformDropdownData(state.deviceModels, state.deviceManufacturers),
  osNames: state.osNames,
  osVersions: transformDropdownData(state.osVersions, state.osNames),
  timeZones: state.timeZones,
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiToken.addingEditing,
  downloading: state.uiToken.downloading,
  downloadingAppVersions: state.uiToken.downloadingAppVersions,
  downloadingDeviceManufacturers: state.uiToken.downloadingDeviceManufacturers,
  downloadingDeviceModels: state.uiToken.downloadingDeviceModels,
  downloadingOsNames: state.uiToken.downloadingOsNames,
  downloadingOsVersions: state.uiToken.downloadingOsVersions,
  downloadingTimeZones: state.uiToken.downloadingTimeZones,
  initialValues: getInitialValues(state),
  os: getInitialValues(state).os,
  tappedId: state.uiToken.tappedId || '',
  sendingNotification: state.uiToken.sendingNotification,
  users: transformUserDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
});

const searchAppVersionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearAppVersions());
    dispatch(downloadAppVersionsAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchDeviceManufacturerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDeviceManufacturers());
    dispatch(downloadDeviceManufacturersAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchDeviceModelDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDeviceModels());
    dispatch(downloadDeviceModelsAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchOsNameDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearOsNames());
    dispatch(downloadOsNamesAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchOsVersionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearOsVersions());
    dispatch(downloadOsVersionsAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchTimeZoneDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearTimeZones());
    dispatch(downloadTimeZonesAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setAppVersionSearchText(''));
    dispatch(setDeviceManufacturerSearchText(''));
    dispatch(setDeviceModelSearchText(''));
    dispatch(setOsNameSearchText(''));
    dispatch(setOsVersionSearchText(''));
    dispatch(setTimeZoneSearchText(''));
    dispatch(setUserSearchText(''));
    dispatch(clearAppVersions());
    dispatch(clearDeviceManufacturers());
    dispatch(clearDeviceModels());
    dispatch(clearOsNames());
    dispatch(clearOsVersions());
    dispatch(clearTimeZones());
    dispatch(clearUsers());
    dispatch(downloadDeviceManufacturersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadOsNamesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadAppVersionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDeviceModelsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadOsVersionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTimeZonesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_TOKEN));
    dispatch(setTokenSearchText(''));
    dispatch(setUserTappedId(''));
    dispatch(clearTokens());
    dispatch(setTokenSelectedPageSize(20));
    dispatch(setTokenSelectedOrderBy(INITIAL_ORDER_BY_TOKENS));
    dispatch(downloadTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString.replace(/user/, 'user.id')));
    dispatch(clearTokens());
    dispatch(downloadTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_TOKEN));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTokensAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTokenSelectedPageSize(pageSize));
    dispatch(downloadTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeAppVersionText: (text) => {
    dispatch(setAppVersionSearchText(text));
    if (text.length > 2) {
      searchAppVersionDebounce(dispatch);
    }
  },
  onChangeDeviceManufacturerText: (text) => {
    dispatch(setDeviceManufacturerSearchText(text));
    if (text.length > 2) {
      searchDeviceManufacturerDebounce(dispatch);
    }
  },
  onChangeDeviceModelText: (text) => {
    dispatch(setDeviceModelSearchText(text));
    if (text.length > 2) {
      searchDeviceModelDebounce(dispatch);
    }
  },
  onChangeOsNameText: (text) => {
    dispatch(setOsNameSearchText(text));
    if (text.length > 2) {
      searchOsNameDebounce(dispatch);
    }
  },
  onChangeOsVersionText: (text) => {
    dispatch(setOsVersionSearchText(text));
    if (text.length > 2) {
      searchOsVersionDebounce(dispatch);
    }
  },
  onChangeTimeZoneText: (text) => {
    dispatch(setTimeZoneSearchText(text));
    if (text.length > 2) {
      searchTimeZoneDebounce(dispatch);
    }
  },
  onConfirmDeletePressed: (id) => {
    dispatch(disablingTokenAsync(id, 'token'))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCountTotalPersonPressed: (filterString) => {
    dispatch(downloadTokenTotalPersonCountAsync(filterString))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setTokenTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDisableConfirmation));
  },
  onOpenSendNotificationDialog: () => {
    dispatch(setAppVersionSearchText(''));
    dispatch(setDeviceManufacturerSearchText(''));
    dispatch(setDeviceModelSearchText(''));
    dispatch(setOsNameSearchText(''));
    dispatch(setOsVersionSearchText(''));
    dispatch(setTimeZoneSearchText(''));
    dispatch(clearAppVersions());
    dispatch(clearDeviceManufacturers());
    dispatch(clearDeviceModels());
    dispatch(clearOsNames());
    dispatch(clearOsVersions());
    dispatch(clearTimeZones());
    dispatch(downloadDeviceManufacturersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadOsNamesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadAppVersionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDeviceModelsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadOsVersionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTimeZonesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setTokenSelectedPageSize(pageSize));
    dispatch(clearTokens());
    dispatch(downloadTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTokens());
    dispatch(setTokenSelectedOrderBy(INITIAL_ORDER_BY_TOKENS));
    dispatch(downloadTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setTokenSearchText(text));
      dispatch(clearTokens());
      await dispatch(downloadTokensAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationPressed: (values, filterString) => {
    dispatch(sendTokenNotificationAsync(values, filterString))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setTokenSelectedOrderBy(orderBy));
    dispatch(clearTokens());
    dispatch(downloadTokensAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setTokenTappedId(id));
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TokenPage);
