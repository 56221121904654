import {
  Button,
  FormControl,
  FormHelperText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import {
  COLOR_BODY_TEXT, COLOR_BODY_TEXT_LIGHT, COLOR_BODY_TEXT_LIGHTER, COLOR_ICON,
} from '../../constant';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    margin: '0px 10px 0 10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 10px 5px 10px',
    border: `1px solid ${COLOR_BODY_TEXT_LIGHTER}`,
    borderRadius: 5,
    marginBottom: 10,
  },
  colorPicker: (props) => ({
    height: 20,
    width: 10,
    marginRight: 5,
    backgroundColor: props.backgroundColor,
  }),
  form: {
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    width: '100%',
  },
  label: {
    color: COLOR_BODY_TEXT_LIGHT,
    fontSize: '14px',
    marginBottom: '5px',
  },
  labelValue: {
    color: COLOR_BODY_TEXT,
    fontSize: '14px',
    marginBottom: '5px',
  },
});

const ColorPickerField = ({
  value, label, visibility, error, helperText,
  onChangePressed, onFieldPressed,
}) => {
  const [hex, setHex] = useState();
  const colorProps = {
    backgroundColor: hex,
  };
  const classes = useStyles(colorProps);

  useEffect(() => {
    setHex(value || COLOR_ICON);
  }, [value]);

  return (
    <div className={classes.container}>
      <FormControl className={classes.form} error={error}>
        <Typography className={classes.label}>{label}</Typography>
        <div className={classes.textContainer}>
          <Button variant="contained" onClick={() => onFieldPressed(visibility)} className={`${classes.colorPicker}`} />
          <Typography className={classes.labelValue}>{hex}</Typography>
        </div>
        { visibility ? (
          <div>
            <SketchPicker
              color={hex}
              onChange={(color) => {
                onChangePressed(color.hex);
                setHex(color.hex);
              }}
            />
          </div>
        ) : null }
        <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default ColorPickerField;

ColorPickerField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,

  visibility: PropTypes.bool.isRequired,
  error: PropTypes.bool,

  onChangePressed: PropTypes.func,
  onFieldPressed: PropTypes.func.isRequired,
};

ColorPickerField.defaultProps = {
  value: '',
  label: '',
  helperText: '',

  error: false,

  onChangePressed: () => {},
};
