import {
  downloadingAttendanceSummaryClockInStatusTrend, setAttendanceSummaryClockInStatusTrend,
} from '../simple-action';
import { downloadAttendanceSummaryClockInStatusTrend } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryClockInStatusTrend(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryClockInStatusTrend(token);

    dispatch(setAttendanceSummaryClockInStatusTrend(data));
  } finally {
    dispatch(downloadingAttendanceSummaryClockInStatusTrend(false));
  }
};
