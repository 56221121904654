import { downloadingSummaryPreviousYearTopRecipients, setSummaryPreviousYearTopRecipients } from '../simple-action';
import { downloadSummaryTopRecipients } from '../../../helper';
import { PACKET_SUMMARY_PREVIOUS_PERIOD, PACKET_SUMMARY_GROUPING_BY_MONTH } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryPreviousYearTopRecipients(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryTopRecipients(PACKET_SUMMARY_PREVIOUS_PERIOD,
      PACKET_SUMMARY_GROUPING_BY_MONTH, token);

    dispatch(setSummaryPreviousYearTopRecipients(result));
  } finally {
    dispatch(downloadingSummaryPreviousYearTopRecipients(false));
  }
};
