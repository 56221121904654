import { reset } from 'redux-form';
import {
  addingEditingQuiz, clearQuizes, setAlertErrorMessage,
  setFunctionalPageMode, setQuizSelectedQuizCategoryId,
} from '../simple-action';
import { addQuiz, editQuiz } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_QUIZ } from '../../../constant';
import downloadQuizesAsync from './downloadQuizesAsync';

export default (
  title, description, validFrom, validUntil, durationMinutes,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingQuiz(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedQuizCategoryId, selectedVisibilityStatus,
    } = getState().uiQuiz;
    const { data } = getState().quizes;

    const found = data[tappedId];
    const SelectedCategoryId = selectedQuizCategoryId || found.category.id;
    const SelectedVisibility = selectedVisibilityStatus || found.visibilityStatus;

    if (tappedId) {
      await editQuiz(
        tappedId, title, description, validFrom, validUntil,
        durationMinutes, SelectedCategoryId, SelectedVisibility,
        token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addQuiz(
        title, description, validFrom, validUntil,
        durationMinutes, SelectedCategoryId, SelectedVisibility,
        token,
      );
    }

    dispatch(reset(RXFORM_QUIZ));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setQuizSelectedQuizCategoryId(''));
  } finally {
    dispatch(addingEditingQuiz(false));
  }
};
