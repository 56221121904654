import { connect } from 'react-redux';
import {
  loginAsync, loginWithMicrosoftAsync, setAlertErrorMessage, setAlertForcePopUp,
} from '../../redux/action';
import LoginScreen from './login.presentation';
import { OAUTH_MICROSOFT_REDIRECT_URI, OAUTH_MICROSOFT_URL, OAUTH_PROVIDER_MICROSOFT } from '../../constant';
import { transformConfigValueBoolean } from '../../helper/helper';
import { downloadMyConfigItemsAsync } from '../../module/configuration/redux/action';

const getOAuthMicrosoftUrl = (state) => {
  const tenantId = state.myConfigItems?.auth?.oAuthMicrosoftTenantId || '';
  const clientId = state.myConfigItems?.auth?.oAuthMicrosoftClientIdWeb || '';
  const scope = state.myConfigItems?.auth?.oAuthMicrosoftScopes || '';
  const redirectUri = encodeURIComponent(OAUTH_MICROSOFT_REDIRECT_URI);
  const url = OAUTH_MICROSOFT_URL
    .replace(/\{tenantId\}/, tenantId)
    .replace(/\{clientId\}/, clientId)
    .replace(/\{redirectUri\}/, redirectUri)
    .replace(/\{scope\}/, encodeURIComponent(scope));
  return url;
};

const mapStateToProps = (state) => ({
  loggingIn: state.uiLogin.loggingIn,
  oAuthMicrosoftEnabled: transformConfigValueBoolean(state.myConfigItems?.auth?.oAuthMicrosoftEnabled || ''),
  oAuthMicrosoftUrl: getOAuthMicrosoftUrl(state),
  oauthClientId: state.myConfigItems?.auth?.oAuthGoogleClientIdWeb || '',
  loggingInWithMicrosoft: state.uiLogin.loggingInWithMicrosoft,
  myConfigItems: state.myConfigItems,
  downloadingMyConfigItems: state.uiLogin.downloadingMyConfigItems,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppearWithAuthCode: (oAuthProvider, microsoftOAuthCode) => {
    if (microsoftOAuthCode && oAuthProvider === OAUTH_PROVIDER_MICROSOFT) {
      dispatch(loginWithMicrosoftAsync(microsoftOAuthCode, ownProps.history.push))
        .catch((err) => {
          dispatch(setAlertForcePopUp(true));
          dispatch(setAlertErrorMessage(err));
        });
    }
  },
  onLoginPressed: async ({ username, password }) => {
    try {
      await dispatch(loginAsync(username, password, ownProps.history.push));
    } catch (error) {
      dispatch(setAlertForcePopUp(true));
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
