import { downloadingRegistrations, setRegistration } from '../simple-action';
import { downloadRegistrations, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, registrations, uiRegistration } = getState();
  const { token } = authentication;
  const { meta, data } = registrations;
  const { selectedPageSize, searchBarText, orderBy } = uiRegistration;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingRegistrations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadRegistrations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setRegistration(list));
    }
  } finally {
    dispatch(downloadingRegistrations(false));
  }
};
