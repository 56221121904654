import {
  DOWNLOADING_DISTRICTS, ADDING_EDITING_DISTRICT, DOWNLOADING_DELETING_DISTRICT,
  SET_DISTRICT_SELECTED_PAGE_SIZE, SET_DISTRICT_TAPPED_ID, SET_DISTRICT_SEARCH_TEXT,
  SET_DISTRICT_SELECTED_ORDER_BY, SET_DISTRICT_SELECTED_CITY_ID, SAVING_DISTRICTS,
  SET_DISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, ENABLING_DISABLING_DISTRICT,
  RESYNCING_DISTRICT,
} from '../action';
import { INITIAL_ORDER_BY_DISTRICTS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  resyncing: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedCityId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_DISTRICTS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_DISTRICTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_DISTRICT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_DISTRICT:
      return { ...state, downloadingDeleting: action.status };
    case SET_DISTRICT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_DISTRICT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_DISTRICT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_DISTRICT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_DISTRICT_SELECTED_CITY_ID:
      return { ...state, selectedCityId: action.id };
    case SAVING_DISTRICTS:
      return { ...state, saving: action.status };
    case SET_DISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_DISTRICT:
      return { ...state, enablingDisabling: action.status };
    case RESYNCING_DISTRICT:
      return { ...state, resyncing: action.status };
    default: return state;
  }
};
