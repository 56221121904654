import { DOWNLOADING_ACHIEVEMENT, DOWNLOADING_BALANCE, SET_ACHIEVEMENT_TAPPED_ID } from '../action';

const initialState = {
  downloadingAchievement: false,
  downloadingBalance: false,
  tappedId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ACHIEVEMENT:
      return { ...state, downloadingAchievement: action.status };
    case DOWNLOADING_BALANCE:
      return { ...state, downloadingBalance: action.status };
    case SET_ACHIEVEMENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    default: return state;
  }
};
