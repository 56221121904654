import { downloadingHistories, setHistories } from '../simple-action';
import { downloadHistories, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, histories, uiHistory } = getState();
  const { token } = authentication;
  const { meta } = histories;
  const { selectedPageSize, searchBarText, orderBy } = uiHistory;
  const { filterString } = getState().uiFunctionalPage;

  try {
    dispatch(downloadingHistories(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadHistories(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setHistories(list));
    }
  } finally {
    dispatch(downloadingHistories(false));
  }
};
