import {
  addPermission, clearPermissions, downloadingDeletingPermission, setAlertErrorMessage,
  setFunctionalPageMode, setPermissionTappedId,
} from '../simple-action';
import { downloadPermission, deletePermission } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadPermissionsAsync from './downloadPermissionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPermission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPermission;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deletePermission(tappedId, token);
      dispatch(setPermissionTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearPermissions());
      dispatch(downloadPermissionsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const permission = await downloadPermission(tappedId, token);
      dispatch(addPermission(permission));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingPermission(false));
  }
};
