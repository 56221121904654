import {
  DOWNLOADING_COMPANIES_MDATA, ADDING_EDITING_COMPANY_MDATA,
  DOWNLOADING_COMPANY_MDATA, SET_COMPANY_SELECTED_PAGE_SIZE_MDATA,
  SET_COMPANY_TAPPED_ID_MDATA, SET_COMPANY_SEARCH_TEXT_MDATA,
  SET_COMPANY_SELECTED_ORDER_BY_MDATA, SAVING_COMPANIES_MDATA,
  SET_COMPANY_MAP_MARKER_MDATA, SET_COMPANY_CLEAR_LOGO_MDATA,
  SET_COMPANY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING_MDATA,
  SET_COMPANY_SELECTED_CITY_ID_MDATA, ENABLING_DISABLING_COMPANY_MDATA,
  SET_COMPANY_MAP_CENTER_MDATA, RESYNCING_COMPANY_MDATA,
  SET_COMPANY_LOGO_IMAGE, SET_COMPANY_BANNER_IMAGE,
} from '../action';
import { INITIAL_ORDER_BY_COMPANIES } from '../../constant';
import { isDecimal } from '../../helper';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  resyncing: false,
  saving: false,
  clearLogo: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedCityId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_COMPANIES,
  center: null,
  marker: null,
  filterString: '',
  selectedLogoImage: '',
  selectedBannerImage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_COMPANIES_MDATA:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_COMPANY_MDATA:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_COMPANY_MDATA:
      return { ...state, downloadingDeleting: action.status };
    case SET_COMPANY_SELECTED_PAGE_SIZE_MDATA:
      return { ...state, selectedPageSize: action.size };
    case SET_COMPANY_TAPPED_ID_MDATA:
      return { ...state, tappedId: action.id };
    case SET_COMPANY_SEARCH_TEXT_MDATA:
      return { ...state, searchBarText: action.text };
    case SET_COMPANY_SELECTED_ORDER_BY_MDATA:
      return { ...state, orderBy: action.order };
    case SET_COMPANY_SELECTED_CITY_ID_MDATA:
      return { ...state, selectedCityId: action.id };
    case SAVING_COMPANIES_MDATA:
      return { ...state, saving: action.status };
    case SET_COMPANY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING_MDATA:
      return { ...state, filterString: action.text };
    case SET_COMPANY_CLEAR_LOGO_MDATA:
      return { ...state, clearLogo: action.status };
    case ENABLING_DISABLING_COMPANY_MDATA:
      return { ...state, enablingDisabling: action.status };
    case SET_COMPANY_LOGO_IMAGE:
      return { ...state, selectedLogoImage: action.image };
    case SET_COMPANY_BANNER_IMAGE:
      return { ...state, selectedBannerImage: action.image };
    case SET_COMPANY_MAP_CENTER_MDATA: {
      const { data } = action;
      const currLat = state.center ? state.center.lat : null;
      const currLng = state.center ? state.center.lng : null;
      if (data && isDecimal(data.latitude) && isDecimal(data.longitude)) {
        return {
          ...state,
          center: {
            lat: data.latitude && isDecimal(data.latitude) ? data.latitude : currLat,
            lng: data.longitude && isDecimal(data.longitude) ? data.longitude : currLng,
          },
        };
      }
      return { ...state, center: { lat: 0, lng: 0 } };
    }
    case SET_COMPANY_MAP_MARKER_MDATA: {
      const { data } = action;
      const currLat = state.marker ? state.marker.lat : null;
      const currLng = state.marker ? state.marker.lng : null;

      if (data && isDecimal(data.latitude) && isDecimal(data.longitude)) {
        return {
          ...state,
          marker: {
            lat: data.latitude && isDecimal(data.latitude) ? data.latitude : currLat,
            lng: data.longitude && isDecimal(data.longitude) ? data.longitude : currLng,
          },
        };
      }
      return { ...state, marker: { lat: 0, lng: 0 } };
    }
    case RESYNCING_COMPANY_MDATA:
      return { ...state, resyncing: action.status };
    default: return state;
  }
};
