import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    carRegistrationMenu: {
      title: 'Car Registration',
    },
    registrationPage: {
      title: 'Registration',

      labelNo: 'No',
      labelVin: 'VIN',
      labelUser: 'User',
      labelCarModel: 'Car Model',
      labelModelYear: 'Model Year',
      labelLicensePlate: 'License Plate',
      labelStatus: 'Status',
      labelApprovedOn: 'Approved on',
      labelRejectedOn: 'Rejected on',
      labelCreatedDate: 'Created Date',
      labelCity: 'City',
      labelProvince: 'Province',
      labelVehicleInfo: 'Vehicle Info',

      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',

      placeholderModel: 'Model',
      placeholderTrim: 'Trim',
      placeholderColor: 'Color',
      placeholderLicensePlate: 'License Plate',
      placeholderTransmission: 'Transmission',
      placeholderStnk: 'STNK',
      placeholderWarrantyStartDate: 'Warranty Start Date',
      placeholderWarrantyEndDate: 'Warranty End Date',
      placeholderWarrantyNotes: 'Warranty Notes',
      placeholderPersonId: 'Person ID',
      placeholderPersonalAccount: 'Personal Account',
      placeholderOwnerName: 'Owner Name',
      placeholderInvoiceNo: 'Invoice No',
      placeholderEngineNo: 'Engine No',
      placeholderOwnerEmail: 'Owner Email',
      placeholderDealerCode: 'Dealer Code',
      placeholderDealerName: 'Dealer Name',
      placeholderDealerAddress: 'Dealer Address',
      placeholderModelYear: 'Model Year',
      placeholderUserId: 'User ID',
      placeholderUserName: 'User Name',
      placeholderFullName: 'Full Name',
      placeholderNik: 'NIK',
      placeholderVin: 'VIN',
      placeholderSalutation: 'Salutation',
      placeholderPhone: 'Phone',
      placeholderEmail: 'Email',
      placeholderStreetAddress: 'Street Address',
      placeholderDistrict: 'District',
      placeholderCity: 'City',
      placeholderProvince: 'Province',
      placeholderTimeZone: 'Time Zone',
      placeholderRegistrationStatus: 'Registration Status',
      placeholderRegistrationNotes: 'Registration Notes',
      placeholderApprovedOn: 'Approved on',
      placeholderRejectedOn: 'Rejected on',
      placeholderNotes: 'Notes',
      placeholderPrice: 'Price',

      msgRejectConfirmation: 'Are you sure want to reject this product?',
      msgApproveConfirmation: 'Are you sure want to approve this product?',
    },
  },
  id: {
    carRegistrationMenu: {
      title: 'Registrasi Mobil',
    },
    registrationPage: {
      title: 'Registrasi',

      labelNo: 'No',
      labelVin: 'VIN',
      labelUser: 'Pengguna',
      labelCarModel: 'Model Mobil',
      labelModelYear: 'Tahun Model',
      labelLicensePlate: 'Plat Nomor',
      labelStatus: 'Status',
      labelApprovedOn: 'Disetujui pada',
      labelRejectedOn: 'Ditolak pada',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelVehicleInfo: 'Info Kendaraan',

      buttonCaptionApprove: 'Setujui',
      buttonCaptionReject: 'Tolak',

      placeholderModel: 'Model',
      placeholderTrim: 'Lis',
      placeholderColor: 'Warna',
      placeholderLicensePlate: 'Plat Nomor',
      placeholderTransmission: 'Transmisi',
      placeholderStnk: 'STNK',
      placeholderWarrantyStartDate: 'Tanggal Garansi Dimulai',
      placeholderWarrantyEndDate: 'Tanggal Garansi Berakhir',
      placeholderWarrantyNotes: 'Catatan Garansi',
      placeholderPersonId: 'ID Individu',
      placeholderPersonalAccount: 'Akun Personal',
      placeholderOwnerName: 'Nama Pemilik',
      placeholderInvoiceNo: 'No. Faktur',
      placeholderEngineNo: 'No. Mesin',
      placeholderOwnerEmail: 'Email Pemilik',
      placeholderDealerCode: 'Kode Penjual',
      placeholderDealerName: 'Nama Penjual',
      placeholderDealerAddress: 'Alamat Penjual',
      placeholderModelYear: 'Tahun Model',
      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderNik: 'NIK',
      placeholderVin: 'VIN',
      placeholderSalutation: 'Sebutan',
      placeholderPhone: 'Telepon',
      placeholderEmail: 'Email',
      placeholderStreetAddress: 'Alamat Jalan',
      placeholderDistrict: 'Kecamatan',
      placeholderCity: 'Kota',
      placeholderProvince: 'Provinsi',
      placeholderTimeZone: 'Zona Waktu',
      placeholderRegistrationStatus: 'Status Registrasi',
      placeholderRegistrationNotes: 'Catatan Registrasi',
      placeholderApprovedOn: 'Disetujui pada',
      placeholderRejectedOn: 'Ditolak pada',
      placeholderNotes: 'Catatan',
      placeholderPrice: 'Harga',

      msgRejectConfirmation: 'Apa Anda yakin ingin menolak produk ini?',
      msgApproveConfirmation: 'Apa anda yakin ingin menyetujui produk ini?',
    },
  },
});

export default LocalizedString;
