import { reset } from 'redux-form';
import {
  addingEditingApplication, clearApplications, setAlertErrorMessage,
  setApplicationSelectedPermissionName, setFunctionalPageMode,
} from '../simple-action';
import { addApplication, editApplication, transformLocalImagePath } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_APPLICATION } from '../../../constant';
import downloadApplicationsAsync from './downloadApplicationsAsync';

export default (name, description, url, androidAppId, iosAppId, downloadUrl, permission, order,
  minAppVersion, maxAppVersion, icon) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingApplication(true));

    const { token } = getState().authentication;
    const { tappedId, selectedPermissionName } = getState().uiApplication;
    const { data } = getState().applications;

    const found = data[tappedId];
    const permissionName = selectedPermissionName || found.permission;
    if (tappedId) {
      await editApplication(tappedId, name, description, url, androidAppId,
        iosAppId, downloadUrl, order, permissionName, minAppVersion, maxAppVersion,
        transformLocalImagePath(icon), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addApplication(name, description, url, androidAppId, iosAppId,
        downloadUrl, order, selectedPermissionName, minAppVersion, maxAppVersion,
        transformLocalImagePath(icon), token);
    }

    dispatch(reset(RXFORM_APPLICATION));
    dispatch(clearApplications());
    dispatch(downloadApplicationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setApplicationSelectedPermissionName(''));
  } finally {
    dispatch(addingEditingApplication(false));
  }
};
