import { downloadingDistricts, setDistricts } from '../simple-action';
import { downloadDistricts, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, districts, uiDistrict } = getState();
  const { token } = authentication;
  const { meta } = districts;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiDistrict;

  try {
    dispatch(downloadingDistricts(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadDistricts(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setDistricts(list));
    }
  } finally {
    dispatch(downloadingDistricts(false));
  }
};
