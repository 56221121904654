
import { INITIAL_ORDER_BY_COVID_DATAS } from '../../constant';
import {
  DOWNLOADING_DELETING_COVID_DATA,
  DOWNLOADING_COVID_DATAS, SET_COVID_DATA_SEARCH_TEXT,
  SET_COVID_DATA_SELECTED_ORDER_BY,
  SET_COVID_DATA_SELECTED_PAGE_SIZE,
  SET_COVID_DATA_TAPPED_ID,
  ADDING_EDITING_COVID_DATA,
  SET_COVID_DATA_SELECTED_EMPLOYEE_STATUS,
  SAVING_COVID_DATA,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_COVID_DATAS,
  selectedEmployeeStatus: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_COVID_DATAS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_COVID_DATA:
      return { ...state, downloadingDeleting: action.status };
    case ADDING_EDITING_COVID_DATA:
      return { ...state, addingEditing: action.status };
    case SAVING_COVID_DATA:
      return { ...state, saving: action.status };
    case SET_COVID_DATA_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_COVID_DATA_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_COVID_DATA_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_COVID_DATA_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_COVID_DATA_SELECTED_EMPLOYEE_STATUS:
      return { ...state, selectedEmployeeStatus: action.option };
    default: return state;
  }
};
