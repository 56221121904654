import { cancelTokenRequest, downloadTokenRequest } from '../../../helper';
import { addTokenRequest, cancelingTokenRequest } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(cancelingTokenRequest(true));

    const { token } = getState().authentication;
    const { data } = getState().tokenRequests;
    const { tappedId } = getState().uiTokenRequest;

    const found = data[tappedId];
    const {
      voucher, quantity, validFrom, validUntil,
    } = found;

    await cancelTokenRequest(tappedId, voucher.id, quantity, validFrom, validUntil, token);

    const result = await downloadTokenRequest(tappedId, token);
    dispatch(addTokenRequest(result));
  } finally {
    dispatch(cancelingTokenRequest(false));
  }
};
