import moment from 'moment';
import { downloadingCalendarDateRangeStatus, setCalendarDateRangeStatus, setCalendarDays } from '../simple-action';
import { downloadCalendarDateRangeStatus } from '../../../helper';
import { REVERSED_ISO_DATE_FORMAT } from '../../../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCalendarDateRangeStatus(true));

    const { authentication, uiCalendar } = getState();
    const { token } = authentication;
    const { tappedId, selectedYear } = uiCalendar;

    const momentObj = moment();
    const startDate = momentObj.startOf('year').year(selectedYear.value)
      .format(REVERSED_ISO_DATE_FORMAT);
    const endDate = momentObj.endOf('year').year(selectedYear.value)
      .format(REVERSED_ISO_DATE_FORMAT);

    const result = await downloadCalendarDateRangeStatus(token, tappedId, startDate, endDate);

    dispatch(setCalendarDateRangeStatus(result));
    dispatch(setCalendarDays(result));
  } finally {
    dispatch(downloadingCalendarDateRangeStatus(false));
  }
};
