import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import moment from 'moment';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_TIME, DATE_TIME_FORMAT_WITHOUT_PIPE,
  INITIAL_ORDER_BY_QUIZ_QUESTIONS, MENUID_QUIZ_QUIZ_QUESTION,
  PAGE_MODE_TABLE, RXFIELD_QUIZ_QUESTION_FIELD_TYPE, RXFIELD_QUIZ_QUESTION_LABEL,
  RXFIELD_QUIZ_QUESTION_NAME,
  RXFIELD_QUIZ_QUESTION_MUST_USE_CAMERA, RXFIELD_QUIZ_QUESTION_OPTION_MODE,
  RXFIELD_QUIZ_QUESTION_OPTION_VALUE_SEPARATOR,
  RXFIELD_QUIZ_QUESTION_PICKER_MODE,
  RXFIELD_QUIZ_QUESTION_QUIZ,
  RXFIELD_QUIZ_QUESTION_TEXT_KEYBOARD_TYPE, RXFIELD_QUIZ_QUESTION_TEXT_MULTILINE,
  RXFORM_QUIZ_QUESTION, SIMPLE_DATE_FORMAT, TIME_FORMAT,
  RXFIELD_QUIZ_QUESTION_IMAGE, RXFIELD_QUIZ_QUESTION_CLEAR_IMAGE, REST_BASE_URL,
  REST_URL_IMAGE_STORAGE, RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER_LOGICAL_OPERATOR,
} from '../../constant';
import {
  transformDropdownData, transformInitialValues, toMoment,
} from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearQuizes, clearQuizQuestions, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setQuizAdvancedFilterDialogSelectedFilterString, setQuizSearchText,
  setQuizQuestionSearchText,
  setQuizQuestionSelectedFieldType, setQuizQuestionSelectedLogicalOperator,
  setQuizQuestionSelectedMustUseCamera, setQuizQuestionSelectedOptionMode,
  setQuizQuestionSelectedOptionValueSeparator, setQuizQuestionSelectedOrderBy,
  setQuizQuestionSelectedPageSize,
  setQuizQuestionSelectedPickerMode, setQuizQuestionSelectedQuiz,
  setQuizQuestionSelectedTextKeyboardType, setQuizQuestionSelectedTextMultiline,
  setQuizQuestionTappedId,
  addEditQuizQuestionAsync, downloadQuizQuestionsAsync,
  downloadQuizesAsync, downloadDeleteQuizQuestionAsync, enableDisableQuizQuestionAsync,
  setQuizQuestionSelectedCorrectAnswerLogicalOperator,
} from '../../redux/action';
import QuizQuestionPage from './quiz-question.presentation';

const getDateTimeFormat = (fieldType, dateTime) => {
  switch (fieldType) {
    case ENUM_FIELD_DATE: return moment.utc(dateTime).format(SIMPLE_DATE_FORMAT);
    case ENUM_FIELD_DATE_TIME: return toMoment(dateTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE);
    case ENUM_FIELD_TIME: return moment.utc(dateTime).format(TIME_FORMAT);
    default: return null;
  }
};

const getInitialValues = (state) => {
  const { quizQuestions, uiFunctionalPage, uiQuizQuestion } = state;
  const { data } = quizQuestions;
  const { downloadingDeleting, tappedId } = uiQuizQuestion;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    dateTimeMaxValue: found.dateTimeMaxValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMaxValue) : null,
    dateTimeMinValue: found.dateTimeMinValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMinValue) : null,
    textMultiline: found.textMultiline
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    mustUseCamera: found.mustUseCamera
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    quiz: found.quiz ? { label: found.quiz.title, value: found.quiz.id } : { label: '', value: '' },
    image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : '',
  }) : {
    name: '',
    label: '',
    placeHolder: '',
    validationMessage: '',
    defaultValue: '',
    order: '',
    required: false,
    visible: true,
    readOnly: false,
    textMultiline: false,
    clearImage: false,
    textKeyboardType: null,
    fieldType: '',
    pickerMode: '',
    optionMode: null,
    optionValues: '',
    optionValueSeparator: null,
    sectionId: '',
    sectionLabel: '',
    appliedOn: '',
    textMinLength: '',
    textMaxLength: '',
    textRegexValidation: null,
    intMinValue: '',
    intMaxValue: '',
    floatMinValue: '',
    floatMaxValue: '',
    dateTimeMinValue: null,
    dateTimeMaxValue: null,
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiQuizQuestion.addingEditing,
  downloading: state.uiQuizQuestion.downloading,
  downloadingDeleting: state.uiQuizQuestion.downloadingDeleting,
  enablingDisabling: state.uiQuizQuestion.enablingDisabling,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  selectedFieldType: state.uiQuizQuestion.selectedFieldType,
  currentQuizQuestionStatus: state.quizQuestions.data[state.uiFunctionalPage.tappedId]
    ? state.quizQuestions.data[state.uiFunctionalPage.tappedId].status : '',
  quizes: transformDropdownData(state.quizes.data),
  loadingQuizes: state.uiQuiz.downloading,
});

const camelize = (text) => {
  const str = text.replace(/[^a-zA-Z0-9]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return str.substr(0, 1).toLowerCase() + str.substr(1);
};

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setQuizAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setQuizSearchText(''));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_QUIZ_QUIZ_QUESTION));
    dispatch(setQuizQuestionSearchText(''));
    dispatch(clearQuizQuestions());
    dispatch(setQuizQuestionSelectedPageSize(20));
    dispatch(setQuizQuestionSelectedOrderBy(INITIAL_ORDER_BY_QUIZ_QUESTIONS));
    dispatch(downloadQuizQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/quiz/, 'quiz.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearQuizQuestions());
    dispatch(downloadQuizQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_QUIZ_QUESTION));
  },
  onCancelPressed: () => {
    dispatch(setQuizQuestionSelectedFieldType(''));
    dispatch(setQuizQuestionSelectedLogicalOperator(''));
    dispatch(setQuizQuestionSelectedMustUseCamera(''));
    dispatch(setQuizQuestionSelectedOptionMode(''));
    dispatch(setQuizQuestionSelectedOptionValueSeparator(''));
    dispatch(setQuizQuestionSelectedPickerMode(''));
    dispatch(setQuizQuestionSelectedTextKeyboardType(''));
    dispatch(setQuizQuestionSelectedTextMultiline(''));
    dispatch(setQuizQuestionSelectedCorrectAnswerLogicalOperator(''));
    dispatch(reset(RXFORM_QUIZ_QUESTION));
  },
  onChangeLabelText: (e, pageMode) => {
    dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_LABEL, e.target.value));
    if (pageMode === PAGE_MODE_TABLE) {
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_NAME,
        camelize(e.target.value)));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadQuizQuestionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setQuizQuestionSelectedPageSize(pageSize));
    dispatch(downloadQuizQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeQuizText: async (text) => {
    try {
      dispatch(setQuizAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setQuizSearchText(text));
      dispatch(clearQuizes());
      await dispatch(downloadQuizesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteQuizQuestionAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableQuizQuestionAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onCreatePressed: () => {
    dispatch(setQuizQuestionTappedId(''));
    dispatch(reset(RXFORM_QUIZ_QUESTION));
    dispatch(setQuizQuestionSelectedFieldType(''));
    dispatch(setQuizQuestionSelectedLogicalOperator(''));
    dispatch(setQuizQuestionSelectedMustUseCamera(''));
    dispatch(setQuizQuestionSelectedOptionMode(''));
    dispatch(setQuizQuestionSelectedOptionValueSeparator(''));
    dispatch(setQuizQuestionSelectedPickerMode(''));
    dispatch(setQuizQuestionSelectedTextKeyboardType(''));
    dispatch(setQuizQuestionSelectedTextMultiline(''));
    dispatch(setQuizQuestionSelectedCorrectAnswerLogicalOperator(''));
    dispatch(setQuizAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setQuizSearchText(''));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setQuizQuestionTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setQuizQuestionTappedId(id));
    dispatch(downloadDeleteQuizQuestionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setQuizAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setQuizSearchText(''));
    dispatch(clearQuizes());
    dispatch(downloadQuizesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setQuizQuestionTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onFieldTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedFieldType(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_FIELD_TYPE, option));
    } else {
      dispatch(setQuizQuestionSelectedFieldType(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_FIELD_TYPE, ''));
    }
  },
  onMustUseCameraOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedMustUseCamera(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_MUST_USE_CAMERA, option));
    } else {
      dispatch(setQuizQuestionSelectedMustUseCamera(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_MUST_USE_CAMERA, ''));
    }
  },
  onOptionModeOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedOptionMode(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_OPTION_MODE, option));
    } else {
      dispatch(setQuizQuestionSelectedOptionMode(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_OPTION_MODE, ''));
    }
  },
  onOptionValueSeparatorOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedOptionValueSeparator(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_OPTION_VALUE_SEPARATOR,
        option));
    } else {
      dispatch(setQuizQuestionSelectedOptionValueSeparator(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_OPTION_VALUE_SEPARATOR, ''));
    }
  },
  onPickerModeOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedPickerMode(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_PICKER_MODE, option));
    } else {
      dispatch(setQuizQuestionSelectedPickerMode(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_PICKER_MODE, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setQuizQuestionSelectedPageSize(pageSize));
    dispatch(clearQuizQuestions());
    dispatch(downloadQuizQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearQuizQuestions());
    dispatch(setQuizQuestionSelectedOrderBy(INITIAL_ORDER_BY_QUIZ_QUESTIONS));
    dispatch(downloadQuizQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async (values) => {
    try {
      await dispatch(addEditQuizQuestionAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setQuizQuestionSearchText(text));
      dispatch(clearQuizQuestions());
      await dispatch(downloadQuizQuestionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onQuizOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedQuiz(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_QUIZ, option));
    } else {
      dispatch(setQuizQuestionSelectedQuiz(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_QUIZ, ''));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setQuizQuestionSelectedOrderBy(orderBy));
    dispatch(clearQuizQuestions());
    dispatch(downloadQuizQuestionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async (values) => {
    await dispatch(addEditQuizQuestionAsync(values));
  },
  onTextKeyboardTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedTextKeyboardType(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_TEXT_KEYBOARD_TYPE,
        option));
    } else {
      dispatch(setQuizQuestionSelectedTextKeyboardType(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_TEXT_KEYBOARD_TYPE, ''));
    }
  },
  onTextMultilineOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedTextMultiline(option));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_TEXT_MULTILINE, option));
    } else {
      dispatch(setQuizQuestionSelectedTextMultiline(''));
      dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_TEXT_MULTILINE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setQuizQuestionTappedId(id));
    dispatch(downloadDeleteQuizQuestionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_IMAGE, image));
  },
  onClearImageOptionSelected: (option) => {
    dispatch(change(RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_CLEAR_IMAGE, option));
  },
  onCorrectAnswerLogicalOperatorOptionSelected: (option) => {
    if (option) {
      dispatch(setQuizQuestionSelectedCorrectAnswerLogicalOperator(option));
      dispatch(change(
        RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER_LOGICAL_OPERATOR, option,
      ));
    } else {
      dispatch(setQuizQuestionSelectedCorrectAnswerLogicalOperator(''));
      dispatch(change(
        RXFORM_QUIZ_QUESTION, RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER_LOGICAL_OPERATOR, '',
      ));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestionPage);
