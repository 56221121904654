import {
  addService, clearServices, downloadingDeletingService, setAlertErrorMessage,
  setFunctionalPageMode, setServiceTappedId,
} from '../simple-action';
import { downloadService, deleteService } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadServicesAsync from './downloadServicesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingService(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiService;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteService(tappedId, token);
      dispatch(setServiceTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearServices());
      dispatch(downloadServicesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const service = await downloadService(tappedId, token);
      dispatch(addService(service));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingService(false));
  }
};
