import {
  addQuizCategory, clearQuizCategories, downloadingDeletingQuizCategory, setAlertErrorMessage,
  setFunctionalPageMode, setQuizCategoryTappedId,
} from '../simple-action';
import { downloadQuizCategory, deleteQuizCategory } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadQuizCategoriesAsync from './downloadQuizCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingQuizCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiQuizCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteQuizCategory(tappedId, token);
      dispatch(setQuizCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearQuizCategories());
      dispatch(downloadQuizCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const shortlink = await downloadQuizCategory(tappedId, token);
      dispatch(addQuizCategory(shortlink));
    }
  } finally {
    dispatch(downloadingDeletingQuizCategory(false));
  }
};
