import {
  DOWNLOADING_FORUM_CATEGORIES, ADDING_EDITING_FORUM_CATEGORY, DOWNLOADING_DELETING_FORUM_CATEGORY,
  SET_FORUM_CATEGORY_SELECTED_PAGE_SIZE, SET_FORUM_CATEGORY_TAPPED_ID,
  SET_FORUM_CATEGORY_SEARCH_TEXT,
  SET_FORUM_CATEGORY_SELECTED_ORDER_BY, SET_FORUM_CATEGORY_SELECTED_PARENT_CATEGORY,
  DOWNLOADING_FORUM_PARENT_CATEGORIES, SET_FORUM_PARENT_CATEGORY_SEARCH_TEXT,
  SET_FORUM_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';
import { INITIAL_ORDER_BY_FORUM_CATEGORIES } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_FORUM_CATEGORIES,
  selectedParentCategory: '',
  downloadingParentCategories: false,
  forumParentCategorySearchBarText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_FORUM_CATEGORIES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_FORUM_CATEGORY:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_FORUM_CATEGORY:
      return { ...state, downloadingDeleting: action.status };
    case SET_FORUM_CATEGORY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_FORUM_CATEGORY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FORUM_CATEGORY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_FORUM_CATEGORY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_FORUM_CATEGORY_SELECTED_PARENT_CATEGORY:
      return { ...state, selectedParentCategory: action.option };
    case DOWNLOADING_FORUM_PARENT_CATEGORIES:
      return { ...state, downloadingParentCategories: action.status };
    case SET_FORUM_PARENT_CATEGORY_SEARCH_TEXT:
      return { ...state, forumParentCategorySearchBarText: action.text };
    case SET_FORUM_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
