import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, makeStyles,
} from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import LocalizedString from '../../../localization';
import { AccentButton } from '../../../component';
import {
  COLOR_SECONDARY, COLOR_PRIMARY, COLOR_BACKGROUND, ALERT_TYPE_INFO,
} from '../../../constant';

const useStyles = makeStyles(() => ({
  alertTitle: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  buttonColor: {
    background: COLOR_PRIMARY,
    color: COLOR_BACKGROUND,
  },
  centerContent: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UnsavedChangesDialog = ({
  display, onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={display}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <SweetAlert
        type={ALERT_TYPE_INFO}
        title={LocalizedString.common.alertMessageUnsavedChanges}
        onConfirm={onClose}
        openAnim={{ name: 'showSweetAlert', duration: 500 }}
        closeOnClickOutside={false}
        customButtons={(
          <AccentButton
            onClick={onClose}
            variant="contained"
            className={classes.buttonColor}
            caption={LocalizedString.common.buttonCaptionOK}
          />
          )}
      >
        {LocalizedString.common.alertMessageSaveChanges}
      </SweetAlert>
    </Dialog>
  );
};

export default UnsavedChangesDialog;

UnsavedChangesDialog.propTypes = {
  display: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
};
