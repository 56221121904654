import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteAnnotationDesigner, downloadAnnotationDesignList } from '../../../helper';
import {
  setAlertErrorMessage,
  setAnnotationDesignListTappedId, setFunctionalPageMode,
  setAnnotationDesigns,
  clearAnnotationDesignLists,
  downloadingDeletingAnnotationDesignList,
  addAnnotationDesignList,
  setPDFTemplate,
  setAnnotationDesignAnnotationDesignName,
  setAnnotationDesignAnnotationDesignDescription,
} from '../simple-action';
import downloadAnnotationDesignListsAsync from './downloadAnnotationDesignListsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingAnnotationDesignList(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAnnotationDesignList;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteAnnotationDesigner(tappedId, token);
      dispatch(setAnnotationDesignListTappedId(''));
      dispatch(setAnnotationDesignAnnotationDesignName(''));
      dispatch(setAnnotationDesignAnnotationDesignDescription(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearAnnotationDesignLists());
      dispatch(downloadAnnotationDesignListsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadAnnotationDesignList(tappedId, token);
      dispatch(addAnnotationDesignList(result));
      dispatch(setAnnotationDesigns(result.annotations));
      dispatch(setAnnotationDesignAnnotationDesignName(result.name));
      dispatch(setAnnotationDesignAnnotationDesignDescription(result.description));
      dispatch(setPDFTemplate(result.pdfPath));
    }
  } finally {
    dispatch(downloadingDeletingAnnotationDesignList(false));
  }
};
