import { SET_SUMMARY_OS_DISTRIBUTION, CLEAR_SUMMARY_OS_DISTRIBUTION } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SUMMARY_OS_DISTRIBUTION: {
      return action.data;
    }
    case CLEAR_SUMMARY_OS_DISTRIBUTION:
      return state;
    default:
      return state;
  }
};
