import { ANALYTICS_DATA_LIMIT } from '../../../constant';
import { getAnalyticsCustomErrorMessages } from '../../../helper';
import {
  downloadingAnalyticsCustomErrorMessages, setAnalyticsCustomErrorMessages,
  clearAnalyticsCustomErrorMessages,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsCustomErrorMessages(true));

    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { customErrorMessageDateRange } = uiAnalytics;
    const { token } = authentication;

    const requestBody = {
      dateRanges: [{
        startDate: customErrorMessageDateRange.from,
        endDate: customErrorMessageDateRange.to,
      }],
      dimensions: [
        { name: 'eventName' },
        { name: 'customEvent:message' },
      ],
      metrics: [
        { name: 'eventCount' },
        { name: 'totalUsers' },
      ],
      limit: ANALYTICS_DATA_LIMIT,
      dimensionFilter: {
        filter: {
          fieldName: 'eventName',
          stringFilter: {
            matchType: 'EXACT',
            value: 'application_error',
          },
        },
      },
      metricAggregations: ['TOTAL'],
    };

    const response = await getAnalyticsCustomErrorMessages(
      myConfigItems?.core?.googleAnalyticsPropertyId, requestBody, token,
    );
    dispatch(clearAnalyticsCustomErrorMessages());
    dispatch(setAnalyticsCustomErrorMessages(response));
  } finally {
    dispatch(downloadingAnalyticsCustomErrorMessages(false));
  }
};
