import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  addPersonalHolidayAsync, clearPersonalHolidays, clearProfiles, downloadPersonalHolidaysAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setPersonalHolidayAdvancedFilterDialogSelectedFilterString, setPersonalHolidaySearchText,
  setPersonalHolidaySelectedFile, setPersonalHolidaySelectedOrderBy,
  setPersonalHolidaySelectedPageSize, setPersonalHolidayTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import {
  INITIAL_ORDER_BY_PERSONAL_HOLIDAYS, PAGE_MODE_TABLE, RXFORM_PERSONAL_HOLIDAY,
  MENUID_ATTENDANCE_ADDON_PERSONAL_HOLIDAY, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  RXFIELD_PERSONAL_HOLIDAY_FILE,
} from '../../constant';
import { debounceSearch, transformInitialValues, transformUserDropdownData } from '../../helper';
import PersonalHolidayPage from './personal-holiday.presentation';

const getInitialValues = (state) => {
  const { personalHolidays, uiFunctionalPage, uiPersonalHoliday } = state;
  const { data } = personalHolidays;
  const { downloadingDeleting, tappedId } = uiPersonalHoliday;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : { file: null };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.profiles.data),
  addingEditing: state.uiPersonalHoliday.addingEditing,
  downloading: state.uiPersonalHoliday.downloading,
  loadingUser: state.uiProfile.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  templateUrl: state?.myConfigItems?.attendanceAddOn?.personalHolidayImportTemplate,
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_PERSONAL_HOLIDAY));
    dispatch(setPersonalHolidayAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setPersonalHolidaySearchText(''));
    dispatch(clearPersonalHolidays());
    dispatch(setPersonalHolidaySelectedPageSize(20));
    dispatch(setPersonalHolidaySelectedOrderBy(INITIAL_ORDER_BY_PERSONAL_HOLIDAYS));
    dispatch(downloadPersonalHolidaysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id');
    dispatch(setPersonalHolidayAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPersonalHolidays());
    dispatch(downloadPersonalHolidaysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setPersonalHolidaySelectedFile(''));
    dispatch(reset(RXFORM_PERSONAL_HOLIDAY));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPersonalHolidaysAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPersonalHolidaySelectedPageSize(pageSize));
    dispatch(downloadPersonalHolidaysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onCreatePressed: () => {
    dispatch(setPersonalHolidayTappedId(''));
  },
  onFileSelected: (base64) => {
    dispatch(setPersonalHolidaySelectedFile(base64));
    dispatch(change(RXFORM_PERSONAL_HOLIDAY, RXFIELD_PERSONAL_HOLIDAY_FILE, base64));
  },
  onRefresh: (pageSize) => {
    dispatch(setPersonalHolidaySelectedPageSize(pageSize));
    dispatch(clearPersonalHolidays());
    dispatch(downloadPersonalHolidaysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setPersonalHolidayAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPersonalHolidays());
    dispatch(setPersonalHolidaySelectedOrderBy(INITIAL_ORDER_BY_PERSONAL_HOLIDAYS));
    dispatch(downloadPersonalHolidaysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPersonalHolidaySearchText(text));
      dispatch(clearPersonalHolidays());
      await dispatch(downloadPersonalHolidaysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPersonalHolidaySelectedOrderBy(orderBy));
    dispatch(clearPersonalHolidays());
    dispatch(downloadPersonalHolidaysAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async () => {
    await dispatch(addPersonalHolidayAsync());
  },
  onViewPressed: (id) => {
    dispatch(setPersonalHolidayTappedId(id));
  },
  onDownloadTemplatePressed: async (templateUrl) => {
    try {
      const extension = templateUrl?.match(/(\.[^.]*)$/g)[0];
      const fileUrl = `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${templateUrl}`;
      const response = await fetch(fileUrl);
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Personal Holiday Import Template${extension}`;
        a.click();
      });
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalHolidayPage);
