import { INITIAL_ORDER_BY_PERSONAL_HOLIDAYS } from '../../constant';
import {
  ADDING_EDITING_PERSONAL_HOLIDAY, DOWNLOADING_PERSONAL_HOLIDAY, DOWNLOADING_PERSONAL_HOLIDAYS,
  SET_PERSONAL_HOLIDAY_SELECTED_FILE, SET_PERSONAL_HOLIDAY_SELECTED_PAGE_SIZE,
  SET_PERSONAL_HOLIDAY_TAPPED_ID, SET_PERSONAL_HOLIDAY_SEARCH_TEXT,
  SET_PERSONAL_HOLIDAY_SELECTED_ORDER_BY,
  SET_PERSONAL_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';

const initialState = {
  addingEditing: false,
  downloading: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  selectedFile: '',
  orderBy: INITIAL_ORDER_BY_PERSONAL_HOLIDAYS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_EDITING_PERSONAL_HOLIDAY:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_PERSONAL_HOLIDAYS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_PERSONAL_HOLIDAY:
      return { ...state, downloadingDeleting: action.status };
    case SET_PERSONAL_HOLIDAY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PERSONAL_HOLIDAY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PERSONAL_HOLIDAY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PERSONAL_HOLIDAY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PERSONAL_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_PERSONAL_HOLIDAY_SELECTED_FILE: {
      return { ...state, selectedFile: action.base64 };
    }
    default: return state;
  }
};
