import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, transformQueryResult,
  transformCompleteUrl, sendPostRequest,
} from '../../helper';
import {
  REST_URL_APPROVALS, REST_URL_VIEW_APPROVAL,
  REST_URL_APPROVAL_PROVIDERS, REST_URL_APPROVAL_PROCESSES, REST_URL_IMAGE_STORAGE,
  REST_URL_RESEND_EVENT,
} from './constant';

export * from '../../helper';

export const downloadApprovals = async (
  pageNumber, pageSize, orderBy, searchText,
  filterString, token,
) => {
  const url = buildQueryParams(
    transformCompleteUrl(REST_URL_APPROVALS, searchText, filterString),
    pageNumber,
    pageSize,
    orderBy,
    searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadApproval = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_APPROVAL.replace(/\{id\}/, id), token);
  return response;
};

export const saveApprovalAttachment = async (filePath) => {
  const url = `${REST_URL_IMAGE_STORAGE}${filePath}`;
  const response = await sendGetFileRequest(url);
  return response;
};

export const downloadApprovalProviders = async (token) => {
  const response = await sendGetRequest(REST_URL_APPROVAL_PROVIDERS, token);
  return response;
};

export const downloadApprovalProcesses = async (token) => {
  const response = await sendGetRequest(REST_URL_APPROVAL_PROCESSES, token);
  return response;
};

export const resendEvent = async (type, approvalId, token) => {
  const body = {
    type,
    approvalId,
  };
  await sendPostRequest(REST_URL_RESEND_EVENT, body, token);
};
