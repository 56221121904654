import { downloadingDeletingProfile, addProfile } from '../simple-action';
import { downloadProfile } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingProfile(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiProfile;
    const { tappedId: setAchievementTappedId } = getState().uiAchievement;

    const result = await downloadProfile(setAchievementTappedId || tappedId, token);

    dispatch(addProfile(result));
  } finally {
    dispatch(downloadingDeletingProfile(false));
  }
};
