import { reset } from 'redux-form';
import {
  addingEditingConfigGroup, clearConfigGroups, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { RXFORM_CONFIG_GROUP, PAGE_MODE_TABLE } from '../../../constant';
import { addConfigGroup, editConfigGroup } from '../../../helper';
import downloadConfigGroupsAsync from './downloadConfigGroupsAsync';

export default (order, name, tag, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingConfigGroup(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiConfigGroup;

    if (tappedId) {
      await editConfigGroup(tappedId, order, name, tag, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addConfigGroup(order, name, tag, description, token);
    }

    dispatch(reset(RXFORM_CONFIG_GROUP));
    dispatch(clearConfigGroups());
    dispatch(downloadConfigGroupsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingConfigGroup(false));
  }
};
