import { downloadingConfigItems, setConfigItems } from '../simple-action';
import { downloadConfigItems, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, configItems, uiConfigItem } = getState();
  const { token } = authentication;
  const { meta } = configItems;
  const { selectedPageSize, searchBarText, orderBy } = uiConfigItem;
  const { filterString } = getState().uiFunctionalPage;

  try {
    dispatch(downloadingConfigItems(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadConfigItems(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setConfigItems(list));
    }
  } finally {
    dispatch(downloadingConfigItems(false));
  }
};
