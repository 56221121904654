import {
  addWorkingHourAdjustment,
  downloadingWorkingHourAdjustment,
} from '../simple-action';
import { downloadWorkingHourAdjustment } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingWorkingHourAdjustment(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWorkingHourAdjustment;

    const workingHourAdjustment = await downloadWorkingHourAdjustment(
      tappedId, token,
    );

    dispatch(addWorkingHourAdjustment(workingHourAdjustment));
  } finally {
    dispatch(downloadingWorkingHourAdjustment(false));
  }
};
