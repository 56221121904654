import { SET_ANNOTATION_DESIGN_LISTS, ADD_ANNOTATION_DESIGN_LIST, CLEAR_ANNOTATION_DESIGN_LISTS } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANNOTATION_DESIGN_LISTS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_ANNOTATION_DESIGN_LIST: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_ANNOTATION_DESIGN_LISTS:
      return initialState;
    default:
      return state;
  }
};
