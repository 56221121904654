import {
  DOWNLOADING_SUMMARY_THIS_MONTH_TRENDS, DOWNLOADING_SUMMARY_THIS_MONTH_TOP_RECIPIENTS,
  DOWNLOADING_SUMMARY_THIS_MONTH_PACKET_TYPES, DOWNLOADING_SUMMARY_THIS_MONTH_LOCATIONS,
  DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TRENDS, DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS,
  DOWNLOADING_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES, DOWNLOADING_SUMMARY_PREVIOUS_MONTH_LOCATIONS,
  DOWNLOADING_SUMMARY_THIS_YEAR_TRENDS, DOWNLOADING_SUMMARY_THIS_YEAR_TOP_RECIPIENTS,
  DOWNLOADING_SUMMARY_THIS_YEAR_PACKET_TYPES, DOWNLOADING_SUMMARY_THIS_YEAR_LOCATIONS,
  DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TRENDS, DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS,
  DOWNLOADING_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES, DOWNLOADING_SUMMARY_PREVIOUS_YEAR_LOCATIONS,
} from '../action';

const initialState = {
  downloadingThisMonthTrends: false,
  downloadingThisMonthTopRecipients: false,
  downloadingThisMonthPacketTypes: false,
  downloadingThisMonthLocations: false,
  downloadingPreviousMonthTrends: false,
  downloadingPreviousMonthTopRecipients: false,
  downloadingPreviousMonthPacketTypes: false,
  downloadingPreviousMonthLocations: false,
  downloadingThisYearTrends: false,
  downloadingThisYearTopRecipients: false,
  downloadingThisYearPacketTypes: false,
  downloadingThisYearLocations: false,
  downloadingPreviousYearTrends: false,
  downloadingPreviousYearTopRecipients: false,
  downloadingPreviousYearPacketTypes: false,
  downloadingPreviousYearLocations: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SUMMARY_THIS_MONTH_TRENDS:
      return { ...state, downloadingThisMonthTrends: action.status };
    case DOWNLOADING_SUMMARY_THIS_MONTH_TOP_RECIPIENTS:
      return { ...state, downloadingThisMonthTopRecipients: action.status };
    case DOWNLOADING_SUMMARY_THIS_MONTH_PACKET_TYPES:
      return { ...state, downloadingThisMonthPacketTypes: action.status };
    case DOWNLOADING_SUMMARY_THIS_MONTH_LOCATIONS:
      return { ...state, downloadingThisMonthLocations: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TRENDS:
      return { ...state, downloadingPreviousMonthTrends: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS:
      return { ...state, downloadingPreviousMonthTopRecipients: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES:
      return { ...state, downloadingPreviousMonthPacketTypes: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_MONTH_LOCATIONS:
      return { ...state, downloadingPreviousMonthLocations: action.status };
    case DOWNLOADING_SUMMARY_THIS_YEAR_TRENDS:
      return { ...state, downloadingThisYearTrends: action.status };
    case DOWNLOADING_SUMMARY_THIS_YEAR_TOP_RECIPIENTS:
      return { ...state, downloadingThisYearTopRecipients: action.status };
    case DOWNLOADING_SUMMARY_THIS_YEAR_PACKET_TYPES:
      return { ...state, downloadingThisYearPacketTypes: action.status };
    case DOWNLOADING_SUMMARY_THIS_YEAR_LOCATIONS:
      return { ...state, downloadingThisYearLocations: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TRENDS:
      return { ...state, downloadingPreviousYearTrends: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS:
      return { ...state, downloadingPreviousYearTopRecipients: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES:
      return { ...state, downloadingPreviousYearPacketTypes: action.status };
    case DOWNLOADING_SUMMARY_PREVIOUS_YEAR_LOCATIONS:
      return { ...state, downloadingPreviousYearLocations: action.status };
    default: return state;
  }
};
