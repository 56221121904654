import {
  enablingDisablingProfileSync, setAlertErrorMessage, setProfileSyncSelectedOrderBy,
  setProfileSyncSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_PROFILE_SYNC, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableProfileSync } from '../../../helper';
import downloadProfileSyncsAsync from './downloadProfileSyncsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingProfileSync(true));

    const { token } = getState().authentication;
    const { data } = getState().profileSyncs;
    const { tappedId } = getState().uiProfileSync;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableProfileSync(tappedId, status, token);

    dispatch(setProfileSyncSelectedPageSize(20));
    dispatch(setProfileSyncSelectedOrderBy(INITIAL_ORDER_BY_PROFILE_SYNC));
    dispatch(downloadProfileSyncsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingProfileSync(false));
  }
};
