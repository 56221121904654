import {
  addSurveyResponse, downloadingSurveyResponse,
} from '../simple-action';
import { downloadSurveyResponse } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSurveyResponse(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSurveyResponse;

    const response = await downloadSurveyResponse(tappedId, token);
    dispatch(addSurveyResponse(response));
  } finally {
    dispatch(downloadingSurveyResponse(false));
  }
};
