import PropTypes from 'prop-types';

export * from '../../type';

export const ClockInOutStatusTrendShape = PropTypes.shape({
  date: PropTypes.string,
  Early: PropTypes.number,
  Late: PropTypes.number,
  OnTime: PropTypes.number,
});

export const ClockInOutLocationTrendShape = PropTypes.shape({
  date: PropTypes.string,
  InsideArea: PropTypes.number,
  OutsideArea: PropTypes.number,
});

export const ChartBartItemShape = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
  argument: PropTypes.string,
  barColor: PropTypes.string,
});

export const CustomErrorMessageShape = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.object),
  totalEvent: PropTypes.number,
  totalUser: PropTypes.number,
});
