import { transformSearchText, downloadPersonalHolidays } from '../../../helper';
import { downloadingPersonalHolidays, setPersonalHolidays } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, personalHolidays, uiPersonalHoliday } = getState();
  const { token } = authentication;
  const { meta } = personalHolidays;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiPersonalHoliday;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingPersonalHolidays(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPersonalHolidays(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setPersonalHolidays(list));
  } finally {
    dispatch(downloadingPersonalHolidays(false));
  }
};
