import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import {
  RXFIELD_LOCATIONS_NAME, RXFORM_LOCATIONS, RXSTATE_LOCATIONS,
  RXSTATE_LOCATION_PAGE, RXFIELD_LOCATIONS_DESCRIPTION,
  PAGE_MODE_VIEW,
} from '../../constant';
import { rxformValidateLocations } from '../../validation';
import { FunctionalPage } from '../../component';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';

const renderDialogContent = (addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_LOCATIONS_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.locationScreen.placeholderName}
          label={LocalizedString.locationScreen.placeholderName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_LOCATIONS_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.locationScreen.placeholderDescription}
          label={LocalizedString.locationScreen.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>

);

const LocationPage = ({
  downloading, handleSubmit, pageMode, onEditPressed,
  addingEditing, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_LOCATIONS}
    uiPage={RXSTATE_LOCATION_PAGE}
    tableColumns={[
      {
        title: LocalizedString.locationScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.locationScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.locationScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.locationScreen.buttonCaptionCreateNew}
    deleteButtonCaption={LocalizedString.locationScreen.buttonCaptionDelete}
    title={LocalizedString.locationScreen.title}
    editButtonCaption={LocalizedString.locationScreen.buttonCaptionEdit}
    useFullWidth
  >
    {renderDialogContent(addingEditing, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_LOCATIONS,
  validate: rxformValidateLocations,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LocationPage);

LocationPage.propTypes = {
  onAppear: PropTypes.func.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
};
