import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles,
} from '@material-ui/core';
import { BasePage, VerticalSpacer } from '../../../../component';
import {
  COLOR_ICON_LIGHTER, COLOR_PRIMARY,
} from '../../../../constant';
import LocalizedString from '../../localization';
import {
  AnalyticsPercentageCard,
  CustomErrorMessages, ActiveUserChart,
  TotalUsersByAppVersionsChart, UserByDeviceModel, UserEngagementByScreenName,
  UserByCountry, PageTitleAndScreenName,
} from '../../components';

const useStyles = makeStyles(() => ({
  title: {
    color: COLOR_PRIMARY,
  },
  w500: {
    fontWeight: '500',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mb10: {
    marginBottom: 10,
  },
  gap10: {
    gap: '10px',
  },
  colorPrimary: {
    backgroundColor: COLOR_ICON_LIGHTER,
  },
  barColorPrimary: {
    backgroundColor: COLOR_PRIMARY,
  },
  flexEnd: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uppercase: {
    textTransform: 'capitalize',
  },
}));

const AnalyticsPage = ({
  onAppear,
}) => {
  const classes = useStyles();
  useEffect(() => {
    onAppear();
  }, [onAppear]);

  return (
    <BasePage>
      <Typography variant="h4" className={classes.title}>{LocalizedString.analyticsScreen.title}</Typography>
      <VerticalSpacer height={40} />
      <div className={classes.mb10}>
        <ActiveUserChart />
      </div>
      <div className={classes.mb10}>
        <TotalUsersByAppVersionsChart />
      </div>
      <div className={classes.mb10}>
        <AnalyticsPercentageCard>
          <UserByDeviceModel />
        </AnalyticsPercentageCard>
      </div>
      <div
        className={`${classes.row} ${classes.mb10} ${classes.gap10}`}
        style={{ alignItems: 'stretch' }}
      >
        <AnalyticsPercentageCard>
          <CustomErrorMessages />
        </AnalyticsPercentageCard>
      </div>
      <div className={classes.mb10}>
        <AnalyticsPercentageCard>
          <UserEngagementByScreenName />
        </AnalyticsPercentageCard>
      </div>
      <div className={classes.mb10}>
        <AnalyticsPercentageCard>
          <UserByCountry />
        </AnalyticsPercentageCard>
      </div>
      <div className={classes.mb10}>
        <AnalyticsPercentageCard>
          <PageTitleAndScreenName />
        </AnalyticsPercentageCard>
      </div>
    </BasePage>
  );
};

export default AnalyticsPage;

AnalyticsPage.propTypes = {
  onAppear: PropTypes.func.isRequired,
};
