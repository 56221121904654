import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearPickupLocations, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setPickupLocationSearchText, setPickupLocationSelectedPageSize, setPickupLocationSelectedOrderBy,
  setPickupLocationTappedId,
  addEditPickupLocationAsync, downloadDeletePickupLocationAsync, downloadPickupLocationsAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_PICKUP_LOCATIONS, MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION, PAGE_MODE_TABLE,
  RXFORM_PICKUP_LOCATION,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../../../localization';
import PickupLocationPage from './pickup-location.presentation';

const getInitialValues = (state) => {
  const { pickupLocations, uiFunctionalPage, uiPickupLocation } = state;
  const { data } = pickupLocations;
  const { downloadingDeleting, tappedId } = uiPickupLocation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : { name: '', description: '', keepPacketForHours: '' };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiPickupLocation.addingEditing,
  downloading: state.uiPickupLocation.downloading,
  downloadingDeleting: state.uiPickupLocation.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION));
    dispatch(setPickupLocationSearchText(''));
    dispatch(clearPickupLocations());
    dispatch(setPickupLocationSelectedPageSize(20));
    dispatch(setPickupLocationSelectedOrderBy(INITIAL_ORDER_BY_PICKUP_LOCATIONS));
    dispatch(downloadPickupLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPickupLocationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPickupLocationSelectedPageSize(pageSize));
    dispatch(downloadPickupLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PICKUP_LOCATION));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeletePickupLocationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setPickupLocationTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setPickupLocationTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setPickupLocationTappedId(id));
    dispatch(downloadDeletePickupLocationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setPickupLocationSelectedPageSize(pageSize));
    dispatch(clearPickupLocations());
    dispatch(downloadPickupLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description, keepPacketForHours }) => {
    try {
      await dispatch(addEditPickupLocationAsync(name, description, keepPacketForHours));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPickupLocationSearchText(text));
      dispatch(clearPickupLocations());
      await dispatch(downloadPickupLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPickupLocationSelectedOrderBy(orderBy));
    dispatch(clearPickupLocations());
    dispatch(downloadPickupLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description, keepPacketForHours }) => {
    await dispatch(addEditPickupLocationAsync(name, description, keepPacketForHours));
  },
  onViewPressed: (id) => {
    dispatch(setPickupLocationTappedId(id));
    dispatch(downloadDeletePickupLocationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupLocationPage);
