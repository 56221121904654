import {
  resyncingCity, setAlertErrorMessage, setCitySelectedOrderBy, setCitySelectedPageSize,
} from '../simple-action';
import { resyncMasterDataIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_CITIES, MASTER_DATA_ENTITY_NAME_CITY } from '../../../constant';
import downloadCitiesAsync from './downloadCitiesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingCity(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCity;

    await resyncMasterDataIndividually(MASTER_DATA_ENTITY_NAME_CITY, tappedId, token);

    dispatch(setCitySelectedPageSize(20));
    dispatch(setCitySelectedOrderBy(INITIAL_ORDER_BY_CITIES));
    dispatch(downloadCitiesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(resyncingCity(false));
  }
};
