import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import {
  DrawingManager, GoogleMap, LoadScript, Marker, Polygon,
} from '@react-google-maps/api';
import {
  COLOR_POLYGON, DRAW_MODE_MARKER, DRAW_MODE_NONE, DRAW_MODE_POLYGON, GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_URL_PREFIX,
} from '../../constant';
import { PointShape } from '../../type';

const LIBRARIES = ['drawing'];

const mapContainerStyle = {
  width: '100%',
  height: '650px',
};

const SimpleMapDrawer = ({
  center, marker, userMarker, userPolygon,
  polygon,
  disabled, hidden, loading, renderUserShapeAfterCompleted,
  onDrawingCompleted, onMapDrawerCompleted, onUserMarkerChanged, onUserPolygonChanged,
  zoom,
  containerStyle,
  drawingMode,
}) => {
  const [data, setData] = useState({ center, marker });
  useEffect(() => {
    if (!loading && center && marker) {
      setData({ center, marker });
    }
  }, [loading, center, marker]);

  const handleOnPolygonCompleted = (newPolygon) => {
    const vertices = newPolygon.getPath();
    const retval = [];

    for (let i = 0; i < vertices.getLength(); i += 1) {
      const xy = vertices.getAt(i);
      const latitude = xy.lat();
      const longitude = xy.lng();
      retval.push({ latitude, longitude });
    }

    if (userPolygon) {
      userPolygon.setMap(null);
    }
    if (!renderUserShapeAfterCompleted) {
      newPolygon.setMap(null);
    }

    onUserPolygonChanged(newPolygon);
    onMapDrawerCompleted(retval, onDrawingCompleted, drawingMode);
  };

  const handleOnMarkerCompleted = (newMarker) => {
    const { position } = newMarker;
    const latitude = position.lat();
    const longitude = position.lng();

    if (userMarker) {
      userMarker.setMap(null);
    }
    if (!renderUserShapeAfterCompleted) {
      newMarker.setMap(null);
    }

    const result = {
      latitude,
      longitude,
      url: GOOGLE_MAP_URL_PREFIX.replace(/\{latitude\}/, latitude).replace(/\{longitude\}/, longitude),
    };

    onUserMarkerChanged(newMarker);
    onMapDrawerCompleted(result, onDrawingCompleted, drawingMode);
  };

  const renderDrawingManager = () => {
    if (drawingMode === DRAW_MODE_POLYGON) {
      return (
        <DrawingManager
          drawingMode="polygon"
          options={{
            drawingControl: true,
            drawingControlOptions: { drawingModes: ['polygon'] },
            polygonOptions: {
              fillColor: COLOR_POLYGON,
              fillOpacity: 0.35,
              strokeWeight: 2,
              strokeColor: COLOR_POLYGON,
              clickable: false,
              editable: false,
              zIndex: 1,
            },
          }}
          onPolygonComplete={handleOnPolygonCompleted}
        />
      );
    }
    if (drawingMode === DRAW_MODE_MARKER) {
      return (
        <DrawingManager
          drawingMode="marker"
          options={{
            drawingControl: true,
            drawingControlOptions: { drawingModes: ['marker'] },
          }}
          onMarkerComplete={handleOnMarkerCompleted}
        />
      );
    }
    return null;
  };

  if (loading) {
    return (
      <CircularProgress color="inherit" />
    );
  }
  if (!hidden) {
    return (
      <LoadScript libraries={LIBRARIES} googleMapsApiKey={GOOGLE_MAP_API_KEY}>
        <GoogleMap
          mapContainerStyle={{ ...mapContainerStyle, ...containerStyle }}
          center={data.center}
          zoom={zoom}
        >
          {data.marker && (<Marker position={data.marker} disabled={disabled} />)}

          {polygon && polygon.length > 0 && (
          <Polygon
            path={polygon}
            editable={false}
            disabled={disabled}
            options={{
              strokeColor: COLOR_POLYGON,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: COLOR_POLYGON,
              fillOpacity: 0.35,
            }}
          />
          )}

          {!disabled && (renderDrawingManager())}
        </GoogleMap>
      </LoadScript>
    );
  }
  return null;
};

SimpleMapDrawer.propTypes = {
  center: PointShape,
  marker: PointShape,
  userMarker: PointShape,
  userPolygon: PointShape,
  polygon: PropTypes.arrayOf(PointShape),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  hidden: PropTypes.bool,
  renderUserShapeAfterCompleted: PropTypes.bool.isRequired,
  onDrawingCompleted: PropTypes.func,
  onMapDrawerCompleted: PropTypes.func.isRequired,
  onUserMarkerChanged: PropTypes.func.isRequired,
  onUserPolygonChanged: PropTypes.func.isRequired,
  zoom: PropTypes.number,
  containerStyle: PropTypes.objectOf(PropTypes.string),
  drawingMode: PropTypes.oneOf([
    DRAW_MODE_MARKER,
    DRAW_MODE_POLYGON,
    DRAW_MODE_NONE,
  ]),
};

SimpleMapDrawer.defaultProps = {
  center: null,
  marker: null,
  userMarker: null,
  userPolygon: null,
  polygon: [],
  drawingMode: DRAW_MODE_NONE,
  disabled: false,
  hidden: false,
  loading: false,
  onDrawingCompleted: () => {},
  zoom: 19,
  containerStyle: {},
};

export default SimpleMapDrawer;
