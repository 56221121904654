import {
  RESET_COLOR_PICKER,
  SET_COLOR_PICKER_HEX,
  SET_COLOR_PICKER_VISIBILITY,
} from '../action';

const initialState = {
  hex: '#000000',
  visibility: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COLOR_PICKER_HEX:
      return { ...state, hex: action.hex };
    case SET_COLOR_PICKER_VISIBILITY:
      return { ...state, visibility: action.status };
    case RESET_COLOR_PICKER:
      return initialState;
    default: return state;
  }
};
