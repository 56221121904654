import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { transformInitialValues, transformDropdownData } from '../../helper';
import {
  CONTENT_FORMAT_HTML, CONTENTS_FORMATS, INITIAL_ORDER_BY_FORUM_POSTS, MENUID_FORUM_POST,
  PAGE_MODE_TABLE, RXFIELD_FORUM_POST_LOCKED, RXFIELD_FORUM_POST_MEDIA, RXFIELD_FORUM_POST_PINNED,
  RXFORM_FORUM_POST, RXFIELD_FORUM_POST_CONTENT_FORMAT, RXFIELD_FORUM_POST_CONTENT, switches,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import {
  clearForumCategories, clearForumPosts, clearProfiles, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setForumCategoryAdvancedFilterDialogSelectedFilterString, setForumCategorySearchText,
  setForumPostSearchText, setForumPostSelectedAddMediaMenu, setForumPostSelectedCategory,
  setForumPostSelectedContentFormat, setForumPostSelectedLocked, setForumPostSelectedOrderBy,
  setForumPostSelectedPageSize, setForumPostSelectedPinned, setForumPostTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  addEditForumPostAsync, downloadDeleteForumPostAsync, downloadForumCategoriesAsync,
  downloadForumPostsAsync, downloadProfilesAsync, setAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import ForumPostPage from './forum-post.presentation';

const getInitialValues = (state) => {
  const {
    forumPosts, uiFunctionalPage, uiForumPost,
  } = state;
  const { data } = forumPosts;
  const { downloadingDeleting, tappedId } = uiForumPost;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      category: { label: found.category.name, value: found.category.id },
      author: found.author ? found.author.fullName : '',
      locked: found.locked ? switches[0] : switches[1],
      pinned: found.pinned ? switches[0] : switches[1],
      contentFormat: found.contentFormat === CONTENT_FORMAT_HTML
        ? CONTENTS_FORMATS[0] : CONTENTS_FORMATS[1],
    }) : {
      title: '',
      category: { label: '', value: '' },
      author: { label: '', value: '' },
      contentFormat: '',
      locked: switches[1],
      pinned: switches[1],
      media: [],
      content: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  forumCategories: transformDropdownData(state.forumCategories.data),
  profiles: transformDropdownData(state.profiles.data),
  addingEditing: state.uiForumPost.addingEditing,
  downloading: state.uiForumPost.downloading,
  downloadingDeleting: state.uiForumPost.downloadingDeleting,
  loadingCategory: state.uiForumCategory.downloading,
  loadingProfile: state.uiProfile.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  selectedAddMediaMenu: state.uiForumPost.selectedAddMediaMenu,
  initialValues: getInitialValues(state),
  selectedContentFormat: state.uiForumPost.selectedContentFormat,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_FORUM_POST));
    dispatch(setForumPostSearchText(''));
    dispatch(clearForumPosts());
    dispatch(setForumPostSelectedPageSize(20));
    dispatch(setForumPostSelectedOrderBy(INITIAL_ORDER_BY_FORUM_POSTS));
    dispatch(downloadForumPostsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setForumPostSelectedAddMediaMenu(menu));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setForumCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(clearForumCategories());
    dispatch(clearProfiles());
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/category/, 'category.id').replace(/author/, 'author.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearForumPosts());
    dispatch(downloadForumPostsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_FORUM_POST));
  },
  onCancelPressed: () => {
    dispatch(setForumPostSelectedCategory(''));
    dispatch(setForumPostSelectedContentFormat(''));
    dispatch(setForumPostSelectedLocked(''));
    dispatch(setForumPostSelectedPinned(''));
    dispatch(reset(RXFORM_FORUM_POST));
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setForumPostSelectedCategory(option));
    } else {
      dispatch(setForumPostSelectedCategory(''));
    }
  },
  onChangeCategoryText: async (text) => {
    try {
      dispatch(setForumCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setForumCategorySearchText(text));
      dispatch(clearForumCategories());
      await dispatch(downloadForumCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeContentText: (text) => {
    dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_CONTENT, text));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadForumPostsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setForumPostSelectedPageSize(pageSize));
    dispatch(downloadForumPostsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProfileText: async (text) => {
    try {
      dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteForumPostAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onContentFormatSelected: (option) => {
    if (option) {
      dispatch(setForumPostSelectedContentFormat(option.value));
      dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_CONTENT_FORMAT, option));
    } else {
      dispatch(setForumPostSelectedContentFormat(''));
      dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_CONTENT_FORMAT, ''));
    }
  },
  onCreatePressed: () => {
    dispatch(setForumPostTappedId(''));
    dispatch(setForumPostSelectedCategory(''));
    dispatch(setForumPostSelectedContentFormat(''));
    dispatch(setForumPostSelectedLocked(''));
    dispatch(setForumPostSelectedPinned(''));
    dispatch(setForumCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setForumCategorySearchText(''));
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setForumPostTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setForumPostTappedId(id));
    dispatch(setForumCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setForumCategorySearchText(''));
    dispatch(clearForumCategories());
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDeleteForumPostAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onLockedOptionSelected: (option) => {
    if (option) {
      dispatch(setForumPostSelectedLocked(option));
      dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_LOCKED, option));
    } else {
      dispatch(setForumPostSelectedLocked(''));
      dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_LOCKED, ''));
    }
  },
  onPinnedOptionSelected: (option) => {
    if (option) {
      dispatch(setForumPostSelectedPinned(option));
      dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_PINNED, option));
    } else {
      dispatch(setForumPostSelectedPinned(''));
      dispatch(change(RXFORM_FORUM_POST, RXFIELD_FORUM_POST_PINNED, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setForumPostSelectedPageSize(pageSize));
    dispatch(clearForumPosts());
    dispatch(downloadForumPostsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearForumPosts());
    dispatch(setForumPostSelectedOrderBy(INITIAL_ORDER_BY_FORUM_POSTS));
    dispatch(downloadForumPostsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ title, content, media }) => {
    try {
      await dispatch(addEditForumPostAsync(title, content, media));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setForumPostSearchText(text));
      dispatch(clearForumPosts());
      await dispatch(downloadForumPostsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setForumPostSelectedOrderBy(orderBy));
    dispatch(clearForumPosts());
    dispatch(downloadForumPostsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ title, content, media }) => {
    try {
      await dispatch(addEditForumPostAsync(title, content, media));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onViewPressed: (id) => {
    dispatch(setForumPostTappedId(id));
    dispatch(downloadDeleteForumPostAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForumPostPage);
