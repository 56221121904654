import { getAnalyticsTop5AppVersionsData, getAnalyticsUsersByAppVersionChartData } from '../../../helper';
import {
  downloadingAnalyticsUsersAppVersionChartData, setAnalyticsTop5AppsChartSeries,
  setAnalyticsTop5AppVersions, setAnalyticsUsersAppVersionChartData,
} from '../simple-action';
import { ANALYTICS_CHART_TOP_5_DATA_COLOR_SCHEMES } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsUsersAppVersionChartData(true));

    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { token } = authentication;
    const { usersAppVersionChartDataDateRange } = uiAnalytics;
    const propertyId = myConfigItems?.core?.googleAnalyticsPropertyId;
    let appVersions = [];

    const requestTop5AppBody = {
      dateRanges: [{
        startDate: usersAppVersionChartDataDateRange.from,
        endDate: usersAppVersionChartDataDateRange.to,
      }],
      dimensions: [{ name: 'appVersion' }],
      metrics: [{ name: 'activeUsers' }],
      limit: 5,
    };

    const top5Apps = await getAnalyticsTop5AppVersionsData(
      propertyId, requestTop5AppBody, token,
    );
    appVersions = top5Apps.map((app) => app.version);
    const chartColorSchemes = appVersions.map((version, index) => ({
      key: version,
      name: version,
      color: ANALYTICS_CHART_TOP_5_DATA_COLOR_SCHEMES[index],
    }));
    dispatch(setAnalyticsTop5AppsChartSeries(chartColorSchemes));
    dispatch(setAnalyticsTop5AppVersions(appVersions));

    const requestChartBody = {
      dateRanges: [{
        startDate: usersAppVersionChartDataDateRange.from,
        endDate: usersAppVersionChartDataDateRange.to,
      }],
      dimensions: [{ name: 'appVersion' }, { name: 'nthDay' }],
      metrics: [{ name: 'totalUsers' }],
      dimensionFilter: { filter: { fieldName: 'appVersion', inListFilter: { values: appVersions } } },
      orderBys: [{ dimension: { orderType: 'ALPHANUMERIC', dimensionName: 'nthDay' } }],
    };

    const chartData = await getAnalyticsUsersByAppVersionChartData(
      propertyId, requestChartBody, appVersions, token,
    );
    const charDataArray = Object.values(chartData);
    dispatch(setAnalyticsUsersAppVersionChartData(charDataArray));
  } finally {
    dispatch(downloadingAnalyticsUsersAppVersionChartData(false));
  }
};
