import { reset } from 'redux-form';
import {
  addingEditingDocumentGeneratorTemplate, clearDocumentGeneratorTemplates,
  setAlertErrorMessage, setFunctionalPageMode, setFunctionalPageTappedId,
  setDocumentGeneratorTemplateTappedId,
} from '../simple-action';
import { addDocumentGeneratorTemplate, editDocumentGeneratorTemplate } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW, RXFORM_TEMPLATE } from '../../../constant';
import downloadDocumentGeneratorTemplatesAsync from './downloadDocumentGeneratorTemplatesAsync';
import downloadTemplatePaperSizesAsync from './downloadTemplatePaperSizesAsync';
import downloadDocumentGeneratorTemplateAsync from './downloadDocumentGeneratorTemplateAsync';

export default (
  name, description, templateBody, header, footer, samplePayload,
  pageSize, marginTop, marginRight, marginLeft, marginBottom,
  headerMaxHeight, footerMaxHeight, headerDrawDividerLine,
  footerDrawDividerLine,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingDocumentGeneratorTemplate(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTemplate;

    const getBooleanValue = (value) => {
      if (typeof value === 'string') {
        if (value === 'true') return true;
        if (value === 'false') return false;
        return false;
      }
      if (typeof value === 'object') return value.value;
      return false;
    };

    const requestBody = {
      ...(tappedId && { id: tappedId }),
      name,
      description,
      body: templateBody,
      ...(header && { header }),
      ...(footer && { footer }),
      ...(samplePayload && { samplePayload }),
      ...(pageSize && { pageSize }),
      marginTop,
      marginRight,
      marginLeft,
      marginBottom,
      headerMaxHeight,
      footerMaxHeight,
      headerDrawDividerLine: getBooleanValue(headerDrawDividerLine),
      footerDrawDividerLine: getBooleanValue(footerDrawDividerLine),
    };

    if (tappedId) {
      await editDocumentGeneratorTemplate(requestBody, token);
      dispatch(setFunctionalPageTappedId(tappedId));
      dispatch(setDocumentGeneratorTemplateTappedId(tappedId));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));

      dispatch(downloadDocumentGeneratorTemplateAsync())
        .catch((err) => dispatch(setAlertErrorMessage(err)));

      dispatch(downloadTemplatePaperSizesAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      await addDocumentGeneratorTemplate(requestBody, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(reset(RXFORM_TEMPLATE));
      dispatch(clearDocumentGeneratorTemplates());

      dispatch(downloadDocumentGeneratorTemplatesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    }
  } finally {
    dispatch(addingEditingDocumentGeneratorTemplate(false));
  }
};
