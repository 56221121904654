import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_POINTS = '/point/api/v1/Point?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_POINT = '/point/api/v1/Point/{id}';
export const REST_URL_POINT_SUBMISSIONS = '/point/api/v1/PointSubmission?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_POINT_SUBMISSION = '/point/api/v1/PointSubmission/{id}';
export const REST_URL_PURCHASE_REQUESTS = '/point/api/v1/PurchaseRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_PURCHASE_REQUEST = '/point/api/v1/PurchaseRequest/{id}';
export const REST_URL_POINT_TRANSACTIONS = '/point/api/v1/Transaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_POINT_TRANSACTION = '/point/api/v1/Transaction/{id}';
export const REST_URL_ACHIEVEMENT = '/point/api/v1/Achievement/{id}';
export const REST_URL_BALANCE = '/point/api/v1/Balance/{id}';
export const REST_URL_VOID_POINT_REQUESTS = '/point/api/v1/VoidPointRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_VOID_POINT_REQUEST = '/point/api/v1/VoidPointRequest/{id}';
export const REST_URL_ADD_VOID_POINT_REQUEST = '/point/api/v1/VoidPointRequest';
export const REST_URL_LEADER_BOARD = '/point/api/v1/LeaderBoard?startRank={startRank}&count={count}';

export const ROUTE_NAME_POINT = '/point';
export const ROUTE_NAME_POINT_POINT = '/point-point';
export const ROUTE_NAME_POINT_POINT_SUBMISSION = '/point-submission';
export const ROUTE_NAME_POINT_POINT_TRANSACTION = '/point-transaction';
export const ROUTE_NAME_POINT_ACHIEVEMENT = '/achievement';
export const ROUTE_NAME_POINT_LEADER_BOARD = '/leader-board';
export const ROUTE_NAME_PURCHASE_REQUEST = '/purchase-request';
export const ROUTE_NAME_VOID_POINT_REQUEST = '/void-point-request';

export const RXFORM_POINT = 'pointPage';
export const RXFORM_POINT_SUBMISSION = 'pointSubmissionPage';
export const RXFORM_POINT_TRANSACTION = 'pointTransactionPage';
export const RXFORM_ACHIEVEMENT = 'achievementPage';
export const RXFORM_LEADER_BOARD = 'leaderBoardPage';
export const RXFORM_PURCHASE_REQUEST = 'purchaseRequestPage';
export const RXFORM_VOID_POINT_REQUEST = 'voidPointRequestPage';

export const RXFIELD_POINT_TAGS = 'tags';
export const RXFIELD_POINT_AMOUNT = 'amount';
export const RXFIELD_POINT_VALID_UNTIL = 'validUntil';
export const RXFIELD_POINT_EXPIRED_POINT_ALREADY_PROCESSED = 'expiredPointAlreadyProcessed';
export const RXFIELD_POINT_LABEL = 'label';
export const RXFIELD_POINT_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_POINT_MERCHANT_ID = 'merchantId';
export const RXFIELD_POINT_MERCHANT_NAME = 'merchantName';
export const RXFIELD_POINT_IS_VOIDED = 'isVoided';
export const RXFIELD_POINT_VOIDED_ON = 'voidedOn';
export const RXFIELD_POINT_VOID_REFERENCE_ID = 'voidReferenceId';
export const RXFIELD_POINT_VOID_NOTES = 'voidNotes';
export const RXFIELD_POINT_VOIDED_BY = 'voidedBy';
export const RXFIELD_POINT_USER = 'fullName';
export const RXFIELD_POINT_SUBMISSION_TAGS = 'tags';
export const RXFIELD_POINT_SUBMISSION_AMOUNT = 'amount';
export const RXFIELD_POINT_SUBMISSION_VALID_UNTIL = 'validUntil';
export const RXFIELD_POINT_SUBMISSION_LABEL = 'label';
export const RXFIELD_POINT_SUBMISSION_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_POINT_SUBMISSION_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_POINT_SUBMISSION_MERCHANT_ID = 'merchantId';
export const RXFIELD_POINT_SUBMISSION_MERCHANT_NAME = 'merchantName';
export const RXFIELD_POINT_SUBMISSION_CALLBACK_URL = 'callbackUrl';
export const RXFIELD_POINT_SUBMISSION_USER = 'fullName';
export const RXFIELD_POINT_TRANSACTION_EFFECTIVE_DATE = 'effectiveDate';
export const RXFIELD_POINT_TRANSACTION_TRANSACTION_NUMBER = 'transactionNumber';
export const RXFIELD_POINT_TRANSACTION_TYPE = 'type';
export const RXFIELD_POINT_TRANSACTION_REFERENCE_ID = 'referenceId';
export const RXFIELD_POINT_TRANSACTION_TAGS = 'tags';
export const RXFIELD_POINT_TRANSACTION_AMOUNT = 'amount';
export const RXFIELD_POINT_TRANSACTION_VALID_UNTIL = 'validUntil';
export const RXFIELD_POINT_TRANSACTION_LABEL = 'label';
export const RXFIELD_POINT_TRANSACTION_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_POINT_TRANSACTION_MERCHANT_ID = 'merchantId';
export const RXFIELD_POINT_TRANSACTION_MERCHANT_NAME = 'merchantName';
export const RXFIELD_POINT_TRANSACTION_USER = 'fullName';
export const RXFIELD_POINT_TRANSACTION_ITEMS = 'items';
export const RXFIELD_ACHIEVEMENT_PROFILE_PICTURE = 'profilePicture';
export const RXFIELD_ACHIEVEMENT_USERNAME = 'username';
export const RXFIELD_ACHIEVEMENT_FULLNAME = 'fullName';
export const RXFIELD_ACHIEVEMENT_EMAIL = 'email';
export const RXFIELD_ACHIEVEMENT_PHONE = 'phone';
export const RXFIELD_ACHIEVEMENT_TRANSACTION_HISTORY = 'transactionHistory';
export const RXFIELD_ACHIEVEMENT_USER = 'user';
export const RXFIELD_LEADER_BOARD_START_RANK = 'startRank';
export const RXFIELD_LEADER_BOARD_COUNT = 'count';
export const RXFIELD_LEADER_BOARD_START_PERIOD = 'startPeriod';
export const RXFIELD_LEADER_BOARD_END_PERIOD = 'endPeriod';
export const RXFIELD_LEADER_BOARD_RESULT = 'result';
export const RXFIELD_PURCHASE_REQUEST_LABEL = 'label';
export const RXFIELD_PURCHASE_REQUEST_AMOUNT = 'amount';
export const RXFIELD_PURCHASE_REQUEST_MERCHANT_ID = 'merchantId';
export const RXFIELD_PURCHASE_REQUEST_MERCHANT_NAME = 'merchantName';
export const RXFIELD_PURCHASE_REQUEST_MERCHANT_REF_ID = 'merchantReferenceId';
export const RXFIELD_PURCHASE_REQUEST_CALLBACK_URL = 'callbackUrl';
export const RXFIELD_PURCHASE_REQUEST_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_PURCHASE_REQUEST_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_PURCHASE_REQUEST_USER_ID = 'id';
export const RXFIELD_PURCHASE_REQUEST_FULL_NAME = 'fullName';
export const RXFIELD_PURCHASE_REQUEST_TAGS = 'tags';
export const RXFIELD_PURCHASE_REQUEST_PURCHASE_ITEMS = 'items';
export const RXFIELD_VOID_POINT_REQUEST_REASON = 'reason';
export const RXFIELD_VOID_POINT_REQUEST_MERCHANT_NAME = 'merchantName';
export const RXFIELD_VOID_POINT_REQUEST_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_VOID_POINT_REQUEST_POINT_NAME = 'point.label';
export const RXFIELD_VOID_POINT_REQUEST_POINT_AMOUNT = 'point.amount';
export const RXFIELD_VOID_POINT_REQUEST_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_VOID_POINT_REQUEST_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_VOID_POINT_REQUEST_CALLBACK_URL = 'callbackUrl';
export const RXFIELD_VOID_POINT_REQUEST_USER_ID = 'point.user.id';
export const RXFIELD_VOID_POINT_REQUEST_USER_FULLNAME = 'point.user.fullName';
export const RXFIELD_VOID_POINT_REQUEST_POINT = 'point';
export const RXFIELD_VOID_POINT_REQUEST_POINT_ID = 'point.id';
export const RXFIELD_VOID_POINT_REQUEST_USER = 'user';

export const RXSTATE_POINTS = 'points';
export const RXSTATE_POINT_PAGE = 'uiPoint';
export const RXSTATE_POINT_SUBMISSIONS = 'pointSubmissions';
export const RXSTATE_POINT_SUBMISSION_PAGE = 'uiPointSubmission';
export const RXSTATE_POINT_TRANSACTIONS = 'pointTransactions';
export const RXSTATE_POINT_TRANSACTION_PAGE = 'uiPointTransaction';
export const RXSTATE_POINT_PURCHASE_REQUESTS = 'purchaseRequests';
export const RXSTATE_POINT_PURCHASE_REQUEST_PAGE = 'uiPurchaseRequest';
export const RXSTATE_POINT_VOID_POINT_REQUESTS = 'voidPointRequests';
export const RXSTATE_POINT_VOID_POINT_REQUEST_PAGE = 'uiVoidPointRequest';

export const INITIAL_ORDER_BY_POINTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_POINT_SUBMISSIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_POINT_TRANSACTIONS = `effectiveDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_PURCHASE_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VOID_POINT_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_POINT = 'MENUID_POINT';
export const MENUID_POINT_POINT = 'MENUID_POINT_POINT';
export const MENUID_POINT_POINT_SUBMISSION = 'MENUID_POINT_POINT_SUBMISSION';
export const MENUID_POINT_POINT_TRANSACTION = 'MENUID_POINT_POINT_TRANSACTION';
export const MENUID_POINT_ACHIEVEMENT = 'MENUID_POINT_ACHIEVEMENT';
export const MENUID_POINT_LEADER_BOARD = 'MENUID_POINT_LEADER_BOARD';
export const MENUID_POINT_PURCHASE_REQUEST = 'MENUID_POINT_PURCHASE_REQUEST';
export const MENUID_POINT_VOID_POINT_REQUEST = 'MENUID_POINT_VOID_POINT_REQUEST';

export const SUBMISSION_STATUS_VALUES = [
  { label: 'Success', value: 'Success' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Error', value: 'Error' },
];

export const TRANSACTION_TYPE_VALUES = [
  { label: 'Top Up', value: 'TopUp' },
  { label: 'Purchase', value: 'Purchase' },
  { label: 'Void', value: 'Void' },
  { label: 'Expired Points', value: 'ExpiredPoints' },
];
