import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import {
  Avatar, CircularProgress, Divider, Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  COLOR_BACKGROUND, COLOR_BODY_TEXT_LIGHTER, COLOR_ICON_LIGHTER, COLOR_PRIMARY,
} from '../constant';
import { TotalCountShape } from '../type';

const theme = createMuiTheme();

theme.typography.h2 = {
  fontSize: '2rem',
  '@media (min-width:600px)': {
    fontSize: '2.3rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
};

const useStyles = makeStyles(() => ({
  flatContainer: {
    display: 'flex',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    background: COLOR_BACKGROUND,
    margin: '30px 0px 30px 0px',
  },
  innerContainer: {
    display: 'flex',
  },
  paperContainer: {
    height: 133,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
  number: {
    color: COLOR_PRIMARY,
  },
  caption: {
    color: COLOR_BODY_TEXT_LIGHTER,
  },
  icon: {
    color: COLOR_PRIMARY,
    background: COLOR_ICON_LIGHTER,
  },
  divider: {
    margin: '10px 0px 10px 0px',
    height: 113,
  },
  customField: {
    padding: 10,
  },
}));

const TotalCountSection = ({
  data, useCustomLastField, customLastField, mode,
}) => {
  const classes = useStyles();
  const dataCount = data.map((x) => ({ id: uuid(), ...x }));
  const isEven = 12 % data.length > 0 ? 12 / (data.length + 1) : 12 / data.length;

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        justify={mode === 'elevated' ? 'space-between' : 'space-around'}
        alignItems="center"
        className={mode === 'flat' ? classes.flatContainer : ''}
      >
        {dataCount.map((item) => (
          <Grid item sm={isEven} md={isEven} key={item.id}>
            <Paper
              className={classes.paperContainer}
              elevation={mode === 'elevated' ? 3 : 0}
              square={mode !== 'elevated'}
              key={item.id}
            >
              <Grid container alignItems="center">
                <Grid item xs sm md>
                  <Grid item>
                    {item.downloading
                      ? <CircularProgress size={60} />
                      : <Typography variant="h2" className={classes.number}>{item.count}</Typography>}
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={classes.caption}>{item.label}</Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Avatar className={classes.icon}>
                    {item.icon}
                  </Avatar>
                </Grid>
              </Grid>
            </Paper>

            {mode === 'flat' && (<Divider orientation="vertical" className={classes.divider} />)}
          </Grid>
        ))}

        {useCustomLastField && (
        <div className={classes.customField}>
          {customLastField}
        </div>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default TotalCountSection;

TotalCountSection.propTypes = {
  data: PropTypes.arrayOf(TotalCountShape).isRequired,
  useCustomLastField: PropTypes.bool,
  customLastField: PropTypes.node,
  mode: PropTypes.oneOf(['flat', 'elevated']),
};

TotalCountSection.defaultProps = {
  useCustomLastField: false,
  customLastField: (<></>),
  mode: 'elevated',
};
