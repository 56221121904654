import {
  DOWNLOADING_ACTIVITIES, DOWNLOADING_DELETING_ACTIVITY, SAVING_ACTIVITIES,
  SET_ACTIVITY_SELECTED_PAGE_SIZE, SET_ACTIVITY_TAPPED_ID,
  SET_ACTIVITY_SEARCH_TEXT, SET_ACTIVITY_SELECTED_ORDER_BY,
  SET_ACTIVITY_SELECTED_ACTIVITY_TYPE, SET_ACTIVITY_SELECTED_CUSTOMER,
  SET_ACTIVITY_SELECTED_EMPLOYEE,
} from '../action';
import { INITIAL_ORDER_BY_ACTIVITIES } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  saving: false,
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_ACTIVITIES,
  selectedActivityType: '',
  selectedCustomer: '',
  selectedEmployee: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ACTIVITIES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_ACTIVITY:
      return { ...state, downloadingDeleting: action.status };
    case SAVING_ACTIVITIES:
      return { ...state, saving: action.status };
    case SET_ACTIVITY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ACTIVITY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ACTIVITY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ACTIVITY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ACTIVITY_SELECTED_ACTIVITY_TYPE:
      return { ...state, selectedActivityType: action.option };
    case SET_ACTIVITY_SELECTED_CUSTOMER:
      return { ...state, selectedCustomer: action.option };
    case SET_ACTIVITY_SELECTED_EMPLOYEE:
      return { ...state, selectedEmployee: action.option };
    default: return state;
  }
};
