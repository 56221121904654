import { downloadingDivisions, setDivisions } from '../simple-action';
import { downloadDivisions, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDivisions(true));

    const { token } = getState().authentication;
    const { divisionsSearchText } = getState().notification;

    const list = await downloadDivisions(transformSearchText(divisionsSearchText.toLowerCase()),
      token);

    if (list) {
      dispatch(setDivisions(list));
    }
  } finally {
    dispatch(downloadingDivisions(false));
  }
};
