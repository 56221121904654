import { addRegistration, approvingRegistration } from '../simple-action';
import { approveRejectRegistration, downloadRegistration } from '../../../helper';
import { APPROVAL_STATUS_APPROVED } from '../../../constant';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(approvingRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistration;

    await approveRejectRegistration(tappedId, APPROVAL_STATUS_APPROVED, notes, token);
    const result = await downloadRegistration(tappedId, token);

    dispatch(addRegistration(result));
  } finally {
    dispatch(approvingRegistration(false));
  }
};
