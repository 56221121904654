import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import * as Action from '../action';
import authentication from './authentication';
import users from './users';
import currentUser from './currentUser';
import uiLogin from './uiLogin';
import uiProfile from './uiProfile';
import alert from './alert';
import uiBasePage from './uiBasePage';
import provinces from './provinces';
import uiProvince from './uiProvince';
import cities from './cities';
import uiCity from './uiCity';
import uiCompany from './uiCompany';
import uiFunctionalPage from './uiFunctionalPage';
import branches from './branches';
import uiBranch from './uiBranch';
import permissions from './permissions';
import uiPermission from './uiPermission';
import uiUser from './uiUser';
import roles from './roles';
import uiRole from './uiRole';
import apiKeys from './apiKeys';
import uiApiKey from './uiApiKey';
import tokens from './tokens';
import uiToken from './uiToken';
import applications from './applications';
import uiApplication from './uiApplication';
import cropImage from './cropImage';
import profiles from './profiles';
import totalUsers from './totalUsers';
import activeUsers from './activeUsers';
import activeDevices from './activeDevices';
import osDistribution from './osDistribution';
import loginUsing from './loginUsing';
import uiSummary from './uiSummary';
import androidVersionDistribution from './androidVersionDistribution';
import androidDeviceModelDistribution from './androidDeviceModelDistribution';
import iosVersionDistribution from './iosVersionDistribution';
import iosDeviceModelDistribution from './iosDeviceModelDistribution';
import companies from './companies';
import companiesMdata from './companiesMdata';
import divisions from './divisions';
import departments from './departments';
import browserDistribution from './browserDistribution';
import timezoneDistribution from './timezoneDistribution';
import notification from './notification';
import appVersionDistribution from './appVersionDistribution';
import services from './services';
import uiService from './uiService';
import mapDrawer from './mapDrawer';
import scopes from './scopes';
import uiScope from './uiScope';
import banners from './banners';
import uiBanner from './uiBanner';
import managers from './managers';
import deviceManufacturers from './deviceManufacturers';
import deviceManufacturerDistribution from './deviceManufacturerDistribution';
import deviceModels from './deviceModels';
import osNames from './osNames';
import osVersions from './osVersions';
import timeZones from './timeZones';
import appVersions from './appVersions';
import onlineUsers from './onlineUsers';
import districts from './districts';
import uiDistrict from './uiDistrict';
import subdistricts from './subdistricts';
import uiSubdistrict from './uiSubdistrict';
import requestTimeout from './requestTimeout';
import uiColorPickerField from './uiColorPickerField';
import {
  configActions, configGroups, configItems, configValues, uiConfigAction,
  uiConfigGroup, uiConfigItem, uiConfigValue, profileSyncs, uiProfileSync,
  myConfigItems,
} from '../../module/configuration';
import {
  totalClockInToday, totalClockOutToday, todayClockInStatus, todayClockOutStatus,
  uiAttendanceSummary, todayClockInLocationStatus, todayClockOutLocationStatus,
  clockInStatusTrend, clockOutStatusTrend, clockInLocationTrend, clockOutLocationTrend,
  histories, uiHistory, additionalFields, uiAdditionalField, additionalFieldParents,
  employees, workLocation,
} from '../../module/attendance';
import {
  faqCategories, uiFAQCategory, faqs, uiFAQ,
} from '../../module/faq';
import {
  emails, inboxes, smses, pushNotifications, notificationTasks, whatsapps,
  uiEmail, uiInbox, uiSms, uiPushNotification, uiNotificationTask, uiWhatsapp,
} from '../../module/notification';
import {
  previousMonthLocations, previousMonthPacketTypes, previousMonthTopRecipients, previousMonthTrends,
  previousYearLocations, previousYearPacketTypes, previousYearTopRecipients, previousYearTrends,
  thisMonthLocations, thisMonthPacketTypes, thisMonthTopRecipients, thisMonthTrends,
  thisYearLocations, thisYearPacketTypes, thisYearTopRecipients, thisYearTrends,
  packets, packetTypes, pickupLocations,
  uiPacket, uiPacketType, uiPickupLocation, uiPacketSummary,
} from '../../module/packet';
import {
  activities, activityTypes, customers, industries,
  uiActivity, uiActivityType, uiCustomer, uiIndustry,
} from '../../module/activity';
import {
  companyMappings, infoMappings, uiCompanyMapping, uiInfoMapping,
} from '../../module/bcard';
import {
  shortlinks, uiShortlink,
} from '../../module/shortlink';
import {
  auditTrails, uiAuditTrail,
} from '../../module/audit';
import {
  achievement, balance, points, pointSubmissions, pointTransactions, purchaseRequests,
  uiAchievement, uiPoint, uiPointSubmission, uiPointTransaction, uiPurchaseRequest,
  voidPointRequests, uiVoidPointRequest, leaderBoard, uiLeaderBoard,
} from '../../module/point';
import {
  voucherCategories, uiVoucherCategory, vouchers, uiVoucher,
  tokenRequests, uiTokenRequest, voucherTokens, uiVoucherToken,
  purchasers, claimers, revokers, tokenImports, uiTokenImport,
  voucherPurchaseRequests, uiVoucherPurchaseRequest,
  promotedMerchants, uiPromotedMerchant,
} from '../../module/voucher';
import {
  discussions, discussionComments, uiDiscussionComment, uiDiscussion,
  reportedComments, uiReportedComment,
} from '../../module/discussion';
import {
  forumCategories, uiForumCategory, forumParentCategories,
  forumPosts, uiForumPost,
} from '../../module/forum';
import {
  challenges, gameQRCategories, uiChallenge, uiGameQRCategory,
} from '../../module/game-qr';
import {
  catalog, models, uiCatalog, uiModel,
} from '../../module/car-catalog';
import {
  serviceRequests, uiServiceRequest, dealers, uiDealer,
} from '../../module/car-service';
import { registrations, uiRegistration } from '../../module/car-registration';
import {
  news, newsCategories, uiNews, uiNewsCategory, uiNewsDashboard,
  mostViewedNews, newsTrend,
} from '../../module/news';
import { partnerMerchants, uiPartnerMerchant } from '../../module/partner';
import {
  articles, articleCategories, uiArticle, uiArticleCategory,
} from '../../module/knowledge-management';
import {
  events, locations, uiEvent, uiLocation,
} from '../../module/agenda';
import {
  covidDatas, uiCovidData, uiCovidDashboard,
  covidTotalSummary, covidTrendSummary,
} from '../../module/covid';
import {
  quizCategories, quizes, quizQuestions, quizResponses, surveyCategories, surveys, surveyQuestions,
  surveyQuestionParents, surveyResponses,
  uiQuiz, uiQuizCategory, uiQuizQuestion, uiQuizResponse, uiSurvey, uiSurveyCategory,
  uiSurveyQuestion, uiSurveyResponse,
} from '../../module/survey-and-quiz';
import {
  templates, uiTemplate, templatePaperSizes, uiDocument, documents,
} from '../../module/document-generator';
import {
  fonts, annotationDesigns, uiAnnotationDesign,
  annotationDesignLists, uiAnnotationDesignList,
  uiPdfList, uiUploadPdf,
} from '../../module/pdf';
import {
  approvals, uiApproval, approvalProcesses, approvalProviders,
} from '../../module/approval';
import {
  attendanceCorrections, personalHolidays, wfaQuotas, wfaRequests, wfas, workingHourAdjustments,
  workingHours, holidays,
  uiAttendanceCorrection, uiPersonalHoliday, uiWfa, uiWFAQuota, uiWfaRequest, uiWorkingHour,
  uiWorkingHourAdjustment, uiHoliday,
} from '../../module/attendanceaddon';
import {
  jobs, uiJob, myJobs, uiMyJob,
} from '../../module/user-job';
import {
  calendars, uiCalendar, parentCalendars, uiParentCalendars,
  dateRangeStatus,
} from '../../module/calendar';
import { analytics, uiAnalytics } from '../../module/analytics';

const reducer = combineReducers({
  achievement,
  activeDevices,
  activeUsers,
  activities,
  activityTypes,
  additionalFieldParents,
  additionalFields,
  alert,
  analytics,
  androidDeviceModelDistribution,
  androidVersionDistribution,
  annotationDesignLists,
  annotationDesigns,
  apiKeys,
  applications,
  approvalProcesses,
  approvalProviders,
  approvals,
  appVersionDistribution,
  appVersions,
  articleCategories,
  articles,
  attendanceCorrections,
  auditTrails,
  authentication,
  balance,
  banners,
  branches,
  browserDistribution,
  calendars,
  catalog,
  challenges,
  cities,
  claimers,
  clockInLocationTrend,
  clockInStatusTrend,
  clockOutLocationTrend,
  clockOutStatusTrend,
  companies,
  companiesMdata,
  companyMappings,
  configActions,
  configGroups,
  configItems,
  configValues,
  covidDatas,
  covidTotalSummary,
  covidTrendSummary,
  cropImage,
  currentUser,
  customers,
  dateRangeStatus,
  dealers,
  departments,
  deviceManufacturerDistribution,
  deviceManufacturers,
  deviceModels,
  discussionComments,
  discussions,
  districts,
  divisions,
  documents,
  emails,
  employees,
  events,
  faqCategories,
  faqs,
  fonts,
  forumCategories,
  forumParentCategories,
  forumPosts,
  gameQRCategories,
  histories,
  holidays,
  inboxes,
  industries,
  infoMappings,
  iosDeviceModelDistribution,
  iosVersionDistribution,
  jobs,
  leaderBoard,
  locations,
  loginUsing,
  managers,
  mapDrawer,
  models,
  mostViewedNews,
  myConfigItems,
  myJobs,
  news,
  newsCategories,
  newsTrend,
  notification,
  notificationTasks,
  onlineUsers,
  osDistribution,
  osNames,
  osVersions,
  packets,
  packetTypes,
  parentCalendars,
  partnerMerchants,
  permissions,
  personalHolidays,
  pickupLocations,
  points,
  pointSubmissions,
  pointTransactions,
  previousMonthLocations,
  previousMonthPacketTypes,
  previousMonthTopRecipients,
  previousMonthTrends,
  previousYearLocations,
  previousYearPacketTypes,
  previousYearTopRecipients,
  previousYearTrends,
  profiles,
  profileSyncs,
  promotedMerchants,
  provinces,
  purchaseRequests,
  purchasers,
  pushNotifications,
  quizCategories,
  quizes,
  quizQuestions,
  quizResponses,
  registrations,
  reportedComments,
  requestTimeout,
  revokers,
  roles,
  scopes,
  serviceRequests,
  services,
  shortlinks,
  smses,
  subdistricts,
  surveyCategories,
  surveyQuestionParents,
  surveyQuestions,
  surveyResponses,
  surveys,
  templatePaperSizes,
  templates,
  thisMonthLocations,
  thisMonthPacketTypes,
  thisMonthTopRecipients,
  thisMonthTrends,
  thisYearLocations,
  thisYearPacketTypes,
  thisYearTopRecipients,
  thisYearTrends,
  timezoneDistribution,
  timeZones,
  todayClockInLocationStatus,
  todayClockInStatus,
  todayClockOutLocationStatus,
  todayClockOutStatus,
  tokenImports,
  tokenRequests,
  tokens,
  totalClockInToday,
  totalClockOutToday,
  totalUsers,
  uiAchievement,
  uiActivity,
  uiActivityType,
  uiAdditionalField,
  uiAnalytics,
  uiAnnotationDesign,
  uiAnnotationDesignList,
  uiApiKey,
  uiApplication,
  uiApproval,
  uiArticle,
  uiArticleCategory,
  uiAttendanceCorrection,
  uiAttendanceSummary,
  uiAuditTrail,
  uiBanner,
  uiBasePage,
  uiBranch,
  uiCalendar,
  uiCatalog,
  uiChallenge,
  uiCity,
  uiColorPickerField,
  uiCompany,
  uiCompanyMapping,
  uiConfigAction,
  uiConfigGroup,
  uiConfigItem,
  uiConfigValue,
  uiCovidDashboard,
  uiCovidData,
  uiCustomer,
  uiDealer,
  uiDiscussion,
  uiDiscussionComment,
  uiDistrict,
  uiDocument,
  uiEmail,
  uiEvent,
  uiFAQ,
  uiFAQCategory,
  uiForumCategory,
  uiForumPost,
  uiFunctionalPage,
  uiGameQRCategory,
  uiHistory,
  uiHoliday,
  uiInbox,
  uiIndustry,
  uiInfoMapping,
  uiJob,
  uiLeaderBoard,
  uiLocation,
  uiLogin,
  uiModel,
  uiMyJob,
  uiNews,
  uiNewsCategory,
  uiNewsDashboard,
  uiNotificationTask,
  uiPacket,
  uiPacketSummary,
  uiPacketType,
  uiParentCalendars,
  uiPartnerMerchant,
  uiPdfList,
  uiPermission,
  uiPersonalHoliday,
  uiPickupLocation,
  uiPoint,
  uiPointSubmission,
  uiPointTransaction,
  uiProfile,
  uiProfileSync,
  uiPromotedMerchant,
  uiProvince,
  uiPurchaseRequest,
  uiPushNotification,
  uiQuiz,
  uiQuizCategory,
  uiQuizQuestion,
  uiQuizResponse,
  uiRegistration,
  uiReportedComment,
  uiRole,
  uiScope,
  uiService,
  uiServiceRequest,
  uiShortlink,
  uiSms,
  uiSubdistrict,
  uiSummary,
  uiSurvey,
  uiSurveyCategory,
  uiSurveyQuestion,
  uiSurveyResponse,
  uiTemplate,
  uiToken,
  uiTokenImport,
  uiTokenRequest,
  uiUploadPdf,
  uiUser,
  uiVoidPointRequest,
  uiVoucher,
  uiVoucherCategory,
  uiVoucherPurchaseRequest,
  uiVoucherToken,
  uiWfa,
  uiWFAQuota,
  uiWfaRequest,
  uiWhatsapp,
  uiWorkingHour,
  uiWorkingHourAdjustment,
  users,
  voidPointRequests,
  voucherCategories,
  voucherPurchaseRequests,
  vouchers,
  voucherTokens,
  wfaQuotas,
  wfaRequests,
  wfas,
  whatsapps,
  workingHourAdjustments,
  workingHours,
  workLocation,
  form: formReducer,
});

export default (state, action) => {
  const matches = /(SET|CLEAR)_ERROR_(.+)/.exec(action.type);
  if (matches) {
    const reduxState = reducer(state, action);
    const [, stateOperation, stateName] = matches;
    const stateSlice = reduxState[stateName];
    if (stateSlice && stateOperation === 'SET') {
      return {
        ...reduxState,
        [stateName]: { ...stateSlice, error: action.error },
      };
    }
    if (stateSlice && stateOperation === 'CLEAR') {
      return {
        ...reduxState,
        [stateName]: { ...stateSlice, error: undefined },
      };
    }
  }

  if (action.type === Action.CLEAR_ALL_STATES) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};
