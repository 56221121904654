import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    businessCard: {
      title: 'Business Card',
    },
    companyMappingScreen: {
      title: 'Company Mapping',

      labelOrder: 'Order',
      labelPhrase: 'Phrase',
      labelOperator: 'Operator',
      labelCompanyName: 'Company Name',
      labelAddress: 'Address Line 1',
      labelAddressTwo: 'Address Line 2',
      labelCity: 'City',
      labelProvince: 'Province',
      labelPostalCode: 'Postal Code',
      labelPhone: 'Phone',
      labelFax: 'Fax',
      labelWebsite: 'Website',
      labelCompanyLogoUrl: 'Company Logo URL',
      labelPlaceholderCompanyLogoUrl: 'URL of company\'s logo image',
      labelImageUrl: 'Image 1 URL',
      labelPlaceholderImage1Url: 'URL of first company\'s brand image',
      labelImageTwoUrl: 'Image 2 URL',
      labelPlaceholderImage2Url: 'URL of second company\'s brand image',
      labelType: 'Type',

      buttonCaptionCreateNewCompanyMapping: 'Create New Company Mapping',
      buttonCaptionEditNewCompanyMapping: 'Edit Company Mapping',
      buttonCaptionDeleteNewCompanyMapping: 'Delete Company Mapping',
    },
    infoMappingScreen: {
      title: 'Info Mapping',

      labelNo: 'No',
      labelOrder: 'Order',
      labelType: 'Type',
      labelOperator: 'Operator',
      labelPhrase: 'Phrase',
      labelNewName: 'New Name',

      buttonCaptionCreateNewInfoMapping: 'Create New Info Mapping',
      buttonCaptionEditInfoMapping: 'Edit Info Mapping',
      buttonCaptionDeleteInfoMapping: 'Delete Info Mapping',

      placeholderOrder: 'Order',
      placeholderPhrase: 'Phrase',
      placeholderMappingOperator: 'Mapping Operator',
      placeholderNewName: 'New Name',
      placeholderType: 'Type',
    },
  },
  id: {
    businessCard: {
      title: 'Kartu Nama',
    },
    companyMappingScreen: {
      title: 'Pemetaan Perusahaan',

      labelOrder: 'Urutan',
      labelPhrase: 'Frasa',
      labelOperator: 'Operasi',
      labelCompanyName: 'Nama Perusahaan',
      labelAddress: 'Alamat',
      labelAddressTwo: 'Alamat II',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelPostalCode: 'Kode Pos',
      labelPhone: 'Telefon',
      labelFax: 'Fax',
      labelWebsite: 'Situs Web',
      labelCompanyLogoUrl: 'URL Logo Perusahaan',
      labelPlaceholderCompanyLogoUrl: 'URL Logo Perusahaan',
      labelImageUrl: 'URL Gambar 1',
      labelPlaceholderImage1Url: 'URL Gambar 1',
      labelImageTwoUrl: 'URL Gambar 2',
      labelPlaceholderImage2Url: 'URL Gambar 2',
      labelType: 'Tipe',

      buttonCaptionCreateNewCompanyMapping: 'Buat Pemetaan Perusahaan Baru',
      buttonCaptionEditNewCompanyMapping: 'Ubah Pemetaan Perusahaan Baru',
      buttonCaptionDeleteNewCompanyMapping: 'Hapus Pemetaan Perusahaan Baru',
    },
    infoMappingScreen: {
      title: 'Pemetaan Info',

      labelNo: 'No',
      labelOrder: 'Urutan',
      labelType: 'Tipe',
      labelOperator: 'Operator',
      labelPhrase: 'Frasa',
      labelNewName: 'Nama Baru',

      buttonCaptionCreateNewInfoMapping: 'Buat Pemetaan Info Baru',
      buttonCaptionEditInfoMapping: 'Ubah Pemetaan Info',
      buttonCaptionDeleteInfoMapping: 'Hapus Pemetaan Info',

      placeholderOrder: 'Urutan',
      placeholderPhrase: 'Frasa',
      placeholderMappingOperator: 'Operator Pemetaan',
      placeholderNewName: 'Nama Baru',
      placeholderType: 'Tipe',
    },
  },
});

export default LocalizedString;
