import {
  SET_NEWS_DASHBOARD_SELECTED_FILTER, CLEAR_NEWS_DASHBOARD,
  DOWNLOADING_MOST_VIEWED_NEWS,
} from '../action';

const initialState = {
  editing: false,
  downloading: false,
  selectedFilter: 'Today',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_DASHBOARD_SELECTED_FILTER:
      return { ...state, selectedFilter: action.option };
    case DOWNLOADING_MOST_VIEWED_NEWS:
      return { ...state, downloading: action.status };
    case CLEAR_NEWS_DASHBOARD:
      return initialState;
    default: return state;
  }
};
