import {
  ADDING_EDITING_EVENT, DOWNLOADING_EVENTS, DOWNLOADING_DELETING_EVENT,
  SET_EVENTS_SEARCH_TEXT, SET_EVENTS_SELECTED_ORDER_BY, SET_EVENTS_SELECTED_PAGE_SIZE,
  SET_EVENT_TAPPED_ID, SET_EVENT_SELECTED_TIME_ZONE, SET_EVENT_SELECTED_LOCATION,
  SAVING_EVENTS,
} from '../action';
import { INITIAL_ORDER_BY_EVENTS } from '../../constant';

const initialState = {
  saving: false,
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_EVENTS,
  selectedTimeZone: '',
  selectedLocation: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVING_EVENTS:
      return { ...state, saving: action.status };
    case DOWNLOADING_EVENTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_EVENT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_EVENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_EVENTS_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_EVENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_EVENTS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_EVENTS_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_EVENT_SELECTED_TIME_ZONE:
      return { ...state, selectedTimeZone: action.option };
    case SET_EVENT_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.option };
    default: return state;
  }
};
