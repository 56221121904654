import { ORDER_SORT_DESCENDING, ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_ARTICLES = '/knowledgemanagement/api/v1/Article?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=title&filterString=status=enabled';
export const REST_URL_ADD_EDIT_ARTICLE = '/knowledgemanagement/api/v1/Article';
export const REST_URL_VIEW_DELETE_ARTICLE = '/knowledgemanagement/api/v1/Article/{id}';
export const REST_URL_ARTICLE_CATEGORIES = '/knowledgemanagement/api/v1/ArticleCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_ARTICLE_CATEGORY = '/knowledgemanagement/api/v1/ArticleCategory';
export const REST_URL_VIEW_DELETE_ARTICLE_CATEGORY = '/knowledgemanagement/api/v1/ArticleCategory/{id}';
export const REST_URL_SEND_ARTICLE_TO_ME = '/knowledgemanagement/api/v1/SendToMe/{id}';

export const ROUTE_NAME_KNOWLEDGE_MANAGEMENT = '/knowledge-management';
export const ROUTE_NAME_ARTICLE = '/article';
export const ROUTE_NAME_ARTICLE_CATEGORY = '/article-category';

export const RXFORM_ARTICLES = 'articlesPage';
export const RXFORM_ARTICLE_CATEGORY = 'articleCategoryPage';

export const RXFIELD_ARTICLES_TITLE = 'title';
export const RXFIELD_ARTICLES_PUBLISHED_DATE = 'publishedDate';
export const RXFIELD_ARTICLES_AUTHOR = 'author';
export const RXFIELD_ARTICLES_CONTENT = 'content';
export const RXFIELD_ARTICLES_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_ARTICLES_MEDIA = 'media';
export const RXFIELD_ARTICLES_SHORT_URL = 'shortUrl';
export const RXFIELD_ARTICLES_REACTION_ID = 'reactionId';
export const RXFIELD_ARTICLES_DISCUSSION_ID = 'discusId';
export const RXFIELD_ARTICLES_CATEGORY = 'category';
export const RXFIELD_ARTICLES_VIEW_COUNT = 'viewCount';
export const RXFIELD_ARTICLES_DEEP_LINK = 'deepLink';

export const RXFIELD_ARTICLE_CATEGORY_NAME = 'name';
export const RXFIELD_ARTICLE_CATEGORY_DESCRIPTION = 'description';

export const RXSTATE_ARTICLES = 'articles';
export const RXSTATE_ARTICLE_PAGE = 'uiArticle';
export const RXSTATE_ARTICLE_CATEGORIES = 'articleCategories';
export const RXSTATE_ARTICLE_CATEGORY_PAGE = 'uiArticleCategory';

export const INITIAL_ORDER_BY_ARTICLES = `publishedDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_ARTICLE_CATEGORIES = `name ${ORDER_SORT_ASCENDING}`;

export const MENUID_KNOWLEDGE_MANAGEMENT = 'MENUID_KNOWLEDGE_MANAGEMENT';
export const MENUID_KNOWLEDGE_MANAGEMENT_ARTICLE_CATEGORY = 'MENUID_KNOWLEDGE_MANAGEMENT_ARTICLE_CATEGORY';
export const MENUID_KNOWLEDGE_MANAGEMENT_ARTICLES = 'MENUID_KNOWLEDGE_MANAGEMENT_ARTICLES';
