import { downloadingSummaryOnlineUsers, setSummaryOnlineUsers } from '../simple-action';
import { downloadSummaryOnlineUsers } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryOnlineUsers(true));
    const { token } = getState().authentication;

    const result = await downloadSummaryOnlineUsers(token);
    dispatch(setSummaryOnlineUsers(result));
  } finally {
    dispatch(downloadingSummaryOnlineUsers(false));
  }
};
