import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_COMPANY_MAPPINGS, PAGE_MODE_TABLE,
  RXFIELD_COMPANY_MAPPING_OPERATOR, RXFORM_COMPANY_MAPPING,
} from '../../constant';
import {
  addEditCompanyMappingAsync, clearCompanyMappings, downloadCompanyMappingsAsync,
  downloadDeleteCompanyMappingAsync, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setCompanyMappingsearchText,
  setCompanyMappingselectedOperator, setCompanyMappingselectedOrderBy,
  setCompanyMappingselectedPageSize, setCompanyMappingTappedId,
} from '../../redux/action';
import CompanyMappingScreen from './company-mapping.presentation';
import LocalizedString from '../../../../localization';
import { transformInitialValues } from '../../helper';

const getInitialValues = (state) => {
  const { companyMappings, uiFunctionalPage, uiCompanyMapping } = state;
  const { data } = companyMappings;
  const { downloadingDeleting, tappedId } = uiCompanyMapping;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found)
    : {
      order: '',
      phrase: '',
      mappingOperator: '',
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      province: '',
      postalCode: '',
      phone: '',
      fax: '',
      webSite: '',
      companyLogoUrl: '',
      image1Url: '',
      image2Url: '',
      type: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiCompanyMapping.addingEditing,
  downloading: state.uiCompanyMapping.downloading,
  downloadingDeleting: state.uiCompanyMapping.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(downloadCompanyMappingsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearCompanyMappings());
    dispatch(downloadCompanyMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_COMPANY_MAPPING));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COMPANY_MAPPING));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCompanyMappingsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCompanyMappingselectedPageSize(pageSize));
    dispatch(downloadCompanyMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteCompanyMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setCompanyMappingTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setCompanyMappingTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setCompanyMappingTappedId(id));
    dispatch(downloadDeleteCompanyMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setCompanyMappingselectedPageSize(pageSize));
    dispatch(clearCompanyMappings());
    dispatch(downloadCompanyMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCompanyMappings());
    dispatch(setCompanyMappingselectedOrderBy(INITIAL_ORDER_BY_COMPANY_MAPPINGS));
    dispatch(downloadCompanyMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    order, phrase, mappingOperator, companyName, addressLine1, addressLine2,
    city, province, postalCode, phone, fax, webSite, companyLogoUrl, image1Url, image2Url,
    type,
  }) => {
    try {
      await dispatch(addEditCompanyMappingAsync(
        order, phrase, mappingOperator, companyName, addressLine1, addressLine2,
        city, province, postalCode, phone, fax, webSite, companyLogoUrl, image1Url, image2Url,
        type,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCompanyMappingsearchText(text));
      dispatch(clearCompanyMappings());
      await dispatch(downloadCompanyMappingsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCompanyMappingselectedOrderBy(orderBy));
    dispatch(clearCompanyMappings());
    dispatch(downloadCompanyMappingsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    order, phrase, mappingOperator, companyName, addressLine1, addressLine2,
    city, province, postalCode, phone, fax, webSite, companyLogoUrl, image1Url, image2Url,
    type,
  }) => {
    await dispatch(addEditCompanyMappingAsync(
      order, phrase, mappingOperator, companyName, addressLine1, addressLine2,
      city, province, postalCode, phone, fax, webSite, companyLogoUrl, image1Url, image2Url,
      type,
    ));
  },
  onViewPressed: (id) => {
    dispatch(setCompanyMappingTappedId(id));
    dispatch(downloadDeleteCompanyMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onOperatorOptionSelected: (option) => {
    if (option) {
      dispatch(setCompanyMappingselectedOperator(option));
      dispatch(change(RXFORM_COMPANY_MAPPING, RXFIELD_COMPANY_MAPPING_OPERATOR, option));
    } else {
      dispatch(setCompanyMappingselectedOperator(''));
      dispatch(change(RXFORM_COMPANY_MAPPING, RXFIELD_COMPANY_MAPPING_OPERATOR, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMappingScreen);
