import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_COVID_DATAS = '/covid/api/v1/CovidData?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_COVID_DATA = '/covid/api/v1/CovidData';
export const REST_URL_VIEW_DELETE_COVID_DATA = '/covid/api/v1/CovidData/{id}';
export const REST_URL_COVID_DATA_TOTAL_SUMMARY = '/covid/api/v1/CovidData/TotalSummary?summaryPeriod={summaryPeriod}&employeeStatus={employeeStatus}';
export const REST_URL_COVID_DATA_TREND_SUMMARY = '/covid/api/v1/CovidData/TrendSummary?summaryPeriod={summaryPeriod}&employeeStatus={employeeStatus}';

export const ROUTE_NAME_COVID = '/covid';
export const ROUTE_NAME_COVID_DATA = '/covid-data';
export const ROUTE_NAME_COVID_DASHBOARD = '/covid-dashboard';

export const RXFORM_COVID_DATA = 'covidData';
export const RXFORM_COVID_DASHBOARD = 'covidDashboard';

export const RXFIELD_COVID_DATA_DATE = 'date';
export const RXFIELD_COVID_DATA_EMPLOYEE_STATUS = 'employeeStatus';
export const RXFIELD_COVID_DATA_HOSPITALIZED = 'hospitalized';
export const RXFIELD_COVID_DATA_SELF_ISOLATION = 'selfIsolation';
export const RXFIELD_COVID_DATA_DEATH = 'death';
export const RXFIELD_COVID_DATA_RECOVERED = 'recovered';
export const RXFIELD_COVID_DATA_VACCINATED = 'vaccinated';
export const RXFIELD_COVID_DATA_RT_ANTIBODY = 'rtAntibody';
export const RXFIELD_COVID_DATA_RT_ANTIGEN = 'rtAntigen';
export const RXFIELD_COVID_DATA_GENOSE = 'genose';
export const RXFIELD_COVID_DATA_SEROLOGI = 'serologi';
export const RXFIELD_COVID_DATA_PCR = 'pcr';
export const RXFIELD_COVID_DASHBOARD_SUMMARY_PERIOD_FILTER = 'summaryPeriod';
export const RXFIELD_COVID_DASHBOARD_EMPLOYEE_STATUS_FILTER = 'employeeStatus';


export const RXSTATE_COVID_DATAS = 'covidDatas';
export const RXSTATE_COVID_DATA_PAGE = 'uiCovidData';

export const INITIAL_ORDER_BY_COVID_DATAS = `date ${ORDER_SORT_DESCENDING}`;

export const MENUID_COVID = 'MENUID_COVID';
export const MENUID_COVID_DATA = 'MENUID_COVID_DATA';
export const MENUID_COVID_DASHBOARD = 'MENUID_COVID_DASHBOARD';

export const EMPLOYEE_STATUS = ['Organik', 'AlihDaya'];
export const EMPLOYEE_STATUS_FILTER = [
  { value: 'All', label: 'All' },
  { value: 'Organik', label: 'Organik' },
  { value: 'AlihDaya', label: 'Alih Daya' },
];
export const PERIOD_FILTER = [
  { value: 'Today', label: 'Today' },
  { value: 'ThisWeek', label: 'This Week' },
  { value: 'ThisMonth', label: 'This Month' },
  { value: 'ThisYear', label: 'This Year' },
];
