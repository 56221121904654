import { reset } from 'redux-form';
import { RXFORM_VOID_POINT_REQUEST, INITIAL_ORDER_BY_API_KEYS } from '../../../constant';
import { addVoidPointRequest, downloadApiKeys } from '../../../helper';
import { addingVoidPointRequest, clearVoidPointRequests, setAlertErrorMessage } from '../simple-action';
import downloadVoidPointRequestsAsync from './downloadVoidPointRequestsAsync';

export default (reason, merchantReferenceId, callbackUrl) => async (dispatch, getState) => {
  try {
    dispatch(addingVoidPointRequest(true));

    const { token } = getState().authentication;
    const { selectedPoint } = getState().uiVoidPointRequest;
    const point = selectedPoint.value;

    const apiKeys = await downloadApiKeys(
      1,
      20,
      INITIAL_ORDER_BY_API_KEYS,
      '',
      '|ownerAppName><POINT',
      token,
    );
    const apiKeysResponse = Object.values(apiKeys.data);

    await addVoidPointRequest(reason, merchantReferenceId, callbackUrl, point,
      apiKeysResponse[0].token);

    dispatch(reset(RXFORM_VOID_POINT_REQUEST));
    dispatch(clearVoidPointRequests());
    dispatch(downloadVoidPointRequestsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingVoidPointRequest(false));
  }
};
