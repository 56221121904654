import {
  generatingDocumentTemplatePreview,
} from '../simple-action';
import { generateDocumentTemplatePreview } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(generatingDocumentTemplatePreview(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTemplate;
    const body = { templateId: tappedId };
    const response = await generateDocumentTemplatePreview(body, token);

    const arrbuff = await response.arrayBuffer();
    const blob = new Blob([arrbuff], {
      type: 'application/pdf',
    });
    const newWindow = window.open('', '_blank');
    const dataUrl = window.URL.createObjectURL(blob);

    const title = newWindow.document.createElement('title');
    const iframe = newWindow.document.createElement('iframe');

    newWindow.document.head.appendChild(title);

    iframe.setAttribute('src', dataUrl);
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('type', 'application/pdf');

    newWindow.document.body.appendChild(iframe);
  } finally {
    dispatch(generatingDocumentTemplatePreview(false));
  }
};
