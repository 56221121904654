import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    locationScreen: {
      title: 'Location',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      placeholderName: 'Location Name',
      placeholderDescription: 'Description',

      buttonCaptionCreateNew: 'Create New Location',
      buttonCaptionEdit: 'Edit Location',
      buttonCaptionDelete: 'Delete Location',
    },
    eventScreen: {
      title: 'Event',

      labelNo: 'No',
      labelName: 'Name',
      labelStartTime: 'Start Time',
      labelReferenceNumber: 'Reference Number',
      labelParticipants: 'Participants',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelLocation: 'Location',

      placeholderName: 'Name',
      placeholderReferenceNumber: 'Reference Number',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderTimeZone: 'Time Zone',
      placeholderLocation: 'Location',
      placeholderParticipants: 'Participants',
      placeholderDescription: 'Description',

      buttonCaptionCreateNew: 'Create New Event',
      buttonCaptionEdit: 'Edit Event',
      buttonCaptionDelete: 'Delete Event',
    },
  },
  id: {
    locationScreen: {
      title: 'Lokasi',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      placeholderName: 'Nama Lokasi',
      placeholderDescription: 'Deskripsi',

      buttonCaptionCreateNew: 'Buat Lokasi Baru',
      buttonCaptionEdit: 'Ubah Lokasi',
      buttonCaptionDelete: 'Hapus Lokasi',
    },
    eventScreen: {
      title: 'Acara',

      labelNo: 'No',
      labelName: 'Nama',
      labelStartTime: 'Waktu Mulai',
      labelReferenceNumber: 'Nomor Referensi',
      labelParticipants: 'Peserta',
      labelStartDate: 'Tanggal Mulai',
      labelEndDate: 'Tanggal Selesai',
      labelLocation: 'Lokasi',

      placeholderName: 'Nama',
      placeholderReferenceNumber: 'Nomor Referensi',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderTimeZone: 'Zona Waktu',
      placeholderLocation: 'Lokasi',
      placeholderParticipants: 'Peserta',
      placeholderDescription: 'Deskripsi',

      buttonCaptionCreateNew: 'Buat Acara Baru',
      buttonCaptionEdit: 'Ubah Acara',
      buttonCaptionDelete: 'Hapus Acara',
    },
  },
});

export default LocalizedString;
