import { ROUTE_NAME_DASHBOARD, ROUTE_NAME_LOGIN } from '../../../constant';
import { downloadMyConfigItemsAsync, setAlertErrorMessage } from '../../../module/configuration/redux/action';

export default (history) => async (dispatch, getState) => {
  const { token } = getState().authentication;

  dispatch(downloadMyConfigItemsAsync())
    .catch((err) => dispatch(setAlertErrorMessage(err)));

  if (token) {
    const { currentUser } = getState();
    const user = currentUser ? getState().users[currentUser.id] : undefined;
    if (user) {
      history.push(ROUTE_NAME_DASHBOARD);
    } else {
      history.push(ROUTE_NAME_LOGIN + history.location.search);
    }
  } else {
    history.push(ROUTE_NAME_LOGIN + history.location.search);
  }
};
