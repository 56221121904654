import {
  DOWNLOADING_FAQS, ADDING_EDITING_FAQ, DOWNLOADING_DELETING_FAQ,
  SET_FAQ_SELECTED_PAGE_SIZE, SET_FAQ_TAPPED_ID, SET_FAQ_SEARCH_TEXT,
  SET_FAQ_SELECTED_ORDER_BY, SET_FAQ_SELECTED_CATEGORY_ID,
  SET_FAQ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SAVING_FAQS,
} from '../action';
import { INITIAL_ORDER_BY_FAQS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_FAQS,
  selectedCategoryId: '',
  saving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_FAQS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_FAQ:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_FAQ:
      return { ...state, downloadingDeleting: action.status };
    case SAVING_FAQS:
      return { ...state, saving: action.status };
    case SET_FAQ_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_FAQ_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FAQ_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_FAQ_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_FAQ_SELECTED_CATEGORY_ID:
      return { ...state, selectedCategoryId: action.id };
    case SET_FAQ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
