import { reset } from 'redux-form';
import {
  addingEditingProvince, clearProvinces, setAlertErrorMessage, setFunctionalPageMode,
  setProvinceSelectedTimezone,
} from '../simple-action';
import { addProvince, editProvince } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_PROVINCE } from '../../../constant';
import downloadProvincesAsync from './downloadProvincesAsync';

export default (name, code) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingProvince(true));

    const { token } = getState().authentication;
    const { tappedId, selectedTimezone } = getState().uiProvince;
    const { data } = getState().provinces;

    const found = data[tappedId];
    const timezone = selectedTimezone || found.timeZone;

    if (tappedId) {
      await editProvince(tappedId, name, timezone, code, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addProvince(name, timezone, code, token);
    }

    dispatch(reset(RXFORM_PROVINCE));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setProvinceSelectedTimezone(''));
  } finally {
    dispatch(addingEditingProvince(false));
  }
};
