
import {
  SET_COVID_DASHBOARD_ACTIVE_TAB, SET_COVID_DASHBOARD_SELECTED_PERIOD,
  SET_COVID_DASHBOARD_SELECTED_EMPLOYEE_STATUS,
  DOWNLOADING_COVID_DATA_TOTAL_SUMMARY,
  DOWNLOADING_COVID_DATA_TREND_SUMMARY,
} from '../action';

const initialState = {
  activeTab: 0,
  selectedSummaryPeriod: '',
  selectedEmployeeStatus: 'All',
  downloadingTotalSummary: false,
  downloadingTrendSummary: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COVID_DASHBOARD_ACTIVE_TAB:
      return { ...state, activeTab: action.index };
    case SET_COVID_DASHBOARD_SELECTED_PERIOD:
      return { ...state, selectedSummaryPeriod: action.filter };
    case SET_COVID_DASHBOARD_SELECTED_EMPLOYEE_STATUS:
      return { ...state, selectedEmployeeStatus: action.filter };
    case DOWNLOADING_COVID_DATA_TOTAL_SUMMARY:
      return { ...state, downloadingTotalSummary: action.status };
    case DOWNLOADING_COVID_DATA_TREND_SUMMARY:
      return { ...state, downloadingTrendSummary: action.status };
    default: return state;
  }
};
