import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_SURVEY_CATEGORIES = '/survey/api/v1/SurveyCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_SURVEY_CATEGORY = '/survey/api/v1/SurveyCategory';
export const REST_URL_VIEW_DELETE_SURVEY_CATEGORY = '/survey/api/v1/SurveyCategory/{id}';
export const REST_URL_QUIZ_CATEGORIES = '/survey/api/v1/QuizCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_QUIZ_CATEGORY = '/survey/api/v1/QuizCategory';
export const REST_URL_VIEW_DELETE_QUIZ_CATEGORY = '/survey/api/v1/QuizCategory/{id}';
export const REST_URL_QUIZES = '/survey/api/v1/Quiz?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_QUIZ = '/survey/api/v1/Quiz';
export const REST_URL_VIEW_DELETE_QUIZ = '/survey/api/v1/Quiz/{id}';
export const REST_URL_SURVEYS = '/survey/api/v1/Survey?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_SURVEY = '/survey/api/v1/Survey/{id}';
export const REST_URL_ADD_EDIT_SURVEY = '/survey/api/v1/Survey';
export const REST_URL_SURVEY_QUESTIONS = '/survey/api/v1/Survey/Question?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_SURVEY_QUESTION = '/survey/api/v1/Survey/Question';
export const REST_URL_VIEW_DELETE_SURVEY_QUESTION = '/survey/api/v1/Survey/Question/{id}';
export const REST_URL_ENABLE_DISABLE_SURVEY_QUESTION = '/survey/api/v1/Survey/Question/Status';
export const REST_URL_SURVEY_RESPONSES = '/survey/api/v1/SurveyResponse?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=survey.title|user.fullName&filterString=';
export const REST_URL_VIEW_SURVEY_RESPONSE = '/survey/api/v1/SurveyResponse/{id}';
export const REST_URL_QUIZ_QUESTIONS = '/survey/api/v1/Quiz/Question?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_QUIZ_QUESTION = '/survey/api/v1/Quiz/Question';
export const REST_URL_VIEW_DELETE_QUIZ_QUESTION = '/survey/api/v1/Quiz/Question/{id}';
export const REST_URL_ENABLE_DISABLE_QUIZ_QUESTION = '/survey/api/v1/Quiz/Question/Status';
export const REST_URL_QUIZ_RESPONSES = '/survey/api/v1/QuizResponse?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=quiz.title|user.fullName&filterString=';
export const REST_URL_VIEW_DELETE_QUIZ_RESPONSE = '/survey/api/v1/QuizResponse/{id}';
export const REST_URL_SAVE_SURVEY_RESULT = '/survey/api/v1/SurveyResult/Download?surveyId={id}';
export const REST_URL_QUIZ_SURVEY_RESULT = '/survey/api/v1/QuizResult/Download?quizId={id}';
export const REST_URL_SEND_QUIZ_TO_ME = '/survey/api/v1/QuizSendToMe/{id}';
export const REST_URL_SEND_SURVEY_TO_ME = '/survey/api/v1/SurveySendToMe/{id}';

export const ROUTE_NAME_SURVEY_MENU = '/survey-menu';
export const ROUTE_NAME_SURVEY_CATEGORY = '/survey-category';
export const ROUTE_NAME_SURVEY_SURVEY = '/survey';
export const ROUTE_NAME_SURVEY_QUESTION = '/survey-question';
export const ROUTE_NAME_SURVEY_RESPONSE = '/survey-response';
export const ROUTE_NAME_QUIZ_MENU = '/quiz-menu';
export const ROUTE_NAME_QUIZ_CATEGORY = '/quiz-category';
export const ROUTE_NAME_QUIZ_QUIZ = '/quiz';
export const ROUTE_NAME_QUIZ_QUESTION = '/quiz-question';
export const ROUTE_NAME_QUIZ_RESPONSE = '/quiz-response';

export const RXFORM_SURVEY_CATEGORY = 'SurveyCategoryPage';
export const RXFORM_QUIZ_CATEGORY = 'QuizCategoryPage';
export const RXFORM_QUIZ = 'QuizPage';
export const RXFORM_QUIZ_QUESTION = 'QuizQuestionPage';
export const RXFORM_QUIZ_RESPONSE = 'SurveyResponsePage';
export const RXFORM_SURVEY = 'SurveyPage';
export const RXFORM_SURVEY_QUESTION = 'SurveyQuestionPage';
export const RXFORM_SURVEY_RESPONSE = 'SurveyResponsePage';

export const RXFIELD_QUIZ_CATEGORY_NAME = 'name';
export const RXFIELD_QUIZ_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_SURVEY_CATEGORY_NAME = 'name';
export const RXFIELD_SURVEY_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_QUIZ_TITLE = 'title';
export const RXFIELD_QUIZ_DESCRIPTION = 'description';
export const RXFIELD_QUIZ_VALID_FROM = 'validFrom';
export const RXFIELD_QUIZ_VALID_UNTIL = 'validUntil';
export const RXFIELD_QUIZ_DURATION = 'durationMinutes';
export const RXFIELD_QUIZ_CATEGORY = 'category';
export const RXFIELD_QUIZ_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_QUIZ_DEEPLINK = 'deepLink';
export const RXFIELD_QUIZ_PARTICIPANT_COUNT = 'participantCount';
export const RXFIELD_QUIZ_RESULT = 'result';
export const RXFIELD_QUIZ_QUESTION_NAME = 'name';
export const RXFIELD_QUIZ_QUESTION_LABEL = 'label';
export const RXFIELD_QUIZ_QUESTION_VALIDATION_MESSAGE = 'validationMessage';
export const RXFIELD_QUIZ_QUESTION_ORDER = 'order';
export const RXFIELD_QUIZ_QUESTION_TEXT_MULTILINE = 'textMultiline';
export const RXFIELD_QUIZ_QUESTION_TEXT_KEYBOARD_TYPE = 'textKeyboardType';
export const RXFIELD_QUIZ_QUESTION_FIELD_TYPE = 'fieldType';
export const RXFIELD_QUIZ_QUESTION_PICKER_MODE = 'pickerMode';
export const RXFIELD_QUIZ_QUESTION_OPTION_MODE = 'optionMode';
export const RXFIELD_QUIZ_QUESTION_OPTION_VALUES = 'optionValues';
export const RXFIELD_QUIZ_QUESTION_OPTION_VALUE_SEPARATOR = 'optionValueSeparator';
export const RXFIELD_QUIZ_QUESTION_SECTION_ID = 'sectionId';
export const RXFIELD_QUIZ_QUESTION_SECTION_LABEL = 'sectionLabel';
export const RXFIELD_QUIZ_QUESTION_TEXT_MIN_LENGTH = 'textMinLength';
export const RXFIELD_QUIZ_QUESTION_TEXT_MAX_LENGTH = 'textMaxLength';
export const RXFIELD_QUIZ_QUESTION_TEXT_REGEX_VALIDATION = 'textRegexValidation';
export const RXFIELD_QUIZ_QUESTION_INT_MIN_VALUE = 'intMinValue';
export const RXFIELD_QUIZ_QUESTION_INT_MAX_VALUE = 'intMaxValue';
export const RXFIELD_QUIZ_QUESTION_FLOAT_MIN_VALUE = 'floatMinValue';
export const RXFIELD_QUIZ_QUESTION_FLOAT_MAX_VALUE = 'floatMaxValue';
export const RXFIELD_QUIZ_QUESTION_DATE_TIME_MIN_VALUE = 'dateTimeMinValue';
export const RXFIELD_QUIZ_QUESTION_DATE_TIME_MAX_VALUE = 'dateTimeMaxValue';
export const RXFIELD_QUIZ_QUESTION_MUST_USE_CAMERA = 'mustUseCamera';
export const RXFIELD_QUIZ_QUESTION_QUIZ = 'quiz';
export const RXFIELD_QUIZ_QUESTION_IMAGE = 'image';
export const RXFIELD_QUIZ_QUESTION_CLEAR_IMAGE = 'clearImage';
export const RXFIELD_QUIZ_RESPONSE_STARTED_AT = 'startedAt';
export const RXFIELD_QUIZ_RESPONSE_FINISHED_AT = 'finishedAt';
export const RXFIELD_QUIZ_RESPONSE_SCORE = 'score';
export const RXFIELD_QUIZ_RESPONSE_QUIZ_ID = 'quizId';
export const RXFIELD_QUIZ_RESPONSE_QUIZ_TITLE = 'quizTitle';
export const RXFIELD_QUIZ_RESPONSE_QUIZ = 'quiz';
export const RXFIELD_QUIZ_RESPONSE_USER_ID = 'userId';
export const RXFIELD_QUIZ_RESPONSE_USER_NAME = 'userName';
export const RXFIELD_QUIZ_RESPONSE_IS_FINSIHED = 'isFinished';
export const RXFIELD_QUIZ_RESPONSE_DURATION = 'duration';
export const RXFIELD_QUIZ_QUESTION_SCORE = 'score';
export const RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER = 'correctAnswer';
export const RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER_LOGICAL_OPERATOR = 'correctAnswerLogicalOperator';
export const RXFIELD_SURVEY_TITLE = 'title';
export const RXFIELD_SURVEY_DESCRIPTION = 'description';
export const RXFIELD_SURVEY_CATEGORY = 'category';
export const RXFIELD_SURVEY_VALID_FROM = 'validFrom';
export const RXFIELD_SURVEY_VALID_UNTIL = 'validUntil';
export const RXFIELD_SURVEY_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_SURVEY_PARTICIPANT_COUNT = 'participantCount';
export const RXFIELD_SURVEY_DEEPLINK = 'deepLink';
export const RXFIELD_SURVEY_QUESTION_NAME = 'name';
export const RXFIELD_SURVEY_QUESTION_LABEL = 'label';
export const RXFIELD_SURVEY_QUESTION_PLACEHOLDER = 'placeHolder';
export const RXFIELD_SURVEY_QUESTION_VALIDATION_MESSAGE = 'validationMessage';
export const RXFIELD_SURVEY_QUESTION_DEFAULT_VALUE = 'defaultValue';
export const RXFIELD_SURVEY_QUESTION_ORDER = 'order';
export const RXFIELD_SURVEY_QUESTION_REQUIRED = 'required';
export const RXFIELD_SURVEY_QUESTION_VISIBLE = 'visible';
export const RXFIELD_SURVEY_QUESTION_READ_ONLY = 'readOnly';
export const RXFIELD_SURVEY_QUESTION_TEXT_MULTILINE = 'textMultiline';
export const RXFIELD_SURVEY_QUESTION_TEXT_KEYBOARD_TYPE = 'textKeyboardType';
export const RXFIELD_SURVEY_QUESTION_FIELD_TYPE = 'fieldType';
export const RXFIELD_SURVEY_QUESTION_PICKER_MODE = 'pickerMode';
export const RXFIELD_SURVEY_QUESTION_OPTION_MODE = 'optionMode';
export const RXFIELD_SURVEY_QUESTION_OPTION_VALUES = 'optionValues';
export const RXFIELD_SURVEY_QUESTION_OPTION_VALUE_SEPARATOR = 'optionValueSeparator';
export const RXFIELD_SURVEY_QUESTION_SECTION_ID = 'sectionId';
export const RXFIELD_SURVEY_QUESTION_SECTION_LABEL = 'sectionLabel';
export const RXFIELD_SURVEY_QUESTION_TEXT_MIN_LENGTH = 'textMinLength';
export const RXFIELD_SURVEY_QUESTION_TEXT_MAX_LENGTH = 'textMaxLength';
export const RXFIELD_SURVEY_QUESTION_TEXT_REGEX_VALIDATION = 'textRegexValidation';
export const RXFIELD_SURVEY_QUESTION_INT_MIN_VALUE = 'intMinValue';
export const RXFIELD_SURVEY_QUESTION_INT_MAX_VALUE = 'intMaxValue';
export const RXFIELD_SURVEY_QUESTION_FLOAT_MIN_VALUE = 'floatMinValue';
export const RXFIELD_SURVEY_QUESTION_FLOAT_MAX_VALUE = 'floatMaxValue';
export const RXFIELD_SURVEY_QUESTION_DATE_TIME_MIN_VALUE = 'dateTimeMinValue';
export const RXFIELD_SURVEY_QUESTION_DATE_TIME_MAX_VALUE = 'dateTimeMaxValue';
export const RXFIELD_SURVEY_QUESTION_PARENT = 'parent';
export const RXFIELD_SURVEY_QUESTION_SHOW_IF_PARENT_VALUE = 'showIfParentValue';
export const RXFIELD_SURVEY_QUESTION_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR = 'showIfParentValueLogicalOperator';
export const RXFIELD_SURVEY_QUESTION_MUST_USE_CAMERA = 'mustUseCamera';
export const RXFIELD_SURVEY_QUESTION_SURVEY = 'survey';
export const RXFIELD_SURVEY_QUESTION_IMAGE = 'image';
export const RXFIELD_SURVEY_QUESTION_CLEAR_IMAGE = 'clearImage';
export const RXFIELD_SURVEY_RESPONSE_USER_NAME = 'userName';
export const RXFIELD_SURVEY_RESPONSE_USER_ID = 'userId';
export const RXFIELD_SURVEY_RESPONSE_SURVEY = 'survey';
export const RXFIELD_SURVEY_RESPONSE_SURVEY_TITLE = 'surveyTitle';
export const RXFIELD_SURVEY_RESPONSE_SURVEY_DESCRIPTION = 'surveyDescription';

export const RXSTATE_QUIZ_CATEGORIES = 'quizCategories';
export const RXSTATE_QUIZ_CATEGORY_PAGE = 'uiQuizCategory';
export const RXSTATE_SURVEY_CATEGORIES = 'surveyCategories';
export const RXSTATE_SURVEY_CATEGORY_PAGE = 'uiSurveyCategory';
export const RXSTATE_QUIZES = 'quizes';
export const RXSTATE_QUIZ_PAGE = 'uiQuiz';
export const RXSTATE_SURVEYS = 'surveys';
export const RXSTATE_SURVEY_PAGE = 'uiSurvey';
export const RXSTATE_SURVEY_QUESTIONS = 'surveyQuestions';
export const RXSTATE_SURVEY_QUESTION_PAGE = 'uiSurveyQuestion';
export const RXSTATE_SURVEY_RESPONSES = 'surveyResponses';
export const RXSTATE_SURVEY_RESPONSE_PAGE = 'uiSurveyResponse';
export const RXSTATE_QUIZ_QUESTIONS = 'quizQuestions';
export const RXSTATE_QUIZ_QUESTION_PAGE = 'uiQuizQuestion';
export const RXSTATE_QUIZ_RESPONSES = 'quizResponses';
export const RXSTATE_QUIZ_RESPONSE_PAGE = 'uiQuizResponse';

export const INITIAL_ORDER_BY_SURVEY_CATEGORIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_QUIZ_CATEGORIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_QUIZES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SURVEYS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SURVEY_QUESTIONS = `order ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SURVEY_RESPONSES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_QUIZ_QUESTIONS = `order ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_QUIZ_RESPONSES = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_SURVEY = 'MENUID_SURVEY';
export const MENUID_SURVEY_SURVEY_CATEGORY = 'MENUID_SURVEY_SURVEY_CATEGORY';
export const MENUID_SURVEY_SURVEY = 'MENUID_SURVEY_SURVEY';
export const MENUID_QUIZ = 'MENUID_QUIZ';
export const MENUID_QUIZ_QUIZ_CATEGORY = 'MENUID_QUIZ_QUIZ_CATEGORY';
export const MENUID_QUIZ_QUIZ = 'MENUID_QUIZ_QUIZ';
export const MENUID_QUIZ_QUIZ_QUESTION = 'MENUID_QUIZ_QUIZ_QUESTION';
export const MENUID_QUIZ_QUIZ_RESPONSE = 'MENUID_QUIZ_QUIZ_RESPONSE';
export const MENUID_SURVEY_SURVEY_QUESTION = 'MENUID_SURVEY_SURVEY_QUESTION';
export const MENUID_SURVEY_SURVEY_RESPONSE = 'MENUID_SURVEY_SURVEY_RESPONSE';
