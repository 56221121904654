import { rxFormValidateRequiredFields } from '../../validation';
import { RXFIELD_VOID_POINT_REQUEST_POINT, RXFIELD_VOID_POINT_REQUEST_REASON, RXFIELD_VOID_POINT_REQUEST_MERCHANT_REFERENCE_ID } from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateVoidPointRequest = (values) => {
  const requiredFields = [
    RXFIELD_VOID_POINT_REQUEST_REASON,
    RXFIELD_VOID_POINT_REQUEST_POINT,
    RXFIELD_VOID_POINT_REQUEST_MERCHANT_REFERENCE_ID,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
