import { downloadingQuizResponses, setQuizResponses } from '../simple-action';
import { downloadQuizResponses, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, quizResponses, uiQuizResponse,
  } = getState();
  const { token } = authentication;
  const { meta, data } = quizResponses;
  const { selectedPageSize, searchBarText, orderBy } = uiQuizResponse;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingQuizResponses(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadQuizResponses(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setQuizResponses(list));
    }
  } finally {
    dispatch(downloadingQuizResponses(false));
  }
};
