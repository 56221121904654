import { cancelWfa, downloadWfa } from '../../../helper';
import { addWfa, cancelingWfa } from '../simple-action';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(cancelingWfa(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWfa;

    await cancelWfa(tappedId, notes, token);

    const result = await downloadWfa(tappedId, token);
    dispatch(addWfa(result));
  } finally {
    dispatch(cancelingWfa(false));
  }
};
