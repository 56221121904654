import { downloadingQuizQuestions, setQuizQuestions } from '../simple-action';
import { downloadQuizQuestions, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, quizQuestions, uiQuizQuestion } = getState();
  const { token } = authentication;
  const { meta } = quizQuestions;
  const { selectedPageSize, searchBarText, orderBy } = uiQuizQuestion;
  const { filterString } = getState().uiFunctionalPage;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingQuizQuestions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadQuizQuestions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setQuizQuestions(list));
  } finally {
    dispatch(downloadingQuizQuestions(false));
  }
};
