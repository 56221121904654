import { downloadTemplatePaperSizes } from '../../../helper';
import { downloadingTemplatePaperSizes, setTemplatePaperSizes } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingTemplatePaperSizes(true));

    const list = await downloadTemplatePaperSizes(
      token,
    );

    if (list) {
      dispatch(setTemplatePaperSizes(list));
    }
  } finally {
    dispatch(downloadingTemplatePaperSizes(false));
  }
};
