import {
  DOWNLOADING_WHATSAPPS, DOWNLOADING_DELETING_WHATSAPP,
  RESENDING_WHATSAPP, SET_WHATSAPP_SELECTED_PAGE_SIZE,
  SET_WHATSAPP_TAPPED_ID, SET_WHATSAPP_SEARCH_TEXT,
  SET_WHATSAPP_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_WHATSAPPS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  resending: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_WHATSAPPS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WHATSAPPS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_WHATSAPP:
      return { ...state, downloadingDeleting: action.status };
    case RESENDING_WHATSAPP:
      return { ...state, resending: action.status };
    case SET_WHATSAPP_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WHATSAPP_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WHATSAPP_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WHATSAPP_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
