import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_INDUSTRY_DESCRIPTION, RXFIELD_INDUSTRY_NAME, RXFORM_INDUSTRY,
  RXSTATE_INDUSTRIES, RXSTATE_INDUSTRY_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { rxformValidateIndustry } from '../../validation';

const renderDialogContent = (addingEditing, downloadingDeleting, pageMode) => (
  <div>
    <Field
      name={RXFIELD_INDUSTRY_NAME}
      component={renderReduxFormOutlinedTextField}
      placeholder={LocalizedString.industryScreen.placeholderName}
      label={LocalizedString.industryScreen.placeholderName}
      disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
      required
    />
    <Field
      name={RXFIELD_INDUSTRY_DESCRIPTION}
      component={renderReduxFormOutlinedTextField}
      placeholder={LocalizedString.industryScreen.placeholderDescription}
      label={LocalizedString.industryScreen.placeholderDescription}
      disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
      required
      multiline
    />
  </div>
);

const IndustryPage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize, onConfirmDeletePressed,
  onCreatePressed, onDeletePressed, onEditPressed, onRefresh, onSavePressed, onSearchBarTextChanged,
  onSortPressed, onSubmitPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_INDUSTRIES}
    uiPage={RXSTATE_INDUSTRY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.industryScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.industryScreen.labelName, field: 'name', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.industryScreen.buttonCaptionCreateNewIndustry}
    deleteButtonCaption={LocalizedString.industryScreen.buttonCaptionDeleteIndustry}
    editButtonCaption={LocalizedString.industryScreen.buttonCaptionEditIndustry}
    title={LocalizedString.industryScreen.title}
    createPermissionName="ACTIVITY_CREATE_INDUSTRY"
    deletePermissionName="ACTIVITY_DELETE_INDUSTRY"
    editPermissionName="ACTIVITY_EDIT_INDUSTRY"
    useFullWidth
  >
    {renderDialogContent(addingEditing, downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_INDUSTRY,
  validate: rxformValidateIndustry,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(IndustryPage);

IndustryPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
