import {
  DOWNLOADING_SURVEYS, ADDING_EDITING_SURVEY, DOWNLOADING_DELETING_SURVEY,
  SET_SURVEY_SELECTED_PAGE_SIZE, SET_SURVEY_TAPPED_ID, SET_SURVEY_SEARCH_TEXT,
  SET_SURVEY_SELECTED_ORDER_BY, SET_SURVEY_SELECTED_VISIBILITY_STATUS,
  SET_SURVEY_SELECTED_CATEGORY_ID, SET_SURVEY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SAVING_SURVEY_RESULT, SENDING_SURVEY_NOTIFICATION, COPYING_SURVEY_DEEPLINK,
} from '../action';
import { INITIAL_ORDER_BY_SURVEYS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  sendingNotification: false,
  copying: false,
  selectedPageSize: 20,
  selectedVisibilityStatus: '',
  selectedCategoryId: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SURVEYS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SURVEYS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_SURVEY:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_SURVEY:
      return { ...state, downloadingDeleting: action.status };
    case SET_SURVEY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SURVEY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SURVEY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SURVEY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SURVEY_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.option };
    case SET_SURVEY_SELECTED_CATEGORY_ID:
      return { ...state, selectedCategoryId: action.option };
    case SET_SURVEY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SAVING_SURVEY_RESULT:
      return { ...state, saving: action.status };
    case SENDING_SURVEY_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    case COPYING_SURVEY_DEEPLINK:
      return { ...state, copying: action.status };
    default: return state;
  }
};
