/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import Calendar from 'rc-year-calendar';
import PropTypes from 'prop-types';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'rc-year-calendar/locales/rc-year-calendar.id';
import '../../styles/index.css';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  activityIndicator: {
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const YearCalendar = ({ selectedYear, daysPayload, downloadingDeleting }) => {
  const classes = useStyles();
  const ENGLISH_LANGUAGE = 'en';
  const INDONESIA_LANGUAGE = 'in';
  const [language, setLanguage] = useState(ENGLISH_LANGUAGE);
  let instance = null;

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    setLanguage(userLanguage === INDONESIA_LANGUAGE ? userLanguage : ENGLISH_LANGUAGE);
  }, []);

  const renderDataSource = (el, date, dayData) => {
    const dayObj = dayData[0];
    el.setAttribute('style', `background-color:${dayObj.color};color: white`);
    return el;
  };

  const renderDay = (el, date) => {
    const day = date.getDay();
    el.setAttribute('style', `color: ${day === 0 || day === 6 ? 'red' : ''}`);
    return el;
  };

  const handleDayEnter = (e) => {
    if (e.events.length > 0 && e.events[0].name.length > 0) {
      if (instance != null) {
        instance.destroy();
        instance = null;
      }

      instance = tippy(e.element, {
        placement: 'right',
        content: e.events[0].name,
        arrow: true,
      });
      instance.show();
    }
    return null;
  };

  const handleDayLeave = () => {
    if (instance != null) {
      instance.destroy();
      instance = null;
    }
  };

  if (downloadingDeleting) {
    return <CircularProgress className={classes.activityIndicator} />;
  }

  return (
    <Calendar
      language={language}
      year={selectedYear?.value}
      allowOverlap={false}
      dataSource={daysPayload}
      displayHeader={false}
      weekStart={1}
      style="custom"
      customDataSourceRenderer={(el, date, dayData) => renderDataSource(el, date, dayData)}
      customDayRenderer={(el, b) => renderDay(el, b)}
      onDayEnter={(e) => handleDayEnter(e)}
      onDayLeave={() => handleDayLeave()}
    />
  );
};

export default YearCalendar;

YearCalendar.propTypes = {
  selectedYear: PropTypes.object.isRequired,
  daysPayload: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
};
