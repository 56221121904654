import {
  addQuizQuestion, clearQuizQuestions, downloadingDeletingQuizQuestion,
  setAlertErrorMessage, setFunctionalPageMode, setQuizQuestionTappedId,
  setQuizQuestionSelectedFieldType,
  setQuizQuestionSelectedQuiz,
} from '../simple-action';
import { downloadQuizQuestion, deleteQuizQuestion } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadQuizQuestionsAsync from './downloadQuizQuestionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingQuizQuestion(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiQuizQuestion;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteQuizQuestion(tappedId, token);
      dispatch(setQuizQuestionTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearQuizQuestions());
      dispatch(downloadQuizQuestionsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const quizQuestion = await downloadQuizQuestion(tappedId, token);
      const { fieldType, quiz } = quizQuestion;
      dispatch(setQuizQuestionSelectedQuiz(quiz ? { label: quiz.title, value: quiz.id } : ''));
      dispatch(setQuizQuestionSelectedFieldType(fieldType));
      dispatch(addQuizQuestion(quizQuestion));
    }
  } finally {
    dispatch(downloadingDeletingQuizQuestion(false));
  }
};
