import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN,
  RXSTATE_WORKING_HOUR_ADJUSTMENTS,
  RXSTATE_WORKING_HOUR_ADJUSTMENT_PAGE,
  RXFORM_WORKING_HOUR_ADJUSTMENT,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_ID,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_CREATED_DATE,
  FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_STATUS,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_NUMBER,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_START_DATE,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_ID,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_NAME,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_END_DATE,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_NAME,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_ID,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_START_BEFORE_MIN,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_START_AFTER_MIN,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_END_BEFORE_MIN,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_END_AFTER_MIN,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_AUTO_CLOCK_OUT_AFTER_MINUTES,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_TIMEZONE,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_STATUS_TIME,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_PIC_ID,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_EMPLOYEE_ID,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_DEPARTMENT,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_PIC_NAME,
  RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_DIVISION,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';
import { APPROVAL_STATUS } from '../../../approval';
import { toMoment } from '../../helper';

const renderDialogContent = () => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderApprovalId}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderApprovalId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_NUMBER}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderApprovalNumber}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderApprovalNumber}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_STATUS}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderApprovalStatus}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderApprovalStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_STATUS_TIME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderApprovalStatusTime}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderApprovalStatusTime}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_PIC_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderApprovalPicId}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderApprovalPicId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_PIC_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderApprovalPicName}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderApprovalPicName}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderRequesterId}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderRequesterId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderRequesterName}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderRequesterName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_EMPLOYEE_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderRequesterEmployeeId}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderRequesterEmployeeId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_DEPARTMENT}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderRequesterDepartment}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderRequesterDepartment}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_DIVISION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderRequesterDivision}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderRequesterDivision}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_START_DATE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderStartDate}
            placeholder={LocalizedString.workingHourAdjustmentPage.lplaceholdertartDate}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_END_DATE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderEndDate}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderEndDate}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item sm md>
      <Field
        name={RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_TIMEZONE}
        component={renderReduxFormOutlinedTextField}
        label={LocalizedString.workingHourAdjustmentPage.placeholderWorkingHourTimeZone}
        placeholder={
            LocalizedString.workingHourPage.placeholderWorkingHourTimeZone
          }
        disabled
      />
    </Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderWorkingHourId}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderWorkingHourId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_START_BEFORE_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                LocalizedString.workingHourAdjustmentPage.placeholderStartBeforeMin
              }
            label={LocalizedString.workingHourAdjustmentPage.placeholderStartBeforeMin}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_START_AFTER_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                LocalizedString.workingHourAdjustmentPage.placeholderStartAfterMin
              }
            label={LocalizedString.workingHourAdjustmentPage.placeholderStartAfterMin}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderEarliestClockIn}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderWorkingHourName}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderWorkingHourName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_END_BEFORE_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                LocalizedString.workingHourAdjustmentPage.placeholderEndBeforeMin
              }
            label={LocalizedString.workingHourAdjustmentPage.placeholderEndBeforeMin}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_END_AFTER_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.workingHourAdjustmentPage.placeholderEndAfterMin}
            label={LocalizedString.workingHourAdjustmentPage.placeholderEndAfterMin}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_ADJUSTMENT_AUTO_CLOCK_OUT_AFTER_MINUTES}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.workingHourAdjustmentPage.placeholderAutoClockOut}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const WorkingHourAdjustmentPage = ({
  requesters, downloading, loadingRequester,
  handleSubmit, onAppear, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onChangePage, onChangePageSize, onChangeRequesterText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  workingHours, loadingWorkingHour, onChangeWorkingHourText,
  pics, loadingPic, onChangePicText,
}) => (
  <FunctionalPage
    data={RXSTATE_WORKING_HOUR_ADJUSTMENTS}
    uiPage={RXSTATE_WORKING_HOUR_ADJUSTMENT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.workingHourAdjustmentPage.labelApprovalStatus,
        field: 'approvalStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: APPROVAL_STATUS,
      },
      {
        title: LocalizedString.workingHourAdjustmentPage.labelRequester,
        field: 'requester',
        type: FILTER_TYPE_DROPDOWN,
        data: requesters,
        loading: loadingRequester,
        onChangeFilterText: onChangeRequesterText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.workingHourAdjustmentPage.labelPIC,
        field: 'pic',
        type: FILTER_TYPE_DROPDOWN,
        data: pics,
        loading: loadingPic,
        onChangeFilterText: onChangePicText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.workingHourAdjustmentPage.labelWorkingHour,
        field: 'workingHour',
        type: FILTER_TYPE_DROPDOWN,
        data: workingHours,
        loading: loadingWorkingHour,
        onChangeFilterText: onChangeWorkingHourText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.workingHourAdjustmentPage.labelCreatedDate,
        field: RXFIELD_WORKING_HOUR_ADJUSTMENT_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.workingHourAdjustmentPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.workingHourAdjustmentPage.labelApprovalNumber, field: 'approvalNumber', sorting: !downloading },
      { title: LocalizedString.workingHourAdjustmentPage.labelRequester, field: 'requester.fullName', sorting: !downloading },
      {
        title: LocalizedString.workingHourAdjustmentPage.labelStartDate,
        field: 'startDate',
        sorting: !downloading,
        render: ({ startDate }) => (startDate
          ? toMoment(startDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.workingHourAdjustmentPage.labelEndDate,
        field: 'endDate',
        sorting: !downloading,
        render: ({ endDate }) => (endDate
          ? toMoment(endDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.workingHourAdjustmentPage.labelWorkingHour, field: 'workingHour.name', sorting: !downloading },
      { title: LocalizedString.workingHourAdjustmentPage.labelPIC, field: 'approvalStatusPicName', sorting: !downloading },
      { title: LocalizedString.workingHourAdjustmentPage.labelApprovalStatus, field: 'approvalStatus', sorting: !downloading },
      {
        title: LocalizedString.workingHourAdjustmentPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.workingHourAdjustmentPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WORKING_HOUR_ADJUSTMENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WorkingHourAdjustmentPage);

WorkingHourAdjustmentPage.propTypes = {
  workingHours: PropTypes.arrayOf(SimpleDataShape).isRequired,
  requesters: PropTypes.arrayOf(SimpleDataShape).isRequired,
  pics: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingWorkingHour: PropTypes.bool.isRequired,
  loadingRequester: PropTypes.bool.isRequired,
  loadingPic: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeWorkingHourText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeRequesterText: PropTypes.func.isRequired,
  onChangePicText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
