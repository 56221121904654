import { reset } from 'redux-form';
import {
  addingEditingLocation, clearLocations, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addLocation, editLocation } from '../../../helper';
import { RXFORM_LOCATIONS, PAGE_MODE_TABLE } from '../../../constant';
import downloadLocationsAsync from './downloadLocationsAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingLocation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiLocation;

    if (tappedId) {
      await editLocation(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addLocation(name, description, token);
    }

    dispatch(reset(RXFORM_LOCATIONS));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingLocation(false));
  }
};
