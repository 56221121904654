import {
  REST_URL_DEALERS,
  REST_URL_SERVICE_REQUESTS,
  REST_URL_VIEW_DELETE_SERVICE_REQUEST,
} from './constant';
import {
  buildQueryParams, sendGetRequest, transformQueryResult,
  sendDeleteRequest,
} from '../../helper';

export * from '../../helper';

export const downloadServiceRequests = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SERVICE_REQUESTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_SERVICE_REQUESTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadServiceRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SERVICE_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const deleteServiceRequest = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SERVICE_REQUEST.replace(/\{id\}/, id), token);
};

export const downloadDealers = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DEALERS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_DEALERS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};
