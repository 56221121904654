import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, withStyles,
} from '@material-ui/core';
import { AccentButton } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_BACKGROUND_SECONDARY, COLOR_DANGEROUS, COLOR_PRIMARY,
  COLOR_TEXT_ON_PRIMARY, NOTIFICATION_RECIPIENT_TYPES, RXFIELD_NOTIFICATION_TASK_BODY,
  RXFIELD_NOTIFICATION_TASK_FILTERED_RECIPIENTS, RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE,
  RXFIELD_NOTIFICATION_TASK_TITLE, RXFIELD_NOTIFICATION_TASK_URL,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
  totalPersonCountContainer: {
    padding: '10px 20px 10px 20px',
    background: COLOR_BACKGROUND_SECONDARY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '20px 0px 20px 0px',
  },
  totalPersonCountWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  captionText: {
    marginLeft: 10,
  },
  boldText: {
    fontWeight: 'bold',
  },
  outlinedButton: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
    background: COLOR_BACKGROUND,
  },
}));

const DuplicateDialog = ({
  downloadingTotalPersonCount, submitting, visibility,
  handleSubmit, onCancelPressed, onCountPressed, onRecipientTypeOptionSelected, onSendPressed,
  totalPersonCount, classes,
}) => (
  <Dialog
    open={visibility}
    aria-labelledby="form-dialog-title"
    maxWidth="lg"
    fullWidth
    classes={{ paper: classes.paper }}
  >
    <div className={classes.headerContainer}>
      <DialogTitle>{LocalizedString.notificationTaskScreen.labelDuplicateTask}</DialogTitle>
    </div>

    <DialogContent>
      <form
        onSubmit={handleSubmit(onSendPressed)}
        className={classes.form}
      >
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_TITLE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderTitle}
                label={LocalizedString.notificationTaskScreen.placeholderTitle}
                disabled={submitting}
                multiline
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_BODY}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderBody}
                label={LocalizedString.notificationTaskScreen.placeholderBody}
                disabled={submitting}
                multiline
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_URL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderUrl}
                label={LocalizedString.notificationTaskScreen.placeholderUrl}
                disabled={submitting}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_FILTERED_RECIPIENTS}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderFilteredRecipients}
                label={LocalizedString.notificationTaskScreen.placeholderFilteredRecipients}
                disabled={submitting}
              />
            </Grid>

            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE}
                component={renderReduxFormSimpleDropdownField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderRecipientType}
                label={LocalizedString.notificationTaskScreen.placeholderRecipientType}
                disabled={submitting}
                data={NOTIFICATION_RECIPIENT_TYPES}
                onOptionSelected={onRecipientTypeOptionSelected}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.totalPersonCountContainer}>
          <div className={classes.totalPersonCountWrapper}>
            <Typography variant="h6" className={classes.boldText}>{totalPersonCount}</Typography>
            <Typography variant="subtitle2" className={classes.captionText}>{GlobalLocalizedString.common.labelPerson}</Typography>
          </div>

          <AccentButton
            variant="outlined"
            caption={GlobalLocalizedString.common.buttonCaptionCount}
            onClick={handleSubmit(onCountPressed)}
            loading={downloadingTotalPersonCount}
            className={classes.outlinedButton}
          />
        </Grid>

        <DialogActions className={classes.buttonContainer}>
          <AccentButton
            onClick={onCancelPressed}
            variant="text"
            caption={GlobalLocalizedString.common.buttonCaptionCancel}
            className={classes.buttonText}
            disabled={submitting}
          />

          <AccentButton
            variant="contained"
            type="submit"
            className={classes.button}
            loading={submitting}
            disableElevation
            caption={GlobalLocalizedString.common.buttonCaptionSend}
            disabled={submitting}
          />
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(DuplicateDialog);

DuplicateDialog.propTypes = {
  downloadingTotalPersonCount: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  visibility: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCountPressed: PropTypes.func.isRequired,
  onRecipientTypeOptionSelected: PropTypes.func.isRequired,
  onSendPressed: PropTypes.func.isRequired,
  totalPersonCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};
