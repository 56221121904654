import {
  DOWNLOADING_MODELS, ADDING_EDITING_MODEL, DOWNLOADING_DELETING_MODEL,
  SET_MODEL_SELECTED_PAGE_SIZE, SET_MODEL_TAPPED_ID, SET_MODEL_SEARCH_TEXT,
  SET_MODEL_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_MODELS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_MODELS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_MODELS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_MODEL:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_MODEL:
      return { ...state, downloadingDeleting: action.status };
    case SET_MODEL_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_MODEL_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_MODEL_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_MODEL_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
