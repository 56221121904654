import { connect } from 'react-redux';
import { isEmpty, toNumber, omit } from 'lodash';
import PageTitleAndScreenName from './page-title-and-screen-name.presentation';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  setAnalyticsPageTitleAndScreenNameDateRanges,
  setPageTitleAndScreenNameFilterDialogVisibility,
  setAlertErrorMessage,
} from '../../redux/action/simple-action';
import downloadAnalyticsPageTitleAndScreenNamesAsync from '../../redux/action/async/downloadAnalyticsPageTitleAndScreenNamesAsync';
import {
  getNDaysAgo,
  sortDesc,
  toCurrency,
  toMoment,
} from '../../helper';

const getFilterDateRange = (dateRange) => {
  let dateFrom = dateRange.from;
  let dateTo = dateRange.to;

  if (dateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (dateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const convertSecondsToHoursAndMinutes = (seconds) => {
  const roundedSeconds = Math.floor(seconds);
  const hours = Math.floor(roundedSeconds / 3600);
  const minutes = Math.floor((roundedSeconds % 3600) / 60);
  const remainingSeconds = roundedSeconds % 60;
  return { hours, minutes, remainingSeconds };
};

const getAverageEngagementPerActiveUser = (seconds) => {
  const time = convertSecondsToHoursAndMinutes(seconds);
  if (time.hours && time.minutes && time.remainingSeconds) {
    return `${time.hours}h ${time.minutes}m ${time.remainingSeconds}s`;
  }
  if (time.minutes && time.remainingSeconds) {
    return `${time.minutes}m ${time.remainingSeconds}s`;
  }
  return `${time.remainingSeconds}s`;
};

const getViewsPerActiveUsersWithDecimalValue = (x) => {
  const split = `${x.viewsPerActiveUser}`.split('.');
  const firstValue = split[0];
  const secondValue = split[1];
  const currencyFirstValue = toCurrency(firstValue);
  const firstTwoDigitSecondValue = secondValue.slice(0, 2);
  return `${currencyFirstValue},${firstTwoDigitSecondValue}`;
};

const getPageTitleAndScreenNameData = (state) => {
  const { pageTitleAndScreenNames } = state.analytics;
  const sortedData = pageTitleAndScreenNames
    .sort((a, b) => sortDesc(toNumber(a.views), toNumber(b.views)));
  const data = sortedData.map((x) => {
    const viewsPerActiveUser = () => {
      if (!x.viewsPerActiveUser) return '';
      if (`${x.viewsPerActiveUser}`.includes('.')) {
        return getViewsPerActiveUsersWithDecimalValue(x);
      }
      return toCurrency(`${x.viewsPerActiveUser}`);
    };

    return ({
      ...x,
      views: toCurrency(`${x.views}`),
      activeUsers: toCurrency(`${x.activeUsers}`),
      viewsPerActiveUser: viewsPerActiveUser(),
      averageEngagementPerActiveUser: getAverageEngagementPerActiveUser(
        x.averageEngagementPerActiveUser,
      ),
    });
  });
  return data;
};

const getTotalValues = (state) => {
  const { pageTitleAndScreenNames } = state.analytics;
  const baseData = pageTitleAndScreenNames.reduce((p, c) => {
    if (!isEmpty(p)) {
      return {
        views: toNumber(p.views) + toNumber(c.views),
        activeUsers: toNumber(p.activeUsers) + toNumber(c.activeUsers),
        viewsPerActiveUser: toNumber(p.viewsPerActiveUser)
          + toNumber(c.viewsPerActiveUser),
        averageEngagementPerActiveUser: p.averageEngagementPerActiveUser
          + c.averageEngagementPerActiveUser,
      };
    }
    return omit(c, 'screenPageName');
  }, {});

  const viewsPerActiveUser = () => {
    if (!baseData.viewsPerActiveUser) return '';
    if (`${baseData.viewsPerActiveUser}`.includes('.')) {
      return getViewsPerActiveUsersWithDecimalValue(baseData);
    }
    return toCurrency(`${baseData.viewsPerActiveUser}`);
  };

  const data = {
    views: toCurrency(`${baseData?.views || 0}`),
    activeUsers: toCurrency(`${baseData?.activeUsers || 0}`),
    viewsPerActiveUser: viewsPerActiveUser(),
    averageEngagementPerActiveUser: getAverageEngagementPerActiveUser(
      baseData.averageEngagementPerActiveUser,
    ),
  };
  return data;
};

const getTotalData = (state) => {
  const { pageTitleAndScreenNames } = state.analytics;
  return pageTitleAndScreenNames?.length || 0;
};

const mapStateToProps = (state) => ({
  pageTitleAndScreenNames: getPageTitleAndScreenNameData(state),
  downloadingPageTitleAndScreenNames: state.uiAnalytics.downloadingPageTitleAndScreenNames,
  pageTitleAndScreenNameDateRange:
    getFilterDateRange(state.uiAnalytics.pageTitleAndScreenNameDateRange),
  pageTitleAndScreenNameFilterDialogVisibility:
        state.uiAnalytics.pageTitleAndScreenNameFilterDialogVisibility,
  pageTitleAndScreenNameTotalValues: getTotalValues(state),
  totalPages: getTotalData(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFilterIconPressed: () => {
    dispatch(setPageTitleAndScreenNameFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setPageTitleAndScreenNameFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsPageTitleAndScreenNameDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsPageTitleAndScreenNamesAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setPageTitleAndScreenNameFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsPageTitleAndScreenNameDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsPageTitleAndScreenNamesAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setPageTitleAndScreenNameFilterDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitleAndScreenName);
