import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import {
  Button, FormControl, Typography, makeStyles, FormHelperText, ButtonGroup,
} from '@material-ui/core';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import LocalizedString from '../localization';
import { COLOR_DANGEROUS } from '../constant';
import VerticalSpacer from './vertical-spacer';
import { getImageValue } from '../helper';

const useStyles = makeStyles((theme) => ({
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  button: {
    borderRadius: 5,
    borderWidth: 1,
  },
  cameraSourceButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const renderCapturedImage = (value) => {
  if (value) {
    return (
      <img alt="avatar" src={getImageValue(value)} width={480} height={360} />
    );
  }
  return null;
};

const renderCameraSourceButtons = (devices, setDeviceId, classes) => (
  <ButtonGroup size="small">
    {devices.map((device) => (
      <Button
        onClick={() => setDeviceId(device.deviceId)}
        className={classes.button}
      >
        {device.label}
      </Button>
    ))}
  </ButtonGroup>
);

const WebCam = ({
  onPictureTaken, error, disabled, label, helperText, value,
}) => {
  const classes = useStyles();

  const [deviceId, setDeviceId] = useState({});
  const [devices, setDevices] = useState([]);

  const videoConstraints = {
    width: 960,
    height: 720,
    facingMode: 'environment',
    deviceId,
  };

  const webcamRef = useRef(null);

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot({ width: 960, height: 720 });
      onPictureTaken(imageSrc);
    },
    [webcamRef, onPictureTaken],
  );
  const handleDevices = useCallback(
    (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices],
  );

  useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices],
  );

  return (
    <div>
      <Typography>{label}</Typography>
      <FormControl
        error={error}
      >
        <Webcam
          audio={false}
          height={360}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={480}
          videoConstraints={videoConstraints}
        />
        <VerticalSpacer height={20} />
        <div className={classes.cameraSourceButtonsContainer}>
          <Typography>{LocalizedString.common.labelCameraSource}</Typography>
          {renderCameraSourceButtons(devices, setDeviceId, classes)}
        </div>
        <VerticalSpacer height={20} />
        <Button
          onClick={capture}
          disabled={disabled}
          variant="contained"
        >
          {LocalizedString.common.buttonCaptionTakePicture}
        </Button>
        <VerticalSpacer height={20} />
        {renderCapturedImage(value)}
        <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default WebCam;

WebCam.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,

  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,

  onPictureTaken: PropTypes.func.isRequired,
};

WebCam.defaultProps = {
  error: false,
  disabled: false,

  value: '',

  label: null,
  helperText: null,
};
