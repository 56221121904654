import { downloadingAdditionalFieldParents, setAdditionalFieldParents } from '../simple-action';
import { downloadAdditionalFields, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_ADDITIONAL_FIELDS } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, additionalFieldParents, uiAdditionalField } = getState();
  const { token } = authentication;
  const { meta } = additionalFieldParents;
  const { parentSearchText } = uiAdditionalField;

  const searchText = transformSearchText(parentSearchText);

  try {
    dispatch(downloadingAdditionalFieldParents(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAdditionalFields(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_ADDITIONAL_FIELDS,
      searchText,
      null,
      token,
    );

    dispatch(setAdditionalFieldParents(list));
  } finally {
    dispatch(downloadingAdditionalFieldParents(false));
  }
};
