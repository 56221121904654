import { reset } from 'redux-form';
import {
  addingEditingQuizCategory, clearQuizCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addQuizCategory, editQuizCategory } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_QUIZ_CATEGORY } from '../../../constant';
import downloadQuizCategoriesAsync from './downloadQuizCategoriesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingQuizCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiQuizCategory;

    if (tappedId) {
      await editQuizCategory(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addQuizCategory(name, description, token);
    }

    dispatch(reset(RXFORM_QUIZ_CATEGORY));
    dispatch(clearQuizCategories());
    dispatch(downloadQuizCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingQuizCategory(false));
  }
};
