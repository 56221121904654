
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    partnerMenu: {
      title: 'Partner',
    },
    partnerMerchantPage: {
      title: 'Partner Merchant',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelShortName: 'Short Name',
      labelTagLine: 'Tag Line',
      labelStatus: 'Status',

      placeholderName: 'Name',
      placeholderAddress: 'Address',
      placeholderCity: 'City',
      placeholderWebsite: 'Website',
      placeholderPlayStoreUrl: 'PlayStore URL',
      placeholderAppStoreUrl: 'AppStore URL',
      placeholderCompanyLogo: 'Company Logo',
      placeholderCompanyBanner: 'Company Banner',
      placeholderShortName: 'Short Name',
      placeholderTagLine: 'Tag Line',
      placeholderDescription: 'Description',
      placeholderTermsAndConditions: 'Terms and Conditions',
      placeholderStatus: 'Status',
      placeholderCompany: 'Company',

      buttonCaptionCreatePartnerMerchant: 'Create Partner Merchant',
      buttonCaptionEditPartnerMerchant: 'Edit Partner Merchant',
      buttonCaptionDeletePartnerMerchant: 'Delete Partner Merchant',
    },
  },
  id: {
    partnerMenu: {
      title: 'Partner',
    },
    partnerMerchantPage: {
      title: 'Mitra Penjual',

      labelNo: 'No',
      labelName: 'Nama',
      labelShortName: 'Nama Singkat',
      labelTagLine: 'Slogan',
      labelCity: 'Kota',
      labelStatus: 'Status',

      placeholderName: 'Nama',
      placeholderAddress: 'Alamat',
      placeholderCity: 'Kota',
      placeholderWebsite: 'Situs Web',
      placeholderPlayStoreUrl: 'URL PlayStore',
      placeholderAppStoreUrl: 'URL AppStore',
      placeholderCompanyLogo: 'Logo Perusahaan',
      placeholderCompanyBanner: 'Spanduk Perusahaan',
      placeholderShortName: 'Nama Singkat',
      placeholderTagLine: 'Slogan',
      placeholderDescription: 'Deskripsi',
      placeholderTermsAndConditions: 'Syarat dan Ketentuan',
      placeholderStatus: 'Status',
      placeholderCompany: 'Perusahaan',

      buttonCaptionCreatePartnerMerchant: 'Buat Mitra Penjual',
      buttonCaptionEditPartnerMerchant: 'Ubah Mitra Penjual',
      buttonCaptionDeletePartnerMerchant: 'Hapus Mitra Penjual',
    },
  },
});

export default LocalizedString;
