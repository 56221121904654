import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  makeStyles, Grid,
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import {
  COLOR_PRIMARY,
  COLOR_BACKGROUND,
  PAGE_MODE_VIEW,
  COLOR_DANGEROUS,
  COLOR_TEXT_ON_PRIMARY,
  PICKER_MODE_DATE,
  SET_BY_OPTIONS,
  SET_AS_OPTIONS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DAYS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DATE,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DESCRIPTION,
  SET_BY_WEEKDAY,
  SET_BY_INDIVIDUAL_DATE,
  RXFORM_CALENDAR_SET_DATES_DIALOG,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField,
  renderReduxFormCheckboxOption,
  renderReduxFormDateTimePickerField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import AccentButton from '../../../../component/accent-button';
import { FormInitialValueShape } from '../../type';
import { rxformValidateCalendarSetDatesDialog } from '../../validation';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 80,
  },
  title: {
    color: COLOR_PRIMARY,
    fontWeight: 500,
  },
  editDetailheaderField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  primaryButtonContainer: {
    padding: '4px',
    marginRight: '12px',
  },
  primaryButton: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  editDetailtitle: {
    color: COLOR_PRIMARY,
    marginRight: 35,
    fontWeight: 500,
  },
  form: {
    display: 'flex',
    flex: 1,
  },
  buttonText: {
    color: COLOR_DANGEROUS,
    margin: '10px',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowSpaceBetween: {
    justifyContent: 'space-between',
  },
  rowAlignCenter: {
    alignItems: 'center',
  },
  justifyFlexEnd: {
    justifyContent: 'flex-end',
  },
  marginRightSmall: {
    marginRight: '8px',
  },
  calendarLegendBox: {
    width: 20,
    height: 20,
    borderRadius: 4,
  },
  calendarLegendWorkingDay: {
    backgroundColor: 'green',
  },
  calendarLegendHoliday: {
    backgroundColor: 'red',
  },
  calendarLegendUndefined: {
    backgroundColor: 'white',
    border: '1px solid black',
  },
  paper: {
    transform: 'translateZ(0px)',
  },
  floatingContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 35px 12px 35px',
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 5,
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.16)',
    height: 86,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    zIndex: 1,
    '&:hover': {
      backgroundColor: COLOR_BACKGROUND,
    },
  },
  activityIndicator: {
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const renderSetDatesDialogContent = (
  initialValues,
  addingEditing,
  downloadingDeleting,
  classes,
  pageMode,
  months,
  days,
  selectedSetBy,
  selectedSetAs,
  selectedMonth,
  selectedDays,
  selectedDate,
  setDatesDescription,
  dateInitialFocusedDate,
  onSetByOptionSelected,
  onSetAsOptionSelected,
  onMonthOptionSelected,
  onDayOptionSelected,
  onDateChanged,
  onSetDatesDescriptionTextChanged,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.calendarPage.labelSetBy}
            label={LocalizedString.calendarPage.labelSetBy}
            value={selectedSetBy}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={SET_BY_OPTIONS}
            onOptionSelected={onSetByOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.calendarPage.labelSetAs}
            label={LocalizedString.calendarPage.labelSetAs}
            value={selectedSetAs}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={SET_AS_OPTIONS}
            onOptionSelected={onSetAsOptionSelected}
            required
          />
        </Grid>
        {selectedSetBy?.value === SET_BY_WEEKDAY && (
          <Grid item>
            <Field
              name={RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.calendarPage.labelMonths}
              label={LocalizedString.calendarPage.labelMonths}
              value={selectedMonth}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={months}
              onOptionSelected={onMonthOptionSelected}
              required={selectedSetBy?.value === SET_BY_WEEKDAY}
            />
          </Grid>
        )}
        {selectedSetBy?.value === SET_BY_WEEKDAY && (
          <Grid item>
            <Field
              name={RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DAYS}
              component={renderReduxFormCheckboxOption}
              data={days}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              horizontal
              onValueSelected={(data) => onDayOptionSelected(data)}
              required={selectedSetBy?.value === SET_BY_WEEKDAY}
            />
          </Grid>
        )}
        {selectedSetBy?.value === SET_BY_INDIVIDUAL_DATE && (
          <Grid item>
            <Field
              name={RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DATE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.calendarPage.labelDate}
              placeholder={LocalizedString.calendarPage.labelDate}
              value={selectedDate}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              pickerMode={PICKER_MODE_DATE}
              onChangeDate={onDateChanged}
              required={selectedSetBy?.value === SET_BY_INDIVIDUAL_DATE}
              initialFocusedDate={dateInitialFocusedDate}
            />
          </Grid>
        )}
        {selectedSetBy?.value === SET_BY_INDIVIDUAL_DATE && (
          <Grid item>
            <Field
              name={RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.calendarPage.labelDescription}
              label={LocalizedString.calendarPage.labelDescription}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              value={setDatesDescription}
              onChangeText={(e) => onSetDatesDescriptionTextChanged(e.target.value)}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  </div>
);

const SetDatesDialog = ({
  initialValues, addingEditing, downloadingDeleting, pageMode,
  months, days, daysPayload, selectedYear, selectedSetBy, selectedSetAs, selectedMonth,
  selectedDays, selectedDate, setDatesDescription, dateInitialFocusedDate, addingCalendarDays,
  onSetByOptionSelected, onSetAsOptionSelected, onMonthOptionSelected, onDayOptionSelected,
  onDateChanged, onSetDatesDescriptionTextChanged, handleSubmit, onOkButtonPressed,
  onSetDatesDialogCancelPressed,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open
      maxWidth="lg"
      classes={{ paper: classes.paper }}
    >
      <div className={classes.headerContainer}>
        <DialogTitle>{LocalizedString.calendarPage.labelSetDates}</DialogTitle>
      </div>
      <DialogContent>
        <div>
          {renderSetDatesDialogContent(
            initialValues,
            addingEditing,
            downloadingDeleting,
            classes,
            pageMode,
            months,
            days,
            selectedSetBy,
            selectedSetAs,
            selectedMonth,
            selectedDays,
            selectedDate,
            setDatesDescription,
            dateInitialFocusedDate,
            onSetByOptionSelected,
            onSetAsOptionSelected,
            onMonthOptionSelected,
            onDayOptionSelected,
            onDateChanged,
            onSetDatesDescriptionTextChanged,
          )}
          <DialogActions className={classes.buttonContainer}>
            <AccentButton
              onClick={onSetDatesDialogCancelPressed}
              variant="text"
              caption={GlobalLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              disabled={addingEditing || downloadingDeleting || addingCalendarDays}
            />
            <AccentButton
              variant="contained"
              className={classes.button}
              disableElevation
              caption={GlobalLocalizedString.common.buttonCaptionOK}
              disabled={addingEditing || downloadingDeleting}
              loading={addingCalendarDays}
              onClick={handleSubmit(() => onOkButtonPressed(
                selectedSetBy,
                selectedSetAs,
                selectedMonth,
                selectedDays,
                selectedDate,
                setDatesDescription,
                selectedYear,
                daysPayload,
              ))}
            />
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default reduxForm({
  form: RXFORM_CALENDAR_SET_DATES_DIALOG,
  validate: rxformValidateCalendarSetDatesDialog,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SetDatesDialog);

SetDatesDialog.propTypes = {
  initialValues: FormInitialValueShape.isRequired,

  months: PropTypes.arrayOf(PropTypes.string).isRequired,
  days: PropTypes.arrayOf(PropTypes.string).isRequired,
  daysPayload: PropTypes.arrayOf(PropTypes.object).isRequired,

  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  addingCalendarDays: PropTypes.bool.isRequired,

  onSetByOptionSelected: PropTypes.func.isRequired,
  onSetAsOptionSelected: PropTypes.func.isRequired,
  onMonthOptionSelected: PropTypes.func.isRequired,
  onDateChanged: PropTypes.func.isRequired,
  onDayOptionSelected: PropTypes.func.isRequired,
  onSetDatesDialogCancelPressed: PropTypes.func.isRequired,
  onSetDatesDescriptionTextChanged: PropTypes.func.isRequired,
  onOkButtonPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  pageMode: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired,
  selectedSetBy: PropTypes.string.isRequired,
  selectedSetAs: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  selectedDays: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setDatesDescription: PropTypes.string.isRequired,
  dateInitialFocusedDate: PropTypes.string.isRequired,
};
