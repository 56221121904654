import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  RXFIELD_FAQ_CATEGORY_NAME, RXFIELD_FAQ_CATEGORY_DESCRIPTION,
  RXFORM_FAQ_CATEGORY, RXSTATE_FAQ_CATEGORY, RXSTATE_FAQ_CATEGORY_PAGE,
} from '../../constant';
import { PAGE_MODE_VIEW } from '../../../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateFaqCategory } from '../../validation';

const renderDialogContent = (addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_FAQ_CATEGORY_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.faqCategoryPage.placeholderName}
          label={LocalizedString.faqCategoryPage.placeholderName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_FAQ_CATEGORY_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.faqCategoryPage.placeholderFaqCategoryDescription}
          label={LocalizedString.faqCategoryPage.placeholderFaqCategoryDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>
);

const FaqCategoryPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_FAQ_CATEGORY}
    uiPage={RXSTATE_FAQ_CATEGORY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.faqCategoryPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.faqCategoryPage.labelFaqCategoryName, field: 'name', sorting: !downloading },
      { title: LocalizedString.faqCategoryPage.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.faqCategoryPage.buttonCaptionCreateNewFaqCategory}
    deleteButtonCaption={LocalizedString.faqCategoryPage.buttonCaptionDeleteFaqCategory}
    title={LocalizedString.faqCategoryPage.title}
    editButtonCaption={LocalizedString.faqCategoryPage.buttonCaptionEditFaqCategory}
    useFullWidth
  >
    {renderDialogContent(addingEditing, pageMode)}
  </FunctionalPage>
);


export default reduxForm({
  form: RXFORM_FAQ_CATEGORY,
  validate: rxformValidateFaqCategory,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FaqCategoryPage);

FaqCategoryPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
};
