import { downloadReportedComment } from '../../../helper';
import { addReportedComment, downloadingReportedComments } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingReportedComments(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiReportedComment;

    const result = await downloadReportedComment(tappedId, token);
    dispatch(addReportedComment(result));
  } finally {
    dispatch(downloadingReportedComments(false));
  }
};
