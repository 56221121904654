import { downloadVoucherPurchaseRequest } from '../../../helper';
import {
  addVoucherPurchaseRequest, downloadingVoucherPurchaseRequest,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingVoucherPurchaseRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoucherPurchaseRequest;

    const purchaseRequest = await downloadVoucherPurchaseRequest(tappedId, token);

    dispatch(addVoucherPurchaseRequest(purchaseRequest));
  } finally {
    dispatch(downloadingVoucherPurchaseRequest(false));
  }
};
