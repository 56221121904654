import { downloadingFAQs, setFAQs } from '../simple-action';
import { downloadFAQs, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, faqs, uiFAQ,
  } = getState();
  const { token } = authentication;
  const { meta } = faqs;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiFAQ;

  try {
    dispatch(downloadingFAQs(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadFAQs(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setFAQs(list));
    }
  } finally {
    dispatch(downloadingFAQs(false));
  }
};
