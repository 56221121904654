import { ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING } from '../../constant';
import LocalizedString from './localization';

export * from '../../constant';

export const REST_URL_VOUCHER_CATEGORIES = '/voucher/api/v1/VoucherCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_VOUCHER_CATEGORY = '/voucher/api/v1/VoucherCategory';
export const REST_URL_VIEW_DELETE_VOUCHER_CATEGORY = '/voucher/api/v1/VoucherCategory/{id}';
export const REST_URL_VOUCHERS = '/voucher/api/v1/Voucher?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_VOUCHER = '/voucher/api/v1/Voucher';
export const REST_URL_VIEW_DELETE_VOUCHER = '/voucher/api/v1/Voucher/{id}';
export const REST_URL_ENABLE_DISABLE_VOUCHER = '/voucher/api/v1/Voucher/Status';
export const REST_URL_TOKEN_REQUESTS = '/voucher/api/v1/VoucherTokenRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_TOKEN_REQUEST = '/voucher/api/v1/VoucherTokenRequest/{id}';
export const REST_URL_ADD_TOKEN_REQUEST = '/voucher/api/v1/VoucherTokenRequest';
export const REST_URL_VOUCHER_TOKENS = '/voucher/api/v1/VoucherToken?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_VOUCHER_TOKEN = '/voucher/api/v1/VoucherToken/{id}';
export const REST_URL_CANCEL_TOKEN_REQUEST = '/voucher/api/v1/VoucherTokenRequestCancel/{id}';
export const REST_URL_TOKEN_IMPORTS = '/voucher/api/v1/VoucherTokenImport?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_TOKEN_IMPORT = '/voucher/api/v1/VoucherTokenImport/{id}';
export const REST_URL_IMPORT_EDIT_TOKEN_IMPORT = '/voucher/api/v1/VoucherTokenImport';
export const REST_URL_RETRY_IMPORT_TOKEN = '/voucher/api/v1/VoucherTokenImport/Retry/{id}';
export const REST_URL_VOUCHER_PURCHASE_REQUESTS = '/voucher/api/v1/PurchaseRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_VOUCHER_PURCHASE_REQUEST = '/voucher/api/v1/PurchaseRequest/{id}';
export const REST_URL_PROMOTED_MERCHANTS = '/voucher/api/v1/Merchant?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_PROMOTED_MERCHANT = '/voucher/api/v1/Merchant/{id}';
export const REST_URL_EDIT_PROMOTED_MERCHANT = '/voucher/api/v1/Merchant';

export const ROUTE_NAME_VOUCHER = '/voucher';
export const ROUTE_NAME_VOUCHER_CATEGORY = '/voucher-category';
export const ROUTE_NAME_VOUCHER_VOUCHER = '/voucher-voucher';
export const ROUTE_NAME_VOUCHER_TOKEN_REQUEST = '/voucher-token-request';
export const ROUTE_NAME_VOUCHER_TOKEN = '/voucher-token';
export const ROUTE_NAME_VOUCHER_TOKEN_IMPORT = '/voucher-token-import';
export const ROUTE_NAME_VOUCHER_PURCHASE_REQUEST = '/voucher-purchase-request';
export const ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT = '/voucher-promoted-merchant';

export const RXFORM_VOUCHER_CATEGORY = 'voucherCategory';
export const RXFORM_VOUCHER = 'voucher';
export const RXFORM_TOKEN_REQUEST = 'tokenRequest';
export const RXFORM_VOUCHER_TOKEN = 'voucherToken';
export const RXFORM_TOKEN_IMPORT = 'tokenImport';
export const RXFORM_VOUCHER_PURCHASE_REQUEST = 'voucherPurchaseRequest';
export const RXFORM_PROMOTED_MERCHANT = 'promotedMerchant';

export const RXFIELD_VOUCHER_CATEGORY_NAME = 'name';
export const RXFIELD_VOUCHER_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_VOUCHER_CATEGORY_IMAGE = 'image';
export const RXFIELD_VOUCHER_TITLE = 'title';
export const RXFIELD_VOUCHER_CODE = 'code';
export const RXFIELD_VOUCHER_TYPE = 'type';
export const RXFIELD_VOUCHER_POINT = 'point';
export const RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER = 'maxPurchasePerUser';
export const RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE = 'minAchievementPoinToPurchase';
export const RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE = 'maxAchievementPoinToPurchase';
export const RXFIELD_VOUCHER_ACTION_TYPE = 'actionType';
export const RXFIELD_VOUCHER_VISIBILITY = 'visibility';
export const RXFIELD_VOUCHER_MERCHANT = 'merchant';
export const RXFIELD_VOUCHER_APPLICABLE_AREA_MODE = 'applicableAreaMode';
export const RXFIELD_VOUCHER_APPLICABLE_BRANCHES = 'applicableBranches';
export const RXFIELD_VOUCHER_CATEGORY = 'category';
export const RXFIELD_VOUCHER_VALID_FROM = 'validFrom';
export const RXFIELD_VOUCHER_VALID_UNTIL = 'validUntil';
export const RXFIELD_VOUCHER_IMAGE = 'image';
export const RXFIELD_VOUCHER_DESCRIPTION = 'description';
export const RXFIELD_VOUCHER_TERMS_AND_CONDITIONS = 'termsAndConditions';
export const RXFIELD_VOUCHER_STATUS = 'status';
export const RXFIELD_VOUCHER_DEEP_LINK = 'deepLink';
export const RXFIELD_VOUCHER_IS_PROMOTED = 'isPromoted';
export const RXFIELD_VOUCHER_ORDER = 'order';
export const RXFIELD_VOUCHER_TAGS = 'tags';
export const RXFIELD_TOKEN_REQUEST_VOUCHER = 'voucher';
export const RXFIELD_TOKEN_REQUEST_QUANTITY = 'quantity';
export const RXFIELD_TOKEN_REQUEST_VALID_FROM = 'validFrom';
export const RXFIELD_TOKEN_REQUEST_VALID_UNTIL = 'validUntil';
export const RXFIELD_TOKEN_REQUEST_TASK_STATUS = 'taskStatus';
export const RXFIELD_TOKEN_REQUEST_PROGRESS = 'progress';
export const RXFIELD_TOKEN_REQUEST_PROGRESS_NOTES = 'progressNotes';
export const RXFIELD_TOKEN_REQUEST_START_TIME = 'startTime';
export const RXFIELD_TOKEN_REQUEST_END_TIME = 'endTime';
export const RXFIELD_VOUCHER_TOKEN_CODE = 'code';
export const RXFIELD_VOUCHER_TOKEN_VALID_FROM = 'validFrom';
export const RXFIELD_VOUCHER_TOKEN_VALID_UNTIL = 'validUntil';
export const RXFIELD_VOUCHER_TOKEN_IS_PURCHASED = 'isPurchased';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_ON = 'purchasedOn';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_BY = 'purchasedBy';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_BY_ID = 'purchasedById';
export const RXFIELD_VOUCHER_TOKEN_IS_REVOKED = 'isRevoked';
export const RXFIELD_VOUCHER_TOKEN_REVOKED_ON = 'revokedOn';
export const RXFIELD_VOUCHER_TOKEN_REVOKED_BY = 'revokedBy';
export const RXFIELD_VOUCHER_TOKEN_REVOKED_BY_ID = 'revokedById';
export const RXFIELD_VOUCHER_TOKEN_REVOCATION_NOTES = 'revocationNotes';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER = 'selectedVoucher';
export const RXFIELD_VOUCHER_TOKEN_MERCHANT = 'merchant';
export const RXFIELD_VOUCHER_TOKEN_IS_CLAIMED = 'isClaimed';
export const RXFIELD_VOUCHER_TOKEN_CLAIMED_ON = 'claimedOn';
export const RXFIELD_VOUCHER_TOKEN_CLAIMED_BY = 'claimedBy';
export const RXFIELD_VOUCHER_TOKEN_CLAIMED_BY_ID = 'claimedById';
export const RXFIELD_VOUCHER_TOKEN_CLAIM_NOTES = 'claimNotes';
export const RXFIELD_VOUCHER_TOKEN_CREATED_DATE = 'createdDate';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER_ID = 'voucherId';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER_IMAGE = 'voucherImage';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER_NAME = 'voucherName';
export const RXFIELD_VOUCHER_TOKEN_MERCHANT_NAME = 'merchantName';
export const RXFIELD_TOKEN_IMPORT_CODE = 'code';
export const RXFIELD_TOKEN_IMPORT_VOUCHER_ID = 'voucherId';
export const RXFIELD_TOKEN_IMPORT_VOUCHER_NAME = 'voucherName';
export const RXFIELD_TOKEN_IMPORT_MERCHANT_NAME = 'merchantName';
export const RXFIELD_TOKEN_IMPORT_VALID_FROM = 'validFrom';
export const RXFIELD_TOKEN_IMPORT_VALID_UNTIL = 'validUntil';
export const RXFIELD_TOKEN_IMPORT_IMPORT_STATUS = 'importStatus';
export const RXFIELD_TOKEN_IMPORT_STATUS_NOTES = 'importStatusNotes';
export const RXFIELD_TOKEN_IMPORT_VOUCHER = 'voucher';
export const RXFIELD_TOKEN_IMPORT_MERCHANT = 'merchant';
export const RXFIELD_TOKEN_IMPORT_CSV_FILE = 'csvFile';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_USER = 'user';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_ID = 'voucherId';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_TOKEN_ID = 'voucherTokenId';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_NAME = 'voucherName';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_IMAGE = 'voucherImage';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_PAYMENT_ID = 'paymentId';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_CREATED_DATE = 'createdDate';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER = 'voucher';
export const RXFIELD_PROMOTED_MERCHANT_NAME = 'name';
export const RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED = 'isPromoted';
export const RXFIELD_PROMOTED_MERCHANT_ADDRESS = 'address';
export const RXFIELD_PROMOTED_MERCHANT_ORDER = 'order';
export const RXFIELD_PROMOTED_MERCHANT_CITY = 'city';
export const RXFIELD_PROMOTED_MERCHANT_COMPANY_LOGO = 'logo';
export const RXFIELD_PROMOTED_MERCHANT_COMPANY_BANNER = 'banner';
export const RXFIELD_PROMOTED_MERCHANT_WEBSITE = 'website';
export const RXFIELD_PROMOTED_MERCHANT_STATUS = 'status';
export const RXFIELD_PROMOTED_MERCHANT_PLAY_STORE_URL = 'playStoreUrl';
export const RXFIELD_PROMOTED_MERCHANT_APP_STORE_URL = 'appStoreUrl';

export const RXSTATE_VOUCHER_CATEGORIES = 'voucherCategories';
export const RXSTATE_VOUCHER_CATEGORY_PAGE = 'uiVoucherCategory';
export const RXSTATE_VOUCHERS = 'vouchers';
export const RXSTATE_VOUCHER_PAGE = 'uiVoucher';
export const RXSTATE_TOKEN_REQUESTS = 'tokenRequests';
export const RXSTATE_TOKEN_REQUEST_PAGE = 'uiTokenRequest';
export const RXSTATE_VOUCHER_TOKENS = 'voucherTokens';
export const RXSTATE_VOUCHER_TOKEN_PAGE = 'uiVoucherToken';
export const RXSTATE_TOKEN_IMPORTS = 'tokenImports';
export const RXSTATE_TOKEN_IMPORT_PAGE = 'uiTokenImport';
export const RXSTATE_VOUCHER_PURCHASE_REQUESTS = 'voucherPurchaseRequests';
export const RXSTATE_VOUCHER_PURCHASE_REQUEST_PAGE = 'uiVoucherPurchaseRequest';
export const RXSTATE_PROMOTED_MERCHANTS = 'promotedMerchants';
export const RXSTATE_PROMOTED_MERCHANT_PAGE = 'uiPromotedMerchant';

export const INITIAL_ORDER_BY_VOUCHER_CATEGORIES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_VOUCHERS = `title ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_TOKEN_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VOUCHER_TOKENS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_TOKEN_IMPORTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VOUCHER_PURCHASE_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_PROMOTED_MERCHANTS = `name ${ORDER_SORT_ASCENDING}`;

export const MENUID_VOUCHER = 'MENUID_VOUCHER';
export const MENUID_VOUCHER_CATEGORY = 'MENUID_VOUCHER_CATEGORY';
export const MENUID_VOUCHER_VOUCHER = 'MENUID_VOUCHER_VOUCHER';
export const MENUID_VOUCHER_TOKEN_REQUEST = 'MENUID_VOUCHER_TOKEN_REQUEST';
export const MENUID_VOUCHER_TOKEN = 'MENUID_VOUCHER_TOKEN';
export const MENUID_VOUCHER_TOKEN_IMPORT = 'MENUID_VOUCHER_TOKEN_IMPORT';
export const MENUID_VOUCHER_PURCHASE_REQUEST = 'MENUID_VOUCHER_PURCHASE_REQUEST';
export const MENUID_VOUCHER_PROMOTED_MERCHANT = 'MENUID_VOUCHER_PROMOTED_MERCHANT';

export const VOUCHER_TYPE_PUBLIC = 'Public';
export const VOUCHER_TYPE_PRIVATE = 'Private';
export const APPLICABLE_AREA_MODE_ALL_BRANCHES = 'AllBranches';
export const APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES = 'SpecificBranches';

export const VOUCHER_ACTION_TYPES = ['Regular', 'Url'];
export const VOUCHER_APPLICABLE_AREA_MODES = [
  { label: LocalizedString.voucherPage.labelAllBranches, value: APPLICABLE_AREA_MODE_ALL_BRANCHES },
  {
    label: LocalizedString.voucherPage.labelSpecificBranches,
    value: APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES,
  },
];
export const VOUCHER_TYPES = [VOUCHER_TYPE_PUBLIC, VOUCHER_TYPE_PRIVATE];

export const TOKEN_REQUEST_TASK_STATUS = [
  'Queuing', 'Processing', 'Cancelling',
  'Cancelled', 'Finished', 'Error',
];

export const TASK_STATUS_QUEUING = 'Queuing';
export const TASK_STATUS_PROCESSING = 'Processing';

export const TOKEN_IMPORT_IMPORT_STATUS = ['Error', 'Success'];
