import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CONFIG_VALUE_DESCRIPTION_MAX_LENGTH } from '../constant';

const TooltipText = ({ caption }) => {
  if (caption.length > CONFIG_VALUE_DESCRIPTION_MAX_LENGTH) {
    const text = caption.replace(/\n/g, ' ');

    return (
      <Tooltip title={(<div style={{ whiteSpace: 'pre-line' }}>{caption}</div>)}>
        <Typography variant="inherit">
          {text.length > CONFIG_VALUE_DESCRIPTION_MAX_LENGTH
            ? `${text.slice(0, CONFIG_VALUE_DESCRIPTION_MAX_LENGTH)}...`
            : text}
        </Typography>
      </Tooltip>
    );
  }
  return (
    <Typography variant="inherit">{caption}</Typography>
  );
};

export default TooltipText;

TooltipText.propTypes = {
  caption: PropTypes.string.isRequired,
};
