import {
  ORDER_SORT_ASCENDING,
  MASTER_DATA_ENTITY_NAME_PROVINCE,
  MASTER_DATA_ENTITY_NAME_CITY,
  MASTER_DATA_ENTITY_NAME_DISTRICT,
  MASTER_DATA_ENTITY_NAME_SUBDISTRICT,
  MASTER_DATA_ENTITY_NAME_COMPANY,
  MASTER_DATA_ENTITY_NAME_BRANCH,
  MASTER_DATA_ENTITY_NAME_PROFILE,
  MASTER_DATA_ENTITY_NAME_ADDRESS,
  CHANNEL_ENTITY_NAME_PUBLIC,
  CHANNEL_ENTITY_NAME_RESYNC,
  MASTER_DATA_ENTITY_NAME_WORKING_SHIFT,
} from '../../constant';
import LocalizedString from './localization';

export * from '../../constant';

export const REST_URL_SCREEN = '/screen/api/v1/Screen?searchString={searchString}&pageNumber={pageNumber}&orderBy=publishedDate desc&filterString=status=enabled|visibilityStatus=visible';

export const REST_URL_CONFIG_GROUPS = '/config/api/v1/ConfigGroup?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_CONFIG_GROUP = '/config/api/v1/ConfigGroup';
export const REST_URL_VIEW_DELETE_CONFIG_GROUP = '/config/api/v1/ConfigGroup/{id}';
export const REST_URL_CONFIG_ITEMS = '/config/api/v1/ConfigItem?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_CONFIG_ITEM = '/config/api/v1/ConfigItem';
export const REST_URL_VIEW_DELETE_CONFIG_ITEM = '/config/api/v1/ConfigItem/{id}';
export const REST_URL_FILTERED_CONFIG_ITEMS = '/config/api/v1/ConfigItem?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled|configGroup.name={name}';
export const REST_URL_MY_CONFIG_ITEMS = '/config/api/v1/MyConfigItem';
export const REST_URL_CONFIG_ITEM_BULK_EDIT = '/config/api/v1/ConfigItemBulkEdit';
export const REST_URL_CONFIG_ACTION_RESYNC_CONFIGS = '/config/api/v1/ResyncConfig';
export const REST_URL_CONFIG_ACTION_RESYNC_DB_USERS = '/authext/api/v1/UserSync';
export const REST_URL_CONFIG_ACTION_RESYNC_MASTER_DATA = '/mdata/api/v1/MasterData/Resync';
export const REST_URL_CONFIG_ACTION_RESYNC_USERS = '/auth/api/v1/ResyncUser';
export const REST_URL_PROFILE_SYNCS = '/authext/api/v1/Profile?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ENABLE_DISABLE_PROFILE_SYNC = '/authext/api/v1/Profile/Status';
export const REST_URL_ADD_EDIT_PROFILE_SYNC = '/authext/api/v1/Profile';
export const REST_URL_VIEW_DELETE_PROFILE_SYNC = '/authext/api/v1/Profile/{id}';
export const REST_URL_UPLOAD_CONFIG_ITEM_VALUE_FILE = '/config/api/v1/Upload';

export const ROUTE_NAME_CONFIGURATION = '/configuration';
export const ROUTE_NAME_CONFIG_GROUP = '/config-group';
export const ROUTE_NAME_CONFIG_ITEM = '/config-item';
export const ROUTE_NAME_CONFIG_VALUE = '/config-value';
export const ROUTE_NAME_CONFIG_ACTION = '/config-action';
export const ROUTE_NAME_PROFILE_SYNC = '/profile-sync';

export const RXFORM_CONFIG_GROUP = 'configGroupPage';
export const RXFORM_CONFIG_ITEM = 'configItemPage';
export const RXFORM_CONFIG_VALUE = 'configValuePage';
export const RXFORM_CONFIG_ACTION = 'configActionPage';
export const RXFORM_PROFILE_SYNC = 'profileSyncPage';

export const RXFIELD_CONFIG_GROUP_ORDER = 'order';
export const RXFIELD_CONFIG_GROUP_NAME = 'name';
export const RXFIELD_CONFIG_GROUP_TAG = 'tag';
export const RXFIELD_CONFIG_GROUP_DESCRIPTION = 'description';
export const RXFIELD_CONFIG_ITEM_NAME = 'name';
export const RXFIELD_CONFIG_ITEM_ORDER = 'order';
export const RXFIELD_CONFIG_ITEM_LABEL = 'label';
export const RXFIELD_CONFIG_ITEM_VALUE = 'value';
export const RXFIELD_CONFIG_ITEM_DESCRIPTION = 'description';
export const RXFIELD_CONFIG_ITEM_ALLOW_OVERRIDE = 'allowOverride';
export const RXFIELD_CONFIG_ITEM_ENCRYPTED = 'encrypted';
export const RXFIELD_CONFIG_ITEM_NEED_RESTART = 'needRestart';
export const RXFIELD_CONFIG_ITEM_DATA_TYPE = 'dataType';
export const RXFIELD_CONFIG_ITEM_ENUM_OPTION = 'enumOptions';
export const RXFIELD_CONFIG_ITEM_USED_ON = 'usedOn';
export const RXFIELD_CONFIG_ITEM_CONFIG_GROUP = 'configGroup';
export const RXFIELD_CONFIG_ITEM_CONFIG_SECTION = 'configSection';
export const RXFIELD_CONFIG_ITEM_MULTILINE = 'multiLine';
export const RXFIELD_CONFIG_ACTION_MASTER_DATA = 'masterData';
export const RXFIELD_PROFILE_SYNC_EMPLOYEE_ID = 'employeeId';
export const RXFIELD_PROFILE_SYNC_FULL_NAME = 'fullName';
export const RXFIELD_PROFILE_SYNC_EMAIL = 'email';
export const RXFIELD_PROFILE_SYNC_MANAGER_ID = 'managerEmployeeId';
export const RXFIELD_PROFILE_SYNC_JOB_TITLE = 'jobTitle';
export const RXFIELD_PROFILE_SYNC_DEPARTMENT = 'department';
export const RXFIELD_PROFILE_SYNC_COMPANY = 'company';
export const RXFIELD_PROFILE_SYNC_STATUS = 'status';
export const RXFIELD_PROFILE_SYNC_BRANCH = 'branch';
export const RXFIELD_PROFILE_SYNC_BRANCH_ID = 'branchId';
export const RXFIELD_PROFILE_SYNC_GENDER = 'gender';
export const RXFIELD_PROFILE_SYNC_DATE_OF_BIRTH = 'dateOfBirth';
export const RXFIELD_PROFILE_SYNC_PHONE = 'phone';
export const RXFIELD_PROFILE_SYNC_DIVISION = 'division';
export const RXFIELD_PROFILE_SYNC_MANAGER_EMPLOYEE_ID = 'managerEmployeeId';
export const RXFIELD_CONFIG_ACTION_CHANNEL = 'channel';
export const RXFIELD_CONFIG_ACTION_RESYNC_USERS_CHANNEL = 'resyncUserChannel';

export const RXSTATE_CONFIG_GROUPS = 'configGroups';
export const RXSTATE_CONFIG_GROUP_PAGE = 'uiConfigGroup';
export const RXSTATE_CONFIG_ITEMS = 'configItems';
export const RXSTATE_CONFIG_ITEM_PAGE = 'uiConfigItem';

export const RXSTATE_PROFILE_SYNCS = 'profileSyncs';
export const RXSTATE_PROFILE_SYNC_PAGE = 'uiProfileSync';

export const INITIAL_ORDER_BY_CONFIG_GROUPS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_CONFIG_ITEMS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_PROFILE_SYNC = `fullName ${ORDER_SORT_ASCENDING}`;

export const dataTypes = ['String', 'Integer', 'Long', 'Float', 'Decimal', 'Boolean', 'DateTime', 'Enum', 'File'];
export const configItemUsedOnTypes = ['Server', 'Client', 'ServerAndClient'];

export const MENUID_CONFIGURATION = 'MENUID_CONFIGURATION';
export const MENUID_CONFIGURATION_CONFIGGROUP = 'MENUID_CONFIGURATION_CONFIGGROUP';
export const MENUID_CONFIGURATION_CONFIGITEM = 'MENUID_CONFIGURATION_CONFIGITEM';
export const MENUID_CONFIGURATION_CONFIG_VALUE = 'MENUID_CONFIGURATION_CONFIG_VALUE';
export const MENUID_CONFIGURATION_CONFIG_ACTION = 'MENUID_CONFIGURATION_CONFIG_ACTION';
export const MENUID_CONFIGURATION_PROFILE_SYNC = 'MENUID_CONFIGURATION_PROFILE_SYNC';

export const maxChar = { number: 25, start: 0, end: 24 };

export const BOOLEAN_DROPDOWN_DATA_LOWERCASE = ['false', 'true'];
export const CONFIG_VALUE_DATA_TYPE_ENUM = 'Enum';
export const CONFIG_VALUE_DATA_TYPE_BOOLEAN = 'Boolean';
export const CONFIG_VALUE_DATA_TYPE_FILE = 'File';

export const CONFIG_VALUE_DESCRIPTION_MAX_LENGTH = 130;

export const CONFIG_ACTION_RESYNC_CONFIGS = 'configs';
export const CONFIG_ACTION_RESYNC_MASTER_DATA = 'masterData';
export const CONFIG_ACTION_RESYNC_USERS = 'users';
export const CONFIG_ACTION_RESYNC_DB_USERS = 'dbUsers';

export const CONFIG_ITEM_NAME_DEEP_LINK_SCHEME = 'deepLinkScheme';
export const CONFIG_ITEM_CONFIG_GROUP_NAME_GLOBAL = 'global';

export const MASTER_DATA_OPTIONS = [
  {
    label: LocalizedString.configActionScreen.labelProvince,
    value: MASTER_DATA_ENTITY_NAME_PROVINCE,
  },
  {
    label: LocalizedString.configActionScreen.labelCity,
    value: MASTER_DATA_ENTITY_NAME_CITY,
  },
  {
    label: LocalizedString.configActionScreen.labelDistrict,
    value: MASTER_DATA_ENTITY_NAME_DISTRICT,
  },
  {
    label: LocalizedString.configActionScreen.labelSubDistrict,
    value: MASTER_DATA_ENTITY_NAME_SUBDISTRICT,
  },
  {
    label: LocalizedString.configActionScreen.labelCompany,
    value: MASTER_DATA_ENTITY_NAME_COMPANY,
  },
  {
    label: LocalizedString.configActionScreen.labelBranch,
    value: MASTER_DATA_ENTITY_NAME_BRANCH,
  },
  {
    label: LocalizedString.configActionScreen.labelWorkingShift,
    value: MASTER_DATA_ENTITY_NAME_WORKING_SHIFT,
  },
  {
    label: LocalizedString.configActionScreen.labelProfile,
    value: MASTER_DATA_ENTITY_NAME_PROFILE,
  },
  {
    label: LocalizedString.configActionScreen.labelAddress,
    value: MASTER_DATA_ENTITY_NAME_ADDRESS,
  },
];

export const CHANNEL_OPTIONS = [
  {
    label: LocalizedString.configActionScreen.labelResync,
    value: CHANNEL_ENTITY_NAME_RESYNC,
  },
  {
    label: LocalizedString.configActionScreen.labelPublic,
    value: CHANNEL_ENTITY_NAME_PUBLIC,
  },
];
