import { downloadingSummaryThisYearTrends, setSummaryThisYearTrends } from '../simple-action';
import { downloadSummaryTrends } from '../../../helper';
import { PACKET_SUMMARY_CURRENT_PERIOD, PACKET_SUMMARY_GROUPING_BY_MONTH } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryThisYearTrends(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryTrends(PACKET_SUMMARY_CURRENT_PERIOD,
      PACKET_SUMMARY_GROUPING_BY_MONTH, token);

    dispatch(setSummaryThisYearTrends(result));
  } finally {
    dispatch(downloadingSummaryThisYearTrends(false));
  }
};
