import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  clearSurveys, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setSurveySearchText, setSurveySelectedPageSize, setSurveySelectedOrderBy,
  setSurveyTappedId, setSurveySelectedVisibilityStatus,
  setSurveyAdvancedFilterDialogSelectedFilterString,
  addEditSurveyAsync, downloadDeleteSurveyAsync, downloadSurveysAsync,
  setSurveySelectedCategoryId, setSurveyCategorySearchText,
  clearSurveyCategories, downloadSurveyCategoriesAsync,
  setFunctionalPageMode, saveSurveyResultAsync, sendSurveyNotificationAsync,
  copyingSurveyDeeplink,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_SURVEYS, MENUID_SURVEY_SURVEY, PAGE_MODE_TABLE,
  RXFORM_SURVEY, RXFIELD_SURVEY_VISIBILITY_STATUS,
  RXFIELD_SURVEY_CATEGORY, DATE_TIME_FORMAT_WITHOUT_PIPE,
  ROUTE_NAME_SURVEY_RESPONSE,
} from '../../constant';
import {
  transformInitialValues, debounceSearch, transformDropdownData,
  localDateToUtc, toMoment, sortAsc,
} from '../../helper';
import LocalizedString from '../../../../localization';
import SurveyPage from './survey.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { surveys, uiFunctionalPage, uiSurvey } = state;
  const { data } = surveys;
  const { downloadingDeleting, tappedId } = uiSurvey;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    category: found.category
      ? { label: found.category.name, value: found.category.id } : { label: '', value: '' },
    visibilityStatus: found.visibilityStatus ?? '',
    validFrom: found.validFrom
      ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    validUntil: found.validUntil
      ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    result: found.result && found.result.length
      ? found.result.sort((a, b) => sortAsc(a.order, b.order)) : [],
  }) : {
    title: '',
    description: '',
    validFrom: null,
    validUntil: null,
    category: { label: '', value: '' },
    visibilityStatus: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiSurvey.addingEditing,
  downloading: state.uiSurvey.downloading,
  downloadingDeleting: state.uiSurvey.downloadingDeleting,
  initialValues: getInitialValues(state),
  categories: transformDropdownData(state.surveyCategories.data),
  loadingCategory: state.uiSurveyCategory.downloading,
  tappedId: state.uiFunctionalPage.tappedId,
  saving: state.uiSurvey.saving,
  sendingNotification: state.uiSurvey.sendingNotification,
  copying: state.uiSurvey.copying,
});

const searchSurveyCategoryDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearSurveyCategories());
    dispatch(downloadSurveyCategoriesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSurveyAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_SURVEY_SURVEY));
    dispatch(setSurveySearchText(''));
    dispatch(clearSurveys());
    dispatch(setSurveySelectedPageSize(20));
    dispatch(setSurveySelectedOrderBy(INITIAL_ORDER_BY_SURVEYS));
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSurveysAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSurveySelectedPageSize(pageSize));
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SURVEY));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteSurveyAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setSurveyTappedId(''));
    dispatch(setSurveyCategorySearchText(''));
    dispatch(clearSurveyCategories());
    dispatch(downloadSurveyCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setSurveyTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSurveyTappedId(id));
    dispatch(downloadDeleteSurveyAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setSurveySelectedPageSize(pageSize));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, description, validFrom, validUntil,
  }) => {
    try {
      await dispatch(addEditSurveyAsync(
        title, description, localDateToUtc(validFrom), localDateToUtc(validUntil),
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSurveySearchText(text));
      dispatch(clearSurveys());
      await dispatch(downloadSurveysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSurveySelectedOrderBy(orderBy));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, description, validFrom, validUntil,
  }) => {
    await dispatch(addEditSurveyAsync(
      title, description, localDateToUtc(validFrom), localDateToUtc(validUntil),
    ));
  },
  onViewPressed: (id) => {
    dispatch(setSurveyTappedId(id));
    dispatch(downloadDeleteSurveyAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveySelectedVisibilityStatus(option));
      dispatch(change(RXFORM_SURVEY, RXFIELD_SURVEY_VISIBILITY_STATUS, option));
    } else {
      dispatch(setSurveySelectedVisibilityStatus(''));
      dispatch(change(RXFORM_SURVEY, RXFIELD_SURVEY_VISIBILITY_STATUS, ''));
    }
  },
  onChangeCategoryText: async (text) => {
    dispatch(setSurveyCategorySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSurveyCategoryDebounce(dispatch, true);
    }
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveySelectedCategoryId(option.value));
      dispatch(change(RXFORM_SURVEY, RXFIELD_SURVEY_CATEGORY, option));
    } else {
      dispatch(setSurveySelectedCategoryId(''));
      dispatch(change(RXFORM_SURVEY, RXFIELD_SURVEY_CATEGORY, ''));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setSurveyCategorySearchText(''));
    dispatch(clearSurveyCategories());
    dispatch(downloadSurveyCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/category/, 'category.id');
    dispatch(setSurveyAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setSurveySelectedCategoryId(''));
    dispatch(setSurveySelectedVisibilityStatus(''));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSurveyAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSurveySelectedCategoryId(''));
    dispatch(setSurveySelectedVisibilityStatus(''));
    dispatch(clearSurveys());
    dispatch(setSurveySelectedOrderBy(INITIAL_ORDER_BY_SURVEYS));
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onShowParticipantPressed: (surveyId, title, history) => {
    const text = `survey.id=${surveyId}`;
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    history.push({
      pathname: ROUTE_NAME_SURVEY_RESPONSE,
      state: {
        filterString: text,
        selectedSurvey: { label: title, value: surveyId },
      },
    });
  },
  onDownloadPressed: () => {
    dispatch(saveSurveyResultAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onSendToMePressed: () => {
    dispatch(sendSurveyNotificationAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCopyPressed: (status) => {
    dispatch(copyingSurveyDeeplink(!status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);
