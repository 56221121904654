
import moment from 'moment';
import LocalizedStrings from '../../localization';
import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_LOCATIONS_NAME, RXFIELD_EVENT_NAME, RXFIELD_EVENT_START_TIME,
  RXFIELD_EVENT_END_TIME, RXFIELD_EVENT_TIME_ZONE, RXFIELD_EVENT_LOCATION,
  RXFIELD_EVENT_PARTICIPANTS, RXFIELD_EVENT_DESCRIPTION,
} from './constant';

export const rxformValidateLocations = (values) => {
  const requiredFields = [
    RXFIELD_LOCATIONS_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateEvent = (values) => {
  const requiredFields = [
    RXFIELD_EVENT_NAME,
    RXFIELD_EVENT_START_TIME,
    RXFIELD_EVENT_END_TIME,
    RXFIELD_EVENT_TIME_ZONE,
    RXFIELD_EVENT_LOCATION,
    RXFIELD_EVENT_PARTICIPANTS,
    RXFIELD_EVENT_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (moment(values.endTime).isBefore(values.startTime)) {
    errors.endTime = LocalizedStrings.common.errMsgStartEndDate;
  }

  return errors;
};
