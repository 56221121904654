import { connect } from 'react-redux';
import { change, reset, formValueSelector } from 'redux-form';
import {
  clearChallenges, clearGameQRCategories, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setChallengeSearchText, setChallengeSelectedPageSize,
  setChallengeSelectedOrderBy, setChallengeTappedId, setChallengeSelectedAddMediaMenu,
  setChallengeSelectedCategory, setChallengeSelectedScanPolicy,
  setChallengeSelectedVisibilityStatus, setGameQRCategorySearchText,
  addEditChallengeAsync, downloadChallengesAsync, downloadDeleteChallengeAsync,
  downloadGameQRCategoriesAsync, setChallengeSelectedIsPromoted,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_CHALLENGES, MENUID_GAMEQR_CHALLENGE,
  PAGE_MODE_TABLE, RXFIELD_CHALLENGE_CODES, RXFIELD_CHALLENGE_CONTENT, RXFIELD_CHALLENGE_MEDIA,
  RXFIELD_CHALLENGE_SCAN_POLICY, RXFIELD_CHALLENGE_VISIBILITY_STATUS, RXFORM_CHALLENGE,
  RXFIELD_CHALLENGE_IS_PROMOTED, approval,
} from '../../constant';
import {
  localDateToUtc, sortAsc, transformDropdownData, transformInitialValues, toMoment,
} from '../../helper';
import LocalizedString from '../../../../localization';
import ChallengePage from './challenge.presentation';

const getInitialValues = (state) => {
  const { challenges, uiChallenge, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiChallenge;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? challenges.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      category: found.category ? { label: found.category.name, value: found.category.id } : { label: '', value: '' },
      validUntil: found.validFrom
        ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      validFrom: found.validUntil
        ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      codes: found.codes.length > 0 ? found.codes.sort((a, b) => sortAsc(a.order, b.order))
        : [],
      isPromoted: found.isPromoted ? approval[0] : approval[1],
      order: found.order || 0,
    }) : {
      title: '',
      content: '',
      validFrom: null,
      validUntil: null,
      category: { label: '', value: '' },
      visibilityStatus: '',
      scanPolicy: '',
      reactionId: '',
      tags: '',
      isPromoted: { label: '', value: '' },
      order: 0,
    };
  return initVal;
};

const selector = formValueSelector(RXFORM_CHALLENGE);

const mapStateToProps = (state) => ({
  categories: transformDropdownData(state.gameQRCategories.data),
  addingEditing: state.uiChallenge.addingEditing,
  downloading: state.uiChallenge.downloading,
  downloadingDeleting: state.uiChallenge.downloadingDeleting,
  loadingCategory: state.uiGameQRCategory.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  selectedAddMediaMenu: state.uiChallenge.selectedAddMediaMenu,
  initialValues: getInitialValues(state),
  isPromoted: !!selector(state, RXFIELD_CHALLENGE_IS_PROMOTED)?.value || false,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCodePressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_CODES, data));
  },
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setChallengeSelectedAddMediaMenu(menu));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GAMEQR_CHALLENGE));
    dispatch(setChallengeSearchText(''));
    dispatch(clearChallenges());
    dispatch(setChallengeSelectedPageSize(20));
    dispatch(setChallengeSelectedOrderBy(INITIAL_ORDER_BY_CHALLENGES));
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setChallengeSelectedCategory(''));
    dispatch(setChallengeSelectedScanPolicy(''));
    dispatch(setChallengeSelectedVisibilityStatus(''));
    dispatch(reset(RXFORM_CHALLENGE));
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedCategory(option.value));
    } else {
      dispatch(setChallengeSelectedCategory(''));
    }
  },
  onChangeCategoryText: async (text) => {
    try {
      dispatch(setGameQRCategorySearchText(text));
      dispatch(clearGameQRCategories());
      await dispatch(downloadGameQRCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeContentText: (text) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_CONTENT, text));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadChallengesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setChallengeSelectedPageSize(pageSize));
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteChallengeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setChallengeTappedId(''));
    dispatch(setChallengeSelectedCategory(''));
    dispatch(setChallengeSelectedScanPolicy(''));
    dispatch(setChallengeSelectedVisibilityStatus(''));
    dispatch(setGameQRCategorySearchText(''));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeleteCodePressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_CODES, data));
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setChallengeTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setGameQRCategorySearchText(''));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setChallengeTappedId(id));
    dispatch(downloadDeleteChallengeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setChallengeSelectedPageSize(pageSize));
    dispatch(clearChallenges());
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, content, validFrom, validUntil, tags, media, codes, order,
  }) => {
    try {
      await dispatch(addEditChallengeAsync(title, content, localDateToUtc(validFrom),
        localDateToUtc(validUntil), tags, media, codes, order));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onScanPolicyOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedScanPolicy(option.value));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_POLICY, option));
    } else {
      dispatch(setChallengeSelectedScanPolicy(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_SCAN_POLICY, ''));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setChallengeSearchText(text));
      dispatch(clearChallenges());
      await dispatch(downloadChallengesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setChallengeSelectedOrderBy(orderBy));
    dispatch(clearChallenges());
    dispatch(downloadChallengesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, content, validFrom, validUntil, tags, media, codes, order,
  }) => {
    await dispatch(addEditChallengeAsync(title, content, localDateToUtc(validFrom),
      localDateToUtc(validUntil), tags, media, codes, order));
  },
  onViewPressed: (id) => {
    dispatch(setChallengeTappedId(id));
    dispatch(downloadDeleteChallengeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_VISIBILITY_STATUS, option));
    } else {
      dispatch(setChallengeSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_VISIBILITY_STATUS, ''));
    }
  },
  onIsPromotedSelected: (option) => {
    if (option) {
      dispatch(setChallengeSelectedIsPromoted(option));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_IS_PROMOTED, option));
    } else {
      dispatch(setChallengeSelectedIsPromoted(''));
      dispatch(change(RXFORM_CHALLENGE, RXFIELD_CHALLENGE_IS_PROMOTED, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePage);
