import {
  DOWNLOADING_WFA_QUOTAS,
  ADDING_EDITING_WFA_QUOTA,
  DOWNLOADING_DELETING_WFA_QUOTA,
  SET_WFA_QUOTA_SELECTED_PAGE_SIZE,
  SET_WFA_QUOTA_TAPPED_ID,
  SET_WFA_QUOTA_SEARCH_TEXT,
  SET_WFA_QUOTA_SELECTED_ORDER_BY,
  SET_WFA_QUOTA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_WFA_QUOTA_SELECTED_DEPARTMENT,
} from '../action';
import { INITIAL_ORDER_BY_WFA_QUOTAS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_WFA_QUOTAS,
  selectedDepartment: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WFA_QUOTAS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_WFA_QUOTA:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_WFA_QUOTA:
      return { ...state, downloadingDeleting: action.status };
    case SET_WFA_QUOTA_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WFA_QUOTA_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WFA_QUOTA_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WFA_QUOTA_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WFA_QUOTA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_WFA_QUOTA_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartment: action.value };
    default:
      return state;
  }
};
