import * as Action from '../action';

const initialAuthentication = { token: '' };
export default (state = initialAuthentication, action) => {
  switch (action.type) {
    case Action.ADD_TOKEN:
      return { token: action.token };
    case Action.REMOVE_TOKEN:
      return initialAuthentication;
    default: return state;
  }
};
