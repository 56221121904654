import {
  downloadingAttendanceSummaryClockOutStatusTrend, setAttendanceSummaryClockOutStatusTrend,
} from '../simple-action';
import { downloadAttendanceSummaryClockOutStatusTrend } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryClockOutStatusTrend(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryClockOutStatusTrend(token);

    dispatch(setAttendanceSummaryClockOutStatusTrend(data));
  } finally {
    dispatch(downloadingAttendanceSummaryClockOutStatusTrend(false));
  }
};
