import {
  SET_PDF_TEMPLATE, SET_TEMPLATE_TOTAL_PAGES,
  UPLOADING_PDF_TEMPLATE,
} from '../action';
import {
  PREVIEWING_PDF_TEMPLATE, SET_PDF_URL,
  SET_PDF_DATA,
  SET_UPLOADING_PDF_VISIBILITY, SUBMITTING_ANNOTATION,
  SET_PDF_FILE_NAME,
} from '../action/simple-action';

const initialState = {
  visibility: false,
  uploadingPdfTemplate: false,
  submittingAnnotation: false,
  previewingPdfTemplate: false,
  templatePdf: '',
  pdfUrl: '',
  fileName: '',
  pdfData: {},
  totalPages: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UPLOADING_PDF_VISIBILITY:
      return { ...state, visibility: action.status };
    case UPLOADING_PDF_TEMPLATE:
      return { ...state, uploadingPdfTemplate: action.status };
    case SUBMITTING_ANNOTATION:
      return { ...state, submittingAnnotation: action.status };
    case PREVIEWING_PDF_TEMPLATE:
      return { ...state, previewingPdfTemplate: action.status };
    case SET_PDF_TEMPLATE:
      return { ...state, templatePdf: action.data };
    case SET_PDF_URL:
      return { ...state, pdfUrl: action.url };
    case SET_PDF_DATA:
      return { ...state, pdfData: action.data };
    case SET_PDF_FILE_NAME:
      return { ...state, fileName: action.data };
    case SET_TEMPLATE_TOTAL_PAGES:
      return { ...state, totalPages: action.totalPages };
    default: return state;
  }
};
