import { reset } from 'redux-form';
import { toNumber } from 'lodash';
import {
  addingEditingWFAQuota,
  clearWFAQuotas,
  setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import {
  addWFAQuota, editWFAQuota,
} from '../../../helper';
import {
  PAGE_MODE_TABLE, RXFORM_WFA_QUOTA,
} from '../../../constant';
import downloadWFAQuotasAsync from './downloadWFAQuotasAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingWFAQuota(true));

    const { token } = getState().authentication;
    const { tappedId, selectedDepartment } = getState().uiWFAQuota;
    const {
      quotaPerMonth,
    } = values;

    if (tappedId) {
      await editWFAQuota(
        tappedId, selectedDepartment, toNumber(quotaPerMonth), token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addWFAQuota(
        selectedDepartment, toNumber(quotaPerMonth), token,
      );
    }

    dispatch(reset(RXFORM_WFA_QUOTA));
    dispatch(clearWFAQuotas());
    dispatch(downloadWFAQuotasAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingWFAQuota(false));
  }
};
