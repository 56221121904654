import { downloadingRoles, setRoles } from '../simple-action';
import { downloadRoles, transformSearchText } from '../../../helper';

export default (selectedPageNo, isAll) => async (dispatch, getState) => {
  const { authentication, roles, uiRole } = getState();
  const { token } = authentication;
  const { meta } = roles;
  const { selectedPageSize, searchBarText, orderBy } = uiRole;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingRoles(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadRoles(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      token,
    );

    if (isAll) {
      const result = { ...list };

      for (let i = list.meta.currentPage; i <= list.meta.totalPages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const nextList = await downloadRoles(
          i,
          50,
          orderBy,
          searchText,
          token,
        );

        const finalList = {
          data: { ...result.data, ...nextList.data },
          meta: nextList.meta,
        };

        Object.assign(result, finalList);
        dispatch(setRoles(result));
      }
    } else {
      dispatch(setRoles(list));
    }
  } finally {
    dispatch(downloadingRoles(false));
  }
};
