import { INITIAL_ORDER_BY_TOKEN_IMPORTS } from '../../constant';
import {
  DOWNLOADING_TOKEN_IMPORTS, IMPORTING_EDITING_TOKEN_IMPORT, DOWNLOADING_DELETING_TOKEN_IMPORT,
  RETRYING_TOKEN_IMPORT, SET_TOKEN_IMPORT_SELECTED_PAGE_SIZE, SET_TOKEN_IMPORT_TAPPED_ID,
  SET_TOKEN_IMPORT_SEARCH_TEXT, SET_TOKEN_IMPORT_SELECTED_ORDER_BY, SET_TOKEN_IMPORT_SELECTED_CSV,
  SET_TOKEN_IMPORT_SELECTED_VOUCHER, SET_TOKEN_IMPORT_SELECTED_MERCHANT_ID,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  retrying: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  selectedCsv: '',
  selectedVoucherId: '',
  selectedMerchantId: '',
  orderBy: INITIAL_ORDER_BY_TOKEN_IMPORTS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_TOKEN_IMPORTS:
      return { ...state, downloading: action.status };
    case IMPORTING_EDITING_TOKEN_IMPORT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_TOKEN_IMPORT:
      return { ...state, downloadingDeleting: action.status };
    case RETRYING_TOKEN_IMPORT:
      return { ...state, retrying: action.status };
    case SET_TOKEN_IMPORT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_TOKEN_IMPORT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_TOKEN_IMPORT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_TOKEN_IMPORT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_TOKEN_IMPORT_SELECTED_VOUCHER:
      return { ...state, selectedVoucherId: action.id };
    case SET_TOKEN_IMPORT_SELECTED_MERCHANT_ID:
      return { ...state, selectedMerchantId: action.id };
    case SET_TOKEN_IMPORT_SELECTED_CSV:
      return { ...state, selectedCsv: action.base64 };
    default: return state;
  }
};
