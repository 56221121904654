import { downloadAuditTrail } from '../../../helper';
import { addAuditTrail, downloadingAuditTrail } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAuditTrail(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAuditTrail;

    const auditTrail = await downloadAuditTrail(tappedId, token);
    dispatch(addAuditTrail(auditTrail));
  } finally {
    dispatch(downloadingAuditTrail(false));
  }
};
