import { addHoliday, downloadingHoliday } from '../simple-action';
import { downloadHoliday } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingHoliday(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiHoliday;

    const holiday = await downloadHoliday(tappedId, token);
    dispatch(addHoliday(holiday));
  } finally {
    dispatch(downloadingHoliday(false));
  }
};
