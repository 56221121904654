import {
  REST_URL_REGISTRATIONS, REST_URL_VIEW_REGISTRATION, REST_URL_APPROVE_REJECT_REGISTRATION,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendPutRequest, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadRegistrations = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_REGISTRATIONS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadRegistration = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_REGISTRATION.replace(/\{id\}/, id), token);
  return response;
};

export const approveRejectRegistration = async (id, action, notes, token) => {
  const body = { id, action, notes };
  await sendPutRequest(REST_URL_APPROVE_REJECT_REGISTRATION, body, token);
};
