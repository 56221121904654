import { connect } from 'react-redux';
import CombinedView from './combined-view.presentation';

const mapStateToProps = (state) => ({
  downloading: state.uiCalendar.downloadingDateRangeStatus,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CombinedView);
