import {
  REST_URL_DISCUSSION_COMMENTS, REST_URL_VIEW_DISCUSSION_COMMENT,
  REST_URL_DISCUSSIONS,
  REST_URL_REPORTED_COMMENTS,
  REST_URL_VIEW_REPORTED_COMMENT,
} from './constant';
import { buildQueryParams, sendGetRequest, transformQueryResult } from '../../helper';

export * from '../../helper';

export const downloadDiscussionComments = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DISCUSSION_COMMENTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_DISCUSSION_COMMENTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadDiscussionComment = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DISCUSSION_COMMENT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadDiscussions = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DISCUSSIONS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_DISCUSSIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadReportedComments = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_REPORTED_COMMENTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_REPORTED_COMMENTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadReportedComment = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_REPORTED_COMMENT.replace(/\{id\}/, id), token);
  return response;
};
