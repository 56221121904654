import { connect } from 'react-redux';
import {
  change, reset,
} from 'redux-form';
import {
  DIALOG_TYPE_EDIT,
  MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER,
  PDF_TYPE_IMAGE,
  RXFIELD_ANNOTATION_DESIGNER_FONT_NAME,
  RXFIELD_ANNOTATION_DESIGNER_IMAGE,
  RXFORM_ANNOTATION_DESIGNER,
  PAGE_MODE_ADD,
  PAGE_MODE_TABLE,
  PAGE_MODE_EDIT,
  PAGE_MODE_VIEW,
} from '../../constant';
import {
  sortAsc,
  transformInitialValues,
} from '../../helper';
import {
  renderAnnotationAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setColorPickerHex, downloadFontAsync,
  setSelectedFont, setAnnotationDesignSelectedImage,
  setCreateAnnotationDialogVisibility, setCreateAnnotationDialogPdfType,
  setAnnotationDesignListTappedId,
  setAlertConfirmationMessage, setPDFTemplate,
  downloadAnnotationDesignListsAsync, setAnnotationDesignSelectedPageSize,
  clearAnnotationDesignLists, setAnnotationDesignListSearchText,
  setAnnotationDesignListSelectedOrderBy, setAnnotationDesignTappedAnnotationId,
  setFunctionalPageMode, downloadDeleteAnnotationDesignAsync,
  setAnnotationDesignAnnotationDesignDescription, setAnnotationDesignAnnotationDesignName,
  clearAnnotationDesigns,
} from '../../redux/action';
import AnnotationDesignerPage from './annotation-designer.presentation';
import GlobalizedString from '../../../../localization';
import addEditAnnotationAsync from '../../redux/action/async/addEditAnnotationAsync';

const getFontStyle = (item) => {
  let fontStyle = '';

  fontStyle = item.isBold ? fontStyle.concat('Bold') : fontStyle;
  fontStyle = item.isBold && item.isItalic ? fontStyle.concat(',') : fontStyle;
  fontStyle = item.isItalic ? fontStyle.concat('Italic') : fontStyle;

  return fontStyle;
};

const getInitialValues = (state) => {
  const {
    annotationDesigns, uiAnnotationDesign,
    uiFunctionalPage,
  } = state;
  const { data } = annotationDesigns;
  const {
    tappedAnnotationId,
    annotationDesignerName,
    annotationDesignerDescription,
  } = uiAnnotationDesign;
  const { pageMode } = uiFunctionalPage;

  const found = tappedAnnotationId !== '' && pageMode !== PAGE_MODE_TABLE
    ? data.find((x) => x.id === tappedAnnotationId) : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found,
    {
      name: annotationDesignerName,
      description: annotationDesignerDescription,
      fontName: { label: found?.fontName, value: found?.fontName },
      fontStyle: getFontStyle(found),
      imageValue: found.type === PDF_TYPE_IMAGE ? found.value : '',
    })
    : {
      name: annotationDesignerName,
      description: annotationDesignerDescription,
      fontName: { label: '', value: '' },
      fontStyle: '',
      imageValue: '',
    };
  return result;
};

const transformFormData = (data) => {
  const response = Object.values(data).map((x) => ({
    label: x.name,
    value: x.name,
  }));

  return response;
};

const mapStateToProps = (state) => ({
  data: state.annotationDesigns.data.sort((a, b) => sortAsc(a.page, b.page) || sortAsc(a.y, b.y)),
  fontData: transformFormData(state.fonts.data),
  pageMode: state.uiFunctionalPage.pageMode,
  downloadingList: state.uiAnnotationDesignList.downloading,
  addingEditing: state.uiAnnotationDesign.addingEditing,
  downloading: state.uiAnnotationDesign.downloading,
  downloadingDeleting: state.uiAnnotationDesignList.downloadingDeleting,
  pdfType: state.uiPdfList.pdfType,
  initialValues: getInitialValues(state),
  selectedImage: state.uiAnnotationDesign.selectedImage,
  fontNameVisibility: state.uiAnnotationDesign.fontNameVisibility,
  tappedId: state.uiAnnotationDesignList.tappedId,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER));
    dispatch(clearAnnotationDesigns());
    dispatch(downloadFontAsync());
    dispatch(downloadAnnotationDesignListsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setPDFTemplate(''));
  },
  onBackPressed: () => {
    dispatch(setAnnotationDesignTappedAnnotationId(''));
    dispatch(setAnnotationDesignListTappedId(''));
    dispatch(setPDFTemplate(''));
    dispatch(setAnnotationDesignAnnotationDesignDescription(''));
    dispatch(setAnnotationDesignAnnotationDesignName(''));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_ANNOTATION_DESIGNER));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  },
  onColorSelected: (color) => {
    dispatch(setColorPickerHex(color));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAnnotationDesignListsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAnnotationDesignSelectedPageSize(pageSize));
    dispatch(downloadAnnotationDesignListsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeNameText: (e) => {
    const name = e.target.value || '';
    dispatch(setAnnotationDesignAnnotationDesignName(name));
  },
  onChangeDescriptionText: (e) => {
    const desc = e.target.value || '';
    dispatch(setAnnotationDesignAnnotationDesignDescription(desc));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteAnnotationDesignAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setFunctionalPageMode(PAGE_MODE_ADD));
  },
  onDeletePressed: (id) => {
    dispatch(setAnnotationDesignListTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalizedString.common.msgDeleteConfirmation));
  },
  onDeleteListPressed: (id) => {
    dispatch(setAnnotationDesignTappedAnnotationId(id));
    dispatch(setAlertConfirmationMessage(GlobalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id, type, color) => {
    dispatch(setAnnotationDesignTappedAnnotationId(id));
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_EDIT, type));
    dispatch(setCreateAnnotationDialogPdfType(type));
    dispatch(setColorPickerHex(color));
  },
  onEditButtonPressed: (id) => {
    dispatch(setFunctionalPageMode(PAGE_MODE_EDIT));
    dispatch(setAnnotationDesignListTappedId(id));
    dispatch(downloadDeleteAnnotationDesignAsync());
  },
  onViewButtonPressed: (id) => {
    dispatch(setAnnotationDesignListTappedId(id));
    dispatch(downloadDeleteAnnotationDesignAsync());
  },
  onFontOptionSelected: (option) => {
    if (option) {
      dispatch(setSelectedFont(option));
      dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_FONT_NAME, option));
    } else {
      dispatch(setSelectedFont(''));
      dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_FONT_NAME, ''));
    }
  },
  onImageSelected: (image, fileName, url) => {
    dispatch(setAnnotationDesignSelectedImage(url, fileName));
    dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_IMAGE, image));
  },
  onRefresh: (pageSize) => {
    dispatch(setAnnotationDesignSelectedPageSize(pageSize));
    dispatch(clearAnnotationDesignLists());
    dispatch(downloadAnnotationDesignListsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSaveAnnotationPressed: async (submitValue, pdfType, imageFileName) => {
    try {
      await dispatch(renderAnnotationAsync(submitValue, pdfType, imageFileName));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSavePressed: async (submitValue) => {
    try {
      await dispatch(addEditAnnotationAsync(submitValue));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAnnotationDesignListSearchText(text));
      dispatch(clearAnnotationDesignLists());
      await dispatch(downloadAnnotationDesignListsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAnnotationDesignListSelectedOrderBy(orderBy));
    dispatch(clearAnnotationDesignLists());
    dispatch(downloadAnnotationDesignListsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationDesignerPage);
