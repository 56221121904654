import {
  downloadingSummaryIosDeviceModelDistributions,
  setSummaryIosDeviceModelDistributions,
} from '../simple-action';
import {
  downloadSummaryIosDeviceModelDistribution,
} from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingSummaryIosDeviceModelDistributions(true));

    const list = await downloadSummaryIosDeviceModelDistribution(
      token,
    );

    if (list) {
      dispatch(setSummaryIosDeviceModelDistributions(list));
    }
  } finally {
    dispatch(downloadingSummaryIosDeviceModelDistributions(false));
  }
};
