import { connect } from 'react-redux';
import {
  cropppingImage, setAlertErrorMessage, setCropImagePosition, setCropImageAreaPixels,
  setCropImageResult, setCropImageSource, setCropImageZoom, resetCropImage,
} from '../../redux/action';
import { readFile, transformImageUrl } from '../../helper';
import ImageInputField from './image-input-field.presentation';

const mapStateToProps = (state) => ({
  cropping: state.cropImage.cropping,
  crop: state.cropImage.crop,
  croppedAreaPixels: state.cropImage.croppedAreaPixels,
  croppedImage: state.cropImage.croppedImage,
  fileName: state.cropImage.fileName,
  imageSrc: state.cropImage.imageSrc,
  zoom: state.cropImage.zoom,
});

const createImage = (url) => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
  image.src = url;
});

const cropImage = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const safeArea = Math.max(image.width, image.height) * 2;
  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  );

  return canvas.toDataURL('image/jpeg');
};

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(resetCropImage());
  },
  onCropChanged: (position) => {
    dispatch(setCropImagePosition(position));
  },
  onCropCompleted: (cropArea, cropAreaPixels) => {
    dispatch(setCropImageAreaPixels(cropAreaPixels));
  },
  onFileChange: async (e, useCropper, useResultSource, onImageSelected) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);

      if (useCropper) {
        dispatch(cropppingImage(true));
        dispatch(setCropImageSource(imageDataUrl, file.name));
        dispatch(setCropImagePosition({ x: 0, y: 0 }));
        dispatch(setCropImageZoom(1));
      } else {
        const url = transformImageUrl(imageDataUrl);
        onImageSelected(url, file.name, imageDataUrl);
        if (!useResultSource) {
          dispatch(setCropImageResult(imageDataUrl));
        }
      }
    }
  },
  onOkPressed: async (imageSrc, fileName, croppedAreaPixels, onImageSelected) => {
    try {
      dispatch(cropppingImage(true));
      const cropped = await cropImage(imageSrc, croppedAreaPixels);
      const url = transformImageUrl(cropped);
      onImageSelected(url, fileName);
      dispatch(setCropImageResult(cropped));
      dispatch(cropppingImage(false));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
      dispatch(cropppingImage(false));
    }
  },
  onZoomChanged: (size) => {
    dispatch(setCropImageZoom(size));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageInputField);
