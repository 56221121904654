import { downloadingAdditionalFields, setAdditionalFields } from '../simple-action';
import { downloadAdditionalFields, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, additionalFields, uiAdditionalField } = getState();
  const { token } = authentication;
  const { meta } = additionalFields;
  const { selectedPageSize, searchBarText, orderBy } = uiAdditionalField;
  const { filterString } = getState().uiFunctionalPage;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingAdditionalFields(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAdditionalFields(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setAdditionalFields(list));
  } finally {
    dispatch(downloadingAdditionalFields(false));
  }
};
