import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StyleShape } from '../type';
import { COLOR_BODY_TEXT } from '../constant';

const styles = {
  textStyle: {
    color: COLOR_BODY_TEXT,
    whiteSpace: 'pre-line',
  },
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
};

const Label = ({
  fontSize, fontColor, bold, italic, underline, textStyle, numberOfLines, children, ...props
}) => {
  const ellipsisStyle = numberOfLines
    ? { ...styles.ellipsisText, WebkitLineClamp: numberOfLines.toString() } : {};

  return (
    <Typography
      sx={{
        ...styles.textStyle,
        ...ellipsisStyle,
        fontSize: fontSize || undefined,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textDecorationLine: underline ? 'underline' : 'none',
        ...textStyle,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Label;

Label.propTypes = {
  fontSize: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  numberOfLines: PropTypes.number,
  children: PropTypes.node.isRequired,
  textStyle: PropTypes.oneOfType([StyleShape, PropTypes.arrayOf(StyleShape)]),
};

Label.defaultProps = {
  fontColor: COLOR_BODY_TEXT,
  bold: false,
  italic: false,
  underline: false,
  numberOfLines: 0,
  textStyle: {},
};
