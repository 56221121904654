import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');


export const DOWNLOADING_SURVEY_CATEGORIES = 'DOWNLOADING_SURVEY_CATEGORIES';
export const downloadingSurveyCategories = makeActionCreator(DOWNLOADING_SURVEY_CATEGORIES, 'status');

export const SET_SURVEY_CATEGORIES = 'SET_SURVEY_CATEGORIES';
export const setSurveyCategories = makeActionCreator(SET_SURVEY_CATEGORIES, 'data');

export const ADD_SURVEY_CATEGORY = 'ADD_SURVEY_CATEGORY';
export const addSurveyCategory = makeActionCreator(ADD_SURVEY_CATEGORY, 'data');

export const CLEAR_SURVEY_CATEGORIES = 'CLEAR_SURVEY_CATEGORIES';
export const clearSurveyCategories = makeActionCreator(CLEAR_SURVEY_CATEGORIES);

export const ADDING_EDITING_SURVEY_CATEGORY = 'ADDING_EDITING_SURVEY_CATEGORY';
export const addingEditingSurveyCategory = makeActionCreator(ADDING_EDITING_SURVEY_CATEGORY, 'status');

export const DOWNLOADING_DELETING_SURVEY_CATEGORY = 'DOWNLOADING_DELETING_SURVEY_CATEGORY';
export const downloadingDeletingSurveyCategory = makeActionCreator(DOWNLOADING_DELETING_SURVEY_CATEGORY, 'status');

export const COPYING_SURVEY_DEEPLINK = 'COPYING_SURVEY_DEEPLINK';
export const copyingSurveyDeeplink = makeActionCreator(COPYING_SURVEY_DEEPLINK, 'status');


export const SET_SURVEY_CATEGORY_SELECTED_PAGE_SIZE = 'SET_SURVEY_CATEGORY_SELECTED_PAGE_SIZE';
export const setSurveyCategorySelectedPageSize = makeActionCreator(SET_SURVEY_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_SURVEY_CATEGORY_SELECTED_ORDER_BY = 'SET_SURVEY_CATEGORY_SELECTED_ORDER_BY';
export const setSurveyCategorySelectedOrderBy = makeActionCreator(SET_SURVEY_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_SURVEY_CATEGORY_TAPPED_ID = 'SET_SURVEY_CATEGORY_TAPPED_ID';
export const setSurveyCategoryTappedId = makeActionCreator(SET_SURVEY_CATEGORY_TAPPED_ID, 'id');

export const SET_SURVEY_CATEGORY_SEARCH_TEXT = 'SET_SURVEY_CATEGORY_SEARCH_TEXT';
export const setSurveyCategorySearchText = makeActionCreator(SET_SURVEY_CATEGORY_SEARCH_TEXT, 'text');


export const DOWNLOADING_QUIZ_CATEGORIES = 'DOWNLOADING_QUIZ_CATEGORIES';
export const downloadingQuizCategories = makeActionCreator(DOWNLOADING_QUIZ_CATEGORIES, 'status');

export const SET_QUIZ_CATEGORIES = 'SET_QUIZ_CATEGORIES';
export const setQuizCategories = makeActionCreator(SET_QUIZ_CATEGORIES, 'data');

export const ADD_QUIZ_CATEGORY = 'ADD_QUIZ_CATEGORY';
export const addQuizCategory = makeActionCreator(ADD_QUIZ_CATEGORY, 'data');

export const CLEAR_QUIZ_CATEGORIES = 'CLEAR_QUIZ_CATEGORIES';
export const clearQuizCategories = makeActionCreator(CLEAR_QUIZ_CATEGORIES);

export const ADDING_EDITING_QUIZ_CATEGORY = 'ADDING_EDITING_QUIZ_CATEGORY';
export const addingEditingQuizCategory = makeActionCreator(ADDING_EDITING_QUIZ_CATEGORY, 'status');

export const DOWNLOADING_DELETING_QUIZ_CATEGORY = 'DOWNLOADING_DELETING_QUIZ_CATEGORY';
export const downloadingDeletingQuizCategory = makeActionCreator(DOWNLOADING_DELETING_QUIZ_CATEGORY, 'status');


export const SET_QUIZ_CATEGORY_SELECTED_PAGE_SIZE = 'SET_QUIZ_CATEGORY_SELECTED_PAGE_SIZE';
export const setQuizCategorySelectedPageSize = makeActionCreator(SET_QUIZ_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_QUIZ_CATEGORY_SELECTED_ORDER_BY = 'SET_QUIZ_CATEGORY_SELECTED_ORDER_BY';
export const setQuizCategorySelectedOrderBy = makeActionCreator(SET_QUIZ_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_QUIZ_CATEGORY_TAPPED_ID = 'SET_QUIZ_CATEGORY_TAPPED_ID';
export const setQuizCategoryTappedId = makeActionCreator(SET_QUIZ_CATEGORY_TAPPED_ID, 'id');

export const SET_QUIZ_CATEGORY_SEARCH_TEXT = 'SET_QUIZ_CATEGORY_SEARCH_TEXT';
export const setQuizCategorySearchText = makeActionCreator(SET_QUIZ_CATEGORY_SEARCH_TEXT, 'text');


export const DOWNLOADING_QUIZES = 'DOWNLOADING_QUIZES';
export const downloadingQuizes = makeActionCreator(DOWNLOADING_QUIZES, 'status');

export const SET_QUIZES = 'SET_QUIZES';
export const setQuizes = makeActionCreator(SET_QUIZES, 'data');

export const ADD_QUIZ = 'ADD_QUIZ';
export const addQuiz = makeActionCreator(ADD_QUIZ, 'data');

export const CLEAR_QUIZES = 'CLEAR_QUIZES';
export const clearQuizes = makeActionCreator(CLEAR_QUIZES);

export const ADDING_EDITING_QUIZ = 'ADDING_EDITING_QUIZ';
export const addingEditingQuiz = makeActionCreator(ADDING_EDITING_QUIZ, 'status');

export const DOWNLOADING_DELETING_QUIZ = 'DOWNLOADING_DELETING_QUIZ';
export const downloadingDeletingQuiz = makeActionCreator(DOWNLOADING_DELETING_QUIZ, 'status');

export const SAVING_QUIZ_RESULT = 'SAVING_QUIZ_RESULT';
export const savingQuizResult = makeActionCreator(SAVING_QUIZ_RESULT, 'status');

export const COPYING_QUIZ_DEEPLINK = 'COPYING_QUIZ_DEEPLINK';
export const copyingQuizDeeplink = makeActionCreator(COPYING_QUIZ_DEEPLINK, 'status');


export const SET_QUIZ_SELECTED_PAGE_SIZE = 'SET_QUIZ_SELECTED_PAGE_SIZE';
export const setQuizSelectedPageSize = makeActionCreator(SET_QUIZ_SELECTED_PAGE_SIZE, 'size');

export const SET_QUIZ_SELECTED_ORDER_BY = 'SET_QUIZ_SELECTED_ORDER_BY';
export const setQuizSelectedOrderBy = makeActionCreator(SET_QUIZ_SELECTED_ORDER_BY, 'order');

export const SET_QUIZ_TAPPED_ID = 'SET_QUIZ_TAPPED_ID';
export const setQuizTappedId = makeActionCreator(SET_QUIZ_TAPPED_ID, 'id');

export const SET_QUIZ_SEARCH_TEXT = 'SET_QUIZ_SEARCH_TEXT';
export const setQuizSearchText = makeActionCreator(SET_QUIZ_SEARCH_TEXT, 'text');

export const SET_QUIZ_SELECTED_VISIBILITY_STATUS = 'SET_QUIZ_SELECTED_VISIBILITY_STATUS';
export const setQuizSelectedVisibilityStatus = makeActionCreator(SET_QUIZ_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_QUIZ_SELECTED_QUIZ_CATEGORY_ID = 'SET_QUIZ_SELECTED_QUIZ_CATEGORY_ID';
export const setQuizSelectedQuizCategoryId = makeActionCreator(SET_QUIZ_SELECTED_QUIZ_CATEGORY_ID, 'id');

export const SET_QUIZ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_QUIZ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setQuizAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_QUIZ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_SURVEYS = 'DOWNLOADING_SURVEYS';
export const downloadingSurveys = makeActionCreator(DOWNLOADING_SURVEYS, 'status');

export const SET_SURVEYS = 'SET_SURVEYS';
export const setSurveys = makeActionCreator(SET_SURVEYS, 'data');

export const ADD_SURVEY = 'ADD_SURVEY';
export const addSurvey = makeActionCreator(ADD_SURVEY, 'data');

export const CLEAR_SURVEYS = 'CLEAR_SURVEYS';
export const clearSurveys = makeActionCreator(CLEAR_SURVEYS);

export const ADDING_EDITING_SURVEY = 'ADDING_EDITING_SURVEY';
export const addingEditingSurvey = makeActionCreator(ADDING_EDITING_SURVEY, 'status');

export const DOWNLOADING_DELETING_SURVEY = 'DOWNLOADING_DELETING_SURVEY';
export const downloadingDeletingSurvey = makeActionCreator(DOWNLOADING_DELETING_SURVEY, 'status');

export const SAVING_SURVEY_RESULT = 'SAVING_SURVEY_RESULT';
export const savingSurveyResult = makeActionCreator(SAVING_SURVEY_RESULT, 'status');


export const SET_SURVEY_SELECTED_PAGE_SIZE = 'SET_SURVEY_SELECTED_PAGE_SIZE';
export const setSurveySelectedPageSize = makeActionCreator(SET_SURVEY_SELECTED_PAGE_SIZE, 'size');

export const SET_SURVEY_SELECTED_ORDER_BY = 'SET_SURVEY_SELECTED_ORDER_BY';
export const setSurveySelectedOrderBy = makeActionCreator(SET_SURVEY_SELECTED_ORDER_BY, 'order');

export const SET_SURVEY_TAPPED_ID = 'SET_SURVEY_TAPPED_ID';
export const setSurveyTappedId = makeActionCreator(SET_SURVEY_TAPPED_ID, 'id');

export const SET_SURVEY_SEARCH_TEXT = 'SET_SURVEY_SEARCH_TEXT';
export const setSurveySearchText = makeActionCreator(SET_SURVEY_SEARCH_TEXT, 'text');

export const SET_SURVEY_SELECTED_VISIBILITY_STATUS = 'SET_SURVEY_SELECTED_VISIBILITY_STATUS';
export const setSurveySelectedVisibilityStatus = makeActionCreator(SET_SURVEY_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_SURVEY_SELECTED_CATEGORY_ID = 'SET_SURVEY_SELECTED_CATEGORY_ID';
export const setSurveySelectedCategoryId = makeActionCreator(SET_SURVEY_SELECTED_CATEGORY_ID, 'option');

export const SET_SURVEY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SURVEY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSurveyAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SURVEY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_SURVEY_QUESTIONS = 'DOWNLOADING_SURVEY_QUESTIONS';
export const downloadingSurveyQuestions = makeActionCreator(DOWNLOADING_SURVEY_QUESTIONS, 'status');

export const SET_SURVEY_QUESTIONS = 'SET_SURVEY_QUESTIONS';
export const setSurveyQuestions = makeActionCreator(SET_SURVEY_QUESTIONS, 'data');

export const ADD_SURVEY_QUESTION = 'ADD_SURVEY_QUESTION';
export const addSurveyQuestion = makeActionCreator(ADD_SURVEY_QUESTION, 'data');

export const CLEAR_SURVEY_QUESTIONS = 'CLEAR_SURVEY_QUESTIONS';
export const clearSurveyQuestions = makeActionCreator(CLEAR_SURVEY_QUESTIONS);

export const ADDING_EDITING_SURVEY_QUESTION = 'ADDING_EDITING_SURVEY_QUESTION';
export const addingEditingSurveyQuestion = makeActionCreator(ADDING_EDITING_SURVEY_QUESTION, 'status');

export const DOWNLOADING_DELETING_SURVEY_QUESTION = 'DOWNLOADING_DELETING_SURVEY_QUESTION';
export const downloadingDeletingSurveyQuestion = makeActionCreator(DOWNLOADING_DELETING_SURVEY_QUESTION, 'status');

export const ENABLING_DISABLING_SURVEY_QUESTION = 'ENABLING_DISABLING_SURVEY_QUESTION';
export const enablingDisablingSurveyQuestion = makeActionCreator(ENABLING_DISABLING_SURVEY_QUESTION, 'status');

export const DOWNLOADING_SURVEY_QUESTION_PARENTS = 'DOWNLOADING_SURVEY_QUESTION_PARENTS';
export const downloadingSurveyQuestionParents = makeActionCreator(DOWNLOADING_SURVEY_QUESTION_PARENTS, 'status');

export const SET_SURVEY_QUESTION_PARENTS = 'SET_SURVEY_QUESTION_PARENTS';
export const setSurveyQuestionParents = makeActionCreator(SET_SURVEY_QUESTION_PARENTS, 'data');

export const CLEAR_SURVEY_QUESTION_PARENTS = 'CLEAR_SURVEY_QUESTION_PARENTS';
export const clearSurveyQuestionParents = makeActionCreator(CLEAR_SURVEY_QUESTION_PARENTS);


export const SET_SURVEY_QUESTION_SELECTED_PAGE_SIZE = 'SET_SURVEY_QUESTION_SELECTED_PAGE_SIZE';
export const setSurveyQuestionSelectedPageSize = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_PAGE_SIZE, 'size');

export const SET_SURVEY_QUESTION_SELECTED_ORDER_BY = 'SET_SURVEY_QUESTION_SELECTED_ORDER_BY';
export const setSurveyQuestionSelectedOrderBy = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_ORDER_BY, 'order');

export const SET_SURVEY_QUESTION_TAPPED_ID = 'SET_SURVEY_QUESTION_TAPPED_ID';
export const setSurveyQuestionTappedId = makeActionCreator(SET_SURVEY_QUESTION_TAPPED_ID, 'id');

export const SET_SURVEY_QUESTION_SEARCH_TEXT = 'SET_SURVEY_QUESTION_SEARCH_TEXT';
export const setSurveyQuestionSearchText = makeActionCreator(SET_SURVEY_QUESTION_SEARCH_TEXT, 'text');

export const SET_SURVEY_QUESTION_PARENT_SEARCH_TEXT = 'SET_SURVEY_QUESTION_PARENT_SEARCH_TEXT';
export const setSurveyQuestionParentSearchText = makeActionCreator(SET_SURVEY_QUESTION_PARENT_SEARCH_TEXT, 'text');

export const SET_SURVEY_QUESTION_SELECTED_FIELD_TYPE = 'SET_SURVEY_QUESTION_SELECTED_FIELD_TYPE';
export const setSurveyQuestionSelectedFieldType = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_FIELD_TYPE, 'option');

export const SET_SURVEY_QUESTION_SELECTED_OPTION_MODE = 'SET_SURVEY_QUESTION_SELECTED_OPTION_MODE';
export const setSurveyQuestionSelectedOptionMode = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_OPTION_MODE, 'option');

export const SET_SURVEY_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR = 'SET_SURVEY_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR';
export const setSurveyQuestionSelectedOptionValueSeparator = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR, 'option');

export const SET_SURVEY_QUESTION_SELECTED_PARENT = 'SET_SURVEY_QUESTION_SELECTED_PARENT';
export const setSurveyQuestionSelectedParent = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_PARENT, 'option');

export const SET_SURVEY_QUESTION_SELECTED_PICKER_MODE = 'SET_SURVEY_QUESTION_SELECTED_PICKER_MODE';
export const setSurveyQuestionSelectedPickerMode = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_PICKER_MODE, 'option');

export const SET_SURVEY_QUESTION_SELECTED_TEXT_MULTILINE = 'SET_SURVEY_QUESTION_SELECTED_TEXT_MULTILINE';
export const setSurveyQuestionSelectedTextMultiline = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_TEXT_MULTILINE, 'option');

export const SET_SURVEY_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE = 'SET_SURVEY_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE';
export const setSurveyQuestionSelectedTextKeyboardType = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE, 'option');

export const SET_SURVEY_QUESTION_SELECTED_LOGICAL_OPERATOR = 'SET_SURVEY_QUESTION_SELECTED_LOGICAL_OPERATOR';
export const setSurveyQuestionSelectedLogicalOperator = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_LOGICAL_OPERATOR, 'option');

export const SET_SURVEY_QUESTION_SELECTED_MUST_USE_CAMERA = 'SET_SURVEY_QUESTION_SELECTED_MUST_USE_CAMERA';
export const setSurveyQuestionSelectedMustUseCamera = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_MUST_USE_CAMERA, 'option');

export const SET_SURVEY_QUESTION_SELECTED_SURVEY = 'SET_SURVEY_QUESTION_SELECTED_SURVEY';
export const setSurveyQuestionSelectedSurvey = makeActionCreator(SET_SURVEY_QUESTION_SELECTED_SURVEY, 'option');


export const DOWNLOADING_SURVEY_RESPONSES = 'DOWNLOADING_SURVEY_RESPONSES';
export const downloadingSurveyResponses = makeActionCreator(DOWNLOADING_SURVEY_RESPONSES, 'status');

export const SET_SURVEY_RESPONSES = 'SET_SURVEY_RESPONSES';
export const setSurveyResponses = makeActionCreator(SET_SURVEY_RESPONSES, 'data');

export const ADD_SURVEY_RESPONSE = 'ADD_SURVEY_RESPONSE';
export const addSurveyResponse = makeActionCreator(ADD_SURVEY_RESPONSE, 'data');

export const CLEAR_SURVEY_RESPONSES = 'CLEAR_SURVEY_RESPONSES';
export const clearSurveyResponses = makeActionCreator(CLEAR_SURVEY_RESPONSES);

export const DOWNLOADING_SURVEY_RESPONSE = 'DOWNLOADING_SURVEY_RESPONSE';
export const downloadingSurveyResponse = makeActionCreator(DOWNLOADING_SURVEY_RESPONSE, 'status');


export const SET_SURVEY_RESPONSE_SELECTED_PAGE_SIZE = 'SET_SURVEY_RESPONSE_SELECTED_PAGE_SIZE';
export const setSurveyResponseSelectedPageSize = makeActionCreator(SET_SURVEY_RESPONSE_SELECTED_PAGE_SIZE, 'size');

export const SET_SURVEY_RESPONSE_SELECTED_ORDER_BY = 'SET_SURVEY_RESPONSE_SELECTED_ORDER_BY';
export const setSurveyResponseSelectedOrderBy = makeActionCreator(SET_SURVEY_RESPONSE_SELECTED_ORDER_BY, 'order');

export const SET_SURVEY_RESPONSE_TAPPED_ID = 'SET_SURVEY_RESPONSE_TAPPED_ID';
export const setSurveyResponseTappedId = makeActionCreator(SET_SURVEY_RESPONSE_TAPPED_ID, 'id');

export const SET_SURVEY_RESPONSE_SEARCH_TEXT = 'SET_SURVEY_RESPONSE_SEARCH_TEXT';
export const setSurveyResponseSearchText = makeActionCreator(SET_SURVEY_RESPONSE_SEARCH_TEXT, 'text');


export const DOWNLOADING_QUIZ_QUESTIONS = 'DOWNLOADING_QUIZ_QUESTIONS';
export const downloadingQuizQuestions = makeActionCreator(DOWNLOADING_QUIZ_QUESTIONS, 'status');

export const SET_QUIZ_QUESTIONS = 'SET_QUIZ_QUESTIONS';
export const setQuizQuestions = makeActionCreator(SET_QUIZ_QUESTIONS, 'data');

export const ADD_QUIZ_QUESTION = 'ADD_QUIZ_QUESTION';
export const addQuizQuestion = makeActionCreator(ADD_QUIZ_QUESTION, 'data');

export const CLEAR_QUIZ_QUESTIONS = 'CLEAR_QUIZ_QUESTIONS';
export const clearQuizQuestions = makeActionCreator(CLEAR_QUIZ_QUESTIONS);

export const ADDING_EDITING_QUIZ_QUESTION = 'ADDING_EDITING_QUIZ_QUESTION';
export const addingEditingQuizQuestion = makeActionCreator(ADDING_EDITING_QUIZ_QUESTION, 'status');

export const DOWNLOADING_DELETING_QUIZ_QUESTION = 'DOWNLOADING_DELETING_QUIZ_QUESTION';
export const downloadingDeletingQuizQuestion = makeActionCreator(DOWNLOADING_DELETING_QUIZ_QUESTION, 'status');

export const ENABLING_DISABLING_QUIZ_QUESTION = 'ENABLING_DISABLING_QUIZ_QUESTION';
export const enablingDisablingQuizQuestion = makeActionCreator(ENABLING_DISABLING_QUIZ_QUESTION, 'status');


export const SET_QUIZ_QUESTION_SELECTED_PAGE_SIZE = 'SET_QUIZ_QUESTION_SELECTED_PAGE_SIZE';
export const setQuizQuestionSelectedPageSize = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_PAGE_SIZE, 'size');

export const SET_QUIZ_QUESTION_SELECTED_ORDER_BY = 'SET_QUIZ_QUESTION_SELECTED_ORDER_BY';
export const setQuizQuestionSelectedOrderBy = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_ORDER_BY, 'order');

export const SET_QUIZ_QUESTION_TAPPED_ID = 'SET_QUIZ_QUESTION_TAPPED_ID';
export const setQuizQuestionTappedId = makeActionCreator(SET_QUIZ_QUESTION_TAPPED_ID, 'id');

export const SET_QUIZ_QUESTION_SEARCH_TEXT = 'SET_QUIZ_QUESTION_SEARCH_TEXT';
export const setQuizQuestionSearchText = makeActionCreator(SET_QUIZ_QUESTION_SEARCH_TEXT, 'text');

export const SET_QUIZ_QUESTION_SELECTED_FIELD_TYPE = 'SET_QUIZ_QUESTION_SELECTED_FIELD_TYPE';
export const setQuizQuestionSelectedFieldType = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_FIELD_TYPE, 'option');

export const SET_QUIZ_QUESTION_SELECTED_OPTION_MODE = 'SET_QUIZ_QUESTION_SELECTED_OPTION_MODE';
export const setQuizQuestionSelectedOptionMode = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_OPTION_MODE, 'option');

export const SET_QUIZ_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR = 'SET_QUIZ_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR';
export const setQuizQuestionSelectedOptionValueSeparator = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR, 'option');

export const SET_QUIZ_QUESTION_SELECTED_PICKER_MODE = 'SET_QUIZ_QUESTION_SELECTED_PICKER_MODE';
export const setQuizQuestionSelectedPickerMode = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_PICKER_MODE, 'option');

export const SET_QUIZ_QUESTION_SELECTED_TEXT_MULTILINE = 'SET_QUIZ_QUESTION_SELECTED_TEXT_MULTILINE';
export const setQuizQuestionSelectedTextMultiline = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_TEXT_MULTILINE, 'option');

export const SET_QUIZ_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE = 'SET_QUIZ_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE';
export const setQuizQuestionSelectedTextKeyboardType = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE, 'option');

export const SET_QUIZ_QUESTION_SELECTED_LOGICAL_OPERATOR = 'SET_QUIZ_QUESTION_SELECTED_LOGICAL_OPERATOR';
export const setQuizQuestionSelectedLogicalOperator = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_LOGICAL_OPERATOR, 'option');

export const SET_QUIZ_QUESTION_SELECTED_MUST_USE_CAMERA = 'SET_QUIZ_QUESTION_SELECTED_MUST_USE_CAMERA';
export const setQuizQuestionSelectedMustUseCamera = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_MUST_USE_CAMERA, 'option');

export const SET_QUIZ_QUESTION_SELECTED_QUIZ = 'SET_QUIZ_QUESTION_SELECTED_QUIZ';
export const setQuizQuestionSelectedQuiz = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_QUIZ, 'option');

export const SET_QUIZ_QUESTION_SELECTED_CORRECT_ANSWER_LOGICAL_OPERATOR = 'SET_QUIZ_QUESTION_SELECTED_CORRECT_ANSWER_LOGICAL_OPERATOR';
export const setQuizQuestionSelectedCorrectAnswerLogicalOperator = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_CORRECT_ANSWER_LOGICAL_OPERATOR, 'option');


export const DOWNLOADING_QUIZ_RESPONSES = 'DOWNLOADING_QUIZ_RESPONSES';
export const downloadingQuizResponses = makeActionCreator(DOWNLOADING_QUIZ_RESPONSES, 'status');

export const SET_QUIZ_RESPONSES = 'SET_QUIZ_RESPONSES';
export const setQuizResponses = makeActionCreator(SET_QUIZ_RESPONSES, 'data');

export const ADD_QUIZ_RESPONSE = 'ADD_QUIZ_RESPONSE';
export const addQuizResponse = makeActionCreator(ADD_QUIZ_RESPONSE, 'data');

export const CLEAR_QUIZ_RESPONSES = 'CLEAR_QUIZ_RESPONSES';
export const clearQuizResponses = makeActionCreator(CLEAR_QUIZ_RESPONSES);

export const DOWNLOADING_DELETING_QUIZ_RESPONSE = 'DOWNLOADING_DELETING_QUIZ_RESPONSE';
export const downloadingDeletingQuizResponse = makeActionCreator(DOWNLOADING_DELETING_QUIZ_RESPONSE, 'status');

export const SET_QUIZ_RESPONSE_SELECTED_PAGE_SIZE = 'SET_QUIZ_RESPONSE_SELECTED_PAGE_SIZE';
export const setQuizResponseSelectedPageSize = makeActionCreator(SET_QUIZ_RESPONSE_SELECTED_PAGE_SIZE, 'size');

export const SET_QUIZ_RESPONSE_SELECTED_ORDER_BY = 'SET_QUIZ_QUESTION_SELECTED_ORDER_BY';
export const setQuizResponseSelectedOrderBy = makeActionCreator(SET_QUIZ_QUESTION_SELECTED_ORDER_BY, 'order');

export const SET_QUIZ_RESPONSE_TAPPED_ID = 'SET_QUIZ_QUESTION_TAPPED_ID';
export const setQuizResponseTappedId = makeActionCreator(SET_QUIZ_QUESTION_TAPPED_ID, 'id');

export const SET_QUIZ_RESPONSE_SEARCH_TEXT = 'SET_QUIZ_QUESTION_SEARCH_TEXT';
export const setQuizResponseSearchText = makeActionCreator(SET_QUIZ_QUESTION_SEARCH_TEXT, 'text');

export const SENDING_QUIZ_NOTIFICATION = 'SENDING_QUIZ_NOTIFICATION';
export const sendingQuizNotification = makeActionCreator(SENDING_QUIZ_NOTIFICATION, 'status');

export const SENDING_SURVEY_NOTIFICATION = 'SENDING_SURVEY_NOTIFICATION';
export const sendingSurveyNotification = makeActionCreator(SENDING_SURVEY_NOTIFICATION, 'status');
