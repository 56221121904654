import { downloadingProvinces, setProvince } from '../simple-action';
import { downloadProvinces, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, provinces, uiProvince } = getState();
  const { token } = authentication;
  const { meta } = provinces;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiProvince;

  try {
    dispatch(downloadingProvinces(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProvinces(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setProvince(list));
    }
  } finally {
    dispatch(downloadingProvinces(false));
  }
};
