import { downloadPromotedMerchants, transformSearchText } from '../../../helper';
import { downloadingPromotedMerchants, setPromotedMerchants } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, promotedMerchants, uiPromotedMerchant,
    uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = promotedMerchants;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiPromotedMerchant;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(downloadingPromotedMerchants(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPromotedMerchants(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setPromotedMerchants(list));
    }
  } finally {
    dispatch(downloadingPromotedMerchants(false));
  }
};
