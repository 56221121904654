import {
  addIndustry, clearIndustries, downloadingDeletingIndustry, setAlertErrorMessage,
  setFunctionalPageMode, setIndustryTappedId,
} from '../simple-action';
import { downloadIndustry, deleteIndustry } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadIndustriesAsync from './downloadIndustriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingIndustry(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiIndustry;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteIndustry(tappedId, token);
      dispatch(setIndustryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearIndustries());
      dispatch(downloadIndustriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadIndustry(tappedId, token);
      dispatch(addIndustry(result));
    }
  } finally {
    dispatch(downloadingDeletingIndustry(false));
  }
};
