import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  firstStyle: {
    width: (props) => props.width,
  },
}));

const HorizontalSpacer = ({ ...props }) => {
  const { firstStyle } = useStyles(props);
  return (
    <div className={`${firstStyle}`} />
  );
};

export default HorizontalSpacer;

HorizontalSpacer.propTypes = {
  width: PropTypes.number.isRequired,
};
