import {
  DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY,
  DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY,
  DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS,
  DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND,
  DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND,
  SET_ATTENDANCE_CLOCK_IN_STATUS_TREND_TOOLTIP_TARGET,
  SET_ATTENDANCE_CLOCK_OUT_STATUS_TREND_TOOLTIP_TARGET,
  SET_ATTENDANCE_CLOCK_IN_LOCATION_TREND_TOOLTIP_TARGET,
  SET_ATTENDANCE_CLOCK_OUT_LOCATION_TREND_TOOLTIP_TARGET,
  DOWNLOADING_ATTENDANCE_SUMMARY_WORK_LOCATION,
  SET_ATTENDANCE_SUMMARY_ACTIVE_TAB,
  SET_ATTENDANCE_SUMMARY_SELECTED_PERIOD,
} from '../action';

const initialState = {
  activeTab: 0,
  selectedPeriod: 'Today',
  downloadingWorkLocation: false,
  downloadingTotalClockInToday: false,
  downloadingTotalClockOutToday: false,
  downloadingTodayClockInStatus: false,
  downloadingTodayClockOutStatus: false,
  downloadingClockInStatusTrend: false,
  downloadingClockOutStatusTrend: false,
  downloadingClockInLocationTrend: false,
  downloadingClockOutLocationTrend: false,
  clockInStatusTrendTargetItem: null,
  clockOutStatusTrendTargetItem: null,
  clockInLocationTrendTargetItem: null,
  clockOutLocationTrendTargetItem: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ATTENDANCE_SUMMARY_ACTIVE_TAB:
      return { ...state, activeTab: action.tab };
    case SET_ATTENDANCE_SUMMARY_SELECTED_PERIOD:
      return { ...state, selectedPeriod: action.text };
    case DOWNLOADING_ATTENDANCE_SUMMARY_WORK_LOCATION:
      return { ...state, downloadingWorkLocation: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY:
      return { ...state, downloadingTotalClockInToday: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY:
      return { ...state, downloadingTotalClockOutToday: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS:
      return { ...state, downloadingTodayClockInStatus: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS:
      return { ...state, downloadingTodayClockOutStatus: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND:
      return { ...state, downloadingClockInStatusTrend: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND:
      return { ...state, downloadingClockOutStatusTrend: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND:
      return { ...state, downloadingClockInLocationTrend: action.status };
    case DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND:
      return { ...state, downloadingClockOutLocationTrend: action.status };
    case SET_ATTENDANCE_CLOCK_IN_STATUS_TREND_TOOLTIP_TARGET:
      return { ...state, clockInStatusTrendTargetItem: action.targetItem };
    case SET_ATTENDANCE_CLOCK_OUT_STATUS_TREND_TOOLTIP_TARGET:
      return { ...state, clockOutStatusTrendTargetItem: action.targetItem };
    case SET_ATTENDANCE_CLOCK_IN_LOCATION_TREND_TOOLTIP_TARGET:
      return { ...state, clockInLocationTrendTargetItem: action.targetItem };
    case SET_ATTENDANCE_CLOCK_OUT_LOCATION_TREND_TOOLTIP_TARGET:
      return { ...state, clockOutLocationTrendTargetItem: action.targetItem };
    default: return state;
  }
};
