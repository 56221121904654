import {
  DOWNLOADING_CONFIG_ITEMS, ADDING_EDITING_CONFIG_ITEM, DOWNLOADING_DELETING_CONFIG_ITEM,
  SET_CONFIG_ITEM_SELECTED_PAGE_SIZE, SET_CONFIG_ITEM_TAPPED_ID, SET_CONFIG_ITEM_SEARCH_TEXT,
  SET_CONFIG_ITEM_SELECTED_ORDER_BY, SET_CONFIG_ITEM_SELECTED_CONFIG_GROUP,
  SET_CONFIG_ITEM_SELECTED_DATA_TYPE, SET_CONFIG_ITEM_SELECTED_USED_ON,
  SET_CONFIG_ITEM_DULICATE_MODE, SET_CONFIG_ITEM_SELECTED_MULTILINE,
  SET_CONFIG_ITEM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  UPLOADING_CONFIG_ITEM_VALUE_FILE,
} from '../action';
import { INITIAL_ORDER_BY_CONFIG_ITEMS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_CONFIG_ITEMS,
  selectedConfigGroup: '',
  selectedDataType: '',
  selectedMultiline: '',
  selectedUsedOn: '',
  duplicateMode: false,
  filterString: '',
  uploadingConfigItemValueFile: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CONFIG_ITEMS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_CONFIG_ITEM:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_CONFIG_ITEM:
      return { ...state, downloadingDeleting: action.status };
    case UPLOADING_CONFIG_ITEM_VALUE_FILE:
      return { ...state, uploadingConfigItemValueFile: action.status };
    case SET_CONFIG_ITEM_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CONFIG_ITEM_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CONFIG_ITEM_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CONFIG_ITEM_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CONFIG_ITEM_SELECTED_USED_ON:
      return { ...state, selectedUsedOn: action.option };
    case SET_CONFIG_ITEM_SELECTED_DATA_TYPE:
      return { ...state, selectedDataType: action.option };
    case SET_CONFIG_ITEM_SELECTED_CONFIG_GROUP:
      return { ...state, selectedConfigGroup: action.option };
    case SET_CONFIG_ITEM_DULICATE_MODE:
      return { ...state, duplicateMode: action.status };
    case SET_CONFIG_ITEM_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_CONFIG_ITEM_SELECTED_MULTILINE:
      return { ...state, selectedMultiline: action.option };
    default: return state;
  }
};
