import { reset } from 'redux-form';
import {
  addingEditingBanner, clearBanners, setAlertErrorMessage, setFunctionalPageMode,
  setBannerSelectedClickAction, setBannerShortDescriptionLength,
  setBannerSelectedCompany,
} from '../simple-action';
import { addBanner, editBanner, toNumber } from '../../../helper';
import {
  CLICK_ACTION_NONE, CLICK_ACTION_OPEN_URL, CLICK_ACTION_SHOW_BANNER_BODY, PAGE_MODE_TABLE,
  RXFORM_BANNER,
} from '../../../constant';
import downloadBannersAsync from './downloadBannersAsync';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

export default (title, body, url, clickAction, validFrom, validUntil, androidAppId, iosAppId,
  downloadUrl, order, media, shortDescription, tags) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingBanner(true));

    const { token } = getState().authentication;
    const { tappedId, selectedClickAction, selectedCompany } = getState().uiBanner;

    const medias = media.length > 0 ? transformMedia(media) : [];
    const orders = toNumber(order);
    const companyId = selectedCompany?.value || null;

    let bodyRequest = {};
    switch (selectedClickAction) {
      case CLICK_ACTION_OPEN_URL: {
        bodyRequest = {
          title,
          clickAction,
          url,
          validFrom,
          validUntil,
          order: orders,
          media: medias,
          androidAppId,
          iosAppId,
          downloadUrl,
          shortDescription,
          tags,
          companyId,
        };
        break;
      }
      case CLICK_ACTION_SHOW_BANNER_BODY: {
        bodyRequest = {
          title,
          clickAction,
          body,
          validFrom,
          validUntil,
          order: orders,
          media: medias,
          androidAppId,
          iosAppId,
          downloadUrl,
          shortDescription,
          tags,
          companyId,
        };
        break;
      }
      default: {
        bodyRequest = {
          title,
          clickAction,
          validFrom,
          validUntil,
          order: orders,
          media: medias,
          androidAppId,
          iosAppId,
          downloadUrl,
          shortDescription,
          tags,
          companyId,
        };
        break;
      }
    }

    if (tappedId) {
      await editBanner(tappedId, bodyRequest, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addBanner(bodyRequest, token);
    }

    dispatch(reset(RXFORM_BANNER));
    dispatch(clearBanners());
    dispatch(downloadBannersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setBannerSelectedClickAction(CLICK_ACTION_NONE));
    dispatch(setBannerShortDescriptionLength(0));
    dispatch(setBannerSelectedCompany(null));
  } finally {
    dispatch(addingEditingBanner(false));
  }
};
