import { TextField, withStyles } from '@material-ui/core';

const NumberTextFieldWithoutArrowDial = withStyles(() => ({
  root: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}))(TextField);

export default NumberTextFieldWithoutArrowDial;
