import { downloadingOsVersions, setOsVersions } from '../simple-action';
import { downloadOsVersions, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { osVersionSearchText } = getState().uiToken;

  try {
    dispatch(downloadingOsVersions(true));

    const list = await downloadOsVersions(
      transformSearchText(osVersionSearchText),
      token,
    );

    if (list) {
      dispatch(setOsVersions(list));
    }
  } finally {
    dispatch(downloadingOsVersions(false));
  }
};
