import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, withStyles,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { AccentButton, FunctionalPage } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_DISABLED_ROW, COLOR_DANGEROUS, COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY,
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE, PACKET_STATUS,
  PACKET_STATUS_PICKED_UP, PACKET_PICKED_UP_BY_NON_USER, PACKET_PICKED_UP_BY_RECIPIENT,
  PACKET_PICKED_UP_BY_USER, PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_PACKET_ARRIVAL_IMAGE,
  RXFIELD_PACKET_ARRIVAL_NOTES, RXFIELD_PACKET_HANDED_BY, RXFIELD_PACKET_PACKET_ID,
  RXFIELD_PACKET_PACKET_STATUS, RXFIELD_PACKET_PACKET_TYPE, RXFIELD_PACKET_PICK_UP_BY,
  RXFIELD_PACKET_PICKED_UP_BY, RXFIELD_PACKET_PICKED_UP_BY_NON_USER, RXFIELD_PACKET_PICKED_UP_IMAGE,
  RXFIELD_PACKET_PICKED_UP_NOTES, RXFIELD_PACKET_PICKED_UP_TIME, RXFIELD_PACKET_PICKUP_LOCATION,
  RXFIELD_PACKET_RECEIVED_BY, RXFIELD_PACKET_RECIPIENT, RXFIELD_PACKET_SENDER,
  RXFIELD_PACKET_VALID_UNTIL, RXFORM_PACKET, RXSTATE_PACKETS, RXSTATE_PACKET_PAGE, STATUS_DISABLED,
  UPLOAD_IMAGE_METHOD_SELECT_FILE, UPLOAD_IMAGE_METHOD_TAKE_PICTURE,
} from '../../constant';
import { toMoment } from '../../helper';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormDateTimePickerField, renderReduxFormImageInputField,
  renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormRadioButtonField, renderReduxFormTakePictureField,
} from '../../../../redux-form-rendererer';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { rxformValidatePacket } from '../../validation';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
  uploadImageContainer: {
    padding: '8px 8px 0px 8px',
  },
}));

const renderUploadImageMethod = (
  uploadImageMethod, addingEditing, downloadingDeleting, pageMode,
  onImageSelected, initialValues, fieldName, label, pickingUp,
) => {
  const arrivalField = fieldName === RXFIELD_PACKET_ARRIVAL_IMAGE;
  const additionalDisableCondition = arrivalField ? pageMode === PAGE_MODE_VIEW : pickingUp;
  const defaultValue = arrivalField ? initialValues.arrivalImage : '';

  if (uploadImageMethod === UPLOAD_IMAGE_METHOD_SELECT_FILE) {
    return (
      <Field
        name={fieldName}
        component={renderReduxFormImageInputField}
        label={label}
        disabled={addingEditing || downloadingDeleting || additionalDisableCondition}
        onImageSelected={onImageSelected}
        onBlur={(e) => e.preventDefault()}
        defaultValue={defaultValue}
        useFullWidthImage
        useAvatarWithoutCropper
        fullImageWidth="100%"
        useCropper={false}
      />
    );
  }
  return (
    <Field
      name={fieldName}
      component={renderReduxFormTakePictureField}
      label={label}
      disabled={addingEditing || downloadingDeleting || additionalDisableCondition}
      onPictureTaken={onImageSelected}
      onBlur={(e) => e.preventDefault()}
    />
  );
};

const renderToggleUploadMethodButtons = (classes, uploadImageMethod, onUploadImageMethodChange) => (
  <Grid item className={classes.uploadImageContainer}>
    <ToggleButtonGroup
      value={uploadImageMethod}
      onChange={onUploadImageMethodChange}
      exclusive
    >
      <ToggleButton
        value={UPLOAD_IMAGE_METHOD_SELECT_FILE}
        disabled={uploadImageMethod === UPLOAD_IMAGE_METHOD_SELECT_FILE}
      >
        {LocalizedString.packetScreen.buttonCaptionUploadFile}
      </ToggleButton>
      <ToggleButton
        value={UPLOAD_IMAGE_METHOD_TAKE_PICTURE}
        disabled={uploadImageMethod === UPLOAD_IMAGE_METHOD_TAKE_PICTURE}
      >
        {LocalizedString.packetScreen.buttonCaptionCamera}
      </ToggleButton>
    </ToggleButtonGroup>
  </Grid>
);

const renderDialogContent = (initialValues, recipients, packetTypes, pickupLocations,
  addingEditing, downloadingDeleting, loadingPacketType, loadingPickupLocation, loadingRecipient,
  onArrivalImageSelected, onChangePacketTypeText, onChangePickupLocationText,
  onChangeRecipientText, onPacketTypeOptionSelected, onPickupLocationOptionSelected,
  onRecipientOptionSelected, pageMode, onUploadImageMethodChange, uploadImageMethod,
  classes) => {
  if (pageMode === PAGE_MODE_VIEW) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PACKET_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.packetScreen.placeholderPacketId}
              label={LocalizedString.packetScreen.placeholderPacketId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_SENDER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.packetScreen.placeholderSender}
              label={LocalizedString.packetScreen.placeholderSender}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_RECIPIENT}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.packetScreen.placeholderRecipient}
              label={LocalizedString.packetScreen.placeholderRecipient}
              disabled
              data={recipients}
              value={initialValues.recipient}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_RECEIVED_BY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.packetScreen.placeholderReceivedBy}
              label={LocalizedString.packetScreen.placeholderReceivedBy}
              disabled
              data={recipients}
              value={initialValues.receivedBy}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKUP_LOCATION}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.packetScreen.placeholderPickupLocation}
              label={LocalizedString.packetScreen.placeholderPickupLocation}
              disabled
              data={pickupLocations}
              value={initialValues.pickupLocation}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PACKET_TYPE}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.packetScreen.placeholderPacketType}
              label={LocalizedString.packetScreen.placeholderPacketType}
              disabled
              data={packetTypes}
              value={initialValues.packetType}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_ARRIVAL_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.packetScreen.placeholderArrivalNotes}
              label={LocalizedString.packetScreen.placeholderArrivalNotes}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_ARRIVAL_IMAGE}
              component={renderReduxFormImageInputField}
              label={LocalizedString.packetScreen.placeholderArrivalImage}
              disabled
              defaultValue={initialValues.arrivalImage}
              useFullWidthImage
              fullImageWidth="100%"
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.packetScreen.placeholderValidUntil}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PACKET_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.packetScreen.placeholderPacketStatus}
              label={LocalizedString.packetScreen.placeholderPacketStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKED_UP_BY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.packetScreen.placeholderPickedUpBy}
              label={LocalizedString.packetScreen.placeholderPickedUpBy}
              disabled
              data={recipients}
              value={initialValues.pickedUpBy}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKED_UP_BY_NON_USER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.packetScreen.placeholderPickedUpByNonUser}
              label={LocalizedString.packetScreen.placeholderPickedUpByNonUser}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_HANDED_BY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.packetScreen.placeholderHandedBy}
              label={LocalizedString.packetScreen.placeholderHandedBy}
              disabled
              data={recipients}
              value={initialValues.handedBy}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKED_UP_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.packetScreen.placeholderPickedUpTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKED_UP_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.packetScreen.placeholderPickedUpNotes}
              label={LocalizedString.packetScreen.placeholderPickedUpNotes}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKED_UP_IMAGE}
              component={renderReduxFormImageInputField}
              label={LocalizedString.packetScreen.placeholderPickedupImage}
              disabled
              defaultValue={initialValues.pickedUpImage}
              useFullWidthImage
              fullImageWidth="100%"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PACKET_SENDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.packetScreen.placeholderSender}
            label={LocalizedString.packetScreen.placeholderSender}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PACKET_RECIPIENT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.packetScreen.placeholderRecipient}
            label={LocalizedString.packetScreen.placeholderRecipient}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={recipients}
            value={initialValues.recipient}
            loading={loadingRecipient}
            onChangeText={onChangeRecipientText}
            onOptionSelected={onRecipientOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PACKET_PICKUP_LOCATION}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.packetScreen.placeholderPickupLocation}
            label={LocalizedString.packetScreen.placeholderPickupLocation}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={pickupLocations}
            value={initialValues.pickupLocation}
            loading={loadingPickupLocation}
            onChangeText={onChangePickupLocationText}
            onOptionSelected={onPickupLocationOptionSelected}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PACKET_PACKET_TYPE}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.packetScreen.placeholderPacketType}
            label={LocalizedString.packetScreen.placeholderPacketType}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={packetTypes}
            value={initialValues.packetType}
            loading={loadingPacketType}
            onChangeText={onChangePacketTypeText}
            onOptionSelected={onPacketTypeOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PACKET_ARRIVAL_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.packetScreen.placeholderArrivalNotes}
            label={LocalizedString.packetScreen.placeholderArrivalNotes}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        {renderToggleUploadMethodButtons(classes, uploadImageMethod, onUploadImageMethodChange)}
        <Grid item className={classes.uploadImageContainer}>
          {renderUploadImageMethod(
            uploadImageMethod, addingEditing, downloadingDeleting, pageMode,
            onArrivalImageSelected, initialValues, RXFIELD_PACKET_ARRIVAL_IMAGE,
            LocalizedString.packetScreen.placeholderArrivalImage,
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};


const renderPickUpDialogContent = (initialValues, recipients, loadingRecipient, addingEditing,
  downloadingDeleting, pickingUp, onChangePickedUpByUserText, onPickedUpByOptionSelected,
  onPickedUpByUserOptionSelected, onPickedUpImageSelected, selectedPickedUpBy, classes,
  uploadImageMethod, pageMode, onUploadImageMethodChange) => {
  const PICKED_UP_BY = [
    {
      label: initialValues.recipient.label,
      value: PACKET_PICKED_UP_BY_RECIPIENT,
    },
    {
      label: LocalizedString.packetScreen.labelAnotherUser,
      value: PACKET_PICKED_UP_BY_USER,
    },
    {
      label: LocalizedString.packetScreen.labelOthers,
      value: PACKET_PICKED_UP_BY_NON_USER,
    },
  ];

  const renderPickedUpByField = (option) => {
    switch (option) {
      case PACKET_PICKED_UP_BY_USER:
        return (
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKED_UP_BY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.packetScreen.placeholderPickedUpBy}
              label={LocalizedString.packetScreen.placeholderPickedUpBy}
              disabled={addingEditing || downloadingDeleting || pickingUp}
              data={recipients}
              loading={loadingRecipient}
              onChangeText={onChangePickedUpByUserText}
              onOptionSelected={onPickedUpByUserOptionSelected}
              required={option === PACKET_PICKED_UP_BY_USER}
            />
          </Grid>
        );
      case PACKET_PICKED_UP_BY_NON_USER:
        return (
          <Grid item>
            <Field
              name={RXFIELD_PACKET_PICKED_UP_BY_NON_USER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.packetScreen.placeholderPickedUpByNonUser}
              label={LocalizedString.packetScreen.placeholderPickedUpByNonUser}
              disabled={addingEditing || downloadingDeleting || pickingUp}
              required={option === PACKET_PICKED_UP_BY_NON_USER}
            />
          </Grid>
        );
      default: return null;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PACKET_PICK_UP_BY}
            component={renderReduxFormRadioButtonField}
            label={LocalizedString.packetScreen.placeholderPickedUpBy}
            defaultValue={PICKED_UP_BY[0]}
            data={PICKED_UP_BY}
            onOptionSelected={onPickedUpByOptionSelected}
            disabled={addingEditing || downloadingDeleting || pickingUp}
          />
        </Grid>
        {renderPickedUpByField(selectedPickedUpBy.value)}
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PACKET_PICKED_UP_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.packetScreen.placeholderPickedUpNotes}
            label={LocalizedString.packetScreen.placeholderPickedUpNotes}
            disabled={addingEditing || downloadingDeleting || pickingUp}
            multiline
          />
        </Grid>
        {renderToggleUploadMethodButtons(classes, uploadImageMethod, onUploadImageMethodChange)}
        <Grid item className={classes.uploadImageContainer}>
          {renderUploadImageMethod(
            uploadImageMethod, addingEditing, downloadingDeleting, pageMode,
            onPickedUpImageSelected, initialValues, RXFIELD_PACKET_PICKED_UP_IMAGE,
            LocalizedString.packetScreen.placeholderPickedupImage,
            pickingUp,
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PacketPage = ({
  initialValues, packetTypes, pickupLocations, receivedBys, recipients,
  addingEditing, downloading, downloadingDeleting, loadingPacketType, loadingPickupLocation,
  loadingRecievedBy, loadingRecipient, pickupDialogVisibility, pickingUp,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onArrivalImageSelected, onCancelAdvancedFilterPressed, onCancelPickupPressed, onCancelPressed,
  onChangePacketTypeText, onChangePage, onChangePageSize, onChangePickedUpByUserText,
  onChangePickupLocationText, onChangeReceivedByText, onChangeRecipientText, onConfirmDeletePressed,
  onConfirmPickupPressed, onCreatePressed, onDeletePressed, onDownloadPressed, onEditPressed,
  onRefresh, onPacketTypeOptionSelected, onPickedUpByOptionSelected, onPickedUpByUserOptionSelected,
  onPickedUpImageSelected, onPickupLocationOptionSelected, onPickupPressed,
  onReceivedByOptionSelected, onRecipientOptionSelected, onResetAdvancedFilterPressed,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  classes, currentPacket, selectedPickedUpBy,
  pageMode, tappedId, onUploadImageMethodChange, uploadImageMethod,
}) => (
  <div>
    <Dialog
      open={pickupDialogVisibility}
      maxWidth="lg"
      classes={{ paper: classes.paper }}
    >
      <div className={classes.headerContainer}>
        <DialogTitle>{LocalizedString.packetScreen.labelPickupPacket}</DialogTitle>
      </div>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onConfirmPickupPressed)}
          className={classes.form}
        >
          {renderPickUpDialogContent(initialValues, recipients, loadingRecipient, addingEditing,
            downloadingDeleting, pickingUp, onChangePickedUpByUserText, onPickedUpByOptionSelected,
            onPickedUpByUserOptionSelected, onPickedUpImageSelected, selectedPickedUpBy, classes,
            uploadImageMethod, pageMode, onUploadImageMethodChange)}

          <DialogActions className={classes.buttonContainer}>
            <AccentButton
              onClick={onCancelPickupPressed}
              variant="text"
              caption={GlobalLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              disabled={addingEditing || downloadingDeleting || pickingUp}
            />
            <AccentButton
              variant="contained"
              type="submit"
              className={classes.button}
              loading={pickingUp}
              disableElevation
              caption={LocalizedString.packetScreen.buttonCaptionPickup}
              disabled={addingEditing || downloadingDeleting}
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>

    <FunctionalPage
      data={RXSTATE_PACKETS}
      uiPage={RXSTATE_PACKET_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.packetScreen.buttonCaptionPickup,
          disabled: currentPacket.status === STATUS_DISABLED
        || currentPacket.packetStatus === PACKET_STATUS_PICKED_UP || pickingUp,
          onPress: () => onPickupPressed(tappedId),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.packetScreen.labelRecipient,
          field: RXFIELD_PACKET_RECIPIENT,
          type: FILTER_TYPE_DROPDOWN,
          data: recipients,
          loading: loadingRecipient,
          onChangeFilterText: onChangeRecipientText,
          onFilterOptionSelected: onRecipientOptionSelected,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.packetScreen.labelReceivedBy,
          field: RXFIELD_PACKET_RECEIVED_BY,
          type: FILTER_TYPE_DROPDOWN,
          data: receivedBys,
          loading: loadingRecievedBy,
          onChangeFilterText: onChangeReceivedByText,
          onFilterOptionSelected: onReceivedByOptionSelected,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.packetScreen.labelDocumentType,
          field: RXFIELD_PACKET_PACKET_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: packetTypes,
          loading: loadingPacketType,
          onChangeFilterText: onChangePacketTypeText,
          onFilterOptionSelected: onPacketTypeOptionSelected,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.packetScreen.labelPickupLocation,
          field: RXFIELD_PACKET_PICKUP_LOCATION,
          type: FILTER_TYPE_DROPDOWN,
          data: pickupLocations,
          loading: loadingPickupLocation,
          onChangeFilterText: onChangePickupLocationText,
          onFilterOptionSelected: onPickupLocationOptionSelected,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.packetScreen.labelPacketStatus,
          field: RXFIELD_PACKET_PACKET_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: PACKET_STATUS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.packetScreen.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.packetScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.packetScreen.labelPacketId, field: 'packetId', sorting: !downloading },
        { title: LocalizedString.packetScreen.labelSender, field: 'sender', sorting: !downloading },
        { title: LocalizedString.packetScreen.labelRecipient, field: 'recipient.fullName', sorting: !downloading },
        { title: LocalizedString.packetScreen.labelReceivedBy, field: 'receivedBy.fullName', sorting: !downloading },
        { title: LocalizedString.packetScreen.labelPacketStatus, field: 'packetStatus', sorting: !downloading },
        {
          title: LocalizedString.packetScreen.labelValidUntil,
          field: 'validUntil',
          sorting: !downloading,
          render: ({ validUntil }) => (validUntil
            ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmDeletePressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onDownloadPressed={onDownloadPressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      addDialogSubmitButtonCaption={GlobalLocalizedString.common.buttonCaptionSave}
      createNewButtonCaption={LocalizedString.packetScreen.buttonCaptionReceivePacket}
      deleteButtonCaption={LocalizedString.packetScreen.buttonCaptionDeletePacket}
      editButtonCaption={LocalizedString.packetScreen.buttonCaptionEditPacket}
      title={LocalizedString.packetScreen.title}
      enableSave
      useFullWidth
      useFullWidthDialog
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
    >
      {renderDialogContent(initialValues, recipients, packetTypes, pickupLocations,
        addingEditing, downloadingDeleting, loadingPacketType, loadingPickupLocation,
        loadingRecipient, onArrivalImageSelected, onChangePacketTypeText,
        onChangePickupLocationText, onChangeRecipientText, onPacketTypeOptionSelected,
        onPickupLocationOptionSelected, onRecipientOptionSelected, pageMode,
        onUploadImageMethodChange, uploadImageMethod, classes)}
    </FunctionalPage>
  </div>
);

export default withStyles(styles)(reduxForm({
  form: RXFORM_PACKET,
  validate: rxformValidatePacket,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PacketPage));

PacketPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  packetTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  pickupLocations: PropTypes.arrayOf(SimpleDataShape).isRequired,
  receivedBys: PropTypes.arrayOf(SimpleDataShape).isRequired,
  recipients: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingPacketType: PropTypes.bool.isRequired,
  loadingPickupLocation: PropTypes.bool.isRequired,
  loadingRecievedBy: PropTypes.bool.isRequired,
  loadingRecipient: PropTypes.bool.isRequired,
  pickupDialogVisibility: PropTypes.bool.isRequired,
  pickingUp: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onArrivalImageSelected: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPickupPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePacketTypeText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangePickedUpByUserText: PropTypes.func.isRequired,
  onChangePickupLocationText: PropTypes.func.isRequired,
  onChangeReceivedByText: PropTypes.func.isRequired,
  onChangeRecipientText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onConfirmPickupPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onPacketTypeOptionSelected: PropTypes.func.isRequired,
  onPickedUpByOptionSelected: PropTypes.func.isRequired,
  onPickedUpByUserOptionSelected: PropTypes.func.isRequired,
  onPickedUpImageSelected: PropTypes.func.isRequired,
  onPickupLocationOptionSelected: PropTypes.func.isRequired,
  onPickupPressed: PropTypes.func.isRequired,
  onReceivedByOptionSelected: PropTypes.func.isRequired,
  onRecipientOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentPacket: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string,
    PropTypes.object, PropTypes.number])).isRequired,
  selectedPickedUpBy: PropTypes.oneOfType([PropTypes.string,
    PropTypes.object]).isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  onUploadImageMethodChange: PropTypes.func.isRequired,
  uploadImageMethod: PropTypes.string.isRequired,
};
