import {
  addGameQRCategory, clearGameQRCategories, downloadingDeletingGameQRCategory, setAlertErrorMessage,
  setFunctionalPageMode, setGameQRCategoryTappedId,
} from '../simple-action';
import { downloadGameQRCategory, deleteGameQRCategory } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadGameQRCategoriesAsync from './downloadGameQRCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingGameQRCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGameQRCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteGameQRCategory(tappedId, token);
      dispatch(setGameQRCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearGameQRCategories());
      dispatch(downloadGameQRCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const gameQRCategory = await downloadGameQRCategory(tappedId, token);
      dispatch(addGameQRCategory(gameQRCategory));
    }
  } finally {
    dispatch(downloadingDeletingGameQRCategory(false));
  }
};
