import { SET_FONT, CLEAR_FONT } from '../action';

const initialState = {
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FONT: {
      return {
        data: { ...action.data },
      };
    }
    case CLEAR_FONT:
      return initialState;
    default:
      return state;
  }
};
