import { downloadingAppVersions, setAppVersions } from '../simple-action';
import { downloadAppVersions, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { appVersionSearchText } = getState().uiToken;

  try {
    dispatch(downloadingAppVersions(true));

    const list = await downloadAppVersions(
      transformSearchText(appVersionSearchText),
      token,
    );

    if (list) {
      dispatch(setAppVersions(list));
    }
  } finally {
    dispatch(downloadingAppVersions(false));
  }
};
