import {
  addSurveyQuestion, clearSurveyQuestions, downloadingDeletingSurveyQuestion,
  setAlertErrorMessage, setFunctionalPageMode, setSurveyQuestionTappedId,
  setSurveyQuestionSelectedFieldType, setSurveyQuestionSelectedParent,
  setSurveyQuestionSelectedSurvey,
} from '../simple-action';
import { downloadSurveyQuestion, deleteSurveyQuestion } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadSurveyQuestionsAsync from './downloadSurveyQuestionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSurveyQuestion(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSurveyQuestion;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSurveyQuestion(tappedId, token);
      dispatch(setSurveyQuestionTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSurveyQuestions());
      dispatch(downloadSurveyQuestionsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const surveyQuestion = await downloadSurveyQuestion(tappedId, token);
      const { fieldType, parent, survey } = surveyQuestion;
      dispatch(setSurveyQuestionSelectedSurvey(survey ? { label: survey.title, value: survey.id } : ''));
      dispatch(setSurveyQuestionSelectedFieldType(fieldType));
      dispatch(setSurveyQuestionSelectedParent(parent ? { label: parent.name, value: parent.id } : ''));
      dispatch(addSurveyQuestion(surveyQuestion));
    }
  } finally {
    dispatch(downloadingDeletingSurveyQuestion(false));
  }
};
