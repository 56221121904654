import { downloadingEmails, setEmails } from '../simple-action';
import { downloadEmails, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, emails, uiEmail } = getState();
  const { token } = authentication;
  const { meta, data } = emails;
  const { selectedPageSize, searchBarText, orderBy } = uiEmail;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingEmails(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadEmails(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setEmails(list));
    }
  } finally {
    dispatch(downloadingEmails(false));
  }
};
