import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  RXFORM_VOUCHER_PURCHASE_REQUEST, RXSTATE_VOUCHER_PURCHASE_REQUESTS,
  PICKER_MODE_DATE_TIME,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  COLOR_DISABLED_ROW, COLOR_BACKGROUND, RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_TOKEN_ID,
  RXFIELD_VOUCHER_PURCHASE_REQUEST_CREATED_DATE,
  RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER,
  RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_IMAGE,
  RXSTATE_VOUCHER_PURCHASE_REQUEST_PAGE, RXFIELD_VOUCHER_PURCHASE_REQUEST_USER,
  STATUS_DISABLED, RXFIELD_VOUCHER_PURCHASE_REQUEST_PAYMENT_ID,
  RXFIELD_VOUCHER_PURCHASE_REQUEST_REQUEST_STATUS, RXFIELD_VOUCHER_PURCHASE_REQUEST_STATUS_NOTES,
  RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_ID, RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_NAME,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { SUBMISSION_STATUS_VALUES } from '../../../point';

const renderDialogContent = (
  initialValues,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_USER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherPurchaseRequestPage.placeholderUser}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderUser}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_TOKEN_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherPurchaseRequestPage.placeholderVoucherTokenId}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderVoucherTokenId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_PAYMENT_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherPurchaseRequestPage.placeholderPaymentId}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderPaymentId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_REQUEST_STATUS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherPurchaseRequestPage.placeholderRequestStatus}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderRequestStatus}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_STATUS_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherPurchaseRequestPage.placeholderRequestStatusNotes}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderRequestStatusNotes}
          disabled
          multiline
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherPurchaseRequestPage.placeholderVoucherId}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderVoucherId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherPurchaseRequestPage.placeholderVoucherName}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderVoucherName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.voucherPurchaseRequestPage.placeholderVoucherImage}
          disabled
          defaultValue={initialValues.voucherImage}
          useFullWidthImage
          fullImageWidth="100%"
        />
      </Grid>
    </Grid>
  </Grid>
);

const VoucherPurchaseRequestPage = ({
  downloading,
  handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues,
  vouchers, loadingVoucher, onChangeVoucherText,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUser, onChangeUserText,
}) => (
  <FunctionalPage
    data={RXSTATE_VOUCHER_PURCHASE_REQUESTS}
    uiPage={RXSTATE_VOUCHER_PURCHASE_REQUEST_PAGE}
    filterColumns={[
      {
        title: LocalizedString.voucherPurchaseRequestPage.placeholderCreatedDate,
        field: RXFIELD_VOUCHER_PURCHASE_REQUEST_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherPurchaseRequestPage.placeholderVoucherName,
        field: RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER,
        type: FILTER_TYPE_DROPDOWN,
        data: vouchers,
        loading: loadingVoucher,
        onChangeFilterText: onChangeVoucherText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPurchaseRequestPage.placeholderUser,
        field: RXFIELD_VOUCHER_PURCHASE_REQUEST_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPurchaseRequestPage.placeholderRequestStatus,
        field: RXFIELD_VOUCHER_PURCHASE_REQUEST_REQUEST_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: SUBMISSION_STATUS_VALUES,
        useDropdownValue: true,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    tableColumns={[
      {
        title: LocalizedString.voucherPurchaseRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.voucherPurchaseRequestPage.labelUser, field: 'purchasedBy.fullName', sorting: !downloading },
      { title: LocalizedString.voucherPurchaseRequestPage.labelVoucherName, field: 'voucherToken.voucher.title', sorting: !downloading },
      { title: LocalizedString.voucherPurchaseRequestPage.labelStatus, field: 'requestStatus', sorting: !downloading },
      { title: LocalizedString.voucherPurchaseRequestPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.voucherPurchaseRequestPage.title}
    useFullWidth
    disableCreate
    disableEdit
    disableDelete
    rowStyle={(rowData) => ({
      backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
    })}
  >
    {renderDialogContent(
      initialValues,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_VOUCHER_PURCHASE_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(VoucherPurchaseRequestPage);

VoucherPurchaseRequestPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
};
