import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFORM_FIELD_DESCRIPTION, RXFORM_FIELD_NAME,
  RXFORM_FIELD_TEMPLATE_BODY,
  RXFORM_FIELD_TEMPLATE_MARGIN_BOTTOM,
  RXFORM_FIELD_TEMPLATE_MARGIN_LEFT,
  RXFORM_FIELD_TEMPLATE_MARGIN_RIGHT,
  RXFORM_FIELD_TEMPLATE_MARGIN_TOP,
} from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateDocumentTemplate = (values) => {
  const requiredFields = [
    RXFORM_FIELD_NAME,
    RXFORM_FIELD_DESCRIPTION,
    RXFORM_FIELD_TEMPLATE_BODY,
    RXFORM_FIELD_TEMPLATE_MARGIN_TOP,
    RXFORM_FIELD_TEMPLATE_MARGIN_LEFT,
    RXFORM_FIELD_TEMPLATE_MARGIN_RIGHT,
    RXFORM_FIELD_TEMPLATE_MARGIN_BOTTOM,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
