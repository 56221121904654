import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_REGISTRATIONS = 'DOWNLOADING_REGISTRATIONS';
export const downloadingRegistrations = makeActionCreator(DOWNLOADING_REGISTRATIONS, 'status');

export const SET_REGISTRATIONS = 'SET_REGISTRATIONS';
export const setRegistration = makeActionCreator(SET_REGISTRATIONS, 'data');

export const ADD_REGISTRATION = 'ADD_REGISTRATION';
export const addRegistration = makeActionCreator(ADD_REGISTRATION, 'data');

export const CLEAR_REGISTRATIONS = 'CLEAR_REGISTRATIONS';
export const clearRegistrations = makeActionCreator(CLEAR_REGISTRATIONS);

export const DOWNLOADING_DELETING_REGISTRATION = 'DOWNLOADING_DELETING_REGISTRATION';
export const downloadingDeletingRegistration = makeActionCreator(DOWNLOADING_DELETING_REGISTRATION, 'status');

export const APPROVING_REGISTRATION = 'APPROVING_REGISTRATION';
export const approvingRegistration = makeActionCreator(APPROVING_REGISTRATION, 'status');

export const REJECTING_REGISTRATION = 'REJECTING_REGISTRATION';
export const rejectingRegistration = makeActionCreator(REJECTING_REGISTRATION, 'status');


export const SET_REGISTRATION_SELECTED_PAGE_SIZE = 'SET_REGISTRATION_SELECTED_PAGE_SIZE';
export const setRegistrationSelectedPageSize = makeActionCreator(SET_REGISTRATION_SELECTED_PAGE_SIZE, 'size');

export const SET_REGISTRATION_SELECTED_ORDER_BY = 'SET_REGISTRATION_SELECTED_ORDER_BY';
export const setRegistrationSelectedOrderBy = makeActionCreator(SET_REGISTRATION_SELECTED_ORDER_BY, 'order');

export const SET_REGISTRATION_TAPPED_ID = 'SET_REGISTRATION_TAPPED_ID';
export const setRegistrationTappedId = makeActionCreator(SET_REGISTRATION_TAPPED_ID, 'id');

export const SET_REGISTRATION_SEARCH_TEXT = 'SET_REGISTRATION_SEARCH_TEXT';
export const setRegistrationSearchText = makeActionCreator(SET_REGISTRATION_SEARCH_TEXT, 'text');
