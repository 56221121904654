import {
  addServiceRequest, clearServiceRequests, downloadingDeletingServiceRequest, setAlertErrorMessage,
  setFunctionalPageMode, setServiceRequestTappedId,
} from '../simple-action';
import { downloadServiceRequest, deleteServiceRequest } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadServiceRequestsAsync from './downloadServiceRequestsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingServiceRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiServiceRequest;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteServiceRequest(tappedId, token);
      dispatch(setServiceRequestTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearServiceRequests());
      dispatch(downloadServiceRequestsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const gameQRCategory = await downloadServiceRequest(tappedId, token);
      dispatch(addServiceRequest(gameQRCategory));
    }
  } finally {
    dispatch(downloadingDeletingServiceRequest(false));
  }
};
