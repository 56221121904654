import {
  downloadingDeletingArticle, addArticle, setArticleTappedId,
  clearArticles,
  setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { downloadArticle, deleteArticle } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadArticlesAsync from './downloadArticlesAsync';
import { getDeepLinkScheme } from '../../../../configuration/helper';

const DEEP_LINK_PATH = 'app/articles/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingArticle(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiArticle;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteArticle(tappedId, token);
      dispatch(setArticleTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearArticles());
      dispatch(downloadArticlesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const articles = await downloadArticle(tappedId, token);

      const result = await getDeepLinkScheme(articles, DEEP_LINK_PATH, tappedId, token);

      dispatch(addArticle(result));
    }
  } finally {
    dispatch(downloadingDeletingArticle(false));
  }
};
