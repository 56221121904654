import { homepage } from '../package.json';

export const IS_PRODUCTION = true;

export const REST_BASE_URL = `${window.location.protocol}//${window.location.host}`;
export const REST_URL_LOGIN = '/auth/api/v1/Login';
export const REST_URL_LOGOUT = '/auth/api/v1/Logout';
export const REST_URL_PROVINCES = '/mdata/api/v1/Province?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_CITIES = '/mdata/api/v1/City?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_PROVINCE = '/mdata/api/v1/Province';
export const REST_URL_VIEW_DELETE_PROVINCE = '/mdata/api/v1/Province/{id}';
export const REST_URL_ENABLE_DISABLE_PROVINCE = '/mdata/api/v1/Province/Status';
export const REST_URL_ADD_EDIT_CITY = '/mdata/api/v1/City';
export const REST_URL_VIEW_DELETE_CITY = '/mdata/api/v1/City/{id}';
export const REST_URL_ENABLE_DISABLE_CITY = '/mdata/api/v1/City/Status';
export const REST_URL_BRANCHES = '/mdata/api/v1/Branch?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_BRANCH = '/mdata/api/v1/Branch';
export const REST_URL_VIEW_DELETE_BRANCH = '/mdata/api/v1/Branch/{id}';
export const REST_URL_ENABLE_DISABLE_BRANCH = '/mdata/api/v1/Branch/Status';
export const REST_URL_PERMISSIONS = '/auth/api/v1/Permission?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_PERMISSION = '/auth/api/v1/Permission';
export const REST_URL_VIEW_DELETE_PERMISSION = '/auth/api/v1/Permission/{id}';
export const REST_URL_USERS = '/auth/api/v1/User?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_USER = '/auth/api/v1/User';
export const REST_URL_VIEW_DELETE_USER = '/auth/api/v1/User/{id}';
export const REST_URL_ROLES = '/auth/api/v1/Role?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_ROLE = '/auth/api/v1/Role';
export const REST_URL_VIEW_DELETE_ROLE = '/auth/api/v1/Role/{id}';
export const REST_URL_API_KEYS = '/auth/api/v1/ApiKey?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy},ownerAppName&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_API_KEY = '/auth/api/v1/ApiKey';
export const REST_URL_VIEW_DELETE_API_KEY = '/auth/api/v1/ApiKey/{id}';
export const REST_URL_TOKENS = '/auth/api/v1/Token?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.email|user.phone|user.username|user.fullName|tokenKey|deviceId|deviceManufacturer|deviceModel|timeZone|osName|osVersion|appVersion|acceptLanguage|id&filterString={filterString}';
export const REST_URL_FILTERED_TOKENS = '/auth/api/v1/Token?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled|user.id={userId}|validUntil>{validUntil}';
export const REST_URL_VIEW_DELETE_TOKEN = '/auth/api/v1/Token/{id}';
export const REST_URL_TOKEN_TOTAL_PERSON_COUNT = '/auth/api/v1/Token?pageNumber=1&pageSize=1&filterString=status=enabled|validUntil>={endDate}|{filterString}';
export const REST_URL_APPLICATIONS = '/core/api/v1/Application?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_APPLICATION = '/core/api/v1/Application';
export const REST_URL_VIEW_DELETE_APPLICATION = '/core/api/v1/Application/{id}';
export const REST_URL_IMAGE_STORAGE = '/storage/api/v1/File';
export const REST_URL_PROFILES = '/mdata/api/v1/Profile?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=id|fullName|user.username|user.email|user.phone|employeeId|jobTitle&filterString=';
export const REST_URL_ADD_EDIT_PROFILE = '/mdata/api/v1/Profile';
export const REST_URL_VIEW_DELETE_PROFILE = '/mdata/api/v1/Profile/{id}';
export const REST_URL_ENABLE_DISABLE_PROFILE = '/mdata/api/v1/Profile/Status';
export const REST_URL_SUMMARY_TOTAL_USERS = '/auth/api/v1/Summary/TotalUsers';
export const REST_URL_SUMMARY_ACTIVE_USERS = '/auth/api/v1/Summary/ActiveUsers';
export const REST_URL_SUMMARY_ACTIVE_DEVICES = '/auth/api/v1/Summary/ActiveDevices';
export const REST_URL_SUMMARY_OS_DISTRIBUTION = '/auth/api/v1/Summary/OsDistribution';
export const REST_URL_SUMMARY_LOGIN_USING = '/auth/api/v1/Summary/AccessUsing';
export const REST_URL_SUMMARY_ANDROID_VERSION_DISTRIBUTION = '/auth/api/v1/Summary/AndroidVerDistribution';
export const REST_URL_SUMMARY_IOS_VERSION_DISTRIBUTION = '/auth/api/v1/Summary/IosVerDistribution';
export const REST_URL_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTION = '/auth/api/v1/Summary/DeviceManufacturerDistribution';
export const REST_URL_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTION = '/auth/api/v1/Summary/IosDeviceModelDistribution';
export const REST_URL_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTION = '/auth/api/v1/Summary/AndroidDeviceModelDistribution';
export const REST_URL_SUMMARY_ONLINE_USERS = '/auth/api/v1/Summary/OnlineUsers';
export const REST_URL_SEND_NOTIFICATION = '/core/api/v1/SimpleNotification';
export const REST_URL_COMPANIES = '/mdata/api/v1/Company?searchString=%&pageSize=10';
export const REST_URL_MDATA_COMPANIES = '/mdata/api/v1/m/Company?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_COMPANY_MDATA = '/mdata/api/v1/m/Company';
export const REST_URL_VIEW_DELETE_COMPANY_MDATA = '/mdata/api/v1/m/Company/{id}';
export const REST_URL_ENABLE_DISABLE_COMPANY_MDATA = '/mdata/api/v1/m/Company/Status';
export const REST_URL_DIVISIONS = '/mdata/api/v1/Division?searchString={searchText}&pageSize=10';
export const REST_URL_DEPARTMENTS = '/mdata/api/v1/Department?searchString={searchText}&pageSize=10';
export const REST_URL_PROFILE_TOTAL_PERSON_COUNT = '/mdata/api/v1/Profile?pageNumber=1&pageSize=1&filterString=status=enabled|{filterString}';
export const REST_URL_SUMMARY_BROWSER_DISTRIBUTION = '/auth/api/v1/Summary/BrowserDistribution';
export const REST_URL_SUMMARY_TIMEZONE_DISTRIBUTION = '/auth/api/v1/Summary/TimezoneDistribution';
export const REST_URL_SUMMARY_APP_VERSION_DISTRIBUTION = '/auth/api/v1/Summary/AppVersionDistribution';
export const REST_URL_DISABLE_TOKEN = '/auth/api/v1/Token/Disable';
export const REST_URL_APP_VERSIONS = '/auth/api/v1/Token/AppVersion?searchString={searchText}';
export const REST_URL_DEVICE_MANUFACTURERS = '/auth/api/v1/Token/Device/Manufacturer?searchString={searchText}';
export const REST_URL_DEVICE_MODELS = '/auth/api/v1/Token/Device/Model?searchString={searchText}';
export const REST_URL_OS_NAMES = '/auth/api/v1/Token/OSName?searchString={searchText}';
export const REST_URL_OS_VERSIONS = '/auth/api/v1/Token/OSVersion?searchString={searchText}';
export const REST_URL_TIME_ZONES = '/auth/api/v1/Token/TimeZone?searchString={searchText}';
export const REST_URL_SERVICES = '/auth/api/v1/Service?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_SERVICE = '/auth/api/v1/Service';
export const REST_URL_VIEW_DELETE_SERVICE = '/auth/api/v1/Service/{id}';
export const REST_URL_SCOPES = '/auth/api/v1/Scope?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_SCOPE = '/auth/api/v1/Scope';
export const REST_URL_VIEW_DELETE_SCOPE = '/auth/api/v1/Scope/{id}';
export const REST_URL_BANNERS = '/core/api/v1/Banner?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_BANNER = '/core/api/v1/Banner';
export const REST_URL_VIEW_DELETE_BANNER = '/core/api/v1/Banner/{id}';
export const REST_URL_SAVE_FILE = '/Download?';
export const REST_URL_DOWNLOAD_PROFILE_PICTURES = '/mdata/api/v1/ProfilePicture?';
export const REST_URL_DISTRICTS = '/mdata/api/v1/District?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_DISTRICT = '/mdata/api/v1/District';
export const REST_URL_VIEW_DELETE_DISTRICT = '/mdata/api/v1/District/{id}';
export const REST_URL_ENABLE_DISABLE_DISTRICT = '/mdata/api/v1/District/Status';
export const REST_URL_SUBDISTRICTS = '/mdata/api/v1/Subdistrict?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_SUBDISTRICT = '/mdata/api/v1/Subdistrict';
export const REST_URL_VIEW_DELETE_SUBDISTRICT = '/mdata/api/v1/Subdistrict/{id}';
export const REST_URL_ENABLE_DISABLE_SUBDISTRICT = '/mdata/api/v1/Subdistrict/Status';
export const REST_URL_RESYNC_MASTER_DATA_INDIVIDUALLY = '/mdata/api/v1/MasterData/ResyncIndividual';
export const REST_URL_RESYNC_USER_INDIVIDUALLY = '/auth/api/v1/ResyncUserIndividual';
export const REST_URL_LOGIN_WITH_MICROSOFT = '/auth/api/v1/LoginWith/Microsoft';

export const OAUTH_MICROSOFT_REDIRECT_URI_PATH = `${homepage}/oauth/microsoft/callback`;
export const OAUTH_MICROSOFT_REDIRECT_URI = `${window.location.protocol}//${window.location.host}${OAUTH_MICROSOFT_REDIRECT_URI_PATH}`;
export const OAUTH_MICROSOFT_URL = 'https://login.microsoftonline.com/{tenantId}/oauth2/v2.0/authorize?client_id={clientId}&redirect_uri={redirectUri}&scope={scope}&response_type=code&response_mode=query';

export const REST_METHOD_POST = 'POST';
export const REST_METHOD_GET = 'GET';
export const REST_METHOD_DELETE = 'DELETE';
export const REST_METHOD_PUT = 'PUT';

export const IMAGE_SOURCE_URI_PREFIX = 'data:image/png;base64,';
export const PDF_SOURCE_URI_PREFIX = 'data:application/pdf;base64,';

export const PASSWORD_MIN_LENGTH = 6;

export const KEY_REDUX_STATE = 'redux-state';

export const HTTP_HEADER_VALUE_JSON = 'application/json';

export const URL_ID_TAG = 'id=';

export const ROUTE_NAME_LOGIN = '/login';
export const ROUTE_NAME_LOGOUT = '/logout';
export const ROUTE_NAME_PROFILE = '/profile';
export const ROUTE_NAME_DASHBOARD = '/dashboard';
export const ROUTE_NAME_PERSONALIZATION = '/personalization';
export const ROUTE_NAME_MASTER_DATA = '/master-data';
export const ROUTE_NAME_REPORT = '/report';
export const ROUTE_NAME_PROVINCE = '/province';
export const ROUTE_NAME_CITY = '/city';
export const ROUTE_NAME_COMPANY = '/company';
export const ROUTE_NAME_BRANCH = '/branch';
export const ROUTE_NAME_AUTHENTICATION = '/authentication';
export const ROUTE_NAME_PERMISSION = '/permission';
export const ROUTE_NAME_ROLE = '/role';
export const ROUTE_NAME_USER = '/user';
export const ROUTE_NAME_API_KEY = '/api-key';
export const ROUTE_NAME_TOKEN = '/token';
export const ROUTE_NAME_APPLICATION = '/application';
export const ROUTE_NAME_SUMMARY = '/summary';
export const ROUTE_NAME_SERVICE = '/service';
export const ROUTE_NAME_SCOPE = '/scope';
export const ROUTE_NAME_BANNER = '/banner';
export const ROUTE_NAME_DISTRICT = '/district';
export const ROUTE_NAME_SUBDISTRICT = '/subdistrict';

export const RXFORM_LOGIN_SCREEN = 'loginScreen';
export const RXFORM_PROVINCE = 'provincePage';
export const RXFORM_CITY = 'cityPage';
export const RXFORM_COMPANY = 'companyPage';
export const RXFORM_BRANCH = 'branchPage';
export const RXFORM_PERMISSION = 'permissionPage';
export const RXFORM_USER = 'userPage';
export const RXFORM_ROLE = 'rolePage';
export const RXFORM_API_KEY = 'apiKeyPage';
export const RXFORM_TOKEN = 'tokenPage';
export const RXFORM_APPLICATION = 'applicationPage';
export const RXFORM_PROFILE = 'profilePage';
export const RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL = 'sendPersonalNotificationModal';
export const RXFORM_SERVICE = 'servicePage';
export const RXFORM_SCOPE = 'scopePage';
export const RXFORM_ADVANCED_FILTER_DIALOG = 'advancedFilterDialog';
export const RXFORM_BANNER = 'bannerPage';
export const RXFORM_DISTRICT = 'districtPage';
export const RXFORM_SUBDISTRICT = 'subdistrictPage';

export const RXFIELD_USERNAME = 'username';
export const RXFIELD_PASSWORD = 'password';
export const RXFIELD_ID = 'id';
export const RXFIELD_CREATED_BY = 'createdBy';
export const RXFIELD_CREATED_DATE = 'createdDate';
export const RXFIELD_LAST_MODIFIED_BY = 'lastModifiedBy';
export const RXFIELD_LAST_MODIFIED_DATE = 'lastModifiedDate';
export const RXFIELD_STATUS = 'status';

export const RXFIELD_PROVINCE_NAME = 'name';
export const RXFIELD_PROVINCE_TIME_ZONE = 'timeZone';
export const RXFIELD_PROVINCE_CODE = 'code';
export const RXFIELD_CITY_NAME = 'name';
export const RXFIELD_CITY_PROVINCE = 'province';
export const RXFIELD_CITY_CODE = 'code';
export const RXFIELD_COMPANY_NAME = 'name';
export const RXFIELD_COMPANY_CITY = 'city';
export const RXFIELD_COMPANY_ADDRESS = 'address';
export const RXFIELD_COMPANY_DESCRIPTION = 'description';
export const RXFIELD_COMPANY_TAG = 'tags';
export const RXFIELD_COMPANY_POSTAL_CODE = 'postalCode';
export const RXFIELD_COMPANY_EMAIL = 'email';
export const RXFIELD_COMPANY_PHONE = 'phone';
export const RXFIELD_COMPANY_WEBSITE = 'website';
export const RXFIELD_COMPANY_LOGO = 'logo';
export const RXFIELD_COMPANY_BANNER = 'banner';
export const RXFIELD_COMPANY_GOOGLE_MAP_URL = 'googleMapUrl';
export const RXFIELD_COMPANY_LATITUDE = 'latitude';
export const RXFIELD_COMPANY_LONGITUDE = 'longitude';
export const RXFIELD_COMPANY_APP_STORE_URL = 'appStoreUrl';
export const RXFIELD_COMPANY_PLAY_STORE_URL = 'playStoreUrl';
export const RXFIELD_BRANCH_NAME = 'name';
export const RXFIELD_BRANCH_CITY = 'city';
export const RXFIELD_BRANCH_ADDRESS = 'address';
export const RXFIELD_BRANCH_DESCRIPTION = 'description';
export const RXFIELD_BRANCH_POSTAL_CODE = 'postalCode';
export const RXFIELD_BRANCH_PHONE = 'phone';
export const RXFIELD_BRANCH_EMAIL = 'email';
export const RXFIELD_BRANCH_GOOGLE_MAP_URL = 'googleMapUrl';
export const RXFIELD_BRANCH_LATITUDE = 'latitude';
export const RXFIELD_BRANCH_LONGITUDE = 'longitude';
export const RXFIELD_BRANCH_START_WORKING_HOUR = 'startWorkingHour';
export const RXFIELD_BRANCH_END_WORKING_HOUR = 'endWorkingHour';
export const RXFIELD_BRANCH_START_BEFORE_MIN = 'startWorkingHourMarginBeforeMinutes';
export const RXFIELD_BRANCH_START_AFTER_MIN = 'startWorkingHourMarginAfterMinutes';
export const RXFIELD_BRANCH_END_BEFORE_MIN = 'endWorkingHourMarginBeforeMinutes';
export const RXFIELD_BRANCH_END_AFTER_MIN = 'endWorkingHourMarginAfterMinutes';
export const RXFIELD_BRANCH_AREA = 'area';
export const RXFIELD_BRANCH_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES = 'earliestClockInDriftTimeMinutes';
export const RXFIELD_BRANCH_AUTO_CLOCK_OUT_AFTER_MINUTES = 'autoClockOutAfterMinutes';
export const RXFIELD_BRANCH_COMPANY = 'company';
export const RXFIELD_PERMISSION_NAME = 'name';
export const RXFIELD_PERMISSION_DESCRIPTION = 'description';
export const RXFIELD_USER_USERNAME = 'username';
export const RXFIELD_USER_FULLNAME = 'fullName';
export const RXFIELD_USER_EMAIL = 'email';
export const RXFIELD_USER_PHONE = 'phone';
export const RXFIELD_USER_ROLES = 'roles';
export const RXFIELD_USER_ACTIVE_SESSION = 'activeSessions';
export const RXFIELD_USER_TYPE = 'type';
export const RXFIELD_ROLE_NAME = 'name';
export const RXFIELD_ROLE_DESCRIPTION = 'description';
export const RXFIELD_ROLE_PERMISSION = 'permissions';
export const RXFIELD_API_KEY_TOKEN = 'token';
export const RXFIELD_API_KEY_SCOPE = 'scope';
export const RXFIELD_API_KEY_CONSUMER = 'consumerAppName';
export const RXFIELD_API_KEY_OWNER = 'ownerAppName';
export const RXFIELD_TOKEN_TOKEN = 'tokenKey';
export const RXFIELD_TOKEN_USERNAME = 'username';
export const RXFIELD_TOKEN_VALIDITY = 'validUntil';
export const RXFIELD_TOKEN_LAST_ACTIVITY = 'lastActivity';
export const RXFIELD_TOKEN_DEVICE = 'device';
export const RXFIELD_TOKEN_OS = 'os';
export const RXFIELD_TOKEN_APP_VERSION = 'appVersion';
export const RXFIELD_TOKEN_FULLNAME = 'fullName';
export const RXFIELD_TOKEN_TIMEZONE = 'timeZone';
export const RXFIELD_TOKEN_LANGUAGE = 'acceptLanguage';
export const RXFIELD_TOKEN_DEVICE_MANUFACTURER = 'deviceManufacturer';
export const RXFIELD_TOKEN_DEVICE_MODEL = 'deviceModel';
export const RXFIELD_TOKEN_OS_NAME = 'osName';
export const RXFIELD_TOKEN_OS_VERSION = 'osVersion';
export const RXFIELD_TOKEN_FCM_TOKEN = 'fcmToken';
export const RXFIELD_TOKEN_DEVICE_ID = 'deviceId';
export const RXFIELD_APPLICATION_NAME = 'name';
export const RXFIELD_APPLICATION_DESCRIPTION = 'description';
export const RXFIELD_APPLICATION_URL = 'url';
export const RXFIELD_APPLICATION_ANDROID_ID = 'androidAppId';
export const RXFIELD_APPLICATION_IOS_ID = 'iosAppId';
export const RXFIELD_APPLICATION_DOWNLOAD_URL = 'downloadUrl';
export const RXFIELD_APPLICATION_ORDER = 'order';
export const RXFIELD_APPLICATION_PERMISSION = 'permission';
export const RXFIELD_APPLICATION_ICON = 'icon';
export const RXFIELD_APPLICATION_MIN_APP_VERSION = 'minAppVersion';
export const RXFIELD_APPLICATION_MAX_APP_VERSION = 'maxAppVersion';
export const RXFIELD_PROFILE_USERNAME = 'username';
export const RXFIELD_PROFILE_FULLNAME = 'fullName';
export const RXFIELD_PROFILE_EMAIL = 'email';
export const RXFIELD_PROFILE_DATE_OF_BIRTH = 'dateOfBirth';
export const RXFIELD_PROFILE_GENDER = 'gender';
export const RXFIELD_PROFILE_PHONE = 'phone';
export const RXFIELD_PROFILE_EMPLOYEE_ID = 'employeeId';
export const RXFIELD_PROFILE_JOB_TITLE = 'jobTitle';
export const RXFIELD_PROFILE_DEPARTMENT = 'department';
export const RXFIELD_PROFILE_DIVISION = 'division';
export const RXFIELD_PROFILE_COMPANY_NAME = 'company';
export const RXFIELD_PROFILE_BRANCH = 'branch';
export const RXFIELD_PROFILE_MANAGER = 'manager';
export const RXFIELD_PROFILE_PROFILE_PICTURE = 'profilePicture';
export const RXFIELD_PROFILE_ROLES = 'roles';
export const RXFIELD_PROFILE_START_WORKING_HOUR = 'startWorkingHour';
export const RXFIELD_PROFILE_END_WORKING_HOUR = 'endWorkingHour';
export const RXFIELD_PROFILE_START_BEFORE_MIN = 'startWorkingHourMarginBeforeMinutes';
export const RXFIELD_PROFILE_START_AFTER_MIN = 'startWorkingHourMarginAfterMinutes';
export const RXFIELD_PROFILE_END_BEFORE_MIN = 'endWorkingHourMarginBeforeMinutes';
export const RXFIELD_PROFILE_END_AFTER_MIN = 'endWorkingHourMarginAfterMinutes';
export const RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE = 'workingHourTimeZone';
export const RXFIELD_PROFILE_STATUS = 'status';
export const RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES = 'earliestClockInDriftTimeMinutes';
export const RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES = 'autoClockOutAfterMinutes';
export const RXFIELD_PROFILE_USER_CODE = 'userCode';
export const RXFIELD_PROFILE_TAGS = 'tags';
export const RXFIELD_PROFILE_HOME_ADDRESS_ID = 'homeAddress.id';
export const RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_NAME = 'homeAddress.contactName';
export const RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_PHONE = 'homeAddress.contactPhone';
export const RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_1 = 'homeAddress.addressLine1';
export const RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_2 = 'homeAddress.addressLine2';
export const RXFIELD_PROFILE_HOME_ADDRESS_REGION = 'homeAddressRegion';
export const RXFIELD_PROFILE_HOME_ADDRESS_POSTAL_CODE = 'homeAddress.postalCode';
export const RXFIELD_PROFILE_WORK_ADDRESS_ID = 'workAddress.id';
export const RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_NAME = 'workAddress.contactName';
export const RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_PHONE = 'workAddress.contactPhone';
export const RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_1 = 'workAddress.addressLine1';
export const RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_2 = 'workAddress.addressLine2';
export const RXFIELD_PROFILE_WORK_ADDRESS_REGION = 'workAddressRegion';
export const RXFIELD_PROFILE_WORK_ADDRESS_POSTAL_CODE = 'workAddress.postalCode';
export const RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE = 'title';
export const RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY = 'body';
export const RXFIELD_SEND_PERSONAL_NOTIFICATION_URL = 'url';
export const RXFIELD_SEND_PERSONAL_NOTIFICATION_BRANCH = 'branch';
export const RXFIELD_SEND_PERSONAL_NOTIFICATION_COMPANY_NAME = 'company';
export const RXFIELD_SEND_PERSONAL_NOTIFICATION_DIVISION = 'division';
export const RXFIELD_SEND_PERSONAL_NOTIFICATION_DEPARTMENT = 'department';
export const RXFIELD_SERVICE_NAME = 'name';
export const RXFIELD_SERVICE_DESCRIPTION = 'description';
export const RXFIELD_SCOPE_NAME = 'name';
export const RXFIELD_SCOPE_DESCRIPTION = 'description';
export const RXFIELD_SCOPE_SERVICE = 'service';
export const RXFIELD_BANNER_TITLE = 'title';
export const RXFIELD_BANNER_BODY = 'body';
export const RXFIELD_BANNER_URL = 'url';
export const RXFIELD_BANNER_CLICK_ACTION = 'clickAction';
export const RXFIELD_BANNER_VALID_FROM = 'validFrom';
export const RXFIELD_BANNER_VALID_UNTIL = 'validUntil';
export const RXFIELD_BANNER_VIEW_COUNT = 'viewCount';
export const RXFIELD_BANNER_SHORT_URL = 'shortUrl';
export const RXFIELD_BANNER_REACTION_ID = 'reactionId';
export const RXFIELD_BANNER_DISCUSSION_ID = 'discusId';
export const RXFIELD_BANNER_ANDROID_APP_ID = 'androidAppId';
export const RXFIELD_BANNER_IOS_APP_ID = 'iosAppId';
export const RXFIELD_BANNER_DOWNLOAD_URL = 'downloadUrl';
export const RXFIELD_BANNER_ORDER = 'order';
export const RXFIELD_BANNER_MEDIA = 'media';
export const RXFIELD_BANNER_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_BANNER_TAGS = 'tags';
export const RXFIELD_BANNER_COMPANY = 'company';
export const RXFIELD_DISTRICT_NAME = 'name';
export const RXFIELD_DISTRICT_CITY = 'city';
export const RXFIELD_DISTRICT_CODE = 'code';
export const RXFIELD_SUBDISTRICT_NAME = 'name';
export const RXFIELD_SUBDISTRICT_DISTRICT = 'district';
export const RXFIELD_SUBDISTRICT_CODE = 'code';

export const RXSTATE_LOGIN_PAGE = 'uiLogin';
export const RXSTATE_PROVINCES = 'provinces';
export const RXSTATE_PROVINCE_PAGE = 'uiProvince';
export const RXSTATE_CITIES = 'cities';
export const RXSTATE_CITY_PAGE = 'uiCity';
export const RXSTATE_COMPANIES = 'companies';
export const RXSTATE_COMPANIES_MDATA = 'companiesMdata';
export const RXSTATE_COMPANY_PAGE = 'uiCompany';
export const RXSTATE_BRANCHES = 'branches';
export const RXSTATE_BRANCH_PAGE = 'uiBranch';
export const RXSTATE_PERMISSIONS = 'permissions';
export const RXSTATE_PERMISSION_PAGE = 'uiPermission';
export const RXSTATE_USERS = 'users';
export const RXSTATE_USER_PAGE = 'uiUser';
export const RXSTATE_ROLES = 'roles';
export const RXSTATE_ROLE_PAGE = 'uiRole';
export const RXSTATE_API_KEYS = 'apiKeys';
export const RXSTATE_API_KEY_PAGE = 'uiApiKey';
export const RXSTATE_TOKENS = 'tokens';
export const RXSTATE_TOKEN_PAGE = 'uiToken';
export const RXSTATE_APPLICATIONS = 'applications';
export const RXSTATE_APPLICATION_PAGE = 'uiApplication';
export const RXSTATE_PROFILES = 'profiles';
export const RXSTATE_PROFILE_PAGE = 'uiProfile';
export const RXSTATE_SERVICES = 'services';
export const RXSTATE_SERVICE_PAGE = 'uiService';
export const RXSTATE_SCOPES = 'scopes';
export const RXSTATE_SCOPE_PAGE = 'uiScope';
export const RXSTATE_BANNERS = 'banners';
export const RXSTATE_BANNER_PAGE = 'uiBanner';
export const RXSTATE_DISTRICTS = 'districts';
export const RXSTATE_DISTRICT_PAGE = 'uiDistrict';
export const RXSTATE_SUBDISTRICTS = 'subdistricts';
export const RXSTATE_SUBDISTRICT_PAGE = 'uiSubdistrict';

export const COLOR_BACKGROUND = '#FFFFFF';
export const COLOR_BACKGROUND_SECONDARY = '#F3F5F7';

export const COLOR_PRIMARY = '#007DC5';
export const COLOR_PRIMARY_LIGHTER = '#008fe0';
export const COLOR_SECONDARY = '#72BF44';
export const COLOR_TERTIARY = '#C2BD41';

export const COLOR_ACCENT = '#FAFAFA';

export const COLOR_BODY_TEXT = '#2B3336';
export const COLOR_BODY_TEXT_LIGHT = '#808080';
export const COLOR_BODY_TEXT_LIGHTER = '#949494';
export const COLOR_TEXT_ON_PRIMARY = '#FFFFFF';
export const COLOR_TEXT_03 = '#8B90A0';

export const COLOR_ICON = '#000000';
export const COLOR_ICON_LIGHTER = '#E8E8E8';
export const COLOR_DANGEROUS = '#E30613';
export const COLOR_TABLE_HEADER = '#FAFAFA';
export const COLOR_POLYGON = '#FF0000';
export const COLOR_DISABLED_ROW = '#dce1e8';
export const COLOR_HORIZONTAL_LINE = '#EEEEEE';
export const COLOR_DISABLED_CONTROL = '#A4A4A4';
export const COLOR_TEXT_BLACK_SECONDARY = '#444444';

export const COLOR_C5221F = '#C5221F';
export const COLOR_188038 = '#188038';

export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_INFO = 'info';
export const ALERT_TYPE_WARNING = 'warning';
export const ALERT_TYPE_CONFIRMATION = 'confirmation';
export const ALERT_TYPE_INPUT = 'input';

export const DIALOG_TYPE_ADD = 'add';
export const DIALOG_TYPE_EDIT = 'edit';

export const DATE_FORMAT = 'DD MMMM YYYY';
export const PICKER_DATE_FORMAT = 'dd MMM yyyy';
export const PICKER_TIME_FORMAT = 'HH:mm';
export const PICKER_DATE_TIME_FORMAT = 'dd MMM yyyy HH:mm:ss';
export const PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT = 'dd MMM yyyy HH:mm';
export const REVERSED_ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const REVERSED_ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT_WITHOUT_PIPE = 'DD MMM YYYY HH:mm';
export const DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE = 'DD MMM YYYY HH:mm:ss';
export const DATE_TIME_FORMAT = 'DD MMM YYYY | HH:mm';
export const SIMPLE_DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'HH:mm';
export const MINUTE_SECOND_FORMAT = 'm:ss';
export const MONTH_YEAR_ONLY_FORMAT = 'MMMM YYYY';
export const MONTH_ONLY_FORMAT = 'MMMM';
export const YEAR_ONLY_FORMAT = 'YYYY';
export const DAY_NAME_FORMAT = 'dddd';

export const TIMEZONE_WIB = { offset: '+0700', timezone: 'Asia/Jakarta', symbol: 'WIB' };
export const TIMEZONE_WITA = { offset: '+0800', timezone: 'Asia/Makassar', symbol: 'WITA' };
export const TIMEZONE_WIT = { offset: '+0900', timezone: 'Asia/Jayapura', symbol: 'WIT' };

export const ORDER_SORT_ASCENDING = 'asc';
export const ORDER_SORT_DESCENDING = 'desc';

export const INITIAL_ORDER_BY_API_KEYS = `scope ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_APPLICATIONS = `order ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_BRANCHES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_CITIES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_COMPANIES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_PERMISSIONS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_PROFILES = `fullName ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_PROVINCES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_ROLES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_TOKENS = `lastActivity ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_USERS = `username ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SERVICES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SCOPES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_BANNERS = `order ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_DISTRICTS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_SUBDISTRICTS = `name ${ORDER_SORT_ASCENDING}`;

export const PAGE_MODE_EDIT = 'edit';
export const PAGE_MODE_TABLE = 'table';
export const PAGE_MODE_VIEW = 'view';
export const PAGE_MODE_ADD = 'add';

export const CLICK_ACTION_OPEN_URL = 'OpenUrl';
export const CLICK_ACTION_NONE = 'None';
export const CLICK_ACTION_SHOW_BANNER_BODY = 'ShowBannerBody';

export const STATUS_ENABLED = 'Enabled';
export const STATUS_DISABLED = 'Disabled';

export const RECIPIENT_TYPE_DEVICE_TOKEN = 'DeviceToken';
export const RECIPIENT_TYPE_PROFILE = 'Profile';

export const genders = [{ label: 'Female', value: 'Female' }, { label: 'Male', value: 'Male' }, { label: 'Unspecified', value: 'Unspecified' }];
export const bloodTypes = ['A', 'B', 'O', 'AB'];
export const approval = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
export const timezoneOffset = ['+0700', '+0800', '+0900'];
export const eventNotificationRecipients = ['All', 'Registered', 'Approved'];
export const visibilityStatuses = ['Hidden', 'Visible'];
export const clickActions = [CLICK_ACTION_NONE, CLICK_ACTION_OPEN_URL,
  CLICK_ACTION_SHOW_BANNER_BODY];
export const switches = [{ label: 'Yes', value: true }, { label: 'No', value: false }, { label: 'None', value: '' }];
export const status = [{ label: STATUS_ENABLED, value: 'enabled' }, { label: STATUS_DISABLED, value: 'disabled' }];
export const NOTIFICATION_RECIPIENT_TYPES = [RECIPIENT_TYPE_PROFILE, RECIPIENT_TYPE_DEVICE_TOKEN];

export const PICKER_MODE_DATE = 'date';
export const PICKER_MODE_TIME = 'time';
export const PICKER_MODE_DATE_TIME = 'dateTime';

export const DROPDOWN_OPTION_CLEAR = '<CLEAR>';

export const snackbarDuration = 1000;

export const FILTERED_RECIPIENTS_ID_TAG = 'id=';
export const FILTERED_RECIPIENTS_STATUS_ENABLED_TAG = 'status=enabled';
export const FILTERED_RECIPIENTS_BRANCH_TAG = '|branch.name><';
export const FILTERED_RECIPIENTS_COMPANIES_TAG = '|userCompany.name><';
export const FILTERED_RECIPIENTS_DIVISIONS_TAG = '|division><';
export const FILTERED_RECIPIENTS_DEPARTMENTS_TAG = '|department><';
export const FILTERED_RECIPIENTS_VALID_UNTIL_TAG = '|validUntil>=';

export const MENUID_ROOT = 'MENUID_ROOT';
export const MENUID_DASHBOARD = 'MENUID_DASHBOARD';
export const MENUID_AUTHENTICATION = 'MENUID_AUTHENTICATION';
export const MENUID_AUTHENTICATION_SUMMARY = 'MENUID_AUTHENTICATION_SUMMARY';
export const MENUID_AUTHENTICATION_SERVICE = 'MENUID_AUTHENTICATION_SERVICE';
export const MENUID_AUTHENTICATION_APIKEY = 'MENUID_AUTHENTICATION_APIKEY';
export const MENUID_AUTHENTICATION_TOKEN = 'MENUID_AUTHENTICATION_TOKEN';
export const MENUID_AUTHENTICATION_PERMISSION = 'MENUID_AUTHENTICATION_PERMISSION';
export const MENUID_AUTHENTICATION_ROLE = 'MENUID_AUTHENTICATION_ROLE';
export const MENUID_AUTHENTICATION_USER = 'MENUID_AUTHENTICATION_USER';
export const MENUID_AUTHENTICATION_SCOPE = 'MENUID_AUTHENTICATION_SCOPE';
export const MENUID_PERSONALIZATION = 'MENUID_PERSONALIZATION';
export const MENUID_PERSONALIZATION_APPLICATION = 'MENUID_PERSONALIZATION_APPLICATION';
export const MENUID_PERSONALIZATION_BANNER = 'MENUID_PERSONALIZATION_BANNER';
export const MENUID_PERSONALIZATION_PROFILE = 'MENUID_PERSONALIZATION_PROFILE';
export const MENUID_MASTERDATA = 'MENUID_MASTERDATA';
export const MENUID_MASTERDATA_PROVINCE = 'MENUID_MASTERDATA_PROVINCE';
export const MENUID_MASTERDATA_CITY = 'MENUID_MASTERDATA_CITY';
export const MENUID_MASTERDATA_COMPANY = 'MENUID_MASTERDATA_COMPANY';
export const MENUID_MASTERDATA_BRANCH = 'MENUID_MASTERDATA_BRANCH';
export const MENUID_REPORT = 'MENUID_REPORT';
export const MENUID_MASTERDATA_DISTRICT = 'MENUID_MASTERDATA_DISTRICT';
export const MENUID_MASTERDATA_SUBDISTRICT = 'MENUID_MASTERDATA_SUBDISTRICT';

export const DRAW_MODE_MARKER = 'marker';
export const DRAW_MODE_POLYGON = 'polygon';
export const DRAW_MODE_NONE = 'none';

export const GOOGLE_MAP_API_KEY = 'AIzaSyDDtRSoNi-dLiaZz8prxCAW0-fT7YxJk0w';
export const DEFAULT_MAP_DRAWER_CENTER_MARKER = { latitude: -6.175392, longitude: 106.827153 };
export const DEFAULT_GOOGLE_MAP_URL = 'https://goo.gl/maps/nFMeLcNJBg3JFtoB8';
export const GOOGLE_MAP_URL_PREFIX = 'https://www.google.com/maps/search/?api=1&query={latitude},{longitude}';

export const OAUTH_PROVIDER_PARAM = 'oauth_provider';
export const OAUTH_PROVIDER_MICROSOFT = 'Microsoft';

export const FILTER_TYPE_TEXT = 'text';
export const FILTER_TYPE_DATE = 'date';
export const FILTER_TYPE_DATE_RANGE = 'dateRange';
export const FILTER_TYPE_NUMBER = 'number';
export const FILTER_TYPE_NUMBER_RANGE = 'numberRange';
export const FILTER_TYPE_DROPDOWN = 'dropdown';
export const FILTER_TYPE_MULTIPLE_VALUES = 'multipleValues';
export const FILTER_TYPE_SWITCH = 'switch';
export const FILTER_TYPE_RADIO_BUTTON = 'radioButton';

export const PDF_SOURCE_URI_PREFIX_LENGTH = 28;
export const CSV_SOURCE_URI_PREFIX_LENGTH = 21;
export const IMAGE_SOURCE_URI_PNG_PREFIX_LENGTH = 22;
export const IMAGE_SOURCE_URI_JPEG_PREFIX_LENGTH = 23;

export const DEBOUNCED_SEARCH_DURATION = 700;

export const CHART_TYPE_SIMPLE_LINE = 'simpleLine';
export const CHART_TYPE_SIMPLE_BAR = 'simpleBar';
export const CHART_TYPE_PIE = 'pie';

export const PERMISSION_OPERATOR_AND = 'and';
export const PERMISSION_OPERATOR_OR = 'or';

export const BASE_64_PREFIX = 'data:image/jpeg;base64,';

export const API_KEY_OWNER_APP_NAME_PARAMETER = 'ownerAppName><';
export const API_KEY_CONSUMER_APP_NAME_PARAMETER = 'consumerAppName><';
export const API_KEY_SCOPE_PARAMETER = 'scope><';

export const USER_TYPE_DEFAULT = 'Default';
export const USER_TYPE_INTEGRATION = 'Integration';
export const USER_TYPE_SYSTEM = 'System';

export const TIME_MODE_CLOCK_IN = 'clockIn';
export const TIME_MODE_CLOCK_OUT = 'clockOut';

export const REGEX_DATE_TIME_FORMAT = /(3[01]|0[1-9]|[12][0-9]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4} (2[0-3]|[01][0-9]):([0-5][0-9])(\\.[0-9]+)?/ig;
export const REGEX_DATE_FORMAT = /(3[01]|0[1-9]|[12][0-9]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}?/ig;
export const REGEX_TIME_FORMAT = /(2[0-3]|[01][0-9]):([0-5][0-9])(\\.[0-9]+)?/ig;

export const CONFIG_ITEM_DEEP_LINK_SCHEME_FILTER_STRING = '|name=DeepLinkScheme|configGroup.name=Global';

export const MASTER_DATA_ENTITY_NAME_PROVINCE = 'Province';
export const MASTER_DATA_ENTITY_NAME_CITY = 'City';
export const MASTER_DATA_ENTITY_NAME_DISTRICT = 'District';
export const MASTER_DATA_ENTITY_NAME_SUBDISTRICT = 'SubDistrict';
export const MASTER_DATA_ENTITY_NAME_COMPANY = 'Company';
export const MASTER_DATA_ENTITY_NAME_BRANCH = 'Branch';
export const MASTER_DATA_ENTITY_NAME_WORKING_SHIFT = 'WorkingShift';
export const MASTER_DATA_ENTITY_NAME_PROFILE = 'Profile';
export const MASTER_DATA_ENTITY_NAME_ADDRESS = 'Address';
export const CHANNEL_ENTITY_NAME_RESYNC = 'Resync';
export const CHANNEL_ENTITY_NAME_PUBLIC = 'Public';

export const FILE_EXTENSION_PDF = '.pdf';
export const FILE_EXTENSION_CSV = '.csv';
export const FILE_EXTENSION_IMAGE = 'image/*';
export const FILE_EXTENSION_SPREADSHEET = '.xlsx,.xls';
export const FILE_EXTENSION_SLIDESHOW = '.ppt, .pptx';
export const FILE_EXTENSION_DOCUMENT = '.doc,.docx,.txt';
export const FILE_EXTENSION_ZIP = '.zip,.rar,.7zip';
export const FILE_EXTENSION_ALL = 'image/*,.pdf,.xlsx,.xls,.ppt, .pptx,.doc,.docx,.txt,.zip,.rar,.7zip';

export const TEXT_FORMAT_HTML = 'Html';

export const ERR_TOKEN_SESSION_EXPIRED = 'token or session expired';

export const ENUM_FIELD_TEXT = 'Text';
export const ENUM_FIELD_DATE = 'Date';
export const ENUM_FIELD_DATE_TIME = 'DateTime';
export const ENUM_FIELD_TIME = 'Time';
export const ENUM_FIELD_INTEGER = 'Integer';
export const ENUM_FIELD_FLOAT = 'Float';
export const ENUM_FIELD_PICKER = 'Picker';
export const ENUM_FIELD_BOOLEAN = 'Boolean';
export const ENUM_FIELD_OPTIONS = 'Options';
export const ENUM_FIELD_PHOTO = 'Photo';

export const OPTION_VALUE_SEPARATORS = [',', ';', '|'];
export const OPTION_MODES = ['CheckBox', 'Button'];
export const PICKER_MODES = ['DropDown', 'Radio', 'Button'];
export const FIELD_TYPES = [ENUM_FIELD_TEXT, ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_TIME,
  ENUM_FIELD_INTEGER, ENUM_FIELD_FLOAT, ENUM_FIELD_PICKER, ENUM_FIELD_BOOLEAN, ENUM_FIELD_OPTIONS,
  ENUM_FIELD_PHOTO];
export const KEYBOARD_TYPES = ['Default', 'Numeric', 'Phone', 'Email'];
export const LOGICAL_OPERATOR = ['And', 'Or'];

export const CONFIGURATION_VALUE_TRUE = 'true';

export const EXPORT_TYPE_EXCEL = 'Excel';
export const EXPORT_TYPE_CSV = 'Csv';

export const FILE_TYPE_EXCEL = '.xlsx';
export const FILE_TYPE_CSV = '.csv';

export const IMAGE_FILE_EXTENSIONS = {
  'image/jpeg': [],
  'image/png': [],
  'image/jpg': [],
};

export const BUTTON_TYPE_OUTLINED = 'outlined';
export const BUTTON_TYPE_TEXT = 'text';
export const BUTTON_TYPE_CONTAINED = 'contained';

export const ADVANCED_FILTER_MODE = 'filter';

export const FONT_SIZE_H1 = '24px';
export const FONT_SIZE_H2 = '22px';
export const FONT_SIZE_H3 = '20px';
export const FONT_SIZE_H4 = '18px';
export const FONT_SIZE_BODY_TITLE = '16px';
export const FONT_SIZE_BODY_LARGE = '16px';
export const FONT_SIZE_BODY_EXTRA_LARGE = '24px';
export const FONT_SIZE_BODY = '14px';
export const FONT_SIZE_BODY_SMALL = '12px';
export const FONT_SIZE_BODY_LARGE_TEXTINPUT = '16px';
export const FONT_SIZE_BODY_PARAGRAPH = '14px';
export const FONT_SIZE_BODY_SMALL_INFORMATION = '12px';
export const FONT_SIZE_BODY_EXTRA_SMALL = '10px';
export const FONT_SIZE_BODY_EXTRA_SMALL_BADGE = '10px';
export const FONT_SIZE_MY_POINT_TEXT = '20px';
