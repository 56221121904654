import { downloadingDeviceManufacturers, setDeviceManufacturers } from '../simple-action';
import { downloadDeviceManufacturers, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { deviceManufacturerSearchText } = getState().uiToken;

  try {
    dispatch(downloadingDeviceManufacturers(true));

    const list = await downloadDeviceManufacturers(
      transformSearchText(deviceManufacturerSearchText),
      token,
    );

    if (list) {
      dispatch(setDeviceManufacturers(list));
    }
  } finally {
    dispatch(downloadingDeviceManufacturers(false));
  }
};
