import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_CUSTOMER_DESCRIPTION, RXFIELD_CUSTOMER_INDUSTRY, RXFIELD_CUSTOMER_NAME,
  RXFIELD_CUSTOMER_REF_ID, RXFORM_CUSTOMER, RXSTATE_CUSTOMERS, RXSTATE_CUSTOMER_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateCustomer } from '../../validation';

const renderDialogContent = (defaultIndustryValue, industries, addingEditing, downloadingDeleting,
  loadingIndustry, onChangeIndustryText, onIndustryOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CUSTOMER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.customerScreen.placeholderName}
            label={LocalizedString.customerScreen.placeholderName}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CUSTOMER_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.customerScreen.placeholderDescription}
            label={LocalizedString.customerScreen.placeholderDescription}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CUSTOMER_INDUSTRY}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.customerScreen.placeholderIndustry}
            label={LocalizedString.customerScreen.placeholderIndustry}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={industries}
            value={defaultIndustryValue}
            loading={loadingIndustry}
            onChangeText={onChangeIndustryText}
            onOptionSelected={onIndustryOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CUSTOMER_REF_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.customerScreen.placeholderRefId}
            label={LocalizedString.customerScreen.placeholderRefId}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
);

const CustomerPage = ({
  initialValues, industries,
  addingEditing, downloading, downloadingDeleting, loadingIndustry,
  handleSubmit, onAppear, onCancelPressed, onChangeIndustryText, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onEditPressed, onIndustryOptionSelected,
  onRefresh, onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_CUSTOMERS}
    uiPage={RXSTATE_CUSTOMER_PAGE}
    tableColumns={[
      {
        title: LocalizedString.customerScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.customerScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.customerScreen.labelIndustry, field: 'industry.name', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.customerScreen.buttonCaptionCreateNewCustomer}
    deleteButtonCaption={LocalizedString.customerScreen.buttonCaptionDeleteCustomer}
    editButtonCaption={LocalizedString.customerScreen.buttonCaptionEditCustomer}
    title={LocalizedString.customerScreen.title}
    createPermissionName="ACTIVITY_CREATE_CUSTOMER"
    deletePermissionName="ACTIVITY_DELETE_CUSTOMER"
    editPermissionName="ACTIVITY_EDIT_CUSTOMER"
    useFullWidth
  >
    {renderDialogContent(initialValues.industry.label, industries, addingEditing,
      downloadingDeleting, loadingIndustry, onChangeIndustryText, onIndustryOptionSelected,
      pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CUSTOMER,
  validate: rxformValidateCustomer,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CustomerPage);

CustomerPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  industries: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingIndustry: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeIndustryText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onIndustryOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
