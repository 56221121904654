import { INITIAL_ORDER_BY_ATTENDANCE_CORRECTIONS } from '../../constant';
import {
  DOWNLOADING_ATTENDANCE_CORRECTION, DOWNLOADING_ATTENDANCE_CORRECTIONS,
  SET_ATTENDANCE_CORRECTION_SELECTED_PAGE_SIZE, SET_ATTENDANCE_CORRECTION_TAPPED_ID,
  SET_ATTENDANCE_CORRECTION_SEARCH_TEXT, SET_ATTENDANCE_CORRECTION_SELECTED_ORDER_BY,
  SET_ATTENDANCE_CORRECTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';

const initialState = {
  canceling: false,
  downloading: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_ATTENDANCE_CORRECTIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ATTENDANCE_CORRECTIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_ATTENDANCE_CORRECTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_ATTENDANCE_CORRECTION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ATTENDANCE_CORRECTION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ATTENDANCE_CORRECTION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ATTENDANCE_CORRECTION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ATTENDANCE_CORRECTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default:
      return state;
  }
};
