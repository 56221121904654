import {
  DOWNLOADING_QUIZ_QUESTIONS, ADDING_EDITING_QUIZ_QUESTION,
  DOWNLOADING_DELETING_QUIZ_QUESTION, SET_QUIZ_QUESTION_SELECTED_PAGE_SIZE,
  SET_QUIZ_QUESTION_TAPPED_ID, SET_QUIZ_QUESTION_SEARCH_TEXT,
  SET_QUIZ_QUESTION_SELECTED_ORDER_BY, ENABLING_DISABLING_QUIZ_QUESTION,
  SET_QUIZ_QUESTION_SELECTED_QUIZ,
  SET_QUIZ_QUESTION_SELECTED_FIELD_TYPE, SET_QUIZ_QUESTION_SELECTED_OPTION_MODE,
  SET_QUIZ_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR,
  SET_QUIZ_QUESTION_SELECTED_PICKER_MODE, SET_QUIZ_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE,
  SET_QUIZ_QUESTION_SELECTED_TEXT_MULTILINE,
  SET_QUIZ_QUESTION_SELECTED_LOGICAL_OPERATOR, SET_QUIZ_QUESTION_SELECTED_MUST_USE_CAMERA,
  SET_QUIZ_QUESTION_SELECTED_CORRECT_ANSWER_LOGICAL_OPERATOR,
} from '../action';
import { INITIAL_ORDER_BY_QUIZ_QUESTIONS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_QUIZ_QUESTIONS,
  selectedFieldType: '',
  selectedLogicalOperator: '',
  selectedMustUseCamera: '',
  selectedOptionMode: '',
  selectedOptionValueSeparator: '',
  selectedPickerMode: '',
  selectedTextKeyboardType: '',
  selectedTextMultiline: '',
  selectedQuiz: '',
  selectedCorrectAnswerLogicalOperator: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_QUIZ_QUESTIONS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_QUIZ_QUESTION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_QUIZ_QUESTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_QUIZ_QUESTION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_QUIZ_QUESTION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_QUIZ_QUESTION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_QUIZ_QUESTION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case ENABLING_DISABLING_QUIZ_QUESTION:
      return { ...state, enablingDisabling: action.status };
    case SET_QUIZ_QUESTION_SELECTED_FIELD_TYPE:
      return { ...state, selectedFieldType: action.option };
    case SET_QUIZ_QUESTION_SELECTED_OPTION_MODE:
      return { ...state, selectedOptionMode: action.option };
    case SET_QUIZ_QUESTION_SELECTED_OPTION_VALUE_SEPARATOR:
      return { ...state, selectedOptionValueSeparator: action.option };
    case SET_QUIZ_QUESTION_SELECTED_PICKER_MODE:
      return { ...state, selectedPickerMode: action.option };
    case SET_QUIZ_QUESTION_SELECTED_TEXT_KEYBOARD_TYPE:
      return { ...state, selectedTextKeyboardType: action.option };
    case SET_QUIZ_QUESTION_SELECTED_TEXT_MULTILINE:
      return { ...state, selectedTextMultiline: action.option };
    case SET_QUIZ_QUESTION_SELECTED_LOGICAL_OPERATOR:
      return { ...state, selectedLogicalOperator: action.option };
    case SET_QUIZ_QUESTION_SELECTED_MUST_USE_CAMERA:
      return { ...state, selectedMustUseCamera: action.option };
    case SET_QUIZ_QUESTION_SELECTED_QUIZ:
      return { ...state, selectedQuiz: action.option };
    case SET_QUIZ_QUESTION_SELECTED_CORRECT_ANSWER_LOGICAL_OPERATOR:
      return { ...state, selectedCorrectAnswerLogicalOperator: action.option };
    default: return state;
  }
};
