import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, makeStyles, Modal } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { COLOR_DANGEROUS } from '../../constant';
import LocalizedString from '../../localization';
import AccentButton from '../accent-button';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
}));

const DownloadRequestTimeoutDialog = ({
  isVisible, navigateTo, onNavigatePressed, onCancelPressed,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Modal open={isVisible} disableBackdropClick disableEscapeKeyDown>
      <DialogContent>
        <SweetAlert
          type="info"
          show={isVisible}
          title={LocalizedString.common.alertTitleConfirmation}
          onConfirm={() => onNavigatePressed(history, navigateTo)}
          openAnim={{ name: 'showSweetAlert', duration: 500 }}
          closeOnClickOutside={false}
          customButtons={(
            <div className={classes.buttonContainer}>
              <AccentButton
                onClick={onCancelPressed}
                variant="text"
                className={classes.redButton}
                caption={LocalizedString.common.buttonCaptionNo}
              />
              <AccentButton
                onClick={() => onNavigatePressed(history, navigateTo)}
                caption={LocalizedString.common.buttonCaptionYes}
              />
            </div>
     )}
        >
          {LocalizedString.common.labelDownloadRequestTimeout}
        </SweetAlert>
      </DialogContent>
    </Modal>
  );
};

export default DownloadRequestTimeoutDialog;

DownloadRequestTimeoutDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  navigateTo: PropTypes.string.isRequired,
  onNavigatePressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
};
