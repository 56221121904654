import { downloadingDepartments, setDepartments } from '../simple-action';
import { downloadDepartments, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDepartments(true));

    const { token } = getState().authentication;
    const { departmentsSearchText } = getState().notification;

    const list = await downloadDepartments(transformSearchText(departmentsSearchText.toLowerCase()),
      token);

    if (list) {
      dispatch(setDepartments(list));
    }
  } finally {
    dispatch(downloadingDepartments(false));
  }
};
