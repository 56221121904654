import {
  buildQueryParams,
  convertArrToObj,
  sendDeleteRequest, sendGetRequest,
  sendPostFileRequest,
  sendPostRequest, sendPutRequest,
  transformQueryResult,
} from '../../helper';
import { sortAsc } from '../../helper/helper';
import {
  REST_URL_ADD_EDIT_DOCUMENT_GENERATOR_TEMPLATE,
  REST_URL_DOCUMENT_GENERATOR_DOCUMENTS,
  REST_URL_DOCUMENT_GENERATOR_ENABLE_DISABLE_TEMPLATE,
  REST_URL_DOCUMENT_GENERATOR_TEMPLATES,
  REST_URL_GENERATE_DOCUMENT_TEMPLATE_PREVIEW,
  REST_URL_TEMPLATE_PAPER_SIZES,
  REST_URL_DOCUMENT_GENERATOR_LOCKING_UNLOCKING_TEMPLATE,
  REST_URL_VIEW_DELETE_DOCUMENT_GENERATOR_TEMPLATE,
  REST_URL_VIEW_DOCUMENT_GENERATOR_DOCUMENT,
} from './constant';

export * from '../../helper';

export const downloadDocumentGeneratorTemplates = async (pageNumber, pageSize, orderBy, searchText,
  token) => {
  const url = buildQueryParams(
    REST_URL_DOCUMENT_GENERATOR_TEMPLATES, pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadDocumentGeneratorTemplate = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_DOCUMENT_GENERATOR_TEMPLATE.replace(/\{id\}/, id), token);
  return response;
};

export const enableDisableDocumentGeneratorTemplate = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_DOCUMENT_GENERATOR_ENABLE_DISABLE_TEMPLATE, body, token);
};

export const lockUnlockDocumentGeneratorTemplate = async (id, lockStatus, token) => {
  const body = { id, locked: lockStatus };
  await sendPutRequest(REST_URL_DOCUMENT_GENERATOR_LOCKING_UNLOCKING_TEMPLATE, body, token);
};

export const addDocumentGeneratorTemplate = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_DOCUMENT_GENERATOR_TEMPLATE, body, token);
};

export const editDocumentGeneratorTemplate = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_DOCUMENT_GENERATOR_TEMPLATE, body, token);
};

export const deleteDocumentGeneratorTemplate = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_DOCUMENT_GENERATOR_TEMPLATE.replace(/\{id\}/, id), token);
};

export const generateDocumentTemplatePreview = async (body, token) => {
  const response = await sendPostFileRequest(
    REST_URL_GENERATE_DOCUMENT_TEMPLATE_PREVIEW, body, token,
  );
  return response;
};

export const downloadTemplatePaperSizes = async (token) => {
  const response = await sendGetRequest(REST_URL_TEMPLATE_PAPER_SIZES, token);
  const sortedResp = response.sort((a, b) => sortAsc(a.name, b.name));
  return convertArrToObj(sortedResp, 'name');
};

export const downloadDocumentGeneratorDocuments = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(
    REST_URL_DOCUMENT_GENERATOR_DOCUMENTS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadDocumentGeneratorDocument = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DOCUMENT_GENERATOR_DOCUMENT.replace(/\{id\}/, id), token);
  return response;
};

export const transformTemplateDropdownData = (data) => Object.values(data).map((item) => ({
  label: item.name,
  value: item.id,
}));
