import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles,
} from '@material-ui/core';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  COLOR_BACKGROUND, COLOR_DANGEROUS, COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY,
  COLOR_TYPE_IMAGE, COLOR_TYPE_QR_CODE, COLOR_TYPE_TEXT,
  DIALOG_TYPE_ADD, PDF_TYPE_IMAGE, PDF_TYPE_QR_CODE, PDF_TYPE_TEXT,
} from '../../constant';
import Stepper from './stepper';
import { AccentButton } from '../../../../component';

const textIcon = require('../../../../asset/text-icon.png');
const qrcodeIcon = require('../../../../asset/qr-code-icon.png');
const imageIcon = require('../../../../asset/image-icon.png');

const styles = (() => ({
  paperWithStepper: {
    minHeight: '96vh',
  },
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  headerButtonText: {
    color: COLOR_DANGEROUS,
    marginRight: 20,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'auto',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '10px 0px',
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
    padding: '0px 10px',
  },
  buttonText: {
    color: COLOR_DANGEROUS,
    padding: '0px 10px',
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: '24px',
      color: COLOR_PRIMARY,
    },
    padding: '16px 30px 8px 30px',
  },
  cardTypeContainer: {
    padding: '5px 10px 5px 10px',
    margin: '10px 30px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardTypeText: {
    backgroundColor: COLOR_TYPE_TEXT,
  },
  cardTypeQr: {
    backgroundColor: COLOR_TYPE_QR_CODE,
  },
  cardTypeImage: {
    backgroundColor: COLOR_TYPE_IMAGE,
  },
  square: {
    height: 24,
    width: 24,
  },
  text: {
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '0px 0px 0px 6px',
  },
}));

const getTypeData = (type, classes) => {
  switch (type) {
    case PDF_TYPE_TEXT:
      return {
        color: classes.cardTypeText,
        icon: textIcon,
        text: 'Text',
      };
    case PDF_TYPE_QR_CODE:
      return {
        color: classes.cardTypeQr,
        icon: qrcodeIcon,
        text: 'QR Code',
      };
    case PDF_TYPE_IMAGE:
      return {
        color: classes.cardTypeImage,
        icon: imageIcon,
        text: 'Image',
      };
    default:
      return COLOR_BACKGROUND;
  }
};

const renderType = (type, classes) => {
  const typeData = getTypeData(type, classes);
  return (
    <div className={`${classes.cardTypeContainer} ${typeData.color} `}>
      <img alt="logo" className={classes.square} src={typeData.icon} />
      <Typography className={classes.text}>
        {typeData.text}
      </Typography>
    </div>
  );
};

const AddEditDialog = ({
  stepContent, stepTitle,
  disabled, submitting, usefullWidthDialog, visibility,
  handleSubmit, onCancelPressed, onClosePressed, onError, onSubmitPressed,
  classes, children, dialogType,
  buttonCaption, headerContainerStyle, title, pdfType, selectedImageName,
}) => {
  const onCancelPress = () => {
    onCancelPressed();
    onClosePressed();
  };

  const onHandleSubmit = async (e) => {
    try {
      await onSubmitPressed(e, pdfType, selectedImageName);
      onClosePressed();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Dialog
      open={visibility}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={stepContent.length > 0 || usefullWidthDialog}
      classes={stepContent.length > 0 ? { paper: classes.paperWithStepper }
        : { paper: classes.paper }}
    >
      <div className={`${classes.headerContainer} ${headerContainerStyle}`}>
        <DialogTitle className={classes.title}>
          {
            dialogType === DIALOG_TYPE_ADD
              ? LocalizedString.annotationDesignerPage.buttonCaptionAddNew
              : LocalizedString.annotationDesignerPage.buttonCaptionEdit
          }
        </DialogTitle>
        {stepContent.length > 0 && (
          <AccentButton
            onClick={() => onCancelPress(onCancelPressed, onClosePressed)}
            variant="text"
            caption={GlobalLocalizedString.common.buttonCaptionCancel}
            className={classes.headerButtonText}
            disabled={disabled || submitting}
            size="small"
          />
        )}
        {renderType(title, classes)}
      </div>

      <DialogContent classes={stepContent.length > 0 ? { root: classes.dialogContent } : undefined}>
        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          className={classes.form}
        >
          {stepContent.length > 0 ? (
            <Stepper
              stepTitle={stepTitle}
              stepContent={stepContent}
              addButton={(
                <AccentButton
                  variant="contained"
                  type="submit"
                  loading={submitting}
                  caption={GlobalLocalizedString.common.buttonCaptionSave}
                  disabled={disabled || submitting}
                  size="small"
                />
              )}
              handleSubmit={handleSubmit}
            />
          ) : children}

          <DialogActions className={classes.buttonContainer}>
            {stepContent.length === 0 && (
            <AccentButton
              onClick={() => onCancelPress(onCancelPressed, onClosePressed)}
              variant="outlined"
              caption={GlobalLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              disabled={disabled || submitting}
              size="small"
            />
            )}

            {stepContent.length === 0 && (
            <AccentButton
              variant="contained"
              type="submit"
              className={classes.button}
              loading={submitting}
              disableElevation
              caption={buttonCaption}
              disabled={disabled || submitting}
              size="small"
            />
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AddEditDialog);

AddEditDialog.propTypes = {
  stepContent: PropTypes.arrayOf(PropTypes.node).isRequired,
  stepTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  usefullWidthDialog: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func,
  onClosePressed: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  buttonCaption: PropTypes.string.isRequired,
  headerContainerStyle: PropTypes.string,
  dialogType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pdfType: PropTypes.string.isRequired,
  selectedImageName: PropTypes.string.isRequired,
};

AddEditDialog.defaultProps = {
  disabled: false,
  usefullWidthDialog: false,
  onCancelPressed: () => {},
  children: (<div />),
  headerContainerStyle: '',
};
