import {
  DOWNLOADING_CHALLENGES, ADDING_EDITING_CHALLENGE, DOWNLOADING_DELETING_CHALLENGE,
  SET_CHALLENGE_SELECTED_PAGE_SIZE, SET_CHALLENGE_TAPPED_ID, SET_CHALLENGE_SEARCH_TEXT,
  SET_CHALLENGE_SELECTED_ORDER_BY, SET_CHALLENGE_SELECTED_CATEGORY,
  SET_CHALLENGE_SELECTED_VISIBILITY_STATUS, SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU,
  SET_CHALLENGE_SELECTED_SCAN_POLICY, SET_CHALLENGE_SELECTED_IS_PROMOTED,
} from '../action';
import { INITIAL_ORDER_BY_CHALLENGES } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  selectedCategory: '',
  selectedScanPolicy: '',
  selectedVisibilityStatus: '',
  orderBy: INITIAL_ORDER_BY_CHALLENGES,
  selectedAddMediaMenu: null,
  selectedIsPromoted: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CHALLENGES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_CHALLENGE:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_CHALLENGE:
      return { ...state, downloadingDeleting: action.status };
    case SET_CHALLENGE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CHALLENGE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CHALLENGE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CHALLENGE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CHALLENGE_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.option };
    case SET_CHALLENGE_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.option };
    case SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU:
      return { ...state, selectedAddMediaMenu: action.text };
    case SET_CHALLENGE_SELECTED_SCAN_POLICY:
      return { ...state, selectedScanPolicy: action.option };
    case SET_CHALLENGE_SELECTED_IS_PROMOTED:
      return { ...state, selectedIsPromoted: action.option };
    default: return state;
  }
};
