import {
  RXFIELD_FORUM_CATEGORY_NAME, RXFIELD_FORUM_CATEGORY_DESCRIPTION, RXFIELD_FORUM_CATEGORY_IMAGE,
  RXFIELD_FORUM_POST_TITLE, RXFIELD_FORUM_POST_CONTENT, RXFIELD_FORUM_POST_CATEGORY,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';

export const rxformValidateFormCategory = (values) => {
  const requiredFields = [
    RXFIELD_FORUM_CATEGORY_NAME,
    RXFIELD_FORUM_CATEGORY_DESCRIPTION,
    RXFIELD_FORUM_CATEGORY_IMAGE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateFormPost = (values) => {
  const requiredFields = [
    RXFIELD_FORUM_POST_TITLE,
    RXFIELD_FORUM_POST_CATEGORY,
    RXFIELD_FORUM_POST_CONTENT,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
