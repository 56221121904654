import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_ARTICLES_TITLE, RXFIELD_ARTICLES_PUBLISHED_DATE,
  RXFIELD_ARTICLES_VISIBILITY_STATUS, RXFIELD_ARTICLES_MEDIA, RXFIELD_ARTICLES_CATEGORY,
  RXFIELD_ARTICLE_CATEGORY_NAME,
} from './constant';

export const rxformValidateArticles = (values) => {
  const requiredFields = [
    RXFIELD_ARTICLES_TITLE,
    RXFIELD_ARTICLES_PUBLISHED_DATE,
    RXFIELD_ARTICLES_VISIBILITY_STATUS,
    RXFIELD_ARTICLES_MEDIA,
    RXFIELD_ARTICLES_CATEGORY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateArticleCategory = (values) => {
  const requiredFields = [
    RXFIELD_ARTICLE_CATEGORY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
