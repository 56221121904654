import { PAGE_MODE_TABLE } from '../../../constant';
import { downloadInfoMapping, deleteInfoMapping } from '../../../helper';
import {
  addInfoMapping, clearInfoMappings, downloadingDeletingInfoMapping, setAlertErrorMessage,
  setFunctionalPageMode, setInfoMappingTappedId,
} from '../simple-action';
import downloadInfoMappingsAsync from './downloadInfoMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingInfoMapping(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiInfoMapping;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteInfoMapping(tappedId, token);
      dispatch(setInfoMappingTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearInfoMappings());
      dispatch(downloadInfoMappingsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadInfoMapping(tappedId, token);
      dispatch(addInfoMapping(result));
    }
  } finally {
    dispatch(downloadingDeletingInfoMapping(false));
  }
};
