import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN, MAPPING_OPERATORS, PAGE_MODE_VIEW, RXFIELD_COMPANY_MAPPING_ADDRESS,
  RXFIELD_COMPANY_MAPPING_ADDRESS_TWO, RXFIELD_COMPANY_MAPPING_CITY,
  RXFIELD_COMPANY_MAPPING_COMPANY_LOGO_URL, RXFIELD_COMPANY_MAPPING_COMPANY_NAME,
  RXFIELD_COMPANY_MAPPING_FAX, RXFIELD_COMPANY_MAPPING_IMAGE_TWO_URL,
  RXFIELD_COMPANY_MAPPING_IMAGE_URL, RXFIELD_COMPANY_MAPPING_OPERATOR,
  RXFIELD_COMPANY_MAPPING_ORDER, RXFIELD_COMPANY_MAPPING_PHONE, RXFIELD_COMPANY_MAPPING_PHRASE,
  RXFIELD_COMPANY_MAPPING_POSTAL_CODE, RXFIELD_COMPANY_MAPPING_PROVINCE,
  RXFIELD_COMPANY_MAPPING_WEBSITE, RXFORM_COMPANY_MAPPING, RXSTATE_COMPANY_MAPPINGS,
  RXSTATE_COMPANY_MAPPING_PAGE, RXFIELD_INFO_MAPPING_TYPE, COMPANY_MAPPING_TYPE,
} from '../../constant';
import { renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateCompanyMapping } from '../../validation';
import { FormInitialValueShape } from '../../../../type';

const renderDialogContent = (
  initialValues, addingEditing, downloading, downloadingDeleting,
  onOperatorOptionSelected, pageMode,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_ORDER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyMappingScreen.labelOrder}
          label={LocalizedString.companyMappingScreen.labelOrder}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_INFO_MAPPING_TYPE}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.companyMappingScreen.labelType}
          label={LocalizedString.companyMappingScreen.labelType}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={COMPANY_MAPPING_TYPE}
          value={initialValues.type}
          required
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_OPERATOR}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.companyMappingScreen.labelOperator}
          label={LocalizedString.companyMappingScreen.labelOperator}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={MAPPING_OPERATORS}
          value={initialValues.mappingOperator}
          onOptionSelected={onOperatorOptionSelected}
          required
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_PHRASE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyMappingScreen.labelPhrase}
          label={LocalizedString.companyMappingScreen.labelPhrase}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          multiline
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_COMPANY_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyMappingScreen.labelCompanyName}
          label={LocalizedString.companyMappingScreen.labelCompanyName}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_ADDRESS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyMappingScreen.labelAddress}
          label={LocalizedString.companyMappingScreen.labelAddress}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_ADDRESS_TWO}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyMappingScreen.labelAddressTwo}
          label={LocalizedString.companyMappingScreen.labelAddressTwo}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_CITY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyMappingScreen.labelCity}
          label={LocalizedString.companyMappingScreen.labelCity}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item sm md>
        <Field
          name={RXFIELD_COMPANY_MAPPING_PROVINCE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.companyMappingScreen.labelProvince}
          label={LocalizedString.companyMappingScreen.labelProvince}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item sm md>
        <Grid item sm md>
          <Field
            name={RXFIELD_COMPANY_MAPPING_POSTAL_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyMappingScreen.labelPostalCode}
            label={LocalizedString.companyMappingScreen.labelPostalCode}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_COMPANY_MAPPING_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyMappingScreen.labelPhone}
            label={LocalizedString.companyMappingScreen.labelPhone}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_COMPANY_MAPPING_FAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyMappingScreen.labelFax}
            label={LocalizedString.companyMappingScreen.labelFax}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_COMPANY_MAPPING_COMPANY_LOGO_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyMappingScreen.labelPlaceholderCompanyLogoUrl}
            label={LocalizedString.companyMappingScreen.labelCompanyLogoUrl}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_COMPANY_MAPPING_IMAGE_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyMappingScreen.labelPlaceholderImage1Url}
            label={LocalizedString.companyMappingScreen.labelImageUrl}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_COMPANY_MAPPING_IMAGE_TWO_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyMappingScreen.labelPlaceholderImage2Url}
            label={LocalizedString.companyMappingScreen.labelImageTwoUrl}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_COMPANY_MAPPING_WEBSITE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.companyMappingScreen.labelWebsite}
            label={LocalizedString.companyMappingScreen.labelWebsite}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const CompanyMappingScreen = ({
  initialValues, addingEditing, downloading, downloadingDeleting, onAppear,
  onApplyAdvancedFilterPressed, handleSubmit, onCancelAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onConfirmDeletePressed,
  onCreatePressed, onDeletePressed, onEditPressed, onOperatorOptionSelected, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_COMPANY_MAPPINGS}
    uiPage={RXSTATE_COMPANY_MAPPING_PAGE}
    filterColumns={[
      {
        title: LocalizedString.infoMappingScreen.labelOperator,
        field: RXFIELD_COMPANY_MAPPING_OPERATOR,
        type: FILTER_TYPE_DROPDOWN,
        data: MAPPING_OPERATORS,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.infoMappingScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.companyMappingScreen.labelOrder, field: 'order', sorting: !downloading, width: 40,
      },
      { title: LocalizedString.companyMappingScreen.labelType, field: 'type', sorting: !downloading },
      { title: LocalizedString.companyMappingScreen.labelOperator, field: 'mappingOperator', sorting: !downloading },
      { title: LocalizedString.companyMappingScreen.labelPhrase, field: 'phrase', sorting: !downloading },
      { title: LocalizedString.companyMappingScreen.labelCompanyName, field: 'companyName', sorting: !downloading },
      { title: LocalizedString.companyMappingScreen.labelAddress, field: 'addressLine1', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.companyMappingScreen
      .buttonCaptionCreateNewCompanyMapping}
    deleteButtonCaption={LocalizedString.companyMappingScreen.buttonCaptionDeleteNewCompanyMapping}
    editButtonCaption={LocalizedString.companyMappingScreen.buttonCaptionEditNewCompanyMapping}
    title={LocalizedString.companyMappingScreen.title}
    createPermissionName="BCARD_CREATE_COMPANY_MAPPING"
    deletePermissionName="BCARD_DELETE_COMPANY_MAPPING"
    editPermissionName="BCARD_EDIT_COMPANY_MAPPING"
    useFullWidth
  >
    {renderDialogContent(
      initialValues, addingEditing, downloading, downloadingDeleting,
      onOperatorOptionSelected, pageMode,
    )}
  </FunctionalPage>
);
export default reduxForm({
  form: RXFORM_COMPANY_MAPPING,
  validate: rxformValidateCompanyMapping,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompanyMappingScreen);

CompanyMappingScreen.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onOperatorOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
