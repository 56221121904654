import {
  downloadingAttendanceSummaryTodayClockOutLocationStatus,
  setAttendanceSummaryTodayClockOutLocationStatus,
} from '../simple-action';
import { downloadAttendanceSummaryTodayClockOutLocationStatus, getPeriodDate } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTodayClockOutLocationStatus(true));

    const { authentication } = getState();
    const { token } = authentication;
    const { selectedPeriod } = getState().uiAttendanceSummary;

    const data = await downloadAttendanceSummaryTodayClockOutLocationStatus(
      token,
      getPeriodDate(selectedPeriod).startTime,
      getPeriodDate(selectedPeriod).endTime,

    );

    dispatch(setAttendanceSummaryTodayClockOutLocationStatus(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTodayClockOutLocationStatus(false));
  }
};
