import { downloadingArticles, setArticles } from '../simple-action';
import { downloadArticles, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, articles, uiArticle } = getState();
  const { token } = authentication;
  const { meta, data } = articles;
  const { selectedPageSize, searchBarText, orderBy } = uiArticle;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingArticles(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadArticles(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setArticles(list));
    }
  } finally {
    dispatch(downloadingArticles(false));
  }
};
