
import { INITIAL_ORDER_BY_TOKEN_REQUESTS } from '../../constant';
import {
  ADDING_TOKEN_REQUEST, DOWNLOADING_TOKEN_REQUEST,
  DOWNLOADING_TOKEN_REQUESTS, SET_TOKEN_REQUEST_SEARCH_TEXT,
  SET_TOKEN_REQUEST_SELECTED_ORDER_BY, SET_TOKEN_REQUEST_SELECTED_PAGE_SIZE,
  SET_TOKEN_REQUEST_TAPPED_ID, SET_TOKEN_REQUEST_SELECTED_VOUCHER_ID,
  CANCELING_TOKEN_REQUEST,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  canceling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_TOKEN_REQUESTS,
  selectedVoucherId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_TOKEN_REQUESTS:
      return { ...state, downloading: action.status };
    case ADDING_TOKEN_REQUEST:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_TOKEN_REQUEST:
      return { ...state, downloadingDeleting: action.status };
    case SET_TOKEN_REQUEST_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_TOKEN_REQUEST_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_TOKEN_REQUEST_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_TOKEN_REQUEST_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_TOKEN_REQUEST_SELECTED_VOUCHER_ID:
      return { ...state, selectedVoucherId: action.id };
    case CANCELING_TOKEN_REQUEST:
      return { ...state, canceling: action.status };
    default: return state;
  }
};
