import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Paper, withStyles } from '@material-ui/core';
import {
  ArgumentAxis, BarSeries, Chart, Title, Tooltip, ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, HoverState } from '@devexpress/dx-react-chart';
import classNames from 'clsx';

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return (Target) => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const TooltipTitle = setStyle({
  fontWeight: 'bold',
  paddingBottom: 0,
})(Tooltip.Content);

const TooltipBody = setStyle({
  paddingTop: 0,
})(Tooltip.Content);

const SimpleBarChart = ({
  data, loading, argumentField, title, valueField,
}) => {
  const [targetItem, setTargetItem] = useState(null);

  const TooltipContent = () => (
    <div>
      <div>
        <TooltipTitle
          text={data[targetItem.point][argumentField]}
        />
      </div>
      <div>
        <TooltipBody
          text={data[targetItem.point][valueField]}
        />
      </div>
    </div>
  );

  if (loading) {
    return (
      <CircularProgress color="inherit" size={20} />
    );
  }
  return (
    <Paper>
      <Chart data={data}>
        <ArgumentAxis />
        <ValueAxis />
        <BarSeries
          valueField={valueField}
          argumentField={argumentField}
        />
        <Title text={title} />
        <Animation />
        <EventTracker />
        <HoverState />
        <Tooltip
          targetItem={targetItem}
          onTargetItemChange={(target) => setTargetItem(target)}
          contentComponent={TooltipContent}
        />
        <Animation />
      </Chart>
    </Paper>
  );
};

export default SimpleBarChart;

SimpleBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  argumentField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
};

SimpleBarChart.defaultProps = {
  loading: false,
};
