import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_SHORTLINK_URL,
} from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateShortLink = (values) => {
  const requiredFields = [
    RXFIELD_SHORTLINK_URL,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
