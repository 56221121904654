import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { FunctionalPage, MapDrawer, SectionTitle } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, DRAW_MODE_MARKER, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_MODE_DATE, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_TIME,
  RXFIELD_ACTIVITY_ACTIVITY_TYPE, RXFIELD_ACTIVITY_ATTENDANCE_DATE,
  RXFIELD_ACTIVITY_ACTIVITY_DESCRIPTION, RXFIELD_ACTIVITY_COMPANY, RXFIELD_ACTIVITY_CUSTOMER,
  RXFIELD_ACTIVITY_DIVISION, RXFIELD_ACTIVITY_DEPARTMENT, RXFIELD_ACTIVITY_END_LATITUDE,
  RXFIELD_ACTIVITY_END_LOCATION_ID, RXFIELD_ACTIVITY_END_LOCATION_NAME,
  RXFIELD_ACTIVITY_END_LOCATION_NOTES, RXFIELD_ACTIVITY_END_LONGITUDE, RXFIELD_ACTIVITY_END_TIME,
  RXFIELD_ACTIVITY_MANAGER, RXFIELD_ACTIVITY_PROFILE, RXFIELD_ACTIVITY_START_LATITUDE,
  RXFIELD_ACTIVITY_START_LOCATION_ID, RXFIELD_ACTIVITY_START_LOCATION_NAME,
  RXFIELD_ACTIVITY_START_LONGITUDE, RXFIELD_ACTIVITY_START_TIME, RXFORM_ACTIVITY,
  RXSTATE_ACTIVITIES, RXSTATE_ACTIVITY_PAGE, FILTER_TYPE_MULTIPLE_VALUES,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const renderDialogContent = (defaultClockInCenter, defaultClockInMarker, defaultClockOutCenter,
  defaultClockOutMarker, downloadingDeleting) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_PROFILE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderEmployee}
              label={LocalizedString.activityScreen.placeholderEmployee}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_MANAGER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderManager}
              label={LocalizedString.activityScreen.placeholderManager}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_ATTENDANCE_DATE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.activityScreen.placeholderAttendanceDate}
              disabled
              pickerMode={PICKER_MODE_DATE}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_CUSTOMER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderCustomer}
              label={LocalizedString.activityScreen.placeholderCustomer}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_ACTIVITY_TYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderActivityType}
              label={LocalizedString.activityScreen.placeholderActivityType}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_ACTIVITY_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderActivityDescription}
              label={LocalizedString.activityScreen.placeholderActivityDescription}
              disabled
              multiline
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.activityScreen.labelClockIn} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_START_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.activityScreen.placeholderStartTime}
              disabled
              pickerMode={PICKER_MODE_TIME}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_START_LOCATION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderStartLocationId}
              label={LocalizedString.activityScreen.placeholderStartLocationId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_START_LOCATION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderStartLocationName}
              label={LocalizedString.activityScreen.placeholderStartLocationName}
              disabled
              multiline
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_ACTIVITY_START_LATITUDE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.activityScreen.placeholderStartLatitude}
                label={LocalizedString.activityScreen.placeholderStartLatitude}
                disabled
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_ACTIVITY_START_LONGITUDE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.activityScreen.placeholderStartLongitude}
                label={LocalizedString.activityScreen.placeholderStartLongitude}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography>{LocalizedString.activityScreen.placeholderMap}</Typography>
            <MapDrawer
              drawingMode={DRAW_MODE_MARKER}
              center={defaultClockInCenter}
              marker={defaultClockInMarker}
              disabled
              containerStyle={{ height: '300px' }}
              loading={downloadingDeleting}
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.activityScreen.labelClockOut} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_END_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.activityScreen.placeholderEndTime}
              disabled
              pickerMode={PICKER_MODE_TIME}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_END_LOCATION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderEndLocationId}
              label={LocalizedString.activityScreen.placeholderEndLocationId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_END_LOCATION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderEndLocationName}
              label={LocalizedString.activityScreen.placeholderEndLocationName}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACTIVITY_END_LOCATION_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.activityScreen.placeholderEndActivityNotes}
              label={LocalizedString.activityScreen.placeholderEndActivityNotes}
              disabled
              multiline
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_ACTIVITY_END_LATITUDE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.activityScreen.placeholderEndLatitude}
                label={LocalizedString.activityScreen.placeholderEndLatitude}
                disabled
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_ACTIVITY_END_LONGITUDE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.activityScreen.placeholderEndLongitude}
                label={LocalizedString.activityScreen.placeholderEndLongitude}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography>{LocalizedString.activityScreen.placeholderMap}</Typography>
            <MapDrawer
              drawingMode={DRAW_MODE_MARKER}
              center={defaultClockOutCenter}
              marker={defaultClockOutMarker}
              disabled
              containerStyle={{ height: '300px' }}
              loading={downloadingDeleting}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
);
const ActivityPage = ({
  initialValues, activityTypes, companies, customers, departments, divisions, employees,
  downloading, downloadingDeleting, loadingActivityType, loadingCompanies,
  loadingCustomer, loadingDepartments, loadingDivisions, loadingEmployee,
  handleSubmit, onActivityTypeOptionSelected, onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onChangeActivityTypeText,
  onChangeCustomerText, onChangeCompanyText, onChangeEmployeeText, onChangeDepartmentText,
  onChangeDivisionText, onChangePage, onChangePageSize, onDownloadPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged,
  onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_ACTIVITIES}
    uiPage={RXSTATE_ACTIVITY_PAGE}
    filterColumns={[
      {
        title: LocalizedString.activityScreen.labelAttendanceDate,
        field: RXFIELD_ACTIVITY_START_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.activityScreen.labelEmployee,
        field: RXFIELD_ACTIVITY_PROFILE,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: employees,
        loading: loadingEmployee,
        onChangeFilterText: onChangeEmployeeText,
      },
      {
        title: LocalizedString.activityScreen.labelDepartment,
        field: RXFIELD_ACTIVITY_DEPARTMENT,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: departments,
        loading: loadingDepartments,
        onChangeFilterText: onChangeDepartmentText,
      },
      {
        title: LocalizedString.activityScreen.labelDivision,
        field: RXFIELD_ACTIVITY_DIVISION,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: divisions,
        loading: loadingDivisions,
        onChangeFilterText: onChangeDivisionText,
      },
      {
        title: LocalizedString.activityScreen.labelCompany,
        field: RXFIELD_ACTIVITY_COMPANY,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: companies,
        loading: loadingCompanies,
        onChangeFilterText: onChangeCompanyText,
      },
      {
        title: LocalizedString.activityScreen.labelActivityType,
        field: RXFIELD_ACTIVITY_ACTIVITY_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: activityTypes,
        loading: loadingActivityType,
        onChangeFilterText: onChangeActivityTypeText,
        onFilterOptionSelected: onActivityTypeOptionSelected,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.activityScreen.labelCustomer,
        field: RXFIELD_ACTIVITY_CUSTOMER,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: customers,
        loading: loadingCustomer,
        onChangeFilterText: onChangeCustomerText,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.activityScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.activityScreen.labelEmployee, field: 'profile.fullName', sorting: !downloading },
      { title: LocalizedString.activityScreen.labelActivityType, field: 'activityType.name', sorting: !downloading },
      { title: LocalizedString.activityScreen.labelCustomer, field: 'customer.name', sorting: !downloading },
      {
        title: LocalizedString.activityScreen.labelStartDate,
        field: 'startTime',
        sorting: !downloading,
        render: ({ startTime }) => (startTime
          ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.activityScreen.labelEndDate,
        field: 'endTime',
        sorting: !downloading,
        render: ({ endTime }) => (endTime
          ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onDownloadPressed={onDownloadPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.activityScreen.title}
    enableSave
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues.clockInCenter, initialValues.clockInMarker,
      initialValues.clockOutCenter, initialValues.clockOutMarker, downloadingDeleting)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_ACTIVITY,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ActivityPage);

ActivityPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  activityTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  companies: PropTypes.arrayOf(PropTypes.string).isRequired,
  customers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  departments: PropTypes.arrayOf(PropTypes.string).isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  employees: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingActivityType: PropTypes.bool.isRequired,
  loadingCompanies: PropTypes.bool.isRequired,
  loadingCustomer: PropTypes.bool.isRequired,
  loadingDepartments: PropTypes.bool.isRequired,
  loadingDivisions: PropTypes.bool.isRequired,
  loadingEmployee: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onActivityTypeOptionSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeActivityTypeText: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangeCustomerText: PropTypes.func.isRequired,
  onChangeDepartmentText: PropTypes.func.isRequired,
  onChangeDivisionText: PropTypes.func.isRequired,
  onChangeEmployeeText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
