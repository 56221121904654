import { downloadingCalendars, setCalendars } from '../simple-action';
import { downloadCalendars, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, calendars, uiCalendar } = getState();
  const { token } = authentication;
  const { meta } = calendars;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiCalendar;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingCalendars(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCalendars(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setCalendars(list));
  } finally {
    dispatch(downloadingCalendars(false));
  }
};
