import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  RXFIELD_FAQ_QUESTION, RXFIELD_FAQ_SHORT_ANSWER, RXFIELD_FAQ_DETAILED_ANSWER,
  RXFIELD_FAQ_ORDER, RXFIELD_FAQ_VISIBILITY_STATUS, RXFIELD_FAQ_CATEGORY,
  RXFORM_FAQ, RXSTATE_FAQ, RXSTATE_FAQ_PAGE,
} from '../../constant';
import {
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW, visibilityStatuses,
} from '../../../../constant';
import {
  renderReduxFormOutlinedTextField, renderReduxFormRichTextEditorField,
  renderReduxFormOutlinedDropdownTextField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateFaq } from '../../validation';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  editorStyle: {
    height: 'calc(100% - 80px)',
  },
  editStyle: {
    height: 'calc(100% - 60px)',
  },
  editorContainerStyle: {
    marginBottom: 30,
  },
}));

const renderDialogContent = (
  addingEditing, pageMode, onChangeContentText, downloadingDeleting,
  defaultVisibilityStatusValue, onVisibilityStatusOptionSelected,
  categories, faqCategoryDefaultValue, loadingCategory,
  onChangeCategoryText, onCategoryOptionSelected, classes,
  dialogVisibility,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_FAQ_QUESTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.faqPage.placeholderQuestion}
            label={LocalizedString.faqPage.placeholderQuestion}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
          <Field
            name={RXFIELD_FAQ_VISIBILITY_STATUS}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.faqPage.placeholderVisibilityStatus}
            label={LocalizedString.faqPage.placeholderVisibilityStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={visibilityStatuses}
            value={defaultVisibilityStatusValue}
            onOptionSelected={onVisibilityStatusOptionSelected}
            required
          />
          <Field
            name={RXFIELD_FAQ_CATEGORY}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.faqPage.placeholderCategory}
            label={LocalizedString.faqPage.placeholderCategory}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={categories}
            value={faqCategoryDefaultValue}
            loading={loadingCategory}
            onChangeText={onChangeCategoryText}
            onOptionSelected={onCategoryOptionSelected}
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_FAQ_SHORT_ANSWER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.faqPage.placeholderShortAnswer}
            label={LocalizedString.faqPage.placeholderShortAnswer}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
            required
          />
          <Field
            name={RXFIELD_FAQ_ORDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.faqPage.placeholderOrder}
            label={LocalizedString.faqPage.placeholderOrder}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
            type="number"
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item className={classes.editorContainerStyle}>
        <Field
          name={RXFIELD_FAQ_DETAILED_ANSWER}
          component={renderReduxFormRichTextEditorField}
          label={LocalizedString.faqPage.placeholderDetailedAnswer}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          onChangeText={onChangeContentText}
          loading={downloadingDeleting}
          editorStyle={dialogVisibility ? classes.editorStyle : classes.editStyle}
        />
      </Grid>
    </Grid>
  </Grid>

);

const FaqPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed, onChangeContentText, downloadingDeleting,
  initialValues, onVisibilityStatusOptionSelected,
  categories, loadingCategory, onChangeCategoryText, onCategoryOptionSelected,
  dialogVisibility, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onResetAdvancedFilterPressed, onDownloadPressed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_FAQ}
      uiPage={RXSTATE_FAQ_PAGE}
      tableColumns={[
        {
          title: LocalizedString.faqPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.faqPage.labelQuestion, field: 'question', sorting: !downloading },
        { title: LocalizedString.faqPage.labelShortAnswer, field: 'shortAnswer', sorting: !downloading },
        { title: LocalizedString.faqPage.labelOrder, field: 'order', sorting: !downloading },
        { title: LocalizedString.faqPage.labelVisibilityStatus, field: 'visibilityStatus' },
        { title: LocalizedString.faqPage.labelCategory, field: 'category.name', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.faqPage.placeholderVisibilityStatus,
          field: RXFIELD_FAQ_VISIBILITY_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: visibilityStatuses,
        },
        {
          title: LocalizedString.faqPage.placeholderCategory,
          field: RXFIELD_FAQ_CATEGORY,
          type: FILTER_TYPE_DROPDOWN,
          data: categories,
          loading: loadingCategory,
          onChangeFilterText: onChangeCategoryText,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmDeletePressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      onDownloadPressed={onDownloadPressed}
      createNewButtonCaption={LocalizedString.faqPage.buttonCaptionCreateNewFaq}
      deleteButtonCaption={LocalizedString.faqPage.buttonCaptionDeleteFaq}
      title={LocalizedString.faqPage.title}
      editButtonCaption={LocalizedString.faqPage.buttonCaptionEditFaq}
      useFullWidth
      enableSave
    >
      {renderDialogContent(
        addingEditing, pageMode, onChangeContentText, downloadingDeleting,
        initialValues.visibilityStatus, onVisibilityStatusOptionSelected,
        categories, initialValues.category.label, loadingCategory,
        onChangeCategoryText, onCategoryOptionSelected, classes,
        dialogVisibility,
      )}
    </FunctionalPage>
  );
};


export default reduxForm({
  form: RXFORM_FAQ,
  validate: rxformValidateFaq,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FaqPage);

FaqPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  categories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingCategory: PropTypes.bool.isRequired,
  dialogVisibility: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onChangeContentText: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
