import { connect } from 'react-redux';
import UserByDeviceModel from './user-by-device-model.presentation';
import { getNDaysAgo, toMoment } from '../../../../helper/helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import { setAnalyticsUserByDeviceModelDateRanges, setUserByDeviceModelFilterDialogVisibility, setAlertErrorMessage } from '../../redux/action/simple-action';
import downloadAnalyticsUsersByDeviceAsync from '../../redux/action/async/downloadAnalyticsUsersByDeviceAsync';
import { getProgresssBarChartPercentage } from '../../helper';

const getFilterDateRange = (dateRange) => {
  let dateFrom = dateRange.from;
  let dateTo = dateRange.to;

  if (dateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (dateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const getUserByDeviceModelData = (state) => {
  const { devicesByUsers } = state.analytics;
  const totalDevice = devicesByUsers?.totalDevice || 0;
  const data = devicesByUsers?.dataArray?.map((device) => ({
    ...device,
    percentage: getProgresssBarChartPercentage(device.count, totalDevice),
  }));

  return data;
};

const mapStateToProps = (state) => ({
  usersByDeviceModel: getUserByDeviceModelData(state),
  downloadingDeviceModelsByUsers: state.uiAnalytics.downloadingDeviceModelsByUsers,
  userByDeviceModelDateRange:
    getFilterDateRange(state.uiAnalytics.userByDeviceModelDateRange),
  userByDeviceModelFilterDialogVisibility:
        state.uiAnalytics.userByDeviceModelFilterDialogVisibility,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterIconPressed: () => {
    dispatch(setUserByDeviceModelFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setUserByDeviceModelFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsUserByDeviceModelDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsUsersByDeviceAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserByDeviceModelFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsUserByDeviceModelDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsUsersByDeviceAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserByDeviceModelFilterDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserByDeviceModel);
