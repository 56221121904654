import { reset } from 'redux-form';
import {
  addingEditingPersonalHoliday, clearPersonalHolidays, setAlertErrorMessage,
  setPersonalHolidaySelectedFile,
} from '../simple-action';
import { addPersonalHoliday } from '../../../helper';
import { RXFORM_PERSONAL_HOLIDAY } from '../../../constant';
import downloadPersonalHolidaysAsync from './downloadPersonalHolidaysAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPersonalHoliday(true));

    const { token } = getState().authentication;
    const { selectedFile } = getState().uiPersonalHoliday;

    await addPersonalHoliday(selectedFile, token);

    dispatch(setPersonalHolidaySelectedFile(''));
    dispatch(reset(RXFORM_PERSONAL_HOLIDAY));
    dispatch(clearPersonalHolidays());
    dispatch(downloadPersonalHolidaysAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingPersonalHoliday(false));
  }
};
