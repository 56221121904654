export * from '../../constant';

export const REST_URL_ANALYTICS_DATA = '/core/api/v1/Analytics/v1beta/properties/{propertyId}:runReport?alt=json';

export const ROUTE_NAME_ANALYTICS = '/analytics';

export const MENUID_ANALYTICS = 'MENUID_ANALYTICS';

export const ANALYTICS_DATA_LIMIT = 10;
export const ANALYTICS_DATE_TWENTY_SEVEN_DAYS_AGO = '27daysAgo';
export const ANALYTICS_DATE_TWENTY_EIGHT_DAYS_AGO = '28daysAgo';
export const ANALYTICS_DATE_FIFTY_FIVE_DAYS_AGO = '55daysAgo';
export const ANALYTICS_DATE_ONE_MONTH_AGO = '30daysAgo';
export const ANALYTICS_DATE_TODAY = 'today';
export const ANALYTICS_DATE_YESTERDAY = 'yesterday';

export const ANALYTICS_CHART_TOP_5_DATA_COLOR_SCHEMES = [
  '#42A5F5',
  '#FF7043',
  '#9CCC65',
  '#FFCA28',
  '#26A69A',
];
