import React from 'react';
import PropTypes from 'prop-types';
import {
  FONT_SIZE_H1, FONT_SIZE_H2, FONT_SIZE_H3, FONT_SIZE_H4,
  FONT_SIZE_BODY_TITLE, FONT_SIZE_BODY_LARGE, FONT_SIZE_BODY_SMALL,
  FONT_SIZE_BODY, FONT_SIZE_BODY_EXTRA_SMALL,
  FONT_SIZE_BODY_LARGE_TEXTINPUT, FONT_SIZE_MY_POINT_TEXT,
  FONT_SIZE_BODY_PARAGRAPH, FONT_SIZE_BODY_EXTRA_SMALL_BADGE,
  FONT_SIZE_BODY_SMALL_INFORMATION, FONT_SIZE_BODY_EXTRA_LARGE,
} from '../constant';
import Label from './label';

export const H1 = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_H1} {...props}>
    {children}
  </Label>
);

export const H2 = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_H2} {...props}>
    {children}
  </Label>
);

export const H3 = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_H3} {...props}>
    {children}
  </Label>
);

export const H4 = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_H4} {...props}>
    {children}
  </Label>
);

export const BodyTitle = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_TITLE} bold {...props}>
    {children}
  </Label>
);

export const BodyLarge = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_LARGE} {...props}>
    {children}
  </Label>
);

export const BodyExtraLarge = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_EXTRA_LARGE} {...props}>
    {children}
  </Label>
);

export const BodySmall = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_SMALL} {...props}>
    {children}
  </Label>
);

export const Body = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY} {...props}>
    {children}
  </Label>
);

export const BodyLargeTextInput = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_LARGE_TEXTINPUT} {...props}>
    {children}
  </Label>
);

export const BodyParagraph = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_PARAGRAPH} {...props}>
    {children}
  </Label>
);

export const BodySmallInformation = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_SMALL_INFORMATION} {...props}>
    {children}
  </Label>
);

export const BodyExtraSmall = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_EXTRA_SMALL} {...props}>
    {children}
  </Label>
);

export const BodyExtraSmallBadge = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_BODY_EXTRA_SMALL_BADGE} {...props}>
    {children}
  </Label>
);

export const MyPointText = ({ children, ...props }) => (
  <Label fontSize={FONT_SIZE_MY_POINT_TEXT} {...props}>
    {children}
  </Label>
);

H1.propTypes = {
  children: PropTypes.node.isRequired,
};
H2.propTypes = {
  children: PropTypes.node.isRequired,
};
H3.propTypes = {
  children: PropTypes.node.isRequired,
};
H4.propTypes = {
  children: PropTypes.node.isRequired,
};
BodyTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
BodyLarge.propTypes = {
  children: PropTypes.node.isRequired,
};
BodyExtraLarge.propTypes = {
  children: PropTypes.node.isRequired,
};
BodySmall.propTypes = {
  children: PropTypes.node.isRequired,
};
Body.propTypes = {
  children: PropTypes.node.isRequired,
};
BodyLargeTextInput.propTypes = {
  children: PropTypes.node.isRequired,
};
BodyParagraph.propTypes = {
  children: PropTypes.node.isRequired,
};
BodySmallInformation.propTypes = {
  children: PropTypes.node.isRequired,
};
BodyExtraSmall.propTypes = {
  children: PropTypes.node.isRequired,
};
BodyExtraSmallBadge.propTypes = {
  children: PropTypes.node.isRequired,
};
MyPointText.propTypes = {
  children: PropTypes.node.isRequired,
};
