import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_FORUM_CATEGORIES = '/forum/api/v1/ForumCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_FORUM_CATEGORY = '/forum/api/v1/ForumCategory/{id}';
export const REST_URL_ADD_EDIT_FORUM_CATEGORY = '/forum/api/v1/ForumCategory';
export const REST_URL_FORUM_POSTS = '/forum/api/v1/Post?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_FORUM_POST = '/forum/api/v1/Post/{id}';
export const REST_URL_ADD_EDIT_FORUM_POST = '/forum/api/v1/Post';

export const ROUTE_NAME_FORUM = '/forum';
export const ROUTE_NAME_FORUM_CATEGORY = '/forum-category';
export const ROUTE_NAME_FORUM_POST = '/forum-post';

export const RXFORM_FORUM_CATEGORY = 'forumCategoryPage';
export const RXFORM_FORUM_POST = 'forumPostPage';

export const RXFIELD_FORUM_CATEGORY_PARENT_CATEGORY = 'parentCategory';
export const RXFIELD_FORUM_CATEGORY_STATUS = 'status';
export const RXFIELD_FORUM_CATEGORY_NAME = 'name';
export const RXFIELD_FORUM_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_FORUM_CATEGORY_IMAGE = 'image';
export const RXFIELD_FORUM_POST_TITLE = 'title';
export const RXFIELD_FORUM_POST_CONTENT = 'content';
export const RXFIELD_FORUM_POST_CONTENT_FORMAT = 'contentFormat';
export const RXFIELD_FORUM_POST_CATEGORY = 'category';
export const RXFIELD_FORUM_POST_AUTHOR = 'author';
export const RXFIELD_FORUM_POST_LOCKED = 'locked';
export const RXFIELD_FORUM_POST_PINNED = 'pinned';
export const RXFIELD_FORUM_POST_MEDIA = 'media';
export const RXFIELD_FORUM_POST_STATUS = 'status';
export const RXFIELD_FORUM_POST_SLUG = 'slug';

export const RXSTATE_FORUM_CATEGORIES = 'forumCategories';
export const RXSTATE_FORUM_CATEGORY_PAGE = 'uiForumCategory';
export const RXSTATE_FORUM_POSTS = 'forumPosts';
export const RXSTATE_FORUM_POST_PAGE = 'uiForumPost';

export const INITIAL_ORDER_BY_FORUM_CATEGORIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_FORUM_POSTS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_FORUM = 'MENUID_FORUM';
export const MENUID_FORUM_CATEGORY = 'MENUID_FORUM_CATEGORY';
export const MENUID_FORUM_POST = 'MENUID_FORUM_POST';

export const CONTENT_FORMAT_HTML = 'Html';
export const CONTENT_FORMAT_PLAINTEXT = 'PlainText';

export const CONTENTS_FORMATS = [
  { label: 'Html', value: CONTENT_FORMAT_HTML }, { label: 'Plain Text', value: CONTENT_FORMAT_PLAINTEXT },
];
