import { downloadVouchers, transformSearchText } from '../../../helper';
import { downloadingVouchers, setVouchers } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, vouchers, uiVoucher } = getState();
  const { token } = authentication;
  const { meta } = vouchers;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiVoucher;

  try {
    dispatch(downloadingVouchers(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadVouchers(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setVouchers(list));
    }
  } finally {
    dispatch(downloadingVouchers(false));
  }
};
