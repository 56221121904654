import { downloadingAnnotationDesignLists, setAnnotationDesignLists } from '../simple-action';
import { downloadAnnotationDesignLists, transformSearchText } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, annotationDesignLists, uiAnnotationDesignList, uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = annotationDesignLists;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiAnnotationDesignList;
  const { pageMode } = uiFunctionalPage;

  if (pageMode !== PAGE_MODE_TABLE) {
    return;
  }

  try {
    dispatch(downloadingAnnotationDesignLists(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAnnotationDesignLists(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setAnnotationDesignLists(list));
    }
  } finally {
    dispatch(downloadingAnnotationDesignLists(false));
  }
};
