import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  INITIAL_ORDER_BY_FORUM_CATEGORIES, MENUID_FORUM_CATEGORY, PAGE_MODE_TABLE,
  RXFIELD_FORUM_CATEGORY_PARENT_CATEGORY, RXFIELD_FORUM_CATEGORY_IMAGE,
  RXFORM_FORUM_CATEGORY, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  PAGE_MODE_EDIT,
} from '../../constant';
import { transformDropdownData, transformInitialValues } from '../../helper';
import {
  clearForumCategories, clearForumParentCategories, setActiveSideMenuItem,
  setForumCategoryAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setAlertConfirmationMessage, setForumParentCategorySearchText,
  setForumCategorySearchText, setForumCategorySelectedOrderBy,
  setForumCategorySelectedPageSize, setForumCategoryTappedId,
  downloadDeleteForumCategoryAsync, downloadForumCategoriesAsync,
  downloadForumParentCategoriesAsync, addEditForumCategoryAsync,
  setForumCategorySelectedParentCategory,
} from '../../redux/action';
import ForumCategoryPage from './forum-category.presentation';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const {
    forumCategories, uiFunctionalPage, uiForumCategory,
  } = state;
  const { data } = forumCategories;
  const { downloadingDeleting, tappedId } = uiForumCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      name: found.name ?? '',
      description: found.description ?? '',
      parentCategory: found.parentCategory
        ? { label: found.parentCategory.name, value: found.parentCategory.id }
        : { label: '', value: '' },
      image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
    }) : {
      name: '',
      description: '',
      parentCategory: '',
      image: '',
    };
  return result;
};

const getForumParentCategories = (state) => {
  const { uiForumCategory, uiFunctionalPage } = state;
  const { tappedId } = uiForumCategory;
  const { pageMode } = uiFunctionalPage;

  const forumParentCategories = transformDropdownData(state.forumParentCategories.data);

  const filteredForumParentCategories = pageMode === PAGE_MODE_EDIT
    ? forumParentCategories.filter((item) => item.value !== tappedId)
    : forumParentCategories;

  return filteredForumParentCategories;
};

const mapStateToProps = (state) => ({
  forumParentCategories: getForumParentCategories(state),
  loadingForumParentCategories: state.uiForumCategory.downloadingParentCategories,
  downloading: state.uiForumCategory.downloading,
  addingEditing: state.uiForumCategory.addingEditing,
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiForumCategory.tappedId,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setForumParentCategorySearchText(''));
    dispatch(setForumCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearForumParentCategories());
    dispatch(downloadForumParentCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_FORUM_CATEGORY));
    dispatch(setForumCategorySearchText(''));
    dispatch(clearForumCategories());
    dispatch(setForumCategorySelectedPageSize(20));
    dispatch(setForumCategorySelectedOrderBy(INITIAL_ORDER_BY_FORUM_CATEGORIES));
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/parentCategory/, 'parentCategory.id');
    dispatch(setForumCategoryAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearForumCategories());
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadForumCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setForumCategorySelectedPageSize(pageSize));
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeForumParentCategoryText: async (text) => {
    try {
      dispatch(setForumParentCategorySearchText(text));
      dispatch(clearForumParentCategories());
      await dispatch(downloadForumParentCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setForumCategorySelectedPageSize(pageSize));
    dispatch(clearForumCategories());
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearForumCategories());
    dispatch(setForumCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setForumCategorySelectedOrderBy(INITIAL_ORDER_BY_FORUM_CATEGORIES));
    dispatch(downloadForumCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setForumCategorySearchText(text));
      dispatch(clearForumCategories());
      await dispatch(downloadForumCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setForumCategorySelectedOrderBy(orderBy));
    dispatch(clearForumCategories());
    dispatch(downloadForumCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setForumCategoryTappedId(id));
    dispatch(downloadDeleteForumCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteForumCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setForumCategoryTappedId(''));
    dispatch(setForumParentCategorySearchText(''));
    dispatch(clearForumParentCategories());
    dispatch(downloadForumParentCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setForumCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setForumCategoryTappedId(id));
    dispatch(setForumParentCategorySearchText(''));
    dispatch(clearForumParentCategories());
    dispatch(downloadDeleteForumCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadForumParentCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description, image,
  }) => {
    try {
      await dispatch(addEditForumCategoryAsync(name, description, image));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSubmitPressed: async ({
    name, description, image,
  }) => {
    await dispatch(addEditForumCategoryAsync(name, description, image));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_FORUM_CATEGORY));
  },
  onParentCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setForumCategorySelectedParentCategory(option.value));
      dispatch(change(RXFORM_FORUM_CATEGORY, RXFIELD_FORUM_CATEGORY_PARENT_CATEGORY, option));
    } else {
      dispatch(setForumCategorySelectedParentCategory(''));
      dispatch(change(RXFORM_FORUM_CATEGORY, RXFIELD_FORUM_CATEGORY_PARENT_CATEGORY, ''));
    }
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_FORUM_CATEGORY, RXFIELD_FORUM_CATEGORY_IMAGE, image));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForumCategoryPage);
