import {
  addRole, clearRoles, downloadingDeletingRole, setAlertErrorMessage,
  setFunctionalPageMode, setRoleTappedId,
} from '../simple-action';
import { downloadRole, deleteRole } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadRolesAsync from './downloadRolesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingRole(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRole;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteRole(tappedId, token);
      dispatch(setRoleTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearRoles());
      dispatch(downloadRolesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const role = await downloadRole(tappedId, token);
      dispatch(addRole(role));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingRole(false));
  }
};
