import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    discussionMenu: {
      title: 'Discussion',
    },
    discussionCommentPage: {
      title: 'Discussion Comment',

      labelNo: 'No',
      labelUser: 'User',
      labelDiscussion: 'Discussion',
      labelComment: 'Comment',
      labelCreatedDate: 'Created Date',

      placeholderReplyToID: 'Reply to ID',
      placeholderFullName: 'Full Name',
      placeholderUserID: 'User ID',
      placeholderReplyToComment: 'Reply to Comment',
      placeholderDiscussionID: 'Discussion ID',
      placeholderDiscussionName: 'Discussion Name',
      placeholderComment: 'Comment',
      placeholderReactionID: 'Reaction ID',
      placeholderReplyCount: 'Reply Count',
      placeholderCreatedDate: 'Created Date',
      placeholderUserName: 'Username',
      placeholderDiscussion: 'Discussion',
    },
    reportedCommentPage: {
      title: 'Reported Comment',

      labelNo: 'No',
      labelUser: 'User',
      labelReason: 'Reason',
      labelDiscussionTitle: 'Discussion Title',
      labelCreatedDate: 'Created Date',

      placeholderUserID: 'User ID',
      placeholderFullName: 'Full Name',
      placeholderReason: 'Reason',
      placeholderHandled: 'Handled',
      placeholderDiscussion: 'Discussion',
      placeholderDiscussionID: 'Discussion ID',
      placeholderDiscussionTitle: 'Discussion Title',
      placeholderCommentID: 'Comment ID',
      placeholderComment: 'Comment',
    },
  },
  id: {
    discussionMenu: {
      title: 'Diskusi',
    },
    discussionCommentPage: {
      title: 'Komentar Diskusi',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelDiscussion: 'Diskusi',
      labelComment: 'Komentar',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderReplyToID: 'Balas ke ID',
      placeholderFullName: 'Nama Lengkap',
      placeholderUserID: 'ID Pengguna',
      placeholderReplyToComment: 'Balas ke Komentar',
      placeholderDiscussionID: 'ID Diskusi',
      placeholderDiscussionName: 'Nama Diskusi',
      placeholderComment: 'Komentar',
      placeholderReactionID: 'ID Reaksi',
      placeholderReplyCount: 'Jumlah Balasan',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderUserName: 'Nama Pengguna',
      placeholderDiscussion: 'Diskusi',
    },
    reportedCommentPage: {
      title: 'Komentar Dilaporkan',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelReason: 'Alasan',
      labelDiscussionTitle: 'Judul Diskusi',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUserID: 'ID Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderReason: 'Alasan',
      placeholderHandled: 'Ditangani',
      placeholderDiscussion: 'Diskusi',
      placeholderDiscussionID: 'ID Diskusi',
      placeholderDiscussionTitle: 'Judul Diskusi',
      placeholderCommentID: 'ID Komentar',
      placeholderComment: 'Komentar',
    },
  },
});

export default LocalizedString;
