import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  clearAllStates, setAlertConfirmationMessage, setActiveSideMenuItem, setAlertErrorMessage,
  setDrawerVisibility, setDrawerMenuExpansion, setFunctionalPageMode,
  logoutAsync,
} from '../../redux/action';
import MenuList from '../../drawer-menu';
import BasePage from './base-page.presentation';
import LocalizedString from '../../localization';
import {
  ERR_TOKEN_SESSION_EXPIRED, PAGE_MODE_TABLE, PERMISSION_OPERATOR_AND, PERMISSION_OPERATOR_OR,
  ROUTE_NAME_LOGIN,
} from '../../constant';
import { removeAllStorage, sortAsc } from '../../helper';
import { downloadMyConfigItemsAsync } from '../../module/configuration/redux/action';

const isPermitted = (userPerm, menuPerm = [], permissionsOperator) => {
  if (permissionsOperator) {
    const transfromMenuPerm = menuPerm.map((x) => x.toUpperCase());

    if (permissionsOperator === PERMISSION_OPERATOR_AND) {
      return transfromMenuPerm.every((x) => userPerm[x]);
    }
    if (permissionsOperator === PERMISSION_OPERATOR_OR) {
      return transfromMenuPerm.some((x) => userPerm[x]);
    }
  }
  return menuPerm.every((x) => userPerm[x]);
};

const getNestedChildren = (menuList, parentId, activeMenuId, permissions) => {
  const result = [];
  menuList.map((item, i) => {
    if (menuList[i].parentId === parentId) {
      const child = menuList[i];
      if (isPermitted(permissions, child.permissions, item.permissionsOperator)) {
        const children = getNestedChildren(menuList, child.id, activeMenuId);

        if (children.length) {
          child.children = children;
        }

        const isActiveChild = child.id === activeMenuId ? { ...child, selected: true } : child;
        result.push(isActiveChild);
      }
    }
    return null;
  });

  return result;
};

const hasChild = (id) => MenuList.filter((x) => x.parentId === id).length !== 0;

const getMenuItems = (state) => {
  const menuList = MenuList
    .map((x) => {
      const { uiBasePage, currentUser } = state;
      const { activeMenuId } = uiBasePage;
      const { permissions } = currentUser;

      if (activeMenuId === x.id) {
        return {
          ...x,
          selected: true,
          children: getNestedChildren(MenuList, x.id, activeMenuId, permissions),
        };
      }
      return { ...x, children: getNestedChildren(MenuList, x.id, activeMenuId, permissions) };
    })
    .filter((x) => x.level === 1 && !(hasChild(x.id) && x.children.length === 0));

  const topMenu = [];
  for (let menuIndex = 0; menuIndex < 5; menuIndex += 1) {
    const init = MenuList[menuIndex];
    const found = menuList.find((y) => y.id === init.id);
    if (found) { topMenu.push(found); }
  }

  const restMenu = menuList.filter((x) => topMenu.every((y) => y.id !== x.id))
    .sort((a, b) => sortAsc(a.label.toLowerCase(), b.label.toLowerCase()));
  const result = [...topMenu, ...restMenu];
  return result;
};

const mapStateToProps = (state, ownProps) => {
  const { onSideMenuPressed } = ownProps;
  return ({
    menuItems: getMenuItems(state),
    visibility: state.uiBasePage.visibility,
    expanded: state.uiBasePage.expanded,
    expandedMenuId: state.uiBasePage.expandedMenuId,
    currentUserName: state.currentUser.fullName,
    onSideMenuPressed,
    myConfigItems: state.myConfigItems,
  });
};

const mapDispatchToProps = (dispatch) => ({
  onDrawerPressed: (status) => {
    dispatch(setDrawerVisibility(!status));
  },
  onExpansionPanelChanged: (status, itemId, currentExpandedMenu) => {
    if (currentExpandedMenu === itemId) {
      dispatch(setDrawerMenuExpansion(!status, itemId));
    } else {
      dispatch(setDrawerMenuExpansion(true, itemId));
    }
  },
  onMenuPressed: (item, history) => {
    dispatch(setActiveSideMenuItem(item.id));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    history.push(item.value);
  },
  onLogoutPressed: () => {
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgLogoutConfirmation));
  },
  onConfirmLogoutPressed: async (message, reason, history, myConfigItems, onConfirmPressed) => {
    if (message.toLowerCase().includes(ERR_TOKEN_SESSION_EXPIRED)) {
      await removeAllStorage();
      dispatch(clearAllStates());
      history.push(ROUTE_NAME_LOGIN);
    } else if (message === LocalizedString.common.msgLogoutConfirmation) {
      if (isEmpty(myConfigItems)) {
        await dispatch(downloadMyConfigItemsAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
      dispatch(logoutAsync(history.push, message))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      onConfirmPressed(reason, message);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);
