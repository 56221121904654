import {
  addCustomer, clearCustomers, downloadingDeletingCustomer, setAlertErrorMessage,
  setFunctionalPageMode, setCustomerTappedId,
} from '../simple-action';
import { downloadCustomer, deleteCustomer } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadCustomersAsync from './downloadCustomersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCustomer(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCustomer;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteCustomer(tappedId, token);
      dispatch(setCustomerTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCustomers());
      dispatch(downloadCustomersAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadCustomer(tappedId, token);
      dispatch(addCustomer(result));
    }
  } finally {
    dispatch(downloadingDeletingCustomer(false));
  }
};
