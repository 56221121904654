import { reset } from 'redux-form';
import {
  addingEditingCity, clearCities, setAlertErrorMessage, setCitySelectedProvinceId,
  setFunctionalPageMode,
} from '../simple-action';
import { addCity, editCity } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_CITY } from '../../../constant';
import downloadCitiesAsync from './downloadCitiesAsync';

export default (name, code) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCity(true));

    const { token } = getState().authentication;
    const { tappedId, selectedProvinceId } = getState().uiCity;
    const { data } = getState().cities;

    const found = data[tappedId];
    const province = selectedProvinceId || found.province.id;

    if (tappedId) {
      await editCity(tappedId, name, province, code, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addCity(name, selectedProvinceId, code, token);
    }

    dispatch(reset(RXFORM_CITY));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setCitySelectedProvinceId(''));
  } finally {
    dispatch(addingEditingCity(false));
  }
};
