import { downloadingUsers, setUsers } from '../simple-action';
import { downloadUsers, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, users, uiUser } = getState();
  const { token } = authentication;
  const { meta } = users;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiUser;

  try {
    dispatch(downloadingUsers(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadUsers(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setUsers(list));
    }
  } finally {
    dispatch(downloadingUsers(false));
  }
};
