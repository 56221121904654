import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import {
  Avatar, Box, CircularProgress, FormControl, FormHelperText, makeStyles, Slider, Typography,
} from '@material-ui/core';
import {
  COLOR_BACKGROUND_SECONDARY, COLOR_BODY_TEXT, COLOR_BODY_TEXT_LIGHTER, COLOR_DANGEROUS,
  COLOR_PRIMARY,
} from '../../constant';
import LocalizedString from '../../localization';
import AccentButton from '../accent-button';
import { getImageValue } from '../../helper';

const useStyles = makeStyles((theme) => ({
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cropperForm: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  form: {
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  innerContainer: {
    padding: 20,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cropperContainer: {
    height: 400,
    position: 'relative',
  },
  sliderContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-around',
  },
  slider: {
    display: 'flex',
    margin: '0px 15px 0px 15px',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
  border: {
    border: `dotted 1px ${COLOR_BODY_TEXT_LIGHTER}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageText: {
    color: COLOR_BODY_TEXT_LIGHTER,
  },
}));

const renderAvatar = (useFullWidthImage, croppedImage, fullImageWidth, result, value, classes) => {
  if (useFullWidthImage && (result || croppedImage || value)) {
    return (
      <img alt="avatar" src={result || croppedImage || getImageValue(value)} width={fullImageWidth} height="auto" />
    );
  }
  if (useFullWidthImage && !result && !croppedImage && !value) {
    return (
      <Box className={classes.border}>
        <Typography className={classes.messageText}>
          {LocalizedString.common.errMsgNoDataYet}
        </Typography>
      </Box>
    );
  }
  if (!useFullWidthImage && (result || croppedImage || value)) {
    return (
      <Avatar src={result || croppedImage || getImageValue(value)} className={classes.avatar} />
    );
  }
  return (
    <Avatar className={classes.avatar} />
  );
};

const renderField = (cropping, disabled, useAvatarWithoutCropper, useCropper, useFullWidthImage,
  useResultSource, onCropChanged, onCropCompleted, onFileChange, onImageSelected, onOkPressed,
  onZoomChanged, zoom, crop, croppedAreaPixels, defaultValue, croppedImage, fileName,
  fullImageWidth, imageSrc, result, value, classes) => {
  if (disabled) {
    return renderAvatar(useFullWidthImage, croppedImage, fullImageWidth, result, defaultValue,
      classes);
  }
  if (useCropper) {
    return (
      <div className={classes.innerContainer}>
        <input type="file" onChange={(e) => onFileChange(e, useCropper, useResultSource, onImageSelected)} accept="image/png, image/jpeg, image/jpg" />

        {imageSrc && cropping ? (
          <div className={classes.wrapper}>
            <div className={classes.cropperContainer}>
              <Cropper
                cropSize={{ width: 128, height: 128 }}
                image={imageSrc}
                crop={crop}
                aspect={1 / 1}
                zoom={zoom}
                onCropChange={onCropChanged}
                onCropComplete={onCropCompleted}
                onZoomChange={onZoomChanged}
                onMediaLoaded={(mediaSize) => onZoomChanged(128 / mediaSize.height)}
              />
            </div>

            <div className={classes.sliderContainer}>
              <Slider
                value={zoom}
                min={0.3}
                max={3}
                step={0.01}
                aria-labelledby="Zoom"
                onChange={(event, zoomSize) => onZoomChanged(zoomSize)}
                className={classes.slider}
              />

              <AccentButton
                onClick={() => onOkPressed(imageSrc, fileName, croppedAreaPixels, onImageSelected)}
                caption={LocalizedString.common.buttonCaptionOK}
              />
            </div>
          </div>
        ) : null}

        {!cropping ? renderAvatar(useFullWidthImage, croppedImage, fullImageWidth, result, value,
          classes) : null}
      </div>
    );
  }
  return (
    <div className={classes.innerContainer}>
      <input
        type="file"
        onChange={(e) => onFileChange(e, useCropper, useResultSource, onImageSelected)}
        accept="image/png, image/jpeg, image/jpg"
        style={{ width: '100%' }}
      />

      {useAvatarWithoutCropper && (renderAvatar(useFullWidthImage, croppedImage, fullImageWidth,
        result, value, classes))}
    </div>
  );
};

const ImageInputField = ({
  cropping, error, disabled, hidden, loading, useAvatarWithoutCropper, useCropper,
  useFullWidthImage, useResultSource,
  onAppear, onCropChanged, onCropCompleted, onFileChange,
  onImageSelected, onOkPressed, onZoomChanged,
  zoom, crop, croppedAreaPixels,
  defaultValue, croppedImage, fileName, fullImageWidth, imageSrc, label, helperText, result,
  wrapperStyle,
  value,
  ...props
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl
          className={useCropper ? `${classes.cropperForm} ${wrapperStyle}` : `${classes.form} ${wrapperStyle}`}
          variant="outlined"
          error={error}
          {...props}
        >
          <Typography>{label}</Typography>
          {loading ? (
            <div className={classes.activityIndicator}>
              <CircularProgress color="inherit" />
            </div>
          ) : renderField(cropping, disabled, useAvatarWithoutCropper, useCropper,
            useFullWidthImage, useResultSource, onCropChanged, onCropCompleted, onFileChange,
            onImageSelected, onOkPressed, onZoomChanged, zoom, crop, croppedAreaPixels,
            defaultValue, croppedImage, fileName, fullImageWidth, imageSrc, result, value, classes)}
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default ImageInputField;

ImageInputField.propTypes = {
  cropping: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  useAvatarWithoutCropper: PropTypes.bool,
  useCropper: PropTypes.bool,
  useFullWidthImage: PropTypes.bool,
  useResultSource: PropTypes.bool,
  onAppear: PropTypes.func.isRequired,
  onCropChanged: PropTypes.func.isRequired,
  onCropCompleted: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func,
  onOkPressed: PropTypes.func.isRequired,
  onZoomChanged: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  crop: PropTypes.objectOf(PropTypes.number).isRequired,
  croppedAreaPixels: PropTypes.objectOf(PropTypes.number).isRequired,
  defaultValue: PropTypes.string,
  croppedImage: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fullImageWidth: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  result: PropTypes.string,
  wrapperStyle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ImageInputField.defaultProps = {
  error: false,
  disabled: false,
  hidden: false,
  loading: false,
  useAvatarWithoutCropper: false,
  useCropper: true,
  useFullWidthImage: false,
  useResultSource: false,
  onImageSelected: () => {},
  value: {},
  defaultValue: '',
  fullImageWidth: '60%',
  label: null,
  helperText: null,
  result: '',
  wrapperStyle: '',
};
