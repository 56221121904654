import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearArticleCategories, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setArticleCategorySearchText, setArticleCategorySelectedPageSize,
  setArticleCategorySelectedOrderBy, setArticleCategoryTappedId,
  addEditArticleCategoryAsync, downloadDeleteArticleCategoryAsync, downloadArticleCategoriesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_ARTICLE_CATEGORIES, PAGE_MODE_TABLE, RXFORM_ARTICLE_CATEGORY,
  MENUID_KNOWLEDGE_MANAGEMENT_ARTICLE_CATEGORY,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import ArticleCategoryPage from './article-category.presentation';
import { transformInitialValues } from '../../../../helper';

const getInitialValues = (state) => {
  const { articleCategories, uiFunctionalPage, uiArticleCategory } = state;
  const { data } = articleCategories;
  const { downloadingDeleting, tappedId } = uiArticleCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    name: '',
    description: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiArticleCategory.addingEditing,
  downloading: state.uiArticleCategory.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_KNOWLEDGE_MANAGEMENT_ARTICLE_CATEGORY));
    dispatch(setArticleCategorySearchText(''));
    dispatch(clearArticleCategories());
    dispatch(setArticleCategorySelectedPageSize(20));
    dispatch(setArticleCategorySelectedOrderBy(INITIAL_ORDER_BY_ARTICLE_CATEGORIES));
    dispatch(downloadArticleCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadArticleCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setArticleCategorySelectedPageSize(pageSize));
    dispatch(downloadArticleCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_ARTICLE_CATEGORY));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteArticleCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setArticleCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setArticleCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onRefresh: (pageSize) => {
    dispatch(setArticleCategorySelectedPageSize(pageSize));
    dispatch(clearArticleCategories());
    dispatch(downloadArticleCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditArticleCategoryAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setArticleCategorySearchText(text));
      dispatch(clearArticleCategories());
      await dispatch(downloadArticleCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setArticleCategorySelectedOrderBy(orderBy));
    dispatch(clearArticleCategories());
    dispatch(downloadArticleCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditArticleCategoryAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setArticleCategoryTappedId(id));
  },
  onEditPressed: (id) => {
    dispatch(setArticleCategoryTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCategoryPage);
