import { downloadCovidDatas, transformSearchText } from '../../../helper';
import { downloadingCovidDatas, setCovidDatas } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, covidDatas, uiCovidData,
    uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = covidDatas;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiCovidData;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(downloadingCovidDatas(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCovidDatas(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setCovidDatas(list));
    }
  } finally {
    dispatch(downloadingCovidDatas(false));
  }
};
