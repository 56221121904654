import {
  enablingDisablingWorkingHour,
  setAlertErrorMessage,
  setWorkingHourSelectedOrderBy,
  setWorkingHourSelectedPageSize,
} from '../simple-action';
import {
  INITIAL_ORDER_BY_WORKING_HOURS,
  STATUS_DISABLED,
  STATUS_ENABLED,
} from '../../../constant';
import { enableDisableWorkingHour } from '../../../helper';
import downloadWorkingHoursAsync from './downloadWorkingHoursAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingWorkingHour(true));

    const { token } = getState().authentication;
    const { data } = getState().workingHours;
    const { tappedId } = getState().uiWorkingHour;

    const status = data[tappedId].status === STATUS_ENABLED
      ? STATUS_DISABLED
      : STATUS_ENABLED;

    await enableDisableWorkingHour(tappedId, status, token);

    dispatch(setWorkingHourSelectedPageSize(20));
    dispatch(setWorkingHourSelectedOrderBy(INITIAL_ORDER_BY_WORKING_HOURS));
    dispatch(downloadWorkingHoursAsync(1)).catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(enablingDisablingWorkingHour(false));
  }
};
