import {
  sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest,
  sendPostFileRequest,
  buildQueryParams, transformQueryResult,
} from '../../helper';
import {
  REST_URL_FONT_LIST, REST_URL_UPLOAD_TEMPLATE,
  REST_URL_PREVIEW_PDF,
  REST_URL_ADD_EDIT_ANNOTATION_DESIGNERS,
  REST_URL_ANNOTATION_DESIGNERS,
  REST_URL_VIEW_DELETE_ANNOTATION_DESIGNERS,
} from './constant';

export * from '../../helper';

export const downloadFont = async (token) => {
  const response = await sendGetRequest(REST_URL_FONT_LIST, token);
  return response;
};

export const downloadAnnotationDesignLists = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_ANNOTATION_DESIGNERS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_ANNOTATION_DESIGNERS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadAnnotationDesignList = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ANNOTATION_DESIGNERS.replace(/\{id\}/, id), token);
  return response;
};

export const submitAnnotation = async (body, token) => {
  const response = await sendPostRequest(REST_URL_ADD_EDIT_ANNOTATION_DESIGNERS, body, token);
  return response;
};

export const editAnnotation = async (body, token) => {
  const response = await sendPutRequest(REST_URL_ADD_EDIT_ANNOTATION_DESIGNERS, body, token);
  return response;
};

export const previewPdf = async (body, token) => {
  const response = await sendPostFileRequest(REST_URL_PREVIEW_PDF, body, token);
  return response;
};

export const uploadPDFTemplate = async (base64File, token) => {
  const body = {
    file: base64File,
  };

  const response = await sendPostRequest(REST_URL_UPLOAD_TEMPLATE, body, token);
  return response;
};

export const deleteAnnotationDesigner = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ANNOTATION_DESIGNERS.replace(/\{id\}/, id), token);
};
