import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  cancelWfaAsync, clearAllStates, clearProfiles, clearWfas, downloadWfaAsync, downloadWfasAsync,
  editWfaAsync, setActiveSideMenuItem, setAlertErrorMessage, setAlertInputMessage,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  setWfaAdvancedFilterDialogSelectedFilterString, setWfaSearchText, setWfaSelectedOrderBy,
  setWfaSelectedPageSize, setWfaTappedId,
} from '../../redux/action';
import {
  ERR_TOKEN_SESSION_EXPIRED, INITIAL_ORDER_BY_WFAS, MENUID_ATTENDANCE_ADDON_WFA, PAGE_MODE_TABLE,
  REVERSED_ISO_DATE_FORMAT, ROUTE_NAME_LOGIN, RXFORM_WFA, RXFORM_WFA_EDIT_DIALOG,
  SIMPLE_DATE_FORMAT,
} from '../../constant';
import {
  removeAllStorage, transformInitialValues, debounceSearch, transformUserDropdownData, toMoment,
} from '../../helper';
import WfaPage from './wfa.presentation';
import { downloadProfilesAsync, logoutAsync } from '../../../../redux/action';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { setWfaEditDialogVisibility } from '../../redux/action/simple-action';
import { downloadMyConfigItemsAsync } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { wfas, uiWfa, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiWfa;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? wfas.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      requestId: found.requestId !== null ? found.requestId : '',
      requesterId: found.requester !== null ? found.requester.id : '',
      requesterName: found.requester !== null ? found.requester.fullName : '',
      date: found.date !== null ? toMoment(found.date).format(SIMPLE_DATE_FORMAT) : '',
      wfaStatus: found.wfaStatus !== null ? found.wfaStatus : '',
      wfaStatusNotes: found.wfaStatusNotes !== null ? found.wfaStatusNotes : '',
      requesterEmployeeId: found.requester !== null ? found.requester.employeeId : '',
      requesterDivision: found.requester !== null ? found.requester.division : '',
      requesterDepartment: found.requester !== null ? found.requester.department : '',
    }) : {
      requestId: '',
      requesterId: '',
      requesterName: '',
      date: '',
      wfaStatus: '',
      wfaStatusNotes: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  addingEditing: state.uiWfa.addingEditing,
  canceling: state.uiWfa.canceling,
  downloading: state.uiWfa.downloading,
  downloadingDeleting: state.uiWfa.downloadingDeleting,
  editDialogVisibility: state.uiWfa.editDialogVisibility,
  loadingRequester: state.uiProfile.downloading,
  initialValues: getInitialValues(state),
  requesters: transformUserDropdownData(state.profiles.data),
  tappedId: state.uiFunctionalPage.tappedId || '',
  myConfigItems: state.myConfigItems,
  userPermissions: state.currentUser.permissions,
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setWfaEditDialogVisibility(false));
    dispatch(setWfaAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_WFA));
    dispatch(setWfaSearchText(''));
    dispatch(clearWfas());
    dispatch(setWfaSelectedPageSize(20));
    dispatch(setWfaSelectedOrderBy(INITIAL_ORDER_BY_WFAS));
    dispatch(downloadWfasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('requester', 'requester.id');
    dispatch(setWfaAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearWfas());
    dispatch(downloadWfasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_WFA));
  },
  onCancelDialogPressed: () => {
    dispatch(setWfaEditDialogVisibility(false));
    dispatch(reset(RXFORM_WFA_EDIT_DIALOG));
  },
  onCancelWfaPressed: (id) => {
    dispatch(setWfaTappedId(id));
    dispatch(setAlertInputMessage(GlobalLocalizedString.common.msgCancelConfirmation,
      LocalizedString.wfaPage.placeholderNotes));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_WFA));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWfasAsync(pageNo + 1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWfaSelectedPageSize(pageSize));
    dispatch(downloadWfasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangeRequesterText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onConfirmLogoutPressed: async (message, reason, history, myConfigItems) => {
    if (message.toLowerCase().includes(ERR_TOKEN_SESSION_EXPIRED)) {
      await removeAllStorage();
      dispatch(clearAllStates());
      history.push(ROUTE_NAME_LOGIN);
    } else if (message === GlobalLocalizedString.common.msgLogoutConfirmation) {
      if (isEmpty(myConfigItems)) {
        await dispatch(downloadMyConfigItemsAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
      dispatch(logoutAsync(history.push, message))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else if (message === GlobalLocalizedString.common.msgCancelConfirmation) {
      dispatch(cancelWfaAsync(reason))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    }
  },
  onEditWfaPressed: (id) => {
    dispatch(setWfaTappedId(id));
    dispatch(setWfaEditDialogVisibility(true));
  },
  onRefresh: (pageSize) => {
    dispatch(setWfaSelectedPageSize(pageSize));
    dispatch(clearWfas());
    dispatch(downloadWfasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setWfaAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setWfaSelectedOrderBy(INITIAL_ORDER_BY_WFAS));
    dispatch(clearWfas());
    dispatch(downloadWfasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWfaSearchText(text));
      dispatch(clearWfas());
      await dispatch(downloadWfasAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWfaSelectedOrderBy(orderBy));
    dispatch(clearWfas());
    dispatch(downloadWfasAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSubmitPressed: async ({ date, wfaStatusNotes }) => {
    try {
      await dispatch(editWfaAsync(toMoment(date).format(REVERSED_ISO_DATE_FORMAT), wfaStatusNotes));
    } catch (err) {
      dispatch(setAlertErrorMessage(err));
    }
  },
  onViewPressed: (id) => {
    dispatch(setWfaTappedId(id));
    dispatch(downloadWfaAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WfaPage);
