import { getAnalyticsUserEngagementByScreenName } from '../../../helper';
import {
  downloadingAnalyticsUserEngagementByScreenName, setAnalyticsUserEngagementByScreenName,
  clearAnalyticsUserEngagementByScreenName,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsUserEngagementByScreenName(true));

    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { token } = authentication;
    const { userEngagementByScreenNameDateRange } = uiAnalytics;

    const firstDateFrom = userEngagementByScreenNameDateRange.firstDateRangeFrom;
    const firstDateTo = userEngagementByScreenNameDateRange.firstDateRangeTo;
    const secondDateFrom = userEngagementByScreenNameDateRange.secondDateRangeFrom;
    const secondDateTo = userEngagementByScreenNameDateRange.secondDateRangeTo;

    const requestBody = {
      dateRanges: [
        { startDate: firstDateFrom, endDate: firstDateTo },
        { startDate: secondDateFrom, endDate: secondDateTo },
      ],
      dimensions: [
        { name: 'eventName' },
        { name: 'unifiedScreenName' },
      ],
      metrics: [
        { name: 'userEngagementDuration' },
        { name: 'userEngagementDurationPerEvent', expression: 'userEngagementDuration/eventCount' },
      ],
      dimensionFilter: {
        filter: {
          fieldName: 'eventName',
          inListFilter: {
            caseSensitive: true,
            values: ['user_engagement'],
          },
        },
      },
      metricAggregations: ['TOTAL'],
    };

    const response = await getAnalyticsUserEngagementByScreenName(
      myConfigItems?.core?.googleAnalyticsPropertyId, requestBody, token,
    );
    dispatch(clearAnalyticsUserEngagementByScreenName());
    dispatch(setAnalyticsUserEngagementByScreenName(response));
  } finally {
    dispatch(downloadingAnalyticsUserEngagementByScreenName(false));
  }
};
