import { connect } from 'react-redux';
import YearCalendar from './year-calendar.presentation';
import { SET_AS_HOLIDAY, SET_AS_WORKING_DAY } from '../../constant';

const getDaysItemTypeColor = (type) => {
  if (type === SET_AS_WORKING_DAY) return 'green';
  if (type === SET_AS_HOLIDAY) return 'red';
  return '';
};

const getDaysPalyload = (state) => {
  const { uiCalendar } = state;
  const { days } = uiCalendar;

  const transformDays = days.length > 0 ? days?.filter((day) => !!day.type).map((day, index) => ({
    id: index,
    name: day?.description,
    startDate: new Date(day.date),
    endDate: new Date(day.date),
    color: getDaysItemTypeColor(day?.type),
  })) : [];

  return transformDays;
};

const mapStateToProps = (state) => ({
  selectedYear: state.uiCalendar.selectedYear,
  daysPayload: getDaysPalyload(state),
  downloadingDeleting: state.uiCalendar.downloadingDeleting,
});

const mapDispatchToProps = () => ({
  onAppear: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(YearCalendar);
