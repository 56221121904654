import { downloadingCompaniesMData, setCompaniesMData } from '../simple-action';
import { downloadMDataCompanies, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, cities, uiCompany } = getState();
  const { token } = authentication;
  const { meta } = cities;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiCompany;

  try {
    dispatch(downloadingCompaniesMData(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const list = await downloadMDataCompanies(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );
    if (list) {
      dispatch(setCompaniesMData(list));
    }
  } finally {
    dispatch(downloadingCompaniesMData(false));
  }
};
