import { downloadPoints, transformSearchText } from '../../../helper';
import { downloadingPoints, setPoints } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, points, uiPoint } = getState();
  const { token } = authentication;
  const { meta, data } = points;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiPoint;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingPoints(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPoints(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setPoints(list));
    }
  } finally {
    dispatch(downloadingPoints(false));
  }
};
