import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXSTATE_ARTICLE_CATEGORY_PAGE,
  RXSTATE_ARTICLE_CATEGORIES, RXFORM_ARTICLE_CATEGORY, RXFIELD_ARTICLE_CATEGORY_NAME,
  RXFIELD_ARTICLE_CATEGORY_DESCRIPTION,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateArticleCategory } from '../../validation';

const renderDialogContent = (addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_ARTICLE_CATEGORY_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.articleCategoryScreen.placeholderArticleCategoryName}
          label={LocalizedString.articleCategoryScreen.placeholderArticleCategoryName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_ARTICLE_CATEGORY_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.articleCategoryScreen.placeholderDescription}
          label={LocalizedString.articleCategoryScreen.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>

);

const ArticleCategoryPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_ARTICLE_CATEGORIES}
    uiPage={RXSTATE_ARTICLE_CATEGORY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.articleCategoryScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.articleCategoryScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.articleCategoryScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={
      LocalizedString.articleCategoryScreen.buttonCaptionCreateNewArticleCategory
    }
    deleteButtonCaption={LocalizedString.articleCategoryScreen.buttonCaptionDeleteArticleCategory}
    title={LocalizedString.articleCategoryScreen.title}
    editButtonCaption={LocalizedString.articleCategoryScreen.buttonCaptionEditArticleCategory}
    useFullWidth
  >
    {renderDialogContent(addingEditing, pageMode)}
  </FunctionalPage>
);


export default reduxForm({
  form: RXFORM_ARTICLE_CATEGORY,
  validate: rxformValidateArticleCategory,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ArticleCategoryPage);

ArticleCategoryPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
};
