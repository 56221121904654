import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearPermissions, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setPermissionSearchText, setPermissionSelectedPageSize,
  setPermissionSelectedOrderBy, setPermissionTappedId,
  addEditPermissionAsync, downloadDeletePermissionAsync, downloadPermissionsAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_PERMISSIONS, PAGE_MODE_TABLE, RXFORM_PERMISSION,
  MENUID_AUTHENTICATION_PERMISSION,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import PermissionPage from './permission.presentation';

const getInitialValues = (state) => {
  const { permissions, uiFunctionalPage, uiPermission } = state;
  const { data } = permissions;
  const { downloadingDeleting, tappedId } = uiPermission;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : { name: '', description: '' };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiPermission.addingEditing,
  downloading: state.uiPermission.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_PERMISSION));
    dispatch(setPermissionSearchText(''));
    dispatch(clearPermissions());
    dispatch(setPermissionSelectedPageSize(20));
    dispatch(setPermissionSelectedOrderBy(INITIAL_ORDER_BY_PERMISSIONS));
    dispatch(downloadPermissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPermissionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPermissionSelectedPageSize(pageSize));
    dispatch(downloadPermissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PERMISSION));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeletePermissionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setPermissionTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setPermissionTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onRefresh: (pageSize) => {
    dispatch(setPermissionSelectedPageSize(pageSize));
    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditPermissionAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPermissionSearchText(text));
      dispatch(clearPermissions());
      await dispatch(downloadPermissionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPermissionSelectedOrderBy(orderBy));
    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditPermissionAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setPermissionTappedId(id));
  },
  onEditPressed: (id) => {
    dispatch(setPermissionTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionPage);
