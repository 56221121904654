import {
  ADDING_EDITING_LOCATION, DOWNLOADING_LOCATIONS, DOWNLOADING_DELETING_LOCATION,
  SET_LOCATIONS_SEARCH_TEXT, SET_LOCATIONS_SELECTED_ORDER_BY, SET_LOCATIONS_SELECTED_PAGE_SIZE,
  SET_LOCATION_TAPPED_ID,
} from '../action';
import { INITIAL_ORDER_BY_LOCATIONS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_LOCATIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_LOCATIONS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_LOCATION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_LOCATION:
      return { ...state, downloadingDeleting: action.status };
    case SET_LOCATIONS_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_LOCATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_LOCATIONS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_LOCATIONS_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
