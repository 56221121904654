import {
  addPoint, downloadingDeletingPoint,
} from '../simple-action';
import { downloadPoint } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPoint(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPoint;

    const point = await downloadPoint(tappedId, token);
    dispatch(addPoint(point));
  } finally {
    dispatch(downloadingDeletingPoint(false));
  }
};
