import { downloadingSummaryOsDistribution, setSummaryOsDistribution } from '../simple-action';
import { downloadSummaryOsDistribution } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryOsDistribution(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryOsDistribution(token);

    dispatch(setSummaryOsDistribution(result));
  } finally {
    dispatch(downloadingSummaryOsDistribution(false));
  }
};
