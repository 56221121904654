import {
  DOWNLOADING_INDUSTRIES, ADDING_EDITING_INDUSTRY, DOWNLOADING_DELETING_INDUSTRY,
  SET_INDUSTRY_SELECTED_PAGE_SIZE, SET_INDUSTRY_TAPPED_ID, SET_INDUSTRY_SEARCH_TEXT,
  SET_INDUSTRY_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_INDUSTRIES } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_INDUSTRIES,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_INDUSTRIES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_INDUSTRY:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_INDUSTRY:
      return { ...state, downloadingDeleting: action.status };
    case SET_INDUSTRY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_INDUSTRY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_INDUSTRY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_INDUSTRY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
