import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');


export const DOWNLOADING_INDUSTRIES = 'DOWNLOADING_INDUSTRIES';
export const downloadingIndustries = makeActionCreator(DOWNLOADING_INDUSTRIES, 'status');

export const SET_INDUSTRIES = 'SET_INDUSTRIES';
export const setIndustries = makeActionCreator(SET_INDUSTRIES, 'data');

export const ADD_INDUSTRY = 'ADD_INDUSTRY';
export const addIndustry = makeActionCreator(ADD_INDUSTRY, 'data');

export const CLEAR_INDUSTRIES = 'CLEAR_INDUSTRIES';
export const clearIndustries = makeActionCreator(CLEAR_INDUSTRIES);

export const ADDING_EDITING_INDUSTRY = 'ADDING_EDITING_INDUSTRY';
export const addingEditingIndustry = makeActionCreator(ADDING_EDITING_INDUSTRY, 'status');

export const DOWNLOADING_DELETING_INDUSTRY = 'DOWNLOADING_DELETING_INDUSTRY';
export const downloadingDeletingIndustry = makeActionCreator(DOWNLOADING_DELETING_INDUSTRY, 'status');


export const SET_INDUSTRY_SELECTED_PAGE_SIZE = 'SET_INDUSTRY_SELECTED_PAGE_SIZE';
export const setIndustrySelectedPageSize = makeActionCreator(SET_INDUSTRY_SELECTED_PAGE_SIZE, 'size');

export const SET_INDUSTRY_SELECTED_ORDER_BY = 'SET_INDUSTRY_SELECTED_ORDER_BY';
export const setIndustrySelectedOrderBy = makeActionCreator(SET_INDUSTRY_SELECTED_ORDER_BY, 'order');

export const SET_INDUSTRY_TAPPED_ID = 'SET_INDUSTRY_TAPPED_ID';
export const setIndustryTappedId = makeActionCreator(SET_INDUSTRY_TAPPED_ID, 'id');

export const SET_INDUSTRY_SEARCH_TEXT = 'SET_INDUSTRY_SEARCH_TEXT';
export const setIndustrySearchText = makeActionCreator(SET_INDUSTRY_SEARCH_TEXT, 'text');


export const DOWNLOADING_CUSTOMERS = 'DOWNLOADING_CUSTOMERS';
export const downloadingCustomers = makeActionCreator(DOWNLOADING_CUSTOMERS, 'status');

export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const setCustomers = makeActionCreator(SET_CUSTOMERS, 'data');

export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const addCustomer = makeActionCreator(ADD_CUSTOMER, 'data');

export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS';
export const clearCustomers = makeActionCreator(CLEAR_CUSTOMERS);

export const ADDING_EDITING_CUSTOMER = 'ADDING_EDITING_CUSTOMER';
export const addingEditingCustomer = makeActionCreator(ADDING_EDITING_CUSTOMER, 'status');

export const DOWNLOADING_DELETING_CUSTOMER = 'DOWNLOADING_DELETING_CUSTOMER';
export const downloadingDeletingCustomer = makeActionCreator(DOWNLOADING_DELETING_CUSTOMER, 'status');


export const SET_CUSTOMER_SELECTED_PAGE_SIZE = 'SET_CUSTOMER_SELECTED_PAGE_SIZE';
export const setCustomerSelectedPageSize = makeActionCreator(SET_CUSTOMER_SELECTED_PAGE_SIZE, 'size');

export const SET_CUSTOMER_SELECTED_ORDER_BY = 'SET_CUSTOMER_SELECTED_ORDER_BY';
export const setCustomerSelectedOrderBy = makeActionCreator(SET_CUSTOMER_SELECTED_ORDER_BY, 'order');

export const SET_CUSTOMER_TAPPED_ID = 'SET_CUSTOMER_TAPPED_ID';
export const setCustomerTappedId = makeActionCreator(SET_CUSTOMER_TAPPED_ID, 'id');

export const SET_CUSTOMER_SEARCH_TEXT = 'SET_CUSTOMER_SEARCH_TEXT';
export const setCustomerSearchText = makeActionCreator(SET_CUSTOMER_SEARCH_TEXT, 'text');

export const SET_CUSTOMER_SELECTED_INDUSTRY = 'SET_CUSTOMER_SELECTED_INDUSTRY';
export const setCustomerSelectedIndustry = makeActionCreator(SET_CUSTOMER_SELECTED_INDUSTRY, 'option');


export const DOWNLOADING_ACTIVITIES = 'DOWNLOADING_ACTIVITIES';
export const downloadingActivities = makeActionCreator(DOWNLOADING_ACTIVITIES, 'status');

export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const setActivities = makeActionCreator(SET_ACTIVITIES, 'data');

export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const addActivity = makeActionCreator(ADD_ACTIVITY, 'data');

export const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';
export const clearActivities = makeActionCreator(CLEAR_ACTIVITIES);

export const DOWNLOADING_DELETING_ACTIVITY = 'DOWNLOADING_DELETING_ACTIVITY';
export const downloadingDeletingActivity = makeActionCreator(DOWNLOADING_DELETING_ACTIVITY, 'status');

export const SAVING_ACTIVITIES = 'SAVING_ACTIVITIES';
export const savingActivities = makeActionCreator(SAVING_ACTIVITIES, 'status');


export const SET_ACTIVITY_SELECTED_PAGE_SIZE = 'SET_ACTIVITY_SELECTED_PAGE_SIZE';
export const setActivitySelectedPageSize = makeActionCreator(SET_ACTIVITY_SELECTED_PAGE_SIZE, 'size');

export const SET_ACTIVITY_SELECTED_ORDER_BY = 'SET_ACTIVITY_SELECTED_ORDER_BY';
export const setActivitySelectedOrderBy = makeActionCreator(SET_ACTIVITY_SELECTED_ORDER_BY, 'order');

export const SET_ACTIVITY_TAPPED_ID = 'SET_ACTIVITY_TAPPED_ID';
export const setActivityTappedId = makeActionCreator(SET_ACTIVITY_TAPPED_ID, 'id');

export const SET_ACTIVITY_SEARCH_TEXT = 'SET_ACTIVITY_SEARCH_TEXT';
export const setActivitySearchText = makeActionCreator(SET_ACTIVITY_SEARCH_TEXT, 'text');

export const SET_ACTIVITY_SELECTED_EMPLOYEE = 'SET_ACTIVITY_SELECTED_EMPLOYEE';
export const setActivitySelectedEmployee = makeActionCreator(SET_ACTIVITY_SELECTED_EMPLOYEE, 'option');

export const SET_ACTIVITY_SELECTED_CUSTOMER = 'SET_ACTIVITY_SELECTED_CUSTOMER';
export const setActivitySelectedCustomer = makeActionCreator(SET_ACTIVITY_SELECTED_CUSTOMER, 'option');

export const SET_ACTIVITY_SELECTED_ACTIVITY_TYPE = 'SET_ACTIVITY_SELECTED_ACTIVITY_TYPE';
export const setActivitySelectedActivityType = makeActionCreator(SET_ACTIVITY_SELECTED_ACTIVITY_TYPE, 'option');


export const DOWNLOADING_ACTIVITY_TYPES = 'DOWNLOADING_ACTIVITY_TYPES';
export const downloadingActivityTypes = makeActionCreator(DOWNLOADING_ACTIVITY_TYPES, 'status');

export const SET_ACTIVITY_TYPES = 'SET_ACTIVITY_TYPES';
export const setActivityTypes = makeActionCreator(SET_ACTIVITY_TYPES, 'data');

export const ADD_ACTIVITY_TYPE = 'ADD_ACTIVITY_TYPE';
export const addActivityType = makeActionCreator(ADD_ACTIVITY_TYPE, 'data');

export const CLEAR_ACTIVITY_TYPES = 'CLEAR_ACTIVITY_TYPES';
export const clearActivityTypes = makeActionCreator(CLEAR_ACTIVITY_TYPES);

export const ADDING_EDITING_ACTIVITY_TYPE = 'ADDING_EDITING_ACTIVITY_TYPE';
export const addingEditingActivityType = makeActionCreator(ADDING_EDITING_ACTIVITY_TYPE, 'status');

export const DOWNLOADING_DELETING_ACTIVITY_TYPE = 'DOWNLOADING_DELETING_ACTIVITY_TYPE';
export const downloadingDeletingActivityType = makeActionCreator(DOWNLOADING_DELETING_ACTIVITY_TYPE, 'status');


export const SET_ACTIVITY_TYPE_SELECTED_PAGE_SIZE = 'SET_ACTIVITY_TYPE_SELECTED_PAGE_SIZE';
export const setActivityTypeSelectedPageSize = makeActionCreator(SET_ACTIVITY_TYPE_SELECTED_PAGE_SIZE, 'size');

export const SET_ACTIVITY_TYPE_SELECTED_ORDER_BY = 'SET_ACTIVITY_TYPE_SELECTED_ORDER_BY';
export const setActivityTypeSelectedOrderBy = makeActionCreator(SET_ACTIVITY_TYPE_SELECTED_ORDER_BY, 'order');

export const SET_ACTIVITY_TYPE_TAPPED_ID = 'SET_ACTIVITY_TYPE_TAPPED_ID';
export const setActivityTypeTappedId = makeActionCreator(SET_ACTIVITY_TYPE_TAPPED_ID, 'id');

export const SET_ACTIVITY_TYPE_SEARCH_TEXT = 'SET_ACTIVITY_TYPE_SEARCH_TEXT';
export const setActivityTypeSearchText = makeActionCreator(SET_ACTIVITY_TYPE_SEARCH_TEXT, 'text');
