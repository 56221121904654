import { reset } from 'redux-form';
import {
  addingEditingCustomer, clearCustomers, setAlertErrorMessage, setCustomerSelectedIndustry,
  setFunctionalPageMode,
} from '../simple-action';
import { addCustomer, editCustomer } from '../../../helper';
import { RXFORM_CUSTOMER, PAGE_MODE_TABLE } from '../../../constant';
import downloadCustomersAsync from './downloadCustomersAsync';

export default (refId, name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCustomer(true));

    const { token } = getState().authentication;
    const { tappedId, selectedIndustry } = getState().uiCustomer;
    const { data } = getState().customers;
    const found = data[tappedId];
    const industryId = selectedIndustry || found.industry.id;

    if (tappedId) {
      await editCustomer(tappedId, refId, name, description, industryId, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addCustomer(refId, name, description, industryId, token);
    }

    dispatch(reset(RXFORM_CUSTOMER));
    dispatch(clearCustomers());
    dispatch(downloadCustomersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setCustomerSelectedIndustry(''));
  } finally {
    dispatch(addingEditingCustomer(false));
  }
};
