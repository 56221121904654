import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_SERVICE_REQUESTS = 'DOWNLOADING_SERVICE_REQUESTS';
export const downloadingServiceRequests = makeActionCreator(DOWNLOADING_SERVICE_REQUESTS, 'status');

export const SET_SERVICE_REQUESTS = 'SET_SERVICE_REQUESTS';
export const setServiceRequests = makeActionCreator(SET_SERVICE_REQUESTS, 'data');

export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST';
export const addServiceRequest = makeActionCreator(ADD_SERVICE_REQUEST, 'data');

export const CLEAR_SERVICE_REQUESTS = 'CLEAR_SERVICE_REQUESTS';
export const clearServiceRequests = makeActionCreator(CLEAR_SERVICE_REQUESTS);

export const DOWNLOADING_DELETING_SERVICE_REQUEST = 'DOWNLOADING_DELETING_SERVICE_REQUEST';
export const downloadingDeletingServiceRequest = makeActionCreator(DOWNLOADING_DELETING_SERVICE_REQUEST, 'status');


export const SET_SERVICE_REQUEST_SELECTED_PAGE_SIZE = 'SET_SERVICE_REQUEST_SELECTED_PAGE_SIZE';
export const setServiceRequestSelectedPageSize = makeActionCreator(SET_SERVICE_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_SERVICE_REQUEST_SELECTED_ORDER_BY = 'SET_SERVICE_REQUEST_SELECTED_ORDER_BY';
export const setServiceRequestSelectedOrderBy = makeActionCreator(SET_SERVICE_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_SERVICE_REQUEST_TAPPED_ID = 'SET_SERVICE_REQUEST_TAPPED_ID';
export const setServiceRequestTappedId = makeActionCreator(SET_SERVICE_REQUEST_TAPPED_ID, 'id');

export const SET_SERVICE_REQUEST_SEARCH_TEXT = 'SET_SERVICE_REQUEST_SEARCH_TEXT';
export const setServiceRequestSearchText = makeActionCreator(SET_SERVICE_REQUEST_SEARCH_TEXT, 'text');

export const SET_SERVICE_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SERVICE_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setServiceRequestAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SERVICE_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_DEALERS = 'DOWNLOADING_DEALERS';
export const downloadingDealers = makeActionCreator(DOWNLOADING_DEALERS, 'status');

export const SET_DEALERS = 'SET_DEALERS';
export const setDealers = makeActionCreator(SET_DEALERS, 'data');

export const ADD_DEALER = 'ADD_DEALER';
export const addDealer = makeActionCreator(ADD_DEALER, 'data');

export const CLEAR_DEALERS = 'CLEAR_DEALERS';
export const clearDealers = makeActionCreator(CLEAR_DEALERS);

export const DOWNLOADING_DELETING_DEALER = 'DOWNLOADING_DELETING_DEALER';
export const downloadingDeletingDealer = makeActionCreator(DOWNLOADING_DELETING_DEALER, 'status');


export const SET_DEALER_SELECTED_PAGE_SIZE = 'SET_DEALER_SELECTED_PAGE_SIZE';
export const setDealerSelectedPageSize = makeActionCreator(SET_DEALER_SELECTED_PAGE_SIZE, 'size');

export const SET_DEALER_SELECTED_ORDER_BY = 'SET_DEALER_SELECTED_ORDER_BY';
export const setDealerSelectedOrderBy = makeActionCreator(SET_DEALER_SELECTED_ORDER_BY, 'order');

export const SET_DEALER_TAPPED_ID = 'SET_DEALER_TAPPED_ID';
export const setDealerTappedId = makeActionCreator(SET_DEALER_TAPPED_ID, 'id');

export const SET_DEALER_SEARCH_TEXT = 'SET_DEALER_SEARCH_TEXT';
export const setDealerSearchText = makeActionCreator(SET_DEALER_SEARCH_TEXT, 'text');

export const SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setDealerAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
