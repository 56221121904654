import { reset } from 'redux-form';
import {
  importingEditingTokenImport, clearTokenImports, setAlertErrorMessage, setTokenImportSelectedCsv,
  setTokenImportSelectedVoucher, setFunctionalPageMode,
} from '../simple-action';
import { importTokenImport, editTokenImport } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_TOKEN_IMPORT } from '../../../constant';
import downloadTokenImportsAsync from './downloadTokenImportsAsync';

export default (code, validFrom, validUntil) => async (dispatch, getState) => {
  try {
    dispatch(importingEditingTokenImport(true));

    const { token } = getState().authentication;
    const { tappedId, selectedCsv, selectedVoucherId } = getState().uiTokenImport;

    if (tappedId) {
      await editTokenImport(tappedId, code, validFrom, validUntil, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await importTokenImport(selectedVoucherId, selectedCsv, token);
    }

    dispatch(reset(RXFORM_TOKEN_IMPORT));
    dispatch(clearTokenImports());
    dispatch(downloadTokenImportsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setTokenImportSelectedCsv(''));
    dispatch(setTokenImportSelectedVoucher(''));
  } finally {
    dispatch(importingEditingTokenImport(false));
  }
};
