import {
  buildQueryParams, sendGetRequest, transformQueryResult, sendPostRequest,
  sendPutRequest, sendDeleteRequest, transformMediaData,
} from '../../helper';
import {
  REST_URL_CATALOG, REST_URL_ADD_EDIT_CAR, REST_URL_VIEW_DELETE_CAR,
  REST_URL_MODELS, REST_URL_ADD_EDIT_MODEL, REST_URL_VIEW_DELETE_MODEL,
} from './constant';

export * from '../../helper';

export const downloadCatalog = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_CATALOG, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = transformMediaData(response.data);
  return transformQueryResult(response, transformData);
};

export const addCar = async (
  modelId, trim, color, year, isDefault, description, media, token,
) => {
  const body = {
    modelId, trim, color, year, isDefault, description, media,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_CAR, body, token);
};

export const editCar = async (
  id, modelId, trim, color, year, isDefault, description, media, token,
) => {
  const body = {
    id, modelId, trim, color, year, isDefault, description, media,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_CAR, body, token);
};

export const downloadCar = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CAR.replace(/\{id\}/, id), token);
  const transformData = transformMediaData(response);
  return transformData;
};

export const deleteCar = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CAR.replace(/\{id\}/, id), token);
};

export const downloadModels = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_MODELS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addModel = async (
  name, description, brochureUrl, image, token,
) => {
  const body = {
    name, description, brochureUrl, image,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_MODEL, body, token);
};

export const editModel = async (
  id, name, description, brochureUrl, image, token,
) => {
  const body = {
    id, name, description, brochureUrl, image,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_MODEL, body, token);
};

export const downloadModel = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_MODEL.replace(/\{id\}/, id), token);
  return response;
};

export const deleteModel = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_MODEL.replace(/\{id\}/, id), token);
};
