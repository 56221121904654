import { downloadingSummaryLoginUsing, setSummaryLoginUsing } from '../simple-action';
import { downloadSummaryLoginUsing } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryLoginUsing(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryLoginUsing(token);

    dispatch(setSummaryLoginUsing(result));
  } finally {
    dispatch(downloadingSummaryLoginUsing(false));
  }
};
