import {
  DOWNLOADING_POINT_TRANSACTIONS, DOWNLOADING_DELETING_POINT_TRANSACTION,
  SET_POINT_TRANSACTION_SELECTED_PAGE_SIZE, SET_POINT_TRANSACTION_TAPPED_ID,
  SET_POINT_TRANSACTION_SEARCH_TEXT, SET_POINT_TRANSACTION_SELECTED_ORDER_BY,
  SET_POINT_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';
import { INITIAL_ORDER_BY_POINT_TRANSACTIONS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_POINT_TRANSACTIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_POINT_TRANSACTIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_POINT_TRANSACTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_POINT_TRANSACTION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_POINT_TRANSACTION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_POINT_TRANSACTION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_POINT_TRANSACTION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_POINT_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
