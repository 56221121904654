import { transformSearchText, downloadWfas } from '../../../helper';
import { downloadingWfas, setWfas } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, wfas, uiWfa } = getState();
  const { token } = authentication;
  const { meta } = wfas;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiWfa;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingWfas(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadWfas(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setWfas(list));
  } finally {
    dispatch(downloadingWfas(false));
  }
};
