import {
  addPurchaseRequest, downloadingPurchaseRequest,
} from '../simple-action';
import { downloadPurchaseRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingPurchaseRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPurchaseRequest;

    const purchaseRequest = await downloadPurchaseRequest(tappedId, token);
    dispatch(addPurchaseRequest(purchaseRequest));
  } finally {
    dispatch(downloadingPurchaseRequest(false));
  }
};
