import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_CHALLENGE } from '../../../constant';
import { addChallenge, editChallenge, toNumber } from '../../../helper';
import {
  addingEditingChallenge, clearChallenges, setAlertErrorMessage, setChallengeSelectedCategory,
  setChallengeSelectedScanPolicy, setChallengeSelectedVisibilityStatus, setFunctionalPageMode,
} from '../simple-action';
import downloadChallengeAsync from './downloadChallengesAsync';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

const transformCode = (data) => data.map((x) => ({
  id: x.id || '',
  order: x.order,
  code: x.code || '',
  points: x.points,
  label: x.label,
}));

export default (
  title, content, validFrom, validUntil, tags, media, codes, order,
) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingChallenge(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedCategory, selectedScanPolicy, selectedVisibilityStatus,
      selectedIsPromoted,
    } = getState().uiChallenge;
    const { data } = getState().challenges;

    const found = data[tappedId];
    const categoryId = selectedCategory || found.category.id;
    const visibilityStatus = selectedVisibilityStatus || found.visibilityStatus;
    const scanPolicy = selectedScanPolicy || found.scanPolicy;
    const medias = media.length > 0 ? transformMedia(media) : [];
    const code = transformCode(codes);
    const isPromoted = selectedIsPromoted ? selectedIsPromoted.value : found.isPromoted;
    const newOrderValue = isPromoted && order ? toNumber(order) : 0;

    if (tappedId) {
      await editChallenge(tappedId, title, content, validFrom, validUntil, categoryId,
        visibilityStatus, scanPolicy, tags, medias, code,
        isPromoted, newOrderValue, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addChallenge(title, content, validFrom, validUntil, categoryId,
        visibilityStatus, scanPolicy, tags, medias, code,
        isPromoted, newOrderValue, token);
    }

    dispatch(reset(RXFORM_CHALLENGE));
    dispatch(clearChallenges());
    dispatch(downloadChallengeAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setChallengeSelectedCategory(''));
    dispatch(setChallengeSelectedScanPolicy(''));
    dispatch(setChallengeSelectedVisibilityStatus(''));
  } finally {
    dispatch(addingEditingChallenge(false));
  }
};
