import { downloadingNotificationTasks, setNotificationTasks } from '../simple-action';
import { downloadNotificationTasks, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, notificationTasks, uiNotificationTask, uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta, data } = notificationTasks;
  const { selectedPageSize, searchBarText, orderBy } = uiNotificationTask;
  const { filterString } = uiFunctionalPage;
  const newFilterString = `status=enabled${filterString}`;
  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingNotificationTasks(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadNotificationTasks(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      token,
      newFilterString,
    );

    if (list) {
      dispatch(setNotificationTasks(list));
    }
  } finally {
    dispatch(downloadingNotificationTasks(false));
  }
};
