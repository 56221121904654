import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


const SplashScreen = ({ onAppear }) => {
  useEffect(() => {
    onAppear();
  }, [onAppear]);

  return (
    <div>
      Splash Screen
    </div>
  );
};

export default SplashScreen;

SplashScreen.propTypes = {
  onAppear: PropTypes.func.isRequired,
};
