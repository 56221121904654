import {
  addPacketType, clearPacketTypes, downloadingDeletingPacketType, setAlertErrorMessage,
  setFunctionalPageMode, setPacketTypeTappedId,
} from '../simple-action';
import { downloadPacketType, deletePacketType } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadPacketTypesAsync from './downloadPacketTypesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPacketType(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPacketType;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deletePacketType(tappedId, token);
      dispatch(setPacketTypeTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearPacketTypes());
      dispatch(downloadPacketTypesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const packetType = await downloadPacketType(tappedId, token);
      dispatch(addPacketType(packetType));
    }
  } finally {
    dispatch(downloadingDeletingPacketType(false));
  }
};
