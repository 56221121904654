import { downloadBalance } from '../../../helper';
import { downloadingBalance, setBalance } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingBalance(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAchievement;

    const result = await downloadBalance(tappedId, token);

    dispatch(setBalance(result));
  } finally {
    dispatch(downloadingBalance(false));
  }
};
