import {
  SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY, CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY,
} from '../action';

const initialState = {
  count: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY: {
      return { ...state, count: action.data.count };
    }
    case CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY:
      return initialState;
    default:
      return state;
  }
};
