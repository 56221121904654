import moment from 'moment';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_FLOAT, ENUM_FIELD_INTEGER, ENUM_FIELD_OPTIONS,
  ENUM_FIELD_PICKER, ENUM_FIELD_TEXT, ENUM_FIELD_TIME,
  RXFIELD_ADDITIONAL_FIELD_APPLIED_ON, RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE,
  RXFIELD_ADDITIONAL_FIELD_LABEL, RXFIELD_ADDITIONAL_FIELD_NAME, RXFIELD_ADDITIONAL_FIELD_ORDER,
  TIME_FORMAT,
} from './constant';
import { toNumber } from './helper';
import GlobalLocalizedString from '../../localization';
import LocalizedString from './localization';
import { rxFormValidateRequiredFields } from '../../validation';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateAdditionalField = (values) => {
  const requiredFields = [
    RXFIELD_ADDITIONAL_FIELD_APPLIED_ON,
    RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE,
    RXFIELD_ADDITIONAL_FIELD_LABEL,
    RXFIELD_ADDITIONAL_FIELD_NAME,
    RXFIELD_ADDITIONAL_FIELD_ORDER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  switch (values.fieldType) {
    case ENUM_FIELD_DATE:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_DATE_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue, TIME_FORMAT)
          .isAfter(moment(values.dateTimeMinValue, TIME_FORMAT))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndTime;
      } break;
    case ENUM_FIELD_TEXT:
      if (!values.textKeyboardType) {
        errors.textKeyboardType = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (values.textMinLength && values.textMaxLength
        && (toNumber(values.textMinLength) > toNumber(values.textMaxLength)
        || toNumber(values.textMaxLength) < toNumber(values.textMinLength))) {
        errors.textMaxLength = LocalizedString.additionalFieldScreen.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_INTEGER:
      if (values.intMinValue && values.intMaxValue
        && (toNumber(values.intMinValue) > toNumber(values.intMaxValue)
          || toNumber(values.intMaxValue) < toNumber(values.intMinValue))) {
        errors.intMaxValue = LocalizedString.additionalFieldScreen.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_FLOAT:
      if (values.floatMinValue && values.floatMaxValue
        && (parseFloat(values.floatMinValue) > parseFloat(values.floatMaxValue)
        || parseFloat(values.floatMaxValue) < parseFloat(values.floatMinValue))) {
        errors.floatMaxValue = LocalizedString.additionalFieldScreen.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_PICKER:
      if (!values.pickerMode) {
        errors.pickerMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      } break;
    case ENUM_FIELD_OPTIONS:
      if (!values.optionMode) {
        errors.optionMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValueSeparator) {
        errors.optionValueSeparator = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      break;
    default: break;
  }

  return errors;
};
