import { downloadingCompanies, setCompanies } from '../simple-action';
import { downloadCompanies, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCompanies(true));

    const { token } = getState().authentication;
    const { companiesSearchText } = getState().notification;

    const list = await downloadCompanies(transformSearchText(companiesSearchText.toLowerCase()),
      token);

    if (list) {
      dispatch(setCompanies(list));
    }
  } finally {
    dispatch(downloadingCompanies(false));
  }
};
