import {
  enablingDisablingSurveyQuestion, setAlertErrorMessage, setSurveyQuestionSelectedOrderBy,
  setSurveyQuestionSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_SURVEY_QUESTIONS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableSurveyQuestion } from '../../../helper';
import downloadSurveyQuestionsAsync from './downloadSurveyQuestionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingSurveyQuestion(true));

    const { token } = getState().authentication;
    const { data } = getState().surveyQuestions;
    const { tappedId } = getState().uiSurveyQuestion;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableSurveyQuestion(tappedId, status, token);

    dispatch(setSurveyQuestionSelectedPageSize(20));
    dispatch(setSurveyQuestionSelectedOrderBy(INITIAL_ORDER_BY_SURVEY_QUESTIONS));
    dispatch(downloadSurveyQuestionsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(enablingDisablingSurveyQuestion(false));
  }
};
