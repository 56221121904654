import {
  downloadingSummaryTimezoneDistribution,
  setSummaryTimezoneDistribution,
} from '../simple-action';
import { downloadSummaryTimezoneDistribution } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryTimezoneDistribution(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryTimezoneDistribution(token);

    dispatch(setSummaryTimezoneDistribution(result));
  } finally {
    dispatch(downloadingSummaryTimezoneDistribution(false));
  }
};
