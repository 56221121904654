import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, Grid, Paper, Tabs, Tab,
} from '@material-ui/core';
import {
  AccountMultiple, ClockIn, ClockOut,
} from 'mdi-material-ui';
import { Field, reduxForm } from 'redux-form';
import {
  BasePage, TotalCountSection, PieChart, AttendanceTrendChart,
} from '../../component';
import LocalizedString from '../../localization';
import {
  COLOR_SECONDARY, CLOCK_IN_OUT_STATUS_TREND_BARS, CLOCK_IN_OUT_LOCATION_TREND_BARS,
  COLOR_DISABLED_ROW, COLOR_PRIMARY, COLOR_BACKGROUND, RXFORM_ATTENDANCE_SUMMARY,
  RXFIELD_ATTENDANCE_SUMMARY_PERIOD, FILTER_PEPRIOD,
} from '../../constant';
import {
  SummaryCountShape, ClockInOutStatusTrendShape, ClockInOutLocationTrendShape,
  FormInitialValueShape,
} from '../../type';
import { renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  chartContainer: {
    padding: 24,
    margin: '30px 0px 30px 0px',
    justifyContent: 'space-between',
  },
  tabElement: {
    minWidth: '50%',
    backgroundColor: COLOR_DISABLED_ROW,
  },
  tabElementActive: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_BACKGROUND,
    transform: 'scaleY(1.1)',
    borderRadius: 6,
    boxShadow: COLOR_PRIMARY,
  },
  tabIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginBottom: 20,
    overflow: 'visible',
  },
  tabScroller: {
    overflowX: 'visible',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
}));

const renderPieChartField = (data) => data.map((row) => (
  <Grid container alignItems="stretch" key={row.id}>
    {row.children.map((item) => (
      item.title ? (
        <Grid item xs={12} md key={item.id}>
          <PieChart
            data={item.data}
            loading={item.downloading}
            argumentField="name"
            title={item.title}
            valueField="count"
          />
        </Grid>
      ) : (
        <Grid item xs={12} md key={item.id} />
      )
    ))}
  </Grid>
));

const renderTrendChart = (
  data, downloading, onTooltipTargetItemChange, targetItem, title,
  chartBars,
) => (
  <AttendanceTrendChart
    data={data}
    downloading={downloading}
    title={title}
    targetItem={targetItem}
    onTooltipTargetItemChange={onTooltipTargetItemChange}
    chartBars={chartBars}
  />
);

const AttendanceSummaryPage = ({
  downloadingTotalUsers, totalUsers, onAppear,
  downloadingTotalClockInToday, totalClockInToday,
  downloadingTotalClockOutToday, totalClockOutToday,
  downloadingTodayClockInStatus, todayClockInStatus,
  downloadingTodayClockOutStatus, todayClockOutStatus,
  downloadingTodayClockInLocationStatus, todayClockInLocationStatus,
  downloadingTodayClockOutLocationStatus, todayClockOutLocationStatus,
  downloadingClockInStatusTrend, clockInStatusTrend,
  downloadingClockOutStatusTrend, clockOutStatusTrend,
  downloadingClockInLocationTrend, clockInLocationTrend,
  downloadingClockOutLocationTrend, clockOutLocationTrend,
  onClockInStatusTrendTargetItemChange, clockInStatusTrendTargetItem,
  onClockOutStatusTrendTargetItemChange, clockOutStatusTrendTargetItem,
  onClockInLocationTrendTargetItemChange, clockInLocationTrendTargetItem,
  onClockOutLocationTrendTargetItemChange, clockOutLocationTrendTargetItem,
  workLocation, downloadingWorkLocation, activeTab, onTabPressed,
  onPeriodSelected, initialValues,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  const renderTrend = () => (
    <div>
      {renderTrendChart(
        clockInStatusTrend, downloadingClockInStatusTrend,
        onClockInStatusTrendTargetItemChange, clockInStatusTrendTargetItem,
        LocalizedString.attendanceSummaryScreen.labelClockInStatusTrend,
        CLOCK_IN_OUT_STATUS_TREND_BARS,
      )}
      {renderTrendChart(
        clockOutStatusTrend, downloadingClockOutStatusTrend,
        onClockOutStatusTrendTargetItemChange, clockOutStatusTrendTargetItem,
        LocalizedString.attendanceSummaryScreen.labelClockOutStatusTrend,
        CLOCK_IN_OUT_STATUS_TREND_BARS,
      )}
      {renderTrendChart(
        clockInLocationTrend, downloadingClockInLocationTrend,
        onClockInLocationTrendTargetItemChange, clockInLocationTrendTargetItem,
        LocalizedString.attendanceSummaryScreen.labelClockInLocationTrend,
        CLOCK_IN_OUT_LOCATION_TREND_BARS,
      )}
      {renderTrendChart(
        clockOutLocationTrend, downloadingClockOutLocationTrend,
        onClockOutLocationTrendTargetItemChange, clockOutLocationTrendTargetItem,
        LocalizedString.attendanceSummaryScreen.labelClockOutLocationTrend,
        CLOCK_IN_OUT_LOCATION_TREND_BARS,
      )}
    </div>
  );

  const dataChart = [
    {
      id: '1x',
      children: [
        {
          id: '1xa',
          downloading: downloadingWorkLocation,
          data: workLocation,
          title: LocalizedString.attendanceSummaryScreen.labelWorkLocation,
        },
      ],
    },
    {
      id: '1a',
      children: [
        {
          id: '1aa',
          downloading: downloadingTodayClockInStatus,
          data: todayClockInStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockInStatus,
        },
        {
          id: '1ab',
          downloading: downloadingTodayClockInLocationStatus,
          data: todayClockInLocationStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockInLocationStatus,
        },
      ],
    },
    {
      id: '2a',
      children: [
        {
          id: '2aa',
          downloading: downloadingTodayClockOutStatus,
          data: todayClockOutStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockOutStatus,
        },
        {
          id: '2ab',
          downloading: downloadingTodayClockOutLocationStatus,
          data: todayClockOutLocationStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockOutLocationStatus,
        },
      ],
    },
  ];

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>
          {LocalizedString.attendanceSummaryScreen.title}
        </Typography>
      </div>

      <div className={classes.bodyContainer}>
        <TotalCountSection
          data={[
            {
              downloading: downloadingTotalUsers,
              count: totalUsers,
              icon: <AccountMultiple />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalUser,
            },
            {
              downloading: downloadingTotalClockInToday,
              count: totalClockInToday,
              icon: <ClockIn />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalClockInToday,
            },
            {
              downloading: downloadingTotalClockOutToday,
              count: totalClockOutToday,
              icon: <ClockOut />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalClockOutToday,
            },
          ]}
        />
        <Paper elevation={3} className={classes.chartContainer}>
          <Tabs
            value={activeTab}
            classes={{
              root: classes.tabRoot,
              indicator: classes.tabIndicator,
              scroller: classes.tabScroller,
            }}
            centered
          >
            <Tab
              value={0}
              label={LocalizedString.attendanceSummaryScreen.labelPercentage}
              classes={{
                root: classes.tabElement,
                selected: classes.tabElementActive,
              }}
              onClick={() => onTabPressed(0)}
            />
            <Tab
              value={1}
              label={LocalizedString.attendanceSummaryScreen.labelTrend}
              classes={{
                root: classes.tabElement,
                selected: classes.tabElementActive,
              }}
              onClick={() => onTabPressed(1)}
            />
          </Tabs>

          {activeTab === 0 ? (
            <div className={classes.right}>
              <Field
                name={RXFIELD_ATTENDANCE_SUMMARY_PERIOD}
                component={renderReduxFormSimpleDropdownField}
                placeholder={LocalizedString.attendanceSummaryScreen.placeholderPeriod}
                label={LocalizedString.attendanceSummaryScreen.labelPeriod}
                onOptionSelected={onPeriodSelected}
                data={FILTER_PEPRIOD}
                value={initialValues.period}
                disabled={downloadingWorkLocation
                || downloadingTodayClockInStatus || downloadingTodayClockOutStatus
                || downloadingTodayClockInLocationStatus || downloadingTodayClockOutLocationStatus}
              />
            </div>
          ) : null}
          {activeTab === 0 ? (renderPieChartField(dataChart)) : renderTrend() }
        </Paper>
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_ATTENDANCE_SUMMARY,
})(AttendanceSummaryPage);

AttendanceSummaryPage.propTypes = {
  activeTab: PropTypes.number.isRequired,
  initialValues: FormInitialValueShape.isRequired,
  downloadingTotalUsers: PropTypes.bool,
  downloadingWorkLocation: PropTypes.bool,
  downloadingTotalClockInToday: PropTypes.bool,
  downloadingTotalClockOutToday: PropTypes.bool,
  downloadingTodayClockInStatus: PropTypes.bool,
  downloadingTodayClockOutStatus: PropTypes.bool,
  downloadingTodayClockInLocationStatus: PropTypes.bool,
  downloadingTodayClockOutLocationStatus: PropTypes.bool,
  downloadingClockInStatusTrend: PropTypes.bool,
  downloadingClockOutStatusTrend: PropTypes.bool,
  downloadingClockInLocationTrend: PropTypes.bool,
  downloadingClockOutLocationTrend: PropTypes.bool,

  clockInStatusTrendTargetItem: PropTypes.object,
  clockOutStatusTrendTargetItem: PropTypes.object,
  clockInLocationTrendTargetItem: PropTypes.object,
  clockOutLocationTrendTargetItem: PropTypes.object,

  todayClockInLocationStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockOutLocationStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockOutStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockInStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  workLocation: PropTypes.arrayOf(SummaryCountShape).isRequired,
  totalClockOutToday: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalClockInToday: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  clockInStatusTrend: PropTypes.arrayOf(ClockInOutStatusTrendShape).isRequired,
  clockOutStatusTrend: PropTypes.arrayOf(ClockInOutStatusTrendShape).isRequired,
  clockInLocationTrend: PropTypes.arrayOf(ClockInOutLocationTrendShape).isRequired,
  clockOutLocationTrend: PropTypes.arrayOf(ClockInOutLocationTrendShape).isRequired,

  onAppear: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
  onPeriodSelected: PropTypes.func.isRequired,
  onClockInStatusTrendTargetItemChange: PropTypes.func.isRequired,
  onClockOutStatusTrendTargetItemChange: PropTypes.func.isRequired,
  onClockInLocationTrendTargetItemChange: PropTypes.func.isRequired,
  onClockOutLocationTrendTargetItemChange: PropTypes.func.isRequired,
};

AttendanceSummaryPage.defaultProps = {
  downloadingTotalUsers: false,
  downloadingWorkLocation: false,
  downloadingTotalClockInToday: false,
  downloadingTotalClockOutToday: false,
  downloadingTodayClockInStatus: false,
  downloadingTodayClockOutStatus: false,
  downloadingTodayClockInLocationStatus: false,
  downloadingTodayClockOutLocationStatus: false,
  downloadingClockInStatusTrend: false,
  downloadingClockOutStatusTrend: false,
  downloadingClockInLocationTrend: false,
  downloadingClockOutLocationTrend: false,
  clockInStatusTrendTargetItem: null,
  clockOutStatusTrendTargetItem: null,
  clockInLocationTrendTargetItem: null,
  clockOutLocationTrendTargetItem: null,
};
