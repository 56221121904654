
import { INITIAL_ORDER_BY_VOUCHER_PURCHASE_REQUESTS } from '../../constant';
import {
  DOWNLOADING_VOUCHER_PURCHASE_REQUEST,
  DOWNLOADING_VOUCHER_PURCHASE_REQUESTS, SET_VOUCHER_PURCHASE_REQUEST_SEARCH_TEXT,
  SET_VOUCHER_PURCHASE_REQUEST_SELECTED_ORDER_BY,
  SET_VOUCHER_PURCHASE_REQUEST_SELECTED_PAGE_SIZE,
  SET_VOUCHER_PURCHASE_REQUEST_TAPPED_ID,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_VOUCHER_PURCHASE_REQUESTS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_VOUCHER_PURCHASE_REQUESTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_VOUCHER_PURCHASE_REQUEST:
      return { ...state, downloadingDeleting: action.status };
    case SET_VOUCHER_PURCHASE_REQUEST_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOUCHER_PURCHASE_REQUEST_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_VOUCHER_PURCHASE_REQUEST_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOUCHER_PURCHASE_REQUEST_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
