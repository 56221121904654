import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    surveyMenu: {
      title: 'Survey',
    },
    surveyCategoryScreen: {
      title: 'Survey Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      placeholderName: 'Name',
      placeholderDescription: 'Description',

      buttonCaptionCreateNewSurveyCategory: 'Create New Survey Category',
      buttonCaptionEditSurveyCategory: 'Edit Survey Category',
      buttonCaptionDeleteSurveyCategory: 'Delete Survey Category',
    },
    quizMenu: {
      title: 'Quiz',
    },
    quizScreen: {
      title: 'Quiz',

      labelNo: 'No',
      labelTitle: 'Title',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelDuration: 'Duration',
      labelCategory: 'Category',
      labelVisibilityStatus: 'Status',
      labelQuizResult: 'Quiz Result',
      labelRank: 'Rank',
      labelUserName: 'User Name',
      labelScore: 'Score',
      labelCopied: 'Copied',

      placeholderTitle: 'Title',
      placeholderDescription: 'Description',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderDuration: 'Minutes',
      placeholderCategory: 'Category',
      placeholderVisibilityStatus: 'Status',
      placeholderParticipantCount: 'Participant Count',
      placeholderDeeplink: 'Deep Link',

      buttonCaptionSendToMe: 'Send To Me',
      buttonCaptionCreateNewQuiz: 'Create New Quiz',
      buttonCaptionEditQuiz: 'Edit Quiz',
      buttonCaptionDeleteQuiz: 'Delete Quiz',
      buttonCaptionShowParticipant: 'Show Participants',
    },
    quizCategoryScreen: {
      title: 'Quiz Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      placeholderName: 'Name',
      placeholderDescription: 'Description',

      buttonCaptionCreateNewQuizCategory: 'Create New Quiz Category',
      buttonCaptionEditQuizCategory: 'Edit Quiz Category',
      buttonCaptionDeleteQuizCategory: 'Delete Quiz Category',
    },
    quizResponseScreen: {
      title: 'Quiz Response',

      labelNo: 'No',
      labelUser: 'User',
      labelQuiz: 'Quiz',
      labelScore: 'Score',
      labelStartAt: 'Started At',
      labelFinishAt: 'Finished At',
      labelDuration: 'Duration',
      labelQuizResponse: 'Quiz Response',
      labelMinutes: 'min',
      labelSeconds: 'sec',

      placeholderQuizId: 'Quiz ID',
      placeholderUserId: 'User ID',
      placeholderQuizTitle: 'Quiz Title',
      placeholderUser: 'User',
      placeholderUsername: 'User Name',
      placeholderScore: 'Score',
      placeholderStartAt: 'Started At',
      placeholderFinishAt: 'Finished At',
      placeholderDuration: 'Duration',
      placeholderQuiz: 'Quiz',
      placeholderIsFinished: 'Is Finished',
    },
    surveyScreen: {
      title: 'Survey',

      labelNo: 'No',
      labelTitle: 'Title',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelCategory: 'Category',
      labelVisibilityStatus: 'Visibility Status',
      labelSurveyResult: 'Survey Result',
      labelCopied: 'Copied',

      placeholderTitle: 'Title',
      placeholderDescription: 'Description',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderCategory: 'Category',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderParticipantCount: 'Participant Count',
      placeholderDeeplink: 'Deep Link',

      buttonCaptionSendToMe: 'Send To Me',
      buttonCaptionCreateNewSurvey: 'Create New Survey',
      buttonCaptionEditSurvey: 'Edit Survey',
      buttonCaptionDeleteSurvey: 'Delete Survey',
      buttonCaptionShowParticipant: 'Show Participants',
    },
    surveyQuestionScreen: {
      title: 'Survey Question',

      labelNo: 'No',
      labelName: 'Name',
      labelLabel: 'Label',
      labelFieldType: 'Field Type',
      labelOrder: 'Order',
      labelVisible: 'Visible',
      labelReadOnly: 'Read Only',
      labelRequired: 'Required',
      labelOptionValues: 'Option Values',
      labelOptionValuesHelper: 'Write each value separated by |',
      labelLogicalOperatorHelper: 'You can specify multiple values separated by |',
      labelStatus: 'Status',
      labelSurvey: 'Survey',

      buttonCaptionCreateNewSurveyQuestion: 'Create New Survey Question',
      buttonCaptionEditSurveyQuestion: 'Edit Survey Question',
      buttonCaptionDeleteSurveyQuestion: 'Delete Survey Question',
      buttonCaptionEnable: 'Enable',
      buttonCaptionDisable: 'Disable',

      placeholderName: 'Name',
      placeholderLabel: 'Label',
      placeholderPlaceholder: 'Placeholder',
      placeholderValidationMessage: 'Validation Message',
      placeholderDefaultValue: 'Default Value',
      placeholderOrder: 'Order',
      placeholderRequired: 'Required',
      placeholderVisible: 'Visible',
      placeholderReadOnly: 'Read Only',
      placeholderTextMultiline: 'Text Multiline',
      placeholderTextKeyboardType: 'Text Keyboard Type',
      placeholderFieldType: 'Field Type',
      placeholderPickerMode: 'Picker Mode',
      placeholderOptionMode: 'Option Mode',
      placeholderOptionValues: 'Option A|Option B|Option C',
      placeholderOptionValueSeparator: 'Option Value Separator',
      placeholderSectionId: 'Section ID',
      placeholderSectionLabel: 'Section Label',
      placeholderTextMinLength: 'Text Min Length',
      placeholderTextMaxLength: 'Text Max Length',
      placeholderTextRegexValidation: 'Text Regex Validation',
      placeholderIntMinValue: 'Int Min Value',
      placeholderIntMaxValue: 'Int Max Value',
      placeholderFloatMinValue: 'Float Min Value',
      placeholderFloatMaxValue: 'Float Max Value',
      placeholderDateTimeMinValue: 'Date/Time Min Value',
      placeholderDateTimeMaxValue: 'Date/Time Max Value',
      placeholderParent: 'Parent',
      placeholderShowIfParentValue: 'Show If Parent Value',
      placeholderLogicalOperator: 'Logical Operator',
      placeholderMustUseCamera: 'Must Use Camera',
      placeholderSurvey: 'Survey',
      placeholderImage: 'Image',
      placeholderClearImage: 'Clear Image',

      msgEnableConfirmation: 'Are you sure want to enable this item?',
      msgDisableConfirmation: 'Are you sure want to disable this item?',
    },
    surveyResponseScreen: {
      title: 'Survey Response',

      labelNo: 'No',
      labelSurvey: 'Survey',
      labelUser: 'User',
      labelCreatedDate: 'Created Date',
      labelSurveyAnswers: 'Survey Answers',

      placeholderCreatedDate: 'Created Date',
      placeholderUserName: 'User Name',
      placeholderSurvey: 'Survey',
      placeholderSurveyTitle: 'Survey Title',
      placeholderSurveyDescription: 'Survey Description',
      placeholderUserID: 'User ID',
    },
    quizQuestionScreen: {
      title: 'Quiz Question',

      labelNo: 'No',
      labelName: 'Name',
      labelLabel: 'Label',
      labelFieldType: 'Field Type',
      labelOrder: 'Order',
      labelVisible: 'Visible',
      labelReadOnly: 'Read Only',
      labelRequired: 'Required',
      labelOptionValues: 'Option Values',
      labelOptionValuesHelper: 'Write each value separated by |',
      labelLogicalOperatorHelper: 'You can specify multiple values separated by |',
      labelStatus: 'Status',
      labelQuiz: 'Quiz',

      buttonCaptionCreateNewQuizQuestion: 'Create New Quiz Question',
      buttonCaptionEditQuizQuestion: 'Edit Quiz Question',
      buttonCaptionDeleteQuizQuestion: 'Delete Quiz Question',
      buttonCaptionEnable: 'Enable',
      buttonCaptionDisable: 'Disable',

      placeholderName: 'Name',
      placeholderLabel: 'Label',
      placeholderValidationMessage: 'Validation Message',
      placeholderOrder: 'Order',
      placeholderRequired: 'Required',
      placeholderVisible: 'Visible',
      placeholderReadOnly: 'Read Only',
      placeholderTextMultiline: 'Text Multiline',
      placeholderTextKeyboardType: 'Text Keyboard Type',
      placeholderFieldType: 'Field Type',
      placeholderPickerMode: 'Picker Mode',
      placeholderOptionMode: 'Option Mode',
      placeholderOptionValues: 'Option A|Option B|Option C',
      placeholderOptionValueSeparator: 'Option Value Separator',
      placeholderSectionId: 'Section ID',
      placeholderSectionLabel: 'Section Label',
      placeholderTextMinLength: 'Text Min Length',
      placeholderTextMaxLength: 'Text Max Length',
      placeholderTextRegexValidation: 'Text Regex Validation',
      placeholderIntMinValue: 'Int Min Value',
      placeholderIntMaxValue: 'Int Max Value',
      placeholderFloatMinValue: 'Float Min Value',
      placeholderFloatMaxValue: 'Float Max Value',
      placeholderDateTimeMinValue: 'Date/Time Min Value',
      placeholderDateTimeMaxValue: 'Date/Time Max Value',
      placeholderLogicalOperator: 'Logical Operator',
      placeholderMustUseCamera: 'Must Use Camera',
      placeholderQuiz: 'Quiz',
      placeholderImage: 'Image',
      placeholderClearImage: 'Clear Image',
      placeholderScore: 'Score',
      placeholderCorrectAnswer: 'Correct Answer',

      msgEnableConfirmation: 'Are you sure want to enable this item?',
      msgDisableConfirmation: 'Are you sure want to disable this item?',
    },
  },
  id: {
    surveyMenu: {
      title: 'Survei',
    },
    surveyCategoryScreen: {
      title: 'Kategori Survei',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',

      buttonCaptionCreateNewSurveyCategory: 'Buat Kategori Survei Baru',
      buttonCaptionEditSurveyCategory: 'Ubah Kategori Survei',
      buttonCaptionDeleteSurveyCategory: 'Hapus Kategori Survei',
    },
    quizMenu: {
      title: 'Kuis',
    },
    quizScreen: {
      title: 'Kuis',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelDuration: 'Durasi',
      labelCategory: 'Kategori',
      labelVisibilityStatus: 'Status',
      labelQuizResult: 'Hasil Kuis',
      labelRank: 'Urutan',
      labelUserName: 'Nama Pengguna',
      labelScore: 'Skor',
      labelCopied: 'Disalin',

      placeholderTitle: 'Judul',
      placeholderDescription: 'Deskripsi',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderDuration: 'Menit',
      placeholderCategory: 'Kategori',
      placeholderVisibilityStatus: 'Status',
      placeholderParticipantCount: 'Jumlah Peserta',
      placeholderDeeplink: 'Tautan Dalam',

      buttonCaptionSendToMe: 'Kirim Kepada Saya',
      buttonCaptionCreateNewQuiz: 'Buat Kuis Baru',
      buttonCaptionEditQuiz: 'Ubah Kuis',
      buttonCaptionDeleteQuiz: 'Hapus Kuis',
      buttonCaptionShowParticipant: 'Tampilkan Peserta',
    },
    quizCategoryScreen: {
      title: 'Kategori Kuis',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',

      buttonCaptionCreateNewQuizCategory: 'Buat Kategori Kuis Baru',
      buttonCaptionEditQuizCategory: 'Ubah Kategori Kuis',
      buttonCaptionDeleteQuizCategory: 'Hapus Kategori Kuis',
    },
    quizResponseScreen: {
      title: 'Respon Kuis',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelQuiz: 'Kuis',
      labelScore: 'Skor',
      labelStartAt: 'Waktu Mulai',
      labelFinishAt: 'Waktu Selesai',
      labelDuration: 'Durasi',
      labelQuizResponse: 'Respon Kuis',
      labelMinutes: 'menit',
      labelSeconds: 'detik',

      placeholderQuizId: 'ID Kuis',
      placeholderUserId: 'ID Pengguna',
      placeholderQuizTitle: 'Judul Kuis',
      placeholderUser: 'Pengguna',
      placeholderUsername: 'Nama Pengguna',
      placeholderScore: 'Skor',
      placeholderStartAt: 'Waktu Mulai',
      placeholderFinishAt: 'Waktu Selesai',
      placeholderDuration: 'Durasi',
      placeholderQuiz: 'Kuis',
      placeholderIsFinished: 'Penanda Selesai',
    },
    surveyScreen: {
      title: 'Survei',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelCategory: 'Kategori',
      labelVisibilityStatus: 'Status Visibilitas',
      labelSurveyResult: 'Hasil Survei',
      labelCopied: 'Disalin',

      placeholderTitle: 'Title',
      placeholderDescription: 'Deskripsi',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderCategory: 'Kategori',
      placeholderVisibilityStatus: 'Status Visibilitas',
      placeholderParticipantCount: 'Jumlah Peserta',
      placeholderDeeplink: 'Tautan Dalam',

      buttonCaptionSendToMe: 'Kirim Kepada Saya',
      buttonCaptionCreateNewSurvey: 'Buat Survei Baru',
      buttonCaptionEditSurvey: 'Ubah Survei',
      buttonCaptionDeleteSurvey: 'Hapus Survei',
      buttonCaptionShowParticipant: 'Tampilkan Peserta',
    },
    surveyQuestionScreen: {
      title: 'Pertanyaan Survei',

      labelNo: 'No',
      labelLabel: 'Label',
      labelFieldType: 'Tipe Field',
      labelOrder: 'Urutan',
      labelVisible: 'Terlihat',
      labelReadOnly: 'Hanya Dibaca',
      labelRequired: 'Harus Diisi',
      labelOptionValues: 'Nilai-nilai Opsi',
      labelOptionValuesHelper: 'Tulis setiap nilai dipisahkan dengan |',
      labelLogicalOperatorHelper: 'Anda bisa menulis lebih dari satu pilihan yang dipisahkan dengan |',
      labelStatus: 'Status',
      labelSurvey: 'Survei',

      buttonCaptionCreateNewSurveyQuestion: 'Buat Pertanyaan Survei Baru',
      buttonCaptionEditSurveyQuestion: 'Ubah Pertanyaan Survei',
      buttonCaptionDeleteSurveyQuestion: 'Hapus Pertanyaan Survei',
      buttonCaptionEnable: 'Aktifkan',
      buttonCaptionDisable: 'Nonaktifkan',

      placeholderName: 'Nama',
      placeholderLabel: 'Label',
      placeholderPlaceholder: 'Placeholder',
      placeholderValidationMessage: 'Pesan Validasi',
      placeholderDefaultValue: 'Nilai Default',
      placeholderOrder: 'Urutan',
      placeholderRequired: 'Harus Diisi',
      placeholderVisible: 'Terlihat',
      placeholderReadOnly: 'Hanya Dibaca',
      placeholderTextMultiline: 'Teks Multi baris',
      placeholderTextKeyboardType: 'Tipe Keyboard Teks',
      placeholderFieldType: 'Tipe Field',
      placeholderPickerMode: 'Mode Pemilihan',
      placeholderOptionMode: 'Mode Opsi',
      placeholderOptionValues: 'Opsi A|Opsi B|Opsi C',
      placeholderOptionValueSeparator: 'Pemisah Nilai Opsi',
      placeholderSectionId: 'ID Bagian',
      placeholderSectionLabel: 'Label Bagian',
      placeholderTextMinLength: 'Panjang Teks Min.',
      placeholderTextMaxLength: 'Panjang Teks Maks.',
      placeholderTextRegexValidation: 'Validasi Regex Teks',
      placeholderIntMinValue: 'Nilai Min. Int',
      placeholderIntMaxValue: 'Nilai Maks. Int',
      placeholderFloatMinValue: 'Nilai Min Float',
      placeholderFloatMaxValue: 'Nilai Maks.Float',
      placeholderDateTimeMinValue: 'Nilai Min. Tanggal/Waktu',
      placeholderDateTimeMaxValue: 'Nilai Maks. Tanggal/Waktu',
      placeholderParent: 'Induk',
      placeholderShowIfParentValue: 'Tunjukkan Jika Nilai Induk',
      placeholderLogicalOperator: 'Operator Logika',
      placeholderMustUseCamera: 'Harus Menggunakan Kamera',
      placeholderSurvey: 'Survei',
      placeholderImage: 'Gambar',
      placeholderClearImage: 'Hapus Gambar',

      msgEnableConfirmation: 'Apa Anda yakin ingin mengaktifkan pilihan ini?',
      msgDisableConfirmation: 'Apa Anda yakin ingin menonaktifkan pilihan ini?',
    },
    surveyResponseScreen: {
      title: 'Respon Survei',

      labelNo: 'No',
      labelSurvey: 'Survei',
      labelUser: 'Pengguna',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelSurveyAnswers: 'Jawaban Survei',

      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderUserName: 'Nama Pengguna',
      placeholderSurvey: 'Survei',
      placeholderSurveyTitle: 'Judul Survei',
      placeholderSurveyDescription: 'Deskripsi Survei',
      placeholderUserID: 'ID Pengguna',
    },
    quizQuestionScreen: {
      title: 'Pertanyaan Kuis',

      labelNo: 'No',
      labelLabel: 'Label',
      labelFieldType: 'Tipe Field',
      labelOrder: 'Urutan',
      labelVisible: 'Terlihat',
      labelReadOnly: 'Hanya Dibaca',
      labelRequired: 'Harus Diisi',
      labelOptionValues: 'Nilai-nilai Opsi',
      labelOptionValuesHelper: 'Tulis setiap nilai dipisahkan dengan |',
      labelLogicalOperatorHelper: 'Anda bisa menulis lebih dari satu pilihan yang dipisahkan dengan |',
      labelStatus: 'Status',
      labelQuiz: 'Kuis',

      buttonCaptionCreateNewQuizQuestion: 'Buat Pertanyaan Kuis Baru',
      buttonCaptionEditQuizQuestion: 'Ubah Pertanyaan Kuis',
      buttonCaptionDeleteQuizQuestion: 'Hapus Pertanyaan Kuis',
      buttonCaptionEnable: 'Aktifkan',
      buttonCaptionDisable: 'Nonaktifkan',

      placeholderName: 'Nama',
      placeholderLabel: 'Label',
      placeholderValidationMessage: 'Pesan Validasi',
      placeholderOrder: 'Urutan',
      placeholderRequired: 'Harus Diisi',
      placeholderVisible: 'Terlihat',
      placeholderReadOnly: 'Hanya Dibaca',
      placeholderTextMultiline: 'Teks Multi baris',
      placeholderTextKeyboardType: 'Tipe Keyboard Teks',
      placeholderFieldType: 'Tipe Field',
      placeholderPickerMode: 'Mode Pemilihan',
      placeholderOptionMode: 'Mode Opsi',
      placeholderOptionValues: 'Opsi A|Opsi B|Opsi C',
      placeholderOptionValueSeparator: 'Pemisah Nilai Opsi',
      placeholderSectionId: 'ID Bagian',
      placeholderSectionLabel: 'Label Bagian',
      placeholderTextMinLength: 'Panjang Teks Min.',
      placeholderTextMaxLength: 'Panjang Teks Maks.',
      placeholderTextRegexValidation: 'Validasi Regex Teks',
      placeholderIntMinValue: 'Nilai Min. Int',
      placeholderIntMaxValue: 'Nilai Maks. Int',
      placeholderFloatMinValue: 'Nilai Min Float',
      placeholderFloatMaxValue: 'Nilai Maks.Float',
      placeholderDateTimeMinValue: 'Nilai Min. Tanggal/Waktu',
      placeholderDateTimeMaxValue: 'Nilai Maks. Tanggal/Waktu',
      placeholderLogicalOperator: 'Operator Logika',
      placeholderMustUseCamera: 'Harus Menggunakan Kamera',
      placeholderQuiz: 'Kuis',
      placeholderImage: 'Gambar',
      placeholderClearImage: 'Hapus Gambar',
      placeholderScore: 'Nilai',
      placeholderCorrectAnswer: 'Jawaban Yang Benar',

      msgEnableConfirmation: 'Apa Anda yakin ingin mengaktifkan pilihan ini?',
      msgDisableConfirmation: 'Apa Anda yakin ingin menonaktifkan pilihan ini?',
    },
  },
});

export default LocalizedString;
