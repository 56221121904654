import { SET_DOCUMENT_GENERATOR_DOCUMENTS, ADD_DOCUMENT_GENERATOR_DOCUMENT, CLEAR_DOCUMENT_GENERATOR_DOCUMENTS } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT_GENERATOR_DOCUMENTS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_DOCUMENT_GENERATOR_DOCUMENT: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_DOCUMENT_GENERATOR_DOCUMENTS:
      return initialState;
    default:
      return state;
  }
};
