import { reset } from 'redux-form';
import {
  addingEditingArticleCategory, clearArticleCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addArticleCategory, editArticleCategory } from '../../../helper';
import { RXFORM_ARTICLE_CATEGORY, PAGE_MODE_TABLE } from '../../../constant';
import downloadArticleCategoriesAsync from './downloadArticleCategoriesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingArticleCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiArticleCategory;

    if (tappedId) {
      await editArticleCategory(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addArticleCategory(name, description, token);
    }

    dispatch(reset(RXFORM_ARTICLE_CATEGORY));
    dispatch(clearArticleCategories());
    dispatch(downloadArticleCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingArticleCategory(false));
  }
};
