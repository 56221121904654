import {
  addShortlink, clearShortlinks, downloadingDeletingShortlink, setAlertErrorMessage,
  setFunctionalPageMode, setShortlinkTappedId,
} from '../simple-action';
import { downloadShortlink, deleteShortlink } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadShortlinksAsync from './downloadShortlinksAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingShortlink(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiShortlink;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteShortlink(tappedId, token);
      dispatch(setShortlinkTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearShortlinks());
      dispatch(downloadShortlinksAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const shortlink = await downloadShortlink(tappedId, token);
      dispatch(addShortlink(shortlink));
    }
  } finally {
    dispatch(downloadingDeletingShortlink(false));
  }
};
