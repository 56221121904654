import {
  REST_URL_COMPANY_MAPPINGS, REST_URL_ADD_EDIT_COMPANY_MAPPING,
  REST_URL_VIEW_DELETE_COMPANY_MAPPING, REST_URL_INFO_MAPPINGS,
  REST_URL_ADD_EDIT_INFO_MAPPING, REST_URL_VIEW_DELETE_INFO_MAPPING,
} from './constant';
import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest,
  transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadCompanyMappings = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_COMPANY_MAPPINGS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addCompanyMapping = async (
  order, phrase, mappingOperator, companyName, addressLine1, addressLine2, city, province,
  postalCode, phone, fax, webSite, companyLogoUrl, image1Url, image2Url, type, token) => {
  const body = {
    order,
    phrase,
    mappingOperator,
    companyName,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    phone,
    fax,
    webSite,
    companyLogoUrl,
    image1Url,
    image2Url,
    type,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_COMPANY_MAPPING, body, token);
};

export const editCompanyMapping = async (
  id, order, phrase, mappingOperator, companyName, addressLine1, addressLine2, city, province,
  postalCode, phone, fax, webSite, companyLogoUrl, image1Url, image2Url, type, token) => {
  const body = {
    id,
    order,
    phrase,
    mappingOperator,
    companyName,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    phone,
    fax,
    webSite,
    companyLogoUrl,
    image1Url,
    image2Url,
    type,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_COMPANY_MAPPING, body, token);
};

export const downloadCompanyMapping = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_COMPANY_MAPPING.replace(/\{id\}/, id), token);
  return response;
};

export const deleteCompanyMapping = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_COMPANY_MAPPING.replace(/\{id\}/, id), token);
};

export const downloadInfoMappings = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_INFO_MAPPINGS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addInfoMapping = async (order, phrase, mappingOperator, newName, type, token) => {
  const body = {
    order,
    phrase,
    mappingOperator,
    newName,
    type,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_INFO_MAPPING, body, token);
};

export const editInfoMapping = async (
  id, order, phrase, mappingOperator, newName, type, token) => {
  const body = {
    id,
    order,
    phrase,
    mappingOperator,
    newName,
    type,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_INFO_MAPPING, body, token);
};

export const downloadInfoMapping = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_INFO_MAPPING.replace(/\{id\}/, id), token);
  return response;
};

export const deleteInfoMapping = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_INFO_MAPPING.replace(/\{id\}/, id), token);
};
