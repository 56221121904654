import { downloadingSummaryTotalUsers, setSummaryTotalUsers } from '../simple-action';
import { downloadSummaryTotalUsers } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryTotalUsers(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryTotalUsers(token);

    dispatch(setSummaryTotalUsers(result));
  } finally {
    dispatch(downloadingSummaryTotalUsers(false));
  }
};
