import { SET_DEVICE_MODELS, CLEAR_DEVICE_MODELS } from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_DEVICE_MODELS:
      return action.data;
    case CLEAR_DEVICE_MODELS:
      return [];
    default:
      return state;
  }
};
