import { addActivity, downloadingDeletingActivity } from '../simple-action';
import { downloadActivity } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingActivity(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiActivity;

    const result = await downloadActivity(tappedId, token);
    dispatch(addActivity(result));
  } finally {
    dispatch(downloadingDeletingActivity(false));
  }
};
