import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    activityManagementMenu: {
      title: 'Activity',
    },
    industryScreen: {
      title: 'Industry',

      labelNo: 'No',
      labelName: 'Name',

      buttonCaptionCreateNewIndustry: 'Create New Industry',
      buttonCaptionEditIndustry: 'Edit Industry',
      buttonCaptionDeleteIndustry: 'Delete Industry',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
    },
    customerScreen: {
      title: 'Customer',

      labelNo: 'No',
      labelName: 'Name',
      labelIndustry: 'Industry',

      buttonCaptionCreateNewCustomer: 'Create New Customer',
      buttonCaptionEditCustomer: 'Edit Customer',
      buttonCaptionDeleteCustomer: 'Delete Customer',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderIndustry: 'Industry',
      placeholderRefId: 'Ref ID',
    },
    activityScreen: {
      title: 'History',

      labelNo: 'No',
      labelEmployee: 'Employee',
      labelCustomer: 'Customer',
      labelActivityType: 'Activity Type',
      labelClockIn: 'Start Activity',
      labelClockOut: 'End Activity',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelAttendanceDate: 'Attendance Date',
      labelDepartment: 'Department',
      labelDivision: 'Division',
      labelCompany: 'Company',

      placeholderActivityType: 'Activity Type',
      placeholderActivityDescription: 'Activity Description',
      placeholderAttendanceDate: 'Attendance Date',
      placeholderStartTime: 'Start Time',
      placeholderStartLatitude: 'Start Latitude',
      placeholderStartLongitude: 'Start Longitude',
      placeholderStartLocationId: 'Start Location ID',
      placeholderStartLocationName: 'Start Location Name',
      placeholderEndTime: 'End Time',
      placeholderEndLatitude: 'End Latitude',
      placeholderEndLongitude: 'End Longitude',
      placeholderEndLocationId: 'End Location ID',
      placeholderEndLocationName: 'End Location Name',
      placeholderEndActivityNotes: 'End Activity Notes',
      placeholderCustomer: 'Customer',
      placeholderEmployee: 'Employee',
      placeholderMap: 'Map',
      placeholderManager: 'Manager',
    },
    activityTypeScreen: {
      title: 'Activity Type',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      buttonCaptionCreateNewActivityType: 'Create New Activity Type',
      buttonCaptionEditActivityType: 'Edit Activity Type',
      buttonCaptionDeleteActivityType: 'Delete Activity Type',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
    },
  },
  id: {
    activityManagementMenu: {
      title: 'Manajemen Aktivitas',
    },
    industryScreen: {
      title: 'Industri',

      labelNo: 'No',
      labelName: 'Nama',

      buttonCaptionCreateNewIndustry: 'Buat Industri Baru',
      buttonCaptionEditIndustry: 'Ubah Industri',
      buttonCaptionDeleteIndustry: 'Hapus Industri',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
    },
    customerScreen: {
      title: 'Pelanggan',

      labelNo: 'No',
      labelName: 'Nama',
      labelIndustry: 'Industri',

      buttonCaptionCreateNewCustomer: 'Buat Pelanggan Baru',
      buttonCaptionEditCustomer: 'Ubah Pelanggan',
      buttonCaptionDeleteCustomer: 'Hapus Pelanggan',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderIndustry: 'Industri',
      placeholderRefId: 'ID Ref',
    },
    activityScreen: {
      title: 'Riwayat',

      labelNo: 'No',
      labelEmployee: 'Karyawan',
      labelCustomer: 'Pelanggan',
      labelActivityType: 'Tipe Aktivitas',
      labelClockIn: 'Mulai Aktivitas',
      labelClockOut: 'Selesai Aktivitas',
      labelStartDate: 'Tanggal Mulai',
      labelEndDate: 'Tanggal Selesai',
      labelAttendanceDate: 'Tanggal Kehadiran',
      labelDepartment: 'Departemen',
      labelDivision: 'Divisi',
      labelCompany: 'Perusahaan',

      placeholderActivityType: 'Tipe Aktivitas',
      placeholderActivityDescription: 'Deskripsi Aktivitas',
      placeholderAttendanceDate: 'Tanggal Kehadiran',
      placeholderStartTime: 'Waktu Dimulai',
      placeholderStartLatitude: 'Garis Lintang Awal',
      placeholderStartLongitude: 'Garis Bujur Awal',
      placeholderStartLocationId: 'ID Lokasi Awal',
      placeholderStartLocationName: 'Nama Lokasi Awal',
      placeholderEndTime: 'Waktu Berakhir',
      placeholderEndLatitude: 'Garis Lintang Akhir',
      placeholderEndLongitude: 'Garis Bujur Akhir',
      placeholderEndLocationId: 'ID Lokasi Akhir',
      placeholderEndLocationName: 'Nama Lokasi Akhir',
      placeholderEndActivityNotes: 'Catatan Aktivitas Akhir',
      placeholderCustomer: 'Pelanggan',
      placeholderEmployee: 'Karyawan',
      placeholderMap: 'Peta',
      placeholderManager: 'Manajer',
    },
    activityTypeScreen: {
      title: 'Tipe Aktivitas',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewActivityType: 'Buat Tipe Aktivitas Baru',
      buttonCaptionEditActivityType: 'Ubah Tipe Aktivitas',
      buttonCaptionDeleteActivityType: 'Hapus Tipe Aktivitas',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
    },
  },
});

export default LocalizedString;
