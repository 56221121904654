import { SET_BALANCE, CLEAR_BALANCE } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_BALANCE: {
      return action.data;
    }
    case CLEAR_BALANCE:
      return state;
    default:
      return state;
  }
};
