import {
  TEXT_FORMAT_HTML, REST_URL_PARTNER_MERCHANTS, REST_URL_ADD_EDIT_PARTNER_MERCHANT,
  REST_URL_VIEW_DELETE_PARTNER_MERCHANT,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendDeleteRequest, sendPostRequest, sendPutRequest,
  transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadPartnerMerchants = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PARTNER_MERCHANTS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_PARTNER_MERCHANTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addPartnerMerchant = async (description, shortName, tagLine, termsAndConditions,
  companyId, token) => {
  const body = {
    shortName,
    tagLine,
    description,
    descriptionFormat: TEXT_FORMAT_HTML,
    termsAndConditions,
    termsAndConditionsFormat: TEXT_FORMAT_HTML,
    companyId,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_PARTNER_MERCHANT, body, token);
};

export const editPartnerMerchant = async (id, description, shortName, tagLine, termsAndConditions,
  companyId, token) => {
  const body = {
    id,
    shortName,
    tagLine,
    description,
    descriptionFormat: TEXT_FORMAT_HTML,
    termsAndConditions,
    termsAndConditionsFormat: TEXT_FORMAT_HTML,
    companyId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_PARTNER_MERCHANT, body, token);
};

export const downloadPartnerMerchant = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PARTNER_MERCHANT.replace(/\{id\}/, id),
    token);
  return response;
};

export const deletePartnerMerchant = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_PARTNER_MERCHANT.replace(/\{id\}/, id), token);
};
