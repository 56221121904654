import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_PROMOTED_MERCHANTS, MENUID_VOUCHER_PROMOTED_MERCHANT, PAGE_MODE_TABLE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED,
  RXFORM_PROMOTED_MERCHANT,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData,
} from '../../helper';
import {
  clearPromotedMerchants, downloadPromotedMerchantAsync,
  downloadPromotedMerchantsAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setPromotedMerchantSearchText, setPromotedMerchantSelectedOrderBy,
  setPromotedMerchantSelectedPageSize, setPromotedMerchantTappedId,
  setAdvancedFilterDialogSelectedFilterString,
  setCityAdvancedFilterDialogSelectedFilterString,
  setCitySearchText, clearCities, setPromotedMerchantSelectedIsPromoted,
  editPromotedMerchantAsync,
} from '../../redux/action';
import PromotedMerchantPage from './promoted-merchant.presentation';
import { downloadCitiesAsync } from '../../../../redux/action';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiPromotedMerchant, promotedMerchants } = state;
  const { data } = promotedMerchants;
  const { downloadingDeleting, tappedId } = uiPromotedMerchant;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    city: found.city?.name || '',
    logo: found.logo ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.logo}` : null,
    banner: found.banner ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.banner}` : null,
    isPromoted: found.isPromoted
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
  }) : {
    name: '',
    address: '',
    city: '',
    website: '',
    playStoreUrl: '',
    appStoreUrl: '',
    isPromoted: null,
    order: 0,
    logo: '',
    banner: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPromotedMerchant.downloading,
  downloadingDeleting: state.uiPromotedMerchant.downloadingDeleting,
  addingEditing: state.uiPromotedMerchant.addingEditing,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingCity: state.uiCity.downloading,
  cities: transformDropdownData(state.cities.data),
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_PROMOTED_MERCHANT));
    dispatch(setPromotedMerchantSearchText(''));
    dispatch(clearPromotedMerchants());
    dispatch(setPromotedMerchantSelectedPageSize(20));
    dispatch(setPromotedMerchantSelectedOrderBy(INITIAL_ORDER_BY_PROMOTED_MERCHANTS));
    dispatch(downloadPromotedMerchantsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPromotedMerchantsAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPromotedMerchantSelectedPageSize(pageSize));
    dispatch(downloadPromotedMerchantsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setPromotedMerchantSelectedPageSize(pageSize));
    dispatch(clearPromotedMerchants());
    dispatch(downloadPromotedMerchantsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setPromotedMerchantSearchText(text));
    dispatch(clearPromotedMerchants());
    dispatch(downloadPromotedMerchantsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setPromotedMerchantSelectedOrderBy(orderBy));
    dispatch(clearPromotedMerchants());
    dispatch(downloadPromotedMerchantsAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setPromotedMerchantTappedId(id));
    dispatch(downloadPromotedMerchantAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangeCityText: async (text) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/city/, 'city.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPromotedMerchants());
    dispatch(downloadPromotedMerchantsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPromotedMerchants());
    dispatch(setPromotedMerchantSelectedOrderBy(INITIAL_ORDER_BY_PROMOTED_MERCHANTS));
    dispatch(downloadPromotedMerchantsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onPromotedOptionSelected: (option) => {
    if (option) {
      dispatch(setPromotedMerchantSelectedIsPromoted(option));
      dispatch(change(RXFORM_PROMOTED_MERCHANT, RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED, option));
    } else {
      dispatch(setPromotedMerchantSelectedIsPromoted(''));
      dispatch(change(RXFORM_PROMOTED_MERCHANT, RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED, ''));
    }
  },
  onSavePressed: async ({ order }) => {
    try {
      await dispatch(editPromotedMerchantAsync(order));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onEditPressed: (id) => {
    dispatch(setPromotedMerchantTappedId(id));
    dispatch(downloadPromotedMerchantAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setPromotedMerchantSelectedIsPromoted(null));
    dispatch(reset(RXFORM_PROMOTED_MERCHANT));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotedMerchantPage);
