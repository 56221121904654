import { reset } from 'redux-form';
import {
  addingEditingSurvey, clearSurveys, setAlertErrorMessage, setFunctionalPageMode,
  setSurveySelectedCategoryId,
  setSurveySelectedVisibilityStatus,
} from '../simple-action';
import { addSurvey, editSurvey } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_SURVEY } from '../../../constant';
import downloadSurveysAsync from './downloadSurveysAsync';

export default (title, description, validFrom, validUntil) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSurvey(true));

    const { token } = getState().authentication;
    const { tappedId, selectedVisibilityStatus, selectedCategoryId } = getState().uiSurvey;

    if (tappedId) {
      await editSurvey(
        tappedId, title, description, validFrom, validUntil,
        selectedVisibilityStatus, selectedCategoryId, token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSurvey(
        title, description, validFrom, validUntil,
        selectedVisibilityStatus, selectedCategoryId, token,
      );
    }

    dispatch(reset(RXFORM_SURVEY));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setSurveySelectedCategoryId(''));
    dispatch(setSurveySelectedVisibilityStatus(''));
  } finally {
    dispatch(addingEditingSurvey(false));
  }
};
