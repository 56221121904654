import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformQueryResult, sendGetFileRequest,
} from '../../helper';
import {
  REST_URL_ADD_EDIT_SURVEY_CATEGORY, REST_URL_VIEW_DELETE_SURVEY_CATEGORY,
  REST_URL_SURVEY_CATEGORIES, REST_URL_ADD_EDIT_QUIZ_CATEGORY,
  REST_URL_VIEW_DELETE_QUIZ_CATEGORY, REST_URL_QUIZ_CATEGORIES,
  REST_URL_SURVEYS, REST_URL_VIEW_DELETE_SURVEY, REST_URL_ADD_EDIT_SURVEY,
  REST_URL_ADD_EDIT_QUIZ, REST_URL_QUIZES, REST_URL_VIEW_DELETE_QUIZ,
  REST_URL_SURVEY_QUESTIONS, REST_URL_ADD_EDIT_SURVEY_QUESTION,
  REST_URL_VIEW_DELETE_SURVEY_QUESTION, REST_URL_ENABLE_DISABLE_SURVEY_QUESTION,
  REST_URL_SURVEY_RESPONSES, REST_URL_VIEW_SURVEY_RESPONSE, REST_URL_SAVE_SURVEY_RESULT,
  REST_URL_QUIZ_QUESTIONS, REST_URL_ADD_EDIT_QUIZ_QUESTION, REST_URL_VIEW_DELETE_QUIZ_QUESTION,
  REST_URL_ENABLE_DISABLE_QUIZ_QUESTION, REST_URL_QUIZ_SURVEY_RESULT,
  REST_URL_QUIZ_RESPONSES, REST_URL_VIEW_DELETE_QUIZ_RESPONSE,
  REST_URL_SEND_QUIZ_TO_ME, REST_URL_SEND_SURVEY_TO_ME,
} from './constant';

export * from '../../helper';

export const downloadSurveyCategories = async (
  pageNumber, pageSize, orderBy, searchText, token,
) => {
  const url = buildQueryParams(
    REST_URL_SURVEY_CATEGORIES, pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addSurveyCategory = async (name, description, token) => {
  const body = {
    name,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SURVEY_CATEGORY, body, token);
};

export const editSurveyCategory = async (id, name, description, token) => {
  const body = {
    id,
    name,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SURVEY_CATEGORY, body, token);
};

export const downloadSurveyCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SURVEY_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSurveyCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SURVEY_CATEGORY.replace(/\{id\}/, id), token);
};


export const downloadQuizCategories = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_QUIZ_CATEGORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addQuizCategory = async (name, description, token) => {
  const body = {
    name,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_QUIZ_CATEGORY, body, token);
};

export const editQuizCategory = async (id, name, description, token) => {
  const body = {
    id,
    name,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_QUIZ_CATEGORY, body, token);
};

export const downloadQuizCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_QUIZ_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteQuizCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_QUIZ_CATEGORY.replace(/\{id\}/, id), token);
};

export const downloadSurveys = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(REST_URL_SURVEYS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSurvey = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SURVEY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSurvey = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SURVEY.replace(/\{id\}/, id), token);
};

export const addSurvey = async (
  title, description, validFrom, validUntil, visibilityStatus, categoryId, token,
) => {
  const body = {
    title,
    description,
    validFrom,
    validUntil,
    visibilityStatus,
    categoryId,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SURVEY, body, token);
};

export const editSurvey = async (
  id, title, description, validFrom, validUntil, visibilityStatus, categoryId, token,
) => {
  const body = {
    id,
    title,
    description,
    validFrom,
    validUntil,
    visibilityStatus,
    categoryId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SURVEY, body, token);
};

export const downloadQuizes = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = filterString
    ? buildQueryParams(REST_URL_QUIZES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_QUIZES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addQuiz = async (
  title, description, validFrom, validUntil,
  durationMinutes, categoryId, visibilityStatus, token,
) => {
  const body = {
    title,
    description,
    validFrom,
    validUntil,
    durationMinutes,
    categoryId,
    visibilityStatus,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_QUIZ, body, token);
};

export const editQuiz = async (
  id, title, description, validFrom, validUntil,
  durationMinutes, categoryId, visibilityStatus, token,
) => {
  const body = {
    id,
    title,
    description,
    validFrom,
    validUntil,
    durationMinutes,
    categoryId,
    visibilityStatus,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_QUIZ, body, token);
};

export const downloadQuiz = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_QUIZ.replace(/\{id\}/, id), token);
  return response;
};

export const deleteQuiz = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_QUIZ.replace(/\{id\}/, id), token);
};

export const downloadSurveyQuestions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SURVEY_QUESTIONS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_SURVEY_QUESTIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addSurveyQuestion = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_SURVEY_QUESTION, body, token);
};

export const editSurveyQuestion = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_SURVEY_QUESTION, body, token);
};

export const downloadSurveyQuestion = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SURVEY_QUESTION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSurveyQuestion = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SURVEY_QUESTION.replace(/\{id\}/, id), token);
};

export const enableDisableSurveyQuestion = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_SURVEY_QUESTION, body, token);
};

export const downloadSurveyResponses = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SURVEY_RESPONSES.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_SURVEY_RESPONSES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadSurveyResponse = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_SURVEY_RESPONSE.replace(/\{id\}/, id), token);
  return response;
};

export const downloadQuizQuestions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_QUIZ_QUESTIONS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_QUIZ_QUESTIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addQuizQuestion = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_QUIZ_QUESTION, body, token);
};

export const editQuizQuestion = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_QUIZ_QUESTION, body, token);
};

export const downloadQuizQuestion = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_QUIZ_QUESTION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteQuizQuestion = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_QUIZ_QUESTION.replace(/\{id\}/, id), token);
};

export const enableDisableQuizQuestion = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_QUIZ_QUESTION, body, token);
};

export const downloadQuizResponses = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_QUIZ_RESPONSES.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_QUIZ_RESPONSES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadQuizResponse = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_QUIZ_RESPONSE.replace(/\{id\}/, id), token);
  return response;
};

export const saveSurveyResult = async (id, token) => {
  const url = REST_URL_SAVE_SURVEY_RESULT.replace(/\{id\}/, id);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const saveQuizResult = async (id, token) => {
  const url = REST_URL_QUIZ_SURVEY_RESULT.replace(/\{id\}/, id);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const sendQuizNotification = async (id, token) => {
  await sendPostRequest(REST_URL_SEND_QUIZ_TO_ME.replace(/\{id\}/, id), null, token);
};

export const sendSurveyNotification = async (id, token) => {
  await sendPostRequest(REST_URL_SEND_SURVEY_TO_ME.replace(/\{id\}/, id), null, token);
};
