import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  CircularProgress, FormControl, FormHelperText, makeStyles, Typography,
} from '@material-ui/core';
import { COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_PRIMARY } from '../constant';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
  },
  form: {
    marginLeft: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  editorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch !important',
    height: '100%',
  },
  editor: {
    border: `1px solid ${COLOR_BODY_TEXT}`,
    borderRadius: 2,
    padding: 5,
    height: '100%',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const RichTextEditorField = ({
  disabled, error, hidden, loading,
  onChangeText,
  label, helperText, value,
  editorStyle,
  ...props
}) => {
  const classes = useStyles();
  const [editor, setEditor] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!loading && value) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditor(editorState);
      }
    }
    // eslint-disable-next-line
  }, [loading]);

  const onChange = (state) => {
    const currContent = convertToRaw(state.getCurrentContent());
    const { blocks } = currContent;
    setEditor(state);

    if (blocks.every((x) => x.type !== 'atomic')) {
      if (blocks.some((x) => x.text)) {
        onChangeText(draftToHtml(currContent));
      }
      if (blocks.every((x) => !x.text) && value) {
        onChangeText('');
      }
    }
    if (blocks.some((x) => x.type === 'atomic')) {
      onChangeText(draftToHtml(currContent));
    }
  };

  if (loading) {
    return (
      <CircularProgress color="inherit" />
    );
  }
  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          <Typography>{label}</Typography>
          <Editor
            editorState={editor}
            wrapperClassName={classes.editorWrapper}
            editorClassName={`${classes.editor} ${editorStyle}`}
            onEditorStateChange={onChange}
            readOnly={disabled}
            {...props}
          />
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default RichTextEditorField;

RichTextEditorField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  onChangeText: PropTypes.func,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  value: PropTypes.string,
  editorStyle: PropTypes.string,
};

RichTextEditorField.defaultProps = {
  disabled: false,
  error: false,
  hidden: false,
  loading: false,
  onChangeText: () => {},
  helperText: null,
  value: null,
  editorStyle: null,
};
