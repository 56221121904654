import { downloadVoucher, transformApplicableBranches } from '../../../helper';
import {
  addVoucher, downloadingDeletingVoucher, setVoucherTableDataApplicableBranches,
} from '../simple-action';
import { getDeepLinkScheme } from '../../../../configuration/helper';

const DEEP_LINK_PATH = 'app/voucher/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingVoucher(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoucher;

    const voucher = await downloadVoucher(tappedId, token);

    const result = await getDeepLinkScheme(voucher, DEEP_LINK_PATH, tappedId, token);

    dispatch(addVoucher(result));

    dispatch(setVoucherTableDataApplicableBranches(transformApplicableBranches(
      result.applicableBranches,
    )));
  } finally {
    dispatch(downloadingDeletingVoucher(false));
  }
};
