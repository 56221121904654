import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_FORUM_CATEGORIES = 'DOWNLOADING_FORUM_CATEGORIES';
export const downloadingForumCategories = makeActionCreator(DOWNLOADING_FORUM_CATEGORIES, 'status');

export const SET_FORUM_CATEGORIES = 'SET_FORUM_CATEGORIES';
export const setForumCategories = makeActionCreator(SET_FORUM_CATEGORIES, 'data');

export const ADD_FORUM_CATEGORY = 'ADD_FORUM_CATEGORY';
export const addForumCategory = makeActionCreator(ADD_FORUM_CATEGORY, 'data');

export const CLEAR_FORUM_CATEGORIES = 'CLEAR_FORUM_CATEGORIES';
export const clearForumCategories = makeActionCreator(CLEAR_FORUM_CATEGORIES);

export const ADDING_EDITING_FORUM_CATEGORY = 'ADDING_EDITING_FORUM_CATEGORY';
export const addingEditingForumCategory = makeActionCreator(ADDING_EDITING_FORUM_CATEGORY, 'status');

export const DOWNLOADING_DELETING_FORUM_CATEGORY = 'DOWNLOADING_DELETING_FORUM_CATEGORY';
export const downloadingDeletingForumCategory = makeActionCreator(DOWNLOADING_DELETING_FORUM_CATEGORY, 'status');


export const SET_FORUM_CATEGORY_SELECTED_PAGE_SIZE = 'SET_FORUM_CATEGORY_SELECTED_PAGE_SIZE';
export const setForumCategorySelectedPageSize = makeActionCreator(SET_FORUM_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_FORUM_CATEGORY_SELECTED_ORDER_BY = 'SET_FORUM_CATEGORY_SELECTED_ORDER_BY';
export const setForumCategorySelectedOrderBy = makeActionCreator(SET_FORUM_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_FORUM_CATEGORY_TAPPED_ID = 'SET_FORUM_CATEGORY_TAPPED_ID';
export const setForumCategoryTappedId = makeActionCreator(SET_FORUM_CATEGORY_TAPPED_ID, 'id');

export const SET_FORUM_CATEGORY_SEARCH_TEXT = 'SET_FORUM_CATEGORY_SEARCH_TEXT';
export const setForumCategorySearchText = makeActionCreator(SET_FORUM_CATEGORY_SEARCH_TEXT, 'text');

export const SET_FORUM_CATEGORY_SELECTED_PARENT_CATEGORY = 'SET_FORUM_CATEGORY_SELECTED_PARENT_CATEGORY';
export const setForumCategorySelectedParentCategory = makeActionCreator(SET_FORUM_CATEGORY_SELECTED_PARENT_CATEGORY, 'option');

export const SET_FORUM_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_FORUM_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setForumCategoryAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_FORUM_CATEGORY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_FORUM_PARENT_CATEGORIES = 'DOWNLOADING_FORUM_PARENT_CATEGORIES';
export const downloadingForumParentCategories = makeActionCreator(DOWNLOADING_FORUM_PARENT_CATEGORIES, 'status');

export const SET_FORUM_PARENT_CATEGORIES = 'SET_FORUM_PARENT_CATEGORIES';
export const setForumParentCategories = makeActionCreator(SET_FORUM_PARENT_CATEGORIES, 'data');

export const CLEAR_FORUM_PARENT_CATEGORIES = 'CLEAR_FORUM_PARENT_CATEGORIES';
export const clearForumParentCategories = makeActionCreator(CLEAR_FORUM_PARENT_CATEGORIES);

export const SET_FORUM_PARENT_CATEGORY_SEARCH_TEXT = 'SET_FORUM_PARENT_CATEGORY_SEARCH_TEXT';
export const setForumParentCategorySearchText = makeActionCreator(SET_FORUM_PARENT_CATEGORY_SEARCH_TEXT, 'text');


export const DOWNLOADING_FORUM_POSTS = 'DOWNLOADING_FORUM_POSTS';
export const downloadingForumPosts = makeActionCreator(DOWNLOADING_FORUM_POSTS, 'status');

export const SET_FORUM_POSTS = 'SET_FORUM_POSTS';
export const setForumPosts = makeActionCreator(SET_FORUM_POSTS, 'data');

export const ADD_FORUM_POST = 'ADD_FORUM_POST';
export const addForumPost = makeActionCreator(ADD_FORUM_POST, 'data');

export const CLEAR_FORUM_POSTS = 'CLEAR_FORUM_POSTS';
export const clearForumPosts = makeActionCreator(CLEAR_FORUM_POSTS);

export const ADDING_EDITING_FORUM_POST = 'ADDING_EDITING_FORUM_POST';
export const addingEditingForumPost = makeActionCreator(ADDING_EDITING_FORUM_POST, 'status');

export const DOWNLOADING_DELETING_FORUM_POST = 'DOWNLOADING_DELETING_FORUM_POST';
export const downloadingDeletingForumPost = makeActionCreator(DOWNLOADING_DELETING_FORUM_POST, 'status');


export const SET_FORUM_POST_SELECTED_PAGE_SIZE = 'SET_FORUM_POST_SELECTED_PAGE_SIZE';
export const setForumPostSelectedPageSize = makeActionCreator(SET_FORUM_POST_SELECTED_PAGE_SIZE, 'size');

export const SET_FORUM_POST_SELECTED_ORDER_BY = 'SET_FORUM_POST_SELECTED_ORDER_BY';
export const setForumPostSelectedOrderBy = makeActionCreator(SET_FORUM_POST_SELECTED_ORDER_BY, 'order');

export const SET_FORUM_POST_TAPPED_ID = 'SET_FORUM_POST_TAPPED_ID';
export const setForumPostTappedId = makeActionCreator(SET_FORUM_POST_TAPPED_ID, 'id');

export const SET_FORUM_POST_SEARCH_TEXT = 'SET_FORUM_POST_SEARCH_TEXT';
export const setForumPostSearchText = makeActionCreator(SET_FORUM_POST_SEARCH_TEXT, 'text');

export const SET_FORUM_POST_SELECTED_ADD_MEDIA_MENU = 'SET_FORUM_POST_SELECTED_ADD_MEDIA_MENU';
export const setForumPostSelectedAddMediaMenu = makeActionCreator(SET_FORUM_POST_SELECTED_ADD_MEDIA_MENU, 'text');

export const SET_FORUM_POST_SELECTED_CATEGORY = 'SET_FORUM_POST_SELECTED_CATEGORY';
export const setForumPostSelectedCategory = makeActionCreator(SET_FORUM_POST_SELECTED_CATEGORY, 'option');

export const SET_FORUM_POST_SELECTED_LOCKED = 'SET_FORUM_POST_SELECTED_LOCKED';
export const setForumPostSelectedLocked = makeActionCreator(SET_FORUM_POST_SELECTED_LOCKED, 'option');

export const SET_FORUM_POST_SELECTED_PINNED = 'SET_FORUM_POST_SELECTED_PINNED';
export const setForumPostSelectedPinned = makeActionCreator(SET_FORUM_POST_SELECTED_PINNED, 'option');

export const SET_FORUM_POST_SELECTED_CONTENT_FORMAT = 'SET_FORUM_POST_SELECTED_CONTENT_FORMAT';
export const setForumPostSelectedContentFormat = makeActionCreator(SET_FORUM_POST_SELECTED_CONTENT_FORMAT, 'option');
