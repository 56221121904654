import { reset } from 'redux-form';
import {
  addingEditingFAQ, clearFAQs, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addFAQ, editFAQ } from '../../../helper';
import { RXFORM_FAQ, PAGE_MODE_TABLE } from '../../../constant';
import downloadFAQsAsync from './downloadFAQsAsync';

export default (
  question, shortAnswer, detailedAnswer, order, visibilityStatus,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingFAQ(true));

    const { token } = getState().authentication;
    const { tappedId, selectedCategoryId } = getState().uiFAQ;
    const { data } = getState().faqs;
    const found = data[tappedId];
    const category = selectedCategoryId || found.category.id;
    if (tappedId) {
      await editFAQ(
        tappedId, question, shortAnswer, detailedAnswer, order, visibilityStatus,
        category, token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addFAQ(
        question, shortAnswer, detailedAnswer, order, visibilityStatus, category, token,
      );
    }

    dispatch(reset(RXFORM_FAQ));
    dispatch(clearFAQs());
    dispatch(downloadFAQsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingFAQ(false));
  }
};
