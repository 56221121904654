import { getAnalyticsPageTitleAndScreenNamesData } from '../../../helper';
import {
  downloadingAnalyticsPageTitleAndScreenNames,
  setAnalyticsPageTitleAndScreenNames,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsPageTitleAndScreenNames(true));

    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { pageTitleAndScreenNameDateRange } = uiAnalytics;
    const { token } = authentication;

    const requestBody = {
      dateRanges: [{
        startDate: pageTitleAndScreenNameDateRange.from,
        endDate: pageTitleAndScreenNameDateRange.to,
      }],
      dimensions: [{ name: 'pageTitle' }, { name: 'unifiedScreenName' }],
      metrics: [
        { name: 'activeUsers' },
        { name: 'screenPageViews' },
        { name: 'screenPageViewsPerUser' },
        { name: 'userEngagementDuration' },
      ],
      metricAggregations: ['TOTAL'],
    };

    const response = await getAnalyticsPageTitleAndScreenNamesData(
      myConfigItems?.core?.googleAnalyticsPropertyId, requestBody, token,
    );

    dispatch(setAnalyticsPageTitleAndScreenNames(response));
  } finally {
    dispatch(downloadingAnalyticsPageTitleAndScreenNames(false));
  }
};
