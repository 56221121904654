import { downloadingDeviceModels, setDeviceModels } from '../simple-action';
import { downloadDeviceModels, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { deviceModelSearchText } = getState().uiToken;

  try {
    dispatch(downloadingDeviceModels(true));

    const list = await downloadDeviceModels(
      transformSearchText(deviceModelSearchText),
      token,
    );

    if (list) {
      dispatch(setDeviceModels(list));
    }
  } finally {
    dispatch(downloadingDeviceModels(false));
  }
};
