import {
  addSurveyCategory, clearSurveyCategories, downloadingDeletingSurveyCategory, setAlertErrorMessage,
  setFunctionalPageMode, setSurveyCategoryTappedId,
} from '../simple-action';
import { downloadSurveyCategory, deleteSurveyCategory } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadSurveyCategoriesAsync from './downloadSurveyCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingSurveyCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSurveyCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteSurveyCategory(tappedId, token);
      dispatch(setSurveyCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearSurveyCategories());
      dispatch(downloadSurveyCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const shortlink = await downloadSurveyCategory(tappedId, token);
      dispatch(addSurveyCategory(shortlink));
    }
  } finally {
    dispatch(downloadingDeletingSurveyCategory(false));
  }
};
