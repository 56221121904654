import { downloadingNewsTrend, setNewsTrend, clearNewsTrend } from '../simple-action';
import { downloadNewsTrend, getStartEndTime } from '../../../helper';

export default (id) => async (dispatch, getState) => {
  const { authentication, uiNews } = getState();
  const { token } = authentication;
  const { newsTrendPeriode } = uiNews;

  try {
    dispatch(downloadingNewsTrend(true));
    const startEndTime = getStartEndTime(newsTrendPeriode);
    const response = await downloadNewsTrend(
      id, startEndTime, newsTrendPeriode, token,
    );
    dispatch(clearNewsTrend());
    dispatch(setNewsTrend(response));
  } finally {
    dispatch(downloadingNewsTrend(false));
  }
};
