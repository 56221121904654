import { reset } from 'redux-form';
import {
  addingEditingPickupLocation, clearPickupLocations, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addPickupLocation, editPickupLocation } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_PICKUP_LOCATION } from '../../../constant';
import downloadPickupLocationsAsync from './downloadPickupLocationsAsync';

export default (name, description, keepPacketForHours) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPickupLocation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPickupLocation;

    if (tappedId) {
      await editPickupLocation(tappedId, name, description, keepPacketForHours, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addPickupLocation(name, description, keepPacketForHours, token);
    }

    dispatch(reset(RXFORM_PICKUP_LOCATION));
    dispatch(clearPickupLocations());
    dispatch(downloadPickupLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingPickupLocation(false));
  }
};
