import { SET_AUDIT_TRAILS, ADD_AUDIT_TRAIL, CLEAR_AUDIT_TRAILS } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIT_TRAILS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_AUDIT_TRAIL: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_AUDIT_TRAILS:
      return initialState;
    default:
      return state;
  }
};
