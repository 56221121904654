import {
  DOWNLOADING_SUBDISTRICTS, ADDING_EDITING_SUBDISTRICT, DOWNLOADING_DELETING_SUBDISTRICT,
  SET_SUBDISTRICT_SELECTED_PAGE_SIZE, SET_SUBDISTRICT_TAPPED_ID, SET_SUBDISTRICT_SEARCH_TEXT,
  SET_SUBDISTRICT_SELECTED_ORDER_BY, SET_SUBDISTRICT_SELECTED_DISTRICT_ID, SAVING_SUBDISTRICTS,
  SET_SUBDISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, ENABLING_DISABLING_SUBDISTRICT,
  RESYNCING_SUBDISTRICT,
} from '../action';
import { INITIAL_ORDER_BY_SUBDISTRICTS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  resyncing: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedDistrictId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SUBDISTRICTS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SUBDISTRICTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_SUBDISTRICT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_SUBDISTRICT:
      return { ...state, downloadingDeleting: action.status };
    case SET_SUBDISTRICT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SUBDISTRICT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SUBDISTRICT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SUBDISTRICT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_SUBDISTRICT_SELECTED_DISTRICT_ID:
      return { ...state, selectedDistrictId: action.id };
    case SAVING_SUBDISTRICTS:
      return { ...state, saving: action.status };
    case SET_SUBDISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_SUBDISTRICT:
      return { ...state, enablingDisabling: action.status };
    case RESYNCING_SUBDISTRICT:
      return { ...state, resyncing: action.status };
    default: return state;
  }
};
