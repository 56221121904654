import {
  addAdditionalField, clearAdditionalFields, downloadingDeletingAdditionalField,
  setAlertErrorMessage, setFunctionalPageMode, setAdditionalFieldTappedId,
  setAdditionalFieldSelectedFieldType, setAdditionalFieldSelectedParent,
} from '../simple-action';
import { downloadAdditionalField, deleteAdditionalField } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadAdditionalFieldsAsync from './downloadAdditionalFieldsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingAdditionalField(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAdditionalField;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteAdditionalField(tappedId, token);
      dispatch(setAdditionalFieldTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearAdditionalFields());
      dispatch(downloadAdditionalFieldsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const additionalField = await downloadAdditionalField(tappedId, token);
      const { fieldType, parent } = additionalField;
      dispatch(setAdditionalFieldSelectedFieldType(fieldType));
      dispatch(setAdditionalFieldSelectedParent(parent ? { label: parent.name, value: parent.id } : ''));
      dispatch(addAdditionalField(additionalField));
    }
  } finally {
    dispatch(downloadingDeletingAdditionalField(false));
  }
};
