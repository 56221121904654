import uuid from 'uuid';
import { reset } from 'redux-form';
import {
  PDF_TYPE_IMAGE, PDF_TYPE_QR_CODE, PDF_TYPE_TEXT, RXFORM_ANNOTATION_DESIGNER,
} from '../../../constant';
import {
  addingEditingAnnotationDesign,
  resetColorPicker,
  setAnnotationDesignTappedAnnotationId,
  setAnnotationDesigns,
  setAnnotationDesignSelectedImage,
  setCreateAnnotationDialogPdfType,
  setCreateAnnotationDialogVisibility,
  setSelectedFont,
} from '../simple-action';

const getFontStyle = (fontStyle) => {
  if (fontStyle) {
    const bold = fontStyle.includes('Bold') || false;
    const italic = fontStyle.includes('Italic') || false;
    return ({
      isBold: bold,
      isItalic: italic,
    });
  }
  return {
    isBold: false,
    isItalic: false,
  };
};

const convertStringToNumeric = (value) => parseInt(value, 10);

const renderPayload = (submitValue, pdfType, tappedId, hex, imageFileName) => {
  switch (pdfType) {
    case PDF_TYPE_TEXT:
      return {
        type: pdfType,
        value: submitValue.value,
        fontSize: convertStringToNumeric(submitValue.fontSize),
        fontName: submitValue.fontName,
        fontColor: hex,
        page: convertStringToNumeric(submitValue.page),
        x: convertStringToNumeric(submitValue.x),
        y: convertStringToNumeric(submitValue.y),
        rotation: submitValue.rotation,
        id: tappedId || uuid(),
        isBold: getFontStyle(submitValue?.fontStyle).isBold,
        isItalic: getFontStyle(submitValue?.fontStyle).isItalic,
      };
    case PDF_TYPE_QR_CODE:
      return {
        type: pdfType,
        value: submitValue.value,
        page: convertStringToNumeric(submitValue.page),
        x: convertStringToNumeric(submitValue.x),
        y: convertStringToNumeric(submitValue.y),
        width: convertStringToNumeric(submitValue.width),
        height: convertStringToNumeric(submitValue.height),
        color: hex,
        id: tappedId || uuid(),
      };
    case PDF_TYPE_IMAGE:
      return {
        type: pdfType,
        page: convertStringToNumeric(submitValue.page),
        image: imageFileName,
        x: convertStringToNumeric(submitValue.x),
        y: convertStringToNumeric(submitValue.y),
        width: convertStringToNumeric(submitValue.width),
        height: convertStringToNumeric(submitValue.height),
        value: submitValue.imageValue,
        id: tappedId || uuid(),
      };

    default: return {};
  }
};

export default (
  submitValue, pdfType, imageFileName,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingAnnotationDesign(true));

    const { hex } = getState().uiColorPickerField;
    const { tappedAnnotationId } = getState().uiAnnotationDesign;
    const { data } = getState().annotationDesigns;

    const payload = renderPayload(submitValue, pdfType, tappedAnnotationId, hex, imageFileName);
    const addArray = data.findIndex((x) => x.id === payload.id) === -1 ? [...data, payload] : data;
    const newArray = addArray ? addArray.map((x) => {
      if (x.id === tappedAnnotationId) {
        return payload;
      }

      return x;
    }) : [];

    dispatch(setAnnotationDesigns(newArray));

    dispatch(reset(RXFORM_ANNOTATION_DESIGNER));
    dispatch(setAnnotationDesignSelectedImage('', ''));
    dispatch(setAnnotationDesignTappedAnnotationId(''));
    dispatch(setCreateAnnotationDialogVisibility(false, '', ''));
    dispatch(setCreateAnnotationDialogPdfType(''));
    dispatch(setSelectedFont(''));
    dispatch(resetColorPicker());
  } finally {
    dispatch(addingEditingAnnotationDesign(false));
  }
};
