import { downloadingTotalPersonCount, setTotalPersonCount } from '../simple-action';
import { downloadProfileTotalPersonCount } from '../../../helper';
import {
  FILTERED_RECIPIENTS_BRANCH_TAG, FILTERED_RECIPIENTS_COMPANIES_TAG,
  FILTERED_RECIPIENTS_DIVISIONS_TAG, FILTERED_RECIPIENTS_DEPARTMENTS_TAG,
} from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingTotalPersonCount(true));

    const { token } = getState().authentication;
    const {
      selectedBranch, selectedCompany, selectedDepartment, selectedDivision,
    } = getState().notification;

    const branchesTag = selectedBranch
      ? `${FILTERED_RECIPIENTS_BRANCH_TAG}${selectedBranch}` : '';
    const companiesTag = selectedCompany
      ? `${FILTERED_RECIPIENTS_COMPANIES_TAG}${selectedCompany}` : '';
    const divisionsTag = selectedDivision
      ? `${FILTERED_RECIPIENTS_DIVISIONS_TAG}${selectedDivision}` : '';
    const departmentsTag = selectedDepartment
      ? `${FILTERED_RECIPIENTS_DEPARTMENTS_TAG}${selectedDepartment}` : '';

    const filterString = `${branchesTag}${companiesTag}${divisionsTag}${departmentsTag}`;

    const response = await downloadProfileTotalPersonCount(filterString, token);

    dispatch(setTotalPersonCount(response.totalCount));
  } finally {
    dispatch(downloadingTotalPersonCount(false));
  }
};
