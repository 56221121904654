import { reset } from 'redux-form';
import {
  addingEditingRole, clearRoles, setAlertErrorMessage,
  setFunctionalPageMode, setRoleSelectedPermission,
} from '../simple-action';
import { addRole, editRole } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_ROLE } from '../../../constant';
import downloadRolesAsync from './downloadRolesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingRole(true));

    const { token } = getState().authentication;
    const { tappedId, selectedPermission } = getState().uiRole;

    if (tappedId) {
      await editRole(tappedId, name, selectedPermission, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addRole(name, selectedPermission, description, token);
    }

    dispatch(reset(RXFORM_ROLE));
    dispatch(clearRoles());
    dispatch(downloadRolesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setRoleSelectedPermission(''));
  } finally {
    dispatch(addingEditingRole(false));
  }
};
