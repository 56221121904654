import { reset } from 'redux-form';
import {
  addingEditingSurveyCategory, clearSurveyCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addSurveyCategory, editSurveyCategory } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_SURVEY_CATEGORY } from '../../../constant';
import downloadSurveyCategoriesAsync from './downloadSurveyCategoriesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingSurveyCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSurveyCategory;

    if (tappedId) {
      await editSurveyCategory(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addSurveyCategory(name, description, token);
    }

    dispatch(reset(RXFORM_SURVEY_CATEGORY));
    dispatch(clearSurveyCategories());
    dispatch(downloadSurveyCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingSurveyCategory(false));
  }
};
