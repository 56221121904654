import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Paper, makeStyles, IconButton,
  withStyles, Typography,
} from '@material-ui/core';
import {
  Chart, Title, ValueAxis, Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import {
  Animation, EventTracker, HoverState, ArgumentAxis,
} from '@devexpress/dx-react-chart';
import { Tune } from '@material-ui/icons';
import classNames from 'clsx';
import { toMoment, toCurrency } from '../helper';
import { COLOR_PRIMARY, SIMPLE_DATE_FORMAT } from '../constant';
import { H3 } from '../../../component/labels';
import LocalizedString from '../localization';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    height: 550,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    border: '.5px solid #dadce0',
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
  },
  container: {
    border: '.5px solid #dadce0',
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
  },
  title: {
    fontSize: '24px',
    position: 'relative',
    left: '45px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 10px 20px 0px',
  },
  mb10: {
    marginBottom: 10,
  },
  mbl30: {
    marginBottom: 30,
    marginLeft: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  tableHeader: {
    textTransform: 'uppercase',
    borderBottomStyle: 'dashed',
    fontWeight: 500,
  },
  w500: {
    fontWeight: '500',
  },
}));

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return (Target) => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const renderTitle = (title, useFilterButton, onFilterIconPressed, classes) => (useFilterButton
  ? (
    <div className={classes.titleContainer}>
      <div />
      <H3 className={classes.title}>
        {title}
      </H3>
      <IconButton edge="end" onClick={onFilterIconPressed}>
        <Tune style={{ fill: COLOR_PRIMARY }} />
      </IconButton>
    </div>
  )
  : (
    <Title style={{ fontSize: 10 }} text={title} />
  ));

const renderTotal = (data, totalTitle, totalValue, classes) => (
  <div className={classes.mbl30}>
    <div className={`${classes.row} ${classes.spaceBetween} ${classes.mb10}`}>
      <div style={{ flex: 2 }}>
        <span className={classes.tableHeader}>
          {totalTitle}
        </span>
      </div>
    </div>
    <div className={`${classes.row} ${classes.spaceBetween}`}>
      <Typography
        variant="h4"
        className={classes.w500}
        style={{ flex: 2 }}
      >
        {totalValue}
      </Typography>
    </div>
  </div>
);

const TooltipTitle = setStyle({
  fontWeight: 'bold',
  paddingBottom: 0,
})(Tooltip.Content);

const TooltipBody = setStyle({
  paddingTop: 0,
})(Tooltip.Content);

const TooltipContent = (data, {
  text, style, targetItem, ...props
}) => {
  const items = () => {
    const val = data[targetItem.point];
    const date = toMoment()
      .subtract(30, 'days')
      .add(Number(val.day), 'days').format(SIMPLE_DATE_FORMAT);
    return (
      <div>
        <div>
          <TooltipTitle
            text={date}
            {...props}
          />
        </div>
        <div>
          <TooltipBody
            text={`${LocalizedString.analyticsScreen.labelActiveUsers}: ${toCurrency(val.total)}`}
            {...props}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      {items()}
    </div>
  );
};


const AnalyticsBarChart = ({
  data, loading, title, children, totalTitle,
  useFilterButton, onFilterIconPressed, totalValue,
}) => {
  const classes = useStyles();
  const [target, setTarget] = useState(null);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
      </div>
    );
  }
  return (
    <Paper className={classes.container}>
      <Chart data={data}>
        <ArgumentAxis />
        <ValueAxis />
        {children}
        {renderTitle(title, useFilterButton, onFilterIconPressed, classes)}
        {renderTotal(data, totalTitle, totalValue, classes)}
        <EventTracker />
        <HoverState />
        <Tooltip
          targetItem={target}
          onTargetItemChange={(t) => setTarget(t)}
          contentComponent={({
            text, style, targetItem, props,
          }) => TooltipContent(data, {
            text, style, targetItem, props,
          })}
        />
        <Animation />
      </Chart>
    </Paper>
  );
};

export default AnalyticsBarChart;

AnalyticsBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  loading: PropTypes.bool,
  useFilterButton: PropTypes.bool,

  title: PropTypes.string.isRequired,
  totalTitle: PropTypes.string.isRequired,
  totalValue: PropTypes.string.isRequired,

  children: PropTypes.node.isRequired,

  onFilterIconPressed: PropTypes.func,
};

AnalyticsBarChart.defaultProps = {
  loading: false,
  useFilterButton: false,
  onFilterIconPressed: () => {},
};
