import { downloadDiscussionComment } from '../../../helper';
import { addDiscussionComment, downloadingDiscussionComments } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDiscussionComments(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiDiscussionComment;

    const result = await downloadDiscussionComment(tappedId, token);
    dispatch(addDiscussionComment(result));
  } finally {
    dispatch(downloadingDiscussionComments(false));
  }
};
