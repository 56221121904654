import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';


export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');

export const DOWNLOADING_JOBS = 'DOWNLOADING_JOBS';
export const downloadingJobs = makeActionCreator(DOWNLOADING_JOBS, 'status');

export const SET_JOBS = 'SET_JOBS';
export const setJobs = makeActionCreator(SET_JOBS, 'data');

export const ADD_JOB = 'ADD_JOB';
export const addJob = makeActionCreator(ADD_JOB, 'data');

export const CLEAR_JOBS = 'CLEAR_JOBS';
export const clearJobs = makeActionCreator(CLEAR_JOBS);

export const DOWNLOADING_DELETING_JOB = 'DOWNLOADING_DELETING_JOB';
export const downloadingDeletingJob = makeActionCreator(DOWNLOADING_DELETING_JOB, 'status');

export const CANCELING_JOB = 'CANCELING_JOB';
export const cancelingJob = makeActionCreator(CANCELING_JOB, 'status');

export const RESENDING_JOB = 'RESENDING_JOB';
export const resendingJob = makeActionCreator(RESENDING_JOB, 'status');

export const SET_JOB_SELECTED_PAGE_SIZE = 'SET_JOB_SELECTED_PAGE_SIZE';
export const setJobSelectedPageSize = makeActionCreator(SET_JOB_SELECTED_PAGE_SIZE, 'size');

export const SET_JOB_SELECTED_ORDER_BY = 'SET_JOB_SELECTED_ORDER_BY';
export const setJobSelectedOrderBy = makeActionCreator(SET_JOB_SELECTED_ORDER_BY, 'order');

export const SET_JOB_TAPPED_ID = 'SET_JOB_TAPPED_ID';
export const setJobTappedId = makeActionCreator(SET_JOB_TAPPED_ID, 'id');

export const SET_JOB_SEARCH_TEXT = 'SET_JOB_SEARCH_TEXT';
export const setJobSearchText = makeActionCreator(SET_JOB_SEARCH_TEXT, 'text');


export const DOWNLOADING_MY_JOBS = 'DOWNLOADING_MY_JOBS';
export const downloadingMyJobs = makeActionCreator(DOWNLOADING_MY_JOBS, 'status');

export const SET_MY_JOBS = 'SET_MY_JOBS';
export const setMyJobs = makeActionCreator(SET_MY_JOBS, 'data');

export const ADD_MY_JOB = 'ADD_MY_JOB';
export const addMyJob = makeActionCreator(ADD_MY_JOB, 'data');

export const CLEAR_MY_JOBS = 'CLEAR_MY_JOBS';
export const clearMyJobs = makeActionCreator(CLEAR_MY_JOBS);

export const DOWNLOADING_DELETING_MY_JOB = 'DOWNLOADING_DELETING_MY_JOB';
export const downloadingDeletingMyJob = makeActionCreator(DOWNLOADING_DELETING_MY_JOB, 'status');

export const CANCELING_MY_JOB = 'CANCELING_MY_JOB';
export const cancelingMyJob = makeActionCreator(CANCELING_MY_JOB, 'status');

export const RESENDING_MY_JOB = 'RESENDING_MY_JOB';
export const resendingMyJob = makeActionCreator(RESENDING_MY_JOB, 'status');


export const SET_MY_JOB_SELECTED_PAGE_SIZE = 'SET_MY_JOB_SELECTED_PAGE_SIZE';
export const setMyJobSelectedPageSize = makeActionCreator(SET_MY_JOB_SELECTED_PAGE_SIZE, 'size');

export const SET_MY_JOB_SELECTED_ORDER_BY = 'SET_MY_JOB_SELECTED_ORDER_BY';
export const setMyJobSelectedOrderBy = makeActionCreator(SET_MY_JOB_SELECTED_ORDER_BY, 'order');

export const SET_MY_JOB_TAPPED_ID = 'SET_MY_JOB_TAPPED_ID';
export const setMyJobTappedId = makeActionCreator(SET_MY_JOB_TAPPED_ID, 'id');

export const SET_MY_JOB_SEARCH_TEXT = 'SET_MY_JOB_SEARCH_TEXT';
export const setMyJobSearchText = makeActionCreator(SET_MY_JOB_SEARCH_TEXT, 'text');
