import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    documentGeneratorMenu: {
      title: 'Document Generator',
    },
    templatePage: {
      title: 'Template',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelTemplateBody: 'Template Body',
      labelTemplateHeader: 'Template header',
      labelTemplateFooter: 'Template Footer',
      labelSamplePayload: 'Sample Payload',
      labelMarginTop: 'Margin Top',
      labelMarginLeft: 'Margin Left',
      labelMarginRight: 'Margin Right',
      labelMarginBottom: 'Margin Bottom',
      labelHeader: 'Header',
      labelFooter: 'Footer',
      labelMaxHeight: 'Max Height',
      labelDrawDividerLine: 'Draw Divider Line',

      placeholderPaperSize: 'Paper Size',

      buttonCaptionCreateNewTemplate: 'Create Template',
      buttonCaptionEditTemplate: 'Edit Template',
      buttonCaptionDeleteTemplate: 'Delete Template',
      buttonCaptionPreview: 'Preview',
    },
    documentPage: {
      title: 'Document',

      labelDocumentTemplate: 'Document Template',
      labelCreatedDate: 'Created Date',
      labelUser: 'User',
      labelNo: 'No',
      labelTemplateId: 'Template ID',
      labelTemplateName: 'Template Name',
      labelUserId: 'User ID',
      labelUserName: 'User Name',

      buttonCaptionDownload: 'Download',
    },
  },
  id: {
    documentGeneratorMenu: {
      title: 'Pembuat Dokumen',
    },
    templatePage: {
      title: 'Templat',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',
      labelCreatedDate: 'Tanggal Dibuat',
      labelLastModifiedDate: 'Tanggal Terakhir Dimodifikasi',
      labelTemplateBody: 'Isi Templat',
      labelTemplateHeader: 'Tajuk Templat',
      labelTemplateFooter: 'Catatan Kaki Templat',
      labelSamplePayload: 'Sampel Muatan',
      labelMarginTop: 'Margin Atas',
      labelMarginLeft: 'Margin Kiri',
      labelMarginRight: 'Margin Kanan',
      labelMarginBottom: 'Margin Bawah',
      labelHeader: 'Header',
      labelFooter: 'Footer',
      labelMaxHeight: 'Tinggi Maximal',
      labelDrawDividerLine: 'Gambar Garis Pembatas',

      placeholderPaperSize: 'Ukuran Kertas',

      buttonCaptionCreateNewTemplate: 'Buat Templat',
      buttonCaptionEditTemplate: 'Ubah Templat',
      buttonCaptionDeleteTemplate: 'Hapus Templat',
      buttonCaptionPreview: 'Pratinjau',
    },
    documentPage: {
      title: 'Dokumen',

      labelDocumentTemplate: 'Template Dokumen',
      labelCreatedDate: 'Tanggal Dibuat',
      labelUser: 'Pengguna',
      labelNo: 'No',
      labelTemplateId: 'Templat ID',
      labelTemplateName: 'Nama Template',
      labelUserId: 'ID Pengguna',
      labelUserName: 'Nama Pengguna',

      buttonCaptionDownload: 'Unduh',
    },
  },
});

export default LocalizedString;
