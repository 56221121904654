import { connect } from 'react-redux';
import {
  addFunctionalPageAdvancedFilterForm, clearFunctionalPageAdvancedFilterForm,
  setAdvancedFilterDialogSelectedFilterString, setAdvancedFilterDialogVisibility,
  setAlertErrorMessage,
} from '../../../redux/action';
import AdvancedFilterDialog from './advanced-filter-dialog.presentation';

const mapStateToProps = (state) => ({
  advancedFilterVisibility: state.uiFunctionalPage.advancedFilterVisibility,
  filterString: state.uiFunctionalPage.filterString || '',
  initialValues: state.uiFunctionalPage.advancedFilterForm,
});

const mapDispatchToProps = (dispatch) => ({
  onApplyFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
  },
  onChangeDate: (e, field) => {
    dispatch(addFunctionalPageAdvancedFilterForm(field, e));
  },
  onChangeDropdown: (e, field) => {
    if (e) {
      dispatch(addFunctionalPageAdvancedFilterForm(field, e));
    } else {
      dispatch(addFunctionalPageAdvancedFilterForm(field, ''));
    }
  },
  onChangeText: (e, field) => {
    dispatch(addFunctionalPageAdvancedFilterForm(field, e.target.value));
  },
  onChangeSwitch: (e, field) => {
    dispatch(addFunctionalPageAdvancedFilterForm(field, e.value));
  },
  onClosePressed: () => {
    dispatch(setAdvancedFilterDialogVisibility(false));
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
  onResetPressed: () => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearFunctionalPageAdvancedFilterForm());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilterDialog);
