import { reset } from 'redux-form';
import {
  addingEditingCar, clearCatalog, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addCar, editCar, transformMedia } from '../../../helper';
import { RXFORM_CATALOG, PAGE_MODE_TABLE } from '../../../constant';
import downloadCatalogAsync from './downloadCatalogAsync';

export default (
  trim, color, year, description, media,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCar(true));

    const { token } = getState().authentication;
    const { tappedId, selectedModelId, selectedIsDefault } = getState().uiCatalog;
    const { data } = getState().catalog;

    const found = data[tappedId];
    const modelId = selectedModelId || found.model.id;
    const medias = media.length > 0 ? transformMedia(media) : [];
    const isDefault = () => {
      if (selectedIsDefault) { return selectedIsDefault.value; }
      if (tappedId) { return found.isDefault; }
      return true;
    };

    if (tappedId) {
      await editCar(
        tappedId, modelId, trim, color, year, isDefault(), description, medias, token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addCar(
        modelId, trim, color, year, isDefault(), description, medias, token,
      );
    }

    dispatch(reset(RXFORM_CATALOG));
    dispatch(clearCatalog());
    dispatch(downloadCatalogAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingCar(false));
  }
};
