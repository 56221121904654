import {
  REST_URL_POINTS, REST_URL_VIEW_DELETE_POINT, REST_URL_POINT_SUBMISSIONS,
  REST_URL_VIEW_DELETE_POINT_SUBMISSION, REST_URL_POINT_TRANSACTIONS,
  REST_URL_VIEW_DELETE_POINT_TRANSACTION, REST_URL_BALANCE, REST_URL_ACHIEVEMENT,
  REST_URL_VIEW_PURCHASE_REQUEST,
  REST_URL_PURCHASE_REQUESTS, REST_URL_VOID_POINT_REQUESTS, REST_URL_VIEW_VOID_POINT_REQUEST,
  REST_URL_ADD_VOID_POINT_REQUEST, REST_URL_LEADER_BOARD,
} from './constant';
import {
  buildQueryParams, localDateToUtc, sendGetRequest, sendPostRequest, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadPoints = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_POINTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPoint = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_POINT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadPointSubmissions = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_POINT_SUBMISSIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPointSubmission = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_POINT_SUBMISSION.replace(/\{id\}/, id),
    token);
  return response;
};

export const downloadPointTransactions = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_POINT_TRANSACTIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPointTransaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_POINT_TRANSACTION.replace(/\{id\}/, id),
    token);
  return response;
};

export const downloadAchievement = async (id, token) => {
  const response = await sendGetRequest(REST_URL_ACHIEVEMENT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadBalance = async (id, token) => {
  const response = await sendGetRequest(REST_URL_BALANCE.replace(/\{id\}/, id), token);
  return response;
};

export const downloadLeaderBoard = async (startRank, count, startPeriod, endPeriod, token) => {
  const START_PERIOD_KEY = startPeriod ? `&startPeriod=${localDateToUtc(startPeriod)}` : '';
  const END_PERIOD_KEY = endPeriod ? `&endPeriod=${localDateToUtc(endPeriod)}` : '';
  const url = REST_URL_LEADER_BOARD.replace(/\{startRank\}/, startRank).replace(/\{count\}/, count)
    .concat(START_PERIOD_KEY).concat(END_PERIOD_KEY);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadPurchaseRequests = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_PURCHASE_REQUESTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPurchaseRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_PURCHASE_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const downloadVoidPointRequests = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_VOID_POINT_REQUESTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadVoidPointRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_VOID_POINT_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const addVoidPointRequest = async (reason, merchantReferenceId, callbackUrl, pointId,
  token) => {
  const body = {
    reason,
    merchantReferenceId,
    callbackUrl,
    pointId,
  };
  await sendPostRequest(REST_URL_ADD_VOID_POINT_REQUEST, body, token);
};
