import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import {
  Badge, Fab, IconButton, Grid, Menu, MenuItem, Typography, withStyles,
} from '@material-ui/core';
import {
  AddCircleOutlineRounded, ArrowBack, CloudDownload, MoreVert, Notifications,
} from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  COLOR_BACKGROUND, COLOR_BACKGROUND_SECONDARY, COLOR_DANGEROUS, COLOR_DISABLED_ROW, COLOR_PRIMARY,
  COLOR_SECONDARY, ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING, PAGE_MODE_EDIT, PAGE_MODE_TABLE,
  PAGE_MODE_VIEW, RXFIELD_ID, RXFIELD_CREATED_BY, RXFIELD_CREATED_DATE, RXFIELD_LAST_MODIFIED_BY,
  RXFIELD_LAST_MODIFIED_DATE, RXFIELD_STATUS, STATUS_DISABLED,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import { MoreMenuShape, TableColumnShape, TotalCountShape } from '../../type';
import AccentButton from '../accent-button';
import BasePage from '../base-page';
import SectionTitle from '../section-title';
import TotalCountSection from '../total-count-section';
import AddEditDialog from './add-edit-dialog';
import AdvancedFilterDialog from './advanced-filter-dialog';

const styles = (theme) => ({
  headerField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  outlineSendButton: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
    marginLeft: 10,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  editDetailheaderField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 23,
  },
  primaryButton: {
    color: COLOR_PRIMARY,
  },
  editDetailtitle: {
    color: COLOR_SECONDARY,
    marginRight: 35,
  },
  redButton: {
    color: COLOR_DANGEROUS,
    marginRight: 7,
  },
  fieldContainer: {
    background: COLOR_BACKGROUND,
    padding: '13px 35px 13px 35px',
    borderRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    justifyContent: 'space-between',
    marginBottom: 80,
  },
  multipleFloatingContainer: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 35px 12px 35px',
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 5,
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.16)',
    height: 86,
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
    width: '100%',
    zIndex: 1,
    '&:hover': {
      backgroundColor: COLOR_BACKGROUND,
    },
  },
  multipleFloatingShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 259,
    width: 'calc(100% - 259px)',
  },
  leftFloatingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  floatingContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 35px 12px 35px',
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 5,
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.16)',
    height: 86,
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
    width: '100%',
    zIndex: 1,
    '&:hover': {
      backgroundColor: COLOR_BACKGROUND,
    },
  },
  filterButtonContainer: {
    margin: '-50px 0px 0px 20px',
  },
  badge: {
    margin: theme.spacing(0.5),
    width: 10,
  },
  saveButtonWrapper: {
    marginLeft: 10,
  },
  saveButtonLabel: {
    width: '0%',
  },
});

const ITEM_HEIGHT = 48;

const FunctionalPage = ({
  data, stepContent, stepTitle, moreMenus, filterColumns, filterSections, tableColumns,
  totalCountSectionData,
  addingEditing, createPermission, cropping, customDeleteButtonMenuCaption, deletePermission,
  disableCreate, disableDelete, disableEdit, disableSaveSubmit, downloading, downloadingDeleting,
  drawerVisibility, editPermission, enableSave, handleBackButton, saving, sendingNotification,
  sendNotificationPermission, useBackButtonTableMode, useFullWidth, usefullWidthDialog,
  useNotificationCount, useSendAllNotification, useTotalCountCustomLastField, useTotalCountSection,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onBackPressed,
  onCancelAdvancedFilterPressed, onCancelPressed, onCreatePressed, onCreateNewPressed, onChangePage,
  onChangePageSize, onConfirmDeletePressed, onDeletePressed, onDownloadPressed, onEditPressed,
  onFunctionalPageAdvancedFilterPressed, onFunctionalPageAppear, onFunctionalPageBackPressed,
  onFunctionalPageEditPressed, onFunctionalPageViewPressed, onMorePressed,
  onOpenSendNotificationDialog, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSendNotificationPressed, onSortPressed, onSubmitPressed, onViewPressed,
  renderCustomFabComponent,
  currentPage, totalCount, selectedPageSize, children,
  totalCountSectionCustomLastField, classes, history, pageMode, totalCountSectionMode, rowStyle,
  addDialogHeaderContainerStyle, addDialogSubmitButtonCaption, createNewButtonCaption,
  createPermissionName, deleteButtonCaption, deletePermissionName, editButtonCaption,
  editPermissionName, filterString, searchBarText,
  sendNotificationPermissionName, orderBy, tappedId, title,
}) => {
  const [initSearchText, setInitSearchText] = useState(searchBarText ? encodeURIComponent(searchBarText) : '');

  useEffect(() => {
    onFunctionalPageAppear(history);
    onAppear(pageMode);
    // eslint-disable-next-line
  }, [history, onFunctionalPageAppear, onAppear]);

  useEffect(() => {
    if (searchBarText) {
      setInitSearchText(encodeURIComponent(searchBarText));
    } else {
      setInitSearchText('');
    }
  }, [searchBarText]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const columns = tableColumns.length > 0
    ? tableColumns.map((x) => ({ ...x, customSort: () => null })) : [];

  const onCancelPress = () => {
    onCancelPressed();
    if (handleBackButton) {
      onBackPressed();
    } else {
      onFunctionalPageBackPressed();
    }
  };

  const onBackPress = () => {
    if ((useBackButtonTableMode && pageMode === PAGE_MODE_TABLE) || handleBackButton) {
      onBackPressed();
    } else {
      if (pageMode === PAGE_MODE_EDIT) { onCancelPress(); }
      onFunctionalPageBackPressed();
    }
  };

  const onEditPress = (id) => {
    onEditPressed(id);
    onFunctionalPageEditPressed();
  };

  const onCreateNewPress = () => {
    onCreatePressed();
    onCreateNewPressed(createNewButtonCaption);
  };

  const onCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMoreMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSendNotificationPress = () => {
    onOpenSendNotificationDialog();
    onSendNotificationPressed();
  };

  const onSortPress = (orderedColumnId, currSortOrder, prevOrderBy) => {
    const prevSortOrder = prevOrderBy.match('[^ ]* (.*)')[1];

    if (orderedColumnId < 0 && !currSortOrder) {
      const sortOrder = prevSortOrder === ORDER_SORT_ASCENDING ? ORDER_SORT_DESCENDING
        : ORDER_SORT_ASCENDING;
      onSortPressed(`${prevOrderBy.match('[^ ]+')[0]} ${sortOrder}`);
    }
    if (orderedColumnId > 0) {
      const rowData = columns.find((x, i) => i === orderedColumnId);
      onSortPressed(`${rowData.field} ${currSortOrder}`);
    }
  };

  const onSubMoreMenuPress = (itemFunction) => {
    itemFunction();
    onCloseMoreMenu();
  };

  const onViewPress = (id) => {
    onViewPressed(id);
    onFunctionalPageViewPressed(id);
  };

  const renderAdditionalField = () => {
    if (PAGE_MODE_VIEW) {
      return (
        <div>
          <SectionTitle title={LocalizedString.common.labelBasicInformation} />

          <Grid container spacing={3}>
            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_ID}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderId}
                  label={LocalizedString.common.placeholderId}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_CREATED_BY}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderCreatedBy}
                  label={LocalizedString.common.placeholderCreatedBy}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_CREATED_DATE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderCreatedDate}
                  label={LocalizedString.common.placeholderCreatedDate}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_STATUS}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderStatus}
                  label={LocalizedString.common.placeholderStatus}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_LAST_MODIFIED_BY}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderLastModifiedBy}
                  label={LocalizedString.common.placeholderLastModifiedBy}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_LAST_MODIFIED_DATE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderLastModifiedDate}
                  label={LocalizedString.common.placeholderLastModifiedDate}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return null;
  };

  const moreMenuList = [
    {
      caption: LocalizedString.common.labelEdit,
      disabled: disableEdit || (editPermissionName && !editPermission),
      onPress: () => onEditPress(tappedId),
    },
    {
      caption: customDeleteButtonMenuCaption ? (deleteButtonCaption
        || LocalizedString.common.labelCancel) : LocalizedString.common.labelDelete,
      disabled: disableDelete || (deletePermissionName && !deletePermission),
      onPress: () => onDeletePressed(tappedId),
    },
    ...moreMenus,
  ];

  const renderMoreMenu = () => (moreMenuList.some((x) => !x.disabled
  && x.caption !== LocalizedString.common.labelCancel)) && (
  <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={onOpenMoreMenu}
    >
      <MoreVert />
    </IconButton>

    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onCloseMoreMenu}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 200,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {moreMenuList.map((item) => {
        if (!item.disabled && item.caption !== LocalizedString.common.labelCancel) {
          return (
            <MenuItem key={item.caption} onClick={() => onSubMoreMenuPress(item.onPress)}>
              {item.caption}
            </MenuItem>
          );
        }
        return null;
      })}
    </Menu>
  </div>
  );

  const renderFloatingButton = () => {
    if (pageMode === PAGE_MODE_VIEW && useNotificationCount) {
      return (
        <Fab
          className={clsx(classes.multipleFloatingContainer,
            { [classes.multipleFloatingShift]: drawerVisibility })}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        >

          {sendNotificationPermissionName && sendNotificationPermission && (
          <div className={classes.leftFloatingWrapper}>
            <AccentButton
              startIcon={<Notifications />}
              variant="outlined"
              disabled={addingEditing || sendingNotification}
              disableElevation
              caption={LocalizedString.common.buttonCaptionSendNotification}
              onClick={onSendNotificationPress}
            />
          </div>
          )}

          <div>
            {(!disableDelete && deletePermissionName && deletePermission)
            || (!disableDelete && !deletePermissionName) ? (
              <AccentButton
                variant="text"
                className={classes.redButton}
                disabled={downloadingDeleting || addingEditing}
                disableElevation
                caption={deleteButtonCaption}
                onClick={() => onDeletePressed(tappedId)}
              />
              ) : null}

            {(!disableEdit && editPermissionName && editPermission)
            || (!disableEdit && !editPermissionName) ? (
              <AccentButton
                disabled={addingEditing || downloadingDeleting}
                disableElevation
                caption={editButtonCaption}
                onClick={() => onEditPress(tappedId)}
              />
              ) : null}
          </div>
        </Fab>
      );
    }
    if (pageMode === PAGE_MODE_EDIT) {
      return (
        <Fab
          className={classes.floatingContainer}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        >
          <AccentButton
            variant="text"
            className={classes.redButton}
            disabled={addingEditing}
            disableElevation
            caption={LocalizedString.common.buttonCaptionCancel}
            onClick={onCancelPress}
          />

          <AccentButton
            loading={addingEditing}
            disableElevation
            caption={LocalizedString.common.buttonCaptionSave}
            onClick={handleSubmit(onSavePressed)}
            disabled={cropping || disableSaveSubmit}
          />
        </Fab>
      );
    }
    return (
      <Fab
        className={classes.floatingContainer}
        disableFocusRipple
        disableTouchRipple
        disableRipple
      >
        {renderCustomFabComponent()}

        {(!disableDelete && deletePermissionName && deletePermission)
        || (!disableDelete && !deletePermissionName) ? (
          <AccentButton
            variant="text"
            className={classes.redButton}
            disabled={downloadingDeleting || addingEditing}
            disableElevation
            caption={deleteButtonCaption}
            onClick={() => onDeletePressed(tappedId)}
          />
          ) : null}

        {(!disableEdit && editPermissionName && editPermission)
        || (!disableEdit && !editPermissionName) ? (
          <AccentButton
            disabled={addingEditing || downloadingDeleting}
            disableElevation
            caption={editButtonCaption}
            onClick={() => onEditPress(tappedId)}
          />
          ) : null}
      </Fab>
    );
  };

  const renderCreateNewButton = () => (
    <div>
      {((!disableCreate && createPermissionName && createPermission) || (!disableCreate
      && !createPermissionName)) && (
      <AccentButton
        onClick={() => onCreateNewPress()}
        startIcon={<AddCircleOutlineRounded />}
        caption={createNewButtonCaption}
      />
      )}

      {(disableCreate && useSendAllNotification
      && sendNotificationPermissionName && sendNotificationPermission) && (
        <AccentButton
          onClick={onSendNotificationPress}
          startIcon={<Notifications />}
          caption={LocalizedString.common.buttonCaptionSendNotification}
        />
      )}

      {(!disableCreate && useSendAllNotification
      && sendNotificationPermissionName && sendNotificationPermission) && (
      <AccentButton
        onClick={onSendNotificationPress}
        startIcon={<Notifications />}
        caption={LocalizedString.common.buttonCaptionSendNotification}
        variant="outlined"
        className={classes.outlineSendButton}
      />
      )}

      {enableSave && (
      <AccentButton
        onClick={onDownloadPressed}
        startIcon={<CloudDownload />}
        caption=""
        disabled={downloading || addingEditing || downloadingDeleting || saving}
        loading={saving}
        variant="outlined"
        classes={{
          startIcon: classes.saveButtonWrapper,
          label: classes.saveButtonLabel,
          root: classes.saveButtonWrapper,
        }}
      />
      )}
    </div>
  );

  const TitleWithBackButton = (
    <div className={classes.editDetailheaderField}>
      <IconButton onClick={onBackPress}>
        <ArrowBack className={classes.primaryButton} />
      </IconButton>
      <Typography variant="h4" className={classes.editDetailtitle}>{title}</Typography>
    </div>
  );

  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <BasePage onConfirmPressed={onConfirmDeletePressed}>
        <div className={classes.headerField}>
          {useBackButtonTableMode ? TitleWithBackButton
            : (<Typography variant="h4" className={classes.title}>{title}</Typography>)}

          {renderCreateNewButton()}
        </div>

        <MaterialTable
          title={null}
          isLoading={downloading}
          searchText={decodeURIComponent(initSearchText)}
          onSearchChange={(text) => {
            setInitSearchText(encodeURIComponent(text));
            onSearchBarTextChanged(encodeURIComponent(text));
          }}
          onChangePage={(page) => onChangePage(page)}
          onChangeRowsPerPage={(pageSize) => onChangePageSize(pageSize)}
          onOrderChange={(orderedColumnId, orderDirection) => onSortPress(orderedColumnId,
            orderDirection, orderBy)}
          columns={columns}
          data={data}
          totalCount={totalCount}
          page={currentPage > 0 ? currentPage - 1 : currentPage}
          actions={[
            {
              icon: 'refresh',
              tooltip: LocalizedString.common.labelRefreshData,
              isFreeAction: true,
              onClick: () => onRefresh(selectedPageSize),
            },
            {
              icon: () => (<AccentButton caption={LocalizedString.common.buttonCaptionView} variant="outlined" />),
              onClick: (event, rowData) => onViewPress(rowData.id),
            },
            {
              position: 'row',
              action: (rowData) => ({
                icon: () => renderMoreMenu(),
                hidden: rowData.hideContextMenu || false,
                onClick: (event, rowdata) => onMorePressed(rowdata.id),
                position: 'row',
              }),
            },

          ]}
          options={{
            headerStyle: {
              backgroundColor: COLOR_BACKGROUND_SECONDARY,
            },
            searchFieldStyle: {
              border: `1px solid ${COLOR_BACKGROUND_SECONDARY}`,
              borderRadius: 2,
            },
            pageSize: selectedPageSize,
            pageSizeOptions: [10, 20, 50],
            actionsColumnIndex: 1,
            doubleHorizontalScroll: true,
            draggable: false,
            showEmptyDataSourceMessage: !!(data.length === 0 && !downloading),
            emptyRowsWhenPaging: false,
            debounceInterval: 400,
            showTitle: false,
            searchText: decodeURIComponent(initSearchText),
            rowStyle: rowStyle || ((rowData) => ({
              backgroundColor: rowData.status === STATUS_DISABLED
                ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
            })),
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                {filterColumns.length > 0 && (
                  <div className={classes.filterButtonContainer}>
                    <Badge color="error" badgeContent=" " invisible={!filterString} classes={{ anchorOriginTopRightRectangle: classes.badge }}>
                      <AccentButton
                        variant="outlined"
                        caption={LocalizedString.common.buttonCaptionAdvancedFilter}
                        disabled={addingEditing || downloadingDeleting}
                        onClick={() => {
                          onAdvancedFilterPressed();
                          onFunctionalPageAdvancedFilterPressed();
                        }}
                      />
                    </Badge>
                  </div>
                )}
              </div>
            ),
          }}
        />

        {!disableCreate && (
        <AddEditDialog
          submitting={addingEditing}
          onSubmitPressed={onSubmitPressed}
          onCancelPressed={onCancelPress}
          handleSubmit={handleSubmit}
          disabled={cropping || disableSaveSubmit}
          stepContent={stepContent}
          stepTitle={stepTitle}
          headerContainerStyle={addDialogHeaderContainerStyle}
          usefullWidthDialog={usefullWidthDialog}
          buttonCaption={addDialogSubmitButtonCaption}
        >
          {children}
        </AddEditDialog>
        )}

        {filterColumns.length > 0 && (
          <AdvancedFilterDialog
            downloading={downloading}
            filterColumns={filterColumns}
            filterSections={filterSections}
            onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
            onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
            onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
          />
        )}
      </BasePage>
    );
  }
  return (
    <BasePage onConfirmPressed={onConfirmDeletePressed}>
      {TitleWithBackButton}

      {pageMode === PAGE_MODE_VIEW && useTotalCountSection && (
      <TotalCountSection
        data={totalCountSectionData}
        mode={totalCountSectionMode}
        useCustomLastField={useTotalCountCustomLastField}
        customLastField={totalCountSectionCustomLastField}
      />
      )}

      <div className={classes.fieldContainer}>
        {useFullWidth ? children : (
          <Grid container>
            <Grid item sm={6}>
              {children}
            </Grid>
          </Grid>
        )}
        {renderAdditionalField()}
        {renderFloatingButton()}
      </div>

    </BasePage>
  );
};

export default withRouter(withStyles(styles)(FunctionalPage));

FunctionalPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepContent: PropTypes.arrayOf(PropTypes.node),
  stepTitle: PropTypes.arrayOf(PropTypes.string),
  moreMenus: PropTypes.arrayOf(MoreMenuShape),
  filterColumns: PropTypes.arrayOf(TableColumnShape),
  filterSections: PropTypes.arrayOf(TableColumnShape),
  tableColumns: PropTypes.arrayOf(TableColumnShape),
  totalCountSectionData: PropTypes.arrayOf(TotalCountShape),
  addingEditing: PropTypes.bool,
  createPermission: PropTypes.bool.isRequired,
  cropping: PropTypes.bool.isRequired,
  customDeleteButtonMenuCaption: PropTypes.bool,
  deletePermission: PropTypes.bool.isRequired,
  disableCreate: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableSaveSubmit: PropTypes.bool,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool,
  drawerVisibility: PropTypes.bool.isRequired,
  editPermission: PropTypes.bool.isRequired,
  enableSave: PropTypes.bool,
  handleBackButton: PropTypes.bool,
  saving: PropTypes.bool,
  sendingNotification: PropTypes.bool,
  sendNotificationPermission: PropTypes.bool,
  useBackButtonTableMode: PropTypes.bool,
  useFullWidth: PropTypes.bool,
  usefullWidthDialog: PropTypes.bool,
  useNotificationCount: PropTypes.bool,
  useSendAllNotification: PropTypes.bool,
  useTotalCountCustomLastField: PropTypes.bool,
  useTotalCountSection: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onAdvancedFilterPressed: PropTypes.func,
  onAppear: PropTypes.func,
  onApplyAdvancedFilterPressed: PropTypes.func,
  onBackPressed: PropTypes.func,
  onCancelAdvancedFilterPressed: PropTypes.func,
  onCancelPressed: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangePageSize: PropTypes.func,
  onConfirmDeletePressed: PropTypes.func,
  onCreatePressed: PropTypes.func,
  onCreateNewPressed: PropTypes.func,
  onDeletePressed: PropTypes.func,
  onDownloadPressed: PropTypes.func,
  onEditPressed: PropTypes.func,
  onFunctionalPageAdvancedFilterPressed: PropTypes.func.isRequired,
  onFunctionalPageAppear: PropTypes.func.isRequired,
  onFunctionalPageBackPressed: PropTypes.func.isRequired,
  onFunctionalPageEditPressed: PropTypes.func,
  onFunctionalPageViewPressed: PropTypes.func.isRequired,
  onMorePressed: PropTypes.func,
  onOpenSendNotificationDialog: PropTypes.func,
  onRefresh: PropTypes.func,
  onResetAdvancedFilterPressed: PropTypes.func,
  onSavePressed: PropTypes.func,
  onSearchBarTextChanged: PropTypes.func,
  onSendNotificationPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func,
  onSubmitPressed: PropTypes.func,
  onViewPressed: PropTypes.func,
  renderCustomFabComponent: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  children: PropTypes.node,
  totalCountSectionCustomLastField: PropTypes.node,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageMode: PropTypes.oneOf([PAGE_MODE_EDIT, PAGE_MODE_TABLE, PAGE_MODE_VIEW]).isRequired,
  totalCountSectionMode: PropTypes.oneOf(['flat', 'elevated']),
  rowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  addDialogHeaderContainerStyle: PropTypes.string,
  addDialogSubmitButtonCaption: PropTypes.string,
  createNewButtonCaption: PropTypes.string,
  createPermissionName: PropTypes.string,
  deleteButtonCaption: PropTypes.string,
  deletePermissionName: PropTypes.string,
  editButtonCaption: PropTypes.string,
  editPermissionName: PropTypes.string,
  filterString: PropTypes.string,
  searchBarText: PropTypes.string.isRequired,
  sendNotificationPermissionName: PropTypes.string,
  orderBy: PropTypes.string.isRequired,
  tappedId: PropTypes.string,
  title: PropTypes.string.isRequired,
};

FunctionalPage.defaultProps = {
  stepContent: [],
  stepTitle: [],
  moreMenus: [],
  filterColumns: [],
  filterSections: [],
  tableColumns: [],
  totalCountSectionData: [],
  addingEditing: false,
  customDeleteButtonMenuCaption: false,
  disableCreate: false,
  disableDelete: false,
  disableEdit: false,
  disableSaveSubmit: false,
  downloadingDeleting: false,
  enableSave: false,
  handleBackButton: false,
  saving: false,
  sendingNotification: false,
  sendNotificationPermission: false,
  useBackButtonTableMode: false,
  useFullWidth: false,
  usefullWidthDialog: false,
  useNotificationCount: false,
  useSendAllNotification: false,
  useTotalCountCustomLastField: false,
  useTotalCountSection: false,
  handleSubmit: () => {},
  onAdvancedFilterPressed: () => {},
  onAppear: () => {},
  onApplyAdvancedFilterPressed: () => {},
  onBackPressed: () => {},
  onCancelAdvancedFilterPressed: () => {},
  onCancelPressed: () => {},
  onChangePage: () => {},
  onChangePageSize: () => {},
  onConfirmDeletePressed: () => {},
  onCreatePressed: () => {},
  onCreateNewPressed: () => {},
  onDeletePressed: () => {},
  onDownloadPressed: () => {},
  onEditPressed: () => {},
  onFunctionalPageEditPressed: () => {},
  onMorePressed: () => {},
  onOpenSendNotificationDialog: () => {},
  onRefresh: () => {},
  onResetAdvancedFilterPressed: () => {},
  onSavePressed: () => {},
  onSearchBarTextChanged: () => {},
  onSortPressed: () => {},
  onSubmitPressed: () => {},
  onViewPressed: () => {},
  renderCustomFabComponent: () => {},
  children: (<div />),
  totalCountSectionCustomLastField: (<div />),
  totalCountSectionMode: 'flat',
  rowStyle: null,
  addDialogHeaderContainerStyle: '',
  addDialogSubmitButtonCaption: '',
  createNewButtonCaption: '',
  createPermissionName: '',
  deleteButtonCaption: '',
  deletePermissionName: '',
  editButtonCaption: '',
  editPermissionName: '',
  filterString: '',
  sendNotificationPermissionName: '',
  tappedId: '',
};
