import { downloadingTokens, setTokens } from '../simple-action';
import { downloadTokens, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, tokens, uiToken, uiUser,
  } = getState();
  const { token } = authentication;
  const { meta } = tokens;
  const { selectedPageSize, searchBarText, orderBy } = uiToken;
  const { tappedId } = uiUser;
  const { filterString } = getState().uiFunctionalPage;
  const filterStringTemp = filterString || 'status=enabled';

  try {
    dispatch(downloadingTokens(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadTokens(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      tappedId,
      filterStringTemp,
      token,
    );

    if (list) {
      dispatch(setTokens(list));
    }
  } finally {
    dispatch(downloadingTokens(false));
  }
};
