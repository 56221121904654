import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { YearCalendar } from '..';

const useStyles = makeStyles(() => ({
  activityIndicator: {
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const CombinedView = ({ downloading }) => {
  const classes = useStyles();

  if (downloading) {
    return (
      <div style={{ paddingTop: '24px', paddingBottom: '24px' }}>
        <CircularProgress size="30px" className={classes.activityIndicator} />
      </div>
    );
  }

  return <YearCalendar />;
};

export default CombinedView;

CombinedView.propTypes = {
  downloading: PropTypes.bool.isRequired,
};
