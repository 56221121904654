import { addUser, downloadingDeletingUser } from '../simple-action';
import { downloadUser } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingUser(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiUser;

    const user = await downloadUser(tappedId, token);
    const found = { ...user, roles: user.roles.length > 0 ? user.roles.map((x) => x.name) : '' };

    dispatch(addUser(found));
  } finally {
    dispatch(downloadingDeletingUser(false));
  }
};
