import { downloadingSurveyQuestions, setSurveyQuestions } from '../simple-action';
import { downloadSurveyQuestions, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, surveyQuestions, uiSurveyQuestion } = getState();
  const { token } = authentication;
  const { meta } = surveyQuestions;
  const { selectedPageSize, searchBarText, orderBy } = uiSurveyQuestion;
  const { filterString } = getState().uiFunctionalPage;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingSurveyQuestions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSurveyQuestions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setSurveyQuestions(list));
  } finally {
    dispatch(downloadingSurveyQuestions(false));
  }
};
