import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    newsScreen: {
      title: 'News',

      labelNo: 'No',
      labelTitle: 'Title',
      labelCategory: 'Category',
      labelPublishedDate: 'Published Date',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Order',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelSendNotificationTo: 'You are going to send notification to users',
      labelClickForMoreInfo: 'Click for more info',
      labelViewCount: 'View Count',
      labelHeadline: 'Headline',
      labelCopied: 'Copied!',
      labelViewerTrend: 'Viewer Trend',

      buttonCaptionCreateNewNews: 'Create New News',
      buttonCaptionEditNews: 'Edit News',
      buttonCaptionDeleteNews: 'Delete News',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',
      buttonCaptionSendToMe: 'Send To Me',
      buttonCaptionSendToUsers: 'Send To Users',

      placeholderTitle: 'Title',
      placeholderContent: 'Content',
      placeholderPublishedDate: 'Published Date',
      placeholderCategory: 'Category',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderAuthor: 'Author',
      placeholderSourceName: 'Source Name',
      placeholderSourceLink: 'Source Link',
      placeholderShortUrl: 'Short URL',
      placeholderReactionId: 'Reaction ID',
      placeholderDiscussionId: 'Discussion ID',
      placeholderShortDescription: 'Short Description',
      placeholderViewCount: 'View Count',
      placeholderTags: 'Tags',
      placeholderHeadline: 'Headline',
      placeholderDeepLink: 'Deep Link',
      placeholderPeriode: 'Period',
    },
    newsCategoryScreen: {
      title: 'News Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      buttonCaptionCreateNewNewsCategory: 'Create New News Category',
      buttonCaptionDeleteNewsCategory: 'Delete News Category',
      buttonCaptionEditNewsCategory: 'Edit News Category',

      placeholderNewsCategoryName: 'News Category Name',
      placeholderDescription: 'Description',
      placeholderNewsCategoryImage: 'Image',
    },
    newsDashboardScreen: {
      title: 'Dashboard',

      labelTopTen: '10 Top News',
      labelNo: 'No',
      labelNewsTitle: 'News Title',
      labelViewers: 'Viewers',

      placeholderFilter: 'Filter',

      buttonCaptionView: 'View',
    },
  },
  id: {
    newsScreen: {
      title: 'Berita',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelCategory: 'Kategori',
      labelPublishedDate: 'Tanggal Publikasi',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Urutan',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelSendNotificationTo: 'Anda akan mengirim pemberitahuan kepada pengguna',
      labelClickForMoreInfo: 'Klik untuk info lebih lanjut',
      labelViewCount: 'Jumlah Tampilan',
      labelHeadline: 'Tajuk Utama',
      labelCopied: 'Disalin!',
      labelViewerTrend: 'Tren Pembaca',

      buttonCaptionCreateNewNews: 'Buat Berita Baru',
      buttonCaptionEditNews: 'Ubah Berita',
      buttonCaptionDeleteNews: 'Hapus Berita',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',
      buttonCaptionSendToMe: 'Kirim Kepada Saya',
      buttonCaptionSendToUsers: 'Kirim Kepada Pengguna',

      placeholderTitle: 'Judul',
      placeholderContent: 'Konten',
      placeholderPublishedDate: 'Tanggal Publikasi',
      placeholderCategory: 'Kategori',
      placeholderVisibilityStatus: 'Status Terlihat',
      placeholderAuthor: 'Pemilik',
      placeholderSourceName: 'Nama Sumber',
      placeholderSourceLink: 'Tautan Sumber',
      placeholderShortUrl: 'Tautan Pendek',
      placeholderReactionId: 'ID Reaksi',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderShortDescription: 'Short Description',
      placeholderViewCount: 'Jumlah Tampilan',
      placeholderTags: 'Tag',
      placeholderHeadline: 'Tajuk Utama',
      placeholderDeepLink: 'Tautan Dalam',
      placeholderPeriode: 'Periode',
    },
    newsCategoryScreen: {
      title: 'Kategori Berita',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewNewsCategory: 'Buat Kategori Berita Baru',
      buttonCaptionDeleteNewsCategory: 'Hapus Kategori Berita',
      buttonCaptionEditNewsCategory: 'Uabh Kategori Berita',

      placeholderNewsCategoryName: 'Nama Kategori Berita',
      placeholderDescription: 'Deskripsi',
      placeholderNewsCategoryImage: 'Gambar',
    },
    newsDashboardScreen: {
      title: 'Dashboard',

      labelTopTen: '10 Berita Teratas',
      labelNo: 'No',
      labelNewsTitle: 'Judul Berita',
      labelViewers: 'Pembaca',

      placeholderFilter: 'Saring',

      buttonCaptionView: 'Lihat',
    },
  },
});

export default LocalizedString;
