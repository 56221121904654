import { reset } from 'redux-form';
import moment from 'moment';
import {
  addingEditingAdditionalField, clearAdditionalFields, setAlertErrorMessage, setFunctionalPageMode,
  setAdditionalFieldParentSearchText, setAdditionalFieldSelectedAppliedOn,
  setAdditionalFieldSelectedFieldType, setAdditionalFieldSelectedLogicalOperator,
  setAdditionalFieldSelectedMustUseCamera, setAdditionalFieldSelectedOptionMode,
  setAdditionalFieldSelectedOptionValueSeparator, setAdditionalFieldSelectedParent,
  setAdditionalFieldSelectedPickerMode, setAdditionalFieldSelectedTextKeyboardType,
  setAdditionalFieldSelectedTextMultiline,
} from '../simple-action';
import {
  addAdditionalField, editAdditionalField, localDateToUtc, toMoment, toNumber,
} from '../../../helper';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_FLOAT, ENUM_FIELD_INTEGER, ENUM_FIELD_OPTIONS,
  ENUM_FIELD_PHOTO, ENUM_FIELD_PICKER, ENUM_FIELD_TEXT, ENUM_FIELD_TIME, PAGE_MODE_TABLE,
  REVERSED_ISO_DATE_FORMAT, RXFORM_ADDITIONAL_FIELD,
} from '../../../constant';
import downloadAdditionalFieldsAsync from './downloadAdditionalFieldsAsync';

export default (values) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingAdditionalField(true));

    const { token } = getState().authentication;
    const {
      selectedAppliedOn, selectedFieldType, selectedLogicalOperator, selectedMustUseCamera,
      selectedOptionMode, selectedOptionValueSeparator, selectedParent, selectedPickerMode,
      selectedTextKeyboardType, selectedTextMultiline, tappedId,
    } = getState().uiAdditionalField;
    const { data } = getState().additionalFields;
    const {
      name, label, placeHolder, validationMessage, defaultValue, order, required, visible, readOnly,
      optionValues, sectionId, sectionLabel, textMinLength, textMaxLength, textRegexValidation,
      intMinValue, intMaxValue, floatMinValue, floatMaxValue, dateTimeMinValue, dateTimeMaxValue,
      showIfParentValue,
    } = values;

    const found = data[tappedId];
    const isTextMultiline = found && typeof found.textMultiline === 'object' ? found.textMultiline.value : false;
    const isMustUseCamera = found && typeof found.mustUseCamera === 'object' ? found.mustUseCamera.value : false;

    let additionalBody = {};
    switch (selectedFieldType) {
      case ENUM_FIELD_DATE:
        additionalBody = {
          dateTimeMinValue: dateTimeMinValue ? moment.utc(dateTimeMinValue)
            .format(REVERSED_ISO_DATE_FORMAT) : null,
          dateTimeMaxValue: dateTimeMaxValue ? moment.utc(dateTimeMaxValue)
            .format(REVERSED_ISO_DATE_FORMAT) : null,
        }; break;
      case ENUM_FIELD_DATE_TIME:
        additionalBody = {
          dateTimeMinValue: dateTimeMinValue ? localDateToUtc(dateTimeMinValue) : null,
          dateTimeMaxValue: dateTimeMaxValue ? localDateToUtc(dateTimeMaxValue) : null,
        }; break;
      case ENUM_FIELD_TIME:
        additionalBody = {
          dateTimeMinValue: dateTimeMinValue
            ? `${toMoment().format(REVERSED_ISO_DATE_FORMAT)}T${dateTimeMinValue}` : null,
          dateTimeMaxValue: dateTimeMaxValue
            ? `${toMoment().format(REVERSED_ISO_DATE_FORMAT)}T${dateTimeMaxValue}` : null,
        }; break;
      case ENUM_FIELD_TEXT:
        additionalBody = {
          textMultiline: typeof selectedTextMultiline === 'object' ? selectedTextMultiline.value : isTextMultiline,
          textKeyboardType: selectedTextKeyboardType || found.textKeyboardType,
          textMinLength: textMinLength ? toNumber(textMinLength) : null,
          textMaxLength: textMaxLength ? toNumber(textMaxLength) : null,
          textRegexValidation,
        }; break;
      case ENUM_FIELD_INTEGER:
        additionalBody = {
          intMinValue: intMinValue ? toNumber(intMinValue) : null,
          intMaxValue: intMaxValue ? toNumber(intMaxValue) : null,
        }; break;
      case ENUM_FIELD_FLOAT:
        additionalBody = {
          floatMinValue: floatMinValue ? parseFloat(floatMinValue) : null,
          floatMaxValue: floatMaxValue ? parseFloat(floatMaxValue) : null,
        }; break;
      case ENUM_FIELD_PICKER:
        additionalBody = {
          pickerMode: selectedPickerMode || found.pickerMode,
          OptionValues: optionValues,
        }; break;
      case ENUM_FIELD_OPTIONS:
        additionalBody = {
          optionMode: selectedOptionMode || found.optionMode,
          OptionValues: optionValues,
          optionValueSeparator: selectedOptionValueSeparator || found.optionValueSeparator,
        }; break;
      case ENUM_FIELD_PHOTO:
        additionalBody = {
          mustUseCamera: typeof selectedMustUseCamera === 'object' ? selectedMustUseCamera.value : isMustUseCamera,
        }; break;
      default: additionalBody = {};
    }

    const body = {
      id: found ? found.id : undefined,
      name,
      label,
      placeHolder,
      validationMessage,
      defaultValue,
      order: toNumber(order),
      required,
      visible,
      readOnly,
      fieldType: selectedFieldType || found.fieldType,
      sectionId,
      sectionLabel,
      appliedOn: selectedAppliedOn || found.appliedOn,
      ParentFieldId: typeof selectedParent === 'object' ? selectedParent.value : undefined,
      ShowIfParentValue: typeof selectedParent === 'object' && selectedParent.value ? showIfParentValue : undefined,
      showIfParentValueLogicalOperator: typeof selectedParent === 'object' && selectedParent.value ? (selectedLogicalOperator || found.showIfParentValueLogicalOperator) : undefined,
      ...additionalBody,
    };

    if (tappedId) {
      await editAdditionalField(body, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addAdditionalField(body, token);
    }

    dispatch(reset(RXFORM_ADDITIONAL_FIELD));
    dispatch(clearAdditionalFields());
    dispatch(downloadAdditionalFieldsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setAdditionalFieldParentSearchText(''));
    dispatch(setAdditionalFieldSelectedAppliedOn(''));
    dispatch(setAdditionalFieldSelectedFieldType(''));
    dispatch(setAdditionalFieldSelectedLogicalOperator(''));
    dispatch(setAdditionalFieldSelectedMustUseCamera(''));
    dispatch(setAdditionalFieldSelectedOptionMode(''));
    dispatch(setAdditionalFieldSelectedOptionValueSeparator(''));
    dispatch(setAdditionalFieldSelectedParent(''));
    dispatch(setAdditionalFieldSelectedPickerMode(''));
    dispatch(setAdditionalFieldSelectedTextKeyboardType(''));
    dispatch(setAdditionalFieldSelectedTextMultiline(''));
  } finally {
    dispatch(addingEditingAdditionalField(false));
  }
};
