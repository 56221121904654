import { downloadingSurveys, setSurveys } from '../simple-action';
import { downloadSurveys, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, surveys, uiSurvey } = getState();
  const { token } = authentication;
  const { meta, data } = surveys;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiSurvey;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingSurveys(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSurveys(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setSurveys(list));
    }
  } finally {
    dispatch(downloadingSurveys(false));
  }
};
