import { downloadingWorkingHourAdjustments, setWorkingHourAdjustments } from '../simple-action';
import { downloadWorkingHourAdjustments, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, workingHourAdjustments, uiWorkingHourAdjustment,
  } = getState();
  const { token } = authentication;
  const { meta } = workingHourAdjustments;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiWorkingHourAdjustment;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingWorkingHourAdjustments(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadWorkingHourAdjustments(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setWorkingHourAdjustments(list));
  } finally {
    dispatch(downloadingWorkingHourAdjustments(false));
  }
};
