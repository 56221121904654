import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { clearEmployees, setHistoryEmployeeSearchText, downloadEmployeesAsync } from '../../../attendance/redux/action';
import {
  clearPackets, clearPacketTypes, clearPickupLocations, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setPacketTypeSearchText, setPacketSearchText, setPacketSelectedPageSize, setPacketSelectedOrderBy,
  setPacketSelectedPacketType, setPacketSelectedPickupLocation, setPacketTappedId,
  setPacketSelectedReceivedBy, setPacketSelectedRecipient, setPacketPickupDialogVisibility,
  setPickupLocationSearchText, setPacketSelectedPickedUpBy, setPacketSelectedPickedUpByUser,
  addEditPacketAsync, downloadDeletePacketAsync, downloadPacketsAsync, downloadPacketTypesAsync,
  downloadPickupLocationsAsync, pickupPacketAsync, savePacketsAsync,
  setPickUpPacketUploadImageMethod,
} from '../../redux/action';
import { clearManagers, setProfileManagerSearchText, downloadManagersAsync } from '../../../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_PACKETS, MENUID_PACKET_MANAGEMENT_PACKET,
  PAGE_MODE_TABLE, RXFIELD_PACKET_ARRIVAL_IMAGE, RXFIELD_PACKET_PACKET_TYPE,
  RXFIELD_PACKET_PICK_UP_BY, RXFIELD_PACKET_PICKED_UP_BY, RXFIELD_PACKET_PICKED_UP_IMAGE,
  RXFIELD_PACKET_PICKUP_LOCATION, RXFIELD_PACKET_RECEIVED_BY, RXFIELD_PACKET_RECIPIENT,
  RXFORM_PACKET, DEFAULT_DATE_TIME,
} from '../../constant';
import { toMoment, transformDropdownData, transformInitialValues } from '../../helper';
import LocalizedString from '../../../../localization';
import PacketPage from './packet.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { packets, uiFunctionalPage, uiPacket } = state;
  const { data } = packets;
  const {
    downloadingDeleting, pickupDialogVisibility, pickingUp, tappedId,
  } = uiPacket;
  const { pageMode } = uiFunctionalPage;

  const found = (pageMode !== PAGE_MODE_TABLE && !downloadingDeleting && !pickingUp)
   || (pageMode === PAGE_MODE_TABLE && pickupDialogVisibility && tappedId) ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    recipient: found.recipient ? { label: found.recipient.fullName, value: found.recipient.id } : '',
    receivedBy: found.receivedBy ? { label: found.receivedBy.fullName, value: found.receivedBy.id } : '',
    pickupLocation: found.pickupLocation ? { label: found.pickupLocation.name, value: found.pickupLocation.id } : '',
    packetType: found.packetType ? { label: found.packetType.name, value: found.packetType.id } : '',
    pickedUpBy: found.pickedUpBy ? { label: found.pickedUpBy.fullName, value: found.pickedUpBy.id } : '',
    handedBy: found.handedBy ? { label: found.handedBy.fullName, value: found.handedBy.id } : '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
      : null,
    pickedUpTime: found.pickedUpTime !== DEFAULT_DATE_TIME
      ? toMoment(found.pickedUpTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
  }) : {
    sender: '',
    recipient: '',
    pickupLocation: '',
    packetType: '',
    arrivalNotes: '',
    arrivalImage: '',
  };
  return result;
};

const transformedData = (state) => {
  const { managers } = state;
  const { data } = managers;
  const result = Object.values(data).map((item) => ({
    ...item, fullName: `${item.fullName} (${item.email})`,
  }));
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiPacket.addingEditing,
  downloadingDeleting: state.uiPacket.downloadingDeleting,
  downloading: state.uiPacket.downloading,
  loadingPacketType: state.uiPacketType.downloading,
  loadingPickupLocation: state.uiPickupLocation.downloading,
  loadingRecipient: state.uiProfile.downloading,
  loadingRecievedBy: state.uiHistory.downloadingEmployees,
  pickingUp: state.uiPacket.pickingUp,
  pickupDialogVisibility: state.uiPacket.pickupDialogVisibility,
  currentPacket: state.packets.data[state.uiFunctionalPage.tappedId]
    ? state.packets.data[state.uiFunctionalPage.tappedId] : {},
  selectedPickedUpBy: state.uiPacket.selectedPickedUpBy,
  tappedId: state.uiFunctionalPage.tappedId || '',
  receivedBys: transformDropdownData(state.employees.data),
  recipients: transformDropdownData(transformedData(state)),
  packetTypes: transformDropdownData(state.packetTypes.data),
  pickupLocations: transformDropdownData(state.pickupLocations.data),
  initialValues: getInitialValues(state),
  uploadImageMethod: state.uiPacket.uploadImageMethod,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setPacketSelectedRecipient(''));
    dispatch(setPacketSelectedReceivedBy(''));
    dispatch(setPacketSelectedPacketType(''));
    dispatch(setPacketSelectedPickupLocation(''));
    dispatch(clearManagers());
    dispatch(clearEmployees());
    dispatch(clearPacketTypes());
    dispatch(clearPickupLocations());
    dispatch(downloadManagersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadEmployeesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPacketTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPickupLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PACKET_MANAGEMENT_PACKET));
    dispatch(setPacketSearchText(''));
    dispatch(clearPackets());
    dispatch(setPacketSelectedPageSize(20));
    dispatch(setPacketSelectedOrderBy(INITIAL_ORDER_BY_PACKETS));
    dispatch(downloadPacketsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/recipient/, 'recipient.id').replace(/receivedBy/, 'receivedBy.id')
      .replace(/packetType/, 'packetType.id').replace(/pickupLocation/, 'pickupLocation.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPackets());
    dispatch(downloadPacketsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onArrivalImageSelected: (image) => {
    dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_ARRIVAL_IMAGE, image));
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setPacketSelectedRecipient(''));
    dispatch(setPacketSelectedReceivedBy(''));
    dispatch(setPacketSelectedPacketType(''));
    dispatch(setPacketSelectedPickupLocation(''));
  },
  onCancelPickupPressed: () => {
    dispatch(setPacketPickupDialogVisibility(false));
    dispatch(reset(RXFORM_PACKET));
    dispatch(setPacketSelectedPickedUpBy(''));
    dispatch(setPacketSelectedPickedUpByUser(''));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PACKET));
    dispatch(setPacketSelectedRecipient(''));
    dispatch(setPacketSelectedPacketType(''));
    dispatch(setPacketSelectedPickupLocation(''));
  },
  onChangePacketTypeText: async (text) => {
    try {
      dispatch(setPacketTypeSearchText(text));
      dispatch(clearPacketTypes());
      await dispatch(downloadPacketTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPacketsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPacketSelectedPageSize(pageSize));
    dispatch(downloadPacketsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePickedUpByUserText: async (text) => {
    try {
      dispatch(setProfileManagerSearchText(text));
      dispatch(clearManagers());
      await dispatch(downloadManagersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePickupLocationText: async (text) => {
    try {
      dispatch(setPickupLocationSearchText(text));
      dispatch(clearPickupLocations());
      await dispatch(downloadPickupLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeReceivedByText: async (text) => {
    try {
      dispatch(setHistoryEmployeeSearchText(text));
      dispatch(clearEmployees());
      await dispatch(downloadEmployeesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeRecipientText: async (text) => {
    try {
      dispatch(setProfileManagerSearchText(text));
      dispatch(clearManagers());
      await dispatch(downloadManagersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeletePacketAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmPickupPressed: ({ pickedUpNotes, pickedupImage, pickedUpByNonUser }) => {
    dispatch(pickupPacketAsync(pickedUpNotes, pickedupImage, pickedUpByNonUser))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(reset(RXFORM_PACKET));
    dispatch(setPacketTappedId(''));
    dispatch(setProfileManagerSearchText(''));
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setPacketTypeSearchText(''));
    dispatch(clearPacketTypes());
    dispatch(downloadPacketTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setPickupLocationSearchText(''));
    dispatch(clearPickupLocations());
    dispatch(downloadPickupLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setPacketTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(savePacketsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id) => {
    dispatch(reset(RXFORM_PACKET));
    dispatch(setPacketTappedId(id));
    dispatch(setProfileManagerSearchText(''));
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setPacketTypeSearchText(''));
    dispatch(clearPacketTypes());
    dispatch(downloadPacketTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setPickupLocationSearchText(''));
    dispatch(clearPickupLocations());
    dispatch(downloadPickupLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onPacketTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setPacketSelectedPacketType(option));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PACKET_TYPE, option));
    } else {
      dispatch(setPacketSelectedPacketType(''));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PACKET_TYPE, ''));
    }
  },
  onPickedUpByOptionSelected: (option) => {
    if (option) {
      dispatch(setPacketSelectedPickedUpBy(option));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PICK_UP_BY, option));
    } else {
      dispatch(setPacketSelectedPickedUpBy(''));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PICK_UP_BY, ''));
    }
  },
  onPickedUpByUserOptionSelected: (option) => {
    if (option) {
      dispatch(setPacketSelectedPickedUpByUser(option));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PICKED_UP_BY, option));
    } else {
      dispatch(setPacketSelectedPickedUpByUser(''));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PICKED_UP_BY, ''));
    }
  },
  onPickedUpImageSelected: (image) => {
    dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PICKED_UP_IMAGE, image));
  },
  onPickupLocationOptionSelected: (option) => {
    if (option) {
      dispatch(setPacketSelectedPickupLocation(option));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PICKUP_LOCATION, option));
    } else {
      dispatch(setPacketSelectedPickupLocation(''));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_PICKUP_LOCATION, ''));
    }
  },
  onPickupPressed: (id) => {
    dispatch(setPacketTappedId(id));
    dispatch(setPacketPickupDialogVisibility(true));
    dispatch(setProfileManagerSearchText(''));
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onReceivedByOptionSelected: (option) => {
    if (option) {
      dispatch(setPacketSelectedReceivedBy(option));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_RECEIVED_BY, option));
    } else {
      dispatch(setPacketSelectedReceivedBy(''));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_RECEIVED_BY, ''));
    }
  },
  onRecipientOptionSelected: (option) => {
    if (option) {
      dispatch(setPacketSelectedRecipient(option));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_RECIPIENT, option));
    } else {
      dispatch(setPacketSelectedRecipient(''));
      dispatch(change(RXFORM_PACKET, RXFIELD_PACKET_RECIPIENT, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPacketSelectedPageSize(pageSize));
    dispatch(clearPackets());
    dispatch(downloadPacketsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setPacketSelectedRecipient(''));
    dispatch(setPacketSelectedReceivedBy(''));
    dispatch(setPacketSelectedPacketType(''));
    dispatch(setPacketSelectedPickupLocation(''));
    dispatch(clearPackets());
    dispatch(setPacketSelectedOrderBy(INITIAL_ORDER_BY_PACKETS));
    dispatch(downloadPacketsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ sender, arrivalNotes, arrivalImage }) => {
    try {
      await dispatch(addEditPacketAsync(sender, arrivalNotes, arrivalImage));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPacketSearchText(text));
      dispatch(clearPackets());
      await dispatch(downloadPacketsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPacketSelectedOrderBy(orderBy));
    dispatch(clearPackets());
    dispatch(downloadPacketsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ sender, arrivalNotes, arrivalImage }) => {
    await dispatch(addEditPacketAsync(sender, arrivalNotes, arrivalImage));
  },
  onViewPressed: (id) => {
    dispatch(setPacketTappedId(id));
    dispatch(downloadDeletePacketAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onUploadImageMethodChange: (event, method) => {
    dispatch(setPickUpPacketUploadImageMethod(method));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PacketPage);
