import { connect } from 'react-redux';
import MapDrawer from './map-drawer.presentation';
import {
  addingPolygon, setMapDrawerMarker, setMapDrawerPolygon, setMapDrawerRenderUserShapeAfterComplete,
} from '../../redux/action';

const mapStateToProps = (state) => ({
  userMarker: state.mapDrawer.marker,
  userPolygon: state.mapDrawer.polygon,
  renderUserShapeAfterCompleted: state.mapDrawer.renderUserShapeAfterCompleted,
});

const mapDispatchToProps = (dispatch) => ({
  onMapDrawerCompleted: (data, onDrawingCompleted, drawingMode) => {
    dispatch(setMapDrawerRenderUserShapeAfterComplete(true));
    dispatch(addingPolygon(true));
    onDrawingCompleted(data, drawingMode);
    dispatch(addingPolygon(false));
    dispatch(setMapDrawerRenderUserShapeAfterComplete(false));
  },
  onUserMarkerChanged: (data) => {
    dispatch(setMapDrawerMarker(data));
  },
  onUserPolygonChanged: (data) => {
    dispatch(setMapDrawerPolygon(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapDrawer);
