import { sendingQuizNotification } from '../simple-action';
import { sendQuizNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(sendingQuizNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiQuiz;

    await sendQuizNotification(tappedId, token);
  } finally {
    dispatch(sendingQuizNotification(false));
  }
};
