import { isArray } from 'lodash';
import { downloadingApiKeys, setApiKeys } from '../simple-action';
import { downloadApiKeys, transformSearchText } from '../../../helper';
import {
  API_KEY_OWNER_APP_NAME_PARAMETER,
  API_KEY_CONSUMER_APP_NAME_PARAMETER,
  API_KEY_SCOPE_PARAMETER,
} from '../../../constant';

const getNewParameter = (fieldName, data, filterString) => {
  const arr = filterString.split('|');
  const selectedField = arr.find((x) => x.startsWith(fieldName));
  const ids = selectedField.split('><')[1];
  const newIds = ids.includes(';') ? ids.split(';') : ids;
  if (isArray(newIds)) {
    const names = newIds.map((x) => data.find((item) => item.value === x).label);
    const newParameter = names.reduce((prev, curr) => {
      if (!prev) {
        return `${fieldName}${curr}`;
      }
      return `${prev};${curr}`;
    }, '');
    return newParameter;
  }
  const { label } = data.find((item) => item.value === newIds);
  const newParameter = `${fieldName}${label}`;
  return newParameter;
};

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, apiKeys, uiApiKey, uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = apiKeys;
  const {
    selectedPageSize, searchBarText, orderBy, selectedAdvancedFilterOwnerAppName,
    selectedAdvancedFilterConsumerAppName, selectedAdvancedFilterScope,
  } = uiApiKey;
  const { filterString } = uiFunctionalPage;

  const ownerParam = filterString.includes(API_KEY_OWNER_APP_NAME_PARAMETER)
    ? `${getNewParameter(API_KEY_OWNER_APP_NAME_PARAMETER, selectedAdvancedFilterOwnerAppName, filterString)}|` : '';
  const consumerParam = filterString.includes(API_KEY_CONSUMER_APP_NAME_PARAMETER)
    ? `${getNewParameter(API_KEY_CONSUMER_APP_NAME_PARAMETER, selectedAdvancedFilterConsumerAppName, filterString)}|` : '';
  const scopeParam = filterString.includes(API_KEY_SCOPE_PARAMETER)
    ? `${getNewParameter(API_KEY_SCOPE_PARAMETER, selectedAdvancedFilterScope, filterString)}|` : '';

  const filterStringTemp = filterString && `|${ownerParam}${consumerParam}${scopeParam}`;

  try {
    dispatch(downloadingApiKeys(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadApiKeys(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterStringTemp,
      token,
    );

    if (list) {
      dispatch(setApiKeys(list));
    }
  } finally {
    dispatch(downloadingApiKeys(false));
  }
};
