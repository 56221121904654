import {
  DOWNLOADING_APPLICATIONS, ADDING_EDITING_APPLICATION, DOWNLOADING_DELETING_APPLICATION,
  SET_APPLICATION_SELECTED_PAGE_SIZE, SET_APPLICATION_TAPPED_ID, SET_APPLICATION_SEARCH_TEXT,
  SET_APPLICATION_SELECTED_ORDER_BY, SET_APPLICATION_SELECTED_PERMISSION_NAME,
} from '../action';
import { INITIAL_ORDER_BY_APPLICATIONS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedPermissionName: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_APPLICATIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_APPLICATIONS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_APPLICATION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_APPLICATION:
      return { ...state, downloadingDeleting: action.status };
    case SET_APPLICATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_APPLICATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_APPLICATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_APPLICATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_APPLICATION_SELECTED_PERMISSION_NAME:
      return { ...state, selectedPermissionName: action.id };
    default: return state;
  }
};
