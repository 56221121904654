import { reset } from 'redux-form';
import {
  pickingUpPacket, clearPackets, setAlertErrorMessage, setFunctionalPageMode,
  setPacketSelectedPickedUpBy, setPacketSelectedPickedUpByUser, setPacketPickupDialogVisibility,
} from '../simple-action';
import { pickUpPacket } from '../../../helper';
import {
  PACKET_PICKED_UP_BY_NON_USER, PACKET_PICKED_UP_BY_RECIPIENT, PACKET_PICKED_UP_BY_USER,
  PAGE_MODE_TABLE, RXFORM_PACKET,
} from '../../../constant';
import downloadPacketsAsync from './downloadPacketsAsync';

export default (pickedUpNotes, pickedupImage, pickedUpByNonUser) => async (dispatch, getState) => {
  try {
    dispatch(pickingUpPacket(true));

    const { token } = getState().authentication;
    const { selectedPickedUpBy, selectedPickedUpByUser, tappedId } = getState().uiPacket;
    const { data } = getState().packets;
    const found = data[tappedId];

    let pickedUpByProfileId;
    switch (selectedPickedUpBy.value) {
      case PACKET_PICKED_UP_BY_RECIPIENT:
        pickedUpByProfileId = found.recipient.id; break;
      case PACKET_PICKED_UP_BY_USER:
        pickedUpByProfileId = selectedPickedUpByUser.value; break;
      default: pickedUpByProfileId = undefined; break;
    }

    const pickedUpByNonUserProfileId = selectedPickedUpBy.value === PACKET_PICKED_UP_BY_NON_USER
      ? pickedUpByNonUser : undefined;

    await pickUpPacket(tappedId, pickedUpByProfileId, pickedUpByNonUserProfileId, pickedUpNotes,
      pickedupImage, token);
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));

    dispatch(setPacketPickupDialogVisibility(false));
    dispatch(reset(RXFORM_PACKET));
    dispatch(clearPackets());
    dispatch(downloadPacketsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setPacketSelectedPickedUpBy(''));
    dispatch(setPacketSelectedPickedUpByUser(''));
  } finally {
    dispatch(pickingUpPacket(false));
  }
};
