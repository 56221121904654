import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_AUDIT_TRAILS = 'DOWNLOADING_AUDIT_TRAILS';
export const downloadingAuditTrails = makeActionCreator(DOWNLOADING_AUDIT_TRAILS, 'status');

export const SET_AUDIT_TRAILS = 'SET_AUDIT_TRAILS';
export const setAuditTrails = makeActionCreator(SET_AUDIT_TRAILS, 'data');

export const ADD_AUDIT_TRAIL = 'ADD_AUDIT_TRAIL';
export const addAuditTrail = makeActionCreator(ADD_AUDIT_TRAIL, 'data');

export const CLEAR_AUDIT_TRAILS = 'CLEAR_AUDIT_TRAILS';
export const clearAuditTrails = makeActionCreator(CLEAR_AUDIT_TRAILS);

export const DOWNLOADING_AUDIT_TRAIL = 'DOWNLOADING_AUDIT_TRAIL';
export const downloadingAuditTrail = makeActionCreator(DOWNLOADING_AUDIT_TRAIL, 'status');

export const FILTERING_AUDIT_TRAIL_COLUMNS = 'FILTERING_AUDIT_TRAIL_COLUMNS';
export const filteringAuditTrailColumns = makeActionCreator(FILTERING_AUDIT_TRAIL_COLUMNS, 'status');

export const COPYING_COLUMN_VALUE = 'COPYING_COLUMN_VALUE';
export const copyingColumnValue = makeActionCreator(COPYING_COLUMN_VALUE, 'status');


export const SET_AUDIT_TRAIL_SELECTED_PAGE_SIZE = 'SET_AUDIT_TRAIL_SELECTED_PAGE_SIZE';
export const setAuditTrailSelectedPageSize = makeActionCreator(SET_AUDIT_TRAIL_SELECTED_PAGE_SIZE, 'size');

export const SET_AUDIT_TRAIL_SELECTED_ORDER_BY = 'SET_AUDIT_TRAIL_SELECTED_ORDER_BY';
export const setAuditTrailSelectedOrderBy = makeActionCreator(SET_AUDIT_TRAIL_SELECTED_ORDER_BY, 'order');

export const SET_AUDIT_TRAIL_TAPPED_ID = 'SET_AUDIT_TRAIL_TAPPED_ID';
export const setAuditTrailTappedId = makeActionCreator(SET_AUDIT_TRAIL_TAPPED_ID, 'id');

export const SET_AUDIT_TRAIL_SEARCH_TEXT = 'SET_AUDIT_TRAIL_SEARCH_TEXT';
export const setAuditTrailSearchText = makeActionCreator(SET_AUDIT_TRAIL_SEARCH_TEXT, 'text');

export const SET_AUDIT_TRAIL_SELECTED_SERVICE = 'SET_AUDIT_TRAIL_SELECTED_SERVICE';
export const setAuditTrailSelectedService = makeActionCreator(SET_AUDIT_TRAIL_SELECTED_SERVICE, 'option');

export const SET_AUDIT_TRAIL_SELECTED_MODIFIED = 'SET_AUDIT_TRAIL_SELECTED_MODIFIED';
export const setAuditTrailSelectedModified = makeActionCreator(SET_AUDIT_TRAIL_SELECTED_MODIFIED, 'status');
