import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_CONFIG_GROUP_NAME, RXFIELD_CONFIG_GROUP_ORDER, RXFIELD_CONFIG_GROUP_TAG,
  RXFIELD_CONFIG_ITEM_NAME, RXFIELD_CONFIG_ITEM_ORDER, RXFIELD_CONFIG_ITEM_LABEL,
  RXFIELD_CONFIG_ITEM_DATA_TYPE, RXFIELD_CONFIG_ITEM_DESCRIPTION, RXFIELD_CONFIG_ITEM_VALUE,
  RXFIELD_CONFIG_ITEM_USED_ON, RXFIELD_CONFIG_ITEM_CONFIG_GROUP, RXFIELD_PROFILE_SYNC_FULL_NAME,
  RXFIELD_PROFILE_SYNC_EMPLOYEE_ID, RXFIELD_PROFILE_SYNC_EMAIL,
  RXFIELD_PROFILE_SYNC_BRANCH_ID, RXFIELD_PROFILE_SYNC_MANAGER_EMPLOYEE_ID,
} from './constant';

export const rxformValidateConfigGroup = (values) => {
  const requiredFields = [
    RXFIELD_CONFIG_GROUP_NAME,
    RXFIELD_CONFIG_GROUP_ORDER,
    RXFIELD_CONFIG_GROUP_TAG,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateConfigItem = (values) => {
  const requiredFields = [
    RXFIELD_CONFIG_ITEM_NAME, RXFIELD_CONFIG_ITEM_ORDER, RXFIELD_CONFIG_ITEM_LABEL,
    RXFIELD_CONFIG_ITEM_VALUE, RXFIELD_CONFIG_ITEM_DESCRIPTION, RXFIELD_CONFIG_ITEM_DATA_TYPE,
    RXFIELD_CONFIG_ITEM_USED_ON, RXFIELD_CONFIG_ITEM_CONFIG_GROUP,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateProfileSync = (values) => {
  const requiredFields = [
    RXFIELD_PROFILE_SYNC_EMPLOYEE_ID,
    RXFIELD_PROFILE_SYNC_FULL_NAME,
    RXFIELD_PROFILE_SYNC_EMAIL,
    RXFIELD_PROFILE_SYNC_BRANCH_ID,
    RXFIELD_PROFILE_SYNC_MANAGER_EMPLOYEE_ID,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
