import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, withStyles,
} from '@material-ui/core';
import { AccentButton } from '../../component';
import {
  COLOR_DANGEROUS, COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY, PICKER_MODE_DATE, RXFIELD_WFA_DATE,
  RXFIELD_WFA_STATUSES_NOTES, RXFORM_WFA_EDIT_DIALOG,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { rxformValidateWfa } from '../../validation';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
}));

const EditDialog = ({
  submitting, visibility,
  handleSubmit, onCancelPressed, onSubmitPressed,
  classes,
}) => (
  <Dialog
    open={visibility}
    aria-labelledby="form-dialog-title"
    classes={{ paper: classes.paper }}
  >
    <div className={classes.headerContainer}>
      <DialogTitle>{LocalizedString.wfaPage.labelEditWfa}</DialogTitle>
    </div>

    <DialogContent>
      <form
        onSubmit={handleSubmit(onSubmitPressed)}
        className={classes.form}
      >
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_WFA_DATE}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.wfaPage.labelDate}
                disabled={submitting}
                pickerMode={PICKER_MODE_DATE}
                required
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_WFA_STATUSES_NOTES}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.wfaPage.labelWfaStatusNotes}
                label={LocalizedString.wfaPage.labelWfaStatusNotes}
                disabled={submitting}
                multiline
              />
            </Grid>
          </Grid>
        </Grid>

        <DialogActions className={classes.buttonContainer}>
          <AccentButton
            onClick={onCancelPressed}
            variant="text"
            caption={GlobalLocalizedString.common.buttonCaptionCancel}
            className={classes.buttonText}
            disabled={submitting}
          />

          <AccentButton
            variant="contained"
            type="submit"
            className={classes.button}
            loading={submitting}
            disableElevation
            caption={GlobalLocalizedString.common.buttonCaptionSave}
            disabled={submitting}
          />
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
);

export default reduxForm({
  form: RXFORM_WFA_EDIT_DIALOG,
  validate: rxformValidateWfa,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true,
})(withStyles(styles)(EditDialog));

EditDialog.propTypes = {
  submitting: PropTypes.bool.isRequired,
  visibility: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
