import { transformSearchText, downloadHolidays } from '../../../helper';
import { downloadingHolidays, setHolidays } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, holidays, uiHoliday } = getState();
  const { token } = authentication;
  const { meta } = holidays;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiHoliday;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingHolidays(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadHolidays(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setHolidays(list));
  } finally {
    dispatch(downloadingHolidays(false));
  }
};
