import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_CONFIG_GROUPS, MENUID_CONFIGURATION_CONFIGGROUP, PAGE_MODE_TABLE,
  RXFORM_CONFIG_GROUP,
} from '../../constant';
import { transformInitialValues } from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearConfigGroups, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setConfigGroupSearchText, setConfigGroupSelectedPageSize, setConfigGroupSelectedOrderBy,
  setConfigGroupTappedId,
  addEditConfigGroupAsync, downloadDeleteConfigGroupAsync, downloadConfigGroupsAsync,
} from '../../redux/action';
import ConfigGroupPage from './config-group.presentation';

const getInitialValues = (state) => {
  const { configGroups, uiFunctionalPage, uiConfigGroup } = state;
  const { data } = configGroups;
  const { downloadingDeleting, tappedId } = uiConfigGroup;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found)
    : {
      name: '', description: '', order: 0, tag: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiConfigGroup.addingEditing,
  downloading: state.uiConfigGroup.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CONFIGURATION_CONFIGGROUP));
    dispatch(setConfigGroupSearchText(''));
    dispatch(clearConfigGroups());
    dispatch(setConfigGroupSelectedPageSize(20));
    dispatch(setConfigGroupSelectedOrderBy(INITIAL_ORDER_BY_CONFIG_GROUPS));
    dispatch(downloadConfigGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CONFIG_GROUP));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadConfigGroupsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setConfigGroupSelectedPageSize(pageSize));
    dispatch(downloadConfigGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteConfigGroupAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setConfigGroupTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setConfigGroupTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setConfigGroupTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setConfigGroupSelectedPageSize(pageSize));
    dispatch(clearConfigGroups());
    dispatch(downloadConfigGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    order, name, tag, description,
  }) => {
    try {
      await dispatch(addEditConfigGroupAsync(parseInt(order, 10), name, tag, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setConfigGroupSearchText(text));
      dispatch(clearConfigGroups());
      await dispatch(downloadConfigGroupsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setConfigGroupSelectedOrderBy(orderBy));
    dispatch(clearConfigGroups());
    dispatch(downloadConfigGroupsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    order, name, tag, description,
  }) => {
    await dispatch(addEditConfigGroupAsync(parseInt(order, 10), name, tag, description));
  },
  onViewPressed: (id) => {
    dispatch(setConfigGroupTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigGroupPage);
