import { connect } from 'react-redux';

import { change, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import TemplatePage from './template.presentation';
import {
  addEditDocumentGeneratorTemplateAsync, deleteDocumentGeneratorTemplateAsync,
  clearDocumentGeneratorTemplates, downloadDocumentGeneratorTemplateAsync,
  downloadDocumentGeneratorTemplatesAsync, enableDisableDocumentGeneratorTemplateAsync,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setDocumentGeneratorTemplateSearchText, setDocumentGeneratorTemplateSelectedOrderBy,
  setDocumentGeneratorTemplateSelectedPageSize, setDocumentGeneratorTemplateTappedId,
  generateDocumentTemplatePreviewAsync, setTemplatePaperSizeFieldValue,
  downloadTemplatePaperSizesAsync, lockUnlockDocumentGeneratorTemplateAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE, MENUID_DOCUMENT_GENERATOR_TEMPLATE,
  PAGE_MODE_TABLE, RXFORM_FIELD_TEMPLATE_PAPER_SIZE, RXFORM_TEMPLATE,
  RXFORM_FIELD_TEMPLATE_HEADER_DRAW_DIVIDER_LINE,
  RXFORM_FIELD_TEMPLATE_FOOTER_DRAW_DIVIDER_LINE,
  PAGE_MODE_EDIT, approval,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const { templates, uiTemplate, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiTemplate;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? templates.data[tappedId] : {};

  const headerDrawDividerLine = () => {
    if (typeof found.headerDrawDividerLine === 'boolean') {
      return found.headerDrawDividerLine ? approval[0] : approval[1];
    }
    if (pageMode === PAGE_MODE_EDIT) {
      return approval[1];
    }
    return '';
  };

  const footerDrawDividerLine = () => {
    if (typeof found.footerDrawDividerLine === 'boolean') {
      return found.footerDrawDividerLine ? approval[0] : approval[1];
    }
    if (pageMode === PAGE_MODE_EDIT) {
      return approval[1];
    }
    return '';
  };

  const initVal = Object.keys(found || {}).length > 0
    ? transformInitialValues(found, {
      pageSize: found?.pageSize || '',
      marginTop: found?.marginTop || (pageMode === PAGE_MODE_EDIT ? 25 : ''),
      marginLeft: found?.marginLeft || (pageMode === PAGE_MODE_EDIT ? 25 : ''),
      marginRight: found?.marginRight || (pageMode === PAGE_MODE_EDIT ? 25 : ''),
      marginBottom: found?.marginBottom || (pageMode === PAGE_MODE_EDIT ? 25 : ''),
      headerMaxHeight: found?.headerMaxHeight || (pageMode === PAGE_MODE_EDIT ? 100 : ''),
      footerMaxHeight: found?.footerMaxHeight || (pageMode === PAGE_MODE_EDIT ? 100 : ''),
      headerDrawDividerLine: headerDrawDividerLine(),
      footerDrawDividerLine: footerDrawDividerLine(),
    })
    : {
      name: '',
      description: '',
      body: '',
      pageSize: '',
      marginTop: 25,
      marginLeft: 25,
      marginRight: 25,
      marginBottom: 25,
      headerMaxHeight: 100,
      footerMaxHeight: 100,
      headerDrawDividerLine: approval[1],
      footerDrawDividerLine: approval[1],
    };
  return initVal;
};

const getPaperSizes = (state) => {
  const { templatePaperSizes } = state;
  const { data } = templatePaperSizes;
  if (!isEmpty(data)) {
    const newData = Object.values(data).map((x) => {
      const name = x?.name || '';
      const description = x?.description || '';
      const label = `${name} (${description})`;
      return ({ label, value: name });
    });

    return newData;
  }
  return [];
};

const getIsRowLocked = (state) => {
  const { templates, uiFunctionalPage } = state;
  const { tappedId } = uiFunctionalPage;
  const { data: allData } = templates;
  const data = allData[tappedId];
  const locked = data?.locked || false;
  return locked;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  downloading: state.uiTemplate.downloading,
  downloadingDeleting: state.uiTemplate.downloadingDeleting,
  enablingDisabling: state.uiTemplate.enablingDisabling,
  lockingUnlocking: state.uiTemplate.lockingUnlocking,
  addingEditing: state.uiTemplate.addingEditing,
  pageMode: state.uiFunctionalPage.pageMode,
  templateStatus: state.templates.data[state.uiFunctionalPage.tappedId]
    ? state.templates.data[state.uiFunctionalPage.tappedId].status : '',
  isRowLocked: getIsRowLocked(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  generatingPreview: state.uiTemplate.generatingPreview,
  paperSizes: getPaperSizes(state),
  downloadingPaperSizes: state.uiTemplate.downloadingPaperSizes,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_DOCUMENT_GENERATOR_TEMPLATE));
    dispatch(setDocumentGeneratorTemplateSearchText(''));
    dispatch(clearDocumentGeneratorTemplates());
    dispatch(setDocumentGeneratorTemplateSelectedPageSize(20));
    dispatch(setDocumentGeneratorTemplateSelectedOrderBy(
      INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE,
    ));
    dispatch(downloadDocumentGeneratorTemplatesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setDocumentGeneratorTemplateSelectedPageSize(pageSize));
    dispatch(clearDocumentGeneratorTemplates());
    dispatch(downloadDocumentGeneratorTemplatesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setDocumentGeneratorTemplateSearchText(text));
    dispatch(clearDocumentGeneratorTemplates());
    dispatch(downloadDocumentGeneratorTemplatesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setDocumentGeneratorTemplateSelectedOrderBy(orderBy));
    dispatch(clearDocumentGeneratorTemplates());
    dispatch(downloadDocumentGeneratorTemplatesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDocumentGeneratorTemplateSelectedPageSize(pageSize));
    dispatch(downloadDocumentGeneratorTemplatesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDocumentGeneratorTemplatesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_TEMPLATE));
  },
  onViewPressed: (id) => {
    dispatch(setDocumentGeneratorTemplateTappedId(id));
    dispatch(downloadDocumentGeneratorTemplateAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(downloadTemplatePaperSizesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setDocumentGeneratorTemplateTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(deleteDocumentGeneratorTemplateAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
      case GlobalLocalizedString.common.msgLockConfirmation:
      case GlobalLocalizedString.common.msgUnlockConfirmation:
        dispatch(lockUnlockDocumentGeneratorTemplateAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
      default:
        dispatch(enableDisableDocumentGeneratorTemplateAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
    }
  },
  onEditPressed: (id) => {
    dispatch(setDocumentGeneratorTemplateTappedId(id));
    dispatch(downloadDocumentGeneratorTemplateAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(downloadTemplatePaperSizesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: ({
    body, description, name, header, footer, samplePayload, pageSize,
    marginTop, marginRight, marginLeft, marginBottom,
    headerMaxHeight, footerMaxHeight, headerDrawDividerLine,
    footerDrawDividerLine,
  }) => {
    try {
      dispatch(addEditDocumentGeneratorTemplateAsync(
        name, description, body, header, footer, samplePayload, pageSize,
        marginTop, marginRight, marginLeft, marginBottom,
        headerMaxHeight, footerMaxHeight, headerDrawDividerLine,
        footerDrawDividerLine,
      ))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSubmitPressed: ({
    body, description, name, header, footer, samplePayload, pageSize,
    marginTop, marginRight, marginLeft, marginBottom,
    headerMaxHeight, footerMaxHeight, headerDrawDividerLine,
    footerDrawDividerLine,
  }) => {
    try {
      dispatch(addEditDocumentGeneratorTemplateAsync(
        name, description, body, header, footer, samplePayload, pageSize,
        marginTop, marginRight, marginLeft, marginBottom,
        headerMaxHeight, footerMaxHeight, headerDrawDividerLine,
        footerDrawDividerLine,
      ))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCreatePressed: () => {
    dispatch(setDocumentGeneratorTemplateTappedId(''));

    dispatch(downloadTemplatePaperSizesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setDocumentGeneratorTemplateTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onPreviewClicked: () => {
    dispatch(generateDocumentTemplatePreviewAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onPaperSizeOptionSelected: (option) => {
    if (option) {
      dispatch(setTemplatePaperSizeFieldValue(option.value));
      dispatch(change(RXFORM_TEMPLATE, RXFORM_FIELD_TEMPLATE_PAPER_SIZE,
        option));
    } else {
      dispatch(setTemplatePaperSizeFieldValue(''));
      dispatch(change(RXFORM_TEMPLATE, RXFORM_FIELD_TEMPLATE_PAPER_SIZE, ''));
    }
  },
  onHeaderDrawDividerLineOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_TEMPLATE, RXFORM_FIELD_TEMPLATE_HEADER_DRAW_DIVIDER_LINE,
        option));
    } else {
      dispatch(change(RXFORM_TEMPLATE, RXFORM_FIELD_TEMPLATE_PAPER_SIZE, null));
    }
  },
  onFooterDrawDividerLineOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_TEMPLATE, RXFORM_FIELD_TEMPLATE_FOOTER_DRAW_DIVIDER_LINE,
        option));
    } else {
      dispatch(change(RXFORM_TEMPLATE, RXFORM_FIELD_TEMPLATE_PAPER_SIZE, null));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
