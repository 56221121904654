import { INITIAL_ORDER_BY_HOLIDAYS } from '../../constant';
import {
  DOWNLOADING_HOLIDAY, DOWNLOADING_HOLIDAYS, SET_HOLIDAY_SELECTED_PAGE_SIZE,
  SET_HOLIDAY_TAPPED_ID, SET_HOLIDAY_SEARCH_TEXT, SET_HOLIDAY_SELECTED_ORDER_BY,
  SET_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  ADDING_HOLIDAYS, SET_HOLIDAY_SELECTED_FILE,
  SET_HOLIDAY_SELECTED_YEAR,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  addingEditing: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  selectedFile: '',
  selectedYear: '',
  orderBy: INITIAL_ORDER_BY_HOLIDAYS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_HOLIDAYS:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_HOLIDAYS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_HOLIDAY:
      return { ...state, downloadingDeleting: action.status };
    case SET_HOLIDAY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_HOLIDAY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_HOLIDAY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_HOLIDAY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_HOLIDAY_SELECTED_FILE:
      return { ...state, selectedFile: action.text };
    case SET_HOLIDAY_SELECTED_YEAR:
      return { ...state, selectedYear: action.text };
    default:
      return state;
  }
};
