import {
  addBanner, clearBanners, downloadingDeletingBanner, setAlertErrorMessage,
  setBannerSelectedClickAction, setBannerShortDescriptionLength, setBannerTappedId,
  setFunctionalPageMode, setBannerSelectedCompany,
} from '../simple-action';
import { downloadBanner, deleteBanner } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadBannersAsync from './downloadBannersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingBanner(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiBanner;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteBanner(tappedId, token);
      dispatch(setBannerTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearBanners());
      dispatch(downloadBannersAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      dispatch(setBannerShortDescriptionLength(0));

      const banner = await downloadBanner(tappedId, token);
      dispatch(addBanner(banner));
      dispatch(setBannerSelectedCompany({ label: banner.company?.name || '', value: banner.company?.id || '' }));
      dispatch(setBannerSelectedClickAction(banner.clickAction));
      if (banner.shortDescription) {
        dispatch(setBannerShortDescriptionLength(banner.shortDescription.length));
      }
    }
  } finally {
    dispatch(downloadingDeletingBanner(false));
  }
};
