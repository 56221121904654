import { downloadingOsNames, setOsNames } from '../simple-action';
import { downloadOsNames, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { osNameSearchText } = getState().uiToken;

  try {
    dispatch(downloadingOsNames(true));

    const list = await downloadOsNames(
      transformSearchText(osNameSearchText),
      token,
    );

    if (list) {
      dispatch(setOsNames(list));
    }
  } finally {
    dispatch(downloadingOsNames(false));
  }
};
