import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_SERVICE_REQUESTS = '/carservice/api/v1/ServiceBooking?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|dealer.name&filterString=';
export const REST_URL_VIEW_DELETE_SERVICE_REQUEST = '/carservice/api/v1/ServiceBooking/{id}';
export const REST_URL_DEALERS = '/carservice/api/v1/Dealer?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';

export const ROUTE_NAME_CAR_SERVICE = '/car-service';
export const ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST = '/service-request';

export const RXFORM_SERVICE_REQUEST = 'serviceRequestPage';

export const RXFIELD_SERVICE_REQUEST_USER_ID = 'id';
export const RXFIELD_SERVICE_REQUEST_USER_NAME = 'fullName';
export const RXFIELD_SERVICE_REQUEST_DEALER_ID = 'id';
export const RXFIELD_SERVICE_REQUEST_DEALER_NAME = 'name';
export const RXFIELD_SERVICE_REQUEST_REQUESTED_DATE = 'serviceDate';
export const RXFIELD_SERVICE_REQUEST_REPAIR_TYPE = 'repairType';

export const RXSTATE_SERVICE_REQUESTS = 'serviceRequests';
export const RXSTATE_SERVICE_REQUEST_PAGE = 'uiServiceRequest';

export const INITIAL_ORDER_BY_SERVICE_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_DEALERS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_CAR_SERVICE = 'MENUID_CAR_SERVICE';
export const MENUID_CAR_SERVICE_SERVICE_REQUEST = 'MENUID_CAR_SERVICE_SERVICE_REQUEST';
