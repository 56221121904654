import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    gameQRMenu: {
      title: 'Game QR',
    },
    gameQRCategoryPage: {
      title: 'Game QR Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      placeholderImage: 'Image',

      placeholderStatus: 'Status',
      placeholderName: 'Name',
      placeholderDescription: 'Description',

      buttonCaptionCreateNewGameQRCategory: 'Create Game QR Category',
      buttonCaptionDeleteGameQRCategory: 'Delete Game QR Category',
      buttonCaptionEditGameQRCategory: 'Edit Game QR Category',
    },
    challengePage: {
      title: 'Challenge',

      labelNo: 'No',
      labelTitle: 'Title',
      labelCategory: 'Category',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelMedia: 'Media',
      labelOrder: 'Order',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelCodes: 'Codes',
      labelCode: 'Code',
      labelPoints: 'Points',
      labelLabel: 'Label',
      labelSequential: 'Sequential',
      labelRandom: 'Random',

      buttonCaptionCreateNewChallenge: 'Create New Challenge',
      buttonCaptionEditChallenge: 'Edit Challenge',
      buttonCaptionDeleteChallenge: 'Delete Challenge',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',

      placeholderTitle: 'Title',
      placeholderContent: 'Content',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderCategory: 'Category',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderReactionId: 'Reaction ID',
      placeholderTags: 'Tags',
      placeholderScanPolicy: 'Scan Policy',
      placeholderPromoted: 'Promoted',
      placeholderOrder: 'Order',
    },
  },
  id: {
    gameQRMenu: {
      title: 'QR Permainan',
    },
    gameQRCategoryPage: {
      title: 'Kategori QR Permainan',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      placeholderStatus: 'Status',
      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderImage: 'Gambar',

      buttonCaptionCreateNewGameQRCategory: 'Buat Kategori QR Permainan',
      buttonCaptionDeleteGameQRCategory: 'Hapus Kategori QR Permainan',
      buttonCaptionEditGameQRCategory: 'Ubah Kategori QR Permainan',
    },
    challengePage: {
      title: 'Tantangan',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelCategory: 'Kategori',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelMedia: 'Media',
      labelOrder: 'Urutan',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelCodes: 'Kode',
      labelCode: 'Kode',
      labelPoints: 'Poin',
      labelLabel: 'Label',
      labelSequential: 'Berurutan',
      labelRandom: 'Acak',

      buttonCaptionCreateNewChallenge: 'Buat Tantangan Baru',
      buttonCaptionEditChallenge: 'Ubah Tantangan',
      buttonCaptionDeleteChallenge: 'Hapus Tantangan',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',

      placeholderTitle: 'Judul',
      placeholderContent: 'Konten',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderCategory: 'Kategori',
      placeholderVisibilityStatus: 'Status Terlihat',
      placeholderReactionId: 'ID Reaksi',
      placeholderTags: 'Tag',
      placeholderScanPolicy: 'Kebijakan Pemindaian',
      placeholderPromoted: 'Dipromosikan',
      placeholderOrder: 'Urutan',
    },
  },
});

export default LocalizedString;
