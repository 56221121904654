import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_PUSH_NOTIFICATIONS, MENUID_NOTIFICATION_PUSH_NOTIFICATION,
  PAGE_MODE_TABLE, RXFORM_PUSH_NOTIFICATION,
} from '../../constant';
import { transformInitialValues } from '../../../../helper';
import LocalizedString from '../../localization';
import {
  clearPushNotifications, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setPushNotificationSearchText,
  setPushNotificationSelectedPageSize, setPushNotificationSelectedOrderBy,
  setPushNotificationTappedId,
  downloadPushNotificationsAsync, downloadPushNotificationAsync, resendPushNotificationAsync,
} from '../../redux/action';
import PushNotificationPage from './push-notification.presentation';

const getInitialValues = (state) => {
  const { pushNotifications, uiFunctionalPage, uiPushNotification } = state;
  const { data } = pushNotifications;
  const { downloadingDeleting, tappedId } = uiPushNotification;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    body: '',
    format: '',
    processingStatus: '',
    notes: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiPushNotification.downloading,
  downloadingDeleting: state.uiPushNotification.downloadingDeleting,
  resending: state.uiPushNotification.resending,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NOTIFICATION_PUSH_NOTIFICATION));
    dispatch(setPushNotificationSearchText(''));
    dispatch(clearPushNotifications());
    dispatch(setPushNotificationSelectedPageSize(20));
    dispatch(setPushNotificationSelectedOrderBy(INITIAL_ORDER_BY_PUSH_NOTIFICATIONS));
    dispatch(downloadPushNotificationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearPushNotifications());
    dispatch(downloadPushNotificationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_PUSH_NOTIFICATION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPushNotificationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPushNotificationSelectedPageSize(pageSize));
    dispatch(downloadPushNotificationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmResendPressed: () => {
    dispatch(resendPushNotificationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setPushNotificationSelectedPageSize(pageSize));
    dispatch(clearPushNotifications());
    dispatch(downloadPushNotificationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResendPressed: (id) => {
    dispatch(setPushNotificationTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.notificationMenu.msgResendConfirmation));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPushNotifications());
    dispatch(setPushNotificationSelectedOrderBy(INITIAL_ORDER_BY_PUSH_NOTIFICATIONS));
    dispatch(downloadPushNotificationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPushNotificationSearchText(text));
      dispatch(clearPushNotifications());
      await dispatch(downloadPushNotificationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPushNotificationSelectedOrderBy(orderBy));
    dispatch(clearPushNotifications());
    dispatch(downloadPushNotificationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setPushNotificationTappedId(id));
    dispatch(downloadPushNotificationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationPage);
