import { SET_CITIES, ADD_CITY, CLEAR_CITIES } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CITIES: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_CITY: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_CITIES:
      return initialState;
    default:
      return state;
  }
};
