import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    carCatalogMenu: {
      title: 'Car Catalog',
    },
    modelPage: {
      title: 'Model',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderBrochureUrl: 'Brochure URL',
      placeholderImage: 'Image',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      labelBrochureUrl: 'Brochure URL',

      buttonCaptionCreateNewModel: 'Create New Model',
      buttonCaptionDeleteModel: 'Delete Model',
      buttonCaptionEditModel: 'Edit Model',
    },
    catalogPage: {
      title: 'Catalog',

      placeholderTrim: 'Trim',
      placeholderModel: 'Model',
      placeholderColor: 'Color',
      placeholderYear: 'Year',
      placeholderIsDefault: 'Default',
      placeholderDescription: 'Description',

      labelNo: 'No',
      labelTrim: 'Trim',
      labelModel: 'Model',
      labelColor: 'Color',
      labelYear: 'Year',
      labelMedia: 'Media',
      labelOrder: 'Order',
      labelYoutube: 'Youtube',
      labelImage: 'Image',

      buttonCaptionCreateNewCar: 'Create New Car',
      buttonCaptionDeleteCar: 'Delete Car',
      buttonCaptionEditCar: 'Edit Car',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',
    },
  },
  id: {
    carCatalogMenu: {
      title: 'Katalog Mobil',
    },
    modelPage: {
      title: 'Model',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderBrochureUrl: 'URL Brosur',
      placeholderImage: 'Gambar',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',
      labelBrochureUrl: 'URL Brosur',

      buttonCaptionCreateNewModel: 'Buat Model Baru',
      buttonCaptionDeleteModel: 'Hapus Model',
      buttonCaptionEditModel: 'Ubah Model',
    },
    catalogPage: {
      title: 'Katalog',

      placeholderTrim: 'Lis',
      placeholderModel: 'Model',
      placeholderColor: 'Warna',
      placeholderYear: 'Tahun',
      placeholderIsDefault: 'Default',
      placeholderDescription: 'Deskripsi',

      labelNo: 'No',
      labelTrim: 'Lis',
      labelModel: 'Model',
      labelColor: 'Warna',
      labelYear: 'Tahun',
      labelMedia: 'Media',
      labelOrder: 'Urutan',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',

      buttonCaptionCreateNewCar: 'Buat Mobil Baru',
      buttonCaptionDeleteCar: 'Hapus Mobil',
      buttonCaptionEditCar: 'Ubah Mobil',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',
    },
  },
});

export default LocalizedString;
