import {
  addConfigItem, downloadingDeletingConfigItem,
  setConfigItemSelectedConfigGroup, setConfigItemSelectedDataType,
  setConfigItemSelectedMultiline, setConfigItemSelectedUsedOn,
} from '../simple-action';
import { downloadConfigItem } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingConfigItem(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiConfigItem;

    const result = await downloadConfigItem(tappedId, token);

    dispatch(addConfigItem(result));
    dispatch(setConfigItemSelectedConfigGroup(result?.configGroup?.id || ''));
    dispatch(setConfigItemSelectedDataType(result?.dataType || ''));
    dispatch(setConfigItemSelectedUsedOn(result?.usedOn || ''));
    dispatch(setConfigItemSelectedMultiline(result.multiLine ? { label: result.multiLine.toString(), value: result.multiLine.toString() } : ''));
  } finally {
    dispatch(downloadingDeletingConfigItem(false));
  }
};
