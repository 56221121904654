import {
  DOWNLOADING_BANNERS, ADDING_EDITING_BANNER, DOWNLOADING_DELETING_BANNER,
  SET_BANNER_SELECTED_PAGE_SIZE, SET_BANNER_TAPPED_ID, SET_BANNER_SEARCH_TEXT,
  SET_BANNER_SELECTED_ORDER_BY, SET_BANNER_SELECTED_CLICK_ACTION,
  SET_BANNER_SELECTED_ADD_MEDIA_MENU, SET_BANNER_SHORT_DESCRIPTION_LENGTH,
  SET_BANNER_SELECTED_COMPANY,
} from '../action';
import { CLICK_ACTION_NONE, INITIAL_ORDER_BY_BANNERS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  orderBy: INITIAL_ORDER_BY_BANNERS,
  searchBarText: '',
  selectedAddMediaMenu: '',
  selectedClickAction: CLICK_ACTION_NONE,
  selectedPageSize: 20,
  shortDescriptionLength: 0,
  selectedCompany: null,
  tappedId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_BANNERS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_BANNER:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_BANNER:
      return { ...state, downloadingDeleting: action.status };
    case SET_BANNER_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_BANNER_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_BANNER_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_BANNER_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_BANNER_SELECTED_CLICK_ACTION:
      return { ...state, selectedClickAction: action.option };
    case SET_BANNER_SELECTED_ADD_MEDIA_MENU:
      return { ...state, selectedAddMediaMenu: action.text };
    case SET_BANNER_SHORT_DESCRIPTION_LENGTH:
      return { ...state, shortDescriptionLength: action.number };
    case SET_BANNER_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.value };
    default: return state;
  }
};
