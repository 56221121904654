import { reset } from 'redux-form';
import {
  addingEditingPartnerMerchant, clearPartnerMerchants, setAlertErrorMessage,
  setFunctionalPageMode, setPartnerMerchantSelectedCompany,
} from '../simple-action';
import { addPartnerMerchant, editPartnerMerchant } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_PARTNER_MERCHANT } from '../../../constant';
import downloadPartnerMerchantsAsync from './downloadPartnerMerchantsAsync';

export default (description, shortName, tagLine, termsAndConditions) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingPartnerMerchant(true));

    const { token } = getState().authentication;
    const { tappedId, selectedCompany } = getState().uiPartnerMerchant;
    const { data } = getState().partnerMerchants;
    const found = data[tappedId];
    const companyId = selectedCompany ? selectedCompany.value : found.company.id;

    if (tappedId) {
      await editPartnerMerchant(tappedId, description, shortName, tagLine, termsAndConditions,
        companyId, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addPartnerMerchant(description, shortName, tagLine, termsAndConditions, companyId,
        token);
    }

    dispatch(reset(RXFORM_PARTNER_MERCHANT));
    dispatch(clearPartnerMerchants());
    dispatch(downloadPartnerMerchantsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setPartnerMerchantSelectedCompany(null));
  } finally {
    dispatch(addingEditingPartnerMerchant(false));
  }
};
