import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_ACTIVITY_TYPE_DESCRIPTION,
  RXFIELD_ACTIVITY_TYPE_NAME,
  RXFIELD_CUSTOMER_INDUSTRY, RXFIELD_CUSTOMER_NAME,
  RXFIELD_INDUSTRY_DESCRIPTION, RXFIELD_INDUSTRY_NAME,
} from './constant';

export const rxformValidateIndustry = (values) => {
  const requiredFields = [
    RXFIELD_INDUSTRY_NAME,
    RXFIELD_INDUSTRY_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCustomer = (values) => {
  const requiredFields = [
    RXFIELD_CUSTOMER_NAME,
    RXFIELD_CUSTOMER_INDUSTRY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateActivityType = (values) => {
  const requiredFields = [
    RXFIELD_ACTIVITY_TYPE_NAME,
    RXFIELD_ACTIVITY_TYPE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
