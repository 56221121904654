import {
  DOWNLOADING_DOCUMENT_GENERATOR_TEMPLATES, SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_PAGE_SIZE,
  SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_ORDER_BY, SET_DOCUMENT_GENERATOR_TEMPLATE_TAPPED_ID,
  SET_DOCUMENT_GENERATOR_TEMPLATE_SEARCH_TEXT, DOWNLOADING_DELETING_DOCUMENT_GENERATOR_TEMPLATE,
  ENABLING_DISABLING_DOCUMENT_GENERATOR_TEMPLATE, ADDING_EDITING_DOCUMENT_GENERATOR_TEMPLATE,
  LOCKING_UNLOCKING_DOCUMENT_GENERATOR_TEMPLATE, GENERATING_DOCUMENT_TEMPLATE_PREVIEW,
  DOWNLOADING_TEMPLATE_PAPER_SIZES,
} from '../action';
import { INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE } from '../../constant';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  enablingDisabling: false,
  addingEditing: false,
  lockingUnlocking: false,
  generatingPreview: false,
  downloadingPaperSizes: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_DOCUMENT_GENERATOR_TEMPLATES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_DOCUMENT_GENERATOR_TEMPLATE:
      return { ...state, downloadingDeleting: action.status };
    case ENABLING_DISABLING_DOCUMENT_GENERATOR_TEMPLATE:
      return { ...state, enablingDisabling: action.status };
    case ADDING_EDITING_DOCUMENT_GENERATOR_TEMPLATE:
      return { ...state, addingEditing: action.status };
    case LOCKING_UNLOCKING_DOCUMENT_GENERATOR_TEMPLATE:
      return { ...state, lockingUnlocking: action.status };
    case SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_DOCUMENT_GENERATOR_TEMPLATE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_DOCUMENT_GENERATOR_TEMPLATE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case GENERATING_DOCUMENT_TEMPLATE_PREVIEW:
      return { ...state, generatingPreview: action.status };
    case DOWNLOADING_TEMPLATE_PAPER_SIZES:
      return { ...state, downloadingPaperSizes: action.status };
    default: return state;
  }
};
