import {
  resyncingSubdistrict, setAlertErrorMessage, setSubdistrictSelectedOrderBy,
  setSubdistrictSelectedPageSize,
} from '../simple-action';
import { resyncMasterDataIndividually } from '../../../helper';
import { INITIAL_ORDER_BY_SUBDISTRICTS, MASTER_DATA_ENTITY_NAME_SUBDISTRICT } from '../../../constant';
import downloadSubdistrictsAsync from './downloadSubdistrictsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingSubdistrict(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSubdistrict;

    await resyncMasterDataIndividually(MASTER_DATA_ENTITY_NAME_SUBDISTRICT, tappedId, token);

    dispatch(setSubdistrictSelectedPageSize(20));
    dispatch(setSubdistrictSelectedOrderBy(INITIAL_ORDER_BY_SUBDISTRICTS));
    dispatch(downloadSubdistrictsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(resyncingSubdistrict(false));
  }
};
