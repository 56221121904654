import { reset } from 'redux-form';
import {
  addingEditingVoucher, clearVouchers, setAlertErrorMessage, setFunctionalPageMode,
  setVoucherSelectedApplicableBranches, setVoucherSelectedMerchantId,
  setVoucherSelectedVoucherCategoryId, setVoucherTableDataApplicableBranches,
} from '../simple-action';
import {
  addVoucher, editVoucher, transformLocalImagePath, toNumber,
} from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_VOUCHER, VOUCHER_TYPE_PUBLIC } from '../../../constant';
import downloadVouchersAsync from './downloadVouchersAsync';

const transformApplicableBranches = (data) => data.map((x) => (x.value));

export default (title, code, type, point, maxPurchasePerUser, minAchievementPoinToPurchase,
  maxAchievementPoinToPurchase, actionType, visibility, applicableAreaMode, validFrom, validUntil,
  image, description, termsAndConditions, status,
  order, tags) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingVoucher(true));

    const { token } = getState().authentication;
    const {
      selectedMerchantId, selectedVoucherCategoryId, tableDataApplicableBranches, tappedId,
      selectedIsPromoted,
    } = getState().uiVoucher;
    const { data } = getState().vouchers;

    const found = data[tappedId];
    const merchantId = selectedMerchantId || found.merchant.id;
    const categoryId = selectedVoucherCategoryId || found.category.id;
    const clearImage = !image;
    const applicableBranchesIds = tableDataApplicableBranches.length > 0
      ? transformApplicableBranches(tableDataApplicableBranches) : [];
    const pointByType = type === VOUCHER_TYPE_PUBLIC ? 0 : point;
    const isPromoted = selectedIsPromoted ? selectedIsPromoted.value : found.isPromoted;
    const newOrderValue = isPromoted && order ? toNumber(order) : 0;

    if (tappedId) {
      await editVoucher(tappedId, title, code, type, pointByType, maxPurchasePerUser,
        minAchievementPoinToPurchase, maxAchievementPoinToPurchase, actionType, visibility,
        categoryId, merchantId, applicableAreaMode, applicableBranchesIds, validFrom, validUntil,
        transformLocalImagePath(image), clearImage, description, termsAndConditions, status,
        isPromoted, newOrderValue, tags, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addVoucher(title, code, type, pointByType, maxPurchasePerUser,
        minAchievementPoinToPurchase, maxAchievementPoinToPurchase, actionType, visibility,
        categoryId, merchantId, applicableAreaMode, applicableBranchesIds, validFrom, validUntil,
        transformLocalImagePath(image), description, termsAndConditions, status,
        isPromoted, newOrderValue, tags, token);
    }

    dispatch(reset(RXFORM_VOUCHER));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setVoucherSelectedMerchantId(''));
    dispatch(setVoucherSelectedVoucherCategoryId(''));
    dispatch(setVoucherSelectedApplicableBranches(null));
    dispatch(setVoucherTableDataApplicableBranches([]));
  } finally {
    dispatch(addingEditingVoucher(false));
  }
};
