import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_ATTENDANCE_CORRECTIONS, MENUID_ATTENDANCE_ADDON_ATTENDANCE_CORRECTION,
  PAGE_MODE_TABLE, RXFORM_ATTENDANCE_CORRECTION, TIME_MODE_CLOCK_IN, TIME_MODE_CLOCK_OUT,
} from '../../constant';
import {
  convertClockInOutHoursToMinutes, debounceSearch, isDecimal, transformDropdownData,
  transformInitialValues, transformUserDropdownData,
} from '../../../../helper';
import {
  clearAttendanceCorrections, clearProfiles, downloadAttendanceCorrectionAsync,
  downloadAttendanceCorrectionsAsync, setActiveSideMenuItem,
  setAttendanceCorrectionAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setAttendanceCorrectionSearchText, setAttendanceCorrectionSelectedOrderBy,
  setAttendanceCorrectionSelectedPageSize, setAttendanceCorrectionTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import { clearEmployees, downloadEmployeesAsync, setHistoryEmployeeSearchText } from '../../../attendance/redux/action';
import AttendanceCorrectionPage from './attendance-correction.presentation';

const getInitialValues = (state) => {
  const { attendanceCorrections, uiFunctionalPage, uiAttendanceCorrection } = state;
  const { data } = attendanceCorrections;
  const { downloadingDeleting, tappedId } = uiAttendanceCorrection;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    attendance: {
      ...found.attendance,
      startWorkingHourTimeZone: found.attendance.startWorkingHourTimeZone !== null ? found.attendance.startWorkingHourTimeZone : '',
      earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
        found.attendance.earliestClockInDriftTimeMinutes, found.attendance.startWorkingHour,
        TIME_MODE_CLOCK_IN,
      ),
      autoClockOutTime: convertClockInOutHoursToMinutes(
        found.attendance.autoClockOutTime, found.attendance.endWorkingHour, TIME_MODE_CLOCK_OUT,
      ),
    },
    clockInCenter: found.attendance.clockInUserReportedLatitude
    && found.attendance.clockInUserReportedLongitude
    && isDecimal(found.attendance.clockInUserReportedLatitude)
    && isDecimal(found.attendance.clockInUserReportedLongitude) ? {
        lat: found.attendance.clockInUserReportedLatitude,
        lng: found.attendance.clockInUserReportedLongitude,
      } : null,
    clockInMarker: found.attendance.clockInUserReportedLatitude
    && found.attendance.clockInUserReportedLongitude
    && isDecimal(found.attendance.clockInUserReportedLatitude)
    && isDecimal(found.attendance.clockInUserReportedLongitude) ? {
        lat: found.attendance.clockInUserReportedLatitude,
        lng: found.attendance.clockInUserReportedLongitude,
      } : null,
    clockOutCenter: found.attendance.clockOutUserReportedLatitude
    && found.attendance.clockOutUserReportedLongitude
    && isDecimal(found.attendance.clockOutUserReportedLatitude)
    && isDecimal(found.attendance.clockOutUserReportedLongitude) ? {
        lat: found.attendance.clockOutUserReportedLatitude,
        lng: found.attendance.clockOutUserReportedLongitude,
      } : null,
    clockOutMarker: found.attendance.clockOutUserReportedLatitude
    && found.attendance.clockOutUserReportedLongitude
    && isDecimal(found.attendance.clockOutUserReportedLatitude)
    && isDecimal(found.attendance.clockOutUserReportedLongitude) ? {
        lat: found.attendance.clockOutUserReportedLatitude,
        lng: found.attendance.clockOutUserReportedLongitude,
      } : null,
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  attendances: transformDropdownData(state.histories.data),
  pics: transformUserDropdownData(state.employees.data),
  requesters: transformUserDropdownData(state.profiles.data),
  downloading: state.uiAttendanceCorrection.downloading,
  loadingAttendances: state.uiHistory.downloading,
  loadingPics: state.uiHistory.downloadingEmployees,
  loadingRequesters: state.uiProfile.downloading,
});

const searchPicDebounce = debounceSearch((dispatch) => {
  dispatch(clearEmployees());
  dispatch(downloadEmployeesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const searchRequesterDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearEmployees());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setAttendanceCorrectionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_ATTENDANCE_CORRECTION));
    dispatch(setAttendanceCorrectionSearchText(''));
    dispatch(clearAttendanceCorrections());
    dispatch(setAttendanceCorrectionSelectedPageSize(20));
    dispatch(setAttendanceCorrectionSelectedOrderBy(INITIAL_ORDER_BY_ATTENDANCE_CORRECTIONS));
    dispatch(downloadAttendanceCorrectionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/attendance/, 'attendance.id').replace(/requester/, 'requester.id').replace(/pic/, 'pic.id');
    dispatch(setAttendanceCorrectionAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearAttendanceCorrections());
    dispatch(downloadAttendanceCorrectionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_ATTENDANCE_CORRECTION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAttendanceCorrectionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAttendanceCorrectionSelectedPageSize(pageSize));
    dispatch(downloadAttendanceCorrectionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePicText: async (text) => {
    dispatch(setHistoryEmployeeSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchPicDebounce(dispatch);
    }
  },
  onChangeRequesterText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchRequesterDebounce(dispatch);
    }
  },
  onDownloadAttachmentPressed: async (initialValues) => {
    try {
      const { attachment: fileUrl, id } = initialValues;
      const extension = fileUrl?.match(/(\.[^.]*)$/g)[0];
      const response = await fetch(fileUrl);
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = extension ? `attendance_corection_${id}${extension}` : `attendance_corection_${id}`;
        a.click();
      });
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setAttendanceCorrectionSelectedPageSize(pageSize));
    dispatch(clearAttendanceCorrections());
    dispatch(downloadAttendanceCorrectionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setAttendanceCorrectionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearAttendanceCorrections());
    dispatch(setAttendanceCorrectionSelectedOrderBy(INITIAL_ORDER_BY_ATTENDANCE_CORRECTIONS));
    dispatch(downloadAttendanceCorrectionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAttendanceCorrectionSearchText(text));
      dispatch(clearAttendanceCorrections());
      await dispatch(downloadAttendanceCorrectionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAttendanceCorrectionSelectedOrderBy(orderBy));
    dispatch(clearAttendanceCorrections());
    dispatch(downloadAttendanceCorrectionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setAttendanceCorrectionTappedId(id));
    dispatch(downloadAttendanceCorrectionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceCorrectionPage);
