import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearWFAQuotas,
  setActiveSideMenuItem,
  setAlertConfirmationMessage,
  setAlertErrorMessage,
  setWFAQuotaSearchText,
  setWFAQuotaSelectedPageSize,
  setWFAQuotaSelectedOrderBy,
  setWFAQuotaTappedId,
  addEditWFAQuotaAsync,
  downloadDeleteWFAQuotaAsync,
  downloadWFAQuotasAsync,
  setWFAQuotaAdvancedFilterDialogSelectedFilterString,
  clearDepartments,
  setDepartmentSearchText,
  setWFAQuotaSelectedDepartment,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_WFA_QUOTAS,
  MENUID_ATTENDANCE_ADDON_WFA_QUOTA,
  PAGE_MODE_TABLE,
  RXFIELD_WFA_QUOTA_DEPARTMENT,
  RXFORM_ADVANCED_FILTER_DIALOG,
  RXFORM_WFA_QUOTA,
} from '../../constant';
import {
  debounceSearch,
  transformDropdownData,
  transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import WFAQuotaPage from './wfa-quota.presentation';
import { downloadDepartmentsAsync } from '../../../../redux/action';

const getInitialValues = (state) => {
  const { wfaQuotas, uiWFAQuota, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiWFAQuota;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? wfaQuotas.data[tappedId]
    : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      departmentName: found?.departmentName
        ? { label: found.departmentName, value: found.departmentName }
        : '',
    })
    : {};
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiWFAQuota.addingEditing,
  downloading: state.uiWFAQuota.downloading,
  downloadingDeleting: state.uiWFAQuota.downloadingDeleting,
  initialValues: getInitialValues(state),
  departments: transformDropdownData(state.departments.data),
  loadingDepartment: state.notification.downloadingDepartments,
});

const searchDepartmentDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDepartments());
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(
      setWFAQuotaAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_WFA_QUOTA));
    dispatch(setWFAQuotaSearchText(''));
    dispatch(clearWFAQuotas());
    dispatch(setWFAQuotaSelectedPageSize(20));
    dispatch(setWFAQuotaSelectedOrderBy(INITIAL_ORDER_BY_WFA_QUOTAS));
    dispatch(downloadWFAQuotasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_WFA_QUOTA));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWFAQuotasAsync(pageNo + 1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWFAQuotaSelectedPageSize(pageSize));
    dispatch(downloadWFAQuotasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onConfirmPressed: () => {
    dispatch(downloadDeleteWFAQuotaAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setWFAQuotaTappedId(''));

    dispatch(setDepartmentSearchText(''));
    dispatch(clearDepartments());
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setWFAQuotaTappedId(id));
    dispatch(
      setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation),
    );
  },
  onEditPressed: (id) => {
    dispatch(setWFAQuotaTappedId(id));
    dispatch(downloadDeleteWFAQuotaAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });

    dispatch(setDepartmentSearchText(''));
    dispatch(clearDepartments());
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setWFAQuotaSelectedPageSize(pageSize));
    dispatch(clearWFAQuotas());
    dispatch(downloadWFAQuotasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSavePressed: async (values) => {
    try {
      await dispatch(addEditWFAQuotaAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWFAQuotaSearchText(text));
      dispatch(clearWFAQuotas());
      await dispatch(downloadWFAQuotasAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWFAQuotaSelectedOrderBy(orderBy));
    dispatch(clearWFAQuotas());
    dispatch(downloadWFAQuotasAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSubmitPressed: async (values) => {
    await dispatch(addEditWFAQuotaAsync(values));
  },
  onViewPressed: (id) => {
    dispatch(setWFAQuotaTappedId(id));
    dispatch(downloadDeleteWFAQuotaAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setDepartmentSearchText(''));
    dispatch(clearDepartments());
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(
      setWFAQuotaAdvancedFilterDialogSelectedFilterString(filterString),
    );
    dispatch(clearWFAQuotas());
    dispatch(downloadWFAQuotasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_WFA_QUOTA));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(
      setWFAQuotaAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(setWFAQuotaSelectedDepartment(''));
    dispatch(reset(RXFORM_ADVANCED_FILTER_DIALOG));
    dispatch(clearWFAQuotas());
    dispatch(downloadWFAQuotasAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangeDepartmentText: (text) => {
    dispatch(setDepartmentSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDepartmentDebounce(dispatch, true);
    }
  },
  onDepartmentOptionSelected: (option) => {
    if (option) {
      dispatch(setWFAQuotaSelectedDepartment(option.label));
      dispatch(change(
        RXFORM_WFA_QUOTA, RXFIELD_WFA_QUOTA_DEPARTMENT, option.label,
      ));
    } else {
      dispatch(setWFAQuotaSelectedDepartment(''));
      dispatch(change(
        RXFORM_WFA_QUOTA, RXFIELD_WFA_QUOTA_DEPARTMENT, '',
      ));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WFAQuotaPage);
