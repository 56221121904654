import {
  DOWNLOADING_PUSH_NOTIFICATIONS, DOWNLOADING_DELETING_PUSH_NOTIFICATION,
  RESENDING_PUSH_NOTIFICATION, SET_PUSH_NOTIFICATION_SELECTED_PAGE_SIZE,
  SET_PUSH_NOTIFICATION_TAPPED_ID, SET_PUSH_NOTIFICATION_SEARCH_TEXT,
  SET_PUSH_NOTIFICATION_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_PUSH_NOTIFICATIONS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  resending: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PUSH_NOTIFICATIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PUSH_NOTIFICATIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_PUSH_NOTIFICATION:
      return { ...state, downloadingDeleting: action.status };
    case RESENDING_PUSH_NOTIFICATION:
      return { ...state, resending: action.status };
    case SET_PUSH_NOTIFICATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PUSH_NOTIFICATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PUSH_NOTIFICATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PUSH_NOTIFICATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
