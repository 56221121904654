import { connect } from 'react-redux';
import { readFile } from '../../helper';
import DropzonePicker from './dropzone-picker.presentation';

const mapStateToProps = (state) => ({
  dialogType: state.uiFunctionalPage.dialogType,
  visibility: state.uiFunctionalPage.visibility,
});

const mapDispatchToProps = () => ({
  onFileChange: async (files, onImageSelected, onFileSelected) => {
    files.forEach(async (file) => {
      const dataUrl = await readFile(file);
      const url = dataUrl.replace(/^.+base64,/, '');
      if (onImageSelected) {
        onImageSelected(url, file.name);
      } else if (onFileSelected) {
        onFileSelected(url, file.name);
      }
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DropzonePicker);
