import moment from 'moment';
import {
  buildQueryParams, convertArrToObj, sendDeleteRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformMediaData, transformQueryResult, toMoment, sendGetFileRequest,
} from '../../helper';
import {
  REST_URL_NEWS, REST_URL_ADD_EDIT_NEWS, REST_URL_VIEW_DELETE_NEWS,
  REST_URL_SEND_NEWS_TO_ME, REST_URL_NEWS_CATEGORIES, REST_URL_ADD_EDIT_NEWS_CATEGORY,
  REST_URL_VIEW_DELETE_NEWS_CATEGORY, REST_URL_MOST_VIEWED_NEWS,
  REST_URL_DOWNLOAD_NEWS_TREND, DAY_OF_THE_WEEK, MOST_VIEWED_NEWS_FILTERS,
  REVERSED_ISO_DATE_FORMAT, DAY_NAME_FORMAT, SIMPLE_DATE_FORMAT,
  REVERSED_ISO_DATE_TIME_FORMAT, REST_URL_SAVE_FILE,
  MONTH_ONLY_FORMAT,
} from './constant';

export * from '../../helper';

export const saveNewsList = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_NEWS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadNewsList = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_NEWS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_NEWS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = transformMediaData(response.data);
  return transformQueryResult(response, transformData);
};

export const addNews = async (title, content, publishedDate, categoryId, visibilityStatus,
  media, sourceName, sourceUrl, shortDescription, isHeadline, tags, token) => {
  const body = {
    title,
    content,
    publishedDate,
    categoryId,
    visibilityStatus,
    media,
    sourceName,
    sourceUrl,
    shortDescription,
    isHeadline,
    tags,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_NEWS, body, token);
};

export const editNews = async (id, title, content, publishedDate, categoryId, visibilityStatus,
  media, sourceName, sourceUrl, shortDescription, isHeadline, tags, token) => {
  const body = {
    id,
    title,
    content,
    publishedDate,
    categoryId,
    visibilityStatus,
    media,
    sourceName,
    sourceUrl,
    shortDescription,
    isHeadline,
    tags,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_NEWS, body, token);
};

export const downloadNews = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_NEWS.replace(/\{id\}/, id), token);
  const transformData = transformMediaData(response);
  return transformData;
};

const momentUtc = (time) => {
  const offset = toMoment(time).utcOffset();
  const utcDate = moment(time)
    .subtract(offset, 'minutes')
    .format(REVERSED_ISO_DATE_TIME_FORMAT);
  return utcDate;
};

export const downloadMostViewedNews = async ({ startTime, endTime }, token) => {
  const url = REST_URL_MOST_VIEWED_NEWS
    .replace(/\{startTime\}/, momentUtc(startTime))
    .replace(/\{endTime\}/, momentUtc(endTime));
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'newsId');
};

export const deleteNews = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_NEWS.replace(/\{id\}/, id), token);
};

export const sendNewsNotification = async (id, token) => {
  await sendPostRequest(REST_URL_SEND_NEWS_TO_ME.replace(/\{id\}/, id), null, token);
};

export const downloadNewsCategories = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_NEWS_CATEGORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addNewsCategory = async (name, description, image, token) => {
  const body = { name, description, image };
  await sendPostRequest(REST_URL_ADD_EDIT_NEWS_CATEGORY, body, token);
};

export const editNewsCategory = async (id, name, description, image, token) => {
  const body = {
    id, name, description, image,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_NEWS_CATEGORY, body, token);
};

export const downloadNewsCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_NEWS_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteNewsCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_NEWS_CATEGORY.replace(/\{id\}/, id), token);
};


export const downloadNewsTrend = async (
  id, { startTime, endTime }, newsTrendPeriode, token,
) => {
  const url = REST_URL_DOWNLOAD_NEWS_TREND
    .replace(/\{id\}/, id)
    .replace(/\{startTime\}/, momentUtc(startTime))
    .replace(/\{endTime\}/, momentUtc(endTime));
  const response = await sendGetRequest(url, token);
  const newResponse = response
    .map((item) => ({
      ...item,
      name: toMoment(item.name).format(
        newsTrendPeriode === MOST_VIEWED_NEWS_FILTERS[4] ? MONTH_ONLY_FORMAT : SIMPLE_DATE_FORMAT,
      ),
    }));
  return convertArrToObj(newResponse, 'name');
};

export const getDayAmount = (dayName) => {
  if (dayName === DAY_OF_THE_WEEK[1]) return 1;
  if (dayName === DAY_OF_THE_WEEK[2]) return 2;
  if (dayName === DAY_OF_THE_WEEK[3]) return 3;
  if (dayName === DAY_OF_THE_WEEK[4]) return 4;
  if (dayName === DAY_OF_THE_WEEK[5]) return 5;
  if (dayName === DAY_OF_THE_WEEK[6]) return 6;
  return 0;
};

export const getStartEndTime = (selectedFilter) => {
  if (selectedFilter === MOST_VIEWED_NEWS_FILTERS[1]) {
    const yesterdayDate = toMoment(moment().subtract(1, 'days')).format(REVERSED_ISO_DATE_FORMAT);
    return { startTime: `${yesterdayDate}T00:00`, endTime: `${yesterdayDate}T23:59` };
  }
  if (selectedFilter === MOST_VIEWED_NEWS_FILTERS[2]) {
    const dayName = toMoment(moment().format()).format(DAY_NAME_FORMAT);
    const currentDate = toMoment(moment().format()).format(REVERSED_ISO_DATE_FORMAT);
    const startTime = toMoment(moment().subtract(getDayAmount(dayName), 'days')).format(REVERSED_ISO_DATE_FORMAT);
    return { startTime: `${startTime}T00:00`, endTime: `${currentDate}T23:59` };
  }
  if (selectedFilter === MOST_VIEWED_NEWS_FILTERS[3]) {
    const currentDate = toMoment(moment().format()).format(REVERSED_ISO_DATE_FORMAT);
    const startTime = currentDate.slice(0, 7);
    return { startTime: `${startTime}-01T00:00`, endTime: `${currentDate}T23:59` };
  }
  if (selectedFilter === MOST_VIEWED_NEWS_FILTERS[4]) {
    const currentDate = toMoment(moment().format()).format(REVERSED_ISO_DATE_FORMAT);
    const startTime = currentDate.slice(0, 4);
    return { startTime: `${startTime}-01-01T00:00`, endTime: `${currentDate}T23:59` };
  }

  const currentDate = toMoment(moment().format()).format(REVERSED_ISO_DATE_FORMAT);
  return { startTime: `${currentDate}T00:00`, endTime: `${currentDate}T23:59` };
};
