import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  clearApprovals, setActiveSideMenuItem, setAlertErrorMessage,
  setApprovalSearchText, setApprovalSelectedPageSize, setApprovalSelectedOrderBy,
  setApprovalTappedId, downloadApprovalAsync, downloadApprovalsAsync,
  setApprovalAdvancedFilterDialogSelectedFilterString,
  clearApprovalProcesses, downloadApprovalProcessesAsync,
  clearApprovalProviders, downloadApprovalProvidersAsync,
  setApprovalSelectedStepId, setDownloadRequestTimeoutDialogVisibility,
  saveApprovalAttachmentAsync, setApprovalSelectedResendEventOption,
  resendEventAsync, setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  clearProfiles, setAlertConfirmationMessage,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_APPROVALS, MENUID_APPROVAL_APPROVAL, PAGE_MODE_TABLE,
  RXFORM_APPROVAL,
  RXFIELD_APPROVAL_PROCESS_NAME,
  RXFIELD_APPROVAL_APPROVAL_NUMBER,
  RXFIELD_APPROVAL_TITLE,
  RXFIELD_APPROVAL_DESCRIPTION,
  RXFIELD_APPROVAL_NOTES,
  RXFIELD_APPROVAL_APPROVAL_STATUS,
  RXFIELD_APPROVAL_STATUS_CHANGED_AT,
  RXFIELD_APPROVAL_REQUESTER_ID,
  RXFIELD_APPROVAL_REQUESTER_NAME,
  RXFIELD_APPROVAL_REQUESTER_NOTES,
  RXFIELD_APPROVAL_REFERENCE_ID,
  RXFIELD_APPROVAL_PROVIDER_NAME,
  RXFIELD_APPROVAL_ALLOW_TO_ADD_ATTACHMENT,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_APPROVAL_STEPS,
  RXFIELD_APPROVAL_ATTACHMENTS,
  RXFIELD_APPROVAL_DATA,
  RXFIELD_APPROVAL_RESEND_EVENT,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch, toMoment, sortAsc,
} from '../../helper';
import ApprovalPage from './approval.presentation';
import LocalizedString from '../../localization';
import { downloadProfilesAsync } from '../../../../redux/action';
import { downloadMyConfigItemsAsync } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { approvals, uiApproval, uiFunctionalPage } = state;
  const { downloadingDetail, tappedId } = uiApproval;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDetail
    ? approvals.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0 ? transformInitialValues(found, {
    [RXFIELD_APPROVAL_PROCESS_NAME]: found?.processName ? found?.processName : '',
    [RXFIELD_APPROVAL_APPROVAL_NUMBER]: found?.approvalNumber ? found?.approvalNumber : '',
    [RXFIELD_APPROVAL_TITLE]: found?.title ? found?.title : '',
    [RXFIELD_APPROVAL_DESCRIPTION]: found?.description ? found?.description : '',
    [RXFIELD_APPROVAL_NOTES]: found?.notes ? found?.notes : '',
    [RXFIELD_APPROVAL_APPROVAL_STATUS]: found?.approvalStatus ? found?.approvalStatus : '',
    [RXFIELD_APPROVAL_STATUS_CHANGED_AT]: found?.statusChangedAt ? toMoment(found?.statusChangedAt).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    [RXFIELD_APPROVAL_REQUESTER_ID]: found?.requester.id ? found?.requester?.id : '',
    [RXFIELD_APPROVAL_REQUESTER_NAME]: found?.requester.fullName ? found?.requester?.fullName : '',
    [RXFIELD_APPROVAL_REQUESTER_NOTES]: found?.requesterNotes ? found?.requesterNotes : '',
    [RXFIELD_APPROVAL_REFERENCE_ID]: found?.referenceId ? found?.referenceId : '',
    [RXFIELD_APPROVAL_PROVIDER_NAME]: found?.providerName ? found?.providerName : '',
    [RXFIELD_APPROVAL_ALLOW_TO_ADD_ATTACHMENT]: found?.allowToAddAttachments?.toString() || '',
    [RXFIELD_APPROVAL_STEPS]: found?.steps
      ? found?.steps.sort((a, b) => sortAsc(a.order, b.order)) : [],
    [RXFIELD_APPROVAL_ATTACHMENTS]: found?.attachments ? found?.attachments : [],
    [RXFIELD_APPROVAL_DATA]: found?.data && !isEmpty(found.data)
      ? Object.entries(found.data).map((x) => ({
        fieldName: x[0],
        value: x[1],
      }))
      : [],
  }) : {};
  return initVal;
};

const getApprovers = (state) => {
  const { approvals, uiApproval, uiFunctionalPage } = state;
  const { downloadingDetail, tappedId, selectedStepId } = uiApproval;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDetail
    ? approvals.data[tappedId] : {};

  const selectedStepData = () => {
    if (found?.steps && selectedStepId) {
      const step = found.steps.find((x) => x.id === selectedStepId);
      return step;
    }
    return {};
  };

  return !isEmpty(selectedStepData()) ? selectedStepData().approvers : [];
};

const getComments = (state) => {
  const { approvals, uiApproval, uiFunctionalPage } = state;
  const { downloadingDetail, tappedId, selectedStepId } = uiApproval;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDetail
    ? approvals.data[tappedId] : {};

  const selectedStepData = () => {
    if (found?.steps && selectedStepId) {
      const step = found.steps.find((x) => x.id === selectedStepId);
      return step;
    }
    return {};
  };

  return !isEmpty(selectedStepData()) ? selectedStepData().comments : [];
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiApproval.downloading,
  downloadingDetail: state.uiApproval.downloadingDetail,
  downloadingAttachment: state.uiApproval.downloadingAttachment,
  showApproverPopUp: state.uiApproval.showApproverPopUp,
  showCommentPopUp: state.uiApproval.showCommentPopUp,
  initialValues: getInitialValues(state),
  requesters: transformUserDropdownData(state.profiles.data),
  loadingRequester: state.uiProfile.downloading,
  processNames: state.approvalProcesses,
  loadingProcessNames: state.uiApproval.downloadingApprovalProcesses,
  providerNames: state.approvalProviders,
  loadingProviderNames: state.uiApproval.downloadingApprovalProviders,
  approvers: getApprovers(state),
  comments: getComments(state),
  resendingEvent: state.uiApproval.resendingEvent,
  selectedResendEventOption: state.uiApproval.selectedResendEventOption,
  tappedId: state.uiApproval.tappedId,
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setApprovalAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setApprovalSelectedResendEventOption(''));
    dispatch(setActiveSideMenuItem(MENUID_APPROVAL_APPROVAL));
    dispatch(setApprovalSearchText(''));
    dispatch(clearApprovals());
    dispatch(setApprovalSelectedPageSize(20));
    dispatch(setApprovalSelectedOrderBy(INITIAL_ORDER_BY_APPROVALS));
    dispatch(downloadApprovalsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_APPROVAL));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadApprovalsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setApprovalSelectedPageSize(pageSize));
    dispatch(downloadApprovalsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setApprovalSelectedPageSize(pageSize));
    dispatch(clearApprovals());
    dispatch(downloadApprovalsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setApprovalSearchText(text));
      dispatch(clearApprovals());
      await dispatch(downloadApprovalsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setApprovalSelectedOrderBy(orderBy));
    dispatch(clearApprovals());
    dispatch(downloadApprovalsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setApprovalTappedId(id));
    dispatch(downloadApprovalAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setApprovalSelectedResendEventOption(''));
    dispatch(change(RXFORM_APPROVAL, RXFIELD_APPROVAL_RESEND_EVENT, ''));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(clearApprovalProcesses());
    dispatch(downloadApprovalProcessesAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(clearApprovalProviders());
    dispatch(downloadApprovalProvidersAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('requester', 'requester.id');

    dispatch(setApprovalAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearApprovals());
    dispatch(downloadApprovalsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearApprovals());
    dispatch(setApprovalAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setApprovalSelectedOrderBy(INITIAL_ORDER_BY_APPROVALS));
    dispatch(downloadApprovalsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeRequesterText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadAttachmentPressed: (item) => {
    dispatch(saveApprovalAttachmentAsync(item))
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onStepActionButtonPressed: (showDialog, id) => {
    showDialog(true);
    dispatch(setApprovalSelectedStepId(id));
  },
  onCopyDataValuePressed: (value) => {
    navigator.clipboard.writeText(value);
  },
  onResendEventOptionSelected: (value) => {
    dispatch(setApprovalSelectedResendEventOption(value));
    dispatch(change(RXFORM_APPROVAL, RXFIELD_APPROVAL_RESEND_EVENT, value));
  },
  onResendEventPressed: (id) => {
    dispatch(setApprovalTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.approvalPage.msgConfirmResendEvent));
  },
  onConfirmResendEventPressed: () => {
    dispatch(resendEventAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalPage);
