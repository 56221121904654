import {
  addWFAQuota,
  clearWFAQuotas,
  downloadingDeletingWFAQuota,
  setAlertErrorMessage,
  setFunctionalPageMode,
  setWFAQuotaSelectedDepartment,
  setWFAQuotaTappedId,
} from '../simple-action';
import { downloadWFAQuota, deleteWFAQuota } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadWFAQuotasAsync from './downloadWFAQuotasAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingWFAQuota(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWFAQuota;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteWFAQuota(tappedId, token);
      dispatch(setWFAQuotaTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearWFAQuotas());
      dispatch(downloadWFAQuotasAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const wfaQuota = await downloadWFAQuota(tappedId, token);
      dispatch(addWFAQuota(wfaQuota));
      dispatch(setWFAQuotaSelectedDepartment(wfaQuota?.departmentName));
    }
  } finally {
    dispatch(downloadingDeletingWFAQuota(false));
  }
};
