import { downloadingParentCalendars, setParentCalendars } from '../simple-action';
import { downloadCalendars, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_CALENDAR } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, parentCalendars, uiParentCalendars } = getState();
  const { token } = authentication;
  const { meta } = parentCalendars;
  const { searchBarText } = uiParentCalendars;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingParentCalendars(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCalendars(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_CALENDAR,
      searchText,
      '',
      token,
    );

    dispatch(setParentCalendars(list));
  } finally {
    dispatch(downloadingParentCalendars(false));
  }
};
