import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_COMPANY_MAPPINGS = '/bcard/api/v1/CompanyMapping?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_COMPANY_MAPPING = '/bcard/api/v1/CompanyMapping';
export const REST_URL_VIEW_DELETE_COMPANY_MAPPING = '/bcard/api/v1/CompanyMapping/{id}';
export const REST_URL_INFO_MAPPINGS = '/bcard/api/v1/InfoMapping?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_INFO_MAPPING = '/bcard/api/v1/InfoMapping';
export const REST_URL_VIEW_DELETE_INFO_MAPPING = '/bcard/api/v1/InfoMapping/{id}';

export const ROUTE_NAME_BCARD = '/bcard';
export const ROUTE_NAME_COMPANY_MAPPING = '/company-mapping';
export const ROUTE_NAME_INFO_MAPPING = '/info-mapping';

export const RXFORM_COMPANY_MAPPING = 'companyMappingPage';
export const RXFORM_INFO_MAPPING = 'infoMappingPage';

export const RXFIELD_COMPANY_MAPPING_ORDER = 'order';
export const RXFIELD_COMPANY_MAPPING_PHRASE = 'phrase';
export const RXFIELD_COMPANY_MAPPING_OPERATOR = 'mappingOperator';
export const RXFIELD_COMPANY_MAPPING_COMPANY_NAME = 'companyName';
export const RXFIELD_COMPANY_MAPPING_ADDRESS = 'addressLine1';
export const RXFIELD_COMPANY_MAPPING_ADDRESS_TWO = 'addressLine2';
export const RXFIELD_COMPANY_MAPPING_CITY = 'city';
export const RXFIELD_COMPANY_MAPPING_PROVINCE = 'province';
export const RXFIELD_COMPANY_MAPPING_POSTAL_CODE = 'postalCode';
export const RXFIELD_COMPANY_MAPPING_PHONE = 'phone';
export const RXFIELD_COMPANY_MAPPING_FAX = 'fax';
export const RXFIELD_COMPANY_MAPPING_WEBSITE = 'webSite';
export const RXFIELD_COMPANY_MAPPING_COMPANY_LOGO_URL = 'companyLogoUrl';
export const RXFIELD_COMPANY_MAPPING_IMAGE_URL = 'image1Url';
export const RXFIELD_COMPANY_MAPPING_IMAGE_TWO_URL = 'image2Url';
export const RXFIELD_INFO_MAPPING_ORDER = 'order';
export const RXFIELD_INFO_MAPPING_PHRASE = 'phrase';
export const RXFIELD_INFO_MAPPING_MAPPING_OPERATOR = 'mappingOperator';
export const RXFIELD_INFO_MAPPING_NEW_NAME = 'newName';
export const RXFIELD_INFO_MAPPING_TYPE = 'type';

export const RXSTATE_COMPANY_MAPPINGS = 'companyMappings';
export const RXSTATE_COMPANY_MAPPING_PAGE = 'uiCompanyMapping';
export const RXSTATE_INFO_MAPPINGS = 'infoMappings';
export const RXSTATE_INFO_MAPPING_PAGE = 'uiInfoMapping';

export const INITIAL_ORDER_BY_COMPANY_MAPPINGS = `order ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_INFO_MAPPINGS = `order ${ORDER_SORT_ASCENDING}`;

export const MENUID_BCARD = 'MENUID_BCARD';
export const MENUID_BCARD_COMPANY_MAPPING = 'MENUID_BCARD_COMPANY_MAPPING';
export const MENUID_BCARD_INFO_MAPPING = 'MENUID_BCARD_INFO_MAPPING';

export const MAPPING_OPERATORS = ['Equals', 'Contains', 'StartsWith', 'EndsWith'];
export const MAPPING_TYPES = ['JobTitle', 'Department'];
export const COMPANY_MAPPING_TYPE = ['CompanyName', 'BranchName'];
