import {
  addArticleCategory, clearArticleCategories, downloadingDeletingArticleCategory,
  setAlertErrorMessage, setFunctionalPageMode, setArticleCategoryTappedId,
} from '../simple-action';
import { downloadArticleCategory, deleteArticleCategory } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadArticleCategoriesAsync from './downloadArticleCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingArticleCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiArticleCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteArticleCategory(tappedId, token);
      dispatch(setArticleCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearArticleCategories());
      dispatch(downloadArticleCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const articleCategory = await downloadArticleCategory(tappedId, token);
      dispatch(addArticleCategory(articleCategory));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingArticleCategory(false));
  }
};
