import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearPacketTypes, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setPacketTypeSearchText, setPacketTypeSelectedPageSize, setPacketTypeSelectedOrderBy,
  setPacketTypeTappedId,
  addEditPacketTypeAsync, downloadDeletePacketTypeAsync, downloadPacketTypesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_PACKET_TYPES, MENUID_PACKET_MANAGEMENT_PACKET_TYPE, PAGE_MODE_TABLE,
  RXFORM_PACKET_TYPE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../../../localization';
import PacketTypePage from './packet-type.presentation';

const getInitialValues = (state) => {
  const { packetTypes, uiFunctionalPage, uiPacketType } = state;
  const { data } = packetTypes;
  const { downloadingDeleting, tappedId } = uiPacketType;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : { name: '', description: '', keepPacketForHours: '' };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiPacketType.addingEditing,
  downloading: state.uiPacketType.downloading,
  downloadingDeleting: state.uiPacketType.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PACKET_MANAGEMENT_PACKET_TYPE));
    dispatch(setPacketTypeSearchText(''));
    dispatch(clearPacketTypes());
    dispatch(setPacketTypeSelectedPageSize(20));
    dispatch(setPacketTypeSelectedOrderBy(INITIAL_ORDER_BY_PACKET_TYPES));
    dispatch(downloadPacketTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPacketTypesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPacketTypeSelectedPageSize(pageSize));
    dispatch(downloadPacketTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PACKET_TYPE));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeletePacketTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setPacketTypeTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setPacketTypeTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setPacketTypeTappedId(id));
    dispatch(downloadDeletePacketTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setPacketTypeSelectedPageSize(pageSize));
    dispatch(clearPacketTypes());
    dispatch(downloadPacketTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description, keepPacketForHours }) => {
    try {
      await dispatch(addEditPacketTypeAsync(name, description, keepPacketForHours));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPacketTypeSearchText(text));
      dispatch(clearPacketTypes());
      await dispatch(downloadPacketTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPacketTypeSelectedOrderBy(orderBy));
    dispatch(clearPacketTypes());
    dispatch(downloadPacketTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description, keepPacketForHours }) => {
    await dispatch(addEditPacketTypeAsync(name, description, keepPacketForHours));
  },
  onViewPressed: (id) => {
    dispatch(setPacketTypeTappedId(id));
    dispatch(downloadDeletePacketTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PacketTypePage);
