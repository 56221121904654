import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_ADD_EDIT_SHORTLINK, REST_URL_VIEW_DELETE_SHORTLINK, REST_URL_SHORTLINKS,
} from './constant';

export * from '../../helper';

export const downloadShortlinks = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_SHORTLINKS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addShortlink = async (url, description, token) => {
  const body = {
    url,
    description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_SHORTLINK, body, token);
};

export const editShortlink = async (id, url, description, token) => {
  const body = {
    id,
    url,
    description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SHORTLINK, body, token);
};

export const downloadShortlink = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SHORTLINK.replace(/\{id\}/, id), token);
  return response;
};

export const deleteShortlink = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SHORTLINK.replace(/\{id\}/, id), token);
};
