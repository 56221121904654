import {
  addModel, clearModels, downloadingDeletingModel, setAlertErrorMessage,
  setFunctionalPageMode, setModelTappedId,
} from '../simple-action';
import { downloadModel, deleteModel } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadModelsAsync from './downloadModelsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingModel(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiModel;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteModel(tappedId, token);
      dispatch(setModelTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearModels());
      dispatch(downloadModelsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const Model = await downloadModel(tappedId, token);
      dispatch(addModel(Model));
    }
  } finally {
    dispatch(downloadingDeletingModel(false));
  }
};
