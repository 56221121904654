import { reset } from 'redux-form';
import {
  addingEditingProfileSync, clearProfileSync, setAlertErrorMessage, setFunctionalPageMode,
  setProfileSyncSelectedBranchId, setProfileSyncSelectedCompany, setProfileSyncSelectedManagerId,
} from '../simple-action';
import { RXFORM_PROFILE_SYNC, PAGE_MODE_TABLE } from '../../../constant';
import { addProfileSync, editProfileSync } from '../../../helper';
import downloadProfileSyncsAsync from './downloadProfileSyncsAsync';

export default (employeeId, fullName, email, phone, managerEmployeeId, jobTitle,
  department, division, branchId, dateOfBirth, gender) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingProfileSync(true));

    const { token } = getState().authentication;
    const {
      tappedId, duplicateMode, selectedBranch, selectedCompany, selectedManager,
    } = getState().uiProfileSync;

    const genderValue = typeof gender === 'object' ? gender?.value || undefined : gender || undefined;
    const manager = selectedManager || managerEmployeeId;
    const branch = selectedBranch || branchId;
    const company = typeof selectedCompany === 'object' ? selectedCompany?.label : selectedCompany;

    if (tappedId && !duplicateMode) {
      await editProfileSync(tappedId, fullName, email, phone, manager, jobTitle,
        department, division, company, branch, dateOfBirth, genderValue, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addProfileSync(employeeId, fullName, email, phone, manager,
        jobTitle, department, division, company, branch, dateOfBirth, genderValue, token);
    }

    dispatch(setProfileSyncSelectedBranchId(''));
    dispatch(setProfileSyncSelectedCompany(''));
    dispatch(setProfileSyncSelectedManagerId(''));
    dispatch(reset(RXFORM_PROFILE_SYNC));
    dispatch(clearProfileSync());
    dispatch(downloadProfileSyncsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(addingEditingProfileSync(false));
  }
};
