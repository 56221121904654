import { downloadingWFAQuotas, setWFAQuotas } from '../simple-action';
import { downloadWFAQuotas, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, wfaQuotas, uiWFAQuota } = getState();
  const { token } = authentication;
  const { meta } = wfaQuotas;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiWFAQuota;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingWFAQuotas(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadWFAQuotas(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setWFAQuotas(list));
  } finally {
    dispatch(downloadingWFAQuotas(false));
  }
};
