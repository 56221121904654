import {
  downloadInboxes,
  transformSearchText,
} from '../../../helper';
import {
  downloadingInboxes,
  setInboxes,
} from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication,
    inboxes,
    uiInbox,
  } = getState();
  const { token } = authentication;
  const { meta } = inboxes;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiInbox;

  try {
    dispatch(downloadingInboxes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadInboxes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setInboxes(list));
    }
  } finally {
    dispatch(downloadingInboxes(false));
  }
};
