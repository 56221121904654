import { reset } from 'redux-form';
import {
  addingEditingFAQCategory, clearFAQCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addFAQCategory, editFAQCategory } from '../../../helper';
import { RXFORM_FAQ_CATEGORY, PAGE_MODE_TABLE } from '../../../constant';
import downloadFAQCategoriesAsync from './downloadFAQCategoriesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingFAQCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiFAQCategory;

    if (tappedId) {
      await editFAQCategory(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addFAQCategory(name, description, token);
    }

    dispatch(reset(RXFORM_FAQ_CATEGORY));
    dispatch(clearFAQCategories());
    dispatch(downloadFAQCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingFAQCategory(false));
  }
};
