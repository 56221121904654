import { DOWNLOADING_PARENT_CALENDARS, SET_PARENT_CALENDARS_SEARCH_TEXT } from '../action';

const initialState = {
  downloading: false,
  searchBarText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PARENT_CALENDARS:
      return { ...state, downloading: action.status };
    case SET_PARENT_CALENDARS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    default:
      return state;
  }
};
