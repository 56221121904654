import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_REGISTRATIONS = '/mycar/api/v1/Registration?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|vehicle.model|vehicle.licensePlate&filterString=status=enabled';
export const REST_URL_VIEW_REGISTRATION = '/mycar/api/v1/Registration/{id}';
export const REST_URL_APPROVE_REJECT_REGISTRATION = '/mycar/api/v1/RegistrationApproval';

export const ROUTE_NAME_CAR_REGISTRATION = '/car-registration';
export const ROUTE_NAME_REGISTRATION = '/registration';

export const RXFORM_REGISTRATION = 'registrationPage';

export const RXFIELD_REGISTRATION_VEHICLE_VIN = 'vehicle.vin';
export const RXFIELD_REGISTRATION_VEHICLE_MODEL = 'vehicle.model';
export const RXFIELD_REGISTRATION_VEHICLE_TRIM = 'vehicle.trim';
export const RXFIELD_REGISTRATION_VEHICLE_COLOR = 'vehicle.color';
export const RXFIELD_REGISTRATION_VEHICLE_LICENSE_PLATE = 'vehicle.licensePlate';
export const RXFIELD_REGISTRATION_VEHICLE_TRANSMISSION = 'vehicle.transmission';
export const RXFIELD_REGISTRATION_VEHICLE_STNK = 'vehicle.stnk';
export const RXFIELD_REGISTRATION_VEHICLE_PHONE = 'vehicle.phone';
export const RXFIELD_REGISTRATION_VEHICLE_WARRANTY_START_DATE = 'vehicle.warantyStartDate';
export const RXFIELD_REGISTRATION_VEHICLE_WARRANTY_END_DATE = 'vehicle.warantyEndDate';
export const RXFIELD_REGISTRATION_VEHICLE_WARRANTY_NOTE = 'vehicle.warantyNote';
export const RXFIELD_REGISTRATION_VEHICLE_PERSON_ID = 'vehicle.personId';
export const RXFIELD_REGISTRATION_VEHICLE_IS_PERSONAL_ACCOUNT = 'vehicle.isPersonalAccount';
export const RXFIELD_REGISTRATION_VEHICLE_NIK = 'vehicle.nik';
export const RXFIELD_REGISTRATION_VEHICLE_OWNER_NAME = 'vehicle.ownerName';
export const RXFIELD_REGISTRATION_VEHICLE_INVOICE_NO = 'vehicle.fakturNo';
export const RXFIELD_REGISTRATION_VEHICLE_ENGINE_NO = 'vehicle.engineNo';
export const RXFIELD_REGISTRATION_VEHICLE_OWNER_EMAIL = 'vehicle.ownerEmail';
export const RXFIELD_REGISTRATION_VEHICLE_DEALER_CODE = 'vehicle.dealerCode';
export const RXFIELD_REGISTRATION_VEHICLE_DEALER_NAME = 'vehicle.dealerName';
export const RXFIELD_REGISTRATION_VEHICLE_DEALER_ADDRESS = 'vehicle.dealerAddress';
export const RXFIELD_REGISTRATION_VEHICLE_MODEL_YEAR = 'vehicle.modelYear';
export const RXFIELD_REGISTRATION_VEHICLE_PRICE = 'vehicle.price';
export const RXFIELD_REGISTRATION_USER_ID = 'user.id';
export const RXFIELD_REGISTRATION_USER_NAME = 'user.fullName';
export const RXFIELD_REGISTRATION_FULL_NAME = 'fullName';
export const RXFIELD_REGISTRATION_NIK = 'nik';
export const RXFIELD_REGISTRATION_SALUTATION = 'salutation';
export const RXFIELD_REGISTRATION_PHONE = 'phone';
export const RXFIELD_REGISTRATION_EMAIL = 'email';
export const RXFIELD_REGISTRATION_STREET_ADDRESS = 'streetAddress';
export const RXFIELD_REGISTRATION_DISTRICT = 'district';
export const RXFIELD_REGISTRATION_CITY = 'city.name';
export const RXFIELD_REGISTRATION_PROVINCE = 'city.province.name';
export const RXFIELD_REGISTRATION_REGISTRATION_STATUS = 'registrationStatus';
export const RXFIELD_REGISTRATION_REGISTRATION_NOTES = 'registrationNotes';
export const RXFIELD_REGISTRATION_APPROVED_ON = 'approvedOn';
export const RXFIELD_REGISTRATION_REJECTED_ON = 'rejectedOn';

export const RXSTATE_REGISTRATIONS = 'registrations';
export const RXSTATE_REGISTRATION_PAGE = 'uiRegistration';

export const INITIAL_ORDER_BY_REGISTRATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_CAR_REGISTRATION = 'MENUID_CAR_REGISTRATION';
export const MENUID_CAR_REGISTRATION_REGISTRATION = 'MENUID_CAR_REGISTRATION_REGISTRATION';

export const APPROVAL_STATUS_APPROVED = 'Approve';
export const APPROVAL_STATUS_REJECTED = 'Reject';

export const REGISTRATION_STATUS_PENDING = 'Pending';
export const REGISTRATION_STATUS_APPROVED = 'Approved';
export const REGISTRATION_STATUS_REJECTED = 'Rejected';

export const REGISTRATION_STATUS = [REGISTRATION_STATUS_APPROVED,
  REGISTRATION_STATUS_REJECTED, REGISTRATION_STATUS_PENDING];
