import { reset } from 'redux-form';
import {
  addingEditingCalendar,
  clearCalendars,
  setAlertErrorMessage,
  setFunctionalPageMode,
  setCalendarTappedId,
} from '../simple-action';
import {
  addCalendar, editCalendar,
} from '../../../helper';
import {
  PAGE_MODE_TABLE, RXFORM_CALENDAR,
} from '../../../constant';
import downloadCalendarsAsync from './downloadCalendarsAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCalendar(true));

    const { token } = getState().authentication;
    const { tappedId, days } = getState().uiCalendar;
    const {
      name,
      description,
      parents,
    } = values;

    const transformedParents = parents.map((parent, index) => ({
      calendarId: parent.value,
      order: index + 1,
    }));

    const body = {
      name,
      description,
      days,
      parents: transformedParents,
    };

    if (tappedId) {
      await editCalendar({ ...body, id: tappedId }, token);
    } else {
      await addCalendar(body, token);
    }

    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    dispatch(reset(RXFORM_CALENDAR));
    dispatch(clearCalendars());
    dispatch(downloadCalendarsAsync(1)).catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setCalendarTappedId(''));
  } finally {
    dispatch(addingEditingCalendar(false));
  }
};
