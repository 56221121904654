import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  PAGE_MODE_TABLE, INITIAL_ORDER_BY_EVENTS, MENUID_AGENDA_EVENT,
  RXFORM_EVENTS, DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_EVENT_TIME_ZONE,
  RXFIELD_EVENT_LOCATION, RXFIELD_EVENT_DESCRIPTION,
} from '../../constant';
import EventPage from './event.presentation';
import { transformInitialValues, toMoment, transformDropdownData } from '../../helper';
import {
  clearEvents, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setEventsSearchText, setEventsSelectedPageSize,
  setEventsSelectedOrderBy, setEventTappedId,
  addEditEventAsync, downloadDeleteEventAsync, downloadEventsAsync, downloadLocationsAsync,
  setEventSelectedTimeZine, setEventSelectedLocation,
  setLocationsSearchText, clearLocations, setAdvancedFilterDialogSelectedFilterString,
  saveEventsAsync,
} from '../../redux/action';
import GlobalLocalizedString from '../../../../localization';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { events, uiFunctionalPage, uiEvent } = state;
  const { data } = events;
  const { downloadingDeleting, tappedId } = uiEvent;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found, {
    startTime: found.startTime ? toMoment(found.startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    endTime: found.endTime ? toMoment(found.endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    location: found.location ? { label: found.location.name, value: found.location.id } : {},
  }) : {
    name: '',
    referenceNumber: '',
    startTime: '',
    endTime: '',
    timeZone: '',
    location: { label: '', value: '' },
    participants: '',
    description: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiEvent.addingEditing,
  downloadingDeleting: state.uiEvent.downloadingDeleting,
  downloading: state.uiEvent.downloading,
  initialValues: getInitialValues(state),
  locations: transformDropdownData(state.locations.data),
  downloadingLocation: state.uiLocation.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AGENDA_EVENT));
    dispatch(setEventsSearchText(''));
    dispatch(clearEvents());
    dispatch(setEventsSelectedPageSize(20));
    dispatch(setEventsSelectedOrderBy(INITIAL_ORDER_BY_EVENTS));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventsSelectedPageSize(pageSize));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_EVENTS));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteEventAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onCreatePressed: () => {
    dispatch(setEventTappedId(''));
    dispatch(setLocationsSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onDeletePressed: (id) => {
    dispatch(setEventTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onRefresh: (pageSize) => {
    dispatch(setEventsSelectedPageSize(pageSize));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, referenceNumber, startTime, endTime, participants, description,
  }) => {
    try {
      await dispatch(addEditEventAsync(name, referenceNumber, startTime, endTime,
        participants, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventsSearchText(text));
      dispatch(clearEvents());
      await dispatch(downloadEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventsSelectedOrderBy(orderBy));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, referenceNumber, startTime, endTime, participants, description,
  }) => {
    await dispatch(addEditEventAsync(name, referenceNumber, startTime, endTime,
      participants, description));
  },
  onViewPressed: (id) => {
    dispatch(setEventTappedId(id));
    dispatch(downloadDeleteEventAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEditPressed: (id) => {
    dispatch(setLocationsSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
    dispatch(setEventTappedId(id));
  },
  onTimeZoneOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedTimeZine(option));
      dispatch(change(RXFORM_EVENTS, RXFIELD_EVENT_TIME_ZONE, option));
    } else {
      dispatch(setEventSelectedTimeZine(''));
      dispatch(change(RXFORM_EVENTS, RXFIELD_EVENT_TIME_ZONE, ''));
    }
  },
  onLocationOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedLocation(option));
      dispatch(change(RXFORM_EVENTS, RXFIELD_EVENT_LOCATION, option));
    } else {
      dispatch(setEventSelectedLocation(''));
      dispatch(change(RXFORM_EVENTS, RXFIELD_EVENT_LOCATION, ''));
    }
  },
  onChangeDescriptionText: (text) => {
    dispatch(change(RXFORM_EVENTS, RXFIELD_EVENT_DESCRIPTION, text));
  },
  onChangeLocationText: async (text) => {
    if (text.length > 2 || text.length === 0) {
      try {
        dispatch(setLocationsSearchText(text));
        dispatch(clearLocations());
        await dispatch(downloadLocationsAsync(1));
      } catch (error) {
        dispatch(setAlertErrorMessage(error));
      }
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setEventSelectedLocation(''));
    dispatch(setLocationsSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/location/, 'location.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_EVENTS));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventSelectedLocation(''));
    dispatch(setLocationsSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveEventsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
