import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { AccentButton, FunctionalPage } from '../../component';
import {
  DATE_FORMAT, FILE_EXTENSION_SPREADSHEET, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  IMPORT_STATUSES, PAGE_MODE_VIEW, PICKER_DATE_FORMAT, PICKER_DATE_TIME_FORMAT, PICKER_MODE_DATE,
  PICKER_MODE_DATE_TIME, RXFIELD_CREATED_DATE, RXFIELD_PERSONAL_HOLIDAY_DESCRIPTION,
  RXFIELD_PERSONAL_HOLIDAY_END_DATE, RXFIELD_PERSONAL_HOLIDAY_FILE,
  RXFIELD_PERSONAL_HOLIDAY_IMPORT_STATUS, RXFIELD_PERSONAL_HOLIDAY_IMPORT_STATUS_NOTES,
  RXFIELD_PERSONAL_HOLIDAY_START_DATE, RXFIELD_PERSONAL_HOLIDAY_USER_DEPARTMENT,
  RXFIELD_PERSONAL_HOLIDAY_USER_DIVISION, RXFIELD_PERSONAL_HOLIDAY_USER_EMPLOYEE_ID,
  RXFIELD_PERSONAL_HOLIDAY_USER_ID, RXFIELD_PERSONAL_HOLIDAY_USER_NAME, RXFORM_PERSONAL_HOLIDAY,
  RXSTATE_PERSONAL_HOLIDAY_PAGE, RXSTATE_PERSONAL_HOLIDAYS,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormDateTimePickerField, renderReduxFormFilePickerField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidatePersonalHoliday } from '../../validation';

const renderDialogContent = (initialValues, addingEditing, onDownloadTemplatePressed,
  onFileSelected, pageMode, templateUrl) => {
  if (pageMode === PAGE_MODE_VIEW) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderUserId}
              label={LocalizedString.personalHolidayPage.placeholderUserId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_USER_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderUserName}
              label={LocalizedString.personalHolidayPage.placeholderUserName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_USER_EMPLOYEE_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderEmployeeId}
              label={LocalizedString.personalHolidayPage.placeholderEmployeeId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_USER_DEPARTMENT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderDepartment}
              label={LocalizedString.personalHolidayPage.placeholderDepartment}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_USER_DIVISION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderDivision}
              label={LocalizedString.personalHolidayPage.placeholderDivision}
              disabled
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_START_DATE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.personalHolidayPage.placeholderStartDate}
              disabled
              pickerMode={PICKER_MODE_DATE}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_END_DATE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.personalHolidayPage.placeholderEndDate}
              disabled
              pickerMode={PICKER_MODE_DATE}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_IMPORT_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderImportStatus}
              label={LocalizedString.personalHolidayPage.placeholderImportStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_IMPORT_STATUS_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderImportStatusNotes}
              label={LocalizedString.personalHolidayPage.placeholderImportStatusNotes}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PERSONAL_HOLIDAY_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.personalHolidayPage.placeholderDescription}
              label={LocalizedString.personalHolidayPage.placeholderDescription}
              disabled
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PERSONAL_HOLIDAY_FILE}
            component={renderReduxFormFilePickerField}
            label={LocalizedString.personalHolidayPage.placeholderFile}
            disabled={addingEditing}
            onFileSelected={onFileSelected}
            acceptedFileExtension={FILE_EXTENSION_SPREADSHEET}
            required
            onBlur={(e) => e.preventDefault()}
          />
          <AccentButton
            variant="text"
            caption={GlobalLocalizedString.common.buttonCaptionDownloadTemplate}
            onClick={() => onDownloadTemplatePressed(templateUrl)}
            disabled={addingEditing}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const PersonalHolidayPage = ({
  initialValues, users,
  addingEditing, downloading, loadingUser,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelPressed, onChangePage,
  onChangePageSize, onChangeUserText, onCreatePressed, onDownloadTemplatePressed, onFileSelected,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed,
  pageMode, templateUrl,
}) => (
  <FunctionalPage
    data={RXSTATE_PERSONAL_HOLIDAYS}
    uiPage={RXSTATE_PERSONAL_HOLIDAY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.personalHolidayPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.personalHolidayPage.labelUser, field: 'user.fullName', sorting: !downloading },
      {
        title: LocalizedString.personalHolidayPage.labelStartDate,
        field: 'startDate',
        sorting: !downloading,
        render: ({ startDate }) => (startDate
          ? toMoment(startDate).format(DATE_FORMAT) : null),
      },
      {
        title: LocalizedString.personalHolidayPage.labelEndDate,
        field: 'endDate',
        sorting: !downloading,
        render: ({ endDate }) => (endDate
          ? toMoment(endDate).format(DATE_FORMAT) : null),
      },
      { title: LocalizedString.personalHolidayPage.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.personalHolidayPage.labelImportStatus, field: 'importStatus', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.personalHolidayPage.labelUser,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.personalHolidayPage.labelImportStatus,
        field: RXFIELD_PERSONAL_HOLIDAY_IMPORT_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: IMPORT_STATUSES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.personalHolidayPage.labelStartDate,
        field: RXFIELD_PERSONAL_HOLIDAY_START_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE,
        format: PICKER_DATE_FORMAT,
      },
      {
        title: LocalizedString.personalHolidayPage.labelEndDate,
        field: RXFIELD_PERSONAL_HOLIDAY_END_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE,
        format: PICKER_DATE_FORMAT,
      },
      {
        title: LocalizedString.personalHolidayPage.labelCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCreatePressed={onCreatePressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.personalHolidayPage
      .buttonCaptionCreateNewPersonalHoliday}
    createPermissionName="ATTENDANCEADDON_IMPORT_HOLIDAY"
    title={LocalizedString.personalHolidayPage.title}
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues, addingEditing, onDownloadTemplatePressed, onFileSelected,
      pageMode, templateUrl)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PERSONAL_HOLIDAY,
  validate: rxformValidatePersonalHoliday,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PersonalHolidayPage);

PersonalHolidayPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadTemplatePressed: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  templateUrl: PropTypes.string.isRequired,
};
