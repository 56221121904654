import {
  addApplication, clearApplications, downloadingDeletingApplication, setAlertErrorMessage,
  setApplicationTappedId, setFunctionalPageMode,
} from '../simple-action';
import { downloadApplication, deleteApplication } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadApplicationsAsync from './downloadApplicationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingApplication(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiApplication;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteApplication(tappedId, token);
      dispatch(setApplicationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearApplications());
      dispatch(downloadApplicationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const application = await downloadApplication(tappedId, token);
      dispatch(addApplication(application));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingApplication(false));
  }
};
