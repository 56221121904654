import {
  addDocumentGeneratorTemplates, downloadingDeletingDocumentGeneratorTemplate,
} from '../simple-action';
import { downloadDocumentGeneratorTemplate } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingDocumentGeneratorTemplate(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTemplate;

    const template = await downloadDocumentGeneratorTemplate(tappedId, token);

    dispatch(addDocumentGeneratorTemplates(template));
  } finally {
    dispatch(downloadingDeletingDocumentGeneratorTemplate(false));
  }
};
