import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_PARTNER_MERCHANTS = 'DOWNLOADING_PARTNER_MERCHANTS';
export const downloadingPartnerMerchants = makeActionCreator(DOWNLOADING_PARTNER_MERCHANTS, 'status');

export const SET_PARTNER_MERCHANTS = 'SET_PARTNER_MERCHANTS';
export const setPartnerMerchants = makeActionCreator(SET_PARTNER_MERCHANTS, 'data');

export const ADD_PARTNER_MERCHANT = 'ADD_PARTNER_MERCHANT';
export const addPartnerMerchant = makeActionCreator(ADD_PARTNER_MERCHANT, 'data');

export const CLEAR_PARTNER_MERCHANTS = 'CLEAR_PARTNER_MERCHANTS';
export const clearPartnerMerchants = makeActionCreator(CLEAR_PARTNER_MERCHANTS);

export const ADDING_EDITING_PARTNER_MERCHANT = 'ADDING_EDITING_PARTNER_MERCHANT';
export const addingEditingPartnerMerchant = makeActionCreator(ADDING_EDITING_PARTNER_MERCHANT, 'status');

export const DOWNLOADING_DELETING_PARTNER_MERCHANT = 'DOWNLOADING_DELETING_PARTNER_MERCHANT';
export const downloadingDeletingPartnerMerchant = makeActionCreator(DOWNLOADING_DELETING_PARTNER_MERCHANT, 'status');


export const SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE = 'SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE';
export const setPartnerMerchantSelectedPageSize = makeActionCreator(SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE, 'size');

export const SET_PARTNER_MERCHANT_SELECTED_ORDER_BY = 'SET_PARTNER_MERCHANT_SELECTED_ORDER_BY';
export const setPartnerMerchantSelectedOrderBy = makeActionCreator(SET_PARTNER_MERCHANT_SELECTED_ORDER_BY, 'order');

export const SET_PARTNER_MERCHANT_TAPPED_ID = 'SET_PARTNER_MERCHANT_TAPPED_ID';
export const setPartnerMerchantTappedId = makeActionCreator(SET_PARTNER_MERCHANT_TAPPED_ID, 'id');

export const SET_PARTNER_MERCHANT_SEARCH_TEXT = 'SET_PARTNER_MERCHANT_SEARCH_TEXT';
export const setPartnerMerchantSearchText = makeActionCreator(SET_PARTNER_MERCHANT_SEARCH_TEXT, 'text');

export const SET_PARTNER_MERCHANT_SELECTED_COMPANY = 'SET_PARTNER_MERCHANT_SELECTED_COMPANY';
export const setPartnerMerchantSelectedCompany = makeActionCreator(SET_PARTNER_MERCHANT_SELECTED_COMPANY, 'value');
