import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_DOCUMENT_GENERATOR_DOCUMENT,
  MENUID_DOCUMENT_GENERATOR_DOCUMENT,
  PAGE_MODE_TABLE,
  RXFORM_DOCUMENT,
} from '../../constant';
import { transformInitialValues, transformTemplateDropdownData, transformUserDropdownData } from '../../helper';
import {
  clearDocumentGeneratorDocuments,
  clearDocumentGeneratorTemplates,
  clearUsers,
  downloadDocumentGeneratorDocumentAsync, downloadDocumentGeneratorDocumentsAsync,
  downloadDocumentGeneratorTemplatesAsync,
  downloadDocumentResultAsync,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setDocumentAdvancedFilterDialogSelectedFilterString,
  setDocumentGeneratorDocumentSearchText,
  setDocumentGeneratorDocumentSelectedOrderBy,
  setDocumentGeneratorDocumentSelectedPageSize,
  setDocumentGeneratorDocumentTappedId,
  setDocumentGeneratorTemplateSearchText,
  setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setDownloadRequestTimeoutDialogVisibility,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import DocumentPage from './document.presentation';

const getInitialValues = (state) => {
  const { documents, uiDocument, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiDocument;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? documents.data[tappedId] : {};

  const initVal = Object.keys(found || {}).length > 0
    ? transformInitialValues(found, {
      pageSize: found?.pageSize || '',
    })
    : {
      body: '',
      pageSize: '',
    };
  return initVal;
};


const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  templates: transformTemplateDropdownData(state.templates.data),
  loadingUser: state.uiUser.downloading,
  loadingTemplate: state.uiTemplate.downloading,
  initialValues: getInitialValues(state),
  downloading: state.uiDocument.downloading,
  downloadingDeleting: state.uiDocument.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloadingDocumentResult: state.uiDocument.downloadingDocumentResult,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_DOCUMENT_GENERATOR_DOCUMENT));
    dispatch(setDocumentGeneratorDocumentSearchText(''));
    dispatch(clearDocumentGeneratorDocuments());
    dispatch(setDocumentGeneratorDocumentSelectedPageSize(20));
    dispatch(setDocumentGeneratorDocumentSelectedOrderBy(
      INITIAL_ORDER_BY_DOCUMENT_GENERATOR_DOCUMENT,
    ));
    dispatch(downloadDocumentGeneratorDocumentsAsync());
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id').replace(/template/, 'documentTemplate.id');
    dispatch(setDocumentAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearDocumentGeneratorDocuments());
    dispatch(downloadDocumentGeneratorDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setDocumentAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearDocumentGeneratorDocuments());
    dispatch(
      setDocumentGeneratorDocumentSelectedOrderBy(INITIAL_ORDER_BY_DOCUMENT_GENERATOR_DOCUMENT),
    );
    dispatch(downloadDocumentGeneratorDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setDocumentGeneratorDocumentSelectedPageSize(pageSize));
    dispatch(clearDocumentGeneratorDocuments());
    dispatch(downloadDocumentGeneratorDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setDocumentGeneratorDocumentSearchText(text));
    dispatch(clearDocumentGeneratorDocuments());
    dispatch(downloadDocumentGeneratorDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setDocumentGeneratorDocumentSelectedOrderBy(orderBy));
    dispatch(clearDocumentGeneratorDocuments());
    dispatch(downloadDocumentGeneratorDocumentsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDocumentGeneratorDocumentSelectedPageSize(pageSize));
    dispatch(downloadDocumentGeneratorDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDocumentGeneratorDocumentsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_DOCUMENT));
  },
  onViewPressed: (id) => {
    dispatch(setDocumentGeneratorDocumentTappedId(id));
    dispatch(downloadDocumentGeneratorDocumentAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeTemplateText: async (text) => {
    try {
      dispatch(setDocumentGeneratorTemplateSearchText(text));
      dispatch(clearDocumentGeneratorTemplates());
      await dispatch(downloadDocumentGeneratorTemplatesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadPressed: () => {
    dispatch(downloadDocumentResultAsync())
      .then((url) => {
        const a = document.createElement('a');
        a.setAttribute('id', 'documentId');
        a.setAttribute('href', url);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }).catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onAdvancedFilterPressed: (text) => {
    dispatch(setUserSearchText(text));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setDocumentGeneratorTemplateSearchText(text));
    dispatch(clearDocumentGeneratorTemplates());
    dispatch(downloadDocumentGeneratorTemplatesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPage);
