import {
  addPickupLocation, clearPickupLocations, downloadingDeletingPickupLocation, setAlertErrorMessage,
  setFunctionalPageMode, setPickupLocationTappedId,
} from '../simple-action';
import { downloadPickupLocation, deletePickupLocation } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadPickupLocationsAsync from './downloadPickupLocationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPickupLocation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPickupLocation;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deletePickupLocation(tappedId, token);
      dispatch(setPickupLocationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearPickupLocations());
      dispatch(downloadPickupLocationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const pickupLocation = await downloadPickupLocation(tappedId, token);
      dispatch(addPickupLocation(pickupLocation));
    }
  } finally {
    dispatch(downloadingDeletingPickupLocation(false));
  }
};
