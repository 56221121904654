import {
  DOWNLOADING_TOKENS, DOWNLOADING_DELETING_TOKEN, DOWNLOADING_APP_VERSIONS,
  DOWNLOADING_DEVICE_MANUFACTURERS, DOWNLOADING_DEVICE_MODELS, DOWNLOADING_OS_NAMES,
  DOWNLOADING_OS_VERSIONS, DOWNLOADING_TIME_ZONES, SET_APP_VERSION_SEARCH_TEXT,
  SET_DEVICE_MANUFACTURER_SEARCH_TEXT, SET_DEVICE_MODEL_SEARCH_TEXT,
  SET_OS_NAME_SEARCH_TEXT, SET_OS_VERSION_SEARCH_TEXT, SET_TIME_ZONE_SEARCH_TEXT,
  SET_TOKEN_SELECTED_PAGE_SIZE, SET_TOKEN_TAPPED_ID, SET_TOKEN_SEARCH_TEXT,
  SET_TOKEN_SELECTED_ORDER_BY, SENDING_TOKEN_NOTIFICATION,
} from '../action';
import { INITIAL_ORDER_BY_TOKENS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  downloadingAppVersions: false,
  downloadingAndroidDeviceModels: false,
  downloadingDeviceManufacturers: false,
  downloadingDeviceManufacturerDistributions: false,
  downloadingDeviceModels: false,
  downloadingIosDeviceModels: false,
  downloadingOsNames: false,
  downloadingOsVersions: false,
  downloadingTimeZones: false,
  sendingNotification: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  appVersionSearchText: '',
  androidDeviceModelSearchText: '',
  deviceManufacturerSearchText: '',
  deviceManufacturerDistributionSearchText: '',
  deviceModelSearchText: '',
  iosDeviceModelSearchText: '',
  osNameSearchText: '',
  osVersionSearchText: '',
  timeZoneSearchText: '',
  orderBy: INITIAL_ORDER_BY_TOKENS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_TOKENS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_TOKEN:
      return { ...state, downloadingDeleting: action.status };
    case SET_TOKEN_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_TOKEN_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_TOKEN_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_TOKEN_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case DOWNLOADING_APP_VERSIONS:
      return { ...state, downloadingAppVersions: action.status };
    case DOWNLOADING_DEVICE_MANUFACTURERS:
      return { ...state, downloadingDeviceManufacturers: action.status };
    case DOWNLOADING_DEVICE_MODELS:
      return { ...state, downloadingDeviceModels: action.status };
    case DOWNLOADING_OS_NAMES:
      return { ...state, downloadingOsNames: action.status };
    case DOWNLOADING_OS_VERSIONS:
      return { ...state, downloadingOsVersions: action.status };
    case DOWNLOADING_TIME_ZONES:
      return { ...state, downloadingTimeZones: action.status };
    case SET_APP_VERSION_SEARCH_TEXT:
      return { ...state, appVersionSearchText: action.text };
    case SET_DEVICE_MANUFACTURER_SEARCH_TEXT:
      return { ...state, deviceManufacturerSearchText: action.text };
    case SET_DEVICE_MODEL_SEARCH_TEXT:
      return { ...state, deviceModelSearchText: action.text };
    case SET_OS_NAME_SEARCH_TEXT:
      return { ...state, osNameSearchText: action.text };
    case SET_OS_VERSION_SEARCH_TEXT:
      return { ...state, osVersionSearchText: action.text };
    case SET_TIME_ZONE_SEARCH_TEXT:
      return { ...state, timeZoneSearchText: action.text };
    case SENDING_TOKEN_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    default: return state;
  }
};
