import { reset } from 'redux-form';
import { CONTENT_FORMAT_PLAINTEXT, PAGE_MODE_TABLE, RXFORM_FORUM_POST } from '../../../constant';
import { addForumPost, editForumPost, toNumber } from '../../../helper';
import {
  addingEditingForumPost, clearForumPosts, setAlertErrorMessage, setFunctionalPageMode,
  setForumPostSelectedCategory, setForumPostSelectedLocked, setForumPostSelectedPinned,
  setForumPostSelectedContentFormat,
} from '../simple-action';
import downloadForumPostsAsync from './downloadForumPostsAsync';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

export default (title, content, media) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingForumPost(true));

    const { token } = getState().authentication;
    const { data } = getState().forumPosts;
    const {
      tappedId, selectedCategory, selectedLocked, selectedPinned, selectedContentFormat,
    } = getState().uiForumPost;
    const found = data[tappedId];
    const category = selectedCategory.value || (found ? found.category.id : '');
    const foundLocked = found ? found.locked : false;
    const foundPinned = found ? found.pinned : false;
    const locked = selectedLocked ? selectedLocked.value : foundLocked;
    const pinned = selectedPinned ? selectedPinned.value : foundPinned;
    const contentFormat = selectedContentFormat || (found
      ? found.contentFormat : CONTENT_FORMAT_PLAINTEXT);
    const medias = media.length > 0 ? transformMedia(media) : [];

    if (tappedId) {
      await editForumPost(tappedId, title, content, contentFormat, category, locked,
        pinned, medias, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addForumPost(title, content, contentFormat, category, locked, pinned,
        medias, token);
    }

    dispatch(reset(RXFORM_FORUM_POST));
    dispatch(setForumPostSelectedCategory(''));
    dispatch(setForumPostSelectedLocked(''));
    dispatch(setForumPostSelectedPinned(''));
    dispatch(setForumPostSelectedContentFormat(''));
    dispatch(clearForumPosts());
    dispatch(downloadForumPostsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingForumPost(false));
  }
};
