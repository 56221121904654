import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteChallenge, downloadChallenge } from '../../../helper';
import {
  addChallenge, clearChallenges, downloadingDeletingChallenge, setAlertErrorMessage,
  setChallengeTappedId, setFunctionalPageMode,
} from '../simple-action';
import downloadChallengesAsync from './downloadChallengesAsync';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingChallenge(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiChallenge;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteChallenge(tappedId, token);
      dispatch(setChallengeTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearChallenges());
      dispatch(downloadChallengesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadChallenge(tappedId, token);
      dispatch(addChallenge(result));
    }
  } finally {
    dispatch(downloadingDeletingChallenge(false));
  }
};
