import {
  DOWNLOADING_DISCUSSIONS,
  SET_DISCUSSION_SELECTED_PAGE_SIZE,
  SET_DISCUSSION_TAPPED_ID, SET_DISCUSSION_SEARCH_TEXT,
  SET_DISCUSSION_SELECTED_ORDER_BY, SET_DISCUSSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';
import { INITIAL_ORDER_BY_DISCUSSION } from '../../constant';

const initialState = {
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_DISCUSSION,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_DISCUSSIONS:
      return { ...state, downloading: action.status };
    case SET_DISCUSSION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_DISCUSSION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_DISCUSSION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_DISCUSSION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_DISCUSSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
