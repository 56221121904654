import {
  addRegistration, downloadingDeletingRegistration, setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';
import { downloadRegistration } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistration;

    const registration = await downloadRegistration(tappedId, token);
    dispatch(addRegistration(registration));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingRegistration(false));
  }
};
