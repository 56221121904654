import {
  SET_CREATE_ANNOTATION_DIALOG_PDF_TYPE, SET_CREATE_ANNOTATION_DIALOG_VISIBILITY,
} from '../action';

const initialState = {
  title: '',
  dialogType: '',
  pdfType: '',
  dialogVisibility: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CREATE_ANNOTATION_DIALOG_VISIBILITY:
      return {
        ...state,
        dialogVisibility: action.status,
        dialogType: action.dialogType,
        title: action.title,
      };
    case SET_CREATE_ANNOTATION_DIALOG_PDF_TYPE:
      return {
        ...state, pdfType: action.pdfType,
      };
    default: return state;
  }
};
