import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_COVID_DATAS = 'DOWNLOADING_COVID_DATAS';
export const downloadingCovidDatas = makeActionCreator(DOWNLOADING_COVID_DATAS, 'status');

export const SET_COVID_DATAS = 'SET_COVID_DATAS';
export const setCovidDatas = makeActionCreator(SET_COVID_DATAS, 'data');

export const ADD_COVID_DATA = 'ADD_COVID_DATA';
export const addCovidData = makeActionCreator(ADD_COVID_DATA, 'data');

export const CLEAR_COVID_DATAS = 'CLEAR_COVID_DATAS';
export const clearCovidDatas = makeActionCreator(CLEAR_COVID_DATAS);

export const ADDING_EDITING_COVID_DATA = 'ADDING_EDITING_COVID_DATA';
export const addingEditingCovidData = makeActionCreator(ADDING_EDITING_COVID_DATA, 'status');

export const DOWNLOADING_DELETING_COVID_DATA = 'DOWNLOADING_DELETING_COVID_DATA';
export const downloadingDeletingCovidData = makeActionCreator(DOWNLOADING_DELETING_COVID_DATA, 'status');

export const SAVING_COVID_DATA = 'SAVING_COVID_DATA';
export const savingCovidData = makeActionCreator(SAVING_COVID_DATA, 'status');


export const SET_COVID_DATA_SELECTED_PAGE_SIZE = 'SET_COVID_DATA_SELECTED_PAGE_SIZE';
export const setCovidDataSelectedPageSize = makeActionCreator(SET_COVID_DATA_SELECTED_PAGE_SIZE, 'size');

export const SET_COVID_DATA_SELECTED_ORDER_BY = 'SET_COVID_DATA_SELECTED_ORDER_BY';
export const setCovidDataSelectedOrderBy = makeActionCreator(SET_COVID_DATA_SELECTED_ORDER_BY, 'order');

export const SET_COVID_DATA_TAPPED_ID = 'SET_COVID_DATA_TAPPED_ID';
export const setCovidDataTappedId = makeActionCreator(SET_COVID_DATA_TAPPED_ID, 'id');

export const SET_COVID_DATA_SEARCH_TEXT = 'SET_COVID_DATA_SEARCH_TEXT';
export const setCovidDataSearchText = makeActionCreator(SET_COVID_DATA_SEARCH_TEXT, 'text');

export const SET_COVID_DATA_SELECTED_EMPLOYEE_STATUS = 'SET_COVID_DATA_SELECTED_EMPLOYEE_STATUS';
export const setCovidDataSelectedEmployeeStatus = makeActionCreator(SET_COVID_DATA_SELECTED_EMPLOYEE_STATUS, 'option');


export const SET_COVID_DASHBOARD_ACTIVE_TAB = 'SET_COVID_DASHBOARD_ACTIVE_TAB';
export const setCovidDashboardActiveTab = makeActionCreator(SET_COVID_DASHBOARD_ACTIVE_TAB, 'index');

export const SET_COVID_DASHBOARD_SELECTED_PERIOD = 'SET_COVID_DASHBOARD_SELECTED_PERIOD';
export const setCovidDashboardSelectedPeriod = makeActionCreator(SET_COVID_DASHBOARD_SELECTED_PERIOD, 'filter');

export const SET_COVID_DASHBOARD_SELECTED_EMPLOYEE_STATUS = 'SET_COVID_DASHBOARD_SELECTED_EMPLOYEE_STATUS';
export const setCovidDashboardSelectedEmployeeStatus = makeActionCreator(SET_COVID_DASHBOARD_SELECTED_EMPLOYEE_STATUS, 'filter');


export const DOWNLOADING_COVID_DATA_TOTAL_SUMMARY = 'DOWNLOADING_COVID_DATA_TOTAL_SUMMARY';
export const downloadingCovidDataTotalSummary = makeActionCreator(DOWNLOADING_COVID_DATA_TOTAL_SUMMARY, 'status');

export const SET_COVID_DATA_TOTAL_SUMMARY = 'SET_COVID_DATA_TOTAL_SUMMARY';
export const setCovidDataTotalSummary = makeActionCreator(SET_COVID_DATA_TOTAL_SUMMARY, 'data');

export const CLEAR_COVID_DATA_TOTAL_SUMMARY = 'CLEAR_COVID_DATA_TOTAL_SUMMARY';
export const clearCovidDataTotalSummary = makeActionCreator(CLEAR_COVID_DATA_TOTAL_SUMMARY);


export const DOWNLOADING_COVID_DATA_TREND_SUMMARY = 'DOWNLOADING_COVID_DATA_TREND_SUMMARY';
export const downloadingCovidDataTrendSummary = makeActionCreator(DOWNLOADING_COVID_DATA_TREND_SUMMARY, 'status');

export const SET_COVID_DATA_TREND_SUMMARY = 'SET_COVID_DATA_TREND_SUMMARY';
export const setCovidDataTrendSummary = makeActionCreator(SET_COVID_DATA_TREND_SUMMARY, 'data');

export const CLEAR_COVID_DATA_TREND_SUMMARY = 'CLEAR_COVID_DATA_TREND_SUMMARY';
export const clearCovidDataTrendSummary = makeActionCreator(CLEAR_COVID_DATA_TREND_SUMMARY);
