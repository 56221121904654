import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, Typography, makeStyles, InputAdornment, IconButton, Snackbar,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import {
  CloudDownload, FileCopyOutlined,
} from '@material-ui/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  FunctionalPage, SectionTitle, PieChart, AccentButton,
} from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_SURVEY_TITLE, RXFIELD_SURVEY_DESCRIPTION,
  RXSTATE_SURVEYS, RXSTATE_SURVEY_PAGE, RXFORM_SURVEY,
  RXFIELD_SURVEY_CATEGORY,
  RXFIELD_SURVEY_VALID_FROM,
  RXFIELD_SURVEY_VALID_UNTIL,
  RXFIELD_SURVEY_VISIBILITY_STATUS,
  RXFIELD_SURVEY_PARTICIPANT_COUNT,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  FILTER_TYPE_DROPDOWN,
  COLOR_DISABLED_ROW,
  COLOR_BACKGROUND,
  ENUM_FIELD_OPTIONS,
  ENUM_FIELD_PICKER,
  ENUM_FIELD_BOOLEAN,
  COLOR_PRIMARY,
  RXFIELD_SURVEY_DEEPLINK,
  visibilityStatuses,
  snackbarDuration,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField, renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateSurvey } from '../../validation';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  horizontalSpacer: {
    width: 10,
  },
  saveButtonWrapper: {
    marginLeft: 10,
  },
  saveButtonLabel: {
    width: '0%',
  },
  buttonContainer: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  italicButton: {
    fontStyle: 'italic',
    color: COLOR_PRIMARY,
    marginRight: 10,
  },
}));

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode,
  onVisibilityStatusOptionSelected,
  categories, loadingCategory, onChangeCategoryText,
  onCategoryOptionSelected, initialValues,
  onShowParticipantPressed, onDownloadPressed,
  history, tappedId, saving, classes,
  onCopyPressed, copying,
) => {
  const optionsType = initialValues.result && initialValues.result
    .filter((x) => x.fieldType === ENUM_FIELD_OPTIONS || x.fieldType === ENUM_FIELD_PICKER
    || x.fieldType === ENUM_FIELD_BOOLEAN);

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SURVEY_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.surveyScreen.placeholderTitle}
              label={LocalizedString.surveyScreen.placeholderTitle}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SURVEY_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.surveyScreen.placeholderDescription}
              label={LocalizedString.surveyScreen.placeholderDescription}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SURVEY_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.surveyScreen.placeholderCategory}
              label={LocalizedString.surveyScreen.placeholderCategory}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={categories}
              loading={loadingCategory}
              onChangeText={onChangeCategoryText}
              onOptionSelected={onCategoryOptionSelected}
              required
            />
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <Grid item sm md>
              <Field
                name={RXFIELD_SURVEY_PARTICIPANT_COUNT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.surveyScreen.placeholderParticipantCount}
                label={LocalizedString.surveyScreen.placeholderParticipantCount}
                disabled
                hidden={pageMode !== PAGE_MODE_VIEW}
              />
            </Grid>
            {pageMode === PAGE_MODE_VIEW && (
            <Grid item>
              <div className={classes.buttonsContainer}>
                <AccentButton
                  onClick={() => onShowParticipantPressed(tappedId, initialValues.title, history)}
                  caption={LocalizedString.surveyScreen.buttonCaptionShowParticipant}
                  disabled={!initialValues[RXFIELD_SURVEY_PARTICIPANT_COUNT]}
                />
                <div className={classes.horizontalSpacer} />
                <AccentButton
                  onClick={onDownloadPressed}
                  startIcon={<CloudDownload />}
                  caption=""
                  disabled={
                          addingEditing || downloadingDeleting
                          || !initialValues[RXFIELD_SURVEY_PARTICIPANT_COUNT]
                        }
                  loading={saving}
                  variant="outlined"
                  classes={{
                    startIcon: classes.saveButtonWrapper,
                    label: classes.saveButtonLabel,
                    root: classes.saveButtonWrapper,
                  }}
                />
              </div>
            </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SURVEY_VALID_FROM}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.surveyScreen.placeholderValidFrom}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SURVEY_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.surveyScreen.placeholderValidUntil}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SURVEY_VISIBILITY_STATUS}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.surveyScreen.placeholderVisibilityStatus}
              label={LocalizedString.surveyScreen.placeholderVisibilityStatus}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={visibilityStatuses}
              onOptionSelected={onVisibilityStatusOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SURVEY_DEEPLINK}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.surveyScreen.placeholderDeeplink}
              label={LocalizedString.surveyScreen.placeholderDeeplink}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.deepLink}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={copying}
          autoHideDuration={snackbarDuration}
          message={LocalizedString.surveyScreen.labelCopied}
          onClose={() => onCopyPressed(copying)}
        />
      </Grid>

      {pageMode === PAGE_MODE_VIEW && !isEmpty(optionsType)
    && (<SectionTitle title={LocalizedString.surveyScreen.labelSurveyResult} />)}

      {pageMode === PAGE_MODE_VIEW && !isEmpty(optionsType) && optionsType.map((item) => {
        const total = item.result.reduce((p, c) => p + c.value, 0);

        const getOrder = optionsType.findIndex((x) => x.id === item.id) + 1;

        return (
          <Grid container spacing={3} key={item.id}>
            <Grid item sm md>
              <Grid item>
                <Typography variant="subtitle2">
                  {`${getOrder}. ${item.label}`}
                </Typography>
              </Grid>
              {item.result.map((answer) => (
                <Grid item key={answer.key}>
                  <ul>
                    <Typography variant="body2">
                      <li>{`${answer.key}: ${parseFloat(((answer.value / total) * 100).toFixed(2))}%`}</li>
                    </Typography>
                  </ul>
                </Grid>
              ))}
            </Grid>
            <Grid item sm md>
              <PieChart
                data={item.result}
                loading={downloadingDeleting}
                argumentField="key"
                title={item?.title || ''}
                valueField="value"
                height={item.result.length < 4 ? 200 : item.result.length * 64}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const SurveyPage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed, onVisibilityStatusOptionSelected,
  categories, loadingCategory, onChangeCategoryText,
  onCategoryOptionSelected,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onResetAdvancedFilterPressed,
  initialValues, onShowParticipantPressed, onDownloadPressed,
  history, tappedId, saving, sendingNotification, onSendToMePressed,
  onCopyPressed, copying,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_SURVEYS}
      uiPage={RXSTATE_SURVEY_PAGE}
      tableColumns={[
        {
          title: LocalizedString.surveyScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.surveyScreen.labelTitle, field: 'title', sorting: !downloading },
        {
          title: LocalizedString.surveyScreen.labelValidFrom,
          field: 'validFrom',
          sorting: !downloading,
          render: ({ validFrom }) => (validFrom
            ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.surveyScreen.labelValidUntil,
          field: 'validUntil',
          sorting: !downloading,
          render: ({ validUntil }) => (validUntil
            ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.surveyScreen.labelCategory, field: 'category.name', sorting: !downloading },
        { title: LocalizedString.surveyScreen.labelVisibilityStatus, field: 'visibilityStatus', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.surveyScreen.placeholderValidFrom,
          field: RXFIELD_SURVEY_VALID_FROM,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.surveyScreen.placeholderValidUntil,
          field: RXFIELD_SURVEY_VALID_UNTIL,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.surveyScreen.placeholderCategory,
          field: RXFIELD_SURVEY_CATEGORY,
          type: FILTER_TYPE_DROPDOWN,
          data: categories,
          loading: loadingCategory,
          onChangeFilterText: onChangeCategoryText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.surveyScreen.placeholderVisibilityStatus,
          field: RXFIELD_SURVEY_VISIBILITY_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: visibilityStatuses,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmDeletePressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      createNewButtonCaption={
      LocalizedString.surveyScreen.buttonCaptionCreateNewSurvey
    }
      editButtonCaption={LocalizedString.surveyScreen.buttonCaptionEditSurvey}
      deleteButtonCaption={LocalizedString.surveyScreen.buttonCaptionDeleteSurvey}
      title={LocalizedString.surveyScreen.title}
      useFullWidth
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      rowStyle={(rowData) => ({
        backgroundColor: rowData.visibilityStatus === visibilityStatuses[0]
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
    >
      {pageMode === PAGE_MODE_VIEW && (
      <div className={classes.buttonContainer}>
        <AccentButton
          variant="text"
          className={classes.italicButton}
          disabled={downloadingDeleting || addingEditing}
          loading={sendingNotification}
          disableElevation
          caption={LocalizedString.surveyScreen.buttonCaptionSendToMe}
          onClick={onSendToMePressed}
          size="small"
        />
      </div>
      )}
      {renderDialogContent(
        addingEditing, downloadingDeleting, pageMode,
        onVisibilityStatusOptionSelected,
        categories, loadingCategory, onChangeCategoryText,
        onCategoryOptionSelected, initialValues,
        onShowParticipantPressed, onDownloadPressed,
        history, tappedId, saving, classes,
        onCopyPressed, copying,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_SURVEY,
  validate: rxformValidateSurvey,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SurveyPage);

SurveyPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  categories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingCategory: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onShowParticipantPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onSendToMePressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
