import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, CircularProgress, FormControl, FormHelperText, makeStyles, TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons/';
import { COLOR_PRIMARY, COLOR_BODY_TEXT, COLOR_DANGEROUS } from '../constant';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  textField: {
    display: 'flex',
    alignItems: 'stretch',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const AutocompleteCheckboxField = ({
  options,
  disabled, error, loading,
  onChangeText, onOptionSelected,
  helperText, value,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <div className={classes.container}>
      <FormControl className={classes.form} variant="outlined" error={error}>
        <Autocomplete
          disableCloseOnSelect
          multiple
          value={value || []}
          inputValue={inputValue}
          disabled={disabled}
          loading={loading}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          getOptionLabel={(option) => (typeof option === 'object' ? option.label : (option || ''))}
          getOptionSelected={(option, selectedValue) => {
            if (typeof option === 'object') {
              return option.value === selectedValue.value;
            }
            return option === selectedValue || '';
          }}
          onChange={(event, option) => onOptionSelected(option)}
          onInputChange={(event, text, reason) => {
            if (reason === 'input') {
              setInputValue(text);
              onChangeText(text);
            }
          }}
          options={options}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {typeof option === 'object' ? option.label : (option || '')}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Asynchronous"
              variant="outlined"
              className={classes.textField}
              disabled={disabled}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              {...props}
            />
          )}
        />
        <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default AutocompleteCheckboxField;

AutocompleteCheckboxField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onChangeText: PropTypes.func,
  onOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
};

AutocompleteCheckboxField.defaultProps = {
  disabled: false,
  error: false,
  loading: false,
  onChangeText: () => {},
  onOptionSelected: () => {},
  helperText: '',
  value: [],
};
