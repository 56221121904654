import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { AccentButton, FunctionalPage } from '../../component';
import {
  FILE_EXTENSION_SPREADSHEET, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, IMPORT_STATUSES,
  PAGE_MODE_VIEW, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_HOLIDAY_DAY, RXFIELD_HOLIDAY_DESCRIPTION, RXFIELD_HOLIDAY_FILE,
  RXFIELD_HOLIDAY_IMPORT_STATUS_NOTES, RXFIELD_HOLIDAY_IMPORT_STATUS, RXFIELD_HOLIDAY_MONTH,
  RXFIELD_HOLIDAY_YEAR, RXFORM_HOLIDAY, RXSTATE_HOLIDAY_PAGE, RXSTATE_HOLIDAYS,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormFilePickerField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { rxformValidateHoliday } from '../../validation';
import { FormInitialValueShape } from '../../type';

const renderDialogContent = (year, pageMode, onFileSelected, addingEditing, defaultYearValue,
  onDownloadTemplatePressed, onYearSelected, templateUrl) => {
  if (pageMode !== PAGE_MODE_VIEW) {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_HOLIDAY_YEAR}
                component={renderReduxFormSimpleDropdownField}
                placeholder={LocalizedString.holidayPage.placeholderYear}
                label={LocalizedString.holidayPage.placeholderYear}
                value={defaultYearValue}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                data={year}
                onOptionSelected={onYearSelected}
                required
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_HOLIDAY_FILE}
                component={renderReduxFormFilePickerField}
                label={LocalizedString.holidayPage.labelFile}
                disabled={addingEditing}
                onFileSelected={onFileSelected}
                acceptedFileExtension={FILE_EXTENSION_SPREADSHEET}
                required
                onBlur={(e) => e.preventDefault()}
                usePreview={false}
              />
              <AccentButton
                variant="text"
                caption={GlobalLocalizedString.common.buttonCaptionDownloadTemplate}
                onClick={() => onDownloadTemplatePressed(templateUrl)}
                disabled={addingEditing}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_HOLIDAY_DAY}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.holidayPage.placeholderDay}
              label={LocalizedString.holidayPage.placeholderDay}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_HOLIDAY_MONTH}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.holidayPage.placeholderMonth}
              label={LocalizedString.holidayPage.placeholderMonth}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_HOLIDAY_YEAR}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.holidayPage.placeholderYear}
              label={LocalizedString.holidayPage.placeholderYear}
              value={defaultYearValue}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={year}
              onOptionSelected={onYearSelected}
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_HOLIDAY_IMPORT_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.holidayPage.placeholderImportStatus}
              label={LocalizedString.holidayPage.placeholderImportStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_HOLIDAY_IMPORT_STATUS_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.holidayPage.placeholderImportStatusNote}
              label={LocalizedString.holidayPage.placeholderImportStatusNote}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_HOLIDAY_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.holidayPage.placeholderDescription}
              label={LocalizedString.holidayPage.placeholderDescription}
              disabled
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const HolidayPage = ({
  downloading, initialValues,
  handleSubmit, onSubmitPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onCancelPressed, onChangePage,
  onChangePageSize, onRefresh, year, selectedYear, onYearSelected,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  pageMode, onFileSelected, addingEditing, onCreatePressed, onDownloadTemplatePressed,
  templateUrl,
}) => (
  <FunctionalPage
    data={RXSTATE_HOLIDAYS}
    uiPage={RXSTATE_HOLIDAY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.holidayPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => rowData,
      },
      { title: LocalizedString.holidayPage.labelDay, field: 'day', sorting: false },
      { title: LocalizedString.holidayPage.labelMonth, field: 'month', sorting: !downloading },
      { title: LocalizedString.holidayPage.labelYear, field: 'year', sorting: !downloading },
      { title: LocalizedString.holidayPage.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.holidayPage.labelImportStatus, field: 'importStatus', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.holidayPage.labelImportStatus,
        field: 'importStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: IMPORT_STATUSES,
      },
      {
        title: LocalizedString.holidayPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    onSubmitPressed={onSubmitPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCreatePressed={onCreatePressed}
    onConfirmDeletePressed={() => {}}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.holidayPage.title}
    createNewButtonCaption={LocalizedString.holidayPage.buttonCaptionCreateNewHoliday}
    useFullWidth
    disableEdit
    disableDelete
  >
    {renderDialogContent(year, pageMode, onFileSelected, addingEditing,
      (selectedYear || initialValues.year), onDownloadTemplatePressed, onYearSelected, templateUrl)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_HOLIDAY,
  validate: rxformValidateHoliday,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(HolidayPage);

HolidayPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  year: PropTypes.arrayOf(PropTypes.string).isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadTemplatePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onYearSelected: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired,
  templateUrl: PropTypes.string.isRequired,
};
