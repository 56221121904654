import {
  downloadingSummaryAndroidDeviceModelDistributions,
  setSummaryAndroidDeviceModelDistributions,
} from '../simple-action';
import {
  downloadSummaryAndroidDeviceModelDistribution,
} from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingSummaryAndroidDeviceModelDistributions(true));

    const list = await downloadSummaryAndroidDeviceModelDistribution(
      token,
    );

    if (list) {
      dispatch(setSummaryAndroidDeviceModelDistributions(list));
    }
  } finally {
    dispatch(downloadingSummaryAndroidDeviceModelDistributions(false));
  }
};
