import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_POINT_TRANSACTIONS,
  MENUID_POINT_POINT_TRANSACTION, PAGE_MODE_TABLE, RXFORM_POINT_TRANSACTION,
} from '../../constant';
import { transformInitialValues, transformDropdownData, toMoment } from '../../helper';
import {
  clearPointTransactions, clearUsers, setActiveSideMenuItem, setAlertErrorMessage,
  setPointTransactionAdvancedFilterDialogSelectedFilterString, setPointTransactionSearchText,
  setPointTransactionSelectedPageSize, setPointTransactionSelectedOrderBy,
  setPointTransactionTappedId, setUserSearchText, setUserAdvancedFilterDialogSelectedFilterString,
  downloadPointTransactionAsync, downloadPointTransactionsAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import PointTransactionPage from './point-transaction.presentation';

const getInitialValues = (state) => {
  const {
    pointTransactions, uiFunctionalPage, uiPointTransaction,
  } = state;
  const { data } = pointTransactions;
  const { downloadingDeleting, tappedId } = uiPointTransaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user.fullName,
    effectiveDate: found.effectiveDate
      ? toMoment(found.effectiveDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    items: isEmpty(found.items) ? found.items : found.items.map((item, count) => ({
      ...item,
      no: count + 1,
    })),
  }) : {
    effectiveDate: '',
    transactionNumber: '',
    type: '',
    referenceId: '',
    tags: null,
    amount: '',
    label: '',
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    fullName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPointTransaction.downloading,
  initialValues: getInitialValues(state),
  users: transformDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setPointTransactionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_POINT_POINT_TRANSACTION));
    dispatch(setPointTransactionSearchText(''));
    dispatch(clearPointTransactions());
    dispatch(setPointTransactionSelectedPageSize(20));
    dispatch(setPointTransactionSelectedOrderBy(INITIAL_ORDER_BY_POINT_TRANSACTIONS));
    dispatch(downloadPointTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setPointTransactionAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPointTransactions());
    dispatch(downloadPointTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_POINT_TRANSACTION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPointTransactionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPointTransactionSelectedPageSize(pageSize));
    dispatch(downloadPointTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPointTransactionSelectedPageSize(pageSize));
    dispatch(clearPointTransactions());
    dispatch(downloadPointTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setPointTransactionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPointTransactions());
    dispatch(setPointTransactionSelectedOrderBy(INITIAL_ORDER_BY_POINT_TRANSACTIONS));
    dispatch(downloadPointTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPointTransactionSearchText(text));
      dispatch(clearPointTransactions());
      await dispatch(downloadPointTransactionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPointTransactionSelectedOrderBy(orderBy));
    dispatch(clearPointTransactions());
    dispatch(downloadPointTransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setPointTransactionTappedId(id));
    dispatch(downloadPointTransactionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PointTransactionPage);
