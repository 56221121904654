import {
  RXFIELD_GAMEQR_CATEGORY_NAME, RXFIELD_GAMEQR_CATEGORY_DESCRIPTION,
  RXFIELD_CHALLENGE_TITLE, RXFIELD_CHALLENGE_CONTENT, RXFIELD_CHALLENGE_VALID_FROM,
  RXFIELD_CHALLENGE_VALID_UNTIL, RXFIELD_CHALLENGE_CATEGORY, RXFIELD_CHALLENGE_VISIBILITY_STATUS,
  RXFIELD_CHALLENGE_SCAN_POLICY, RXFIELD_CHALLENGE_MEDIA, RXFIELD_CHALLENGE_CODES,
  RXFIELD_CHALLENGE_IS_PROMOTED, RXFIELD_CHALLENGE_ORDER,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';

export const rxformValidateFormGameQRCategory = (values) => {
  const requiredFields = [
    RXFIELD_GAMEQR_CATEGORY_NAME,
    RXFIELD_GAMEQR_CATEGORY_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateChallenge = (values) => {
  let requiredFields = [
    RXFIELD_CHALLENGE_TITLE,
    RXFIELD_CHALLENGE_CONTENT,
    RXFIELD_CHALLENGE_VALID_FROM,
    RXFIELD_CHALLENGE_VALID_UNTIL,
    RXFIELD_CHALLENGE_CATEGORY,
    RXFIELD_CHALLENGE_VISIBILITY_STATUS,
    RXFIELD_CHALLENGE_SCAN_POLICY,
    RXFIELD_CHALLENGE_MEDIA,
    RXFIELD_CHALLENGE_CODES,
    RXFIELD_CHALLENGE_IS_PROMOTED,
  ];
  const isOrderRequired = values.isPromoted
    ? [...requiredFields, RXFIELD_CHALLENGE_ORDER] : requiredFields;
  requiredFields = isOrderRequired;

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
