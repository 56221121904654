import PropTypes from 'prop-types';

export * from '../../type';

export const AnnotationShape = PropTypes.shape({
  title: PropTypes.string,
  type: PropTypes.string,
  page: PropTypes.number,
  fontName: PropTypes.string,
  fontStyle: PropTypes.string,
  fontSize: PropTypes.string,
  rotation: PropTypes.string,
  coordinateX: PropTypes.string,
  coordinateY: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
});
