import {
  addEvent, clearEvents, downloadingDeletingEvent,
  setAlertErrorMessage, setFunctionalPageMode, setEventTappedId,
} from '../simple-action';
import { downloadEvent, deleteEvent } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadEventsAsync from './downloadEventsAsync';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { tappedId } = getState().uiEvent;
  const { confirmation } = getState().alert;

  try {
    dispatch(downloadingDeletingEvent(true));

    if (confirmation) {
      await deleteEvent(tappedId, token);
      dispatch(setEventTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEvents());
      dispatch(downloadEventsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const event = await downloadEvent(tappedId, token);
      dispatch(addEvent(event));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingEvent(false));
  }
};
