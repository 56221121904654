import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_DISCUSSION_COMMENTS = 'DOWNLOADING_DISCUSSION_COMMENTS';
export const downloadingDiscussionComments = makeActionCreator(DOWNLOADING_DISCUSSION_COMMENTS, 'status');

export const SET_DISCUSSION_COMMENTS = 'SET_DISCUSSION_COMMENTS';
export const setDiscussionComments = makeActionCreator(SET_DISCUSSION_COMMENTS, 'data');

export const ADD_DISCUSSION_COMMENT = 'ADD_DISCUSSION_COMMENT';
export const addDiscussionComment = makeActionCreator(ADD_DISCUSSION_COMMENT, 'data');

export const CLEAR_DISCUSSION_COMMENTS = 'CLEAR_DISCUSSION_COMMENTS';
export const clearDiscussionComments = makeActionCreator(CLEAR_DISCUSSION_COMMENTS);


export const SET_DISCUSSION_COMMENT_SELECTED_PAGE_SIZE = 'SET_DISCUSSION_COMMENT_SELECTED_PAGE_SIZE';
export const setDiscussionCommentSelectedPageSize = makeActionCreator(SET_DISCUSSION_COMMENT_SELECTED_PAGE_SIZE, 'size');

export const SET_DISCUSSION_COMMENT_SELECTED_ORDER_BY = 'SET_DISCUSSION_COMMENT_SELECTED_ORDER_BY';
export const setDiscussionCommentSelectedOrderBy = makeActionCreator(SET_DISCUSSION_COMMENT_SELECTED_ORDER_BY, 'order');

export const SET_DISCUSSION_COMMENT_TAPPED_ID = 'SET_DISCUSSION_COMMENT_TAPPED_ID';
export const setDiscussionCommentTappedId = makeActionCreator(SET_DISCUSSION_COMMENT_TAPPED_ID, 'id');

export const SET_DISCUSSION_COMMENT_SEARCH_TEXT = 'SET_DISCUSSION_COMMENT_SEARCH_TEXT';
export const setDiscussionCommentSearchText = makeActionCreator(SET_DISCUSSION_COMMENT_SEARCH_TEXT, 'text');

export const SET_DISCUSSION_COMMENT_SELECTED_USER = 'SET_DISCUSSION_COMMENT_SELECTED_USER';
export const setDiscussionCommentSelectedUser = makeActionCreator(SET_DISCUSSION_COMMENT_SELECTED_USER, 'option');


export const DOWNLOADING_DISCUSSIONS = 'DOWNLOADING_DISCUSSIONS';
export const downloadingDiscussions = makeActionCreator(DOWNLOADING_DISCUSSIONS, 'status');

export const SET_DISCUSSIONS = 'SET_DISCUSSIONS';
export const setDiscussions = makeActionCreator(SET_DISCUSSIONS, 'data');

export const ADD_DISCUSSION = 'ADD_DISCUSSION';
export const addDiscussion = makeActionCreator(ADD_DISCUSSION, 'data');

export const CLEAR_DISCUSSIONS = 'CLEAR_DISCUSSIONS';
export const clearDiscussions = makeActionCreator(CLEAR_DISCUSSIONS);


export const SET_DISCUSSION_SELECTED_PAGE_SIZE = 'SET_DISCUSSION_SELECTED_PAGE_SIZE';
export const setDiscussionSelectedPageSize = makeActionCreator(SET_DISCUSSION_SELECTED_PAGE_SIZE, 'size');

export const SET_DISCUSSION_SELECTED_ORDER_BY = 'SET_DISCUSSION_SELECTED_ORDER_BY';
export const setDiscussionSelectedOrderBy = makeActionCreator(SET_DISCUSSION_SELECTED_ORDER_BY, 'order');

export const SET_DISCUSSION_TAPPED_ID = 'SET_DISCUSSION_TAPPED_ID';
export const setDiscussionTappedId = makeActionCreator(SET_DISCUSSION_TAPPED_ID, 'id');

export const SET_DISCUSSION_SEARCH_TEXT = 'SET_DISCUSSION_SEARCH_TEXT';
export const setDiscussionSearchText = makeActionCreator(SET_DISCUSSION_SEARCH_TEXT, 'text');

export const SET_DISCUSSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_DISCUSSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setDiscussionAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_DISCUSSION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_REPORTED_COMMENTS = 'DOWNLOADING_REPORTED_COMMENTS';
export const downloadingReportedComments = makeActionCreator(DOWNLOADING_REPORTED_COMMENTS, 'status');

export const SET_REPORTED_COMMENTS = 'SET_REPORTED_COMMENTS';
export const setReportedComments = makeActionCreator(SET_REPORTED_COMMENTS, 'data');

export const ADD_REPORTED_COMMENT = 'ADD_REPORTED_COMMENT';
export const addReportedComment = makeActionCreator(ADD_REPORTED_COMMENT, 'data');

export const CLEAR_REPORTED_COMMENTS = 'CLEAR_REPORTED_COMMENTS';
export const clearReportedComments = makeActionCreator(CLEAR_REPORTED_COMMENTS);


export const SET_REPORTED_COMMENT_SELECTED_PAGE_SIZE = 'SET_REPORTED_COMMENT_SELECTED_PAGE_SIZE';
export const setReportedCommentSelectedPageSize = makeActionCreator(SET_REPORTED_COMMENT_SELECTED_PAGE_SIZE, 'size');

export const SET_REPORTED_COMMENT_SELECTED_ORDER_BY = 'SET_REPORTED_COMMENT_SELECTED_ORDER_BY';
export const setReportedCommentSelectedOrderBy = makeActionCreator(SET_REPORTED_COMMENT_SELECTED_ORDER_BY, 'order');

export const SET_REPORTED_COMMENT_TAPPED_ID = 'SET_REPORTED_COMMENT_TAPPED_ID';
export const setReportedCommentTappedId = makeActionCreator(SET_REPORTED_COMMENT_TAPPED_ID, 'id');

export const SET_REPORTED_COMMENT_SEARCH_TEXT = 'SET_REPORTED_COMMENT_SEARCH_TEXT';
export const setReportedCommentSearchText = makeActionCreator(SET_REPORTED_COMMENT_SEARCH_TEXT, 'text');
