import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, InputAdornment, Typography, makeStyles,
} from '@material-ui/core';
import { Email, Person, Smartphone } from '@material-ui/icons';
import { FunctionalPage, SectionTitle, SendNotificationDialog } from '../../component';
import {
  genders, COLOR_BACKGROUND, COLOR_DISABLED_ROW, DROPDOWN_OPTION_CLEAR, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW, PICKER_MODE_DATE, PICKER_MODE_TIME,
  RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, RXFIELD_PROFILE_BRANCH,
  RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, RXFIELD_PROFILE_COMPANY_NAME,
  RXFIELD_PROFILE_DATE_OF_BIRTH, RXFIELD_PROFILE_DEPARTMENT, RXFIELD_PROFILE_DIVISION,
  RXFIELD_PROFILE_EMAIL, RXFIELD_PROFILE_EMPLOYEE_ID, RXFIELD_PROFILE_END_AFTER_MIN,
  RXFIELD_PROFILE_END_BEFORE_MIN, RXFIELD_PROFILE_END_WORKING_HOUR, RXFIELD_PROFILE_FULLNAME,
  RXFIELD_PROFILE_GENDER, RXFIELD_PROFILE_JOB_TITLE, RXFIELD_PROFILE_MANAGER, RXFIELD_PROFILE_PHONE,
  RXFIELD_PROFILE_PROFILE_PICTURE, RXFIELD_PROFILE_ROLES, RXFIELD_PROFILE_START_AFTER_MIN,
  RXFIELD_PROFILE_START_BEFORE_MIN, RXFIELD_PROFILE_START_WORKING_HOUR, RXFIELD_PROFILE_STATUS,
  RXFIELD_PROFILE_USERNAME, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE, RXFORM_PROFILE, RXSTATE_PROFILES,
  RXSTATE_PROFILE_PAGE, STATUS_DISABLED, STATUS_ENABLED, status, timezoneOffset,
  RXFIELD_PROFILE_USER_CODE, RXFIELD_PROFILE_TAGS, COLOR_SECONDARY,
  RXFIELD_PROFILE_HOME_ADDRESS_ID, RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_NAME,
  RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_PHONE, RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_1,
  RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_2, RXFIELD_PROFILE_HOME_ADDRESS_REGION,
  RXFIELD_PROFILE_HOME_ADDRESS_POSTAL_CODE,
  RXFIELD_PROFILE_WORK_ADDRESS_ID, RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_NAME,
  RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_PHONE, RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_1,
  RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_2, RXFIELD_PROFILE_WORK_ADDRESS_REGION,
  RXFIELD_PROFILE_WORK_ADDRESS_POSTAL_CODE,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormDateTimePickerField, renderReduxFormImageInputField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateProfile } from '../../validation';

const useStyles = makeStyles(() => ({
  heading: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    margin: '0px 0px 20px 10px',
  },
}));

const renderDialogContent = (defaultBranchValue, defaultGenderValue, defaultProfilePictureValue,
  defaultWorkingHourTimeZoneValue, branches, addingEditing, loadingBranch, onAutoClockOutChanged,
  onBranchOptionSelected, onChangeBranchText, onEarliestClockInChanged, onEndWorkingHourChanged,
  onStartWorkingHourChanged, onTimeZoneOptionSelected, pageMode, classes) => (
    <div>
      <Field
        name={RXFIELD_PROFILE_PROFILE_PICTURE}
        component={renderReduxFormImageInputField}
        label={LocalizedString.profileScreen.placeholderProfilePicture}
        defaultValue={defaultProfilePictureValue}
        disabled
      />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_EMPLOYEE_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderEmployeeId}
              label={LocalizedString.profileScreen.placeholderEmployeeId}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_USERNAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderUsername}
              label={LocalizedString.profileScreen.placeholderUsername}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_FULLNAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderFullName}
              label={LocalizedString.profileScreen.placeholderFullName}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderEmail}
              label={LocalizedString.profileScreen.placeholderEmail}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_USER_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderUserCode}
              label={LocalizedString.profileScreen.placeholderUserCode}
              disabled
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_DATE_OF_BIRTH}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.profileScreen.placeholderDateOfBirth}
              disabled
              pickerMode={PICKER_MODE_DATE}
              disableFuture
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_GENDER}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileScreen.placeholderGender}
              label={LocalizedString.profileScreen.placeholderGender}
              disabled
              data={genders}
              value={defaultGenderValue}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderPhone}
              label={LocalizedString.profileScreen.placeholderPhone}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Smartphone />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_ROLES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderRoles}
              label={LocalizedString.profileScreen.placeholderRoles}
              multiline
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_TAGS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderTags}
              label={LocalizedString.profileScreen.placeholderTags}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.profileScreen.labelAddress} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Typography variant="subtitle1" className={classes.heading}>
            {LocalizedString.profileScreen.labelHomeAddress}
          </Typography>

          <Grid item>
            <Field
              name={RXFIELD_PROFILE_HOME_ADDRESS_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressId}
              label={LocalizedString.profileScreen.placeholderAddressId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressContactName}
              label={LocalizedString.profileScreen.placeholderAddressContactName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressContactPhone}
              label={LocalizedString.profileScreen.placeholderAddressContactPhone}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_1}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine1}
              label={LocalizedString.profileScreen.placeholderAddressAddressLine1}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_2}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine2}
              label={LocalizedString.profileScreen.placeholderAddressAddressLine2}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_HOME_ADDRESS_REGION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressRegion}
              label={LocalizedString.profileScreen.placeholderAddressRegion}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_HOME_ADDRESS_POSTAL_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressPostalCode}
              label={LocalizedString.profileScreen.placeholderAddressPostalCode}
              disabled
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Typography variant="subtitle1" className={classes.heading}>
            {LocalizedString.profileScreen.labelWorkAddress}
          </Typography>

          <Grid item>
            <Field
              name={RXFIELD_PROFILE_WORK_ADDRESS_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressId}
              label={LocalizedString.profileScreen.placeholderAddressId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressContactName}
              label={LocalizedString.profileScreen.placeholderAddressContactName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressContactPhone}
              label={LocalizedString.profileScreen.placeholderAddressContactPhone}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_1}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine1}
              label={LocalizedString.profileScreen.placeholderAddressAddressLine1}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_2}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine2}
              label={LocalizedString.profileScreen.placeholderAddressAddressLine2}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_WORK_ADDRESS_REGION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressRegion}
              label={LocalizedString.profileScreen.placeholderAddressRegion}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_WORK_ADDRESS_POSTAL_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderAddressPostalCode}
              label={LocalizedString.profileScreen.placeholderAddressPostalCode}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.profileScreen.labelCompanyInformation} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_JOB_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderJobTitle}
              label={LocalizedString.profileScreen.placeholderJobTitle}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_DEPARTMENT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderDepartment}
              label={LocalizedString.profileScreen.placeholderDepartment}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_DIVISION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderDivision}
              label={LocalizedString.profileScreen.placeholderDivision}
              disabled
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_COMPANY_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderCompanyName}
              label={LocalizedString.profileScreen.placeholderCompanyName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_BRANCH}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileScreen.placeholderBranch}
              label={LocalizedString.profileScreen.placeholderBranch}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={branches}
              value={defaultBranchValue}
              loading={loadingBranch}
              onOptionSelected={onBranchOptionSelected}
              onChangeText={onChangeBranchText}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_MANAGER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderManager}
              label={LocalizedString.profileScreen.placeholderManager}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.profileScreen.labelWorkingHour} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_PROFILE_START_WORKING_HOUR}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.profileScreen.placeholderStartWorkingHour}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onChangeDate={onStartWorkingHourChanged}
            pickerMode={PICKER_MODE_TIME}
          />
        </Grid>

        <Grid item sm md>
          <Field
            name={RXFIELD_PROFILE_END_WORKING_HOUR}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.profileScreen.placeholderEndWorkingHour}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onChangeDate={onEndWorkingHourChanged}
            pickerMode={PICKER_MODE_TIME}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Field
          name={RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE}
          component={renderReduxFormSimpleDropdownField}
          label={LocalizedString.profileScreen.placeholderWorkingHourTimeZone}
          placeholder={LocalizedString.profileScreen.placeholderWorkingHourTimeZone}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={[...timezoneOffset, DROPDOWN_OPTION_CLEAR]}
          value={defaultWorkingHourTimeZoneValue}
          onOptionSelected={onTimeZoneOptionSelected}
        />
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_START_BEFORE_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderStartBeforeMin}
              label={LocalizedString.profileScreen.placeholderStartBeforeMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_START_AFTER_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderStartAfterMin}
              label={LocalizedString.profileScreen.placeholderStartAfterMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.profileScreen.placeholderEarliestClockIn}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onChangeDate={onEarliestClockInChanged}
              pickerMode={PICKER_MODE_TIME}
              disableFuture
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_END_BEFORE_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderEndBeforeMin}
              label={LocalizedString.profileScreen.placeholderEndBeforeMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_END_AFTER_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderEndAfterMin}
              label={LocalizedString.profileScreen.placeholderEndAfterMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.profileScreen.placeholderAutoClockOut}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onChangeDate={onAutoClockOutChanged}
              pickerMode={PICKER_MODE_TIME}
              disableFuture
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
);

const ProfilePage = ({
  initialValues, branches, companies, departments, divisions, managers,
  addingEditing, downloading, enablingDisabling, loadingBranch, loadingCompanies,
  loadingDepartments, loadingDivisions, loadingManagers, sendingNotification,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onAutoClockOutChanged, onBranchOptionSelected, onCancelAdvancedFilterPressed, onCancelPressed,
  onChangeBranchText, onChangeCompanyText, onChangeDepartmentText, onChangeDivisionText,
  onChangeManagerText, onChangePage, onChangePageSize, onCompanyOptionSelected,
  onConfirmEnableDisablePressed, onDepartmentOptionSelected, onDivisionOptionSelected,
  onDownloadPressed, onEarliestClockInChanged, onEditPressed, onEnableDisablePressed,
  onEndWorkingHourChanged, onManagerOptionSelected, onOpenSendNotificationDialog, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSendNotificationPressed,
  onSortPressed, onStartWorkingHourChanged, onTimeZoneOptionSelected, onViewPressed,
  currentProfileStatus, pageMode, tappedId,
}) => {
  const classes = useStyles();

  return (
    <div>
      <SendNotificationDialog
        loading={sendingNotification}
        useFilterSection={pageMode === PAGE_MODE_TABLE}
        onSendPressed={onSendNotificationPressed}
        recipient={initialValues.fullName}
        sendingToLabel={LocalizedString.profileScreen.labelSendNotificationTo}
        additionalUrlPlaceholder={LocalizedString.common.labelAdditionalUrlPlaceholders}
      />

      <FunctionalPage
        data={RXSTATE_PROFILES}
        uiPage={RXSTATE_PROFILE_PAGE}
        moreMenus={[
          {
            caption: LocalizedString.common.buttonCaptionEnable,
            disabled: currentProfileStatus === STATUS_ENABLED || enablingDisabling,
            onPress: () => onEnableDisablePressed(tappedId,
              LocalizedString.common.msgEnableConfirmation),
          },
          {
            caption: LocalizedString.common.buttonCaptionDisable,
            disabled: currentProfileStatus === STATUS_DISABLED || enablingDisabling,
            onPress: () => onEnableDisablePressed(tappedId,
              LocalizedString.common.msgDisableConfirmation),
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.profileScreen.labelDepartment,
            field: RXFIELD_PROFILE_DEPARTMENT,
            type: FILTER_TYPE_DROPDOWN,
            data: departments,
            loading: loadingDepartments,
            onChangeFilterText: onChangeDepartmentText,
            onFilterOptionSelected: onDepartmentOptionSelected,
          },
          {
            title: LocalizedString.profileScreen.labelDivision,
            field: RXFIELD_PROFILE_DIVISION,
            type: FILTER_TYPE_DROPDOWN,
            data: divisions,
            loading: loadingDivisions,
            onChangeFilterText: onChangeDivisionText,
            onFilterOptionSelected: onDivisionOptionSelected,
          },
          {
            title: LocalizedString.profileScreen.labelCompany,
            field: RXFIELD_PROFILE_COMPANY_NAME,
            type: FILTER_TYPE_DROPDOWN,
            data: companies,
            loading: loadingCompanies,
            onChangeFilterText: onChangeCompanyText,
            onFilterOptionSelected: onCompanyOptionSelected,
          },
          {
            title: LocalizedString.profileScreen.labelBranch,
            field: RXFIELD_PROFILE_BRANCH,
            type: FILTER_TYPE_DROPDOWN,
            data: branches,
            loading: loadingBranch,
            onChangeFilterText: onChangeBranchText,
            onFilterOptionSelected: (option) => onBranchOptionSelected(option, false),
            useDropdownValue: true,
          },
          {
            title: LocalizedString.profileScreen.labelManager,
            field: RXFIELD_PROFILE_MANAGER,
            type: FILTER_TYPE_DROPDOWN,
            data: managers,
            loading: loadingManagers,
            onChangeFilterText: onChangeManagerText,
            onFilterOptionSelected: onManagerOptionSelected,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.profileScreen.labelStatus,
            field: RXFIELD_PROFILE_STATUS,
            type: FILTER_TYPE_DROPDOWN,
            data: status,
            useDropdownValue: true,
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.profileScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.profileScreen.labelEmployeeID, field: 'employeeId', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelFullName, field: 'fullName', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelEmail, field: 'email', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelJobTitle, field: 'jobTitle', sorting: !downloading },
        ]}
        handleSubmit={handleSubmit}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onConfirmDeletePressed={onConfirmEnableDisablePressed}
        onDownloadPressed={onDownloadPressed}
        onEditPressed={onEditPressed}
        onOpenSendNotificationDialog={onOpenSendNotificationDialog}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSavePressed={onSavePressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        editButtonCaption={LocalizedString.profileScreen.buttonCaptionEditProfile}
        title={LocalizedString.profileScreen.title}
        disableCreate
        disableDelete
        enableSave
        useFullWidth
        useNotificationCount
        useSendAllNotification
        rowStyle={(rowData) => ({
          backgroundColor: rowData.status === STATUS_DISABLED
            ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
        })}
        sendNotificationPermissionName="CORE_SEND_SIMPLE_NOTIFICATION"
      >
        {renderDialogContent(initialValues.branch ? initialValues.branch.label : '', initialValues.gender.label,
          initialValues.profilePicture, initialValues.workingHourTimeZone, branches,
          addingEditing, loadingBranch, onAutoClockOutChanged, onBranchOptionSelected,
          onChangeBranchText, onEarliestClockInChanged, onEndWorkingHourChanged,
          onStartWorkingHourChanged, onTimeZoneOptionSelected, pageMode, classes)}
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_PROFILE,
  validate: rxformValidateProfile,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProfilePage);

ProfilePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  branches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  companies: PropTypes.arrayOf(SimpleDataShape).isRequired,
  departments: PropTypes.arrayOf(SimpleDataShape).isRequired,
  divisions: PropTypes.arrayOf(SimpleDataShape).isRequired,
  managers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingBranch: PropTypes.bool.isRequired,
  loadingCompanies: PropTypes.bool.isRequired,
  loadingDepartments: PropTypes.bool.isRequired,
  loadingDivisions: PropTypes.bool.isRequired,
  loadingManagers: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAutoClockOutChanged: PropTypes.func.isRequired,
  onBranchOptionSelected: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeBranchText: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangeDepartmentText: PropTypes.func.isRequired,
  onChangeDivisionText: PropTypes.func.isRequired,
  onChangeManagerText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCompanyOptionSelected: PropTypes.func.isRequired,
  onConfirmEnableDisablePressed: PropTypes.func.isRequired,
  onDepartmentOptionSelected: PropTypes.func.isRequired,
  onDivisionOptionSelected: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEarliestClockInChanged: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onEndWorkingHourChanged: PropTypes.func.isRequired,
  onManagerOptionSelected: PropTypes.func.isRequired,
  onOpenSendNotificationDialog: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendNotificationPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onStartWorkingHourChanged: PropTypes.func.isRequired,
  onTimeZoneOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentProfileStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
