import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const MENUID_DOCUMENT_GENERATOR = 'MENUID_DOCUMENT_GENERATOR';
export const MENUID_DOCUMENT_GENERATOR_TEMPLATE = 'MENUID_DOCUMENT_GENERATOR_TEMPLATE';
export const MENUID_DOCUMENT_GENERATOR_DOCUMENT = 'MENUID_DOCUMENT_GENERATOR_DOCUMENT';

export const ROUTE_DOCUMENT_GENERATOR = '/document-generator';
export const ROUTE_DOCUMENT_GENERATOR_TEMPLATE = '/template';
export const ROUTE_DOCUMENT_GENERATOR_DOCUMENT = '/document';

export const REST_URL_DOCUMENT_GENERATOR_TEMPLATES = '/docgen/api/v1/Template/DocumentTemplate?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}';
export const REST_URL_VIEW_DELETE_DOCUMENT_GENERATOR_TEMPLATE = '/docgen/api/v1/Template/DocumentTemplate/{id}';
export const REST_URL_DOCUMENT_GENERATOR_ENABLE_DISABLE_TEMPLATE = '/docgen/api/v1/Template/DocumentTemplate/Status';
export const REST_URL_DOCUMENT_GENERATOR_LOCKING_UNLOCKING_TEMPLATE = '/docgen/api/v1/Template/DocumentTemplate/Lock';
export const REST_URL_ADD_EDIT_DOCUMENT_GENERATOR_TEMPLATE = '/docgen/api/v1/Template/DocumentTemplate';
export const REST_URL_GENERATE_DOCUMENT_TEMPLATE_PREVIEW = '/docgen/api/v1/Template/DocumentTemplate/Generate';
export const REST_URL_TEMPLATE_PAPER_SIZES = '/docgen/api/v1/PageSize';
export const REST_URL_DOCUMENT_GENERATOR_DOCUMENTS = '/docgen/api/v1/Template/Document?pageNumber={pageNumber}&pageSize={pageSize}&searchStringIncludedFields=user.fullName|documentTemplate.name&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DOCUMENT_GENERATOR_DOCUMENT = '/docgen/api/v1/Template/Document/{id}';

export const INITIAL_ORDER_BY_DOCUMENT_GENERATOR_TEMPLATE = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_DOCUMENT_GENERATOR_DOCUMENT = `name ${ORDER_SORT_ASCENDING}`;

export const RXFORM_TEMPLATE = 'templatePage';
export const RXFORM_DOCUMENT = 'documentPage';

export const RXFORM_FIELD_NAME = 'name';
export const RXFORM_FIELD_DESCRIPTION = 'description';
export const RXFORM_FIELD_TEMPLATE_BODY = 'body';
export const RXFORM_FIELD_TEMPLATE_HEADER = 'header';
export const RXFORM_FIELD_TEMPLATE_FOOTER = 'footer';
export const RXFORM_FIELD_TEMPLATE_SAMPLE_PAYLOAD = 'samplePayload';
export const RXFORM_FIELD_TEMPLATE_PAPER_SIZE = 'pageSize';
export const RXFORM_FIELD_TEMPLATE_MARGIN_TOP = 'marginTop';
export const RXFORM_FIELD_TEMPLATE_MARGIN_LEFT = 'marginLeft';
export const RXFORM_FIELD_TEMPLATE_MARGIN_RIGHT = 'marginRight';
export const RXFORM_FIELD_TEMPLATE_MARGIN_BOTTOM = 'marginBottom';
export const RXFORM_FIELD_TEMPLATE_HEADER_MAX_HEIGHT = 'headerMaxHeight';
export const RXFORM_FIELD_TEMPLATE_FOOTER_MAX_HEIGHT = 'footerMaxHeight';
export const RXFORM_FIELD_TEMPLATE_HEADER_DRAW_DIVIDER_LINE = 'headerDrawDividerLine';
export const RXFORM_FIELD_TEMPLATE_FOOTER_DRAW_DIVIDER_LINE = 'footerDrawDividerLine';

export const RXSTATE_TEMPLATE = 'templates';
export const RXSTATE_TEMPLATE_PAGE = 'uiTemplate';
export const RXSTATE_DOCUMENT = 'documents';
export const RXSTATE_DOCUMENT_PAGE = 'uiDocument';

export const BODY_FIELD_MAX_HEIGHT = 400;
export const HEADER_FOOTER_FIELD_MAX_HEIGHT = 200;

export const RXFORM_FIELD_DOCUMENT_TEMPLATE_ID = 'documentTemplate.id';
export const RXFORM_FIELD_DOCUMENT_TEMPLATE_NAME = 'documentTemplate.name';
export const RXFORM_FIELD_DOCUMENT_USER_ID = 'user.id';
export const RXFORM_FIELD_DOCUMENT_USER_NAME = 'user.fullName';
