import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');


export const DOWNLOADING_PICKUP_LOCATIONS = 'DOWNLOADING_PICKUP_LOCATIONS';
export const downloadingPickupLocations = makeActionCreator(DOWNLOADING_PICKUP_LOCATIONS, 'status');

export const SET_PICKUP_LOCATIONS = 'SET_PICKUP_LOCATIONS';
export const setPickupLocations = makeActionCreator(SET_PICKUP_LOCATIONS, 'data');

export const ADD_PICKUP_LOCATION = 'ADD_PICKUP_LOCATION';
export const addPickupLocation = makeActionCreator(ADD_PICKUP_LOCATION, 'data');

export const CLEAR_PICKUP_LOCATIONS = 'CLEAR_PICKUP_LOCATIONS';
export const clearPickupLocations = makeActionCreator(CLEAR_PICKUP_LOCATIONS);

export const ADDING_EDITING_PICKUP_LOCATION = 'ADDING_EDITING_PICKUP_LOCATION';
export const addingEditingPickupLocation = makeActionCreator(ADDING_EDITING_PICKUP_LOCATION, 'status');

export const DOWNLOADING_DELETING_PICKUP_LOCATION = 'DOWNLOADING_DELETING_PICKUP_LOCATION';
export const downloadingDeletingPickupLocation = makeActionCreator(DOWNLOADING_DELETING_PICKUP_LOCATION, 'status');


export const SET_PICKUP_LOCATION_SELECTED_PAGE_SIZE = 'SET_PICKUP_LOCATION_SELECTED_PAGE_SIZE';
export const setPickupLocationSelectedPageSize = makeActionCreator(SET_PICKUP_LOCATION_SELECTED_PAGE_SIZE, 'size');

export const SET_PICKUP_LOCATION_SELECTED_ORDER_BY = 'SET_PICKUP_LOCATION_SELECTED_ORDER_BY';
export const setPickupLocationSelectedOrderBy = makeActionCreator(SET_PICKUP_LOCATION_SELECTED_ORDER_BY, 'order');

export const SET_PICKUP_LOCATION_TAPPED_ID = 'SET_PICKUP_LOCATION_TAPPED_ID';
export const setPickupLocationTappedId = makeActionCreator(SET_PICKUP_LOCATION_TAPPED_ID, 'id');

export const SET_PICKUP_LOCATION_SEARCH_TEXT = 'SET_PICKUP_LOCATION_SEARCH_TEXT';
export const setPickupLocationSearchText = makeActionCreator(SET_PICKUP_LOCATION_SEARCH_TEXT, 'text');


export const DOWNLOADING_PACKET_TYPES = 'DOWNLOADING_PACKET_TYPES';
export const downloadingPacketTypes = makeActionCreator(DOWNLOADING_PACKET_TYPES, 'status');

export const SET_PACKET_TYPES = 'SET_PACKET_TYPES';
export const setPacketTypes = makeActionCreator(SET_PACKET_TYPES, 'data');

export const ADD_PACKET_TYPE = 'ADD_PACKET_TYPE';
export const addPacketType = makeActionCreator(ADD_PACKET_TYPE, 'data');

export const CLEAR_PACKET_TYPES = 'CLEAR_PACKET_TYPES';
export const clearPacketTypes = makeActionCreator(CLEAR_PACKET_TYPES);

export const ADDING_EDITING_PACKET_TYPE = 'ADDING_EDITING_PACKET_TYPE';
export const addingEditingPacketType = makeActionCreator(ADDING_EDITING_PACKET_TYPE, 'status');

export const DOWNLOADING_DELETING_PACKET_TYPE = 'DOWNLOADING_DELETING_PACKET_TYPE';
export const downloadingDeletingPacketType = makeActionCreator(DOWNLOADING_DELETING_PACKET_TYPE, 'status');


export const SET_PACKET_TYPE_SELECTED_PAGE_SIZE = 'SET_PACKET_TYPE_SELECTED_PAGE_SIZE';
export const setPacketTypeSelectedPageSize = makeActionCreator(SET_PACKET_TYPE_SELECTED_PAGE_SIZE, 'size');

export const SET_PACKET_TYPE_SELECTED_ORDER_BY = 'SET_PACKET_TYPE_SELECTED_ORDER_BY';
export const setPacketTypeSelectedOrderBy = makeActionCreator(SET_PACKET_TYPE_SELECTED_ORDER_BY, 'order');

export const SET_PACKET_TYPE_TAPPED_ID = 'SET_PACKET_TYPE_TAPPED_ID';
export const setPacketTypeTappedId = makeActionCreator(SET_PACKET_TYPE_TAPPED_ID, 'id');

export const SET_PACKET_TYPE_SEARCH_TEXT = 'SET_PACKET_TYPE_SEARCH_TEXT';
export const setPacketTypeSearchText = makeActionCreator(SET_PACKET_TYPE_SEARCH_TEXT, 'text');


export const DOWNLOADING_PACKETS = 'DOWNLOADING_PACKETS';
export const downloadingPackets = makeActionCreator(DOWNLOADING_PACKETS, 'status');

export const SET_PACKETS = 'SET_PACKETS';
export const setPackets = makeActionCreator(SET_PACKETS, 'data');

export const ADD_PACKET = 'ADD_PACKET';
export const addPacket = makeActionCreator(ADD_PACKET, 'data');

export const CLEAR_PACKETS = 'CLEAR_PACKETS';
export const clearPackets = makeActionCreator(CLEAR_PACKETS);

export const ADDING_EDITING_PACKET = 'ADDING_EDITING_PACKET';
export const addingEditingPacket = makeActionCreator(ADDING_EDITING_PACKET, 'status');

export const DOWNLOADING_DELETING_PACKET = 'DOWNLOADING_DELETING_PACKET';
export const downloadingDeletingPacket = makeActionCreator(DOWNLOADING_DELETING_PACKET, 'status');

export const SAVING_PACKETS = 'SAVING_PACKETS';
export const savingPackets = makeActionCreator(SAVING_PACKETS, 'status');

export const PICKUP_UP_PACKET = 'PICKUP_UP_PACKET';
export const pickingUpPacket = makeActionCreator(PICKUP_UP_PACKET, 'status');


export const SET_PACKET_SELECTED_PAGE_SIZE = 'SET_PACKET_SELECTED_PAGE_SIZE';
export const setPacketSelectedPageSize = makeActionCreator(SET_PACKET_SELECTED_PAGE_SIZE, 'size');

export const SET_PACKET_SELECTED_ORDER_BY = 'SET_PACKET_SELECTED_ORDER_BY';
export const setPacketSelectedOrderBy = makeActionCreator(SET_PACKET_SELECTED_ORDER_BY, 'order');

export const SET_PACKET_TAPPED_ID = 'SET_PACKET_TAPPED_ID';
export const setPacketTappedId = makeActionCreator(SET_PACKET_TAPPED_ID, 'id');

export const SET_PACKET_SEARCH_TEXT = 'SET_PACKET_SEARCH_TEXT';
export const setPacketSearchText = makeActionCreator(SET_PACKET_SEARCH_TEXT, 'text');

export const SET_PACKET_SELECTED_RECIPIENT = 'SET_PACKET_SELECTED_RECIPIENT';
export const setPacketSelectedRecipient = makeActionCreator(SET_PACKET_SELECTED_RECIPIENT, 'option');

export const SET_PACKET_SELECTED_PICKUP_LOCATION = 'SET_PACKET_SELECTED_PICKUP_LOCATION';
export const setPacketSelectedPickupLocation = makeActionCreator(SET_PACKET_SELECTED_PICKUP_LOCATION, 'option');

export const SET_PACKET_SELECTED_PACKET_TYPE = 'SET_PACKET_SELECTED_PACKET_TYPE';
export const setPacketSelectedPacketType = makeActionCreator(SET_PACKET_SELECTED_PACKET_TYPE, 'option');

export const SET_PACKET_SELECTED_RECEIVED_BY = 'SET_PACKET_SELECTED_RECEIVED_BY';
export const setPacketSelectedReceivedBy = makeActionCreator(SET_PACKET_SELECTED_RECEIVED_BY, 'option');

export const SET_PACKET_PICKUP_DIALOG_VISIBILITY = 'SET_PACKET_PICKUP_DIALOG_VISIBILITY';
export const setPacketPickupDialogVisibility = makeActionCreator(SET_PACKET_PICKUP_DIALOG_VISIBILITY, 'status');

export const SET_PACKET_SELECTED_PICKED_UP_BY = 'SET_PACKET_SELECTED_PICKED_UP_BY';
export const setPacketSelectedPickedUpBy = makeActionCreator(SET_PACKET_SELECTED_PICKED_UP_BY, 'option');

export const SET_PACKET_SELECTED_PICKED_UP_BY_USER = 'SET_PACKET_SELECTED_PICKED_UP_BY_USER';
export const setPacketSelectedPickedUpByUser = makeActionCreator(SET_PACKET_SELECTED_PICKED_UP_BY_USER, 'option');


export const DOWNLOADING_SUMMARY_THIS_MONTH_TRENDS = 'DOWNLOADING_SUMMARY_THIS_MONTH_TRENDS';
export const downloadingSummaryThisMonthTrends = makeActionCreator(DOWNLOADING_SUMMARY_THIS_MONTH_TRENDS, 'status');

export const SET_SUMMARY_THIS_MONTH_TRENDS = 'SET_SUMMARY_THIS_MONTH_TRENDS';
export const setSummaryThisMonthTrends = makeActionCreator(SET_SUMMARY_THIS_MONTH_TRENDS, 'data');

export const CLEAR_SUMMARY_THIS_MONTH_TRENDS = 'CLEAR_SUMMARY_THIS_MONTH_TRENDS';
export const clearSummaryThisMonthTrends = makeActionCreator(CLEAR_SUMMARY_THIS_MONTH_TRENDS);


export const DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TRENDS = 'DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TRENDS';
export const downloadingSummaryPreviousMonthTrends = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TRENDS, 'status');

export const SET_SUMMARY_PREVIOUS_MONTH_TRENDS = 'SET_SUMMARY_PREVIOUS_MONTH_TRENDS';
export const setSummaryPreviousMonthTrends = makeActionCreator(SET_SUMMARY_PREVIOUS_MONTH_TRENDS, 'data');

export const CLEAR_SUMMARY_PREVIOUS_MONTH_TRENDS = 'CLEAR_SUMMARY_PREVIOUS_MONTH_TRENDS';
export const clearSummaryPreviousMonthTrends = makeActionCreator(
  CLEAR_SUMMARY_PREVIOUS_MONTH_TRENDS,
);


export const DOWNLOADING_SUMMARY_THIS_YEAR_TRENDS = 'DOWNLOADING_SUMMARY_THIS_YEAR_TRENDS';
export const downloadingSummaryThisYearTrends = makeActionCreator(DOWNLOADING_SUMMARY_THIS_YEAR_TRENDS, 'status');

export const SET_SUMMARY_THIS_YEAR_TRENDS = 'SET_SUMMARY_THIS_YEAR_TRENDS';
export const setSummaryThisYearTrends = makeActionCreator(SET_SUMMARY_THIS_YEAR_TRENDS, 'data');

export const CLEAR_SUMMARY_THIS_YEAR_TRENDS = 'CLEAR_SUMMARY_THIS_YEAR_TRENDS';
export const clearSummaryThisYearTrends = makeActionCreator(CLEAR_SUMMARY_THIS_YEAR_TRENDS);


export const DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TRENDS = 'DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TRENDS';
export const downloadingSummaryPreviousYearTrends = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TRENDS, 'status');

export const SET_SUMMARY_PREVIOUS_YEAR_TRENDS = 'SET_SUMMARY_PREVIOUS_YEAR_TRENDS';
export const setSummaryPreviousYearTrends = makeActionCreator(SET_SUMMARY_PREVIOUS_YEAR_TRENDS, 'data');

export const CLEAR_SUMMARY_PREVIOUS_YEAR_TRENDS = 'CLEAR_SUMMARY_PREVIOUS_YEAR_TRENDS';
export const clearSummaryPreviousYearTrends = makeActionCreator(CLEAR_SUMMARY_PREVIOUS_YEAR_TRENDS);


export const DOWNLOADING_SUMMARY_THIS_MONTH_LOCATIONS = 'DOWNLOADING_SUMMARY_THIS_MONTH_LOCATIONS';
export const downloadingSummaryThisMonthLocations = makeActionCreator(DOWNLOADING_SUMMARY_THIS_MONTH_LOCATIONS, 'status');

export const SET_SUMMARY_THIS_MONTH_LOCATIONS = 'SET_SUMMARY_THIS_MONTH_LOCATIONS';
export const setSummaryThisMonthLocations = makeActionCreator(SET_SUMMARY_THIS_MONTH_LOCATIONS, 'data');

export const CLEAR_SUMMARY_THIS_MONTH_LOCATIONS = 'CLEAR_SUMMARY_THIS_MONTH_LOCATIONS';
export const clearSummaryThisMonthLocations = makeActionCreator(CLEAR_SUMMARY_THIS_MONTH_LOCATIONS);


export const DOWNLOADING_SUMMARY_PREVIOUS_MONTH_LOCATIONS = 'DOWNLOADING_SUMMARY_PREVIOUS_MONTH_LOCATIONS';
export const downloadingSummaryPreviousMonthLocations = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_MONTH_LOCATIONS, 'status');

export const SET_SUMMARY_PREVIOUS_MONTH_LOCATIONS = 'SET_SUMMARY_PREVIOUS_MONTH_LOCATIONS';
export const setSummaryPreviousMonthLocations = makeActionCreator(SET_SUMMARY_PREVIOUS_MONTH_LOCATIONS, 'data');

export const CLEAR_SUMMARY_PREVIOUS_MONTH_LOCATIONS = 'CLEAR_SUMMARY_PREVIOUS_MONTH_LOCATIONS';
export const clearSummaryPreviousMonthLocations = makeActionCreator(
  CLEAR_SUMMARY_PREVIOUS_MONTH_LOCATIONS,
);


export const DOWNLOADING_SUMMARY_THIS_YEAR_LOCATIONS = 'DOWNLOADING_SUMMARY_THIS_YEAR_LOCATIONS';
export const downloadingSummaryThisYearLocations = makeActionCreator(DOWNLOADING_SUMMARY_THIS_YEAR_LOCATIONS, 'status');

export const SET_SUMMARY_THIS_YEAR_LOCATIONS = 'SET_SUMMARY_THIS_YEAR_LOCATIONS';
export const setSummaryThisYearLocations = makeActionCreator(SET_SUMMARY_THIS_YEAR_LOCATIONS, 'data');

export const CLEAR_SUMMARY_THIS_YEAR_LOCATIONS = 'CLEAR_SUMMARY_THIS_YEAR_LOCATIONS';
export const clearSummaryThisYearLocations = makeActionCreator(CLEAR_SUMMARY_THIS_YEAR_LOCATIONS);


export const DOWNLOADING_SUMMARY_PREVIOUS_YEAR_LOCATIONS = 'DOWNLOADING_SUMMARY_PREVIOUS_YEAR_LOCATIONS';
export const downloadingSummaryPreviousYearLocations = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_YEAR_LOCATIONS, 'status');

export const SET_SUMMARY_PREVIOUS_YEAR_LOCATIONS = 'SET_SUMMARY_PREVIOUS_YEAR_LOCATIONS';
export const setSummaryPreviousYearLocations = makeActionCreator(SET_SUMMARY_PREVIOUS_YEAR_LOCATIONS, 'data');

export const CLEAR_SUMMARY_PREVIOUS_YEAR_LOCATIONS = 'CLEAR_SUMMARY_PREVIOUS_YEAR_LOCATIONS';
export const clearSummaryPreviousYearLocations = makeActionCreator(
  CLEAR_SUMMARY_PREVIOUS_YEAR_LOCATIONS,
);


export const DOWNLOADING_SUMMARY_THIS_MONTH_TOP_RECIPIENTS = 'DOWNLOADING_SUMMARY_THIS_MONTH_TOP_RECIPIENTS';
export const downloadingSummaryThisMonthTopRecipients = makeActionCreator(DOWNLOADING_SUMMARY_THIS_MONTH_TOP_RECIPIENTS, 'status');

export const SET_SUMMARY_THIS_MONTH_TOP_RECIPIENTS = 'SET_SUMMARY_THIS_MONTH_TOP_RECIPIENTS';
export const setSummaryThisMonthTopRecipients = makeActionCreator(SET_SUMMARY_THIS_MONTH_TOP_RECIPIENTS, 'data');

export const CLEAR_SUMMARY_THIS_MONTH_TOP_RECIPIENTS = 'CLEAR_SUMMARY_THIS_MONTH_TOP_RECIPIENTS';
export const clearSummaryThisMonthTopRecipients = makeActionCreator(
  CLEAR_SUMMARY_THIS_MONTH_TOP_RECIPIENTS,
);


export const DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS = 'DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS';
export const downloadingSummaryPreviousMonthTopRecipients = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS, 'status');

export const SET_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS = 'SET_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS';
export const setSummaryPreviousMonthTopRecipients = makeActionCreator(SET_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS, 'data');

export const CLEAR_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS = 'CLEAR_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS';
export const clearSummaryPreviousMonthTopRecipients = makeActionCreator(
  CLEAR_SUMMARY_PREVIOUS_MONTH_TOP_RECIPIENTS,
);


export const DOWNLOADING_SUMMARY_THIS_YEAR_TOP_RECIPIENTS = 'DOWNLOADING_SUMMARY_THIS_YEAR_TOP_RECIPIENTS';
export const downloadingSummaryThisYearTopRecipients = makeActionCreator(DOWNLOADING_SUMMARY_THIS_YEAR_TOP_RECIPIENTS, 'status');

export const SET_SUMMARY_THIS_YEAR_TOP_RECIPIENTS = 'SET_SUMMARY_THIS_YEAR_TOP_RECIPIENTS';
export const setSummaryThisYearTopRecipients = makeActionCreator(SET_SUMMARY_THIS_YEAR_TOP_RECIPIENTS, 'data');

export const CLEAR_SUMMARY_THIS_YEAR_TOP_RECIPIENTS = 'CLEAR_SUMMARY_THIS_YEAR_TOP_RECIPIENTS';
export const clearSummaryThisYearTopRecipients = makeActionCreator(
  CLEAR_SUMMARY_THIS_YEAR_TOP_RECIPIENTS,
);


export const DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS = 'DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS';
export const downloadingSummaryPreviousYearTopRecipients = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS, 'status');

export const SET_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS = 'SET_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS';
export const setSummaryPreviousYearTopRecipients = makeActionCreator(SET_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS, 'data');

export const CLEAR_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS = 'CLEAR_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS';
export const clearSummaryPreviousYearTopRecipients = makeActionCreator(
  CLEAR_SUMMARY_PREVIOUS_YEAR_TOP_RECIPIENTS,
);


export const DOWNLOADING_SUMMARY_THIS_MONTH_PACKET_TYPES = 'DOWNLOADING_SUMMARY_THIS_MONTH_PACKET_TYPES';
export const downloadingSummaryThisMonthPacketTypes = makeActionCreator(DOWNLOADING_SUMMARY_THIS_MONTH_PACKET_TYPES, 'status');

export const SET_SUMMARY_THIS_MONTH_PACKET_TYPES = 'SET_SUMMARY_THIS_MONTH_PACKET_TYPES';
export const setSummaryThisMonthPacketTypes = makeActionCreator(SET_SUMMARY_THIS_MONTH_PACKET_TYPES, 'data');

export const CLEAR_SUMMARY_THIS_MONTH_PACKET_TYPES = 'CLEAR_SUMMARY_THIS_MONTH_PACKET_TYPES';
export const clearSummaryThisMonthPacketTypes = makeActionCreator(
  CLEAR_SUMMARY_THIS_MONTH_PACKET_TYPES,
);


export const DOWNLOADING_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES = 'DOWNLOADING_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES';
export const downloadingSummaryPreviousMonthPacketTypes = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES, 'status');

export const SET_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES = 'SET_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES';
export const setSummaryPreviousMonthPacketTypes = makeActionCreator(SET_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES, 'data');

export const CLEAR_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES = 'CLEAR_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES';
export const clearSummaryPreviousMonthPacketTypes = makeActionCreator(
  CLEAR_SUMMARY_PREVIOUS_MONTH_PACKET_TYPES,
);


export const DOWNLOADING_SUMMARY_THIS_YEAR_PACKET_TYPES = 'DOWNLOADING_SUMMARY_THIS_YEAR_PACKET_TYPES';
export const downloadingSummaryThisYearPacketTypes = makeActionCreator(DOWNLOADING_SUMMARY_THIS_YEAR_PACKET_TYPES, 'status');

export const SET_SUMMARY_THIS_YEAR_PACKET_TYPES = 'SET_SUMMARY_THIS_YEAR_PACKET_TYPES';
export const setSummaryThisYearPacketTypes = makeActionCreator(SET_SUMMARY_THIS_YEAR_PACKET_TYPES, 'data');

export const CLEAR_SUMMARY_THIS_YEAR_PACKET_TYPES = 'CLEAR_SUMMARY_THIS_YEAR_PACKET_TYPES';
export const clearSummaryThisYearPacketTypes = makeActionCreator(
  CLEAR_SUMMARY_THIS_YEAR_PACKET_TYPES,
);


export const DOWNLOADING_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES = 'DOWNLOADING_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES';
export const downloadingSummaryPreviousYearPacketTypes = makeActionCreator(DOWNLOADING_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES, 'status');

export const SET_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES = 'SET_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES';
export const setSummaryPreviousYearPacketTypes = makeActionCreator(SET_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES, 'data');

export const CLEAR_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES = 'CLEAR_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES';
export const clearSummaryPreviousYearPacketTypes = makeActionCreator(
  CLEAR_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES,
);


export const SET_PICK_UP_PACKET_UPLOAD_IMAGE_METHOD = 'SET_PICK_UP_PACKET_UPLOAD_IMAGE_METHOD';
export const setPickUpPacketUploadImageMethod = makeActionCreator(SET_PICK_UP_PACKET_UPLOAD_IMAGE_METHOD, 'method');
