import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { AccentButton } from '../../../../component';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import { FunctionalPage } from '../../../configuration/component';
import {
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW,
  PICKER_DATE_TIME_FORMAT,
  PICKER_MODE_DATE_TIME,
  RXFORM_DOCUMENT,
  RXFORM_FIELD_DOCUMENT_TEMPLATE_ID,
  RXFORM_FIELD_DOCUMENT_TEMPLATE_NAME, RXFORM_FIELD_DOCUMENT_USER_ID,
  RXFORM_FIELD_DOCUMENT_USER_NAME,
  RXSTATE_DOCUMENT,
  RXSTATE_DOCUMENT_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';

const renderDialogContent = (
  initialValues, downloadingDeleting, addingEditing, pageMode,
  downloadingDocumentResult, onDownloadPressed,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFORM_FIELD_DOCUMENT_TEMPLATE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.documentPage.labelTemplateId}
            label={LocalizedString.documentPage.labelTemplateId}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFORM_FIELD_DOCUMENT_TEMPLATE_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.documentPage.labelTemplateName}
            label={LocalizedString.documentPage.labelTemplateName}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFORM_FIELD_DOCUMENT_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.documentPage.labelUserId}
            label={LocalizedString.documentPage.labelUserId}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFORM_FIELD_DOCUMENT_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.documentPage.labelUserName}
            label={LocalizedString.documentPage.labelUserName}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <AccentButton
            onClick={() => onDownloadPressed()}
            caption={LocalizedString.documentPage.buttonCaptionDownload}
            loading={downloadingDocumentResult}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const DocumentPage = ({
  initialValues, downloading, downloadingDeleting,
  pageMode, users, loadingUser, onResetAdvancedFilterPressed,
  onAppear, onRefresh, onSearchBarTextChanged, onSortPressed, onChangePageSize,
  onChangePage, onCancelPressed, onViewPressed, addingEditing,
  handleSubmit, onChangeUserText, onChangeTemplateText, onDownloadPressed,
  templates, loadingTemplate, onApplyAdvancedFilterPressed, downloadingDocumentResult,
  onAdvancedFilterPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_DOCUMENT}
    uiPage={RXSTATE_DOCUMENT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.documentPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.documentPage.labelDocumentTemplate,
        field: 'template',
        type: FILTER_TYPE_DROPDOWN,
        data: templates,
        loading: loadingTemplate,
        onChangeFilterText: onChangeTemplateText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.documentPage.labelUser,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.documentPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.documentPage.labelTemplateName, field: 'documentTemplate.name', sorting: !downloading },
      { title: LocalizedString.documentPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.documentPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onViewPressed={onViewPressed}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    title={LocalizedString.documentPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
  >
    {renderDialogContent(
      initialValues, downloadingDeleting, addingEditing, pageMode,
      downloadingDocumentResult, onDownloadPressed,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_DOCUMENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DocumentPage);

DocumentPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  templates: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  loadingTemplate: PropTypes.bool.isRequired,
  downloadingDocumentResult: PropTypes.bool.isRequired,
  pageMode: PropTypes.string.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeTemplateText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
