import {
  SET_WORKING_HOURS,
  ADD_WORKING_HOUR,
  CLEAR_WORKING_HOURS,
} from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WORKING_HOURS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_WORKING_HOUR: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_WORKING_HOURS:
      return initialState;
    default:
      return state;
  }
};
