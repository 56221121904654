import {
  addConfigItem, clearConfigItems, downloadingDeletingConfigItem,
  setConfigItemTappedId, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { downloadConfigItem, deleteConfigItem } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadConfigItemsAsync from './downloadConfigItemsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingConfigItem(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiConfigItem;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteConfigItem(tappedId, token);
      dispatch(setConfigItemTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearConfigItems());
      dispatch(downloadConfigItemsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const configItem = await downloadConfigItem(tappedId, token);
      dispatch(addConfigItem(configItem));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingConfigItem(false));
  }
};
