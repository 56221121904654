import { reset } from 'redux-form';
import { RXFORM_WFA } from '../../../constant';
import { editWfa, downloadWfa } from '../../../helper';
import { addWfa, addingEditingWfa, setWfaEditDialogVisibility } from '../simple-action';

export default (date, wfaStatusNotes) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingWfa(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWfa;

    await editWfa(tappedId, date, wfaStatusNotes, token);

    const result = await downloadWfa(tappedId, token);
    dispatch(addWfa(result));
    dispatch(setWfaEditDialogVisibility(false));
    dispatch(reset(RXFORM_WFA));
  } finally {
    dispatch(addingEditingWfa(false));
  }
};
