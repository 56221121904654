import { downloadingFont, setFont } from '../simple-action';
import { downloadFont } from '../../../helper';

export default () => async (dispatch, getState) => {
  const {
    authentication,
  } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingFont(true));

    const list = await downloadFont(
      token,
    );

    if (list) {
      dispatch(setFont(list));
    }
  } finally {
    dispatch(downloadingFont(false));
  }
};
