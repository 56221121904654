import {
  addPointSubmission, downloadingDeletingPointSubmission,
  setFunctionalPageMode,
} from '../simple-action';
import { downloadPointSubmission } from '../../../helper';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPointSubmission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPointSubmission;

    const pointSubmission = await downloadPointSubmission(tappedId, token);
    dispatch(addPointSubmission(pointSubmission));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingPointSubmission(false));
  }
};
