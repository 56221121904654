import { reset } from 'redux-form';
import {
  addingEditingPacketType, clearPacketTypes, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addPacketType, editPacketType } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_PACKET_TYPE } from '../../../constant';
import downloadPacketTypesAsync from './downloadPacketTypesAsync';

export default (name, description, keepPacketForHours) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPacketType(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPacketType;

    if (tappedId) {
      await editPacketType(tappedId, name, description, keepPacketForHours, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addPacketType(name, description, keepPacketForHours, token);
    }

    dispatch(reset(RXFORM_PACKET_TYPE));
    dispatch(clearPacketTypes());
    dispatch(downloadPacketTypesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingPacketType(false));
  }
};
