import { reset } from 'redux-form';
import moment from 'moment';
import {
  addingEditingEvent, clearEvents, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addEvent, editEvent } from '../../../helper';
import { RXFORM_EVENTS, PAGE_MODE_TABLE } from '../../../constant';
import downloadEventsAsync from './downloadEventsAsync';

export default (name, referenceNumber, startTime, endTime,
  participants, description) => async (dispatch, getState) => {
  const { authentication, uiEvent, events } = getState();
  const { token } = authentication;
  const { tappedId, selectedTimeZone, selectedLocation } = uiEvent;
  const { data } = events;

  const found = data[tappedId];
  const timezone = selectedTimeZone || found.timeZone;
  const locationId = selectedLocation.value || found.location.id;
  const starttime = moment(startTime).format();
  const endtime = moment(endTime).format();

  try {
    dispatch(addingEditingEvent(true));

    if (tappedId) {
      await editEvent(tappedId, name, referenceNumber, starttime, endtime, timezone,
        locationId, participants, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addEvent(name, referenceNumber, starttime, endtime, timezone,
        locationId, participants, description, token);
    }

    dispatch(reset(RXFORM_EVENTS));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingEvent(false));
  }
};
