import { LOGGING_IN, LOGGING_OUT, LOGGING_IN_WITH_MICROSOFT } from '../action';
import { DOWNLOADING_MY_CONFIG_ITEMS } from '../../module/configuration/redux/action';

const initialState = {
  loggingIn: false,
  loggingOut: false,
  loggingInWithMicrosoft: false,
  downloadingMyConfigItems: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return { ...state, loggingIn: action.status };
    case LOGGING_OUT:
      return { ...state, loggingOut: action.status };
    case LOGGING_IN_WITH_MICROSOFT:
      return { ...state, loggingInWithMicrosoft: action.status };
    case DOWNLOADING_MY_CONFIG_ITEMS:
      return { ...state, downloadingMyConfigItems: action.status };
    default: return state;
  }
};
