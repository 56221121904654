import { downloadingConfigValues, setConfigValues, clearConfigValues } from '../simple-action';
import { downloadFilteredConfigItems, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiConfigValue } = getState();
  const { token } = authentication;
  const { selectedFilter } = uiConfigValue;

  try {
    dispatch(downloadingConfigValues(true));
    dispatch(clearConfigValues());
    const list = await downloadFilteredConfigItems(
      1,
      20,
      'order asc',
      transformSearchText(''),
      token,
      selectedFilter,
    );

    const result = { ...list };
    for (let i = list.meta.currentPage; i <= list.meta.totalPages; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const nextList = await downloadFilteredConfigItems(
        i,
        20,
        'order asc',
        '%',
        token,
        selectedFilter,
      );

      const finalList = {
        data: { ...result.data, ...nextList.data },
        meta: nextList.meta,
      };

      Object.assign(result, finalList);
      dispatch(setConfigValues(result));
    }
  } finally {
    dispatch(downloadingConfigValues(false));
  }
};
