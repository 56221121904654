import { reset } from 'redux-form';
import {
  addingEditingCompanyMapping, clearCompanyMappings, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addCompanyMapping, editCompanyMapping } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_COMPANY_MAPPING } from '../../../constant';
import downloadCompanyMappingsAsync from './downloadCompanyMappingsAsync';

export default (
  order, phrase, mappingOperator, companyName, addressLine1, addressLine2,
  city, province, postalCode, phone, fax, webSite, companyLogoUrl,
  image1Url, image2Url, type,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCompanyMapping(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCompanyMapping;

    if (tappedId) {
      await editCompanyMapping(
        tappedId, order, phrase, mappingOperator, companyName, addressLine1, addressLine2,
        city, province, postalCode, phone, fax, webSite, companyLogoUrl,
        image1Url, image2Url, type, token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addCompanyMapping(
        order, phrase, mappingOperator, companyName, addressLine1, addressLine2,
        city, province, postalCode, phone, fax, webSite, companyLogoUrl,
        image1Url, image2Url, type, token,
      );
    }

    dispatch(reset(RXFORM_COMPANY_MAPPING));
    dispatch(clearCompanyMappings());
    dispatch(downloadCompanyMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingCompanyMapping(false));
  }
};
