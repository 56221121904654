import { downloadForumPosts, transformSearchText } from '../../../helper';
import { downloadingForumPosts, setForumPosts } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, forumPosts, uiForumPost } = getState();
  const { token } = authentication;
  const { meta, data } = forumPosts;
  const { selectedPageSize, searchBarText, orderBy } = uiForumPost;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingForumPosts(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadForumPosts(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setForumPosts(list));
    }
  } finally {
    dispatch(downloadingForumPosts(false));
  }
};
