import { downloadingDeletingProfile, setHistoryProfileDetail } from '../simple-action';
import { downloadProfile } from '../../../helper';

export default (historyId) => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingProfile(true));
    const { authentication, histories } = getState();
    const { token } = authentication;
    const { data } = histories;
    const history = data[historyId];
    const { id } = history.profile;

    const result = await downloadProfile(id, token);
    dispatch(setHistoryProfileDetail(result));
  } finally {
    dispatch(downloadingDeletingProfile(false));
  }
};
