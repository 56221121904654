import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    forumMenu: {
      title: 'Forum',
    },
    forumCategoryPage: {
      title: 'Forum Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      labelParent: 'Parent',

      placeholderStatus: 'Status',
      placeholderParentCategory: 'Parent Category',
      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderForumCategoryImage: 'Forum Category Image',

      buttonCaptionCreateNewForumCategory: 'Create Forum Category',
      buttonCaptionDeleteForumCategory: 'Delete Forum Category',
      buttonCaptionEditForumCategory: 'Edit Forum Category',
    },
    forumPostPage: {
      title: 'Post',

      labelNo: 'No',
      labelTitle: 'Title',
      labelCategory: 'Category',
      labelAuthor: 'Author',
      labelLocked: 'Locked',
      labelPinned: 'Pinned',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',
      labelOrder: 'Order',
      labelMedia: 'Media',
      labelYoutube: 'Youtube',
      labelImage: 'Image',

      buttonCaptionCreateNewPost: 'Create New Post',
      buttonCaptionEditPost: 'Edit Post',
      buttonCaptionDeletePost: 'Delete Post',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',

      placeholderTitle: 'Title',
      placeholderSlug: 'Slug',
      placeholderContent: 'Content',
      placeholderContentFormat: 'Content Format',
      placeholderCategory: 'Category',
      placeholderLocked: 'Locked',
      placeholderPinned: 'Pinned',
      placeholderAuthor: 'Author',
    },
  },
  id: {
    forumMenu: {
      title: 'Forum',
    },
    forumCategoryPage: {
      title: 'Kategori Forum',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',
      labelParent: 'Induk',

      placeholderStatus: 'Status',
      placeholderParentCategory: 'Kategori Induk',
      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderForumCategoryImage: 'Gambar Kategori Forum',

      buttonCaptionCreateNewForumCategory: 'Buat Kategori Forum',
      buttonCaptionDeleteForumCategory: 'Hapus Kategori Forum',
      buttonCaptionEditForumCategory: 'Ubah Kategori Forum',
    },
    forumPostPage: {
      title: 'Pos',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelCategory: 'Kategori',
      labelAuthor: 'Penulis',
      labelLocked: 'Dikunci',
      labelPinned: 'Disematkan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelStatus: 'Status',
      labelOrder: 'Urutan',
      labelMedia: 'Media',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',

      buttonCaptionCreateNewPost: 'Buat Pos Baru',
      buttonCaptionEditPost: 'Ubah Pos',
      buttonCaptionDeletePost: 'Hapus Pos',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',

      placeholderTitle: 'Judul',
      placeholderSlug: 'Slug',
      placeholderContent: 'Konten',
      placeholderContentFormat: 'Format Konten',
      placeholderCategory: 'Kategori',
      placeholderLocked: 'Dikunci',
      placeholderPinned: 'Disematkan',
      placeholderAuthor: 'Penulis',
    },
  },
});

export default LocalizedString;
