import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME,
  FILTER_TYPE_DROPDOWN,
  RXFORM_WFA_REQUEST,
  RXSTATE_WFA_REQUESTS,
  RXSTATE_WFA_REQUEST_PAGE,
  RXFIELD_WFA_REQUEST_APPROVAL_ID,
  RXFIELD_WFA_REQUEST_APPROVAL_NUMBER,
  RXFIELD_WFA_REQUEST_APPROVAL_STATUS,
  RXFIELD_WFA_REQUEST_REQUESTER_ID,
  RXFIELD_WFA_REQUEST_REQUESTER_NAME,
  RXFIELD_WFA_REQUEST_DATES,
  RXFIELD_WFA_REQUEST_APPROVAL_PIC_ID,
  RXFIELD_WFA_REQUEST_APPROVAL_PIC_NAME,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { toMoment } from '../../helper';
import { SimpleDataShape } from '../../type';
import { APPROVAL_STATUS } from '../../../approval/constant';

const renderDialogContent = (
  downloadingDeleting,
  pageMode,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_APPROVAL_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelApprovalID}
            label={LocalizedString.wfaRequestPage.labelApprovalID}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_APPROVAL_NUMBER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelApprovalNumber}
            label={LocalizedString.wfaRequestPage.labelApprovalNumber}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_APPROVAL_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelApprovalStatus}
            label={LocalizedString.wfaRequestPage.labelApprovalStatus}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_APPROVAL_PIC_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelApprovalPicID}
            label={LocalizedString.wfaRequestPage.labelApprovalPicID}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_APPROVAL_PIC_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelApprovalPicName}
            label={LocalizedString.wfaRequestPage.labelApprovalPicName}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_REQUESTER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelRequesterID}
            label={LocalizedString.wfaRequestPage.labelRequesterID}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_REQUESTER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelRequesterName}
            label={LocalizedString.wfaRequestPage.labelRequesterName}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_REQUEST_DATES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaRequestPage.labelDates}
            label={LocalizedString.wfaRequestPage.labelDates}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const WfaRequestPage = ({
  requesters,
  pics,
  downloading,
  downloadingDeleting,
  loadingRequester,
  loadingPic,
  handleSubmit,
  onAppear,
  onCancelPressed,
  onChangePage,
  onChangePageSize,
  onRefresh,
  onSearchBarTextChanged,
  onSortPressed,
  onViewPressed,
  pageMode,
  onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed,
  onResetAdvancedFilterPressed,
  onChangeRequesterText,
  onChangePicText,
}) => (
  <FunctionalPage
    data={RXSTATE_WFA_REQUESTS}
    uiPage={RXSTATE_WFA_REQUEST_PAGE}
    tableColumns={[
      {
        title: LocalizedString.wfaRequestPage.labelNo,
        field: 'no',
        sorting: false,
        width: 40,
        customFilterAndSearch: (term, rowData) => rowData,
      },
      {
        title: LocalizedString.wfaRequestPage.labelApprovalNumber,
        field: 'approvalNumber',
        sorting: false,
      },
      {
        title: LocalizedString.wfaRequestPage.labelRequester,
        field: 'requester.fullName',
        sorting: !downloading,
      },
      {
        title: LocalizedString.wfaRequestPage.labelApprovalPic,
        field: 'approvalPicName',
        sorting: !downloading,
      },
      {
        title: LocalizedString.wfaRequestPage.labelApprovalStatus,
        field: 'approvalStatus',
        sorting: !downloading,
      },
      {
        title: LocalizedString.wfaRequestPage.labelCreatedDate,
        field: 'createdDate',
        sorting: false,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
          : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.wfaRequestPage.labelApprovalStatus,
        field: 'approvalStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: APPROVAL_STATUS,
      },
      {
        title: LocalizedString.wfaRequestPage.labelRequester,
        field: 'requester',
        type: FILTER_TYPE_DROPDOWN,
        loading: loadingRequester,
        data: requesters,
        onChangeFilterText: onChangeRequesterText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wfaRequestPage.labelPic,
        field: 'pic',
        type: FILTER_TYPE_DROPDOWN,
        loading: loadingPic,
        data: pics,
        onChangeFilterText: onChangePicText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wfaRequestPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.wfaRequestPage.title}
    useFullWidth
    disableCreate
    disableEdit
    disableDelete
  >
    {renderDialogContent(
      downloadingDeleting,
      pageMode,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WFA_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WfaRequestPage);

WfaRequestPage.propTypes = {
  requesters: PropTypes.arrayOf(SimpleDataShape).isRequired,
  pics: PropTypes.arrayOf(SimpleDataShape).isRequired,

  pageMode: PropTypes.string.isRequired,

  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingRequester: PropTypes.bool.isRequired,
  loadingPic: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeRequesterText: PropTypes.func.isRequired,
  onChangePicText: PropTypes.func.isRequired,
};
