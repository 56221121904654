import { downloadLeaderBoard } from '../../../helper';
import { downloadingLeaderBoard, setLeaderBoard } from '../simple-action';

const DEFAULT_START_RANK = 1;
const DEFAULT_COUNT = 10;

export default (startRank, count, startPeriod, endPeriod) => async (dispatch, getState) => {
  try {
    dispatch(downloadingLeaderBoard(true));

    const { token } = getState().authentication;

    const result = await downloadLeaderBoard(
      startRank || DEFAULT_START_RANK,
      count || DEFAULT_COUNT,
      startPeriod,
      endPeriod,
      token,
    );

    dispatch(setLeaderBoard(result));
  } finally {
    dispatch(downloadingLeaderBoard(false));
  }
};
