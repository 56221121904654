import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { MENUID_POINT_LEADER_BOARD, RXFORM_LEADER_BOARD } from '../../constant';
import {
  clearLeaderBoard, setActiveSideMenuItem, setAlertErrorMessage,
  downloadLeaderBoardAsync,
} from '../../redux/action';
import LeaderBoardPage from './leader-board.presentation';

const getInitialValues = (state) => {
  const { leaderBoard, uiLeaderBoard } = state;
  const { downloading } = uiLeaderBoard;

  const initVal = !downloading && leaderBoard.length > 0
    ? { result: leaderBoard.map((item, i) => ({ ...item, no: i + 1 })) }
    : { result: [] };
  return initVal;
};

const mapStateToProps = (state) => ({
  downloading: state.uiLeaderBoard.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_LEADER_BOARD));
    dispatch(clearLeaderBoard());
    dispatch(downloadLeaderBoardAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyPressed: async ({
    startRank, count, startPeriod, endPeriod,
  }) => {
    try {
      await dispatch(downloadLeaderBoardAsync(startRank, count, startPeriod, endPeriod));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_LEADER_BOARD));
  },
  onResetPressed: () => {
    dispatch(reset(RXFORM_LEADER_BOARD));
    dispatch(clearLeaderBoard());
    dispatch(downloadLeaderBoardAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardPage);
