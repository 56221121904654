import { sendingArticleNotification } from '../simple-action';
import { sendArticleNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(sendingArticleNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiArticle;

    await sendArticleNotification(tappedId, token);
  } finally {
    dispatch(sendingArticleNotification(false));
  }
};
