import {
  previewingPDFTemplate,
} from '../simple-action';
import { previewPdf } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(previewingPDFTemplate(true));

    const { token } = getState().authentication;
    const { data } = getState().annotationDesigns;
    const { annotationDesignerDescription, annotationDesignerName } = getState().uiAnnotationDesign;
    const { templatePdf } = getState().uiUploadPdf;
    const { name, description } = getState().form.annotationDesignerPage.values;

    const bodyAdd = ({
      name: annotationDesignerName || name,
      description: annotationDesignerDescription || description,
      pdfPath: templatePdf,
      annotations: data,
    });

    const processAnnotation = async () => {
      const responseAdd = await previewPdf(
        bodyAdd,
        token,
      );
      return responseAdd;
    };
    const response = await processAnnotation();


    const arrbuff = await response.arrayBuffer();

    const blob = new Blob([arrbuff], {
      type: 'application/pdf',
    });
    const newWindow = window.open('', '_blank');
    const dataUrl = window.URL.createObjectURL(blob);

    const title = newWindow.document.createElement('title');
    const iframe = newWindow.document.createElement('iframe');

    newWindow.document.head.appendChild(title);

    iframe.setAttribute('src', dataUrl);
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('type', 'application/pdf');

    newWindow.document.body.appendChild(iframe);
  } finally {
    dispatch(previewingPDFTemplate(false));
  }
};
