import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, makeStyles, Switch, withStyles, FormControlLabel,
} from '@material-ui/core';
import {
  COLOR_DANGEROUS, COLOR_BODY_TEXT_LIGHTER,
} from '../constant';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    margin: 5,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: COLOR_BODY_TEXT_LIGHTER,
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const SwitchField = ({
  disabled, error, hidden, value,
  onOptionSelected,
  label, helperText,
  ...props
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(value);

  useEffect(() => { if (value !== checked) { setChecked(value); } }, [value, checked]);

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          <FormControlLabel
            control={(
              <AntSwitch
                disabled={disabled}
                checked={checked}
                onChange={(e, option) => { setChecked(option); onOptionSelected(option); }}
                name="checked"
                color="primary"
                {...props}
              />
              )}
            label={label}
            labelPlacement="end"
          />
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default SwitchField;

SwitchField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  value: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

SwitchField.defaultProps = {
  disabled: false,
  error: false,
  hidden: false,
  value: false,
  onOptionSelected: () => {},
  helperText: null,
};
