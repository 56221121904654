import { downloadingSummaryPreviousMonthLocations, setSummaryPreviousMonthLocations } from '../simple-action';
import { downloadSummaryLocations } from '../../../helper';
import { PACKET_SUMMARY_PREVIOUS_PERIOD, PACKET_SUMMARY_GROUPING_BY_DATE } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryPreviousMonthLocations(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryLocations(PACKET_SUMMARY_PREVIOUS_PERIOD,
      PACKET_SUMMARY_GROUPING_BY_DATE, token);

    dispatch(setSummaryPreviousMonthLocations(result));
  } finally {
    dispatch(downloadingSummaryPreviousMonthLocations(false));
  }
};
