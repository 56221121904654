import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_APPROVALS = 'DOWNLOADING_APPROVALS';
export const downloadingApprovals = makeActionCreator(DOWNLOADING_APPROVALS, 'status');

export const SET_APPROVALS = 'SET_APPROVALS';
export const setApprovals = makeActionCreator(SET_APPROVALS, 'data');

export const ADD_APPROVAL = 'ADD_APPROVAL';
export const addApproval = makeActionCreator(ADD_APPROVAL, 'data');

export const CLEAR_APPROVALS = 'CLEAR_APPROVALS';
export const clearApprovals = makeActionCreator(CLEAR_APPROVALS);

export const DOWNLOADING_APPROVAL_DETAIL = 'DOWNLOADING_APPROVAL_DETAIL';
export const downloadingApprovalDetail = makeActionCreator(DOWNLOADING_APPROVAL_DETAIL, 'status');

export const DOWNLOADING_APPROVAL_ATTACHMENT = 'DOWNLOADING_APPROVAL_ATTACHMENT';
export const downloadingApprovalAttachment = makeActionCreator(DOWNLOADING_APPROVAL_ATTACHMENT, 'status');


export const SET_APPROVAL_SELECTED_PAGE_SIZE = 'SET_APPROVAL_SELECTED_PAGE_SIZE';
export const setApprovalSelectedPageSize = makeActionCreator(SET_APPROVAL_SELECTED_PAGE_SIZE, 'size');

export const SET_APPROVAL_SELECTED_ORDER_BY = 'SET_APPROVAL_SELECTED_ORDER_BY';
export const setApprovalSelectedOrderBy = makeActionCreator(SET_APPROVAL_SELECTED_ORDER_BY, 'order');

export const SET_APPROVAL_TAPPED_ID = 'SET_APPROVAL_TAPPED_ID';
export const setApprovalTappedId = makeActionCreator(SET_APPROVAL_TAPPED_ID, 'id');

export const SET_APPROVAL_SEARCH_TEXT = 'SET_APPROVAL_SEARCH_TEXT';
export const setApprovalSearchText = makeActionCreator(SET_APPROVAL_SEARCH_TEXT, 'text');

export const SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setApprovalAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SHOW_APPROVAL_APPROVER_POP_UP = 'SHOW_APPROVAL_APPROVER_POP_UP';
export const showApprovalApproverPopUp = makeActionCreator(SHOW_APPROVAL_APPROVER_POP_UP, 'status');

export const SHOW_APPROVAL_COMMENT_POP_UP = 'SHOW_APPROVAL_COMMENT_POP_UP';
export const showApprovalCommentPopUp = makeActionCreator(SHOW_APPROVAL_COMMENT_POP_UP, 'status');


export const DOWNLOADING_APPROVAL_PROCESSES = 'DOWNLOADING_APPROVAL_PROCESSES';
export const downloadingApprovalProcesses = makeActionCreator(DOWNLOADING_APPROVAL_PROCESSES, 'status');

export const DOWNLOADING_APPROVAL_PROVIDERS = 'DOWNLOADING_APPROVAL_PROVIDERS';
export const downloadingApprovalProviders = makeActionCreator(DOWNLOADING_APPROVAL_PROVIDERS, 'status');

export const SET_APPROVAL_PROCESSES = 'SET_APPROVAL_PROCESSES';
export const setApprovalProcesses = makeActionCreator(SET_APPROVAL_PROCESSES, 'data');

export const SET_APPROVAL_PROVIDERS = 'SET_APPROVAL_PROVIDERS';
export const setApprovalProviders = makeActionCreator(SET_APPROVAL_PROVIDERS, 'data');

export const CLEAR_APPROVAL_PROCESSES = 'CLEAR_APPROVAL_PROCESSES';
export const clearApprovalProcesses = makeActionCreator(CLEAR_APPROVAL_PROCESSES);

export const CLEAR_APPROVAL_PROVIDERS = 'CLEAR_APPROVAL_PROVIDERS';
export const clearApprovalProviders = makeActionCreator(CLEAR_APPROVAL_PROVIDERS);

export const SET_APPROVAL_SELECTED_STEP_ID = 'SET_APPROVAL_SELECTED_STEP_ID';
export const setApprovalSelectedStepId = makeActionCreator(SET_APPROVAL_SELECTED_STEP_ID, 'id');

export const RESENDING_EVENT = 'RESENDING_EVENT';
export const resendingEvent = makeActionCreator(RESENDING_EVENT, 'status');

export const SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION = 'SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION';
export const setApprovalSelectedResendEventOption = makeActionCreator(SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION, 'value');
