import { reset } from 'redux-form';
import {
  addingEditingGameQRCategory, clearGameQRCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addGameQRCategory, editGameQRCategory, transformLocalImagePath } from '../../../helper';
import { RXFORM_GAMEQR_CATEGORY, PAGE_MODE_TABLE } from '../../../constant';
import downloadGameQRCategoriesAsync from './downloadGameQRCategoriesAsync';

export default (name, description, image) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingGameQRCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGameQRCategory;

    if (tappedId) {
      await editGameQRCategory(
        tappedId, name, description, transformLocalImagePath(image), token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addGameQRCategory(
        name, description, transformLocalImagePath(image), token,
      );
    }

    dispatch(reset(RXFORM_GAMEQR_CATEGORY));
    dispatch(clearGameQRCategories());
    dispatch(downloadGameQRCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingGameQRCategory(false));
  }
};
