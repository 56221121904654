import {
  addPointTransaction, downloadingDeletingPointTransaction,
  setFunctionalPageMode,
} from '../simple-action';
import { downloadPointTransaction } from '../../../helper';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPointTransaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPointTransaction;

    const pointTransaction = await downloadPointTransaction(tappedId, token);
    dispatch(addPointTransaction(pointTransaction));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingPointTransaction(false));
  }
};
