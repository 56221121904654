import { downloadingScopes, setScopes } from '../simple-action';
import { downloadScopes, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, scopes, uiScope, uiApiKey,
  } = getState();
  const { token } = authentication;
  const { meta, data } = scopes;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiScope;
  const { selectedOwnerAppName } = uiApiKey;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  let additionalFilterString = '';
  if (selectedOwnerAppName) {
    if (selectedOwnerAppName.label === selectedOwnerAppName.value) {
      additionalFilterString = `|service.name=${selectedOwnerAppName.value}`;
    }
    if (selectedOwnerAppName.label !== selectedOwnerAppName.value) {
      additionalFilterString = `|service.id=${selectedOwnerAppName.value}`;
    }
  }

  try {
    dispatch(downloadingScopes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadScopes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString.concat(additionalFilterString),
      token,
    );

    if (list) {
      dispatch(setScopes(list));
    }
  } finally {
    dispatch(downloadingScopes(false));
  }
};
