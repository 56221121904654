import { reset } from 'redux-form';
import {
  addingEditingForumCategory, clearForumCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addForumCategory, editForumCategory, transformLocalImagePath } from '../../../helper';
import { RXFORM_FORUM_CATEGORY, PAGE_MODE_TABLE } from '../../../constant';
import downloadForumCategoriesAsync from './downloadForumCategoriesAsync';

export default (name, description, image) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingForumCategory(true));

    const { token } = getState().authentication;
    const { tappedId, selectedParentCategory } = getState().uiForumCategory;

    if (tappedId) {
      await editForumCategory(
        tappedId, name, description, selectedParentCategory, transformLocalImagePath(image), token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addForumCategory(
        name, description, selectedParentCategory, transformLocalImagePath(image), token,
      );
    }

    dispatch(reset(RXFORM_FORUM_CATEGORY));
    dispatch(clearForumCategories());
    dispatch(downloadForumCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingForumCategory(false));
  }
};
