import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    pdfManagementMenu: {
      title: 'Document Annotation',
    },
    annotationDesignerPage: {
      title: 'Annotation Designer',

      buttonCaptionShowJSON: 'Show JSON',
      buttonCaptionPreview: 'Preview',
      buttonCaptionAddNew: 'Add New',
      buttonCaptionUploadTemplate: 'Upload Template',
      buttonCaptionAdd: 'Add',
      buttonCaptionEdit: 'Edit',
      buttonCaptionClear: 'Clear',

      placeholderValue: 'Value',
      placeholderPage: 'Page',
      placeholderCoordinateX: 'Coordinate X',
      placeholderCoordinateY: 'Coordinate Y',
      placeholderWidth: 'Width',
      placeholderHeight: 'Height',
      placeholderFontName: 'Font Name',
      placeholderFontSize: 'Font Size',
      placeholderFontStyle: 'Font Style',
      placeholderRotation: 'Rotation',
      placeholderImage: 'Image',
      placeholderColor: 'Color',
      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderFile: 'File',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelValue: 'Value',
      labelPage: 'Page',
      labelCoordinateX: 'Coordinate X',
      labelCoordinateY: 'Coordinate Y',
      labelWidth: 'Width',
      labelHeight: 'Height',
      labelFontName: 'Font Name',
      labelFontSize: 'Font Size',
      labelFontStyle: 'Font Style',
      labelRotation: 'Rotation',
      labelImage: 'Image',
      labelColor: 'Color',
      labelNoPage: 'No Page Found',
      labelJSON: 'JSON',
      labelPageMustBeGreater: 'Page must be greater than 0',
      labelFontSizeMustBeGreater: 'Font size must be greater than 0',
      labelImageIsRequired: 'Image cannot be empty',

      buttonCaptionCreateNewAnnotation: 'Create New Annotation',
      buttonCaptionEditAnnotation: 'Edit Annotation',
      buttonCaptionDeleteAnnotation: 'Delete Annotation',

      errorMessagePDFExpired: 'PDF is expired, please reupload PDF',
    },
  },
  id: {
    pdfManagementMenu: {
      title: 'Anotasi Dokumen',
    },
    annotationDesignerPage: {
      title: 'Desainer Anotasi',

      buttonCaptionShowJSON: 'Perlihatkan JSON',
      buttonCaptionPreview: 'Pratinjau',
      buttonCaptionAddNew: 'Buat Baru',
      buttonCaptionUploadTemplate: 'Unggah Templat',
      buttonCaptionAdd: 'Tambah',
      buttonCaptionEdit: 'Ubah',
      buttonCaptionClear: 'Hapus Semua',

      placeholderValue: 'Nilai',
      placeholderPage: 'Halaman',
      placeholderCoordinateX: 'Koordinat X',
      placeholderCoordinateY: 'Koordinat Y',
      placeholderWidth: 'Lebar',
      placeholderHeight: 'Tinggi',
      placeholderFontName: 'Nama Font',
      placeholderFontSize: 'Ukuran Font',
      placeholderFontStyle: 'Gaya Font',
      placeholderRotation: 'Rotasi',
      placeholderImage: 'Gambar',
      placeholderColor: 'Warna',
      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderFile: 'Berkas',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Dibuat',
      labelValue: 'Nilai',
      labelPage: 'Halaman',
      labelCoordinateX: 'Koordinat X',
      labelCoordinateY: 'Koordinat Y',
      labelWidth: 'Lebar',
      labelHeight: 'Tinggi',
      labelFontName: 'Nama Font',
      labelFontSize: 'Ukuran Font',
      labelFontStyle: 'Gaya Font',
      labelRotation: 'Rotasi',
      labelImage: 'Gambar',
      labelColor: 'Warna',
      labelNoPage: 'Tidak Ada Halaman',
      labelJSON: 'JSON',
      labelPageMustBeGreater: 'Halaman harus lebih besar dari 0',
      labelFontSizeMustBeGreater: 'Ukuran font harus lebih besar dari 0',
      labelImageIsRequired: 'Gambar Tidak boleh Kosong',

      buttonCaptionCreateNewAnnotation: 'Buat Anotasi Baru',
      buttonCaptionEditAnnotation: 'Ubah Anotasi',
      buttonCaptionDeleteAnnotation: 'Hapus Anotasi',

      errorMessagePDFExpired: 'PDF Kadaluwarsa, mohon unggah ulang PDF',
    },
  },
});

export default LocalizedString;
