import { downloadAchievement } from '../../../helper';
import { downloadingAchievement, setAchievement } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAchievement(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAchievement;

    const result = await downloadAchievement(tappedId, token);

    dispatch(setAchievement(result));
  } finally {
    dispatch(downloadingAchievement(false));
  }
};
