import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_POINT_SUBMISSIONS, MENUID_POINT_POINT_SUBMISSION,
  PAGE_MODE_TABLE, RXFORM_POINT_SUBMISSION,
} from '../../constant';
import { transformInitialValues, transformDropdownData, toMoment } from '../../helper';
import {
  clearPointSubmissions, clearUsers, setAdvancedFilterDialogSelectedFilterString,
  setActiveSideMenuItem, setAlertErrorMessage, setPointSubmissionSearchText,
  setPointSubmissionSelectedPageSize, setPointSubmissionSelectedOrderBy,
  setPointSubmissionTappedId, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadPointSubmissionAsync, downloadPointSubmissionsAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import PointSubmissionPage from './point-submission.presentation';

const getInitialValues = (state) => {
  const {
    pointSubmissions, uiFunctionalPage, uiPointSubmission,
  } = state;
  const { data } = pointSubmissions;
  const { downloadingDeleting, tappedId } = uiPointSubmission;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user.fullName,
    validUntil: toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    label: '',
    submissionStatus: '',
    submissionStatusNotes: null,
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    callbackUrl: '',
    fullName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPointSubmission.downloading,
  initialValues: getInitialValues(state),
  users: transformDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_POINT_SUBMISSION));
    dispatch(setPointSubmissionSearchText(''));
    dispatch(clearPointSubmissions());
    dispatch(setPointSubmissionSelectedPageSize(20));
    dispatch(setPointSubmissionSelectedOrderBy(INITIAL_ORDER_BY_POINT_SUBMISSIONS));
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPointSubmissions());
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_POINT_SUBMISSION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPointSubmissionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPointSubmissionSelectedPageSize(pageSize));
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPointSubmissionSelectedPageSize(pageSize));
    dispatch(clearPointSubmissions());
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPointSubmissions());
    dispatch(setPointSubmissionSelectedOrderBy(INITIAL_ORDER_BY_POINT_SUBMISSIONS));
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPointSubmissionSearchText(text));
      dispatch(clearPointSubmissions());
      await dispatch(downloadPointSubmissionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPointSubmissionSelectedOrderBy(orderBy));
    dispatch(clearPointSubmissions());
    dispatch(downloadPointSubmissionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setPointSubmissionTappedId(id));
    dispatch(downloadPointSubmissionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PointSubmissionPage);
