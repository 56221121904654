import { downloadingSurveyQuestionParents, setSurveyQuestionParents } from '../simple-action';
import { downloadSurveyQuestions, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_SURVEY_QUESTIONS } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, surveyQuestionParents, uiSurveyQuestion } = getState();
  const { token } = authentication;
  const { meta } = surveyQuestionParents;
  const { parentSearchText } = uiSurveyQuestion;

  const searchText = transformSearchText(parentSearchText);

  try {
    dispatch(downloadingSurveyQuestionParents(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSurveyQuestions(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_SURVEY_QUESTIONS,
      searchText,
      null,
      token,
    );

    dispatch(setSurveyQuestionParents(list));
  } finally {
    dispatch(downloadingSurveyQuestionParents(false));
  }
};
