import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME,
  RXFIELD_WFA_QUOTA_DEPARTMENT,
  RXFIELD_WFA_QUOTA_QUOTA_PER_MONTH,
  RXFORM_WFA_QUOTA,
  RXSTATE_WFA_QUOTAS,
  RXSTATE_WFA_QUOTA_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { rxformValidateWFAQuota } from '../../validation';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (
  addingEditing,
  downloadingDeleting,
  pageMode,
  departments,
  departmentDefaultValue,
  loadingDepartment,
  onChangeDepartmentText,
  onDepartmentOptionSelected,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WFA_QUOTA_DEPARTMENT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.wfaQuotaPage.placeholderDepartment}
            label={LocalizedString.wfaQuotaPage.placeholderDepartment}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            data={departments}
            value={departmentDefaultValue}
            loading={loadingDepartment}
            onChangeText={onChangeDepartmentText}
            onOptionSelected={onDepartmentOptionSelected}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WFA_QUOTA_QUOTA_PER_MONTH}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.wfaQuotaPage.placeholderQuotaPerMonth}
            label={LocalizedString.wfaQuotaPage.placeholderQuotaPerMonth}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            type="number"
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const WFAQuotaPage = ({
  addingEditing,
  downloading,
  downloadingDeleting,
  handleSubmit,
  onAppear,
  onCancelPressed,
  onChangePage,
  onChangePageSize,
  onConfirmPressed,
  onCreatePressed,
  onDeletePressed,
  onEditPressed,
  onRefresh,
  onSavePressed,
  onSearchBarTextChanged,
  onSortPressed,
  onSubmitPressed,
  onViewPressed,
  pageMode,
  onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed,
  onResetAdvancedFilterPressed,
  initialValues,
  departments,
  loadingDepartment,
  onChangeDepartmentText,
  onDepartmentOptionSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_WFA_QUOTAS}
    uiPage={RXSTATE_WFA_QUOTA_PAGE}
    tableColumns={[
      {
        title: LocalizedString.wfaQuotaPage.labelNo,
        field: 'no',
        sorting: false,
        width: 40,
        customFilterAndSearch: (term, rowData) => rowData,
      },
      {
        title: LocalizedString.wfaQuotaPage.labelDepartment,
        field: 'departmentName',
        sorting: !downloading,
      },
      {
        title: LocalizedString.wfaQuotaPage.labelQuota,
        field: 'quotaPerMonth',
        sorting: !downloading,
      },
      {
        title: LocalizedString.wfaQuotaPage.labelCreatedDate,
        field: 'createdDate',
        sorting: false,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
          : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.wfaQuotaPage.labelDepartment,
        field: 'departmentName',
        type: FILTER_TYPE_DROPDOWN,
        data: departments,
        loading: loadingDepartment,
        onChangeFilterText: onChangeDepartmentText,
      },
      {
        title: LocalizedString.wfaQuotaPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={
      LocalizedString.wfaQuotaPage.buttonCaptionCreateNewWFAQuota
    }
    deleteButtonCaption={
      LocalizedString.wfaQuotaPage.buttonCaptionDeleteWFAQuota
    }
    editButtonCaption={
      LocalizedString.wfaQuotaPage.buttonCaptionEditWFAQuota
    }
    title={LocalizedString.wfaQuotaPage.title}
    createPermissionName="ATTENDANCEADDON_CREATE_WFA_QUOTA"
    deletePermissionName="ATTENDANCEADDON_DELETE_WFA_QUOTA"
    editPermissionName="ATTENDANCEADDON_EDIT_WFA_QUOTA"
    useFullWidth
  >
    {renderDialogContent(
      addingEditing,
      downloadingDeleting,
      pageMode,
      departments,
      initialValues.department,
      loadingDepartment,
      onChangeDepartmentText,
      onDepartmentOptionSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WFA_QUOTA,
  validate: rxformValidateWFAQuota,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WFAQuotaPage);

WFAQuotaPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  departments: PropTypes.arrayOf(SimpleDataShape).isRequired,

  pageMode: PropTypes.string.isRequired,

  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingDepartment: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeDepartmentText: PropTypes.func.isRequired,
  onDepartmentOptionSelected: PropTypes.func.isRequired,
};
