import { connect } from 'react-redux';
import {
  downloadAllAnalyticsDataAsync, setActiveSideMenuItem,
  setAlertErrorMessage,
} from '../../redux/action';
import { MENUID_ANALYTICS } from '../../constant';
import { downloadMyConfigItemsAsync } from '../../../configuration/redux/action';
import AnalyticsPage from './analytics.presentation';

const mapDispatchToProps = (dispatch) => ({
  onAppear: async () => {
    try {
      dispatch(setActiveSideMenuItem(MENUID_ANALYTICS));
      await dispatch(downloadMyConfigItemsAsync());
      dispatch(downloadAllAnalyticsDataAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } catch (err) {
      dispatch(setAlertErrorMessage(err));
    }
  },
});

export default connect(null, mapDispatchToProps)(AnalyticsPage);
