import { SET_CALENDAR_DATE_RANGE_STATUS, CLEAR_CALENDAR_DATE_RANGE_STATUS } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_CALENDAR_DATE_RANGE_STATUS: {
      return action.data;
    }
    case CLEAR_CALENDAR_DATE_RANGE_STATUS:
      return state;
    default:
      return state;
  }
};
