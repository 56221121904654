import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { BasePage } from '../../component';
import { COLOR_SECONDARY } from '../../constant';
import LocalizedString from '../../localization';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  title: {
    color: COLOR_SECONDARY,
  },
}));

const ReportPage = ({ onAppear }) => {
  const classes = useStyles();
  useEffect(onAppear, []);
  return (
    <BasePage>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.title}>{LocalizedString.common.labelReport}</Typography>
      </div>
    </BasePage>
  );
};

export default ReportPage;

ReportPage.propTypes = {
  onAppear: PropTypes.func.isRequired,
};
