import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  PAGE_MODE_TABLE, INITIAL_ORDER_BY_LOCATIONS, MENUID_AGENDA_LOCATION,
  RXFORM_LOCATIONS,
} from '../../constant';
import LocationPage from './location.presentation';
import { transformInitialValues } from '../../helper';
import {
  clearLocations, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setLocationsSearchText, setLocationsSelectedPageSize,
  setLocationsSelectedOrderBy, setLocationTappedId,
  addEditLocationAsync, downloadDeleteLocationAsync, downloadLocationsAsync,
} from '../../redux/action';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const { locations, uiFunctionalPage, uiLocation } = state;
  const { data } = locations;
  const { downloadingDeleting, tappedId } = uiLocation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found) : {
    name: '',
    description: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiLocation.addingEditing,
  downloading: state.uiLocation.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AGENDA_LOCATION));
    dispatch(setLocationsSearchText(''));
    dispatch(clearLocations());
    dispatch(setLocationsSelectedPageSize(20));
    dispatch(setLocationsSelectedOrderBy(INITIAL_ORDER_BY_LOCATIONS));
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadLocationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setLocationsSelectedPageSize(pageSize));
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_LOCATIONS));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteLocationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setLocationTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setLocationTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onRefresh: (pageSize) => {
    dispatch(setLocationsSelectedPageSize(pageSize));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditLocationAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setLocationsSearchText(text));
      dispatch(clearLocations());
      await dispatch(downloadLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setLocationsSelectedOrderBy(orderBy));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditLocationAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setLocationTappedId(id));
  },
  onEditPressed: (id) => {
    dispatch(setLocationTappedId(id));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
