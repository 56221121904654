import {
  SET_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS,
  CLEAR_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS,
} from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS: {
      return action.data;
    }
    case CLEAR_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS:
      return state;
    default:
      return state;
  }
};
