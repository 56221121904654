import {
  downloadingMyConfigItems, setMyConfigItems,
} from '../simple-action';
import { downloadMyConfigItems } from '../../../helper';

export default (isLoginScreen = false) => async (dispatch, getState) => {
  try {
    dispatch(downloadingMyConfigItems(true));
    const { authentication } = getState();
    const { token } = authentication;

    const configurations = await downloadMyConfigItems(token, isLoginScreen);

    dispatch(setMyConfigItems(configurations));
  } finally {
    dispatch(downloadingMyConfigItems(false));
  }
};
