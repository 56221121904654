import {
  DOWNLOADING_AUDIT_TRAILS, DOWNLOADING_AUDIT_TRAIL, FILTERING_AUDIT_TRAIL_COLUMNS,
  SET_AUDIT_TRAIL_SELECTED_PAGE_SIZE, SET_AUDIT_TRAIL_TAPPED_ID, SET_AUDIT_TRAIL_SEARCH_TEXT,
  SET_AUDIT_TRAIL_SELECTED_ORDER_BY, SET_AUDIT_TRAIL_SELECTED_SERVICE, COPYING_COLUMN_VALUE,
  SET_AUDIT_TRAIL_SELECTED_MODIFIED,
} from '../action';
import { INITIAL_ORDER_BY_AUDIT_TRAILS } from '../../constant';

const initialState = {
  copying: false,
  downloading: false,
  downloadingDeleting: false,
  filtering: false,
  isModified: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_AUDIT_TRAILS,
  selectedServices: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COPYING_COLUMN_VALUE:
      return { ...state, copying: action.status };
    case DOWNLOADING_AUDIT_TRAILS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_AUDIT_TRAIL:
      return { ...state, downloadingDeleting: action.status };
    case SET_AUDIT_TRAIL_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_AUDIT_TRAIL_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_AUDIT_TRAIL_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_AUDIT_TRAIL_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_AUDIT_TRAIL_SELECTED_SERVICE:
      return { ...state, selectedServices: action.option };
    case SET_AUDIT_TRAIL_SELECTED_MODIFIED:
      return { ...state, isModified: action.status };
    case FILTERING_AUDIT_TRAIL_COLUMNS:
      return { ...state, filtering: action.status };
    default: return state;
  }
};
