import React from 'react';
import { Typography, makeStyles, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  COLOR_BACKGROUND,
  COLOR_ICON_LIGHTER,
  COLOR_PRIMARY,
} from '../../../constant';

const useStyles = makeStyles(() => ({
  w500: {
    fontWeight: '500',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  card: {
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 16,
    padding: 24,
    border: '.5px solid #dadce0',
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
    flex: 1,
  },
  loading: {
    display: 'flex',
    height: 120,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 16,
    padding: 24,
    border: '.5px solid #dadce0',
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
    flex: 1,
  },
  mb10: {
    marginBottom: 10,
  },
  colorPrimary: {
    backgroundColor: COLOR_ICON_LIGHTER,
  },
  barColorPrimary: {
    backgroundColor: COLOR_PRIMARY,
  },
}));

const AnalyticsPercentageCard = ({ title, loading, children }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <Typography
        variant="body1"
        className={`${classes.mb10} ${classes.w500}`}
      >
        {title}
      </Typography>
      {children}
    </div>
  );
};
export default AnalyticsPercentageCard;

AnalyticsPercentageCard.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AnalyticsPercentageCard.defaultProps = {
  loading: false,
  title: '',
};
