import { downloadingTotalPersonCount, setTotalPersonCount } from '../simple-action';
import { downloadTokenTotalPersonCount } from '../../../helper';

export default (filterString) => async (dispatch, getState) => {
  try {
    dispatch(downloadingTotalPersonCount(true));

    const { token } = getState().authentication;

    const response = await downloadTokenTotalPersonCount(filterString, token);

    dispatch(setTotalPersonCount(response.totalCount));
  } finally {
    dispatch(downloadingTotalPersonCount(false));
  }
};
