import { downloadingSmses, setSmses } from '../simple-action';
import { downloadSmses, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, smses, uiSms } = getState();
  const { token } = authentication;
  const { meta, data } = smses;
  const { selectedPageSize, searchBarText, orderBy } = uiSms;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingSmses(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSmses(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setSmses(list));
    }
  } finally {
    dispatch(downloadingSmses(false));
  }
};
