import {
  Image, Video, MusicNoteEighth, FilePdf, GoogleSpreadsheet,
  File, FileDocumentBox, Alphabetical, Numeric0,
} from 'mdi-material-ui';
import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPutRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_CANCEL_MY_JOB,
  REST_URL_DELETE_MY_JOB,
  REST_URL_MY_JOBS, REST_URL_VIEW_MY_JOB,
  REST_URL_JOB,
  REST_URL_DELETE_JOB,
  REST_URL_VIEW_JOB,
  COLOR_PRIMARY,
  RESULT_TYPE,
} from './constant';


export * from '../../helper';

export const downloadJobs = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_JOB.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_JOB, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadMyJobs = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_MY_JOBS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_MY_JOBS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadJob = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_JOB.replace(/\{id\}/, id), token);
  return response;
};

export const deleteJob = async (id, token) => {
  await sendDeleteRequest(REST_URL_DELETE_JOB.replace(/\{id\}/, id), token);
};

export const cancelMyJob = async (id, token) => {
  const response = await sendPutRequest(REST_URL_CANCEL_MY_JOB.replace(/\{id\}/, id), token);
  return response;
};

export const downloadMyJob = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_MY_JOB.replace(/\{id\}/, id), token);
  return response;
};

export const deleteMyJob = async (id, token) => {
  await sendDeleteRequest(REST_URL_DELETE_MY_JOB.replace(/\{id\}/, id), token);
};

export const getResultTypeIcon = (resultType) => {
  switch (resultType) {
    case RESULT_TYPE[1]:
      return { icon: Image, color: COLOR_PRIMARY };
    case RESULT_TYPE[2]:
      return { icon: Video, color: COLOR_PRIMARY };
    case RESULT_TYPE[3]:
      return { icon: MusicNoteEighth, color: COLOR_PRIMARY };
    case RESULT_TYPE[4]:
      return { icon: FilePdf, color: COLOR_PRIMARY };
    case RESULT_TYPE[5]:
      return { icon: File, color: COLOR_PRIMARY };
    case RESULT_TYPE[6]:
      return { icon: GoogleSpreadsheet, color: COLOR_PRIMARY };
    case RESULT_TYPE[7]:
      return { icon: FileDocumentBox, color: COLOR_PRIMARY };
    case RESULT_TYPE[8]:
      return { icon: Alphabetical, color: COLOR_PRIMARY };
    case RESULT_TYPE[9]:
      return { icon: Numeric0, color: COLOR_PRIMARY };
    default: return null;
  }
};
