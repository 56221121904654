import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_PACKET_TYPE_DESCRIPTION, RXFIELD_PACKET_TYPE_NAME,
  RXFORM_PACKET_TYPE, RXSTATE_PACKET_TYPES, RXSTATE_PACKET_TYPE_PAGE,
  RXFIELD_PACKET_TYPE_KEEP_PACKET_HOURS,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidatePacketType } from '../../validation';

const renderDialogContent = (addingEditing, downloadingDeleting, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Field
        name={RXFIELD_PACKET_TYPE_NAME}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.packetTypeScreen.placeholderName}
        label={LocalizedString.packetTypeScreen.placeholderName}
        disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        required
      />
    </Grid>

    <Grid item sm md>
      <Field
        name={RXFIELD_PACKET_TYPE_DESCRIPTION}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.packetTypeScreen.placeholderDescription}
        label={LocalizedString.packetTypeScreen.placeholderDescription}
        disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        required
        multiline
      />
    </Grid>

    <Grid item sm md>
      <Field
        name={RXFIELD_PACKET_TYPE_KEEP_PACKET_HOURS}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.packetTypeScreen.placeholderKeepPacketHours}
        label={LocalizedString.packetTypeScreen.placeholderKeepPacketHours}
        disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
      />
    </Grid>
  </Grid>
);

const PacketTypePage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onEditPressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_PACKET_TYPES}
    uiPage={RXSTATE_PACKET_TYPE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.packetTypeScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.packetTypeScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.packetTypeScreen.labelKeepPacketHours, field: 'keepPacketForHours', sorting: !downloading },
      { title: LocalizedString.packetTypeScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.packetTypeScreen.buttonCaptionCreateNewPacketType}
    deleteButtonCaption={LocalizedString.packetTypeScreen.buttonCaptionDeletePacketType}
    editButtonCaption={LocalizedString.packetTypeScreen.buttonCaptionEditPacketType}
    title={LocalizedString.packetTypeScreen.title}
    createPermissionName="PACKET_CREATE_PACKET_TYPE"
    deletePermissionName="PACKET_DELETE_PACKET_TYPE"
    editPermissionName="PACKET_EDIT_PACKET_TYPE"
    useFullWidth
  >
    {renderDialogContent(addingEditing, downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PACKET_TYPE,
  validate: rxformValidatePacketType,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PacketTypePage);

PacketTypePage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
