import {
  downloadingSummaryDeviceManufacturerDistributions,
  setSummaryDeviceManufacturerDistributions,
} from '../simple-action';
import {
  downloadSummaryDeviceManufacturerDistribution,
} from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingSummaryDeviceManufacturerDistributions(true));

    const list = await downloadSummaryDeviceManufacturerDistribution(
      token,
    );

    if (list) {
      dispatch(setSummaryDeviceManufacturerDistributions(list));
    }
  } finally {
    dispatch(downloadingSummaryDeviceManufacturerDistributions(false));
  }
};
