import {
  DOWNLOADING_WORKING_HOURS,
  ADDING_EDITING_WORKING_HOUR,
  DOWNLOADING_DELETING_WORKING_HOUR,
  SET_WORKING_HOUR_SELECTED_PAGE_SIZE,
  SET_WORKING_HOUR_TAPPED_ID,
  SET_WORKING_HOUR_SEARCH_TEXT,
  SET_WORKING_HOUR_SELECTED_ORDER_BY,
  SET_WORKING_HOUR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  ENABLING_DISABLING_WORKING_HOUR,
  SET_WORKING_HOUR_SELECTED_TIMEZONE,
} from '../action';
import { INITIAL_ORDER_BY_WORKING_HOURS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_WORKING_HOURS,
  selectedTimezone: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WORKING_HOURS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_WORKING_HOUR:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_WORKING_HOUR:
      return { ...state, downloadingDeleting: action.status };
    case ENABLING_DISABLING_WORKING_HOUR:
      return { ...state, enablingDisabling: action.status };
    case SET_WORKING_HOUR_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WORKING_HOUR_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WORKING_HOUR_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WORKING_HOUR_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WORKING_HOUR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_WORKING_HOUR_SELECTED_TIMEZONE:
      return { ...state, selectedTimezone: action.value };
    default:
      return state;
  }
};
