import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearWfaRequests,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setWfaRequestSearchText,
  setWfaRequestSelectedPageSize,
  setWfaRequestSelectedOrderBy,
  setWfaRequestTappedId,
  downloadWfaRequestAsync,
  downloadWfaRequestsAsync,
  setWfaRequestAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_WFA_REQUESTS,
  MENUID_ATTENDANCE_ADDON_WFA_REQUEST,
  PAGE_MODE_TABLE,
  RXFORM_WFA_REQUEST,
  SIMPLE_DATE_FORMAT,
} from '../../constant';
import {
  transformInitialValues, toMoment, debounceSearch, transformUserDropdownData,
} from '../../helper';
import WfaRequestPage from './wfa-request.presentation';
import {
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  clearProfiles,
  downloadProfilesAsync,
} from '../../../../redux/action';
import { clearEmployees, downloadEmployeesAsync, setHistoryEmployeeSearchText } from '../../../attendance/redux/action';

const getDate = (dates) => {
  const transformedDates = dates.map(
    (date) => toMoment(date).format(SIMPLE_DATE_FORMAT),
  );
  let dateSring = '';
  transformedDates.forEach((date, index) => {
    dateSring += index === 0 ? date : `\n${date}`;
  });
  return dateSring;
};

const getInitialValues = (state) => {
  const { wfaRequests, uiWfaRequest, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiWfaRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? wfaRequests.data[tappedId]
    : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      approvalId:
        found.approvalId !== null ? found.approvalId : '',
      approvalNumber:
        found.approvalNumber !== null ? found.approvalNumber : '',
      approvalStatus:
        found.approvalStatus !== null ? found.approvalStatus : '',
      requesterId:
        found.requester !== null ? found.requester.id : '',
      requesterName:
        found.requester !== null ? found.requester.fullName : '',
      dates:
        found.dates.length === 0 ? '' : getDate(found.dates),
    })
    : {
      approvalId: '',
      approvalNumber: '',
      approvalStatus: '',
      requesterId: '',
      requesterName: '',
      dates: [],
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  downloading: state.uiWfaRequest.downloading,
  downloadingDeleting: state.uiWfaRequest.downloadingDeleting,
  initialValues: getInitialValues(state),
  requesters: transformUserDropdownData(state.profiles.data),
  pics: transformUserDropdownData(state.employees.data),
  loadingRequester: state.uiProfile.downloading,
  loadingPic: state.uiHistory.downloadingEmployees,
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const searchPicDebounce = debounceSearch((dispatch) => {
  dispatch(clearEmployees());
  dispatch(downloadEmployeesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(
      setWfaRequestAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_WFA_REQUEST));
    dispatch(setWfaRequestSearchText(''));
    dispatch(clearWfaRequests());
    dispatch(setWfaRequestSelectedPageSize(20));
    dispatch(setWfaRequestSelectedOrderBy(INITIAL_ORDER_BY_WFA_REQUESTS));
    dispatch(downloadWfaRequestsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_WFA_REQUEST));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWfaRequestsAsync(pageNo + 1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWfaRequestSelectedPageSize(pageSize));
    dispatch(downloadWfaRequestsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onRefresh: (pageSize) => {
    dispatch(setWfaRequestSelectedPageSize(pageSize));
    dispatch(clearWfaRequests());
    dispatch(downloadWfaRequestsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWfaRequestSearchText(text));
      dispatch(clearWfaRequests());
      await dispatch(downloadWfaRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWfaRequestSelectedOrderBy(orderBy));
    dispatch(clearWfaRequests());
    dispatch(downloadWfaRequestsAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onViewPressed: (id) => {
    dispatch(setWfaRequestTappedId(id));
    dispatch(downloadWfaRequestAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(clearEmployees());
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('requester', 'requester.id')
      .replace('pic', 'approvalPicId');

    dispatch(
      setWfaRequestAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearWfaRequests());
    dispatch(downloadWfaRequestsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_WFA_REQUEST));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setWfaRequestAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setWfaRequestSelectedOrderBy(INITIAL_ORDER_BY_WFA_REQUESTS));
    dispatch(clearWfaRequests());
    dispatch(downloadWfaRequestsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangeRequesterText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onChangePicText: async (text) => {
    dispatch(setHistoryEmployeeSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchPicDebounce(dispatch);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WfaRequestPage);
