import { transformSearchText } from '../../../../../helper';
import { downloadDocumentGeneratorTemplates } from '../../../helper';
import { downloadingDocumentGeneratorTemplates, setDocumentGeneratorTemplates } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, templates, uiTemplate,
  } = getState();
  const { token } = authentication;
  const { meta } = templates;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiTemplate;

  try {
    dispatch(downloadingDocumentGeneratorTemplates(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const list = await downloadDocumentGeneratorTemplates(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      token,
    );

    if (list) {
      dispatch(setDocumentGeneratorTemplates(list));
    }
  } finally {
    dispatch(downloadingDocumentGeneratorTemplates(false));
  }
};
