import { downloadingSurveyResponses, setSurveyResponses } from '../simple-action';
import { downloadSurveyResponses, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_SURVEY_RESPONSES } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, surveyResponses, uiSurveyResponse,
  } = getState();
  const { token } = authentication;
  const { meta } = surveyResponses;
  const { searchBarText } = uiSurveyResponse;
  const { filterString } = getState().uiFunctionalPage;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingSurveyResponses(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadSurveyResponses(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_SURVEY_RESPONSES,
      searchText,
      filterString,
      token,
    );

    dispatch(setSurveyResponses(list));
  } finally {
    dispatch(downloadingSurveyResponses(false));
  }
};
