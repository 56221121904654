import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_CALENDAR_CALENDAR_NAME,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DATE,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DAYS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY,
  SET_BY_INDIVIDUAL_DATE,
  SET_BY_WEEKDAY,
} from './constant';

const getCalendarSetDatesRequiredFields = (selectedSetBy, restRequiredFields) => {
  if (selectedSetBy?.value === SET_BY_WEEKDAY) {
    return [...restRequiredFields, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS,
      RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DAYS];
  }

  if (selectedSetBy?.value === SET_BY_INDIVIDUAL_DATE) {
    return [...restRequiredFields, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DATE];
  }

  return restRequiredFields;
};

export const rxformValidateCalendar = (values) => {
  const requiredFields = [
    RXFIELD_CALENDAR_CALENDAR_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCalendarSetDatesDialog = (values, props) => {
  const mainFormRequiredFields = [
    RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY,
    RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS,
  ];

  const requiredFields = getCalendarSetDatesRequiredFields(
    props?.selectedSetBy, mainFormRequiredFields,
  );

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
