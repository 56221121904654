import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT, RXFIELD_REPORTED_COMMENT_USER_ID,
  RXFIELD_REPORTED_COMMENT_DISCUSSION, RXFIELD_REPORTED_COMMENT_DISCUSSION_ID,
  RXFIELD_REPORTED_COMMENT_USERNAME, RXSTATE_REPORTED_COMMENTS,
  RXSTATE_REPORTED_COMMENT_PAGE, RXFIELD_REPORTED_COMMENT_REASON,
  RXFIELD_REPORTED_COMMENT_HANDLED, RXFIELD_REPORTED_COMMENT_DISCUSSION_TITLE,
  RXFIELD_REPORTED_COMMENT_COMMENT_ID, RXFIELD_REPORTED_COMMENT_COMMENT,
  RXFORM_REPORTED_COMMENT,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_USER_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderUserID}
          placeholder={LocalizedString.reportedCommentPage.placeholderUserID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_USERNAME}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderFullName}
          placeholder={LocalizedString.reportedCommentPage.placeholderFullName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_REASON}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderReason}
          placeholder={LocalizedString.reportedCommentPage.placeholderReason}
          multiline
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_HANDLED}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderHandled}
          placeholder={LocalizedString.reportedCommentPage.placeholderHandled}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_DISCUSSION_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderDiscussionID}
          placeholder={LocalizedString.reportedCommentPage.placeholderDiscussionID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_DISCUSSION_TITLE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderDiscussionTitle}
          placeholder={LocalizedString.reportedCommentPage.placeholderDiscussionTitle}
          multiline
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_COMMENT_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderCommentID}
          placeholder={LocalizedString.reportedCommentPage.placeholderCommentID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REPORTED_COMMENT_COMMENT}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.reportedCommentPage.placeholderComment}
          placeholder={LocalizedString.reportedCommentPage.placeholderComment}
          multiline
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const ReportedCommentPage = ({
  discussions, users,
  downloading, loadingDiscussion, loadingUser,
  handleSubmit, onAppear, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onChangeDiscussionText, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_REPORTED_COMMENTS}
    uiPage={RXSTATE_REPORTED_COMMENT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.reportedCommentPage.placeholderFullName,
        field: RXFIELD_REPORTED_COMMENT_USERNAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reportedCommentPage.placeholderCommentID,
        field: RXFIELD_REPORTED_COMMENT_COMMENT_ID,
        type: FILTER_TYPE_TEXT,
      },

      {
        title: LocalizedString.reportedCommentPage.placeholderDiscussion,
        field: RXFIELD_REPORTED_COMMENT_DISCUSSION,
        type: FILTER_TYPE_DROPDOWN,
        data: discussions,
        loading: loadingDiscussion,
        onChangeFilterText: onChangeDiscussionText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.reportedCommentPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.reportedCommentPage.labelUser, field: 'user.fullName', sorting: !downloading },
      {
        title: LocalizedString.reportedCommentPage.labelReason,
        field: 'reason',
        sorting: !downloading,
        render: ({ reason }) => (reason.length > 255 ? `${reason.slice(0, 254)}...` : reason),
      },
      { title: LocalizedString.reportedCommentPage.labelDiscussionTitle, field: 'comment.discussion.name', sorting: !downloading },
      { title: LocalizedString.reportedCommentPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.reportedCommentPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_REPORTED_COMMENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ReportedCommentPage);

ReportedCommentPage.propTypes = {
  discussions: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingDiscussion: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeDiscussionText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
