import { downloadAuditTrails, transformSearchText } from '../../../helper';
import { downloadingAuditTrails, setAuditTrails } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, auditTrails, uiAuditTrail } = getState();
  const { token } = authentication;
  const { meta, data } = auditTrails;
  const { selectedPageSize, searchBarText, orderBy } = uiAuditTrail;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingAuditTrails(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAuditTrails(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setAuditTrails(list));
    }
  } finally {
    dispatch(downloadingAuditTrails(false));
  }
};
