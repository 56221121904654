import { downloadingTimeZones, setTimeZones } from '../simple-action';
import { downloadTimeZones, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { timeZoneSearchText } = getState().uiToken;

  try {
    dispatch(downloadingTimeZones(true));

    const list = await downloadTimeZones(
      transformSearchText(timeZoneSearchText),
      token,
    );

    if (list) {
      dispatch(setTimeZones(list));
    }
  } finally {
    dispatch(downloadingTimeZones(false));
  }
};
