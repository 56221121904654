import {
  addWorkingHour,
  clearWorkingHours,
  downloadingDeletingWorkingHour,
  setAlertErrorMessage,
  setFunctionalPageMode,
  setWorkingHourTappedId,
} from '../simple-action';
import { downloadWorkingHour, deleteWorkingHour } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadWorkingHoursAsync from './downloadWorkingHoursAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingWorkingHour(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWorkingHour;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteWorkingHour(tappedId, token);
      dispatch(setWorkingHourTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearWorkingHours());
      dispatch(downloadWorkingHoursAsync(1)).catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const workingHour = await downloadWorkingHour(tappedId, token);
      dispatch(addWorkingHour(workingHour));
    }
  } finally {
    dispatch(downloadingDeletingWorkingHour(false));
  }
};
