import {
  addCar, clearCatalog, downloadingDeletingCar, setAlertErrorMessage,
  setFunctionalPageMode, setCatalogTappedId,
} from '../simple-action';
import { downloadCar, deleteCar } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadCatalogAsync from './downloadCatalogAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCar(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCatalog;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteCar(tappedId, token);
      dispatch(setCatalogTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCatalog());
      dispatch(downloadCatalogAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const Car = await downloadCar(tappedId, token);
      dispatch(addCar(Car));
    }
  } finally {
    dispatch(downloadingDeletingCar(false));
  }
};
