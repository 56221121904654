import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  INITIAL_ORDER_BY_INFO_MAPPINGS, MENUID_BCARD_INFO_MAPPING, PAGE_MODE_TABLE,
  RXFIELD_INFO_MAPPING_MAPPING_OPERATOR, RXFIELD_INFO_MAPPING_TYPE, RXFORM_INFO_MAPPING,
} from '../../constant';
import { transformInitialValues, toNumber } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearInfoMappings, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, setAlertErrorMessage, setInfoMappingSelectedMappingOperator,
  setInfoMappingSearchText, setInfoMappingSelectedPageSize, setInfoMappingSelectedOrderBy,
  setInfoMappingTappedId, setInfoMappingSelectedType,
  addEditInfoMappingAsync, downloadDeleteInfoMappingAsync, downloadInfoMappingsAsync,
} from '../../redux/action';
import InfoMappingPage from './info-mapping.presentation';

const getInitialValues = (state) => {
  const {
    infoMappings, uiFunctionalPage, uiInfoMapping,
  } = state;
  const { data } = infoMappings;
  const { downloadingDeleting, tappedId } = uiInfoMapping;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found)
    : {
      order: 0,
      phrase: '',
      mappingOperator: '',
      newName: '',
      type: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiInfoMapping.addingEditing,
  downloading: state.uiInfoMapping.downloading,
  downloadingDeleting: state.uiInfoMapping.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_BCARD_INFO_MAPPING));
    dispatch(setInfoMappingSearchText(''));
    dispatch(clearInfoMappings());
    dispatch(setInfoMappingSelectedPageSize(20));
    dispatch(setInfoMappingSelectedOrderBy(INITIAL_ORDER_BY_INFO_MAPPINGS));
    dispatch(downloadInfoMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearInfoMappings());
    dispatch(downloadInfoMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_INFO_MAPPING));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_INFO_MAPPING));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadInfoMappingsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setInfoMappingSelectedPageSize(pageSize));
    dispatch(downloadInfoMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteInfoMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setInfoMappingTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setInfoMappingTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setInfoMappingTappedId(id));
    dispatch(downloadDeleteInfoMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onMappingOperatorOptionSelected: (option) => {
    if (option) {
      dispatch(setInfoMappingSelectedMappingOperator(option));
      dispatch(change(RXFORM_INFO_MAPPING, RXFIELD_INFO_MAPPING_MAPPING_OPERATOR, option));
    } else {
      dispatch(setInfoMappingSelectedMappingOperator(''));
      dispatch(change(RXFORM_INFO_MAPPING, RXFIELD_INFO_MAPPING_MAPPING_OPERATOR, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setInfoMappingSelectedPageSize(pageSize));
    dispatch(clearInfoMappings());
    dispatch(downloadInfoMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearInfoMappings());
    dispatch(setInfoMappingSelectedOrderBy(INITIAL_ORDER_BY_INFO_MAPPINGS));
    dispatch(downloadInfoMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ order, phrase, newName }) => {
    try {
      await dispatch(addEditInfoMappingAsync(toNumber(order), phrase, newName));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setInfoMappingSearchText(text));
      dispatch(clearInfoMappings());
      await dispatch(downloadInfoMappingsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setInfoMappingSelectedOrderBy(orderBy));
    dispatch(clearInfoMappings());
    dispatch(downloadInfoMappingsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ order, phrase, newName }) => {
    await dispatch(addEditInfoMappingAsync(toNumber(order), phrase, newName));
  },
  onTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setInfoMappingSelectedType(option));
      dispatch(change(RXFORM_INFO_MAPPING, RXFIELD_INFO_MAPPING_TYPE, option));
    } else {
      dispatch(setInfoMappingSelectedType(''));
      dispatch(change(RXFORM_INFO_MAPPING, RXFIELD_INFO_MAPPING_TYPE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setInfoMappingTappedId(id));
    dispatch(downloadDeleteInfoMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoMappingPage);
