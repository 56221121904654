import {
  downloadingAttendanceSummaryTotalClockOutToday, setAttendanceSummaryTotalClockOutToday,
} from '../simple-action';
import { downloadAttendanceSummaryTotalClockOutToday } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTotalClockOutToday(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryTotalClockOutToday(token);

    dispatch(setAttendanceSummaryTotalClockOutToday(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTotalClockOutToday(false));
  }
};
