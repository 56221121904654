import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME,
  PICKER_MODE_TIME,
  RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES,
  RXFIELD_WORKING_HOUR_CREATED_DATE,
  RXFIELD_WORKING_HOUR_DESCRIPTION,
  RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_WORKING_HOUR_END_AFTER_MIN,
  RXFIELD_WORKING_HOUR_END_BEFORE_MIN,
  RXFIELD_WORKING_HOUR_END_WORKING_HOUR,
  RXFIELD_WORKING_HOUR_NAME,
  RXFIELD_WORKING_HOUR_START_AFTER_MIN,
  RXFIELD_WORKING_HOUR_START_BEFORE_MIN,
  RXFIELD_WORKING_HOUR_START_WORKING_HOUR,
  RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE,
  RXFORM_WORKING_HOUR,
  RXSTATE_WORKING_HOURS,
  RXSTATE_WORKING_HOUR_PAGE,
  STATUS_DISABLED,
  STATUS_ENABLED,
  timezoneOffset,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { rxformValidateWorkingHour } from '../../validation';
import { FormInitialValueShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (
  addingEditing,
  downloadingDeleting,
  pageMode,
  onStartWorkingHourChanged,
  onEndWorkingHourChanged,
  defaultWorkingHourTimeZoneValue,
  onTimeZoneOptionSelected,
  onEarliestClockInChanged,
  onAutoClockOutChanged,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.workingHourPage.placeholderName}
            label={LocalizedString.workingHourPage.placeholderName}
            disabled={
              addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW
            }
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_START_WORKING_HOUR}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingHourPage.placeholderStartWorkingHour}
            disabled={
              addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW
            }
            onChangeDate={onStartWorkingHourChanged}
            pickerMode={PICKER_MODE_TIME}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.workingHourPage.placeholderDescription}
            label={LocalizedString.workingHourPage.placeholderDescription}
            disabled={
              addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW
            }
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_END_WORKING_HOUR}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingHourPage.placeholderEndWorkingHour}
            disabled={
              addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW
            }
            onChangeDate={onEndWorkingHourChanged}
            pickerMode={PICKER_MODE_TIME}
            required
          />
        </Grid>
      </Grid>
    </Grid>

    <Grid item sm md>
      <Field
        name={RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE}
        component={renderReduxFormSimpleDropdownField}
        label={LocalizedString.workingHourPage.placeholderWorkingHourTimeZone}
        placeholder={
          LocalizedString.workingHourPage.placeholderWorkingHourTimeZone
        }
        disabled={
          addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW
        }
        data={timezoneOffset}
        value={defaultWorkingHourTimeZoneValue}
        onOptionSelected={onTimeZoneOptionSelected}
        required
      />
    </Grid>

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_START_BEFORE_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={
              LocalizedString.workingHourPage.placeholderStartBeforeMin
            }
            label={LocalizedString.workingHourPage.placeholderStartBeforeMin}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_START_AFTER_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={
              LocalizedString.workingHourPage.placeholderStartAfterMin
            }
            label={LocalizedString.workingHourPage.placeholderStartAfterMin}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingHourPage.placeholderEarliestClockIn}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            onChangeDate={onEarliestClockInChanged}
            pickerMode={PICKER_MODE_TIME}
            disableFuture
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_END_BEFORE_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={
              LocalizedString.workingHourPage.placeholderEndBeforeMin
            }
            label={LocalizedString.workingHourPage.placeholderEndBeforeMin}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_END_AFTER_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.workingHourPage.placeholderEndAfterMin}
            label={LocalizedString.workingHourPage.placeholderEndAfterMin}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingHourPage.placeholderAutoClockOut}
            disabled={
              addingEditing
              || downloadingDeleting
              || pageMode === PAGE_MODE_VIEW
            }
            onChangeDate={onAutoClockOutChanged}
            pickerMode={PICKER_MODE_TIME}
            disableFuture
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const WorkingHourPage = ({
  addingEditing,
  downloading,
  downloadingDeleting,
  handleSubmit,
  onAppear,
  onCancelPressed,
  onChangePage,
  onChangePageSize,
  onConfirmPressed,
  onCreatePressed,
  onDeletePressed,
  onEditPressed,
  onRefresh,
  onSavePressed,
  onSearchBarTextChanged,
  onSortPressed,
  onSubmitPressed,
  onViewPressed,
  pageMode,
  enablingDisabling,
  currentWorkingHourStatus,
  tappedId,
  onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed,
  onResetAdvancedFilterPressed,
  onStartWorkingHourChanged,
  onEndWorkingHourChanged,
  onTimeZoneOptionSelected,
  onEarliestClockInChanged,
  onAutoClockOutChanged,
  initialValues,
  onEnableDisablePressed,
}) => (
  <FunctionalPage
    data={RXSTATE_WORKING_HOURS}
    uiPage={RXSTATE_WORKING_HOUR_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.workingHourPage.buttonCaptionEnable,
        disabled:
          currentWorkingHourStatus === STATUS_ENABLED || enablingDisabling,
        onPress: () => onEnableDisablePressed(
          tappedId,
          LocalizedString.workingHourPage.msgEnableConfirmation,
        ),
      },
      {
        caption: LocalizedString.workingHourPage.buttonCaptionDisable,
        disabled:
          currentWorkingHourStatus === STATUS_DISABLED || enablingDisabling,
        onPress: () => onEnableDisablePressed(
          tappedId,
          LocalizedString.workingHourPage.msgDisableConfirmation,
        ),
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.workingHourPage.labelNo,
        field: 'no',
        sorting: false,
        width: 40,
        customFilterAndSearch: (term, rowData) => rowData,
      },
      {
        title: LocalizedString.workingHourPage.labelName,
        field: 'name',
        sorting: !downloading,
      },
      {
        title: LocalizedString.workingHourPage.labelStartWorkingHour,
        field: 'startWorkingHour',
        sorting: !downloading,
      },
      {
        title: LocalizedString.workingHourPage.labelEndWorkingHour,
        field: 'endWorkingHour',
        sorting: !downloading,
      },
      {
        title: LocalizedString.workingHourPage.labelTimeZone,
        field: 'workingHourTimeZone',
        sorting: !downloading,
      },
      {
        title: LocalizedString.workingHourPage.labelCreatedDate,
        field: 'createdDate',
        sorting: false,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
          : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.workingHourPage.labelCreatedDate,
        field: RXFIELD_WORKING_HOUR_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={
      LocalizedString.workingHourPage.buttonCaptionCreateNewWorkingHour
    }
    deleteButtonCaption={
      LocalizedString.workingHourPage.buttonCaptionDeleteWorkingHour
    }
    editButtonCaption={
      LocalizedString.workingHourPage.buttonCaptionEditWorkingHour
    }
    title={LocalizedString.workingHourPage.title}
    createPermissionName="ATTENDANCEADDON_CREATE_WORKING_HOUR"
    deletePermissionName="ATTENDANCEADDON_DELETE_WORKING_HOUR"
    editPermissionName="ATTENDANCEADDON_EDIT_WORKING_HOUR"
    useFullWidth
  >
    {renderDialogContent(
      addingEditing,
      downloadingDeleting,
      pageMode,
      onStartWorkingHourChanged,
      onEndWorkingHourChanged,
      initialValues.workingHourTimeZone,
      onTimeZoneOptionSelected,
      onEarliestClockInChanged,
      onAutoClockOutChanged,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WORKING_HOUR,
  validate: rxformValidateWorkingHour,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WorkingHourPage);

WorkingHourPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,

  pageMode: PropTypes.string.isRequired,
  currentWorkingHourStatus: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,

  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onStartWorkingHourChanged: PropTypes.func.isRequired,
  onEndWorkingHourChanged: PropTypes.func.isRequired,
  onTimeZoneOptionSelected: PropTypes.func.isRequired,
  onEarliestClockInChanged: PropTypes.func.isRequired,
  onAutoClockOutChanged: PropTypes.func.isRequired,
};
