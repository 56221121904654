import {
  SET_ANNOTATION_DESIGNS, CLEAR_ANNOTATION_DESIGNS,
} from '../action';

const initialState = {
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANNOTATION_DESIGNS: {
      return {
        data: action.data,
      };
    }
    case CLEAR_ANNOTATION_DESIGNS:
      return initialState;
    default:
      return state;
  }
};
