import { downloadGameQRCategories, transformSearchText } from '../../../helper';
import { downloadingGameQRCategories, setGameQRCategories } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, gameQRCategories, uiGameQRCategory } = getState();
  const { token } = authentication;
  const { meta, data } = gameQRCategories;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGameQRCategory;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingGameQRCategories(true));
    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadGameQRCategories(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setGameQRCategories(list));
    }
  } finally {
    dispatch(downloadingGameQRCategories(false));
  }
};
