import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');


export const DOWNLOADING_EMAILS = 'DOWNLOADING_EMAILS';
export const downloadingEmails = makeActionCreator(DOWNLOADING_EMAILS, 'status');

export const SET_EMAILS = 'SET_EMAILS';
export const setEmails = makeActionCreator(SET_EMAILS, 'data');

export const ADD_EMAIL = 'ADD_EMAIL';
export const addEmail = makeActionCreator(ADD_EMAIL, 'data');

export const CLEAR_EMAILS = 'CLEAR_EMAILS';
export const clearEmails = makeActionCreator(CLEAR_EMAILS);

export const DOWNLOADING_DELETING_EMAIL = 'DOWNLOADING_DELETING_EMAIL';
export const downloadingDeletingEmail = makeActionCreator(DOWNLOADING_DELETING_EMAIL, 'status');

export const RESENDING_EMAIL = 'RESENDING_EMAIL';
export const resendingEmail = makeActionCreator(RESENDING_EMAIL, 'status');


export const SET_EMAIL_SELECTED_PAGE_SIZE = 'SET_EMAIL_SELECTED_PAGE_SIZE';
export const setEmailSelectedPageSize = makeActionCreator(SET_EMAIL_SELECTED_PAGE_SIZE, 'size');

export const SET_EMAIL_SELECTED_ORDER_BY = 'SET_EMAIL_SELECTED_ORDER_BY';
export const setEmailSelectedOrderBy = makeActionCreator(SET_EMAIL_SELECTED_ORDER_BY, 'order');

export const SET_EMAIL_TAPPED_ID = 'SET_EMAIL_TAPPED_ID';
export const setEmailTappedId = makeActionCreator(SET_EMAIL_TAPPED_ID, 'id');

export const SET_EMAIL_SEARCH_TEXT = 'SET_EMAIL_SEARCH_TEXT';
export const setEmailSearchText = makeActionCreator(SET_EMAIL_SEARCH_TEXT, 'text');


export const DOWNLOADING_SMSES = 'DOWNLOADING_SMSES';
export const downloadingSmses = makeActionCreator(DOWNLOADING_SMSES, 'status');

export const SET_SMSES = 'SET_SMSES';
export const setSmses = makeActionCreator(SET_SMSES, 'data');

export const ADD_SMS = 'ADD_SMS';
export const addSms = makeActionCreator(ADD_SMS, 'data');

export const CLEAR_SMSES = 'CLEAR_SMSES';
export const clearSmses = makeActionCreator(CLEAR_SMSES);

export const DOWNLOADING_DELETING_SMS = 'DOWNLOADING_DELETING_SMS';
export const downloadingDeletingSms = makeActionCreator(DOWNLOADING_DELETING_SMS, 'status');

export const RESENDING_SMS = 'RESENDING_SMS';
export const resendingSms = makeActionCreator(RESENDING_SMS, 'status');


export const SET_SMS_SELECTED_PAGE_SIZE = 'SET_SMS_SELECTED_PAGE_SIZE';
export const setSmsSelectedPageSize = makeActionCreator(SET_SMS_SELECTED_PAGE_SIZE, 'size');

export const SET_SMS_SELECTED_ORDER_BY = 'SET_SMS_SELECTED_ORDER_BY';
export const setSmsSelectedOrderBy = makeActionCreator(SET_SMS_SELECTED_ORDER_BY, 'order');

export const SET_SMS_TAPPED_ID = 'SET_SMS_TAPPED_ID';
export const setSmsTappedId = makeActionCreator(SET_SMS_TAPPED_ID, 'id');

export const SET_SMS_SEARCH_TEXT = 'SET_SMS_SEARCH_TEXT';
export const setSmsSearchText = makeActionCreator(SET_SMS_SEARCH_TEXT, 'text');


export const DOWNLOADING_WHATSAPPS = 'DOWNLOADING_WHATSAPPS';
export const downloadingWhatsapps = makeActionCreator(DOWNLOADING_WHATSAPPS, 'status');

export const SET_WHATSAPPS = 'SET_WHATSAPPS';
export const setWhatsapps = makeActionCreator(SET_WHATSAPPS, 'data');

export const ADD_WHATSAPP = 'ADD_WHATSAPP';
export const addWhatsapp = makeActionCreator(ADD_WHATSAPP, 'data');

export const CLEAR_WHATSAPPS = 'CLEAR_WHATSAPPS';
export const clearWhatsapps = makeActionCreator(CLEAR_WHATSAPPS);

export const DOWNLOADING_DELETING_WHATSAPP = 'DOWNLOADING_DELETING_WHATSAPP';
export const downloadingDeletingWhatsapp = makeActionCreator(DOWNLOADING_DELETING_WHATSAPP, 'status');

export const RESENDING_WHATSAPP = 'RESENDING_WHATSAPP';
export const resendingWhatsapp = makeActionCreator(RESENDING_WHATSAPP, 'status');


export const SET_WHATSAPP_SELECTED_PAGE_SIZE = 'SET_WHATSAPP_SELECTED_PAGE_SIZE';
export const setWhatsappSelectedPageSize = makeActionCreator(SET_WHATSAPP_SELECTED_PAGE_SIZE, 'size');

export const SET_WHATSAPP_SELECTED_ORDER_BY = 'SET_WHATSAPP_SELECTED_ORDER_BY';
export const setWhatsappSelectedOrderBy = makeActionCreator(SET_WHATSAPP_SELECTED_ORDER_BY, 'order');

export const SET_WHATSAPP_TAPPED_ID = 'SET_WHATSAPP_TAPPED_ID';
export const setWhatsappTappedId = makeActionCreator(SET_WHATSAPP_TAPPED_ID, 'id');

export const SET_WHATSAPP_SEARCH_TEXT = 'SET_WHATSAPP_SEARCH_TEXT';
export const setWhatsappSearchText = makeActionCreator(SET_WHATSAPP_SEARCH_TEXT, 'text');


export const DOWNLOADING_PUSH_NOTIFICATIONS = 'DOWNLOADING_PUSH_NOTIFICATIONS';
export const downloadingPushNotifications = makeActionCreator(DOWNLOADING_PUSH_NOTIFICATIONS, 'status');

export const SET_PUSH_NOTIFICATIONS = 'SET_PUSH_NOTIFICATIONS';
export const setPushNotifications = makeActionCreator(SET_PUSH_NOTIFICATIONS, 'data');

export const ADD_PUSH_NOTIFICATION = 'ADD_PUSH_NOTIFICATION';
export const addPushNotification = makeActionCreator(ADD_PUSH_NOTIFICATION, 'data');

export const CLEAR_PUSH_NOTIFICATIONS = 'CLEAR_PUSH_NOTIFICATIONS';
export const clearPushNotifications = makeActionCreator(CLEAR_PUSH_NOTIFICATIONS);

export const DOWNLOADING_DELETING_PUSH_NOTIFICATION = 'DOWNLOADING_DELETING_PUSH_NOTIFICATION';
export const downloadingDeletingPushNotification = makeActionCreator(DOWNLOADING_DELETING_PUSH_NOTIFICATION, 'status');

export const RESENDING_PUSH_NOTIFICATION = 'RESENDING_PUSH_NOTIFICATION';
export const resendingPushNotification = makeActionCreator(RESENDING_PUSH_NOTIFICATION, 'status');


export const SET_PUSH_NOTIFICATION_SELECTED_PAGE_SIZE = 'SET_PUSH_NOTIFICATION_SELECTED_PAGE_SIZE';
export const setPushNotificationSelectedPageSize = makeActionCreator(SET_PUSH_NOTIFICATION_SELECTED_PAGE_SIZE, 'size');

export const SET_PUSH_NOTIFICATION_SELECTED_ORDER_BY = 'SET_PUSH_NOTIFICATION_SELECTED_ORDER_BY';
export const setPushNotificationSelectedOrderBy = makeActionCreator(SET_PUSH_NOTIFICATION_SELECTED_ORDER_BY, 'order');

export const SET_PUSH_NOTIFICATION_TAPPED_ID = 'SET_PUSH_NOTIFICATION_TAPPED_ID';
export const setPushNotificationTappedId = makeActionCreator(SET_PUSH_NOTIFICATION_TAPPED_ID, 'id');

export const SET_PUSH_NOTIFICATION_SEARCH_TEXT = 'SET_PUSH_NOTIFICATION_SEARCH_TEXT';
export const setPushNotificationSearchText = makeActionCreator(SET_PUSH_NOTIFICATION_SEARCH_TEXT, 'text');


export const DOWNLOADING_NOTIFICATION_TASKS = 'DOWNLOADING_NOTIFICATION_TASKS';
export const downloadingNotificationTasks = makeActionCreator(DOWNLOADING_NOTIFICATION_TASKS, 'status');

export const SET_NOTIFICATION_TASKS = 'SET_NOTIFICATION_TASKS';
export const setNotificationTasks = makeActionCreator(SET_NOTIFICATION_TASKS, 'data');

export const ADD_NOTIFICATION_TASK = 'ADD_NOTIFICATION_TASK';
export const addNotificationTask = makeActionCreator(ADD_NOTIFICATION_TASK, 'data');

export const CLEAR_NOTIFICATION_TASKS = 'CLEAR_NOTIFICATION_TASKS';
export const clearNotificationTasks = makeActionCreator(CLEAR_NOTIFICATION_TASKS);

export const DOWNLOADING_DELETING_NOTIFICATION_TASK = 'DOWNLOADING_DELETING_NOTIFICATION_TASK';
export const downloadingDeletingNotificationTask = makeActionCreator(DOWNLOADING_DELETING_NOTIFICATION_TASK, 'status');

export const CANCELING_NOTIFICATION_TASK = 'CANCELING_NOTIFICATION_TASK';
export const cancelingNotificationTask = makeActionCreator(CANCELING_NOTIFICATION_TASK, 'status');

export const SENDING_NOTIFICATION_TASK_NOTIFICATION = 'SENDING_NOTIFICATION_TASK_NOTIFICATION';
export const sendingNotificationTaskNotification = makeActionCreator(SENDING_NOTIFICATION_TASK_NOTIFICATION, 'status');

export const DOWNLOADING_NOTIFICATION_TASK_TOTAL_PERSON_COUNT = 'DOWNLOADING_NOTIFICATION_TASK_TOTAL_PERSON_COUNT';
export const downloadingNotificationTaskTotalPersonCount = makeActionCreator(DOWNLOADING_NOTIFICATION_TASK_TOTAL_PERSON_COUNT, 'status');


export const SET_NOTIFICATION_TASK_SELECTED_PAGE_SIZE = 'SET_NOTIFICATION_TASK_SELECTED_PAGE_SIZE';
export const setNotificationTaskSelectedPageSize = makeActionCreator(SET_NOTIFICATION_TASK_SELECTED_PAGE_SIZE, 'size');

export const SET_NOTIFICATION_TASK_SELECTED_ORDER_BY = 'SET_NOTIFICATION_TASK_SELECTED_ORDER_BY';
export const setNotificationTaskSelectedOrderBy = makeActionCreator(SET_NOTIFICATION_TASK_SELECTED_ORDER_BY, 'order');

export const SET_NOTIFICATION_TASK_TAPPED_ID = 'SET_NOTIFICATION_TASK_TAPPED_ID';
export const setNotificationTaskTappedId = makeActionCreator(SET_NOTIFICATION_TASK_TAPPED_ID, 'id');

export const SET_NOTIFICATION_TASK_SEARCH_TEXT = 'SET_NOTIFICATION_TASK_SEARCH_TEXT';
export const setNotificationTaskSearchText = makeActionCreator(SET_NOTIFICATION_TASK_SEARCH_TEXT, 'text');

export const SET_NOTIFICATION_TASK_DUPLICATE_DIALOG_VISIBILITY = 'SET_NOTIFICATION_TASK_DUPLICATE_DIALOG_VISIBILITY';
export const setNotificationTaskDuplicateDialogVisibility = makeActionCreator(SET_NOTIFICATION_TASK_DUPLICATE_DIALOG_VISIBILITY, 'status');

export const SET_NOTIFICATION_TASK_TOTAL_PERSON_COUNT = 'SET_NOTIFICATION_TASK_TOTAL_PERSON_COUNT';
export const setNotificationTaskTotalPersonCount = makeActionCreator(SET_NOTIFICATION_TASK_TOTAL_PERSON_COUNT, 'data');


export const DOWNLOADING_INBOXES = 'DOWNLOADING_INBOXES';
export const downloadingInboxes = makeActionCreator(DOWNLOADING_INBOXES, 'status');

export const DOWNLOADING_DELETING_INBOX = 'DOWNLOADING_DELETING_INBOX';
export const downloadingDeletingInbox = makeActionCreator(DOWNLOADING_DELETING_INBOX, 'status');

export const SET_INBOXES = 'SET_INBOXES';
export const setInboxes = makeActionCreator(SET_INBOXES, 'data');

export const ADD_INBOX = 'ADD_INBOX';
export const addInbox = makeActionCreator(ADD_INBOX, 'data');

export const CLEAR_INBOXES = 'CLEAR_INBOXES';
export const clearInboxes = makeActionCreator(CLEAR_INBOXES);


export const SET_INBOX_SELECTED_PAGE_SIZE = 'SET_INBOX_SELECTED_PAGE_SIZE';
export const setInboxSelectedPageSize = makeActionCreator(SET_INBOX_SELECTED_PAGE_SIZE, 'size');

export const SET_INBOX_SELECTED_ORDER_BY = 'SET_INBOX_SELECTED_ORDER_BY';
export const setInboxSelectedOrderBy = makeActionCreator(SET_INBOX_SELECTED_ORDER_BY, 'order');

export const SET_INBOX_TAPPED_ID = 'SET_INBOX_TAPPED_ID';
export const setInboxTappedId = makeActionCreator(SET_INBOX_TAPPED_ID, 'id');

export const SET_INBOX_SEARCH_TEXT = 'SET_INBOX_SEARCH_TEXT';
export const setInboxSearchText = makeActionCreator(SET_INBOX_SEARCH_TEXT, 'text');

export const SET_INBOX_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_INBOX_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setInboxAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_INBOX_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
