import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { FunctionalPage, SectionTitle } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXSTATE_QUIZ_RESPONSES, RXSTATE_QUIZ_RESPONSE_PAGE,
  RXFORM_QUIZ_RESPONSE, FILTER_TYPE_SWITCH, DATE_TIME_FORMAT,
  RXFIELD_QUIZ_RESPONSE_STARTED_AT, RXFIELD_QUIZ_RESPONSE_FINISHED_AT,
  RXFIELD_QUIZ_RESPONSE_QUIZ, RXFIELD_QUIZ_RESPONSE_USER_NAME,
  RXFIELD_QUIZ_RESPONSE_IS_FINSIHED, RXFIELD_QUIZ_RESPONSE_QUIZ_ID,
  RXFIELD_QUIZ_RESPONSE_QUIZ_TITLE, RXFIELD_QUIZ_RESPONSE_SCORE,
  RXFIELD_QUIZ_RESPONSE_DURATION, RXFIELD_QUIZ_RESPONSE_USER_ID,
  MINUTE_SECOND_FORMAT,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (initialValues) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_QUIZ_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderQuizId}
            placeholder={LocalizedString.quizResponseScreen.placeholderQuizId}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_QUIZ_TITLE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderQuizTitle}
            placeholder={LocalizedString.quizResponseScreen.placeholderQuizTitle}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_SCORE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderScore}
            placeholder={LocalizedString.quizResponseScreen.placeholderScore}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_DURATION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderDuration}
            placeholder={LocalizedString.quizResponseScreen.placeholderDuration}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_USER_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderUserId}
            placeholder={LocalizedString.quizResponseScreen.placeholderUserId}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderUsername}
            placeholder={LocalizedString.quizResponseScreen.placeholderUsername}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_STARTED_AT}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderStartAt}
            placeholder={LocalizedString.quizResponseScreen.placeholderStartAt}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_QUIZ_RESPONSE_FINISHED_AT}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.quizResponseScreen.placeholderFinishAt}
            placeholder={LocalizedString.quizResponseScreen.placeholderFinishAt}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
    {!isEmpty(initialValues.answers) ? (
      <div>
        <SectionTitle title={LocalizedString.quizResponseScreen.labelQuizResponse} />
        <Grid container spacing={3}>
          <Grid item sm md>
            {initialValues.answers.map((item) => (
              <Grid key={item.id}>
                <Field
                  name={item.question.id}
                  component={renderReduxFormOutlinedTextField}
                  label={item.question.label}
                  placeholder={item.question.label}
                  defaultValue={item.value}
                  disabled
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    ) : null}
  </Grid>
);

const renderDuration = (startAt, finishAt) => {
  if (startAt && finishAt) {
    const start = moment(startAt);
    const finish = moment(finishAt);
    const diff = toMoment(finish.diff(start)).format(MINUTE_SECOND_FORMAT);
    return diff.replace(':', ` ${LocalizedString.quizResponseScreen.labelMinutes} `)
      .concat(` ${LocalizedString.quizResponseScreen.labelSeconds}`);
  }
  return '';
};

const QuizResponsePage = ({
  users, downloading, loadingUser, onAppear, initialValues,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  onChangeQuizText, quizes, loadingQuizes, history,
}) => {
  // eslint-disable-next-line
  useEffect(() => onAppear(history?.location?.state || ''), []);

  return (
    <FunctionalPage
      data={RXSTATE_QUIZ_RESPONSES}
      uiPage={RXSTATE_QUIZ_RESPONSE_PAGE}
      filterColumns={[
        {
          title: LocalizedString.quizResponseScreen.placeholderStartAt,
          field: RXFIELD_QUIZ_RESPONSE_STARTED_AT,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.quizResponseScreen.placeholderFinishAt,
          field: RXFIELD_QUIZ_RESPONSE_FINISHED_AT,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.quizResponseScreen.placeholderQuiz,
          field: RXFIELD_QUIZ_RESPONSE_QUIZ,
          type: FILTER_TYPE_DROPDOWN,
          data: quizes,
          loading: loadingQuizes,
          onChangeFilterText: onChangeQuizText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.quizResponseScreen.placeholderUser,
          field: RXFIELD_QUIZ_RESPONSE_USER_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUser,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.quizResponseScreen.placeholderIsFinished,
          field: RXFIELD_QUIZ_RESPONSE_IS_FINSIHED,
          type: FILTER_TYPE_SWITCH,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.quizResponseScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.quizResponseScreen.labelQuiz, field: 'quiz.title', sorting: !downloading },
        { title: LocalizedString.quizResponseScreen.labelUser, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.quizResponseScreen.labelScore, field: 'score', sorting: !downloading },
        {
          title: LocalizedString.quizResponseScreen.labelStartAt,
          field: 'startedAt',
          sorting: !downloading,
          render: ({ startedAt }) => (startedAt ? toMoment(startedAt).format(DATE_TIME_FORMAT) : ''),
        },
        {
          title: LocalizedString.quizResponseScreen.labelFinishAt,
          field: 'finishedAt',
          sorting: !downloading,
          render: ({ finishedAt }) => (finishedAt ? toMoment(finishedAt).format(DATE_TIME_FORMAT) : ''),
        },
        {
          title: LocalizedString.quizResponseScreen.labelDuration,
          field: '',
          sorting: !downloading,
          render: ({ startedAt, finishedAt }) => renderDuration(startedAt, finishedAt),
        },
      ]}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.quizResponseScreen.title}
      useFullWidth
      disableCreate
      disableDelete
      disableEdit
    >
      {renderDialogContent(initialValues)}
    </FunctionalPage>
  );
};

export default withRouter(reduxForm({
  form: RXFORM_QUIZ_RESPONSE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuizResponsePage));

QuizResponsePage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  quizes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingQuizes: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onChangeQuizText: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
