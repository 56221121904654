
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    voucherMenu: {
      title: 'Voucher',
    },
    voucherCategoryScreen: {
      title: 'Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      buttonCaptionCreate: 'Create New Voucher Category',
      buttonCaptionEdit: 'Edit Voucher Category',
      buttonCaptionDelete: 'Delete Voucher Category',

      placeholderImage: 'Image',
    },
    voucherPage: {
      title: 'Voucher',

      labelNo: 'No',
      labelTitle: 'Title',
      labelPoint: 'Point',
      labelVisibility: 'Visibility',
      labelMerchantName: 'Merchant Name',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelApplicableMode: 'Applicable Mode',
      labelAllBranches: 'All Branches',
      labelSpecificBranches: 'Specific Branch(es)',
      labelBranch: 'Branch',
      labelCopied: 'Copied!',

      buttonCaptionCreateNewVoucher: 'Create New Voucher',
      buttonCaptionEditVoucher: 'Edit Voucher',

      placeholderTitle: 'Title',
      placeholderCode: 'Code',
      placeholderType: 'Type',
      placeholderPoint: 'Point',
      placeholderMaxPurchasePerUser: 'Max. Purchase per User',
      placeholderMinAchievementPoinToPurchase: 'Min. Point Achievement to Purchase',
      placeholderMaxAchievementPoinToPurchase: 'Max. Point Achievement to Purchase',
      placeholderActionType: 'Action Type',
      placeholderVisibility: 'Visibility',
      placeholderMerchant: 'Merchant',
      placeholderApplicableAreaMode: 'Applicable Area Mode',
      placeholderApplicableBranches: 'Applicable Branches',
      placeholderCategory: 'Category',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderImage: 'Image',
      placeholderDescription: 'Description',
      placeholderDescriptionFormat: 'Description Format',
      placeholderTermsAndConditions: 'Terms And Conditions',
      placeholderTermsAndConditionsFormat: 'Terms And Conditions Format',
      placeholderStatus: 'Status',
      placeholderBranch: 'Branch',
      placeholderDeepLink: 'Deep Link',
      placeholderPromoted: 'Promoted',
      placeholderOrder: 'Order',
      placeholderTags: 'Tags',
    },
    tokenRequestPage: {
      title: 'Token Request',

      labelNo: 'No',
      labelTitle: 'Title',
      labelStartTime: 'Start Time',
      labelProgress: 'Progress',
      labelNotes: 'Notes',
      labelStatus: 'Status',
      labelTaskStatus: 'Task Status',
      labelEndTime: 'End Time',

      placeholderVoucher: 'Voucher',
      placeholderQuantity: 'Quantity',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderTaskStatus: 'Task Status',
      placeholderProgress: 'Progress',
      placeholderProgressNotes: 'Progress Notes',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderCreatedDate: 'Created Date',

      buttonCaptionCreate: 'Create New Token Request',
      buttonCaptionCancel: 'Cancel Token Request',
    },
    voucherTokenPage: {
      title: 'Token',

      labelNo: 'No',
      labelCode: 'Code',
      labelVoucherName: 'Voucher Name',
      labelMerchantName: 'Merchant Name',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelPurchased: 'Purchased',
      labelRevoked: 'Revoked',
      labelClaimed: 'Claimed',

      placeholderCode: 'Code',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderPurchased: 'Purchased',
      placeholderPurchasedOn: 'Purchased On',
      placeholderPurchasedBy: 'Purchased By',
      placeholderPurchasedById: 'Purchased By ID',
      placeholderRevoked: 'Revoked',
      placeholderRevokedOn: 'Revoked On',
      placeholderRevokedBy: 'Revoked By',
      placeholderRevokedById: 'Revoked by ID',
      placeholderRevocationNotes: 'Revocation Notes',
      placeholderVoucherName: 'Voucher Name',
      placeholderMerchantName: 'Merchant Name',
      placeholderClaimed: 'Claimed',
      placeholderClaimedOn: 'Claimed On',
      placeholderClaimedBy: 'Claimed By',
      placeholderClaimedById: 'Claimed By ID',
      placeholderClaimNotes: 'Claim Notes',
      placeholderCreatedDate: 'Created Date',
      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherImage: 'Voucher Image',
    },
    tokenImportPage: {
      title: 'Token Import',

      labelNo: 'No',
      labelCode: 'Code',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelVoucher: 'Voucher',
      labelMerchant: 'Merchant',
      labelStatus: 'Status',
      labelCsvFile: 'CSV File',

      buttonCaptionEditTokenImport: 'Edit Token Import',
      buttonCaptionDeleteTokenImport: 'Delete Token Import',
      buttonCaptionImportToken: 'Import Token',
      buttonCaptionRetry: 'Retry',
      buttonCaptionImport: 'Import',

      placeholderCode: 'Code',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderVoucherName: 'Voucher Name',
      placeholderMerchantName: 'Merchant Name',
      placeholderImportStatus: 'Import Status',
      placeholderStatusNotes: 'Status Notes',
      placeholderCreatedDate: 'Created Date',
      placeholderVoucherId: 'Voucher ID',

      msgRetryImportConfirmation: 'Are you sure want to retry import this item?',
    },
    voucherPurchaseRequestPage: {
      title: 'Purchase Request',

      labelNo: 'No',
      labelUser: 'User',
      labelVoucherName: 'Voucher Name',
      labelStatus: 'Status',
      labelCreatedDate: 'Created Date',

      placeholderUser: 'User',
      placeholderVoucherTokenId: 'Voucher Token ID',
      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherName: 'Voucher Name',
      placeholderPaymentId: 'Payment ID',
      placeholderVoucherImage: 'Voucher Image',
      placeholderRequestStatus: 'Request Status',
      placeholderRequestStatusNotes: 'Request Status Notes',
      placeholderCreatedDate: 'Created Date',
    },
    promotedMerchantPage: {
      title: 'Promoted Merchant',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelPromoted: 'Promoted',
      labelOrder: 'Order',
      labelStatus: 'Status',

      placeholderName: 'Name',
      placeholderPromoted: 'Promoted',
      placeholderAddress: 'Address',
      placeholderOrder: 'Order',
      placeholderCity: 'City',
      placeholderCompanyLogo: 'Company Logo',
      placeholderWebsite: 'Website',
      placeholderCompanyBanner: 'Company Banner',
      placeholderPlayStoreUrl: 'PlayStore URL',
      placeholderAppStoreUrl: 'AppStore URL',

      buttonCaptionEditPromotedMerchant: 'Edit Promoted Merchant',
    },
  },
  id: {
    voucherMenu: {
      title: 'Voucher',
    },
    voucherCategoryScreen: {
      title: 'Kategori',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      buttonCaptionCreate: 'Buat Kategori Voucher',
      buttonCaptionEdit: 'Ubah Kategori Voucher',
      buttonCaptionDelete: 'Hapus Kategori Voucher',

      placeholderImage: 'Gambar',
    },
    voucherPage: {
      title: 'Voucher',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelPoint: 'Poin',
      labelVisibility: 'Terlihat',
      labelMerchantName: 'Nama Penjual',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelApplicableMode: 'Mode Penerapan',
      labelAllBranches: 'Semua Cabang',
      labelSpecificBranches: 'Cabang Tertentu',
      labelBranch: 'Cabang',
      labelCopied: 'Disalin!',

      buttonCaptionCreateNewVoucher: 'Buat Voucher Baru',
      buttonCaptionEditVoucher: 'Ubah Voucher',

      placeholderTitle: 'Judul',
      placeholderCode: 'Kode',
      placeholderType: 'Tipe',
      placeholderPoint: 'Poin',
      placeholderMaxPurchasePerUser: 'Maks. Pembelian per Pengguna',
      placeholderMinAchievementPoinToPurchase: 'Min. Pencapaian Poin untuk Pembelian',
      placeholderMaxAchievementPoinToPurchase: 'Maks. Pencapaian Poin untuk Pembelian',
      placeholderActionType: 'Tipe Aksi',
      placeholderVisibility: 'Dapat Dilihat',
      placeholderMerchant: 'Penjual',
      placeholderApplicableAreaMode: 'Mode Area Penerapan',
      placeholderApplicableBranches: 'Cabang Penerapan',
      placeholderCategory: 'Kategori',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderImage: 'Gambar',
      placeholderDescription: 'Deskripsi',
      placeholderDescriptionFormat: 'Format Deskripsi',
      placeholderTermsAndConditions: 'Syarat dan Ketentuan',
      placeholderTermsAndConditionsFormat: 'Format Syarat dan Ketentuan',
      placeholderStatus: 'Status',
      placeholderBranch: 'Cabang',
      placeholderDeepLink: 'Tautan Dalam',
      placeholderPromoted: 'Dipromosikan',
      placeholderOrder: 'Urutan',
      placeholderTags: 'Penanda',
    },
    tokenRequestPage: {
      title: 'Permintaan Token',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelStartTime: 'Waktu Mulai',
      labelProgress: 'Kemajuan',
      labelNotes: 'Catatan',
      labelStatus: 'Status',
      labelTaskStatus: 'Status Tugas',
      labelEndTime: 'Waktu Selesai',

      placeholderVoucher: 'Voucher',
      placeholderQuantity: 'Jumlah',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderTaskStatus: 'Status Tugas',
      placeholderProgress: 'Kemajuan',
      placeholderProgressNotes: 'Catatan Kemajuan',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderCreatedDate: 'Tanggal Pembuatan',

      buttonCaptionCreate: 'Buat Permintaan Token Baru',
      buttonCaptionCancel: 'Batalkan Permintaan Token',
    },
    voucherTokenPage: {
      title: 'Token',

      labelNo: 'No',
      labelCode: 'Kode',
      labelVoucherName: 'Nama Voucher',
      labelMerchantName: 'Nama Penjual',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelPurchased: 'Dibeli',
      labelRevoked: 'Dicabut',
      labelClaimed: 'Diklaim',

      placeholderCode: 'Kode',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderPurchased: 'Dibeli',
      placeholderPurchasedOn: 'Dibeli Pada',
      placeholderPurchasedBy: 'Dibeli Oleh',
      placeholderPurchasedById: 'Dibeli Oleh ID',
      placeholderRevoked: 'Dicabut',
      placeholderRevokedOn: 'Dicabut Pada',
      placeholderRevokedBy: 'Dicabut Oleh',
      placeholderRevokedById: 'Dicabut Oleh ID',
      placeholderRevocationNotes: 'Catatan Pencabutan',
      placeholderVoucherName: 'Nama Voucher',
      placeholderMerchantName: 'Nama Penjual',
      placeholderClaimed: 'Diklaim',
      placeholderClaimedOn: 'Diklaim Pada',
      placeholderClaimedBy: 'Diklaim Oleh',
      placeholderClaimedById: 'Diklaim oleh ID',
      placeholderClaimNotes: 'Catatan Klaim',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherImage: 'Gambar Voucher',
    },
    tokenImportPage: {
      title: 'Impor Token',

      labelNo: 'No',
      labelCode: 'Kode',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelVoucher: 'Voucher',
      labelMerchant: 'Penjual',
      labelStatus: 'Status',
      labelCsvFile: 'File CSV',

      buttonCaptionEditTokenImport: 'Ubah Impor Token',
      buttonCaptionDeleteTokenImport: 'Hapus Impor Token',
      buttonCaptionImportToken: 'Impor Token',
      buttonCaptionRetry: 'Coba Ulang',
      buttonCaptionImport: 'Impor',

      placeholderCode: 'Kode',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderVoucherName: 'Nama Voucher',
      placeholderMerchantName: 'Nama Penjual',
      placeholderImportStatus: 'Status Impor',
      placeholderStatusNotes: 'Catatan Status',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderVoucherId: 'ID Voucher',

      msgRetryImportConfirmation: 'Apakah Anda yakin ingin mencoba impor ulang pilihan ini?',
    },
    voucherPurchaseRequestPage: {
      title: 'Permintaan Pembelian',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelVoucherName: 'Nama Voucher',
      labelStatus: 'Status',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUser: 'Pengguna',
      placeholderVoucherTokenId: 'ID Token Voucher',
      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherName: 'Nama Voucher',
      placeholderPaymentId: 'ID Pembayaran',
      placeholderVoucherImage: 'Gambar Voucher',
      placeholderRequestStatus: 'Status Permintaan',
      placeholderRequestStatusNotes: 'Catatan Status Permintaan',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    promotedMerchantPage: {
      title: 'Penjual yang Dipromosikan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelPromoted: 'Dipromosikan',
      labelOrder: 'Urutan',
      labelStatus: 'Status',

      placeholderName: 'Nama',
      placeholderPromoted: 'Dipromosikan',
      placeholderAddress: 'Alamat',
      placeholderOrder: 'Urutan',
      placeholderCity: 'Kota',
      placeholderCompanyLogo: 'Logo Perusahaan',
      placeholderWebsite: 'Situs Web',
      placeholderCompanyBanner: 'Spanduk Perusahaan',
      placeholderPlayStoreUrl: 'URL PlayStore',
      placeholderAppStoreUrl: 'URL AppStore',

      buttonCaptionEditPromotedMerchant: 'Ubah Penjual yang Dipromosikan',
    },
  },
});

export default LocalizedString;
