import {
  enablingDisablingProvince, setAlertErrorMessage, setProvinceSelectedOrderBy,
  setProvinceSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_PROVINCES, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableProvince } from '../../../helper';
import downloadProvincesAsync from './downloadProvincesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingProvince(true));

    const { token } = getState().authentication;
    const { data } = getState().provinces;
    const { tappedId } = getState().uiProvince;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableProvince(tappedId, status, token);

    dispatch(setProvinceSelectedPageSize(20));
    dispatch(setProvinceSelectedOrderBy(INITIAL_ORDER_BY_PROVINCES));
    dispatch(downloadProvincesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(enablingDisablingProvince(false));
  }
};
