import {
  SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS,
  CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS,
} from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS: {
      return action.data;
    }
    case CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS:
      return state;
    default:
      return state;
  }
};
