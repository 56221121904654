import { reset } from 'redux-form';
import {
  addingEditingWorkingHour,
  clearWorkingHours,
  setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import {
  addWorkingHour, editWorkingHour, convertClockInOutMinutesToHours,
} from '../../../helper';
import {
  PAGE_MODE_TABLE, RXFORM_WORKING_HOUR,
  TIME_MODE_CLOCK_IN, TIME_MODE_CLOCK_OUT,
} from '../../../constant';
import downloadWorkingHoursAsync from './downloadWorkingHoursAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingWorkingHour(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWorkingHour;
    const {
      name,
      description,
      startWorkingHour,
      endWorkingHour,
      startWorkingHourMarginBeforeMinutes,
      startWorkingHourMarginAfterMinutes,
      endWorkingHourMarginBeforeMinutes,
      endWorkingHourMarginAfterMinutes,
      workingHourTimeZone,
      status,
      earliestClockInDriftTimeMinutes,
      autoClockOutAfterMinutes,
    } = values;

    const body = {
      name,
      description,
      startWorkingHour,
      endWorkingHour,
      startWorkingHourMarginBeforeMinutes,
      startWorkingHourMarginAfterMinutes,
      endWorkingHourMarginBeforeMinutes,
      endWorkingHourMarginAfterMinutes,
      workingHourTimeZone,
      status,
      earliestClockInDriftTimeMinutes: convertClockInOutMinutesToHours(
        earliestClockInDriftTimeMinutes, startWorkingHour,
        endWorkingHour, TIME_MODE_CLOCK_IN,
      ),
      autoClockOutAfterMinutes: convertClockInOutMinutesToHours(
        autoClockOutAfterMinutes, startWorkingHour,
        endWorkingHour, TIME_MODE_CLOCK_OUT,
      ),
    };

    if (tappedId) {
      await editWorkingHour({ ...body, id: tappedId }, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addWorkingHour(body, token);
    }

    dispatch(reset(RXFORM_WORKING_HOUR));
    dispatch(clearWorkingHours());
    dispatch(downloadWorkingHoursAsync(1)).catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingWorkingHour(false));
  }
};
