import { INITIAL_ORDER_BY_CALENDAR } from '../../constant';
import {
  DOWNLOADING_CALENDARS, ADDING_EDITING_CALENDAR, DOWNLOADING_DELETING_CALENDAR,
  SET_CALENDAR_SELECTED_PAGE_SIZE, SET_CALENDAR_TAPPED_ID, SET_CALENDAR_SEARCH_TEXT,
  SET_CALENDAR_SELECTED_ORDER_BY, SET_CALENDAR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  ADDING_CALENDAR_PARENT_CALENDAR, SET_CALENDAR_SELECTED_PARENT_CALENDARS,
  SET_CALENDAR_TABLE_DATA_PARENT_CALENDARS, SET_CALENDAR_SELECTED_YEAR,
  SET_SET_DATES_DIALOG_VISIBILITY, SET_CALENDAR_SELECTED_DATE, SET_CALENDAR_SELECTED_DAYS,
  SET_CALENDAR_SELECTED_MONTH, SET_CALENDAR_SELECTED_SET_AS, SET_CALENDAR_SELECTED_SET_BY,
  SET_CALENDAR_SET_DATES_DESCRIPTION, SET_CALENDAR_DAYS, SET_CALENDAR_DATE_INITIAL_FOCUSED_DATE,
  SET_CALENDAR_ACTIVE_TAB, DOWNLOADING_CALENDAR_DATE_RANGE_STATUS, ADDING_CALENDAR_DAYS,
} from '../action';
import LocalizedString from '../../localization';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  addingParentCalendar: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_CALENDAR,
  tableDataParentCalendars: [],
  selectedParentCalendars: '',
  selectedYear: null,
  setDatesDialogVisibility: false,
  selectedSetBy: '',
  selectedSetAs: '',
  selectedMonth: '',
  selectedDays: '',
  selectedDate: '',
  setDatesDescription: '',
  dateInitialFocusedDate: '',
  days: [],
  activeTab: LocalizedString.calendarPage.labelIndividualView,
  downloadingDateRangeStatus: false,
  addingCalendarDays: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CALENDARS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_CALENDAR:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_CALENDAR:
      return { ...state, downloadingDeleting: action.status };
    case SET_CALENDAR_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CALENDAR_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CALENDAR_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CALENDAR_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CALENDAR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_CALENDAR_TABLE_DATA_PARENT_CALENDARS:
      return { ...state, tableDataParentCalendars: action.data };
    case SET_CALENDAR_SELECTED_PARENT_CALENDARS:
      return { ...state, selectedParentCalendars: action.option };
    case ADDING_CALENDAR_PARENT_CALENDAR:
      return { ...state, addingParentCalendar: action.status };
    case SET_CALENDAR_SELECTED_YEAR:
      return { ...state, selectedYear: action.year };
    case SET_SET_DATES_DIALOG_VISIBILITY:
      return { ...state, setDatesDialogVisibility: action.status };
    case SET_CALENDAR_SELECTED_SET_BY:
      return { ...state, selectedSetBy: action.setBy };
    case SET_CALENDAR_SELECTED_SET_AS:
      return { ...state, selectedSetAs: action.setAs };
    case SET_CALENDAR_SELECTED_MONTH:
      return { ...state, selectedMonth: action.month };
    case SET_CALENDAR_SELECTED_DAYS:
      return { ...state, selectedDays: action.days };
    case SET_CALENDAR_SELECTED_DATE:
      return { ...state, selectedDate: action.date };
    case SET_CALENDAR_SET_DATES_DESCRIPTION:
      return { ...state, setDatesDescription: action.text };
    case SET_CALENDAR_DAYS:
      return { ...state, days: action.days };
    case SET_CALENDAR_DATE_INITIAL_FOCUSED_DATE:
      return { ...state, dateInitialFocusedDate: action.date };
    case SET_CALENDAR_ACTIVE_TAB:
      return { ...state, activeTab: action.text };
    case DOWNLOADING_CALENDAR_DATE_RANGE_STATUS:
      return { ...state, downloadingDateRangeStatus: action.status };
    case ADDING_CALENDAR_DAYS:
      return { ...state, addingCalendarDays: action.status };
    default:
      return state;
  }
};
