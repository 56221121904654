import { connect } from 'react-redux';
import { sendGetRequest, downloadProfilePictures } from '../../helper';
import { setAlertErrorMessage } from '../../redux/action';
import Comment from './comment.presentation';

const URL_COMMENTS = '/discus/api/v1/DiscussionComment/{discusId}?pageNumber={pageNumber}';
const URL_COMMENTS_REPLIES = '/discus/api/v1/Reply/{commentId}?pageNumber={pageNumber}';
const URL_REACTION = '/reaction/api/v1/Reaction/{reactionId}';

const mapStateToProps = (state) => ({
  token: state.authentication.token,
});

const mapDispatchToProps = () => ({
  getCommentsAsync: async (discusId, token, currPage) => {
    if (discusId) {
      const url = URL_COMMENTS
        .replace(/\{discusId\}/, discusId)
        .replace(/\{pageNumber\}/, currPage + 1);
      const comments = await sendGetRequest(url, token)
        .catch((err) => {
          setAlertErrorMessage(err);
        });
      return comments || {};
    } return {};
  },
  getRepliesAsync: async (commentId, token, currPage) => {
    const url = URL_COMMENTS_REPLIES
      .replace(/\{commentId\}/, commentId)
      .replace(/\{pageNumber\}/, currPage + 1);
    const replies = await sendGetRequest(url, token)
      .catch((err) => {
        setAlertErrorMessage(err);
      });
    return replies || {};
  },
  getReactionsAsync: async (reactionId, token) => {
    const url = URL_REACTION.replace(/\{reactionId\}/, reactionId);
    const reactions = await sendGetRequest(url, token)
      .catch((err) => {
        setAlertErrorMessage(err);
      });
    return reactions || {};
  },
  getUsersProfilePictureAsync: async (userList, token) => {
    const listId = userList.map((x) => x.user.id);
    const profilePictures = await downloadProfilePictures(listId, token)
      .catch((err) => {
        setAlertErrorMessage(err);
      });
    return profilePictures;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
