import '../../../../../component/functional-page/download-file.css';
import { REST_BASE_URL, REST_URL_IMAGE_STORAGE } from '../../../constant';
import { downloadDocumentGeneratorDocument } from '../../../helper';
import { downloadingDocumentResult } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { tappedId } = getState().uiDocument;

  try {
    dispatch(downloadingDocumentResult(true));
    const document = await downloadDocumentGeneratorDocument(tappedId, token);
    const { result } = document;
    const urlApi = REST_BASE_URL + REST_URL_IMAGE_STORAGE + result;

    return urlApi;
  } finally {
    dispatch(downloadingDocumentResult(false));
  }
};
