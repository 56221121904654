import * as Action from '../action';

export default (state = null, action) => {
  switch (action.type) {
    case Action.ADD_CURRENT_USER:
      return action.user;
    default:
      return state;
  }
};
