import { connect } from 'react-redux';
import { setDialogVisibility, setAlertErrorMessage } from '../../../redux/action';
import { DIALOG_TYPE_ADD } from '../../../constant';
import LocalizedString from '../../../localization';
import AddEditDialog from './add-edit-dialog.presentation';

const mapStateToProps = (state, ownProps) => {
  const defaultButtonCaption = state.uiFunctionalPage.dialogType === DIALOG_TYPE_ADD
    ? LocalizedString.common.buttonCaptionAdd : LocalizedString.common.buttonCaptionEdit;

  return ({
    title: state.uiFunctionalPage.title,
    buttonCaption: ownProps.buttonCaption || defaultButtonCaption,
    dialogType: state.uiFunctionalPage.dialogType,
    visibility: state.uiFunctionalPage.visibility,
  });
};

const mapDispatchToProps = (dispatch) => ({
  onClosePressed: () => {
    dispatch(setDialogVisibility(false, '', ''));
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDialog);
