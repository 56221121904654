import {
  SET_DOWNLOAD_REQUEST_TIMEOUT_DIALOG_VISIBILITY,
} from '../action';

const initialState = {
  isVisible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DOWNLOAD_REQUEST_TIMEOUT_DIALOG_VISIBILITY:
      return { ...state, isVisible: action.status };
    default: return state;
  }
};
