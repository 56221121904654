import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, Typography, makeStyles, Button, Paper, CircularProgress,
} from '@material-ui/core';
import { formatLabelToTitle, toCurrency } from '../../helper';
import {
  COLOR_SECONDARY, COLOR_TEXT_ON_PRIMARY, COLOR_PRIMARY, COLOR_BODY_TEXT_LIGHTER,
  RXFIELD_COVID_DASHBOARD_SUMMARY_PERIOD_FILTER,
  RXFIELD_COVID_DASHBOARD_EMPLOYEE_STATUS_FILTER,
  EMPLOYEE_STATUS_FILTER,
  PERIOD_FILTER,
  RXFORM_COVID_DASHBOARD,
} from '../../constant';
import {
  BasePage, VerticalSpacer, SimpleLineChart,
} from '../../component';
import LocalizedString from '../../localization';
import {
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape, TrendShape } from '../../type';

const ARGUMENT_FIELD = 'date';
const VALUE_FIELD = ['totalCase', 'vaccinated', 'totalScreening'];

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    height: 50,
  },
  activeButton: {
    display: 'flex',
    flex: 1,
    background: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
  },
  inactiveButton: {
    display: 'flex',
    flex: 1,
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  heading: {
    color: COLOR_SECONDARY,
  },
  caption: {
    color: COLOR_BODY_TEXT_LIGHTER,
  },
  number: {
    color: COLOR_PRIMARY,
  },
  dataPaper: {
    minWidth: 170,
    height: 133,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paper: {
    padding: 32,
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  centered: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
}));

const getLabel = (label) => {
  if (label.includes('pcr')) return 'PCR';
  if (label.includes('rtAntibody')) return 'RT Antibody';
  if (label.includes('rtAntigen')) return 'RT Antigen';
  return `${formatLabelToTitle(label)}`;
};

const getDeltaValue = (covidTotalSummary, data) => {
  const delta = covidTotalSummary.find((item) => item.label === `${data.label}Delta`);
  return delta.value < 0
    ? `- ${toCurrency(Math.abs(delta.value))}` : `+ ${toCurrency(delta.value)}`;
};

const renderCovidCaseSection = (classes, covidTotalSummary) => (
  <div>
    <Typography variant="h4" className={classes.heading}>
      {LocalizedString.covidDashboardPage.labelCovidCase}
    </Typography>
    <VerticalSpacer height={18} />
    <Grid container spacing={3} className={classes.section}>
      {covidTotalSummary
        .filter((data) => (data.label.includes('totalCase')
      || data.label.includes('hospitalized')
      || data.label.includes('recovered')
      || data.label.includes('selfIsolation')
      || data.label.includes('death')) && !data.label.includes('Delta'))
        .map((data) => (
          <Grid item key={data.label}>
            <Paper className={classes.dataPaper}>
              <Typography variant="body2" className={classes.caption}>
                {getLabel(data.label)}
              </Typography>
              <Typography variant="h2" className={classes.number}>
                {toCurrency(data.value)}
              </Typography>
              <Typography variant="body2" className={classes.caption}>
                {`${getDeltaValue(covidTotalSummary, data)}`}
              </Typography>
            </Paper>
          </Grid>
        ))}
    </Grid>
  </div>
);

const renderVaccinationSection = (classes, covidTotalSummary) => (
  <div>
    <Typography variant="h4" className={classes.heading}>
      {LocalizedString.covidDashboardPage.labelVaccination}
    </Typography>
    <VerticalSpacer height={18} />
    <Grid container spacing={3} className={classes.section}>
      {covidTotalSummary
        .filter((data) => data.label.includes('vaccinated') && !data.label.includes('Delta'))
        .map((data) => (
          <Grid item key={data.label}>
            <Paper className={classes.dataPaper}>
              <Typography variant="body2" className={classes.caption}>
                {getLabel(data.label)}
              </Typography>
              <Typography variant="h2" className={classes.number}>
                {toCurrency(data.value)}
              </Typography>
              <Typography variant="body2" className={classes.caption}>
                {`${getDeltaValue(covidTotalSummary, data)}`}
              </Typography>
            </Paper>
          </Grid>
        ))}
    </Grid>
  </div>
);

const renderScreeningSection = (classes, covidTotalSummary) => (
  <div>
    <Typography variant="h4" className={classes.heading}>
      {LocalizedString.covidDashboardPage.labelScreening}
    </Typography>
    <VerticalSpacer height={18} />
    <Grid container spacing={3} className={classes.section}>
      {covidTotalSummary
        .filter((data) => (data.label.includes('totalScreening')
      || data.label.includes('rtAntibody')
      || data.label.includes('rtAntigen')
      || data.label.includes('genose')
      || data.label.includes('serologi')
      || data.label.includes('pcr')) && !data.label.includes('Delta'))
        .map((data) => (
          <Grid item key={data.label}>
            <Paper className={classes.dataPaper}>
              <Typography variant="body2" className={classes.caption}>
                {getLabel(data.label)}
              </Typography>
              <Typography variant="h2" className={classes.number}>
                {`${toCurrency(data.value)}`}
              </Typography>
              <Typography variant="body2" className={classes.caption}>
                {`${getDeltaValue(covidTotalSummary, data)}`}
              </Typography>
            </Paper>
          </Grid>
        ))}
    </Grid>
  </div>
);

const renderGraph = (
  classes, covidTrendSummary, downloadingTrendSummary,
) => (
  <Grid className={classes.graphContainer}>
    <Typography variant="h4" className={classes.heading}>
      {LocalizedString.covidDashboardPage.labelSummary}
    </Typography>
    <VerticalSpacer height={18} />
    <Grid container>
      <Grid item sm md>
        <SimpleLineChart
          data={covidTrendSummary}
          loading={downloadingTrendSummary}
          argumentField={ARGUMENT_FIELD}
          name={VALUE_FIELD}
          title=""
          valueField={VALUE_FIELD}
          useVerticalLabel={false}
        />
      </Grid>
    </Grid>
  </Grid>
);

const CovidDashboardPage = ({
  initialValues, covidTrendSummary, covidTotalSummary,
  activeTab,
  downloadingTotalSummary, downloadingTrendSummary,
  onAppear, onTabPressed, onEmployeeStatusFilterOptionSelected,
  onSummaryPeriodFilterOptionSelected,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  return (
    <BasePage>
      <div className={classes.tabBar}>
        <Button
          variant={activeTab === 0 ? 'contained' : 'outlined'}
          className={activeTab === 0 ? classes.activeButton : classes.inactiveButton}
          onClick={() => onTabPressed(0)}
        >
          {LocalizedString.covidDashboardPage.buttonCaptionData}
        </Button>
        <Button
          variant={activeTab === 1 ? 'contained' : 'outlined'}
          className={activeTab === 1 ? classes.activeButton : classes.inactiveButton}
          onClick={() => onTabPressed(1)}
        >
          {LocalizedString.covidDashboardPage.buttonCaptionVisualization}
        </Button>
      </div>
      <VerticalSpacer height={15} />
      <Paper square className={classes.paper}>
        <Grid container justify="flex-end">
          <Field
            name={RXFIELD_COVID_DASHBOARD_EMPLOYEE_STATUS_FILTER}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.covidDashboardPage.placeholderTeam}
            label={LocalizedString.covidDashboardPage.placeholderTeam}
            disabled={downloadingTotalSummary || downloadingTrendSummary}
            data={EMPLOYEE_STATUS_FILTER}
            value={initialValues.employeeStatus}
            onOptionSelected={(option) => onEmployeeStatusFilterOptionSelected(
              option, activeTab,
            )}
          />

          <Field
            name={RXFIELD_COVID_DASHBOARD_SUMMARY_PERIOD_FILTER}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.covidDashboardPage.placeholderPeriod}
            label={LocalizedString.covidDashboardPage.placeholderPeriod}
            disabled={downloadingTotalSummary || downloadingTrendSummary}
            data={activeTab === 0 ? PERIOD_FILTER : PERIOD_FILTER.slice(1, 4)}
            value={initialValues.summaryPeriod}
            onOptionSelected={(option) => onSummaryPeriodFilterOptionSelected(
              option, activeTab,
            )}
          />
        </Grid>
        {(downloadingTotalSummary || downloadingTrendSummary) && (
        <div className={classes.centered}>
          <CircularProgress color="inherit" />
        </div>
        )}

        {activeTab === 0 && renderCovidCaseSection(classes, covidTotalSummary)}
        {activeTab === 0 && (<VerticalSpacer height={21} />)}
        {activeTab === 0 && renderVaccinationSection(classes, covidTotalSummary)}
        {activeTab === 0 && (<VerticalSpacer height={21} />)}
        {activeTab === 0 && renderScreeningSection(classes, covidTotalSummary)}

        {activeTab === 1 && renderGraph(
          classes, covidTrendSummary, downloadingTrendSummary,
        )}
      </Paper>
    </BasePage>
  );
};


export default reduxForm({
  form: RXFORM_COVID_DASHBOARD,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CovidDashboardPage);

CovidDashboardPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  covidTrendSummary: PropTypes.arrayOf(TrendShape).isRequired,
  covidTotalSummary: PropTypes.arrayOf(SimpleDataShape).isRequired,

  activeTab: PropTypes.number.isRequired,

  downloadingTotalSummary: PropTypes.bool.isRequired,
  downloadingTrendSummary: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
  onEmployeeStatusFilterOptionSelected: PropTypes.func.isRequired,
  onSummaryPeriodFilterOptionSelected: PropTypes.func.isRequired,
};
