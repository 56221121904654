import moment from 'moment';
import {
  buildQueryParams,
  sendDeleteRequest,
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
  transformQueryResult,
} from '../../helper';
import {
  REST_URL_CALENDARS,
  REST_URL_ADD_EDIT_CALENDAR,
  REST_URL_VIEW_DELETE_CALENDAR,
  REVERSED_ISO_DATE_FORMAT,
  REST_URL_DATE_RANGE_STATUS,
} from './constant';

export * from '../../helper';

export const downloadCalendars = async (
  pageNumber,
  pageSize,
  orderBy,
  searchText,
  filterString,
  token,
) => {
  const url = filterString
    ? buildQueryParams(
      REST_URL_CALENDARS.concat(filterString),
      pageNumber,
      pageSize,
      orderBy,
      searchText,
    )
    : buildQueryParams(
      REST_URL_CALENDARS,
      pageNumber,
      pageSize,
      orderBy,
      searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addCalendar = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_CALENDAR, body, token);
};

export const editCalendar = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_CALENDAR, body, token);
};

export const downloadCalendar = async (id, token) => {
  const response = await sendGetRequest(
    REST_URL_VIEW_DELETE_CALENDAR.replace(/\{id\}/, id),
    token,
  );
  return response;
};

export const deleteCalendar = async (id, token) => {
  await sendDeleteRequest(
    REST_URL_VIEW_DELETE_CALENDAR.replace(/\{id\}/, id),
    token,
  );
};

export const getDates = (year, startMonth, endMonth, dayNum, type, description) => {
  const start = moment().startOf('year').year(year).month(startMonth);
  const end = moment().endOf('year').year(year).month(endMonth);

  const result = [];
  const clonedStartDate = start.clone().day(dayNum);

  if (clonedStartDate.diff(start) >= 0) {
    result.push({ date: clonedStartDate.format(REVERSED_ISO_DATE_FORMAT), type, description });
  }

  while (clonedStartDate.diff(end) < 0) {
    clonedStartDate.add(7, 'days');
    result.push({ date: clonedStartDate.format(REVERSED_ISO_DATE_FORMAT), type, description });
  }

  result.pop();
  return result;
};

export const transformCalendarParentCalendarsData = (data) => Object.values(data).map(
  (item) => ({
    label: item.fullName || item.name || item.title,
    value: item.id,
    order: item.order,
  }),
);

export const downloadCalendarDateRangeStatus = async (token, calendarId, startDate, endDate) => {
  const response = await sendGetRequest(
    REST_URL_DATE_RANGE_STATUS
      .replace(/\{calendarId\}/, calendarId)
      .replace(/\{startDate\}/, startDate)
      .replace(/\{endDate\}/, endDate),
    token,
  );
  return response;
};
