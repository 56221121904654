import { downloadingSummaryPreviousMonthTopRecipients, setSummaryPreviousMonthTopRecipients } from '../simple-action';
import { downloadSummaryTopRecipients } from '../../../helper';
import { PACKET_SUMMARY_PREVIOUS_PERIOD, PACKET_SUMMARY_GROUPING_BY_DATE } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryPreviousMonthTopRecipients(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryTopRecipients(PACKET_SUMMARY_PREVIOUS_PERIOD,
      PACKET_SUMMARY_GROUPING_BY_DATE, token);

    dispatch(setSummaryPreviousMonthTopRecipients(result));
  } finally {
    dispatch(downloadingSummaryPreviousMonthTopRecipients(false));
  }
};
