import { reset } from 'redux-form';
import {
  addingEditingApiKey, clearApiKeys, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { addApiKey, editApiKey } from '../../../helper';
import { RXFORM_API_KEY, PAGE_MODE_TABLE } from '../../../constant';
import downloadApiKeysAsync from './downloadApiKeysAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingApiKey(true));

    const { token } = getState().authentication;
    const {
      selectedConsumerAppName, selectedOwnerAppName, selectedScope, tappedId,
    } = getState().uiApiKey;
    const { data } = getState().apiKeys;

    const found = data[tappedId];
    const ownerAppName = selectedOwnerAppName.label || found.ownerAppName;
    const consumerAppName = selectedConsumerAppName.label || found.consumerAppName;
    const scope = selectedScope.label || found.scope;

    if (tappedId) {
      await editApiKey(tappedId, scope, consumerAppName, ownerAppName, found.token, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addApiKey(scope, consumerAppName, ownerAppName, token);
    }

    dispatch(reset(RXFORM_API_KEY));
    dispatch(clearApiKeys());
    dispatch(downloadApiKeysAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingApiKey(false));
  }
};
