import { INITIAL_ORDER_BY_WFAS } from '../../constant';
import {
  ADDING_EDITING_WFA, DOWNLOADING_WFA, DOWNLOADING_WFAS, SET_WFA_SELECTED_PAGE_SIZE,
  SET_WFA_TAPPED_ID, SET_WFA_SEARCH_TEXT, SET_WFA_SELECTED_ORDER_BY,
  SET_WFA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, CANCELING_WFA,
  SET_WFA_EDIT_DIALOG_VISIBILITY,
} from '../action';

const initialState = {
  addingEditing: false,
  canceling: false,
  downloading: false,
  downloadingDeleting: false,
  editDialogVisibility: true,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_WFAS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_EDITING_WFA:
      return { ...state, addingEditing: action.status };
    case CANCELING_WFA:
      return { ...state, canceling: action.status };
    case DOWNLOADING_WFAS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_WFA:
      return { ...state, downloadingDeleting: action.status };
    case SET_WFA_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WFA_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WFA_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WFA_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WFA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_WFA_EDIT_DIALOG_VISIBILITY:
      return { ...state, editDialogVisibility: action.status };
    default:
      return state;
  }
};
