import {
  ADDING_EDITING_ARTICLE, DOWNLOADING_ARTICLES, DOWNLOADING_DELETING_ARTICLE,
  SET_ARTICLES_SEARCH_TEXT, SET_ARTICLES_SELECTED_ORDER_BY, SET_ARTICLES_SELECTED_PAGE_SIZE,
  SET_ARTICLE_SELECTED_ADD_MEDIA_MENU, SET_ARTICLE_SELECTED_CATEGORY,
  SET_ARTICLE_SELECTED_VISIBILITY_STATUS, SET_ARTICLE_TAPPED_ID,
  SENDING_ARTICLE_NOTIFICATION, COPYING_ARTICLE_DEEP_LINK, SAVING_ARTICLES,
} from '../action';
import { INITIAL_ORDER_BY_ARTICLES } from '../../constant';

const initialState = {
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  sendingNotification: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedCategory: '',
  selectedVisibilityStatus: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_ARTICLES,
  selectedAddMediaMenu: null,
  saving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ARTICLES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_ARTICLE:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_ARTICLE:
      return { ...state, downloadingDeleting: action.status };
    case SET_ARTICLES_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ARTICLE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ARTICLES_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ARTICLES_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ARTICLE_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.option };
    case SET_ARTICLE_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.option };
    case SET_ARTICLE_SELECTED_ADD_MEDIA_MENU:
      return { ...state, selectedAddMediaMenu: action.text };
    case SENDING_ARTICLE_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    case COPYING_ARTICLE_DEEP_LINK:
      return { ...state, copying: action.status };
    case SAVING_ARTICLES:
      return { ...state, saving: action.status };
    default: return state;
  }
};
