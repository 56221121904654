import { downloadingVoidPointRequests, setVoidPointRequests } from '../simple-action';
import { downloadVoidPointRequests, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, voidPointRequests, uiVoidPointRequest } = getState();
  const { token } = authentication;
  const { meta, data } = voidPointRequests;
  const { selectedPageSize, searchBarText, orderBy } = uiVoidPointRequest;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingVoidPointRequests(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadVoidPointRequests(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setVoidPointRequests(list));
    }
  } finally {
    dispatch(downloadingVoidPointRequests(false));
  }
};
