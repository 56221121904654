import {
  SET_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND, CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND,
} from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND: {
      return action.data;
    }
    case CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND:
      return state;
    default:
      return state;
  }
};
