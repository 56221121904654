import {
  enablingDisablingBranch, setAlertErrorMessage, setBranchSelectedOrderBy,
  setBranchSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_BRANCHES, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableBranch } from '../../../helper';
import downloadBranchesAsync from './downloadBranchesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingBranch(true));

    const { token } = getState().authentication;
    const { data } = getState().branches;
    const { tappedId } = getState().uiBranch;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableBranch(tappedId, status, token);

    dispatch(setBranchSelectedPageSize(20));
    dispatch(setBranchSelectedOrderBy(INITIAL_ORDER_BY_BRANCHES));
    dispatch(downloadBranchesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(enablingDisablingBranch(false));
  }
};
