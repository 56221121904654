import { SET_SUMMARY_THIS_MONTH_TOP_RECIPIENTS, CLEAR_SUMMARY_THIS_MONTH_TOP_RECIPIENTS } from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_SUMMARY_THIS_MONTH_TOP_RECIPIENTS: {
      return action.data;
    }
    case CLEAR_SUMMARY_THIS_MONTH_TOP_RECIPIENTS:
      return state;
    default:
      return state;
  }
};
