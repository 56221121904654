import {
  addConfigGroup, clearConfigGroups, downloadingDeletingConfigGroup,
  setConfigGroupTappedId, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { downloadConfigGroup, deleteConfigGroup } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadConfigGroupsAsync from './downloadConfigGroupsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingConfigGroup(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiConfigGroup;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteConfigGroup(tappedId, token);
      dispatch(setConfigGroupTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearConfigGroups());
      dispatch(downloadConfigGroupsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const configGroup = await downloadConfigGroup(tappedId, token);
      dispatch(addConfigGroup(configGroup));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingConfigGroup(false));
  }
};
