import PropTypes from 'prop-types';

export * from '../../type';

export const PageTitleAndScreenNameTotalValuesShape = PropTypes.shape({
  views: PropTypes.string,
  activeUsers: PropTypes.string,
  viewsPerActiveUser: PropTypes.string,
  averageEngagementPerActiveUser: PropTypes.string,
});
