import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  PAGE_MODE_TABLE, MENUID_POINT_VOID_POINT_REQUEST, INITIAL_ORDER_BY_VOID_POINT_REQUESTS,
  RXFIELD_VOID_POINT_REQUEST_POINT, RXFORM_VOID_POINT_REQUEST,
} from '../../constant';
import { transformInitialValues, transformDropdownData } from '../../helper';
import {
  clearPoints, clearUsers, clearVoidPointRequests, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setPointAdvancedFilterDialogSelectedFilterString, setPointSearchText,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  setVoidPointRequestSearchText, setVoidPointRequestSelectedOrderBy,
  setVoidPointRequestSelectedPageSize, setVoidPointRequestSelectedPoint,
  setVoidPointRequestTappedId,
  addVoidPointRequestAsync, downloadPointsAsync, downloadUsersAsync, downloadVoidPointRequestAsync,
  downloadVoidPointRequestsAsync,
} from '../../redux/action';
import VoidPointRequestPage from './void-point-request.presentation';

const getInitialValues = (state) => {
  const {
    voidPointRequests, uiFunctionalPage, uiVoidPointRequest,
  } = state;
  const { data } = voidPointRequests;
  const { downloadingDeleting, tappedId } = uiVoidPointRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    reason: '',
    merchantName: '',
    point: { label: '', value: '' },
    requestStatus: '',
    createdDate: '',
  };
  return result;
};

const transformPointDropdownData = (state) => Object.values(state).map((item) => ({
  label: item.label,
  value: item.id,
}));

const mapStateToProps = (state) => ({
  users: transformDropdownData(state.users.data),
  points: transformPointDropdownData(state.points.data),
  downloading: state.uiVoidPointRequest.downloading,
  addingEditing: state.uiVoidPointRequest.addingEditing,
  loadingUser: state.uiUser.downloading,
  loadingPoint: state.uiPoint.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_VOID_POINT_REQUEST));
    dispatch(setVoidPointRequestSearchText(''));
    dispatch(clearVoidPointRequests());
    dispatch(setVoidPointRequestSelectedPageSize(20));
    dispatch(setVoidPointRequestSelectedOrderBy(INITIAL_ORDER_BY_VOID_POINT_REQUESTS));
    dispatch(downloadVoidPointRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(reset(RXFORM_VOID_POINT_REQUEST));
    dispatch(setVoidPointRequestTappedId(''));
    dispatch(setPointSearchText(''));
    dispatch(clearPoints());
    dispatch(setPointAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_VOID_POINT_REQUEST));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVoidPointRequestsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoidPointRequestSelectedPageSize(pageSize));
    dispatch(downloadVoidPointRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoidPointRequestSelectedOrderBy(orderBy));
    dispatch(clearVoidPointRequests());
    dispatch(downloadVoidPointRequestsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setVoidPointRequestSelectedPageSize(pageSize));
    dispatch(clearVoidPointRequests());
    dispatch(downloadVoidPointRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setVoidPointRequestSearchText(text));
      dispatch(clearVoidPointRequests());
      await dispatch(downloadVoidPointRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onViewPressed: (id) => {
    dispatch(setVoidPointRequestTappedId(id));
    dispatch(downloadVoidPointRequestAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePointText: async (text) => {
    try {
      dispatch(setPointSearchText(text));
      dispatch(clearPoints());
      await dispatch(downloadPointsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setPointAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearVoidPointRequests());
    dispatch(setVoidPointRequestSelectedOrderBy(INITIAL_ORDER_BY_VOID_POINT_REQUESTS));
    dispatch(downloadVoidPointRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/point/, 'point.id').replace(/user/, 'point.user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearVoidPointRequests());
    dispatch(downloadVoidPointRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onPointOptionSelected: (option) => {
    if (option) {
      dispatch(setVoidPointRequestSelectedPoint(option));
      dispatch(change(RXFORM_VOID_POINT_REQUEST, RXFIELD_VOID_POINT_REQUEST_POINT, option));
    }
  },
  onSubmitPressed: async ({ reason, merchantReferenceId, callbackUrl }) => {
    await dispatch(addVoidPointRequestAsync(reason, merchantReferenceId, callbackUrl));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoidPointRequestPage);
