import { connect } from 'react-redux';
import NewsDashboardPage from './news-dashboard.presentation';
import {
  setActiveSideMenuItem, setNewsDashboardSelectedFilter,
  setAlertErrorMessage, downloadMostViewedNewsAsync,
  setNewsTappedId, downloadDeleteNewsAsync, setFunctionalPageTappedId,
  setFunctionalPageMode, downloadNewsTrendAsync, setNewsReturnToDashboard,
  setNewsTrendPeriode,
} from '../../redux/action';
import {
  MENUID_NEWS_MANAGEMENT_NEWS_DASHBOARD,
  PAGE_MODE_VIEW, ROUTE_NAME_NEWS, MOST_VIEWED_NEWS_FILTERS,
} from '../../constant';

const getInitialValues = (state) => {
  const { uiNewsDashboard } = state;
  const { selectedFilter } = uiNewsDashboard;
  return { filter: selectedFilter };
};

const getData = (state) => {
  const { mostViewedNews } = state;
  const { data } = mostViewedNews;

  return Object.values(data).map((item, i) => ({
    ...item,
    no: i + 1,
  }));
};

const mapStateToProps = (state) => ({
  news: getData(state),
  selectedFilter: state.uiNewsDashboard.selectedFilter,
  editing: state.uiNewsDashboard.editing,
  downloading: state.uiNewsDashboard.downloading,
  initialValues: getInitialValues(state),
});
const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NEWS_MANAGEMENT_NEWS_DASHBOARD));
    dispatch(downloadMostViewedNewsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onFilterOptionSelected: (option) => {
    dispatch(setNewsDashboardSelectedFilter(option));
    dispatch(downloadMostViewedNewsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id, history) => {
    history.push(ROUTE_NAME_NEWS);
    dispatch(setFunctionalPageTappedId(id));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    dispatch(setNewsTappedId(id));
    dispatch(setNewsReturnToDashboard(true));
    dispatch(downloadDeleteNewsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setNewsTrendPeriode(MOST_VIEWED_NEWS_FILTERS[2]));
    dispatch(downloadNewsTrendAsync(id))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(NewsDashboardPage);
