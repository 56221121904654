import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Avatar, Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_APPLICATION_ANDROID_ID, RXFIELD_APPLICATION_DESCRIPTION,
  RXFIELD_APPLICATION_DOWNLOAD_URL, RXFIELD_APPLICATION_ICON, RXFIELD_APPLICATION_IOS_ID,
  RXFIELD_APPLICATION_NAME, RXFIELD_APPLICATION_ORDER, RXFIELD_APPLICATION_PERMISSION,
  RXFIELD_APPLICATION_URL, RXFORM_APPLICATION, RXSTATE_APPLICATIONS, RXSTATE_APPLICATION_PAGE,
  RXFIELD_APPLICATION_MIN_APP_VERSION, RXFIELD_APPLICATION_MAX_APP_VERSION,
} from '../../constant';
import { FormInitialValueShape } from '../../type';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField, renderReduxFormImageInputField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateApplication } from '../../validation';

const renderDialogContent = (defaultIconValue, defaultPermissionValue, permissions, addingEditing,
  loadingPermission, onChangePermissionText, onIconImageSelected, onPermissionOptionSelected,
  pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderName}
            label={LocalizedString.applicationScreen.placeholderName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderDescription}
            label={LocalizedString.applicationScreen.placeholderDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderUrl}
            label={LocalizedString.applicationScreen.placeholderUrl}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_ANDROID_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderAndroidAppId}
            label={LocalizedString.applicationScreen.placeholderAndroidAppId}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_IOS_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderIosAppId}
            label={LocalizedString.applicationScreen.placeholderIosAppId}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_DOWNLOAD_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderDownloadUrl}
            label={LocalizedString.applicationScreen.placeholderDownloadUrl}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_ORDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderOrder}
            label={LocalizedString.applicationScreen.placeholderOrder}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_PERMISSION}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.applicationScreen.placeholderPermission}
            label={LocalizedString.applicationScreen.placeholderPermission}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={permissions}
            value={defaultPermissionValue}
            loading={loadingPermission}
            onChangeText={onChangePermissionText}
            onOptionSelected={onPermissionOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_MIN_APP_VERSION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderMinAppVersion}
            label={LocalizedString.applicationScreen.placeholderMinAppVersion}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_MAX_APP_VERSION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.applicationScreen.placeholderMaxAppVersion}
            label={LocalizedString.applicationScreen.placeholderMaxAppVersion}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_APPLICATION_ICON}
            component={renderReduxFormImageInputField}
            label={LocalizedString.applicationScreen.placeholderIcon}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onImageSelected={onIconImageSelected}
            defaultValue={defaultIconValue}
            required
          />
        </Grid>
      </Grid>
    </Grid>
);

const ApplicationPage = ({
  initialValues, permissions,
  addingEditing, downloading, loadingPermission,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize, onChangePermissionText,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onEditPressed, onIconImageSelected,
  onPermissionOptionSelected, onRefresh, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_APPLICATIONS}
    uiPage={RXSTATE_APPLICATION_PAGE}
    tableColumns={[
      {
        title: LocalizedString.applicationScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.applicationScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.applicationScreen.labelOrder, field: 'order', sorting: !downloading },
      { title: LocalizedString.applicationScreen.labelUrl, field: 'url', sorting: !downloading },
      {
        title: LocalizedString.applicationScreen.labelIcon,
        field: 'icon',
        sorting: false,
        searchable: false,
        // eslint-disable-next-line react/prop-types
        render: ({ iconPath }) => (<Avatar src={iconPath} />),
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.applicationScreen.buttonCaptionCreateNewApplication}
    deleteButtonCaption={LocalizedString.applicationScreen.buttonCaptionDeleteApplication}
    editButtonCaption={LocalizedString.applicationScreen.buttonCaptionEditApplication}
    title={LocalizedString.applicationScreen.title}
    useFullWidth
  >
    {renderDialogContent(initialValues.iconPath, initialValues.permission, permissions,
      addingEditing, loadingPermission, onChangePermissionText, onIconImageSelected,
      onPermissionOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_APPLICATION,
  validate: rxformValidateApplication,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ApplicationPage);

ApplicationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingPermission: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangePermissionText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onIconImageSelected: PropTypes.func.isRequired,
  onPermissionOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
