import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_AUDIT_TRAILS = '/audittrail/api/v1/AuditTrail?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=eventType|serviceName|entityName|entityId|user.fullName&filterString=';
export const REST_URL_VIEW_AUDIT_TRAIL = '/audittrail/api/v1/AuditTrail/{id}';

export const ROUTE_NAME_AUDIT_MANAGEMENT = '/audit-management';
export const ROUTE_NAME_AUDIT_TRAIL = '/audit-trail';

export const RXFORM_AUDIT_TRAIL = 'auditTrailPage';

export const RXFIELD_AUDIT_EVENT_TYPE = 'eventType';
export const RXFIELD_AUDIT_SERVICE_NAME = 'serviceName';
export const RXFIELD_AUDIT_ENTITY_NAME = 'entityName';
export const RXFIELD_AUDIT_ENTITY_ID = 'entityId';
export const RXFIELD_AUDIT_USER_ID = 'user.id';
export const RXFIELD_AUDIT_USER_NAME = 'user.fullName';
export const RXFIELD_AUDIT_FILTER_USER_NAME = 'fullName';
export const RXFIELD_AUDIT_EVENT_TIME = 'eventTime';
export const RXFIELD_AUDIT_GROUP_ID = 'groupId';
export const RXFIELD_AUDIT_COLUMNS = 'columns';
export const RXFIELD_AUDIT_IS_MODIFIED = 'isModified';

export const RXSTATE_AUDIT_TRAILS = 'auditTrails';
export const RXSTATE_AUDIT_TRAILS_PAGE = 'uiAuditTrail';

export const INITIAL_ORDER_BY_AUDIT_TRAILS = `eventTime ${ORDER_SORT_DESCENDING}`;

export const MENUID_AUDIT_MANAGEMENT = 'MENUID_AUDIT_MANAGEMENT';
export const MENUID_AUDIT_MANAGEMENT_AUDIT_TRAIL = 'MENUID_AUDIT_MANAGEMENT_TRAIL';

export const EVENT_TYPE_OPERATION_UPDATE = 'Update';

export const EVENT_TYPE_OPERATION = ['Create', EVENT_TYPE_OPERATION_UPDATE, 'Delete'];

export const COLOR_MODIFIED_ROW = '#fee4cd';
export const COLOR_TABLE_ICON = '#dbdbdb';
