import { downloadTokenRequest } from '../../../helper';
import {
  addTokenRequest, downloadingTokenRequest,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingTokenRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTokenRequest;

    const tokenRequest = await downloadTokenRequest(tappedId, token);

    dispatch(addTokenRequest(tokenRequest));
  } finally {
    dispatch(downloadingTokenRequest(false));
  }
};
