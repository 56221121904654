import { connect } from 'react-redux';
import { setActiveSideMenuItem } from '../../redux/action';
import { MENUID_REPORT } from '../../constant';
import ReportPage from './report.presentation';

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_REPORT));
  },
});

export default connect(null, mapDispatchToProps)(ReportPage);
