import {
  addNews, clearNews, downloadingDeletingNews, setAlertErrorMessage,
  setFunctionalPageMode, setNewsShortDescriptionLength, setNewsTappedId,
  setNewsSelectedIsHeadline,
} from '../simple-action';
import { downloadNews, deleteNews } from '../../../helper';
import { approval, PAGE_MODE_TABLE } from '../../../constant';
import downloadNewsAsync from './downloadNewsAsync';
import { getDeepLinkScheme } from '../../../../configuration/helper';

const DEEP_LINK_PATH = 'app/news/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingNews(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiNews;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteNews(tappedId, token);
      dispatch(setNewsTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearNews());
      dispatch(downloadNewsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      dispatch(setNewsShortDescriptionLength(0));

      const news = await downloadNews(tappedId, token);

      const result = await getDeepLinkScheme(news, DEEP_LINK_PATH, tappedId, token);

      dispatch(addNews(result));
      dispatch(setNewsSelectedIsHeadline(result.isHeadline ? approval[0] : approval[1]));
      if (result.shortDescription) {
        dispatch(setNewsShortDescriptionLength(result.shortDescription.length));
      }
    }
  } finally {
    dispatch(downloadingDeletingNews(false));
  }
};
