import React from 'react';
import PropTypes from 'prop-types';
import { COLOR_HORIZONTAL_LINE } from '../constant';

const styles = {
  horizontalLine: {
    width: '100%',
  },
};

const HorizontalLine = ({ borderColor, borderWidth }) => (
  <div style={{ ...styles.horizontalLine, background: borderColor, height: borderWidth }} />
);

export default HorizontalLine;

HorizontalLine.propTypes = {
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
};

HorizontalLine.defaultProps = {
  borderWidth: 1,
  borderColor: COLOR_HORIZONTAL_LINE,
};
