import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Button, Grid,
} from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT, RXSTATE_JOB_PAGE, RXSTATE_JOBS,
  RXFORM_JOB, RXFIELD_JOB_NAME, RXFIELD_JOB_DESCRIPTION,
  RXFIELD_JOB_USER_ID, RXFIELD_JOB_USER_NAME, RXFIELD_JOB_JOB_STATUS,
  RXFIELD_JOB_JOB_STATUS_NOTES, RXFIELD_JOB_PROGRESS, RXFIELD_JOB_JOB_PROGRESS_NOTES,
  RXFIELD_JOB_START_TIME, RXFIELD_JOB_END_TIME, RXFIELD_JOB_DELETED_AFTER, JOB_STATUS, RESULT_TYPE,
  FILTER_TYPE_DROPDOWN, RXFIELD_JOB_USER_PROFILE, RXFIELD_JOB_RESULT_TYPE,
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE, JOB_STATUS_FINISHED,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const JobPage = ({
  initialValues,
  downloading, handleSubmit, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onChangePage, onChangePageSize, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed, tappedId,
  onDownloadPressed, onContextMenuPressed, onConfirmContextMenuPressed,
  profiles, loadingProfiles, onChangeProfileText, onAdvancedFilterPressed,
  currentJobStatus, currentFilePath, currentFileName,
}) => {
  const renderDownloadButton = (resultFilePath, fileName) => {
    if (resultFilePath) {
      return (
        <Button
          onClick={() => onDownloadPressed(resultFilePath, fileName)}
        >
          {LocalizedString.jobPage.buttonCaptionDownload}
        </Button>
      );
    }
    return null;
  };

  return (
    <FunctionalPage
      data={RXSTATE_JOBS}
      uiPage={RXSTATE_JOB_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.jobPage.buttonCaptionDelete,
          onPress: () => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDeleteConfirmation),
        },
        ...(currentJobStatus === JOB_STATUS_FINISHED && currentFilePath ? [
          {
            caption: LocalizedString.jobPage.buttonCaptionDownload,
            disabled: downloading,
            onPress: () => onDownloadPressed(currentFilePath, currentFileName),
          },
        ] : []),
      ]}
      filterColumns={[
        {
          title: LocalizedString.jobPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.jobPage.labelStartTime,
          field: 'startTime',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.jobPage.labelEndTime,
          field: 'endTime',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.jobPage.labelJobStatus, field: 'jobStatus', type: FILTER_TYPE_DROPDOWN, data: JOB_STATUS,
        },
        {
          title: LocalizedString.jobPage.labelResultType, field: 'resultType', type: FILTER_TYPE_DROPDOWN, data: RESULT_TYPE,
        },
        {
          title: LocalizedString.jobPage.labelUser,
          field: RXFIELD_JOB_USER_PROFILE,
          type: FILTER_TYPE_DROPDOWN,
          data: profiles,
          loading: loadingProfiles,
          onChangeFilterText: onChangeProfileText,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.jobPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.jobPage.labelName, field: 'name', sorting: !downloading,
        },
        {
          title: LocalizedString.jobPage.labelUser, field: 'user.fullName', sorting: !downloading,
        },
        { title: LocalizedString.jobPage.labelJobStatus, field: 'jobStatus', sorting: !downloading },
        {
          title: LocalizedString.jobPage.labelStartTime,
          field: 'startTime',
          sorting: !downloading,
          render: ({ startTime }) => (startTime
            ? toMoment(startTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.jobPage.labelEndTime,
          field: 'endTime',
          sorting: !downloading,
          render: ({ endTime }) => (endTime
            ? toMoment(endTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.jobPage.labelProgress,
          field: 'progress',
          render: ({ progress }) => `${progress}%`,
          sorting: !downloading,
        },
        {
          title: LocalizedString.jobPage.labelProgressNotes, field: 'progressNotes', sorting: !downloading,
        },
        {
          title: LocalizedString.jobPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onRefresh={onRefresh}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.jobPage.title}
      disableCreate
      disableEdit
      disableDelete
      useFullWidth
      usefullWidthDialog
    >
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_JOB_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderName}
              label={LocalizedString.jobPage.placeholderName}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderDescription}
              label={LocalizedString.jobPage.placeholderDescription}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderUserID}
              label={LocalizedString.jobPage.placeholderUserID}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_USER_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderUserName}
              label={LocalizedString.jobPage.placeholderUserName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_RESULT_TYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderResultType}
              label={LocalizedString.jobPage.placeholderResultType}
              disabled
            />
          </Grid>
          <Grid item>
            {renderDownloadButton(initialValues?.resultFilePath, initialValues.name)}
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_JOB_JOB_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderJobStatus}
              label={LocalizedString.jobPage.placeholderJobStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_JOB_STATUS_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderJobStatusNotes}
              label={LocalizedString.jobPage.placeholderJobStatusNotes}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_PROGRESS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderProgress}
              label={`${LocalizedString.jobPage.placeholderProgress} (%)`}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_JOB_PROGRESS_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderProgressNotes}
              label={LocalizedString.jobPage.placeholderProgressNotes}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_START_TIME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderStartTime}
              label={LocalizedString.jobPage.placeholderStartTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_END_TIME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderEndTime}
              label={LocalizedString.jobPage.placeholderEndTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_JOB_DELETED_AFTER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.jobPage.placeholderDeletedAfter}
              label={LocalizedString.jobPage.placeholderDeletedAfter}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </FunctionalPage>
  );
};
export default reduxForm({
  form: RXFORM_JOB,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(JobPage);
JobPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingProfiles: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  tappedId: PropTypes.string.isRequired,
  currentJobStatus: PropTypes.string.isRequired,
  currentFilePath: PropTypes.string.isRequired,
  currentFileName: PropTypes.string.isRequired,
  onChangeProfileText: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
};
