import { addNotificationTask, cancelingNotificationTask } from '../simple-action';
import { cancelNotificationTask, downloadNotificationTask } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(cancelingNotificationTask(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiNotificationTask;

    await cancelNotificationTask(tappedId, token);
    const result = await downloadNotificationTask(tappedId, token);

    dispatch(addNotificationTask(result));
  } finally {
    dispatch(cancelingNotificationTask(false));
  }
};
