import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearCities, clearProvinces, clearRegistrations, clearUsers, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage, setAlertInputMessage,
  setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText,
  setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText,
  setRegistrationSearchText, setRegistrationSelectedPageSize, setRegistrationSelectedOrderBy,
  setRegistrationTappedId, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  approveRegistrationAsync, downloadRegistrationAsync, downloadRegistrationsAsync,
  rejectRegistrationAsync,
} from '../../redux/action';
import { downloadCitiesAsync, downloadProvincesAsync, downloadUsersAsync } from '../../../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_REGISTRATIONS,
  MENUID_CAR_REGISTRATION_REGISTRATION, PAGE_MODE_TABLE, RXFORM_REGISTRATION,
} from '../../constant';
import {
  toCurrency, toMoment, transformInitialValues, transformDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import RegistrationPage from './registration.presentation';

const transformDate = (date) => (date ? toMoment(date).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
  : null);

const getInitialValues = (state) => {
  const { registrations, uiFunctionalPage, uiRegistration } = state;
  const { data } = registrations;
  const { downloadingDeleting, tappedId } = uiRegistration;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    vehicle: {
      ...found.vehicle,
      isPersonalAccount: found.vehicle.isPersonalAccount ? GlobalLocalizedString.common.labelYes
        : GlobalLocalizedString.common.labelNo,
      price: found.vehicle.price ? toCurrency(found.vehicle.price) : 0,
      warantyStartDate: transformDate(found.vehicle.warantyStartDate),
      warantyEndDate: transformDate(found.vehicle.warantyEndDate),
    },
    approvedOn: transformDate(found.approvedOn),
    rejectedOn: transformDate(found.rejectedOn),
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  cities: transformDropdownData(state.cities.data),
  provinces: transformDropdownData(state.provinces.data),
  users: transformDropdownData(state.users.data),
  approving: state.uiRegistration.approving,
  downloading: state.uiRegistration.downloading,
  downloadingDeleting: state.uiRegistration.downloadingDeleting,
  rejecting: state.uiRegistration.rejecting,
  loadingCity: state.uiCity.downloading,
  loadingProvince: state.uiProvince.downloading,
  loadingUser: state.uiUser.downloading,
  userPermissions: state.currentUser.permissions,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setCitySearchText(''));
    dispatch(setProvinceSearchText(''));
    dispatch(clearUsers());
    dispatch(clearCities());
    dispatch(clearProvinces());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_REGISTRATION_REGISTRATION));
    dispatch(setRegistrationSearchText(''));
    dispatch(clearRegistrations());
    dispatch(setRegistrationSelectedPageSize(20));
    dispatch(setRegistrationSelectedOrderBy(INITIAL_ORDER_BY_REGISTRATIONS));
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id').replace(/city/, 'city.id')
      .replace(/province/, 'city.province.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearRegistrations());
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApprovePressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.registrationPage.msgApproveConfirmation,
      LocalizedString.registrationPage.placeholderNotes));
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_REGISTRATION));
  },
  onChangeCityText: async (text) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRegistrationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRegistrationSelectedPageSize(pageSize));
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProvinceText: async (text) => {
    try {
      dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.registrationPage.msgApproveConfirmation:
        dispatch(approveRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.registrationPage.msgRejectConfirmation:
        dispatch(rejectRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default: break;
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setRegistrationSelectedPageSize(pageSize));
    dispatch(clearRegistrations());
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRejectPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.registrationPage.msgRejectConfirmation,
      LocalizedString.registrationPage.placeholderNotes));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearRegistrations());
    dispatch(setRegistrationSelectedOrderBy(INITIAL_ORDER_BY_REGISTRATIONS));
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setRegistrationSearchText(text));
      dispatch(clearRegistrations());
      await dispatch(downloadRegistrationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setRegistrationSelectedOrderBy(orderBy));
    dispatch(clearRegistrations());
    dispatch(downloadRegistrationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setRegistrationTappedId(id));
    dispatch(downloadRegistrationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
