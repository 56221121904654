import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_SHORTLINK_REFERENCE_ID, RXFIELD_SHORTLINK_URL,
  RXFIELD_SHORTLINK_SHORT_URL, RXFIELD_SHORTLINK_DESCRIPTION,
  RXSTATE_SHORTLINKS, RXSTATE_SHORTLINK_PAGE, RXFORM_SHORTLINK,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateShortLink } from '../../validation';

const renderDialogContent = (addingEditing, downloadingDeleting, pageMode) => {
  if (pageMode === PAGE_MODE_VIEW) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SHORTLINK_SHORT_URL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.shortlinkScreen.placeholderShortUrl}
              label={LocalizedString.shortlinkScreen.placeholderShortUrl}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
            <Field
              name={RXFIELD_SHORTLINK_URL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.shortlinkScreen.placeholderUrl}
              label={LocalizedString.shortlinkScreen.placeholderUrl}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
              multiline
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SHORTLINK_REFERENCE_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.shortlinkScreen.placeholderReferenceId}
              label={LocalizedString.shortlinkScreen.placeholderReferenceId}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
            <Field
              name={RXFIELD_SHORTLINK_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.shortlinkScreen.placeholderDescription}
              label={LocalizedString.shortlinkScreen.placeholderDescription}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item>
        <Field
          name={RXFIELD_SHORTLINK_URL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.shortlinkScreen.placeholderUrl}
          label={LocalizedString.shortlinkScreen.placeholderUrl}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          multiline
        />
        <Field
          name={RXFIELD_SHORTLINK_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.shortlinkScreen.placeholderDescription}
          label={LocalizedString.shortlinkScreen.placeholderDescription}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  );
};

const ShortlinkPage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_SHORTLINKS}
    uiPage={RXSTATE_SHORTLINK_PAGE}
    tableColumns={[
      {
        title: LocalizedString.shortlinkScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.shortlinkScreen.labelShortUrl, field: 'shortUrl', sorting: !downloading },
      { title: LocalizedString.shortlinkScreen.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.shortlinkScreen.labelUrl, field: 'url', sorting: !downloading },
      { title: LocalizedString.shortlinkScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.shortlinkScreen.buttonCaptionCreateNewShortlink}
    deleteButtonCaption={LocalizedString.shortlinkScreen.buttonCaptionDeleteShortlink}
    title={LocalizedString.shortlinkScreen.title}
    createPermissionName="SHORTLINK_CREATE_LINK"
    deletePermissionName="SHORTLINK_DELETE_LINK"
    disableEdit
    useFullWidth
  >
    {renderDialogContent(addingEditing, downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_SHORTLINK,
  validate: rxformValidateShortLink,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ShortlinkPage);

ShortlinkPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
