import { reset } from 'redux-form';
import {
  addingEditingScope, clearScopes, setAlertErrorMessage, setScopeSelectedServiceId,
  setFunctionalPageMode,
} from '../simple-action';
import { addScope, editScope } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_SCOPE } from '../../../constant';
import downloadScopesAsync from './downloadScopesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingScope(true));

    const { token } = getState().authentication;
    const { tappedId, selectedServiceId } = getState().uiScope;
    const { data } = getState().scopes;

    const found = data[tappedId];
    const service = selectedServiceId || found.service.id;

    if (tappedId) {
      await editScope(tappedId, name, description, service, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addScope(name, description, selectedServiceId, token);
    }

    dispatch(reset(RXFORM_SCOPE));
    dispatch(clearScopes());
    dispatch(downloadScopesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setScopeSelectedServiceId(''));
  } finally {
    dispatch(addingEditingScope(false));
  }
};
