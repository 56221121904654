import { addQuizResponse, downloadingDeletingQuizResponse } from '../simple-action';
import { downloadQuizResponse } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingQuizResponse(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiQuizResponse;

    const response = await downloadQuizResponse(tappedId, token);
    dispatch(addQuizResponse(response));
  } finally {
    dispatch(downloadingDeletingQuizResponse(false));
  }
};
