import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import moment from 'moment';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_TIME, DATE_TIME_FORMAT_WITHOUT_PIPE,
  INITIAL_ORDER_BY_SURVEY_QUESTIONS, MENUID_SURVEY_SURVEY_QUESTION,
  PAGE_MODE_TABLE, RXFIELD_SURVEY_QUESTION_FIELD_TYPE, RXFIELD_SURVEY_QUESTION_LABEL,
  RXFIELD_SURVEY_QUESTION_NAME, RXFIELD_SURVEY_QUESTION_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR,
  RXFIELD_SURVEY_QUESTION_MUST_USE_CAMERA, RXFIELD_SURVEY_QUESTION_OPTION_MODE,
  RXFIELD_SURVEY_QUESTION_OPTION_VALUE_SEPARATOR, RXFIELD_SURVEY_QUESTION_PARENT,
  RXFIELD_SURVEY_QUESTION_PICKER_MODE, RXFIELD_SURVEY_QUESTION_READ_ONLY,
  RXFIELD_SURVEY_QUESTION_REQUIRED, RXFIELD_SURVEY_QUESTION_SURVEY,
  RXFIELD_SURVEY_QUESTION_TEXT_KEYBOARD_TYPE, RXFIELD_SURVEY_QUESTION_TEXT_MULTILINE,
  RXFIELD_SURVEY_QUESTION_VISIBLE, RXFORM_SURVEY_QUESTION, SIMPLE_DATE_FORMAT, TIME_FORMAT,
  RXFIELD_SURVEY_QUESTION_IMAGE, RXFIELD_SURVEY_QUESTION_CLEAR_IMAGE, REST_BASE_URL,
  REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  sortAsc, transformDropdownData, transformInitialValues, toMoment,
} from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearSurveys, clearSurveyQuestionParents, clearSurveyQuestions, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setSurveyAdvancedFilterDialogSelectedFilterString, setSurveySearchText,
  setSurveyQuestionSearchText, setSurveyQuestionParentSearchText,
  setSurveyQuestionSelectedFieldType, setSurveyQuestionSelectedLogicalOperator,
  setSurveyQuestionSelectedMustUseCamera, setSurveyQuestionSelectedOptionMode,
  setSurveyQuestionSelectedOptionValueSeparator, setSurveyQuestionSelectedOrderBy,
  setSurveyQuestionSelectedPageSize, setSurveyQuestionSelectedParent,
  setSurveyQuestionSelectedPickerMode, setSurveyQuestionSelectedSurvey,
  setSurveyQuestionSelectedTextKeyboardType, setSurveyQuestionSelectedTextMultiline,
  setSurveyQuestionTappedId,
  addEditSurveyQuestionAsync, downloadSurveyQuestionParentsAsync, downloadSurveyQuestionsAsync,
  downloadSurveysAsync, downloadDeleteSurveyQuestionAsync, enableDisableSurveyQuestionAsync,
} from '../../redux/action';
import SurveyQuestionPage from './survey-question.presentation';

const getDateTimeFormat = (fieldType, dateTime) => {
  switch (fieldType) {
    case ENUM_FIELD_DATE: return moment.utc(dateTime).format(SIMPLE_DATE_FORMAT);
    case ENUM_FIELD_DATE_TIME: return toMoment(dateTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE);
    case ENUM_FIELD_TIME: return moment.utc(dateTime).format(TIME_FORMAT);
    default: return null;
  }
};

const getInitialValues = (state) => {
  const { surveyQuestions, uiFunctionalPage, uiSurveyQuestion } = state;
  const { data } = surveyQuestions;
  const { downloadingDeleting, tappedId } = uiSurveyQuestion;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    parent: found.parent ? { label: found.parent.name, value: found.parent.id } : { label: '', value: '' },
    dateTimeMaxValue: found.dateTimeMaxValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMaxValue) : null,
    dateTimeMinValue: found.dateTimeMinValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMinValue) : null,
    showIfParentValue: found.parent ? found.showIfParentValue : '',
    textMultiline: found.textMultiline
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    mustUseCamera: found.mustUseCamera
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    survey: found.survey ? { label: found.survey.title, value: found.survey.id } : { label: '', value: '' },
    image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : '',
  }) : {
    name: '',
    label: '',
    placeHolder: '',
    validationMessage: '',
    defaultValue: '',
    order: '',
    required: false,
    visible: true,
    readOnly: false,
    textMultiline: false,
    clearImage: false,
    textKeyboardType: null,
    fieldType: '',
    pickerMode: '',
    optionMode: null,
    optionValues: '',
    optionValueSeparator: null,
    sectionId: '',
    sectionLabel: '',
    appliedOn: '',
    textMinLength: '',
    textMaxLength: '',
    textRegexValidation: null,
    intMinValue: '',
    intMaxValue: '',
    floatMinValue: '',
    floatMaxValue: '',
    dateTimeMinValue: null,
    dateTimeMaxValue: null,
    parent: null,
    showIfParentValue: null,
    showIfParentValueLogicalOperator: null,
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiSurveyQuestion.addingEditing,
  downloading: state.uiSurveyQuestion.downloading,
  downloadingDeleting: state.uiSurveyQuestion.downloadingDeleting,
  enablingDisabling: state.uiSurveyQuestion.enablingDisabling,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  parents: Object.keys(state.surveyQuestionParents.data).length > 0
  && !state.uiSurveyQuestiondownloadingParents
    ? transformDropdownData(state.surveyQuestionParents.data)
      .filter((x) => x.value !== state.uiSurveyQuestion.tappedId)
      .sort((a, b) => sortAsc(a.label.toLowerCase(), b.label.toLowerCase())) : [],
  selectedParent: state.uiSurveyQuestion.selectedParent,
  selectedFieldType: state.uiSurveyQuestion.selectedFieldType,
  loadingParents: state.uiSurveyQuestion.downloadingParents,
  currentSurveyQuestionStatus: state.surveyQuestions.data[state.uiFunctionalPage.tappedId]
    ? state.surveyQuestions.data[state.uiFunctionalPage.tappedId].status : '',
  surveys: transformDropdownData(state.surveys.data),
  loadingSurveys: state.uiSurvey.downloading,
});

const camelize = (text) => {
  const str = text.replace(/[^a-zA-Z0-9]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return str.substr(0, 1).toLowerCase() + str.substr(1);
};

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setSurveyAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSurveySearchText(''));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_SURVEY_SURVEY_QUESTION));
    dispatch(setSurveyQuestionSearchText(''));
    dispatch(clearSurveyQuestions());
    dispatch(setSurveyQuestionSelectedPageSize(20));
    dispatch(setSurveyQuestionSelectedOrderBy(INITIAL_ORDER_BY_SURVEY_QUESTIONS));
    dispatch(downloadSurveyQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/survey/, 'survey.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSurveyQuestions());
    dispatch(downloadSurveyQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_SURVEY_QUESTION));
  },
  onCancelPressed: () => {
    dispatch(setSurveyQuestionParentSearchText(''));
    dispatch(setSurveyQuestionSelectedFieldType(''));
    dispatch(setSurveyQuestionSelectedLogicalOperator(''));
    dispatch(setSurveyQuestionSelectedMustUseCamera(''));
    dispatch(setSurveyQuestionSelectedOptionMode(''));
    dispatch(setSurveyQuestionSelectedOptionValueSeparator(''));
    dispatch(setSurveyQuestionSelectedParent(''));
    dispatch(setSurveyQuestionSelectedPickerMode(''));
    dispatch(setSurveyQuestionSelectedTextKeyboardType(''));
    dispatch(setSurveyQuestionSelectedTextMultiline(''));
    dispatch(reset(RXFORM_SURVEY_QUESTION));
  },
  onChangeLabelText: (e, pageMode) => {
    dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_LABEL, e.target.value));
    if (pageMode === PAGE_MODE_TABLE) {
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_NAME,
        camelize(e.target.value)));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSurveyQuestionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSurveyQuestionSelectedPageSize(pageSize));
    dispatch(downloadSurveyQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeParentText: async (text) => {
    try {
      dispatch(setSurveyQuestionParentSearchText(text));
      dispatch(clearSurveyQuestionParents());
      await dispatch(downloadSurveyQuestionParentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeSurveyText: async (text) => {
    try {
      dispatch(setSurveyAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setSurveySearchText(text));
      dispatch(clearSurveys());
      await dispatch(downloadSurveysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteSurveyQuestionAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableSurveyQuestionAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onCreatePressed: () => {
    dispatch(setSurveyQuestionTappedId(''));
    dispatch(reset(RXFORM_SURVEY_QUESTION));
    dispatch(setSurveyQuestionParentSearchText(''));
    dispatch(setSurveyQuestionSelectedFieldType(''));
    dispatch(setSurveyQuestionSelectedLogicalOperator(''));
    dispatch(setSurveyQuestionSelectedMustUseCamera(''));
    dispatch(setSurveyQuestionSelectedOptionMode(''));
    dispatch(setSurveyQuestionSelectedOptionValueSeparator(''));
    dispatch(setSurveyQuestionSelectedParent(''));
    dispatch(setSurveyQuestionSelectedPickerMode(''));
    dispatch(setSurveyQuestionSelectedTextKeyboardType(''));
    dispatch(setSurveyQuestionSelectedTextMultiline(''));
    dispatch(setSurveyQuestionParentSearchText(''));
    dispatch(setSurveyAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSurveySearchText(''));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(clearSurveyQuestionParents());
    dispatch(downloadSurveyQuestionParentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setSurveyQuestionTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSurveyQuestionTappedId(id));
    dispatch(downloadDeleteSurveyQuestionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setSurveyAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSurveySearchText(''));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(clearSurveyQuestionParents());
    dispatch(downloadSurveyQuestionParentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setSurveyQuestionTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onFieldTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedFieldType(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_FIELD_TYPE, option));
    } else {
      dispatch(setSurveyQuestionSelectedFieldType(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_FIELD_TYPE, ''));
    }
  },
  onLogicalOperatorOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedLogicalOperator(option));
      dispatch(change(RXFORM_SURVEY_QUESTION,
        RXFIELD_SURVEY_QUESTION_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR, option));
    } else {
      dispatch(setSurveyQuestionSelectedLogicalOperator(''));
      dispatch(change(RXFORM_SURVEY_QUESTION,
        RXFIELD_SURVEY_QUESTION_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR, ''));
    }
  },
  onMustUseCameraOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedMustUseCamera(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_MUST_USE_CAMERA, option));
    } else {
      dispatch(setSurveyQuestionSelectedMustUseCamera(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_MUST_USE_CAMERA, ''));
    }
  },
  onOptionModeOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedOptionMode(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_OPTION_MODE, option));
    } else {
      dispatch(setSurveyQuestionSelectedOptionMode(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_OPTION_MODE, ''));
    }
  },
  onOptionValueSeparatorOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedOptionValueSeparator(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_OPTION_VALUE_SEPARATOR,
        option));
    } else {
      dispatch(setSurveyQuestionSelectedOptionValueSeparator(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_OPTION_VALUE_SEPARATOR, ''));
    }
  },
  onParentOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedParent(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_PARENT, option));
    } else {
      dispatch(setSurveyQuestionSelectedParent(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_PARENT, ''));
    }
  },
  onPickerModeOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedPickerMode(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_PICKER_MODE, option));
    } else {
      dispatch(setSurveyQuestionSelectedPickerMode(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_PICKER_MODE, ''));
    }
  },
  onReadOnlyOptionSelected: (option) => {
    dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_READ_ONLY, option));
  },
  onRefresh: (pageSize) => {
    dispatch(setSurveyQuestionSelectedPageSize(pageSize));
    dispatch(clearSurveyQuestions());
    dispatch(downloadSurveyQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRequiredOptionSelected: (option) => {
    dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_REQUIRED, option));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearSurveyQuestions());
    dispatch(setSurveyQuestionSelectedOrderBy(INITIAL_ORDER_BY_SURVEY_QUESTIONS));
    dispatch(downloadSurveyQuestionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async (values) => {
    try {
      await dispatch(addEditSurveyQuestionAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSurveyQuestionSearchText(text));
      dispatch(clearSurveyQuestions());
      await dispatch(downloadSurveyQuestionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSurveyOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedSurvey(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_SURVEY, option));
    } else {
      dispatch(setSurveyQuestionSelectedSurvey(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_SURVEY, ''));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSurveyQuestionSelectedOrderBy(orderBy));
    dispatch(clearSurveyQuestions());
    dispatch(downloadSurveyQuestionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async (values) => {
    await dispatch(addEditSurveyQuestionAsync(values));
  },
  onTextKeyboardTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedTextKeyboardType(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_TEXT_KEYBOARD_TYPE,
        option));
    } else {
      dispatch(setSurveyQuestionSelectedTextKeyboardType(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_TEXT_KEYBOARD_TYPE, ''));
    }
  },
  onTextMultilineOptionSelected: (option) => {
    if (option) {
      dispatch(setSurveyQuestionSelectedTextMultiline(option));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_TEXT_MULTILINE, option));
    } else {
      dispatch(setSurveyQuestionSelectedTextMultiline(''));
      dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_TEXT_MULTILINE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setSurveyQuestionTappedId(id));
    dispatch(downloadDeleteSurveyQuestionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibleOptionSelected: (option) => {
    dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_VISIBLE, option));
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_IMAGE, image));
  },
  onClearImageOptionSelected: (option) => {
    dispatch(change(RXFORM_SURVEY_QUESTION, RXFIELD_SURVEY_QUESTION_CLEAR_IMAGE, option));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestionPage);
