import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_DOCUMENT_GENERATOR_TEMPLATES = 'DOWNLOADING_DOCUMENT_GENERATOR_TEMPLATES';
export const downloadingDocumentGeneratorTemplates = makeActionCreator(DOWNLOADING_DOCUMENT_GENERATOR_TEMPLATES, 'status');

export const SET_DOCUMENT_GENERATOR_TEMPLATES = 'SET_DOCUMENT_GENERATOR_TEMPLATES';
export const setDocumentGeneratorTemplates = makeActionCreator(SET_DOCUMENT_GENERATOR_TEMPLATES, 'data');

export const ADD_DOCUMENT_GENERATOR_TEMPLATE = 'ADD_DOCUMENT_GENERATOR_TEMPLATE';
export const addDocumentGeneratorTemplates = makeActionCreator(ADD_DOCUMENT_GENERATOR_TEMPLATE, 'data');

export const CLEAR_DOCUMENT_GENERATOR_TEMPLATES = 'CLEAR_DOCUMENT_GENERATOR_TEMPLATES';
export const clearDocumentGeneratorTemplates = makeActionCreator(
  CLEAR_DOCUMENT_GENERATOR_TEMPLATES,
);

export const SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_PAGE_SIZE = 'SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_PAGE_SIZE';
export const setDocumentGeneratorTemplateSelectedPageSize = makeActionCreator(SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_PAGE_SIZE, 'size');

export const SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_ORDER_BY = 'SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_ORDER_BY';
export const setDocumentGeneratorTemplateSelectedOrderBy = makeActionCreator(SET_DOCUMENT_GENERATOR_TEMPLATE_SELECTED_ORDER_BY, 'order');

export const SET_DOCUMENT_GENERATOR_TEMPLATE_TAPPED_ID = 'SET_DOCUMENT_GENERATOR_TEMPLATE_TAPPED_ID';
export const setDocumentGeneratorTemplateTappedId = makeActionCreator(SET_DOCUMENT_GENERATOR_TEMPLATE_TAPPED_ID, 'id');

export const SET_DOCUMENT_GENERATOR_TEMPLATE_SEARCH_TEXT = 'SET_DOCUMENT_GENERATOR_TEMPLATES_SEARCH_TEXT';
export const setDocumentGeneratorTemplateSearchText = makeActionCreator(SET_DOCUMENT_GENERATOR_TEMPLATE_SEARCH_TEXT, 'text');

export const DOWNLOADING_DELETING_DOCUMENT_GENERATOR_TEMPLATE = 'DOWNLOADING_DELETING_DOCUMENT_GENERATOR_TEMPLATE';
export const downloadingDeletingDocumentGeneratorTemplate = makeActionCreator(DOWNLOADING_DELETING_DOCUMENT_GENERATOR_TEMPLATE, 'status');

export const ENABLING_DISABLING_DOCUMENT_GENERATOR_TEMPLATE = 'ENABLING_DISABLING_DOCUMENT_GENERATOR_TEMPLATE';
export const enablingDisablingDocumentGeneratorTemplate = makeActionCreator(ENABLING_DISABLING_DOCUMENT_GENERATOR_TEMPLATE, 'status');

export const ADDING_EDITING_DOCUMENT_GENERATOR_TEMPLATE = 'ADDING_EDITING_DOCUMENT_GENERATOR_TEMPLATE';
export const addingEditingDocumentGeneratorTemplate = makeActionCreator(ADDING_EDITING_DOCUMENT_GENERATOR_TEMPLATE, 'status');

export const LOCKING_UNLOCKING_DOCUMENT_GENERATOR_TEMPLATE = 'LOCKING_UNLOCKING_DOCUMENT_GENERATOR_TEMPLATE';
export const lockingUnlockingDocumentGeneratorTemplate = makeActionCreator(LOCKING_UNLOCKING_DOCUMENT_GENERATOR_TEMPLATE, 'status');

export const GENERATING_DOCUMENT_TEMPLATE_PREVIEW = 'GENERATING_DOCUMENT_TEMPLATE_PREVIEW';
export const generatingDocumentTemplatePreview = makeActionCreator(GENERATING_DOCUMENT_TEMPLATE_PREVIEW, 'status');


export const DOWNLOADING_TEMPLATE_PAPER_SIZES = 'DOWNLOADING_TEMPLATE_PAPER_SIZES';
export const downloadingTemplatePaperSizes = makeActionCreator(DOWNLOADING_TEMPLATE_PAPER_SIZES, 'status');

export const SET_TEMPLATE_PAPER_SIZES = 'SET_TEMPLATE_PAPER_SIZES';
export const setTemplatePaperSizes = makeActionCreator(SET_TEMPLATE_PAPER_SIZES, 'data');

export const SET_TEMPLATE_PAGE_SIZE_FIELD_VALUE = 'SET_TEMPLATE_PAGE_SIZE_FIELD_VALUE';
export const setTemplatePaperSizeFieldValue = makeActionCreator(SET_TEMPLATE_PAGE_SIZE_FIELD_VALUE, 'data');


export const DOWNLOADING_DOCUMENT_GENERATOR_DOCUMENTS = 'DOWNLOADING_DOCUMENT_GENERATOR_DOCUMENTS';
export const downloadingDocumentGeneratorDocuments = makeActionCreator(DOWNLOADING_DOCUMENT_GENERATOR_DOCUMENTS, 'status');

export const SET_DOCUMENT_GENERATOR_DOCUMENTS = 'SET_DOCUMENT_GENERATOR_DOCUMENTS';
export const setDocumentGeneratorDocuments = makeActionCreator(SET_DOCUMENT_GENERATOR_DOCUMENTS, 'data');

export const CLEAR_DOCUMENT_GENERATOR_DOCUMENTS = 'CLEAR_DOCUMENT_GENERATOR_DOCUMENTS';
export const clearDocumentGeneratorDocuments = makeActionCreator(
  CLEAR_DOCUMENT_GENERATOR_DOCUMENTS,
);

export const SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_PAGE_SIZE = 'SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_PAGE_SIZE';
export const setDocumentGeneratorDocumentSelectedPageSize = makeActionCreator(SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_PAGE_SIZE, 'size');

export const SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_ORDER_BY = 'SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_ORDER_BY';
export const setDocumentGeneratorDocumentSelectedOrderBy = makeActionCreator(SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_ORDER_BY, 'order');

export const SET_DOCUMENT_GENERATOR_DOCUMENT_TAPPED_ID = 'SET_DOCUMENT_GENERATOR_DOCUMENT_TAPPED_ID';
export const setDocumentGeneratorDocumentTappedId = makeActionCreator(SET_DOCUMENT_GENERATOR_DOCUMENT_TAPPED_ID, 'id');

export const SET_DOCUMENT_GENERATOR_DOCUMENT_SEARCH_TEXT = 'SET_DOCUMENT_GENERATOR_DOCUMENT_SEARCH_TEXT';
export const setDocumentGeneratorDocumentSearchText = makeActionCreator(SET_DOCUMENT_GENERATOR_DOCUMENT_SEARCH_TEXT, 'text');

export const ADD_DOCUMENT_GENERATOR_DOCUMENT = 'ADD_DOCUMENT_GENERATOR_DOCUMENT';
export const addDocumentGeneratorDocument = makeActionCreator(ADD_DOCUMENT_GENERATOR_DOCUMENT, 'data');

export const DOWNLOADING_DELETING_DOCUMENT_GENERATOR_DOCUMENT = 'DOWNLOADING_DELETING_DOCUMENT_GENERATOR_DOCUMENT';
export const downloadingDeletingDocumentGeneratorDocument = makeActionCreator(DOWNLOADING_DELETING_DOCUMENT_GENERATOR_DOCUMENT, 'status');

export const SET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setDocumentAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const DOWNLOADING_DOCUMENT_RESULT = 'DOWNLOADING_DOCUMENT_RESULT';
export const downloadingDocumentResult = makeActionCreator(DOWNLOADING_DOCUMENT_RESULT, 'status');
