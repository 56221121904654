import { downloadingQuizCategories, setQuizCategories } from '../simple-action';
import { downloadQuizCategories, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, quizCategories, uiQuizCategory } = getState();
  const { token } = authentication;
  const { meta, data } = quizCategories;
  const { selectedPageSize, searchBarText, orderBy } = uiQuizCategory;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingQuizCategories(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadQuizCategories(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      token,
    );

    if (list) {
      dispatch(setQuizCategories(list));
    }
  } finally {
    dispatch(downloadingQuizCategories(false));
  }
};
