import { connect } from 'react-redux';
import { MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY } from '../../constant';
import { getChartPercentage } from '../../helper';
import {
  clearSummaryThisMonthLocations, clearSummaryThisMonthPacketTypes,
  clearSummaryThisMonthTopRecipients, clearSummaryThisMonthTrends,
  clearSummaryPreviousMonthLocations, clearSummaryPreviousMonthPacketTypes,
  clearSummaryPreviousMonthTopRecipients, clearSummaryPreviousMonthTrends,
  clearSummaryThisYearLocations, clearSummaryThisYearPacketTypes,
  clearSummaryThisYearTopRecipients, clearSummaryThisYearTrends,
  clearSummaryPreviousYearLocations, clearSummaryPreviousYearPacketTypes,
  clearSummaryPreviousYearTopRecipients, clearSummaryPreviousYearTrends,
  setActiveSideMenuItem, setAlertErrorMessage,
  downloadThisMonthLocationsAsync, downloadThisMonthPacketTypesAsync,
  downloadThisMonthTopRecipientsAsync, downloadThisMonthTrendsAsync,
  downloadPreviousMonthLocationsAsync, downloadPreviousMonthPacketTypesAsync,
  downloadPreviousMonthTopRecipientsAsync, downloadPreviousMonthTrendsAsync,
  downloadThisYearLocationsAsync, downloadThisYearPacketTypesAsync,
  downloadThisYearTopRecipientsAsync, downloadThisYearTrendsAsync,
  downloadPreviousYearLocationsAsync, downloadPreviousYearPacketTypesAsync,
  downloadPreviousYearTopRecipientsAsync, downloadPreviousYearTrendsAsync,
} from '../../redux/action';
import PacketSummaryPage from './packet-summary.presentation';

const ARGUMENT_FIELD = 'key';
const VALUE_FIELD = 'value';

const mapStateToProps = (state) => ({
  downloadingThisMonthTrends: state.uiPacketSummary.downloadingThisMonthTrends,
  downloadingThisMonthTopRecipients: state.uiPacketSummary.downloadingThisMonthTopRecipients,
  downloadingThisMonthPacketTypes: state.uiPacketSummary.downloadingThisMonthPacketTypes,
  downloadingThisMonthLocations: state.uiPacketSummary.downloadingThisMonthLocations,
  downloadingPreviousMonthTrends: state.uiPacketSummary.downloadingPreviousMonthTrends,
  downloadingPreviousMonthTopRecipients: state.uiPacketSummary
    .downloadingPreviousMonthTopRecipients,
  downloadingPreviousMonthPacketTypes: state.uiPacketSummary.downloadingPreviousMonthPacketTypes,
  downloadingPreviousMonthLocations: state.uiPacketSummary.downloadingPreviousMonthLocations,
  downloadingThisYearTrends: state.uiPacketSummary.downloadingThisYearTrends,
  downloadingThisYearTopRecipients: state.uiPacketSummary.downloadingThisYearTopRecipients,
  downloadingThisYearPacketTypes: state.uiPacketSummary.downloadingThisYearPacketTypes,
  downloadingThisYearLocations: state.uiPacketSummary.downloadingThisYearLocations,
  downloadingPreviousYearTrends: state.uiPacketSummary.downloadingPreviousYearTrends,
  downloadingPreviousYearTopRecipients: state.uiPacketSummary.downloadingPreviousYearTopRecipients,
  downloadingPreviousYearPacketTypes: state.uiPacketSummary.downloadingPreviousYearPacketTypes,
  downloadingPreviousYearLocations: state.uiPacketSummary.downloadingPreviousYearLocations,
  thisMonthTrends: state.thisMonthTrends,
  thisMonthTopRecipients: state.thisMonthTopRecipients,
  thisMonthPacketTypes: getChartPercentage(state.thisMonthPacketTypes, ARGUMENT_FIELD, VALUE_FIELD),
  thisMonthLocations: getChartPercentage(state.thisMonthLocations, ARGUMENT_FIELD, VALUE_FIELD),
  previousMonthTrends: state.previousMonthTrends,
  previousMonthTopRecipients: state.previousMonthTopRecipients,
  previousMonthPacketTypes: getChartPercentage(state.previousMonthPacketTypes, ARGUMENT_FIELD,
    VALUE_FIELD),
  previousMonthLocations: getChartPercentage(state.previousMonthLocations, ARGUMENT_FIELD,
    VALUE_FIELD),
  thisYearTrends: state.thisYearTrends,
  thisYearTopRecipients: state.thisYearTopRecipients,
  thisYearPacketTypes: getChartPercentage(state.thisYearPacketTypes, ARGUMENT_FIELD, VALUE_FIELD),
  thisYearLocations: getChartPercentage(state.thisYearLocations, ARGUMENT_FIELD, VALUE_FIELD),
  previousYearTrends: state.previousYearTrends,
  previousYearTopRecipients: state.previousYearTopRecipients,
  previousYearPacketTypes: getChartPercentage(state.previousYearPacketTypes, ARGUMENT_FIELD,
    VALUE_FIELD),
  previousYearLocations: getChartPercentage(state.previousYearLocations, ARGUMENT_FIELD,
    VALUE_FIELD),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY));
    dispatch(clearSummaryThisMonthTrends());
    dispatch(clearSummaryThisMonthTopRecipients());
    dispatch(clearSummaryThisMonthPacketTypes());
    dispatch(clearSummaryThisMonthLocations());
    dispatch(clearSummaryPreviousMonthTrends());
    dispatch(clearSummaryPreviousMonthTopRecipients());
    dispatch(clearSummaryPreviousMonthPacketTypes());
    dispatch(clearSummaryPreviousMonthLocations());
    dispatch(clearSummaryThisYearTrends());
    dispatch(clearSummaryThisYearTopRecipients());
    dispatch(clearSummaryThisYearPacketTypes());
    dispatch(clearSummaryThisYearLocations());
    dispatch(clearSummaryPreviousYearTrends());
    dispatch(clearSummaryPreviousYearTopRecipients());
    dispatch(clearSummaryPreviousYearPacketTypes());
    dispatch(clearSummaryPreviousYearLocations());
    dispatch(downloadThisMonthTrendsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadThisMonthTopRecipientsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadThisMonthPacketTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadThisMonthLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousMonthTrendsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousMonthTopRecipientsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousMonthPacketTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousMonthLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadThisYearTrendsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadThisYearTopRecipientsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadThisYearPacketTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadThisYearLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousYearTrendsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousYearTopRecipientsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousYearPacketTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadPreviousYearLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PacketSummaryPage);
