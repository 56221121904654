import {
  getAnalyticsActiveUserChartData,
} from '../../../helper';
import {
  downloadingAnalyticsActiveUserChartData,
  setAnalyticsActiveUserChartData,
  setAnalyticsTotalActiveUser,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsActiveUserChartData(true));

    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { token } = authentication;
    const { activeUserDateRange } = uiAnalytics;

    const requestChartBody = {
      dimensions: [
        {
          name: 'country',
        },
        {
          name: 'nthDay',
        },
      ],
      metrics: [
        {
          name: 'activeUsers',
        },
      ],
      dateRanges: [
        {
          startDate: activeUserDateRange.from,
          endDate: activeUserDateRange.to,
        },
      ],
      orderBys: [
        {
          dimension: {
            orderType: 'ALPHANUMERIC',
            dimensionName: 'nthDay',
          },
        },
      ],
      metricAggregations: [
        'TOTAL',
      ],
    };

    const chartData = await getAnalyticsActiveUserChartData(
      myConfigItems?.core?.googleAnalyticsPropertyId, requestChartBody, token,
    );
    const charDataArray = Object.values(chartData);
    dispatch(setAnalyticsActiveUserChartData(charDataArray[0]));
    dispatch(setAnalyticsTotalActiveUser(charDataArray[1].totalUser));
  } finally {
    dispatch(downloadingAnalyticsActiveUserChartData(false));
  }
};
