import {
  reset,
} from 'redux-form';
import {
  addingEditingAnnotationDesign,
  addAnnotationDesignList,
  setPDFTemplate,
  setFunctionalPageMode,
} from '../simple-action';
import { RXFORM_ANNOTATION_DESIGNER, PAGE_MODE_VIEW } from '../../../constant';
import { editAnnotation, submitAnnotation } from '../../../helper';

export default (submitValue) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingAnnotationDesign(true));

    const { token } = getState().authentication;
    const { data } = getState().annotationDesigns;
    const { tappedId } = getState().uiAnnotationDesignList;
    const { templatePdf } = getState().uiUploadPdf;

    const bodyAdd = ({
      name: submitValue.name,
      description: submitValue.description,
      pdfPath: templatePdf,
      annotations: data,
    });

    const bodyEdit = ({
      id: tappedId,
      name: submitValue.name,
      description: submitValue.description,
      pdfPath: templatePdf,
      annotations: data,
    });

    const processAnnotation = async () => {
      if (tappedId === '') {
        const responseAdd = await submitAnnotation(
          bodyAdd,
          token,
        );
        return responseAdd;
      }

      const responseEdit = await editAnnotation(
        bodyEdit,
        token,
      );
      return responseEdit;
    };
    const response = await processAnnotation();

    dispatch(addAnnotationDesignList(response));
  } finally {
    dispatch(setPDFTemplate(''));
    dispatch(reset(RXFORM_ANNOTATION_DESIGNER));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    dispatch(addingEditingAnnotationDesign(false));
  }
};
