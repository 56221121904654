import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearActivityTypes, setActiveSideMenuItem, setActivityTypeSearchText,
  setActivityTypeSelectedPageSize, setActivityTypeSelectedOrderBy,
  setActivityTypeTappedId, setAlertConfirmationMessage, setAlertErrorMessage,
  addEditActivityTypeAsync, downloadActivityTypesAsync, downloadDeleteActivityTypeAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_CUSTOMERS, MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE, PAGE_MODE_TABLE,
  RXFORM_ACTIVITY_TYPE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../../../localization';
import ActivityTypePage from './activity-type.presentation';

const getInitialValues = (state) => {
  const { activityTypes, uiActivityType, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiActivityType;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? activityTypes.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0 ? transformInitialValues(found) : {};
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiActivityType.addingEditing,
  downloading: state.uiActivityType.downloading,
  downloadingDeleting: state.uiActivityType.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE));
    dispatch(setActivityTypeSearchText(''));
    dispatch(clearActivityTypes());
    dispatch(setActivityTypeSelectedPageSize(20));
    dispatch(setActivityTypeSelectedOrderBy(INITIAL_ORDER_BY_CUSTOMERS));
    dispatch(downloadActivityTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_ACTIVITY_TYPE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadActivityTypesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setActivityTypeSelectedPageSize(pageSize));
    dispatch(downloadActivityTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteActivityTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setActivityTypeTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setActivityTypeTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setActivityTypeTappedId(id));
    dispatch(downloadDeleteActivityTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setActivityTypeSelectedPageSize(pageSize));
    dispatch(clearActivityTypes());
    dispatch(downloadActivityTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditActivityTypeAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setActivityTypeSearchText(text));
      dispatch(clearActivityTypes());
      await dispatch(downloadActivityTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setActivityTypeSelectedOrderBy(orderBy));
    dispatch(clearActivityTypes());
    dispatch(downloadActivityTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditActivityTypeAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setActivityTypeTappedId(id));
    dispatch(downloadDeleteActivityTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTypePage);
