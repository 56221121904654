import { loggingOut, clearAllStates } from '../simple-action';
import { logout, removeAllStorage } from '../../../helper';
import { ROUTE_NAME_LOGIN } from '../../../constant';

export default (navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(loggingOut(true));
    const { token } = getState().authentication;
    await logout(token);

    navigateTo(ROUTE_NAME_LOGIN);
  } finally {
    await removeAllStorage();
    dispatch(clearAllStates());
    dispatch(loggingOut(false));
  }
};
