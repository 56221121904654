import {
  DOWNLOADING_SMSES, DOWNLOADING_DELETING_SMS, RESENDING_SMS, SET_SMS_SELECTED_PAGE_SIZE,
  SET_SMS_TAPPED_ID, SET_SMS_SEARCH_TEXT, SET_SMS_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_SMSES } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  resending: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SMSES,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SMSES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_SMS:
      return { ...state, downloadingDeleting: action.status };
    case RESENDING_SMS:
      return { ...state, resending: action.status };
    case SET_SMS_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SMS_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SMS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SMS_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
