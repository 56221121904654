import { reset } from 'redux-form';
import {
  editingPromotedMerchant, clearPromotedMerchants, setAlertErrorMessage,
  setPromotedMerchantSelectedIsPromoted, setFunctionalPageMode,
} from '../simple-action';
import { RXFORM_PROMOTED_MERCHANT, PAGE_MODE_TABLE } from '../../../constant';
import { editPromotedMerchant, toNumber } from '../../../helper';
import downloadPromotedMerchantsAsync from './downloadPromotedMerchantsAsync';

export default (order) => async (dispatch, getState) => {
  try {
    dispatch(editingPromotedMerchant(true));

    const { token } = getState().authentication;
    const { selectedIsPromoted, tappedId } = getState().uiPromotedMerchant;
    const isPromoted = selectedIsPromoted ? selectedIsPromoted.value : false;

    await editPromotedMerchant(tappedId, isPromoted, toNumber(order), token);
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));

    dispatch(reset(RXFORM_PROMOTED_MERCHANT));
    dispatch(clearPromotedMerchants());
    dispatch(downloadPromotedMerchantsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setPromotedMerchantSelectedIsPromoted(null));
  } finally {
    dispatch(editingPromotedMerchant(false));
  }
};
