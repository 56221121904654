
import { INITIAL_ORDER_BY_PROMOTED_MERCHANTS } from '../../constant';
import {
  DOWNLOADING_PROMOTED_MERCHANT,
  DOWNLOADING_PROMOTED_MERCHANTS, SET_PROMOTED_MERCHANT_SEARCH_TEXT,
  SET_PROMOTED_MERCHANT_SELECTED_ORDER_BY,
  SET_PROMOTED_MERCHANT_SELECTED_PAGE_SIZE,
  SET_PROMOTED_MERCHANT_TAPPED_ID,
  SET_PROMOTED_MERCHANT_SELECTED_IS_PROMOTED,
  EDITING_PROMOTED_MERCHANT,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PROMOTED_MERCHANTS,
  selectedIsPromoted: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PROMOTED_MERCHANTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_PROMOTED_MERCHANT:
      return { ...state, downloadingDeleting: action.status };
    case EDITING_PROMOTED_MERCHANT:
      return { ...state, addingEditing: action.status };
    case SET_PROMOTED_MERCHANT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PROMOTED_MERCHANT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PROMOTED_MERCHANT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PROMOTED_MERCHANT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PROMOTED_MERCHANT_SELECTED_IS_PROMOTED:
      return { ...state, selectedIsPromoted: action.value };
    default: return state;
  }
};
