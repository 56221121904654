import { reset } from 'redux-form';
import {
  addingEditingNewsCategory, clearNewsCategories, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addNewsCategory, editNewsCategory, transformLocalImagePath } from '../../../helper';
import { RXFORM_NEWS_CATEGORY, PAGE_MODE_TABLE } from '../../../constant';
import downloadNewsCategoriesAsync from './downloadNewsCategoriesAsync';

export default (name, description, image) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingNewsCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiNewsCategory;

    if (tappedId) {
      await editNewsCategory(tappedId, name, description, transformLocalImagePath(image), token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addNewsCategory(name, description, transformLocalImagePath(image), token);
    }

    dispatch(reset(RXFORM_NEWS_CATEGORY));
    dispatch(clearNewsCategories());
    dispatch(downloadNewsCategoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingNewsCategory(false));
  }
};
