import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  INITIAL_ORDER_BY_MODELS, PAGE_MODE_TABLE,
  RXFORM_MODEL, MENUID_CAR_CATALOG_MODEL, RXFIELD_MODEL_IMAGE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearModels, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setModelSearchText, setModelSelectedPageSize, setModelSelectedOrderBy,
  setModelTappedId, addEditModelAsync, downloadDeleteModelAsync, downloadModelsAsync,
} from '../../redux/action';
import ModelPage from './model.presentation';

const getInitialValues = (state) => {
  const { models, uiFunctionalPage, uiModel } = state;
  const { data } = models;
  const { downloadingDeleting, tappedId } = uiModel;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found,
    {
      name: found.name ?? '',
      description: found.description ?? '',
      brochureUrl: found.brochureUrl ?? '',
      image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
    })
    : {
      name: '',
      description: '',
      brochureUrl: '',
      image: null,
    };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiModel.addingEditing,
  downloading: state.uiModel.downloading,
  downloadingDeleting: state.uiModel.downloadingDeleting,
  tappedId: state.uiModel.tappedId || '',
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_CATALOG_MODEL));
    dispatch(setModelSearchText(''));
    dispatch(clearModels());
    dispatch(setModelSelectedPageSize(20));
    dispatch(setModelSelectedOrderBy(INITIAL_ORDER_BY_MODELS));
    dispatch(downloadModelsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_MODEL));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadModelsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setModelSelectedPageSize(pageSize));
    dispatch(downloadModelsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteModelAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setModelTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setModelTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setModelTappedId(id));
    dispatch(downloadDeleteModelAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setModelSelectedPageSize(pageSize));
    dispatch(clearModels());
    dispatch(downloadModelsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: ({
    name, description, brochureUrl, image,
  }) => {
    dispatch(addEditModelAsync(name, description, brochureUrl, image))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setModelSearchText(text));
    dispatch(clearModels());
    dispatch(downloadModelsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setModelSelectedOrderBy(orderBy));
    dispatch(clearModels());
    dispatch(downloadModelsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: ({
    name, description, brochureUrl, image,
  }) => {
    dispatch(addEditModelAsync(name, description, brochureUrl, image))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setModelTappedId(id));
    dispatch(downloadDeleteModelAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_MODEL, RXFIELD_MODEL_IMAGE, image));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelPage);
