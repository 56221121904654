import { transformSearchText, downloadAttendanceCorrections } from '../../../helper';
import { downloadingAttendanceCorrections, setAttendanceCorrections } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, attendanceCorrections, uiAttendanceCorrection } = getState();
  const { token } = authentication;
  const { meta } = attendanceCorrections;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiAttendanceCorrection;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingAttendanceCorrections(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAttendanceCorrections(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setAttendanceCorrections(list));
  } finally {
    dispatch(downloadingAttendanceCorrections(false));
  }
};
