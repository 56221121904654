import {
  addCompanyMapping, clearCompanyMappings, downloadingDeletingCompanyMapping, setAlertErrorMessage,
  setFunctionalPageMode, setCompanyMappingTappedId,
} from '../simple-action';
import { downloadCompanyMapping, deleteCompanyMapping } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadCompanyMappingsAsync from './downloadCompanyMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCompanyMapping(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCompanyMapping;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteCompanyMapping(tappedId, token);
      dispatch(setCompanyMappingTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCompanyMappings());
      dispatch(downloadCompanyMappingsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const companyMap = await downloadCompanyMapping(tappedId, token);
      dispatch(addCompanyMapping(companyMap));
    }
  } finally {
    dispatch(downloadingDeletingCompanyMapping(false));
  }
};
