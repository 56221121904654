import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_ARTICLES = 'DOWNLOADING_ARTICLES';
export const downloadingArticles = makeActionCreator(DOWNLOADING_ARTICLES, 'status');

export const SET_ARTICLES = 'SET_ARTICLES';
export const setArticles = makeActionCreator(SET_ARTICLES, 'data');

export const ADD_ARTICLE = 'ADD_ARTICLE';
export const addArticle = makeActionCreator(ADD_ARTICLE, 'data');

export const CLEAR_ARTICLES = 'CLEAR_ARTICLES';
export const clearArticles = makeActionCreator(CLEAR_ARTICLES);

export const ADDING_EDITING_ARTICLE = 'ADDING_EDITING_ARTICLE';
export const addingEditingArticle = makeActionCreator(ADDING_EDITING_ARTICLE, 'status');

export const DOWNLOADING_DELETING_ARTICLE = 'DOWNLOADING_DELETING_ARTICLE';
export const downloadingDeletingArticle = makeActionCreator(DOWNLOADING_DELETING_ARTICLE, 'status');

export const SENDING_ARTICLE_NOTIFICATION = 'SENDING_ARTICLE_NOTIFICATION';
export const sendingArticleNotification = makeActionCreator(SENDING_ARTICLE_NOTIFICATION, 'status');

export const COPYING_ARTICLE_DEEP_LINK = 'COPYING_ARTICLE_DEEP_LINK';
export const copyingArticleDeepLink = makeActionCreator(COPYING_ARTICLE_DEEP_LINK, 'status');

export const SAVING_ARTICLES = 'SAVING_ARTICLES';
export const savingArticles = makeActionCreator(SAVING_ARTICLES, 'status');


export const SET_ARTICLES_SELECTED_PAGE_SIZE = 'SET_ARTICLES_SELECTED_PAGE_SIZE';
export const setArticlesSelectedPageSize = makeActionCreator(SET_ARTICLES_SELECTED_PAGE_SIZE, 'size');

export const SET_ARTICLES_SELECTED_ORDER_BY = 'SET_ARTICLES_SELECTED_ORDER_BY';
export const setArticlesSelectedOrderBy = makeActionCreator(SET_ARTICLES_SELECTED_ORDER_BY, 'order');

export const SET_ARTICLE_TAPPED_ID = 'SET_ARTICLE_TAPPED_ID';
export const setArticleTappedId = makeActionCreator(SET_ARTICLE_TAPPED_ID, 'id');

export const SET_ARTICLES_SEARCH_TEXT = 'SET_ARTICLES_SEARCH_TEXT';
export const setArticlesSearchText = makeActionCreator(SET_ARTICLES_SEARCH_TEXT, 'text');

export const SET_ARTICLE_SELECTED_CATEGORY = 'SET_ARTICLE_SELECTED_CATEGORY';
export const setArticleSelectedCategory = makeActionCreator(SET_ARTICLE_SELECTED_CATEGORY, 'option');

export const SET_ARTICLE_SELECTED_VISIBILITY_STATUS = 'SET_ARTICLE_SELECTED_VISIBILITY_STATUS';
export const setArticleSelectedVisibilityStatus = makeActionCreator(SET_ARTICLE_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_ARTICLE_SELECTED_ADD_MEDIA_MENU = 'SET_ARTICLE_SELECTED_ADD_MEDIA_MENU';
export const setArticleSelectedAddMediaMenu = makeActionCreator(SET_ARTICLE_SELECTED_ADD_MEDIA_MENU, 'text');


export const DOWNLOADING_ARTICLE_CATEGORIES = 'DOWNLOADING_ARTICLE_CATEGORIES';
export const downloadingArticleCategories = makeActionCreator(DOWNLOADING_ARTICLE_CATEGORIES, 'status');

export const SET_ARTICLE_CATEGORIES = 'SET_ARTICLE_CATEGORIES';
export const setArticleCategories = makeActionCreator(SET_ARTICLE_CATEGORIES, 'data');

export const ADD_ARTICLE_CATEGORY = 'ADD_ARTICLE_CATEGORY';
export const addArticleCategory = makeActionCreator(ADD_ARTICLE_CATEGORY, 'data');

export const CLEAR_ARTICLE_CATEGORIES = 'CLEAR_ARTICLE_CATEGORIES';
export const clearArticleCategories = makeActionCreator(CLEAR_ARTICLE_CATEGORIES);

export const ADDING_EDITING_ARTICLE_CATEGORY = 'ADDING_EDITING_ARTICLE_CATEGORY';
export const addingEditingArticleCategory = makeActionCreator(ADDING_EDITING_ARTICLE_CATEGORY, 'status');

export const DOWNLOADING_DELETING_ARTICLE_CATEGORY = 'DOWNLOADING_DELETING_ARTICLE_CATEGORY';
export const downloadingDeletingArticleCategory = makeActionCreator(DOWNLOADING_DELETING_ARTICLE_CATEGORY, 'status');


export const SET_ARTICLE_CATEGORY_SELECTED_PAGE_SIZE = 'SET_ARTICLE_CATEGORY_SELECTED_PAGE_SIZE';
export const setArticleCategorySelectedPageSize = makeActionCreator(SET_ARTICLE_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_ARTICLE_CATEGORY_SELECTED_ORDER_BY = 'SET_ARTICLE_CATEGORY_SELECTED_ORDER_BY';
export const setArticleCategorySelectedOrderBy = makeActionCreator(SET_ARTICLE_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_ARTICLE_CATEGORY_TAPPED_ID = 'SET_ARTICLE_CATEGORY_TAPPED_ID';
export const setArticleCategoryTappedId = makeActionCreator(SET_ARTICLE_CATEGORY_TAPPED_ID, 'id');

export const SET_ARTICLE_CATEGORY_SEARCH_TEXT = 'SET_ARTICLE_CATEGORY_SEARCH_TEXT';
export const setArticleCategorySearchText = makeActionCreator(SET_ARTICLE_CATEGORY_SEARCH_TEXT, 'text');
