import { downloadingProfiles, setProfiles } from '../simple-action';
import { downloadProfiles, transformSearchText } from '../../../helper';

export default (selectedPageNo, isAll) => async (dispatch, getState) => {
  const { authentication, profiles, uiProfile } = getState();
  const { token } = authentication;
  const { meta } = profiles;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiProfile;

  const searchText = transformSearchText(searchBarText);
  const sortBy = orderBy.includes('email') ? `user.email ${orderBy.match('[^ ]* (.*)')[1]}` : orderBy;

  try {
    dispatch(downloadingProfiles(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProfiles(
      pageToBeDownloaded,
      selectedPageSize,
      sortBy,
      searchText,
      filterString,
      token,
    );

    if (isAll) {
      const result = { ...list };

      for (let i = list.meta.currentPage; i <= list.meta.totalPages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const nextList = await downloadProfiles(
          i,
          50,
          orderBy,
          searchText,
          token,
        );

        const finalList = {
          data: { ...result.data, ...nextList.data },
          meta: nextList.meta,
        };

        Object.assign(result, finalList);
        dispatch(setProfiles(result));
      }
    } else {
      dispatch(setProfiles(list));
    }
  } finally {
    dispatch(downloadingProfiles(false));
  }
};
