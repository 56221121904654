import { addEmail, resendingEmail } from '../simple-action';
import { resendEmail, downloadEmail } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingEmail(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEmail;

    await resendEmail(tappedId, token);
    const result = await downloadEmail(tappedId, token);

    dispatch(addEmail(result));
  } finally {
    dispatch(resendingEmail(false));
  }
};
