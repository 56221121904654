import { connect } from 'react-redux';
import CustomErrorMessagesScreen from './custom-error-messages.presentation';
import { getNDaysAgo, toKFormat, toMoment } from '../../../../helper/helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  downloadAnalyticsApplicationErrorsAsync, setAlertErrorMessage,
  setAnalyticsCustomMessagesDateRanges, setCustomErrorMessageFilterDialogVisibility,
} from '../../redux/action';
import { getProgresssBarChartPercentage } from '../../helper';

const getFilterDateRange = (dateRange) => {
  let dateFrom = dateRange.from;
  let dateTo = dateRange.to;

  if (dateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (dateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const getCustomErrorMessagesData = (state) => {
  const { customErrorMessages } = state.analytics;
  const totalEvent = customErrorMessages?.totalEvent || 0;
  const data = customErrorMessages?.dataArray?.map((message) => ({
    ...message,
    count: toKFormat(message.eventCount),
    percentage: getProgresssBarChartPercentage(message.eventCount, totalEvent),
  }));

  return {
    data,
    totalEvent,
    totalUser: customErrorMessages.totalUser || 0,
  };
};

const mapStateToProps = (state) => ({
  customErrorMessages: getCustomErrorMessagesData(state),
  downloadingCustomErrorMessages: state.uiAnalytics.downloadingCustomErrorMessages,
  customErrorMessageDateRange:
    getFilterDateRange(state.uiAnalytics.customErrorMessageDateRange),
  customErrorMessageFilterDialogVisibility:
        state.uiAnalytics.customErrorMessageFilterDialogVisibility,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterIconPressed: () => {
    dispatch(setCustomErrorMessageFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setCustomErrorMessageFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsCustomMessagesDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsApplicationErrorsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setCustomErrorMessageFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsCustomMessagesDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsApplicationErrorsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setCustomErrorMessageFilterDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomErrorMessagesScreen);
