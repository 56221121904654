import {
  RXFIELD_COVID_DATA_DATE,
  RXFIELD_COVID_DATA_EMPLOYEE_STATUS,
  RXFIELD_COVID_DATA_HOSPITALIZED,
  RXFIELD_COVID_DATA_SELF_ISOLATION,
  RXFIELD_COVID_DATA_DEATH,
  RXFIELD_COVID_DATA_RECOVERED,
  RXFIELD_COVID_DATA_VACCINATED,
  RXFIELD_COVID_DATA_RT_ANTIBODY,
  RXFIELD_COVID_DATA_RT_ANTIGEN,
  RXFIELD_COVID_DATA_GENOSE,
  RXFIELD_COVID_DATA_SEROLOGI,
  RXFIELD_COVID_DATA_PCR,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';

// eslint-disable-next-line import/prefer-default-export
export const rxFormValidateCovidData = (values) => {
  const requiredFields = [
    RXFIELD_COVID_DATA_DATE,
    RXFIELD_COVID_DATA_EMPLOYEE_STATUS,
    RXFIELD_COVID_DATA_HOSPITALIZED,
    RXFIELD_COVID_DATA_SELF_ISOLATION,
    RXFIELD_COVID_DATA_DEATH,
    RXFIELD_COVID_DATA_RECOVERED,
    RXFIELD_COVID_DATA_VACCINATED,
    RXFIELD_COVID_DATA_RT_ANTIBODY,
    RXFIELD_COVID_DATA_RT_ANTIGEN,
    RXFIELD_COVID_DATA_GENOSE,
    RXFIELD_COVID_DATA_SEROLOGI,
    RXFIELD_COVID_DATA_PCR,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  return errors;
};
