
import { INITIAL_ORDER_BY_FORUM_POSTS } from '../../constant';
import {
  ADDING_EDITING_FORUM_POST, DOWNLOADING_DELETING_FORUM_POST, DOWNLOADING_FORUM_POSTS,
  SET_FORUM_POST_SEARCH_TEXT, SET_FORUM_POST_SELECTED_ORDER_BY,
  SET_FORUM_POST_SELECTED_PAGE_SIZE, SET_FORUM_POST_TAPPED_ID,
  SET_FORUM_POST_SELECTED_ADD_MEDIA_MENU, SET_FORUM_POST_SELECTED_CATEGORY,
  SET_FORUM_POST_SELECTED_LOCKED, SET_FORUM_POST_SELECTED_PINNED,
  SET_FORUM_POST_SELECTED_CONTENT_FORMAT,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_FORUM_POSTS,
  selectedAddMediaMenu: null,
  selectedCategory: '',
  selectedLocked: '',
  selectedPinned: '',
  selectedContentFormat: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_FORUM_POSTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_FORUM_POST:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_FORUM_POST:
      return { ...state, downloadingDeleting: action.status };
    case SET_FORUM_POST_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_FORUM_POST_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FORUM_POST_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_FORUM_POST_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_FORUM_POST_SELECTED_ADD_MEDIA_MENU:
      return { ...state, selectedAddMediaMenu: action.text };
    case SET_FORUM_POST_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.option };
    case SET_FORUM_POST_SELECTED_LOCKED:
      return { ...state, selectedLocked: action.option };
    case SET_FORUM_POST_SELECTED_PINNED:
      return { ...state, selectedPinned: action.option };
    case SET_FORUM_POST_SELECTED_CONTENT_FORMAT:
      return { ...state, selectedContentFormat: action.option };
    default: return state;
  }
};
