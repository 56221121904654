import { RXFIELD_PARTNER_MERCHANT_COMPANY } from './constant';
import { rxFormValidateRequiredFields } from '../../validation';

// eslint-disable-next-line import/prefer-default-export
export const rxFormValidatePartnerMerchant = (values) => {
  const requiredFields = [
    RXFIELD_PARTNER_MERCHANT_COMPANY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  return errors;
};
