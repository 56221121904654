import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearBranches, clearCompanies, clearDepartments, clearDivisions, clearManagers, clearProfiles,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText,
  setCompanySearchText, setDepartmentSearchText, setDivisionSearchText,
  setNotificationSelectedBranch, setNotificationSelectedCompany, setNotificationSelectedDepartment,
  setNotificationSelectedDivision, setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileManagerSearchText, setProfileSearchText, setProfileSelectedPageSize,
  setProfileSelectedBranchId, setProfileSelectedCompany, setProfileSelectedDepartment,
  setProfileSelectedDivision, setProfileSelectedManager, setProfileSelectedOrderBy,
  setProfileSelectedTimezone, setProfileTappedId,
  downloadBranchesAsync, downloadCompaniesAsync, downloadDepartmentsAsync, downloadDivisionsAsync,
  downloadManagersAsync, downloadProfileAsync, downloadProfilesAsync, editProfileAsync,
  enableDisableProfileAsync, saveProfilesAsync, sendProfileNotificationAsync,
  setDownloadRequestTimeoutDialogVisibility,
} from '../../redux/action';
import {
  DROPDOWN_OPTION_CLEAR, INITIAL_ORDER_BY_PROFILES, MENUID_PERSONALIZATION_PROFILE, PAGE_MODE_TABLE,
  RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, RXFIELD_PROFILE_BRANCH,
  RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, RXFIELD_PROFILE_END_WORKING_HOUR,
  RXFIELD_PROFILE_START_WORKING_HOUR, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE, RXFORM_PROFILE,
  TIME_MODE_CLOCK_IN, TIME_MODE_CLOCK_OUT,
} from '../../constant';
import {
  convertClockInOutMinutesToHours, convertClockInOutHoursToMinutes, transformDropdownData,
  transformInitialValues, toNumber,
} from '../../helper';
import ProfilePage from './profile.presentation';

import { downloadMyConfigItemsAsync } from '../../module/configuration/redux/action';

const getAddressRegion = (address) => {
  if (!address) return '';
  const { city, district, subDistrict } = address;
  if (city) {
    return `${city.name} - ${city.province.name}`;
  }
  if (district) {
    return `${district.name} - ${district.city.name} - ${district.city.province.name}`;
  }
  if (subDistrict) {
    return `${subDistrict.name} - ${subDistrict.district.name} - ${subDistrict.district.city.name} - ${subDistrict.district.city.province.name}`;
  }
  return '';
};

const getInitialValues = (state) => {
  const { profiles, uiProfile, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiProfile;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? profiles.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        manager: found.manager ? found.manager.fullName : '',
        branch: found.branch ? { label: found.branch.name, value: found.branch.id } : null,
        gender: { label: found.gender, value: found.gender },
        workingHourTimeZone: found.workingHourTimeZone !== null ? found.workingHourTimeZone : '',
        earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
          found.earliestClockInDriftTimeMinutes, found.startWorkingHour, TIME_MODE_CLOCK_IN,
        ),
        autoClockOutAfterMinutes: convertClockInOutHoursToMinutes(
          found.autoClockOutAfterMinutes, found.endWorkingHour, TIME_MODE_CLOCK_OUT,
        ),
        homeAddressRegion: getAddressRegion(found.homeAddress),
        workAddressRegion: getAddressRegion(found.workAddress),
      })
    : {
      gender: { label: '', value: '' },
      branch: { label: '', value: '' },
      workingHourTimeZone: '',
      homeAddressRegion: '',
      workAddressRegion: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  branches: transformDropdownData(state.branches.data),
  companies: transformDropdownData(state.companies.data),
  departments: transformDropdownData(state.departments.data),
  divisions: transformDropdownData(state.divisions.data),
  managers: transformDropdownData(state.managers.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiProfile.addingEditing,
  downloading: state.uiProfile.downloading,
  enablingDisabling: state.uiProfile.enablingDisabling,
  loadingBranch: state.uiBranch.downloading,
  loadingCompanies: state.notification.downloadingCompanies,
  loadingDepartments: state.notification.downloadingDivisions,
  loadingDivisions: state.notification.downloadingDepartments,
  loadingManagers: state.uiProfile.downloadingManagers,
  sendingNotification: state.uiProfile.sendingNotification,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentProfileStatus: state.profiles.data[state.uiFunctionalPage.tappedId]
    ? state.profiles.data[state.uiFunctionalPage.tappedId].status : '',
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedDepartment(''));
    dispatch(setProfileSelectedDivision(''));
    dispatch(setProfileSelectedManager(''));
    dispatch(clearBranches());
    dispatch(clearCompanies());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(clearManagers());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadManagersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_PERSONALIZATION_PROFILE));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedPageSize(20));
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('|', '').replace(/branch/, 'branch.id').replace(/manager/, 'manager.id');
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAutoClockOutChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_AUTO_CLOCK_OUT_AFTER_MINUTES, ''));
    }
  },
  onBranchOptionSelected: (option, changeForm = true) => {
    if (option) {
      dispatch(setProfileSelectedBranchId(option.value));
      if (changeForm) { dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_BRANCH, option)); }
    } else {
      dispatch(setProfileSelectedBranchId(''));
      if (changeForm) { dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_BRANCH, '')); }
    }
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedDepartment(''));
    dispatch(setProfileSelectedDivision(''));
    dispatch(setProfileSelectedManager(''));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PROFILE));
  },
  onChangeBranchText: async (text) => {
    try {
      dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setBranchSearchText(text));
      dispatch(clearBranches());
      await dispatch(downloadBranchesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanySearchText(text));
      dispatch(clearCompanies());
      await dispatch(downloadCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDepartmentText: async (text) => {
    try {
      dispatch(setDepartmentSearchText(text));
      dispatch(clearDepartments());
      await dispatch(downloadDepartmentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDivisionText: async (text) => {
    try {
      dispatch(setDivisionSearchText(text));
      dispatch(clearDivisions());
      await dispatch(downloadDivisionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeManagerText: async (text) => {
    try {
      dispatch(setProfileManagerSearchText(text));
      dispatch(clearManagers());
      await dispatch(downloadManagersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadProfilesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setProfileSelectedPageSize(pageSize));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCompanyOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedCompany(option.value));
    } else {
      dispatch(setProfileSelectedCompany(''));
    }
  },
  onConfirmEnableDisablePressed: () => {
    dispatch(enableDisableProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDepartmentOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedDepartment(option.value));
    } else {
      dispatch(setProfileSelectedDepartment(''));
    }
  },
  onDivisionOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedDivision(option.value));
    } else {
      dispatch(setProfileSelectedDivision(''));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveProfilesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEarliestClockInChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, ''));
    }
  },
  onEditPressed: (id) => {
    dispatch(setProfileTappedId(id));
    dispatch(downloadProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setProfileTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onEndWorkingHourChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_END_WORKING_HOUR, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_END_WORKING_HOUR, ''));
    }
  },
  onManagerOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedManager(option.value));
    } else {
      dispatch(setProfileSelectedManager(''));
    }
  },
  onOpenSendNotificationDialog: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(setCompanySearchText(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setDivisionSearchText(''));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setNotificationSelectedDepartment(''));
    dispatch(setNotificationSelectedDivision(''));
    dispatch(clearBranches());
    dispatch(clearCompanies());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setProfileSelectedPageSize(pageSize));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedDepartment(''));
    dispatch(setProfileSelectedDivision(''));
    dispatch(setProfileSelectedManager(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    startWorkingHour, startWorkingHourMarginBeforeMinutes, startWorkingHourMarginAfterMinutes,
    endWorkingHour, endWorkingHourMarginBeforeMinutes, endWorkingHourMarginAfterMinutes,
    earliestClockInDriftTimeMinutes, autoClockOutAfterMinutes,
  }) => {
    try {
      await dispatch(editProfileAsync(startWorkingHour,
        toNumber(startWorkingHourMarginBeforeMinutes), toNumber(startWorkingHourMarginAfterMinutes),
        endWorkingHour, toNumber(endWorkingHourMarginBeforeMinutes),
        toNumber(endWorkingHourMarginAfterMinutes),
        convertClockInOutMinutesToHours(earliestClockInDriftTimeMinutes, startWorkingHour,
          endWorkingHour, TIME_MODE_CLOCK_IN),
        convertClockInOutMinutesToHours(autoClockOutAfterMinutes, startWorkingHour,
          endWorkingHour, TIME_MODE_CLOCK_OUT)));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationPressed: async (values) => {
    await dispatch(sendProfileNotificationAsync(values));
  },
  onSortPressed: (orderBy) => {
    dispatch(setProfileSelectedOrderBy(orderBy));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onStartWorkingHourChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_START_WORKING_HOUR, date));
    } else {
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_START_WORKING_HOUR, ''));
    }
  },
  onTimeZoneOptionSelected: (option) => {
    if (option !== DROPDOWN_OPTION_CLEAR) {
      dispatch(setProfileSelectedTimezone(option));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE, option));
    } else {
      dispatch(setProfileSelectedTimezone(''));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_WORKING_HOUR_TIMEZONE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setProfileTappedId(id));
    dispatch(downloadProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
