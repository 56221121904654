import { connect } from 'react-redux';
import DownloadRequestTimeoutDialog from './download-request-timeout-dialog.presentation';
import { setDownloadRequestTimeoutDialogVisibility } from '../../redux/action';

const mapStateToProps = (state) => ({
  isVisible: state.requestTimeout.isVisible,
  navigateTo: state.requestTimeout.navigateTo,
});

const mapDispatchToProps = (dispatch) => ({
  onNavigatePressed: (history, navigateTo) => {
    history.push(navigateTo);
    dispatch(setDownloadRequestTimeoutDialogVisibility(false));
  },
  onCancelPressed: () => {
    dispatch(setDownloadRequestTimeoutDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadRequestTimeoutDialog);
