import { downloadCovidDataTrendSummary } from '../../../helper';
import {
  downloadingCovidDataTrendSummary, setCovidDataTrendSummary,
  clearCovidDataTrendSummary,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication, uiCovidDashboard } = getState();
  const { token } = authentication;
  const { selectedSummaryPeriod, selectedEmployeeStatus } = uiCovidDashboard;

  try {
    dispatch(downloadingCovidDataTrendSummary(true));
    const response = await downloadCovidDataTrendSummary(
      selectedSummaryPeriod, selectedEmployeeStatus, token,
    );
    dispatch(clearCovidDataTrendSummary());
    dispatch(setCovidDataTrendSummary(response));
  } finally {
    dispatch(downloadingCovidDataTrendSummary(false));
  }
};
