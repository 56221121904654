import { SET_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES, CLEAR_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES } from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES: {
      return action.data;
    }
    case CLEAR_SUMMARY_PREVIOUS_YEAR_PACKET_TYPES:
      return state;
    default:
      return state;
  }
};
