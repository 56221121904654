import { downloadingNotificationTaskTotalPersonCount, setNotificationTaskTotalPersonCount } from '../simple-action';
import { downloadProfileTotalPersonCount, downloadTokenTotalPersonCount } from '../../../helper';
import { RECIPIENT_TYPE_PROFILE } from '../../../../../constant';

export default (filteredRecipients, recipientType) => async (dispatch, getState) => {
  try {
    dispatch(downloadingNotificationTaskTotalPersonCount(true));

    const { token } = getState().authentication;

    let response;
    if (recipientType === RECIPIENT_TYPE_PROFILE) {
      response = await downloadProfileTotalPersonCount(filteredRecipients, token);
    } else {
      response = await downloadTokenTotalPersonCount(filteredRecipients, token);
    }

    dispatch(setNotificationTaskTotalPersonCount(response.totalCount));
  } finally {
    dispatch(downloadingNotificationTaskTotalPersonCount(false));
  }
};
