import {
  loggingIn, addToken, addCurrentUser, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setAlertInfoMessage, setAlertInputMessage, setAlertWarningMessage,
  setDrawerVisibility,
} from '../simple-action';
import { login } from '../../../helper';
import LocalizedString from '../../../localization';
import { MENUID_DASHBOARD, ROUTE_NAME_DASHBOARD } from '../../../module/attendance';

export default (username, password, navigateTo) => async (dispatch) => {
  if (!username || !password) {
    throw new Error(LocalizedString.loginScreen.errEmptyUsernamePassword);
  }

  try {
    dispatch(loggingIn(true));
    const auth = await login(username, password);
    const { token, user } = auth;

    if (!user.permissions.WEB_ADMIN_LOGIN) {
      throw new Error(LocalizedString.loginScreen.errMsgNoPermissionToLogin);
    }

    dispatch(addToken(token));
    dispatch(addCurrentUser(user));

    dispatch(setActiveSideMenuItem(MENUID_DASHBOARD));
    dispatch(setDrawerVisibility(true));
    navigateTo(ROUTE_NAME_DASHBOARD);
  } finally {
    dispatch(setAlertErrorMessage(''));
    dispatch(setAlertInfoMessage(''));
    dispatch(setAlertWarningMessage(''));
    dispatch(setAlertConfirmationMessage(''));
    dispatch(setAlertInputMessage('', ''));
    dispatch(loggingIn(false));
  }
};
