import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearSurveyCategories, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setSurveyCategorySearchText, setSurveyCategorySelectedPageSize, setSurveyCategorySelectedOrderBy,
  setSurveyCategoryTappedId,
  addEditSurveyCategoryAsync, downloadDeleteSurveyCategoryAsync, downloadSurveyCategoriesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_SURVEY_CATEGORIES, MENUID_SURVEY_SURVEY_CATEGORY, PAGE_MODE_TABLE,
  RXFORM_SURVEY_CATEGORY,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../../../localization';
import SurveyCategoryPage from './survey-category.presentation';

const getInitialValues = (state) => {
  const { surveyCategories, uiFunctionalPage, uiSurveyCategory } = state;
  const { data } = surveyCategories;
  const { downloadingDeleting, tappedId } = uiSurveyCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : { url: '', description: '' };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiSurveyCategory.addingEditing,
  downloading: state.uiSurveyCategory.downloading,
  downloadingDeleting: state.uiSurveyCategory.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_SURVEY_SURVEY_CATEGORY));
    dispatch(setSurveyCategorySearchText(''));
    dispatch(clearSurveyCategories());
    dispatch(setSurveyCategorySelectedPageSize(20));
    dispatch(setSurveyCategorySelectedOrderBy(INITIAL_ORDER_BY_SURVEY_CATEGORIES));
    dispatch(downloadSurveyCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSurveyCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSurveyCategorySelectedPageSize(pageSize));
    dispatch(downloadSurveyCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SURVEY_CATEGORY));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteSurveyCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setSurveyCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setSurveyCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSurveyCategoryTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setSurveyCategorySelectedPageSize(pageSize));
    dispatch(clearSurveyCategories());
    dispatch(downloadSurveyCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditSurveyCategoryAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSurveyCategorySearchText(text));
      dispatch(clearSurveyCategories());
      await dispatch(downloadSurveyCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSurveyCategorySelectedOrderBy(orderBy));
    dispatch(clearSurveyCategories());
    dispatch(downloadSurveyCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditSurveyCategoryAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setSurveyCategoryTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCategoryPage);
