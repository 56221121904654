import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');

export const DOWNLOADING_COMPANY_MAPPINGS = 'DOWNLOADING_COMPANY_MAPPINGS';
export const downloadingCompanyMappings = makeActionCreator(DOWNLOADING_COMPANY_MAPPINGS, 'status');

export const SET_COMPANY_MAPPINGS = 'SET_COMPANY_MAPPINGS';
export const setCompanyMappings = makeActionCreator(SET_COMPANY_MAPPINGS, 'data');

export const ADD_COMPANY_MAPPING = 'ADD_COMPANY_MAPPING';
export const addCompanyMapping = makeActionCreator(ADD_COMPANY_MAPPING, 'data');

export const CLEAR_COMPANY_MAPPINGS = 'CLEAR_COMPANY_MAPPINGS';
export const clearCompanyMappings = makeActionCreator(CLEAR_COMPANY_MAPPINGS);

export const ADDING_EDITING_COMPANY_MAPPING = 'ADDING_EDITING_COMPANY_MAPPING';
export const addingEditingCompanyMapping = makeActionCreator(ADDING_EDITING_COMPANY_MAPPING, 'status');

export const DOWNLOADING_DELETING_COMPANY_MAPPING = 'DOWNLOADING_DELETING_COMPANY_MAPPING';
export const downloadingDeletingCompanyMapping = makeActionCreator(DOWNLOADING_DELETING_COMPANY_MAPPING, 'status');


export const SET_COMPANY_MAPPING_SELECTED_PAGE_SIZE = 'SET_COMPANY_MAPPING_SELECTED_PAGE_SIZE';
export const setCompanyMappingselectedPageSize = makeActionCreator(SET_COMPANY_MAPPING_SELECTED_PAGE_SIZE, 'size');

export const SET_COMPANY_MAPPING_SELECTED_ORDER_BY = 'SET_COMPANY_MAPPING_SELECTED_ORDER_BY';
export const setCompanyMappingselectedOrderBy = makeActionCreator(SET_COMPANY_MAPPING_SELECTED_ORDER_BY, 'order');

export const SET_COMPANY_MAPPING_TAPPED_ID = 'SET_COMPANY_MAPPING_TAPPED_ID';
export const setCompanyMappingTappedId = makeActionCreator(SET_COMPANY_MAPPING_TAPPED_ID, 'id');

export const SET_COMPANY_MAPPING_SEARCH_TEXT = 'SET_COMPANY_MAPPING_SEARCH_TEXT';
export const setCompanyMappingsearchText = makeActionCreator(SET_COMPANY_MAPPING_SEARCH_TEXT, 'text');

export const SET_COMPANY_MAPPING_SELECTED_OPERATOR = 'SET_COMPANY_MAPPING_SELECTED_OPERATOR';
export const setCompanyMappingselectedOperator = makeActionCreator(SET_COMPANY_MAPPING_SELECTED_OPERATOR, 'option');


export const DOWNLOADING_INFO_MAPPINGS = 'DOWNLOADING_INFO_MAPPINGS';
export const downloadingInfoMappings = makeActionCreator(DOWNLOADING_INFO_MAPPINGS, 'status');

export const SET_INFO_MAPPINGS = 'SET_INFO_MAPPINGS';
export const setInfoMappings = makeActionCreator(SET_INFO_MAPPINGS, 'data');

export const ADD_INFO_MAPPING = 'ADD_INFO_MAPPING';
export const addInfoMapping = makeActionCreator(ADD_INFO_MAPPING, 'data');

export const CLEAR_INFO_MAPPINGS = 'CLEAR_INFO_MAPPINGS';
export const clearInfoMappings = makeActionCreator(CLEAR_INFO_MAPPINGS);

export const ADDING_EDITING_INFO_MAPPING = 'ADDING_EDITING_INFO_MAPPING';
export const addingEditingInfoMapping = makeActionCreator(ADDING_EDITING_INFO_MAPPING, 'status');

export const DOWNLOADING_DELETING_INFO_MAPPING = 'DOWNLOADING_DELETING_INFO_MAPPING';
export const downloadingDeletingInfoMapping = makeActionCreator(DOWNLOADING_DELETING_INFO_MAPPING, 'status');


export const SET_INFO_MAPPING_SELECTED_PAGE_SIZE = 'SET_INFO_MAPPING_SELECTED_PAGE_SIZE';
export const setInfoMappingSelectedPageSize = makeActionCreator(SET_INFO_MAPPING_SELECTED_PAGE_SIZE, 'size');

export const SET_INFO_MAPPING_SELECTED_ORDER_BY = 'SET_INFO_MAPPING_SELECTED_ORDER_BY';
export const setInfoMappingSelectedOrderBy = makeActionCreator(SET_INFO_MAPPING_SELECTED_ORDER_BY, 'order');

export const SET_INFO_MAPPING_TAPPED_ID = 'SET_INFO_MAPPING_TAPPED_ID';
export const setInfoMappingTappedId = makeActionCreator(SET_INFO_MAPPING_TAPPED_ID, 'id');

export const SET_INFO_MAPPING_SEARCH_TEXT = 'SET_INFO_MAPPING_SEARCH_TEXT';
export const setInfoMappingSearchText = makeActionCreator(SET_INFO_MAPPING_SEARCH_TEXT, 'text');

export const SET_INFO_MAPPING_SELECTED_MAPPING_OPERATOR = 'SET_INFO_MAPPING_SELECTED_MAPPING_OPERATOR';
export const setInfoMappingSelectedMappingOperator = makeActionCreator(SET_INFO_MAPPING_SELECTED_MAPPING_OPERATOR, 'option');

export const SET_INFO_MAPPING_SELECTED_TYPE = 'SET_INFO_MAPPING_SELECTED_TYPE';
export const setInfoMappingSelectedType = makeActionCreator(SET_INFO_MAPPING_SELECTED_TYPE, 'option');
