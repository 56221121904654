import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest,
  sendPutRequest, transformMediaData, transformQueryResult, sendGetFileRequest,
} from '../../helper';
import {
  REST_URL_ADD_EDIT_ARTICLE, REST_URL_ADD_EDIT_ARTICLE_CATEGORY, REST_URL_ARTICLES,
  REST_URL_ARTICLE_CATEGORIES, REST_URL_SEND_ARTICLE_TO_ME, REST_URL_VIEW_DELETE_ARTICLE,
  REST_URL_VIEW_DELETE_ARTICLE_CATEGORY, REST_URL_SAVE_FILE,
} from './constant';

export * from '../../helper';

export const saveArticles = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_ARTICLES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadArticles = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_ARTICLES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_ARTICLES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = transformMediaData(response.data);
  return transformQueryResult(response, transformData);
};

export const addArticle = async (title, content, publishedDate, categoryId, visibilityStatus,
  media, token) => {
  const body = {
    title,
    content,
    publishedDate,
    categoryId,
    visibilityStatus,
    media,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_ARTICLE, body, token);
};

export const editArticle = async (id, title, content, publishedDate, categoryId, visibilityStatus,
  media, token) => {
  const body = {
    id,
    title,
    content,
    publishedDate,
    categoryId,
    visibilityStatus,
    media,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_ARTICLE, body, token);
};

export const downloadArticle = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ARTICLE.replace(/\{id\}/, id), token);
  const transformData = transformMediaData(response);
  return transformData;
};

export const deleteArticle = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ARTICLE.replace(/\{id\}/, id), token);
};

export const sendArticleNotification = async (id, token) => {
  await sendPostRequest(REST_URL_SEND_ARTICLE_TO_ME.replace(/\{id\}/, id), null, token);
};

export const downloadArticleCategories = async (
  pageNumber, pageSize, orderBy, searchText, token,
) => {
  const url = buildQueryParams(REST_URL_ARTICLE_CATEGORIES,
    pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addArticleCategory = async (name, description, token) => {
  const body = { name, description };
  await sendPostRequest(REST_URL_ADD_EDIT_ARTICLE_CATEGORY, body, token);
};

export const editArticleCategory = async (id, name, description, token) => {
  const body = {
    id, name, description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_ARTICLE_CATEGORY, body, token);
};

export const downloadArticleCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ARTICLE_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteArticleCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ARTICLE_CATEGORY.replace(/\{id\}/, id), token);
};
