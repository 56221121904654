import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_FAQ_CATEGORY_NAME,
  RXFIELD_FAQ_CATEGORY, RXFIELD_FAQ_QUESTION, RXFIELD_FAQ_SHORT_ANSWER,
  RXFIELD_FAQ_ORDER, RXFIELD_FAQ_VISIBILITY_STATUS,
} from './constant';

export const rxformValidateFaqCategory = (values) => {
  const requiredFields = [
    RXFIELD_FAQ_CATEGORY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateFaq = (values) => {
  const requiredFields = [
    RXFIELD_FAQ_CATEGORY,
    RXFIELD_FAQ_QUESTION,
    RXFIELD_FAQ_SHORT_ANSWER,
    RXFIELD_FAQ_ORDER,
    RXFIELD_FAQ_VISIBILITY_STATUS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
