import { reset } from 'redux-form';
import {
  addingEditingIndustry, clearIndustries, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addIndustry, editIndustry } from '../../../helper';
import { RXFORM_INDUSTRY, PAGE_MODE_TABLE } from '../../../constant';
import downloadIndustriesAsync from './downloadIndustriesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingIndustry(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiIndustry;

    if (tappedId) {
      await editIndustry(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addIndustry(name, description, token);
    }

    dispatch(reset(RXFORM_INDUSTRY));
    dispatch(clearIndustries());
    dispatch(downloadIndustriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingIndustry(false));
  }
};
