import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  clearApiKeys, clearServices, clearScopes, copyApiToken, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setApiKeySearchText, setApiKeySelectedAdvancedFilterConsumerAppName,
  setApiKeySelectedAdvancedFilterOwnerAppName, setApiKeySelectedAdvancedFilterScope,
  setApiKeySelectedConsumerAppName, setApiKeySelectedOwnerAppName, setApiKeySelectedPageSize,
  setApiKeySelectedScope, setApiKeySelectedOrderBy, setApiKeyTappedId,
  setScopeAdvancedFilterDialogSelectedFilterString, setScopeSearchText,
  setServiceSearchText,
  addEditApiKeyAsync, downloadApiKeysAsync, downloadDeleteApiKeyAsync, downloadScopesAsync,
  downloadServicesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_API_KEYS, PAGE_MODE_TABLE, MENUID_AUTHENTICATION_APIKEY,
  RXFIELD_API_KEY_CONSUMER, RXFIELD_API_KEY_OWNER, RXFIELD_API_KEY_SCOPE, RXFORM_API_KEY,
} from '../../constant';
import { transformInitialValues, transformDropdownData } from '../../helper';
import LocalizedString from '../../localization';
import ApiKeyPage from './api-key.presentation';

const transformDropDownObject = (data) => (data ? { label: data, value: data } : '');

const getInitialValues = (state) => {
  const {
    apiKeys, uiFunctionalPage, uiApiKey,
  } = state;
  const { data } = apiKeys;
  const {
    downloadingDeleting, tappedId,
  } = uiApiKey;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      ownerAppName: transformDropDownObject(found.ownerAppName),
      consumerAppName: transformDropDownObject(found.consumerAppName),
      scope: transformDropDownObject(found.scope),
    }) : {
      token: '',
      scope: '',
      ownerAppName: '',
      consumerAppName: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiApiKey.addingEditing,
  downloading: state.uiApiKey.downloading,
  initialValues: getInitialValues(state),
  copyStatus: state.uiApiKey.copyStatus,
  services: transformDropdownData(state.services.data),
  scopes: transformDropdownData(state.scopes.data),
  loadingService: state.uiService.downloading,
  loadingScope: state.uiScope.downloading,
  apiKeys: state.apiKeys.data,
});

const downloadServices = (dispatch) => {
  dispatch(setServiceSearchText(''));
  dispatch(clearServices());
  dispatch(downloadServicesAsync(1))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
};

const downloadScopes = (dispatch) => {
  dispatch(setScopeSearchText(''));
  dispatch(clearScopes());
  dispatch(downloadScopesAsync(1))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
};

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setServiceSearchText(''));
    dispatch(setScopeSearchText(''));
    dispatch(clearServices());
    dispatch(clearScopes());
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadScopesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setScopeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_APIKEY));
    dispatch(setApiKeySearchText(''));
    dispatch(clearApiKeys());
    dispatch(setApiKeySelectedPageSize(20));
    dispatch(setApiKeySelectedOrderBy(INITIAL_ORDER_BY_API_KEYS));
    dispatch(downloadApiKeysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearApiKeys());
    dispatch(setApiKeySelectedPageSize(20));
    dispatch(setApiKeySelectedOrderBy(INITIAL_ORDER_BY_API_KEYS));
    dispatch(downloadApiKeysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setApiKeySelectedOwnerAppName(null));
    dispatch(setApiKeySelectedScope(null));
    dispatch(setApiKeySelectedConsumerAppName(null));
    dispatch(reset(RXFORM_API_KEY));
  },
  onChangeConsumerAppNameText: async (text) => {
    try {
      dispatch(setServiceSearchText(text));
      dispatch(clearServices());
      await dispatch(downloadServicesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeOwnerAppNameText: async (text, pageMode) => {
    try {
      dispatch(setServiceSearchText(text));
      dispatch(clearServices());
      await dispatch(downloadServicesAsync(1));
      if (pageMode === PAGE_MODE_TABLE) {
        dispatch(setApiKeySelectedScope(null));
        dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_SCOPE, null));
      }
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeScopeText: async (text) => {
    try {
      dispatch(setScopeSearchText(text));
      dispatch(clearScopes());
      await dispatch(downloadScopesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadApiKeysAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setApiKeySelectedPageSize(pageSize));
    dispatch(downloadApiKeysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onClickCopyIcon: (status) => {
    dispatch(copyApiToken(!status));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteApiKeyAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConsumerAdvancedFilterOptionSelected: (option) => {
    if (option) {
      dispatch(setApiKeySelectedAdvancedFilterConsumerAppName(option));
    } else {
      dispatch(setApiKeySelectedAdvancedFilterConsumerAppName([]));
    }
  },
  onConsumerOptionSelected: (option) => {
    if (option) {
      dispatch(setApiKeySelectedConsumerAppName(option));
      dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_CONSUMER, option));
    } else {
      dispatch(setApiKeySelectedConsumerAppName(null));
      dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_CONSUMER, null));
    }
    downloadServices(dispatch);
  },
  onCreatePressed: () => {
    dispatch(setApiKeyTappedId(''));
    dispatch(setApiKeySelectedOwnerAppName(null));
    dispatch(setApiKeySelectedScope(null));
    dispatch(setApiKeySelectedConsumerAppName(null));
    downloadServices(dispatch);
    downloadScopes(dispatch);
  },
  onDeletePressed: (id) => {
    dispatch(setApiKeyTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id, data) => {
    dispatch(setApiKeyTappedId(id));
    downloadScopes(dispatch);

    const { consumerAppName, ownerAppName, scope } = data[id];
    if (scope) {
      dispatch(setApiKeySelectedScope(transformDropDownObject(scope)));
    } else {
      dispatch(setApiKeySelectedScope(null));
    }

    if (ownerAppName) {
      dispatch(setApiKeySelectedOwnerAppName(transformDropDownObject(ownerAppName)));
    } else {
      dispatch(setApiKeySelectedOwnerAppName(null));
    }

    if (consumerAppName) {
      dispatch(setApiKeySelectedConsumerAppName(transformDropDownObject(consumerAppName)));
    } else {
      dispatch(setApiKeySelectedConsumerAppName(null));
    }
  },
  onOwnerAppNameAdvancedFilterOptionSelected: (option) => {
    if (option) {
      dispatch(setApiKeySelectedAdvancedFilterOwnerAppName(option));
    } else {
      dispatch(setApiKeySelectedAdvancedFilterOwnerAppName([]));
    }
  },
  onOwnerAppNameOptionSelected: async (option) => {
    try {
      dispatch(setApiKeySelectedScope(null));
      dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_SCOPE, null));

      if (option) {
        dispatch(setApiKeySelectedOwnerAppName(option));
        dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_OWNER, option));
      } else {
        dispatch(setApiKeySelectedOwnerAppName(null));
        dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_OWNER, null));
      }

      downloadScopes(dispatch);
    } catch (error) {
      setAlertErrorMessage(error);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setApiKeySelectedPageSize(pageSize));
    dispatch(clearApiKeys());
    dispatch(downloadApiKeysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearApiKeys());
    dispatch(setApiKeySelectedOrderBy(INITIAL_ORDER_BY_API_KEYS));
    dispatch(downloadApiKeysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async () => {
    try {
      await dispatch(addEditApiKeyAsync());
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onScopeOptionAdvancedFilterSelected: (option) => {
    if (option) {
      dispatch(setApiKeySelectedAdvancedFilterScope(option));
    } else {
      dispatch(setApiKeySelectedAdvancedFilterScope([]));
    }
  },
  onScopeOptionSelected: (option) => {
    if (option) {
      dispatch(setApiKeySelectedScope(option));
      dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_SCOPE, option));
    } else {
      dispatch(setApiKeySelectedScope(null));
      dispatch(change(RXFORM_API_KEY, RXFIELD_API_KEY_SCOPE, null));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setApiKeySearchText(text));
      dispatch(clearApiKeys());
      await dispatch(downloadApiKeysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setApiKeySelectedOrderBy(orderBy));
    dispatch(clearApiKeys());
    dispatch(downloadApiKeysAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async () => {
    await dispatch(addEditApiKeyAsync());
  },
  onViewPressed: (id) => {
    dispatch(setApiKeyTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyPage);
