import { downloadingEmployees, setEmployees } from '../simple-action';
import { downloadProfiles, transformSearchText } from '../../../helper';
import { INITIAL_ORDER_BY_PROFILES } from '../../../constant';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, employees, uiHistory } = getState();
  const { token } = authentication;
  const { meta } = employees;
  const { employeeSearchText } = uiHistory;

  const searchText = transformSearchText(employeeSearchText);

  try {
    dispatch(downloadingEmployees(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadProfiles(
      pageToBeDownloaded,
      20,
      INITIAL_ORDER_BY_PROFILES,
      searchText,
      '',
      token,
    );

    dispatch(setEmployees(list));
  } finally {
    dispatch(downloadingEmployees(false));
  }
};
