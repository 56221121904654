import {
  downloadCovidData, deleteCovidData,
} from '../../../helper';
import {
  addCovidData, downloadingDeletingCovidData,
  setCovidDataTappedId,
  setFunctionalPageMode, clearCovidDatas,
  setAlertErrorMessage,
  setCovidDataSelectedEmployeeStatus,
} from '../simple-action';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadCovidDatasAsync from './downloadCovidDatasAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCovidData(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCovidData;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteCovidData(tappedId, token);
      dispatch(setCovidDataTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCovidDatas());
      dispatch(downloadCovidDatasAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const covidData = await downloadCovidData(tappedId, token);
      dispatch(addCovidData(covidData));
      dispatch(setCovidDataSelectedEmployeeStatus(covidData.employeeStatus || ''));
    }
  } finally {
    dispatch(downloadingDeletingCovidData(false));
  }
};
