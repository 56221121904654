import { ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_SCREEN = '/screen/api/v1/Screen?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';

export const REST_URL_PICKUP_LOCATIONS = '/packet/api/v1/Location?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_PICKUP_LOCATION = '/packet/api/v1/Location';
export const REST_URL_VIEW_DELETE_PICKUP_LOCATION = '/packet/api/v1/Location/{id}';
export const REST_URL_PACKET_TYPES = '/packet/api/v1/PacketType?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_PACKET_TYPE = '/packet/api/v1/PacketType';
export const REST_URL_VIEW_DELETE_PACKET_TYPE = '/packet/api/v1/PacketType/{id}';
export const REST_URL_PACKETS = '/packet/api/v1/Packet?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=id|packetId|sender|recipient.fullname|recipient.phone|recipient.email|receivedby.fullname|receivedby.phone|receivedby.email|pickuplocation.name|packettype.name|arrivalnotes|pickedupby.fullname|pickedupby.phone|pickedupby.email|pickedUpByNonUser|handedby.fullname|handedby.phone|handedby.email|pickedupnotes&filterString=';
export const REST_URL_ADD_EDIT_PACKET = '/packet/api/v1/Packet/';
export const REST_URL_VIEW_DELETE_PACKET = '/packet/api/v1/Packet/{id}';
export const REST_URL_SAVE_PACKET = '/packet/api/v1/Packet/Download?filterString=';
export const REST_URL_SUMMARY_TRENDS = '/packet/api/v1/Summary/Trend?startDate={startDate}&endDate={endDate}&grouping={grouping}';
export const REST_URL_SUMMARY_TOP_RECIPIENTS = '/packet/api/v1/Summary/TopRecipient?startDate={startDate}&endDate={endDate}&grouping={grouping}&topRank=10';
export const REST_URL_SUMMARY_PACKET_TYPES = '/packet/api/v1/Summary/PacketType?startDate={startDate}&endDate={endDate}&grouping={grouping}';
export const REST_URL_SUMMARY_LOCATIONS = '/packet/api/v1/Summary/Location?startDate={startDate}&endDate={endDate}&grouping={grouping}';
export const REST_URL_PICKUP_PACKET = '/packet/api/v1/Packet/Pickup';

export const ROUTE_NAME_PACKET_MANAGEMENT = '/packet-management';
export const ROUTE_NAME_PICKUP_LOCATION = '/pickup-location';
export const ROUTE_NAME_PACKET_TYPE = '/packet-type';
export const ROUTE_NAME_PACKET = '/packet';
export const ROUTE_NAME_PACKET_SUMMARY = '/packet-summary';

export const RXFORM_PICKUP_LOCATION = 'pickupLocationPage';
export const RXFORM_PACKET_TYPE = 'packetTypePage';
export const RXFORM_PACKET = 'packetPage';

export const RXFIELD_PICKUP_LOCATION_NAME = 'name';
export const RXFIELD_PICKUP_LOCATION_DESCRIPTION = 'description';
export const RXFIELD_PICKUP_LOCATION_KEEP_PACKET_HOURS = 'keepPacketForHours';
export const RXFIELD_PACKET_TYPE_NAME = 'name';
export const RXFIELD_PACKET_TYPE_DESCRIPTION = 'description';
export const RXFIELD_PACKET_TYPE_KEEP_PACKET_HOURS = 'keepPacketForHours';
export const RXFIELD_PACKET_PACKET_ID = 'packetId';
export const RXFIELD_PACKET_SENDER = 'sender';
export const RXFIELD_PACKET_RECIPIENT = 'recipient';
export const RXFIELD_PACKET_RECEIVED_BY = 'receivedBy';
export const RXFIELD_PACKET_PICKUP_LOCATION = 'pickupLocation';
export const RXFIELD_PACKET_PACKET_TYPE = 'packetType';
export const RXFIELD_PACKET_ARRIVAL_IMAGE = 'arrivalImage';
export const RXFIELD_PACKET_ARRIVAL_NOTES = 'arrivalNotes';
export const RXFIELD_PACKET_VALID_UNTIL = 'validUntil';
export const RXFIELD_PACKET_PACKET_STATUS = 'packetStatus';
export const RXFIELD_PACKET_PICKED_UP_BY = 'pickedUpBy';
export const RXFIELD_PACKET_PICKED_UP_BY_NON_USER = 'pickedUpByNonUser';
export const RXFIELD_PACKET_HANDED_BY = 'handedBy';
export const RXFIELD_PACKET_PICKED_UP_TIME = 'pickedUpTime';
export const RXFIELD_PACKET_PICKED_UP_IMAGE = 'pickedupImage';
export const RXFIELD_PACKET_PICKED_UP_NOTES = 'pickedUpNotes';
export const RXFIELD_PACKET_PICK_UP_BY = 'pickUpBy';

export const RXSTATE_PICKUP_LOCATIONS = 'pickupLocations';
export const RXSTATE_PICKUP_LOCATION_PAGE = 'uiPickupLocation';
export const RXSTATE_PACKET_TYPES = 'packetTypes';
export const RXSTATE_PACKET_TYPE_PAGE = 'uiPacketType';
export const RXSTATE_PACKETS = 'packets';
export const RXSTATE_PACKET_PAGE = 'uiPacket';

export const INITIAL_ORDER_BY_PICKUP_LOCATIONS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_PACKET_TYPES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_PACKETS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_PACKET_MANAGEMENT = 'MENUID_PACKET_MANAGEMENT';
export const MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION = 'MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION';
export const MENUID_PACKET_MANAGEMENT_PACKET_TYPE = 'MENUID_PACKET_MANAGEMENT_PACKET_TYPE';
export const MENUID_PACKET_MANAGEMENT_PACKET = 'MENUID_PACKET_MANAGEMENT_PACKET';
export const MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY = 'MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY';

export const PACKET_SUMMARY_GROUPING_BY_DATE = 'Date';
export const PACKET_SUMMARY_GROUPING_BY_MONTH = 'Month';

export const PACKET_SUMMARY_CURRENT_PERIOD = 'current';
export const PACKET_SUMMARY_PREVIOUS_PERIOD = 'previous';

export const PACKET_STATUS_NOT_PICKED_UP = 'NotPickedUp';
export const PACKET_STATUS_PICKED_UP = 'PickedUp';

export const PACKET_PICKED_UP_BY_RECIPIENT = 'recipient';
export const PACKET_PICKED_UP_BY_USER = 'user';
export const PACKET_PICKED_UP_BY_NON_USER = 'nonUser';

export const PACKET_STATUS = [PACKET_STATUS_NOT_PICKED_UP, PACKET_STATUS_PICKED_UP];

export const UPLOAD_IMAGE_METHOD_SELECT_FILE = 'file';
export const UPLOAD_IMAGE_METHOD_TAKE_PICTURE = 'camera';

export const DEFAULT_DATE_TIME = '0001-01-01T00:00:00';
