import {
  downloadingSummaryBrowserDistribution,
  setSummaryBrowserDistribution,
} from '../simple-action';
import { downloadSummaryBrowserDistribution } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryBrowserDistribution(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryBrowserDistribution(token);

    dispatch(setSummaryBrowserDistribution(result));
  } finally {
    dispatch(downloadingSummaryBrowserDistribution(false));
  }
};
