import { SET_FORUM_CATEGORIES, ADD_FORUM_CATEGORY, CLEAR_FORUM_CATEGORIES } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FORUM_CATEGORIES: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_FORUM_CATEGORY: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_FORUM_CATEGORIES:
      return initialState;
    default:
      return state;
  }
};
