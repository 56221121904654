import { addPushNotification, resendingPushNotification } from '../simple-action';
import { resendPushNotification, downloadPushNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingPushNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPushNotification;

    await resendPushNotification(tappedId, token);
    const result = await downloadPushNotification(tappedId, token);

    dispatch(addPushNotification(result));
  } finally {
    dispatch(resendingPushNotification(false));
  }
};
