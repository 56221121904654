import {
  SET_PARENT_CALENDARS,
  CLEAR_PARENT_CALENDARS,
} from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PARENT_CALENDARS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case CLEAR_PARENT_CALENDARS:
      return initialState;
    default:
      return state;
  }
};
