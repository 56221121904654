import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import {
  BasePage, PieChart, SimpleBarChart, SimpleLineChart,
} from '../../component';
import {
  CHART_TYPE_PIE, CHART_TYPE_SIMPLE_BAR, CHART_TYPE_SIMPLE_LINE, COLOR_SECONDARY,
  MONTH_YEAR_ONLY_FORMAT,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { PacketSummaryShape } from '../../type';

const ARGUMENT_FIELD = 'key';
const VALUE_FIELD = 'value';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  bodyContainer: {
    marginTop: 60,
  },
  heading: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    margin: '0px 0px 20px 10px',
  },
  chartContainer: {
    padding: 24,
    margin: '30px 0px 30px 0px',
    justifyContent: 'space-between',
  },
}));

const renderChartField = (item) => {
  switch (item.type) {
    case CHART_TYPE_SIMPLE_LINE:
      return (
        <Grid item xs={12} md={12} key={item.id}>
          <SimpleLineChart
            data={item.data}
            loading={item.downloading}
            argumentField={ARGUMENT_FIELD}
            name={item.name}
            title={item.title}
            valueField={VALUE_FIELD}
            useVerticalLabel={item.useVerticalLabel}
          />
        </Grid>
      );
    case CHART_TYPE_SIMPLE_BAR:
      return (
        <Grid item xs={12} md={12} key={item.id}>
          <SimpleBarChart
            data={item.data}
            loading={item.downloading}
            argumentField={ARGUMENT_FIELD}
            title={item.title}
            valueField={VALUE_FIELD}
          />
        </Grid>
      );
    case CHART_TYPE_PIE:
      return (
        <Grid item xs={12} md={6} key={item.id}>
          <PieChart
            data={item.data}
            loading={item.downloading}
            argumentField={ARGUMENT_FIELD}
            title={item.title}
            valueField={VALUE_FIELD}
          />
        </Grid>
      );
    default: return null;
  }
};

const PacketSummaryPage = ({
  previousMonthLocations, previousMonthPacketTypes, previousMonthTopRecipients, previousMonthTrends,
  previousYearLocations, previousYearPacketTypes, previousYearTopRecipients, previousYearTrends,
  thisMonthLocations, thisMonthPacketTypes, thisMonthTopRecipients, thisMonthTrends,
  thisYearLocations, thisYearPacketTypes, thisYearTopRecipients, thisYearTrends,
  downloadingPreviousMonthLocations, downloadingPreviousMonthPacketTypes,
  downloadingPreviousMonthTopRecipients, downloadingPreviousMonthTrends,
  downloadingPreviousYearLocations, downloadingPreviousYearPacketTypes,
  downloadingPreviousYearTopRecipients, downloadingPreviousYearTrends,
  downloadingThisMonthLocations, downloadingThisMonthPacketTypes, downloadingThisMonthTopRecipients,
  downloadingThisMonthTrends, downloadingThisYearLocations, downloadingThisYearPacketTypes,
  downloadingThisYearTopRecipients, downloadingThisYearTrends,
  onAppear,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  const dataChart = [
    {
      id: '1a',
      title: LocalizedString.packetSummaryScreen.labelThisMonth.replace(/\{period\}/, (toMoment().startOf('month').format(MONTH_YEAR_ONLY_FORMAT))),
      children: [
        {
          id: '1aa',
          downloading: downloadingThisMonthTrends,
          data: thisMonthTrends,
          title: LocalizedString.packetSummaryScreen.labelTrends,
          type: CHART_TYPE_SIMPLE_LINE,
          name: LocalizedString.packetSummaryScreen.labelReceivedPacket,
          useVerticalLabel: true,
        },
        {
          id: '1ab',
          downloading: downloadingThisMonthTopRecipients,
          data: thisMonthTopRecipients,
          title: LocalizedString.packetSummaryScreen.labelTopRecipients,
          type: CHART_TYPE_SIMPLE_BAR,
        },
        {
          id: '1ac',
          downloading: downloadingThisMonthPacketTypes,
          data: thisMonthPacketTypes,
          title: LocalizedString.packetSummaryScreen.labelPacketTypes,
          type: CHART_TYPE_PIE,
        },
        {
          id: '1ad',
          downloading: downloadingThisMonthLocations,
          data: thisMonthLocations,
          title: LocalizedString.packetSummaryScreen.labelLocations,
          type: CHART_TYPE_PIE,
        },
      ],
    },
    {
      id: '2a',
      title: LocalizedString.packetSummaryScreen.labelPreviousMonth.replace(/\{period\}/, (toMoment().subtract(1, 'months').startOf('month').format(MONTH_YEAR_ONLY_FORMAT))),
      children: [
        {
          id: '2aa',
          downloading: downloadingPreviousMonthTrends,
          data: previousMonthTrends,
          title: LocalizedString.packetSummaryScreen.labelTrends,
          type: CHART_TYPE_SIMPLE_LINE,
          name: LocalizedString.packetSummaryScreen.labelReceivedPacket,
          useVerticalLabel: true,
        },
        {
          id: '2ab',
          downloading: downloadingPreviousMonthTopRecipients,
          data: previousMonthTopRecipients,
          title: LocalizedString.packetSummaryScreen.labelTopRecipients,
          type: CHART_TYPE_SIMPLE_BAR,
        },
        {
          id: '2ac',
          downloading: downloadingPreviousMonthPacketTypes,
          data: previousMonthPacketTypes,
          title: LocalizedString.packetSummaryScreen.labelPacketTypes,
          type: CHART_TYPE_PIE,
        },
        {
          id: '2ad',
          downloading: downloadingPreviousMonthLocations,
          data: previousMonthLocations,
          title: LocalizedString.packetSummaryScreen.labelLocations,
          type: CHART_TYPE_PIE,
        },
      ],
    },
    {
      id: '3a',
      title: LocalizedString.packetSummaryScreen.labelThisYear.replace(/\{period\}/, (toMoment().startOf('year').format(MONTH_YEAR_ONLY_FORMAT))),
      children: [
        {
          id: '3aa',
          downloading: downloadingThisYearTrends,
          data: thisYearTrends,
          title: LocalizedString.packetSummaryScreen.labelTrends,
          type: CHART_TYPE_SIMPLE_LINE,
          name: LocalizedString.packetSummaryScreen.labelReceivedPacket,
        },
        {
          id: '3ab',
          downloading: downloadingThisYearTopRecipients,
          data: thisYearTopRecipients,
          title: LocalizedString.packetSummaryScreen.labelTopRecipients,
          type: CHART_TYPE_SIMPLE_BAR,
        },
        {
          id: '3ac',
          downloading: downloadingThisYearPacketTypes,
          data: thisYearPacketTypes,
          title: LocalizedString.packetSummaryScreen.labelPacketTypes,
          type: CHART_TYPE_PIE,
        },
        {
          id: '3ad',
          downloading: downloadingThisYearLocations,
          data: thisYearLocations,
          title: LocalizedString.packetSummaryScreen.labelLocations,
          type: CHART_TYPE_PIE,
        },
      ],
    },
    {
      id: '4a',
      title: LocalizedString.packetSummaryScreen.labelPreviousYear.replace(/\{period\}/, (toMoment().subtract(1, 'years').startOf('year').format(MONTH_YEAR_ONLY_FORMAT))),
      children: [
        {
          id: '4aa',
          downloading: downloadingPreviousYearTrends,
          data: previousYearTrends,
          title: LocalizedString.packetSummaryScreen.labelTrends,
          type: CHART_TYPE_SIMPLE_LINE,
          name: LocalizedString.packetSummaryScreen.labelReceivedPacket,
        },
        {
          id: '4ab',
          downloading: downloadingPreviousYearTopRecipients,
          data: previousYearTopRecipients,
          title: LocalizedString.packetSummaryScreen.labelTopRecipients,
          type: CHART_TYPE_SIMPLE_BAR,
        },
        {
          id: '4ac',
          downloading: downloadingPreviousYearPacketTypes,
          data: previousYearPacketTypes,
          title: LocalizedString.packetSummaryScreen.labelPacketTypes,
          type: CHART_TYPE_PIE,
        },
        {
          id: '4ad',
          downloading: downloadingPreviousYearLocations,
          data: previousYearLocations,
          title: LocalizedString.packetSummaryScreen.labelLocations,
          type: CHART_TYPE_PIE,
        },
      ],
    },
  ];

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>{LocalizedString.packetSummaryScreen.title}</Typography>
      </div>

      {dataChart.map((item) => (
        <div className={classes.bodyContainer} key={item.id}>
          <Typography variant="subtitle1" className={classes.heading}>{item.title}</Typography>

          <Paper elevation={3} className={classes.chartContainer}>
            <Grid container spacing={1} alignItems="stretch" key={item.id}>
              {item.children.map((x) => renderChartField(x))}
            </Grid>
          </Paper>
        </div>
      ))}
    </BasePage>
  );
};

export default PacketSummaryPage;

PacketSummaryPage.propTypes = {
  previousMonthLocations: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  previousMonthPacketTypes: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  previousMonthTopRecipients: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  previousMonthTrends: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  previousYearLocations: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  previousYearPacketTypes: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  previousYearTopRecipients: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  previousYearTrends: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisMonthLocations: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisMonthPacketTypes: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisMonthTopRecipients: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisMonthTrends: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisYearLocations: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisYearPacketTypes: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisYearTopRecipients: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  thisYearTrends: PropTypes.arrayOf(PacketSummaryShape).isRequired,
  downloadingPreviousMonthLocations: PropTypes.bool.isRequired,
  downloadingPreviousMonthPacketTypes: PropTypes.bool.isRequired,
  downloadingPreviousMonthTopRecipients: PropTypes.bool.isRequired,
  downloadingPreviousMonthTrends: PropTypes.bool.isRequired,
  downloadingPreviousYearLocations: PropTypes.bool.isRequired,
  downloadingPreviousYearPacketTypes: PropTypes.bool.isRequired,
  downloadingPreviousYearTopRecipients: PropTypes.bool.isRequired,
  downloadingPreviousYearTrends: PropTypes.bool.isRequired,
  downloadingThisMonthLocations: PropTypes.bool.isRequired,
  downloadingThisMonthPacketTypes: PropTypes.bool.isRequired,
  downloadingThisMonthTopRecipients: PropTypes.bool.isRequired,
  downloadingThisMonthTrends: PropTypes.bool.isRequired,
  downloadingThisYearLocations: PropTypes.bool.isRequired,
  downloadingThisYearPacketTypes: PropTypes.bool.isRequired,
  downloadingThisYearTopRecipients: PropTypes.bool.isRequired,
  downloadingThisYearTrends: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
};
