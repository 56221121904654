import { ANALYTICS_DATA_LIMIT } from '../../../constant';
import { getAnalyticsCountriesByUsersData } from '../../../helper';
import { downloadingAnalyticsCountriesByUsers, setAnalyticsCountriesByUsers } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsCountriesByUsers(true));

    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { userByCountryDateRange } = uiAnalytics;
    const { token } = authentication;

    const requestBody = {
      dateRanges: [{ startDate: userByCountryDateRange.from, endDate: userByCountryDateRange.to }],
      dimensions: [{ name: 'country' }],
      metrics: [{ name: 'totalUsers' }],
      limit: ANALYTICS_DATA_LIMIT,
    };

    const response = await getAnalyticsCountriesByUsersData(
      myConfigItems?.core?.googleAnalyticsPropertyId, requestBody, token,
    );
    dispatch(setAnalyticsCountriesByUsers(response));
  } finally {
    dispatch(downloadingAnalyticsCountriesByUsers(false));
  }
};
