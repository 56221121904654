import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_NEWS_TITLE, RXFIELD_NEWS_PUBLISHED_DATE,
  RXFIELD_NEWS_VISIBILITY_STATUS, RXFIELD_NEWS_MEDIA, RXFIELD_NEWS_CATEGORY,
  RXFIELD_NEWS_CATEGORY_NAME,
} from './constant';

export const rxformValidateNews = (values) => {
  const requiredFields = [
    RXFIELD_NEWS_TITLE,
    RXFIELD_NEWS_PUBLISHED_DATE,
    RXFIELD_NEWS_VISIBILITY_STATUS,
    RXFIELD_NEWS_MEDIA,
    RXFIELD_NEWS_CATEGORY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateNewsCategory = (values) => {
  const requiredFields = [
    RXFIELD_NEWS_CATEGORY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
