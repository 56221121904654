import {
  SET_RESYNC_CONFIGS, SET_RESYNC_DB_USERS, SET_RESYNC_MASTER_DATA, SET_RESYNC_USERS,
} from '../action';

const initialState = {
  configs: {},
  dbUsers: {},
  masterData: {},
  users: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RESYNC_CONFIGS:
      return { ...state, configs: action.data };
    case SET_RESYNC_DB_USERS:
      return { ...state, dbUsers: action.data };
    case SET_RESYNC_MASTER_DATA:
      return { ...state, masterData: action.data };
    case SET_RESYNC_USERS:
      return { ...state, users: action.data };
    default:
      return state;
  }
};
