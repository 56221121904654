import { connect } from 'react-redux';
import {
  clearSummaryActiveDevices, clearSummaryActiveUsers,
  clearSummaryAndroidDeviceModelDistributions, clearSummaryDeviceManufacturerDistributions,
  clearSummaryAndroidVersionDistribution, clearSummaryIosVersionDistribution,
  clearSummaryLoginUsing, clearSummaryIosDeviceModelDistributions, clearSummaryOsDistribution,
  clearSummaryTotalUsers, clearSummaryBrowserDistribution, clearSummaryAppVersionDistribution,
  setActiveSideMenuItem, setAlertErrorMessage,
  downloadSummaryActiveDevicesAsync, downloadSummaryAndroidDeviceModelDistributionAsync,
  downloadSummaryActiveUsersAsync, downloadSummaryAndroidVersionDistributionAsync,
  downloadSummaryDeviceManufacturerDistributionAsync, downloadSummaryIosVersionDistributionAsync,
  downloadSummaryLoginUsingAsync, downloadSummaryOsDistributionAsync,
  downloadSummaryTotalUsersAsync, downloadSummaryBrowserDistributionAsync,
  downloadSummaryAppVersionDistributionAsync,
  downloadSummaryIosDeviceModelDistributionAsync,
  clearSummaryTimezoneDistribution,
  downloadSummaryTimezoneDistributionAsync, downloadSummaryOnlineUsersAsync,
} from '../../redux/action';
import SummaryPage from './summary.presentation';
import { getChartPercentage, toKFormat, sortDesc } from '../../helper';
import { MENUID_AUTHENTICATION_SUMMARY } from '../../constant';
import LocalizedString from '../../localization';

const ARGUMENT_FIELD = 'name';
const VALUE_FIELD = 'count';

const getTopChartData = (data) => {
  const sortedData = Object.values(data).sort((a, b) => sortDesc(a[VALUE_FIELD], b[VALUE_FIELD]));
  const topData = sortedData.slice(0, 9);
  const others = {
    [ARGUMENT_FIELD]: LocalizedString.summaryScreen.labelPieChartOther,
    [VALUE_FIELD]: sortedData.slice(9, sortedData.length - 1)
      .reduce((prev, current) => prev + current[VALUE_FIELD], 0),
  };
  const newData = sortedData.length > 10 ? { ...topData, others } : { ...topData };
  return getChartPercentage(newData, ARGUMENT_FIELD, VALUE_FIELD);
};

const mapStateToProps = (state) => ({
  downloadingTotalUsers: state.uiSummary.downloadingTotalUsers,
  downloadingActiveUsers: state.uiSummary.downloadingActiveUsers,
  downloadingActiveDevices: state.uiSummary.downloadingActiveDevices,
  downloadingOsDistribution: state.uiSummary.downloadingOsDistribution,
  downloadingLoginUsing: state.uiSummary.downloadingLoginUsing,
  downloadingAndroidVersionDistribution: state.uiSummary.downloadingAndroidVersionDistribution,
  downloadingIosVersionDistribution: state.uiSummary.downloadingIosVersionDistribution,
  downloadingBrowserDistribution: state.uiSummary.downloadingBrowserDistribution,
  downloadingTimezoneDistribution: state.uiSummary.downloadingTimezoneDistribution,
  downloadingAppVersionDistribution: state.uiSummary.downloadingAppVersionDistribution,
  downloadingDeviceManufacturerDistribution:
   state.uiSummary.downloadingDeviceManufacturerDistribution,
  downloadingAndroidDeviceModelDistribution:
   state.uiSummary.downloadingAndroidDeviceModelDistribution,
  downloadingIosDeviceModelDistribution: state.uiSummary.downloadingIosDeviceModelDistribution,
  downloadingOnlineUsers: state.uiSummary.downloadingOnlineUsers,
  totalUsers: Object.keys(state.totalUsers).length > 0 ? toKFormat(state.totalUsers.count) : 0,
  activeUsers: Object.keys(state.activeUsers).length > 0 ? toKFormat(state.activeUsers.count) : 0,
  activeDevices: Object.keys(state.activeDevices).length > 0
    ? toKFormat(state.activeDevices.count) : 0,
  onlineUsers: Object.keys(state.onlineUsers).length > 0 ? toKFormat(state.onlineUsers.count) : 0,
  osDistribution: getTopChartData(state.osDistribution),
  loginUsing: getTopChartData(state.loginUsing),
  androidVersionDistribution: getTopChartData(state.androidVersionDistribution),
  iosVersionDistribution: getTopChartData(state.iosVersionDistribution),
  browserDistribution: getTopChartData(state.browserDistribution),
  timezoneDistribution: getTopChartData(state.timezoneDistribution),
  appVersionDistribution: getTopChartData(state.appVersionDistribution),
  deviceManufactureDistribution: getTopChartData(state.deviceManufacturerDistribution),
  androidDeviceModelDistribution: getTopChartData(state.androidDeviceModelDistribution),
  iosDeviceModelDistribution: getTopChartData(state.iosDeviceModelDistribution),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_SUMMARY));
    dispatch(clearSummaryActiveDevices());
    dispatch(clearSummaryActiveUsers());
    dispatch(clearSummaryLoginUsing());
    dispatch(clearSummaryOsDistribution());
    dispatch(clearSummaryTotalUsers());
    dispatch(clearSummaryAndroidVersionDistribution());
    dispatch(clearSummaryIosVersionDistribution());
    dispatch(clearSummaryBrowserDistribution());
    dispatch(clearSummaryTimezoneDistribution());
    dispatch(clearSummaryAppVersionDistribution());
    dispatch(clearSummaryDeviceManufacturerDistributions());
    dispatch(clearSummaryAndroidDeviceModelDistributions());
    dispatch(clearSummaryIosDeviceModelDistributions());
    dispatch(downloadSummaryLoginUsingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryOsDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryActiveDevicesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryActiveUsersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryTotalUsersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryAndroidVersionDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryIosVersionDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryBrowserDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryTimezoneDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryAppVersionDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryDeviceManufacturerDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryAndroidDeviceModelDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryIosDeviceModelDistributionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSummaryOnlineUsersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPage);
