import { downloadingWorkingHours, setWorkingHours } from '../simple-action';
import { downloadWorkingHours, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, workingHours, uiWorkingHour } = getState();
  const { token } = authentication;
  const { meta } = workingHours;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiWorkingHour;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingWorkingHours(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadWorkingHours(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setWorkingHours(list));
  } finally {
    dispatch(downloadingWorkingHours(false));
  }
};
