import { reset } from 'redux-form';
import {
  addingEditingNews, clearNews, setAlertErrorMessage, setFunctionalPageMode,
  setNewsSelectedCategory, setNewsShortDescriptionLength, setNewsSelectedVisibilityStatus,
  setNewsSelectedIsHeadline,
} from '../simple-action';
import { addNews, editNews, toNumber } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_NEWS } from '../../../constant';
import downloadNewsAsync from './downloadNewsAsync';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

export default (title, content, publishedDate, media, sourceName, sourceUrl,
  shortDescription, tags) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingNews(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedCategory, selectedVisibilityStatus, selectedIsHeadline,
    } = getState().uiNews;
    const { data } = getState().news;

    const found = data[tappedId];
    const category = selectedCategory || found.category.id;
    const status = selectedVisibilityStatus || found.visibilityStatus;
    const medias = media.length > 0 ? transformMedia(media) : [];
    const isHeadline = selectedIsHeadline ? selectedIsHeadline.value : false;

    if (tappedId) {
      await editNews(tappedId, title, content, publishedDate, category, status, medias, sourceName,
        sourceUrl, shortDescription, isHeadline, tags, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addNews(title, content, publishedDate, category, status, medias, sourceName, sourceUrl,
        shortDescription, isHeadline, tags, token);
    }

    dispatch(reset(RXFORM_NEWS));
    dispatch(clearNews());
    dispatch(downloadNewsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setNewsSelectedCategory(''));
    dispatch(setNewsSelectedVisibilityStatus(''));
    dispatch(setNewsShortDescriptionLength(0));
    dispatch(setNewsSelectedIsHeadline(null));
  } finally {
    dispatch(addingEditingNews(false));
  }
};
