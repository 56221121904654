import { INITIAL_ORDER_BY_DOCUMENT_GENERATOR_DOCUMENT } from '../../constant';
import {
  DOWNLOADING_DELETING_DOCUMENT_GENERATOR_DOCUMENT,
  DOWNLOADING_DOCUMENT_GENERATOR_DOCUMENTS,
  DOWNLOADING_DOCUMENT_RESULT,
  SET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_DOCUMENT_GENERATOR_DOCUMENT_SEARCH_TEXT,
  SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_ORDER_BY,
  SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_PAGE_SIZE,
  SET_DOCUMENT_GENERATOR_DOCUMENT_TAPPED_ID,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  enablingDisabling: false,
  addingEditing: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_DOCUMENT_GENERATOR_DOCUMENT,
  downloadingDocumentResult: false,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_DOCUMENT_GENERATOR_DOCUMENTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_DOCUMENT_GENERATOR_DOCUMENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_DOCUMENT_GENERATOR_DOCUMENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_DOCUMENT_GENERATOR_DOCUMENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_DOCUMENT_GENERATOR_DOCUMENT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case DOWNLOADING_DOCUMENT_RESULT:
      return { ...state, downloadingDocumentResult: action.status };
    default: return state;
  }
};
