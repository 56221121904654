import {
  downloadingAttendanceSummaryTodayClockInLocationStatus,
  setAttendanceSummaryTodayClockInLocationStatus,
} from '../simple-action';
import { downloadAttendanceSummaryTodayClockInLocationStatus, getPeriodDate } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTodayClockInLocationStatus(true));

    const { authentication } = getState();
    const { token } = authentication;
    const { selectedPeriod } = getState().uiAttendanceSummary;

    const data = await downloadAttendanceSummaryTodayClockInLocationStatus(
      token,
      getPeriodDate(selectedPeriod).startTime,
      getPeriodDate(selectedPeriod).endTime,
    );

    dispatch(setAttendanceSummaryTodayClockInLocationStatus(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTodayClockInLocationStatus(false));
  }
};
