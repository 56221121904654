import { downloadingPacketTypes, setPacketTypes } from '../simple-action';
import { downloadPacketTypes, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, packetTypes, uiPacketType } = getState();
  const { token } = authentication;
  const { meta, data } = packetTypes;
  const { selectedPageSize, searchBarText, orderBy } = uiPacketType;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingPacketTypes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPacketTypes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      token,
    );

    if (list) {
      dispatch(setPacketTypes(list));
    }
  } finally {
    dispatch(downloadingPacketTypes(false));
  }
};
