import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  clearHolidays, setActiveSideMenuItem, setAlertErrorMessage,
  setHolidaySearchText, setHolidaySelectedPageSize, setHolidaySelectedOrderBy, setHolidayTappedId,
  downloadHolidayAsync, downloadHolidaysAsync, setHolidayAdvancedFilterDialogSelectedFilterString,
  addHolidayAsync, setHolidaySelectedFile, setFunctionalPageMode,
  setHolidaySelectedYear,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_HOLIDAYS, MENUID_ATTENDANCE_ADDON_HOLIDAY, PAGE_MODE_TABLE, PAGE_MODE_VIEW,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_HOLIDAY_FILE, RXFIELD_HOLIDAY_YEAR, RXFORM_HOLIDAY,
  YEAR_ONLY_FORMAT,
} from '../../constant';
import {
  transformInitialValues, toMoment, sortAsc,
} from '../../helper';
import HolidayPage from './holiday.presentation';
import { downloadMyConfigItemsAsync } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { holidays, uiHoliday, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId, selectedFile } = uiHoliday;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? holidays.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      file: selectedFile || '',
    }) : {
      file: '',
    };
  return initVal;
};

const getYear = () => {
  const dateRange = 5;

  const currentYear = toMoment().format(YEAR_ONLY_FORMAT);

  let arr = [];

  for (let i = 0; i <= dateRange; i += 1) {
    const prevYear = toMoment(currentYear).subtract(i, 'year').format(YEAR_ONLY_FORMAT);
    const nextYear = toMoment(currentYear).add(i, 'year').format(YEAR_ONLY_FORMAT);

    arr = [...arr, nextYear];
    arr = [...arr, prevYear];

    arr.sort((a, b) => sortAsc(a, b));
  }

  return [...new Set(arr)];
};

const mapStateToProps = (state) => ({
  year: getYear(),
  downloading: state.uiHoliday.downloading,
  addingEditing: state.uiHoliday.addingEditing,
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  selectedYear: state.uiHoliday.selectedYear,
  templateUrl: state?.myConfigItems?.attendanceAddOn?.nationalHolidayImportTemplate,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setHolidayAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_HOLIDAY));
    dispatch(setHolidaySearchText(''));
    dispatch(clearHolidays());
    dispatch(setHolidaySelectedPageSize(20));
    dispatch(setHolidaySelectedOrderBy(INITIAL_ORDER_BY_HOLIDAYS));
    dispatch(downloadHolidaysAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_HOLIDAY));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadHolidaysAsync(pageNo + 1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setHolidaySelectedPageSize(pageSize));
    dispatch(downloadHolidaysAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCreatePressed: () => {
    dispatch(setHolidaySelectedFile(''));
  },
  onRefresh: (pageSize) => {
    dispatch(setHolidaySelectedPageSize(pageSize));
    dispatch(clearHolidays());
    dispatch(downloadHolidaysAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setHolidaySearchText(text));
      dispatch(clearHolidays());
      await dispatch(downloadHolidaysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setHolidaySelectedOrderBy(orderBy));
    dispatch(clearHolidays());
    dispatch(downloadHolidaysAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onViewPressed: (id) => {
    dispatch(setHolidayTappedId(id));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    dispatch(downloadHolidayAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setHolidayAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearHolidays());
    dispatch(downloadHolidaysAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_HOLIDAY));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setHolidayAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setHolidaySelectedOrderBy(INITIAL_ORDER_BY_HOLIDAYS));
    dispatch(clearHolidays());
    dispatch(downloadHolidaysAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onFileSelected: (base64) => {
    dispatch(setHolidaySelectedFile(base64));
    dispatch(change(RXFORM_HOLIDAY, RXFIELD_HOLIDAY_FILE, base64));
  },
  onSubmitPressed: async (values) => {
    await dispatch(addHolidayAsync(values));
  },
  onYearSelected: (year) => {
    dispatch(setHolidaySelectedYear(year));
    dispatch(
      change(
        RXFORM_HOLIDAY,
        RXFIELD_HOLIDAY_YEAR,
        year,
      ),
    );
  },
  onDownloadTemplatePressed: async (templateUrl) => {
    try {
      const extension = templateUrl?.match(/(\.[^.]*)$/g)[0];
      const fileUrl = `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${templateUrl}`;
      const response = await fetch(fileUrl);
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `National Holiday Import Template${extension}`;
        a.click();
      });
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HolidayPage);
