import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_SURVEY_RESPONSES, MENUID_SURVEY_SURVEY_RESPONSE, PAGE_MODE_TABLE,
  RXFIELD_SURVEY_RESPONSE_SURVEY,
} from '../../constant';
import {
  transformDropdownData, transformInitialValues,
  sortAsc,
} from '../../helper';
import {
  clearSurveyResponses, clearProfiles, setActiveSideMenuItem, addFunctionalPageAdvancedFilterForm,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setSurveyResponseSearchText, setSurveyResponseSelectedOrderBy,
  setSurveyResponseSelectedPageSize, setSurveyResponseTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  downloadSurveyResponseAsync, downloadSurveyResponsesAsync,
  setSurveyAdvancedFilterDialogSelectedFilterString,
  setSurveySearchText, clearSurveys, downloadSurveysAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import SurveyResponsePage from './survey-response.presentation';

const getInitialValues = (state) => {
  const {
    surveyResponses, uiFunctionalPage, uiSurveyResponse,
  } = state;
  const { data } = surveyResponses;
  const { downloadingDetail, tappedId } = uiSurveyResponse;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDetail
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      surveyTitle: found.survey?.title || '',
      surveyDescription: found.survey?.description || '',
      userId: found.user?.id || '',
      userName: found.user?.fullName || '',
      answers: found.answers.length ? found.answers
        .sort((a, b) => sortAsc(a.question.order, b.question.order)) : [],
    }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  users: transformDropdownData(state.profiles.data),
  discussions: transformDropdownData(state.discussions.data),
  downloading: state.uiSurveyResponse.downloading,
  downloadingDeleting: state.uiSurveyResponse.downloadingDeleting,
  loadingUser: state.uiProfile.downloading,
  dialogVisibility: state.uiFunctionalPage.visibility,
  initialValues: getInitialValues(state),
  surveys: transformDropdownData(state.surveys.data),
  loadingSurveys: state.uiSurvey.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setSurveyAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSurveySearchText(''));
    dispatch(clearSurveys());
    dispatch(downloadSurveysAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: (navigationParams) => {
    if (navigationParams) {
      const { label, value } = navigationParams.selectedSurvey;
      dispatch(addFunctionalPageAdvancedFilterForm(RXFIELD_SURVEY_RESPONSE_SURVEY,
        { label, value }));
      dispatch(setAdvancedFilterDialogSelectedFilterString(navigationParams.filterString));
    }
    dispatch(setActiveSideMenuItem(MENUID_SURVEY_SURVEY_RESPONSE));
    dispatch(setSurveyResponseSearchText(''));
    dispatch(clearSurveyResponses());
    dispatch(setSurveyResponseSelectedPageSize(20));
    dispatch(setSurveyResponseSelectedOrderBy(INITIAL_ORDER_BY_SURVEY_RESPONSES));
    dispatch(downloadSurveyResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/userName/, 'user.id')
      .replace(/survey/, 'survey.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSurveyResponses());
    dispatch(downloadSurveyResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSurveyResponsesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSurveyResponseSelectedPageSize(pageSize));
    dispatch(downloadSurveyResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setSurveyResponseSelectedPageSize(pageSize));
    dispatch(clearSurveyResponses());
    dispatch(downloadSurveyResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearSurveyResponses());
    dispatch(setSurveyResponseSelectedOrderBy(INITIAL_ORDER_BY_SURVEY_RESPONSES));
    dispatch(downloadSurveyResponsesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSurveyResponseSearchText(text));
      dispatch(clearSurveyResponses());
      await dispatch(downloadSurveyResponsesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSurveyResponseSelectedOrderBy(orderBy));
    dispatch(clearSurveyResponses());
    dispatch(downloadSurveyResponsesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setSurveyResponseTappedId(id));
    dispatch(downloadSurveyResponseAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeSurveyText: async (text) => {
    try {
      dispatch(setSurveyAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setSurveySearchText(text));
      dispatch(clearSurveys());
      await dispatch(downloadSurveysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResponsePage);
