import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearPermissions, clearRoles, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setPermissionSearchText, setRoleSearchText, setRoleSelectedPageSize,
  setRoleSelectedOrderBy, setRoleTappedId, setRoleSelectedPermission,
  addEditRoleAsync, downloadDeleteRoleAsync, downloadPermissionsAsync, downloadRolesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_ROLES, PAGE_MODE_TABLE, RXFIELD_ROLE_PERMISSION, RXFORM_ROLE,
  MENUID_AUTHENTICATION_ROLE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import ProvincePage from './role.presentation';

const getInitialValues = (state) => {
  const { roles, uiFunctionalPage, uiRole } = state;
  const { data } = roles;
  const { downloadingDeleting, tappedId } = uiRole;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found) : {
      name: '',
      permissions: [],
      description: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  permissions: Object.values(state.permissions.data).map((x) => x.name),
  pageMode: state.uiFunctionalPage.pageMode,
  loadingPermission: state.uiPermission.downloading,
  addingEditing: state.uiRole.addingEditing,
  downloading: state.uiRole.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_ROLE));
    dispatch(setRoleSelectedPageSize(20));
    dispatch(setRoleSelectedOrderBy(INITIAL_ORDER_BY_ROLES));
    dispatch(downloadRolesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_ROLE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRolesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRoleSelectedPageSize(pageSize));
    dispatch(downloadRolesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteRoleAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setRoleTappedId(''));
    dispatch(setRoleSearchText(''));
    dispatch(setPermissionSearchText(''));
    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync(1, true))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDeletePressed: (id) => {
    dispatch(setRoleTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setRoleTappedId(id));
    dispatch(setRoleSearchText(''));
    dispatch(setPermissionSearchText(''));
    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync(1, true))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onPermissionSelected: (option) => {
    if (option.length > 0) {
      dispatch(setRoleSelectedPermission(option));
      dispatch(change(RXFORM_ROLE, RXFIELD_ROLE_PERMISSION, option));
    } else {
      dispatch(setRoleSelectedPermission([]));
      dispatch(change(RXFORM_ROLE, RXFIELD_ROLE_PERMISSION, []));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setRoleSelectedPageSize(pageSize));
    dispatch(clearRoles());
    dispatch(downloadRolesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditRoleAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setRoleSearchText(text));
      dispatch(clearRoles());
      await dispatch(downloadRolesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setRoleSelectedOrderBy(orderBy));
    dispatch(clearRoles());
    dispatch(downloadRolesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditRoleAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setRoleTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvincePage);
