import { SET_SUMMARY_LOGIN_USING, CLEAR_SUMMARY_LOGIN_USING } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SUMMARY_LOGIN_USING: {
      return action.data;
    }
    case CLEAR_SUMMARY_LOGIN_USING:
      return state;
    default:
      return state;
  }
};
