import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    articlesScreen: {
      title: 'Article',

      labelNo: 'No',
      labelTitle: 'Title',
      labelCategory: 'Category',
      labelPublishedDate: 'Published Date',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Order',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelSendNotificationTo: 'You are going to send notification to users',
      labelClickForMoreInfo: 'Click for more info',
      labelViewCount: 'View Count',
      labelHeadline: 'Headline',
      labelCopied: 'Copied!',

      buttonCaptionCreateNewArticle: 'Create New Article',
      buttonCaptionEditArticle: 'Edit Article',
      buttonCaptionDeleteArticle: 'Delete Article',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',
      buttonCaptionSendToMe: 'Send To Me',
      buttonCaptionSendToUsers: 'Send To Users',

      placeholderTitle: 'Title',
      placeholderContent: 'Content',
      placeholderPublishedDate: 'Published Date',
      placeholderCategory: 'Category',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderAuthor: 'Author',
      placeholderSourceName: 'Source Name',
      placeholderSourceLink: 'Source Link',
      placeholderShortUrl: 'Short URL',
      placeholderReactionId: 'Reaction ID',
      placeholderDiscussionId: 'Discussion ID',
      placeholderShortDescription: 'Short Description',
      placeholderViewCount: 'View Count',
      placeholderTags: 'Tags',
      placeholderHeadline: 'Headline',
      placeholderDeepLink: 'Deep Link',
    },
    articleCategoryScreen: {
      title: 'Article Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      buttonCaptionCreateNewArticleCategory: 'Create New Article Category',
      buttonCaptionDeleteArticleCategory: 'Delete Article Category',
      buttonCaptionEditArticleCategory: 'Edit Article Category',

      placeholderArticleCategoryName: 'Article Category Name',
      placeholderDescription: 'Description',
      placeholderArticleCategoryImage: 'Image',
    },
  },
  id: {
    articlesScreen: {
      title: 'Artikel',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelCategory: 'Kategori',
      labelPublishedDate: 'Tanggal Publikasi',
      labelStatus: 'Status',
      labelMedia: 'Media',
      labelOrder: 'Urutan',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelSendNotificationTo: 'Anda akan mengirim pemberitahuan kepada pengguna',
      labelClickForMoreInfo: 'Klik untuk info lebih lanjut',
      labelViewCount: 'Jumlah Tampilan',
      labelHeadline: 'Tajuk Utama',
      labelCopied: 'Disalin!',

      buttonCaptionCreateNewArticle: 'Buat Artikel Baru',
      buttonCaptionEditArticle: 'Ubah Artikel',
      buttonCaptionDeleteArticle: 'Hapus Artikel',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',
      buttonCaptionSendToMe: 'Kirim Kepada Saya',
      buttonCaptionSendToUsers: 'Kirim Kepada Pengguna',

      placeholderTitle: 'Judul',
      placeholderContent: 'Konten',
      placeholderPublishedDate: 'Tanggal Publikasi',
      placeholderCategory: 'Kategori',
      placeholderVisibilityStatus: 'Status Terlihat',
      placeholderAuthor: 'Pemilik',
      placeholderSourceName: 'Nama Sumber',
      placeholderSourceLink: 'Tautan Sumber',
      placeholderShortUrl: 'Tautan Pendek',
      placeholderReactionId: 'ID Reaksi',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderShortDescription: 'Short Description',
      placeholderViewCount: 'Jumlah Tampilan',
      placeholderTags: 'Tag',
      placeholderHeadline: 'Tajuk Utama',
      placeholderDeepLink: 'Tautan Dalam',
    },
    articleCategoryScreen: {
      title: 'Kategori Artikel',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewArticleCategory: 'Buat Kategori Artikel Baru',
      buttonCaptionDeleteArticleCategory: 'Hapus Kategori Artikel',
      buttonCaptionEditArticleCategory: 'Ubah Kategori Artikel',

      placeholderArticleCategoryName: 'Nama Kategori Artikel',
      placeholderDescription: 'Deskripsi',
      placeholderArticleCategoryImage: 'Gambar',
    },
  },
});

export default LocalizedString;
