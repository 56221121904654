import {
  DOWNLOADING_BRANCHES, ADDING_EDITING_BRANCH, DOWNLOADING_DELETING_BRANCH,
  SET_BRANCH_MAP_CENTER, SET_BRANCH_MAP_MARKER, SET_BRANCH_MAP_POLYGON,
  SET_BRANCH_SELECTED_PAGE_SIZE, SET_BRANCH_TAPPED_ID, SET_BRANCH_SEARCH_TEXT,
  SET_BRANCH_SELECTED_ORDER_BY, SET_BRANCH_SELECTED_CITY_ID, SAVING_BRANCHES,
  SET_BRANCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, ENABLING_DISABLING_BRANCH,
  SET_BRANCH_SELECTED_COMPANY_ID, RESYNCING_BRANCH,
} from '../action';
import { INITIAL_ORDER_BY_BRANCHES } from '../../constant';
import { isDecimal } from '../../helper';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  enablingDisabling: false,
  resyncing: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  selectedCityId: '',
  selectedCompanyId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_BRANCHES,
  center: null,
  marker: null,
  polygon: [],
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_BRANCHES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_BRANCH:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_BRANCH:
      return { ...state, downloadingDeleting: action.status };
    case SET_BRANCH_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_BRANCH_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_BRANCH_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_BRANCH_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_BRANCH_SELECTED_CITY_ID:
      return { ...state, selectedCityId: action.id };
    case SET_BRANCH_SELECTED_COMPANY_ID:
      return { ...state, selectedCompanyId: action.id };
    case SET_BRANCH_MAP_CENTER: {
      const { data } = action;
      const currLat = state.center ? state.center.lat : null;
      const currLng = state.center ? state.center.lng : null;

      if (isDecimal(data.latitude) && isDecimal(data.longitude)) {
        return {
          ...state,
          center: {
            lat: data.latitude && isDecimal(data.latitude) ? data.latitude : currLat,
            lng: data.longitude && isDecimal(data.longitude) ? data.longitude : currLng,
          },
        };
      }
      return { ...state };
    }
    case SET_BRANCH_MAP_MARKER: {
      const { data } = action;
      const currLat = state.marker ? state.marker.lat : null;
      const currLng = state.marker ? state.marker.lng : null;

      if (isDecimal(data.latitude) && isDecimal(data.longitude)) {
        return {
          ...state,
          marker: {
            lat: data.latitude && isDecimal(data.latitude) ? data.latitude : currLat,
            lng: data.longitude && isDecimal(data.longitude) ? data.longitude : currLng,
          },
        };
      }
      return { ...state };
    }
    case SET_BRANCH_MAP_POLYGON:
      return {
        ...state,
        polygon: action.data.length > 0
          ? action.data.map((x) => ({ lat: x.latitude, lng: x.longitude, order: x.order })) : [],
      };
    case SAVING_BRANCHES:
      return { ...state, saving: action.status };
    case SET_BRANCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ENABLING_DISABLING_BRANCH:
      return { ...state, enablingDisabling: action.status };
    case RESYNCING_BRANCH:
      return { ...state, resyncing: action.status };
    default: return state;
  }
};
