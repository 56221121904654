import {
  addCompanyMData, downloadingCompanyMData,
} from '../simple-action';
import { downloadCompanyMData } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCompanyMData(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCompany;

    const companiesMdata = await downloadCompanyMData(tappedId, token);
    dispatch(addCompanyMData(companiesMdata));
  } finally {
    dispatch(downloadingCompanyMData(false));
  }
};
