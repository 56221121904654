import { addWfa, downloadingWfa } from '../simple-action';
import { downloadWfa } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingWfa(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWfa;

    const wfaRequest = await downloadWfa(tappedId, token);
    dispatch(addWfa(wfaRequest));
  } finally {
    dispatch(downloadingWfa(false));
  }
};
