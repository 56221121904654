import { downloadCovidDataTotalSummary } from '../../../helper';
import {
  downloadingCovidDataTotalSummary, setCovidDataTotalSummary,
  clearCovidDataTotalSummary,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication, uiCovidDashboard } = getState();
  const { token } = authentication;
  const { selectedSummaryPeriod, selectedEmployeeStatus } = uiCovidDashboard;

  try {
    dispatch(downloadingCovidDataTotalSummary(true));
    const response = await downloadCovidDataTotalSummary(
      selectedSummaryPeriod, selectedEmployeeStatus, token,
    );
    dispatch(clearCovidDataTotalSummary());
    dispatch(setCovidDataTotalSummary(response));
  } finally {
    dispatch(downloadingCovidDataTotalSummary(false));
  }
};
