import moment from 'moment';
import {
  APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES, VOUCHER_TYPE_PUBLIC,
  RXFIELD_VOUCHER_CATEGORY_DESCRIPTION, RXFIELD_VOUCHER_CATEGORY_IMAGE,
  RXFIELD_VOUCHER_CATEGORY_NAME,
  RXFIELD_VOUCHER_ACTION_TYPE, RXFIELD_VOUCHER_APPLICABLE_AREA_MODE,
  RXFIELD_VOUCHER_APPLICABLE_BRANCHES, RXFIELD_VOUCHER_CATEGORY, RXFIELD_VOUCHER_CODE,
  RXFIELD_VOUCHER_DESCRIPTION, RXFIELD_VOUCHER_IMAGE,
  RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE, RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER,
  RXFIELD_VOUCHER_MERCHANT, RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE, RXFIELD_VOUCHER_POINT,
  RXFIELD_VOUCHER_STATUS, RXFIELD_VOUCHER_TITLE, RXFIELD_VOUCHER_TYPE, RXFIELD_VOUCHER_VALID_FROM,
  RXFIELD_VOUCHER_VALID_UNTIL, RXFIELD_VOUCHER_VISIBILITY, RXFIELD_TOKEN_REQUEST_VOUCHER,
  RXFIELD_TOKEN_REQUEST_QUANTITY, RXFIELD_TOKEN_REQUEST_VALID_FROM,
  RXFIELD_TOKEN_REQUEST_VALID_UNTIL, RXFIELD_VOUCHER_IS_PROMOTED,
  RXFIELD_VOUCHER_ORDER, RXFIELD_TOKEN_IMPORT_CODE,
  RXFIELD_TOKEN_IMPORT_VALID_FROM,
  RXFIELD_TOKEN_IMPORT_VALID_UNTIL,
  RXFIELD_TOKEN_IMPORT_VOUCHER,
  RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED,
  RXFIELD_PROMOTED_MERCHANT_ORDER,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';
import LocalizedString from '../../localization';
import { PAGE_MODE_EDIT, PAGE_MODE_TABLE } from '../configuration';

export const rxformValidateVoucherCategory = (values) => {
  const requiredFields = [
    RXFIELD_VOUCHER_CATEGORY_NAME,
    RXFIELD_VOUCHER_CATEGORY_DESCRIPTION,
    RXFIELD_VOUCHER_CATEGORY_IMAGE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateVoucher = (values) => {
  let requiredFields = [
    RXFIELD_VOUCHER_TITLE,
    RXFIELD_VOUCHER_TYPE,
    RXFIELD_VOUCHER_POINT,
    RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER,
    RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE,
    RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE,
    RXFIELD_VOUCHER_ACTION_TYPE,
    RXFIELD_VOUCHER_VISIBILITY,
    RXFIELD_VOUCHER_MERCHANT,
    RXFIELD_VOUCHER_APPLICABLE_AREA_MODE,
    RXFIELD_VOUCHER_CATEGORY,
    RXFIELD_VOUCHER_VALID_FROM,
    RXFIELD_VOUCHER_VALID_UNTIL,
    RXFIELD_VOUCHER_IMAGE,
    RXFIELD_VOUCHER_DESCRIPTION,
    RXFIELD_VOUCHER_IS_PROMOTED,
  ];

  const isCodeRequired = values.type === VOUCHER_TYPE_PUBLIC
    ? [...requiredFields, RXFIELD_VOUCHER_CODE] : requiredFields;
  requiredFields = isCodeRequired;
  const isApplicableBranchRequired = values.applicableAreaMode
  === APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES
    ? [...requiredFields, RXFIELD_VOUCHER_APPLICABLE_BRANCHES] : requiredFields;
  requiredFields = isApplicableBranchRequired;
  const isStatusRequired = values.pageMode === PAGE_MODE_TABLE
    ? [...requiredFields, RXFIELD_VOUCHER_STATUS] : requiredFields;
  requiredFields = isStatusRequired;
  const isOrderRequired = values.isPromoted
    ? [...requiredFields, RXFIELD_VOUCHER_ORDER] : requiredFields;
  requiredFields = isOrderRequired;

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateTokenRequest = (values) => {
  const requiredFields = [
    RXFIELD_TOKEN_REQUEST_VOUCHER,
    RXFIELD_TOKEN_REQUEST_QUANTITY,
    RXFIELD_TOKEN_REQUEST_VALID_FROM,
    RXFIELD_TOKEN_REQUEST_VALID_UNTIL,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateTokenImport = (values, props) => {
  const { pageMode } = props;
  if (pageMode === PAGE_MODE_EDIT) {
    const requiredFields = [
      RXFIELD_TOKEN_IMPORT_CODE,
      RXFIELD_TOKEN_IMPORT_VALID_FROM,
      RXFIELD_TOKEN_IMPORT_VALID_UNTIL,
    ];
    const errors = rxFormValidateRequiredFields(values, requiredFields);

    if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
      errors.validUntil = LocalizedString.common.errMsgStartEndDate;
    }
    return errors;
  }
  const requiredFields = [
    RXFIELD_TOKEN_IMPORT_VOUCHER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxFormValidatePromotedMerchant = (values) => {
  const requiredFields = [
    RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED,
    RXFIELD_PROMOTED_MERCHANT_ORDER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  return errors;
};
