import {
  DOWNLOADING_COMPANY_MAPPINGS, ADDING_EDITING_COMPANY_MAPPING,
  DOWNLOADING_DELETING_COMPANY_MAPPING, SET_COMPANY_MAPPING_SELECTED_PAGE_SIZE,
  SET_COMPANY_MAPPING_TAPPED_ID, SET_COMPANY_MAPPING_SEARCH_TEXT,
  SET_COMPANY_MAPPING_SELECTED_ORDER_BY, SET_COMPANY_MAPPING_SELECTED_OPERATOR,
} from '../action';
import { INITIAL_ORDER_BY_COMPANY_MAPPINGS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_COMPANY_MAPPINGS,
  selectedOperator: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_COMPANY_MAPPINGS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_COMPANY_MAPPING:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_COMPANY_MAPPING:
      return { ...state, downloadingDeleting: action.status };
    case SET_COMPANY_MAPPING_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_COMPANY_MAPPING_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_COMPANY_MAPPING_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_COMPANY_MAPPING_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_COMPANY_MAPPING_SELECTED_OPERATOR:
      return { ...state, selectedOperator: action.option };
    default: return state;
  }
};
