import { downloadingJobs, setJobs } from '../simple-action';
import { downloadJobs, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, jobs, uiJob } = getState();
  const { token } = authentication;
  const { meta, data } = jobs;
  const { selectedPageSize, searchBarText, orderBy } = uiJob;
  const { filterString } = getState().uiFunctionalPage;
  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;
  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }
  try {
    dispatch(downloadingJobs(true));
    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadJobs(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );
    if (list) {
      dispatch(setJobs(list));
    }
  } finally {
    dispatch(downloadingJobs(false));
  }
};
