import { connect } from 'react-redux';
import { setActiveSideMenuItem } from '../../redux/action';
import { MENUID_DASHBOARD } from '../../constant';
import DashboardPage from './dashboard.presentation';

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_DASHBOARD));
  },
});

export default connect(null, mapDispatchToProps)(DashboardPage);
