import {
  downloadingDeletingInbox,
  addInbox,
} from '../simple-action';
import { downloadInbox } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiInbox } = getState();

  const { token } = authentication;
  const { tappedId } = uiInbox;

  try {
    dispatch(downloadingDeletingInbox(true));

    const dailyCheckin = await downloadInbox(
      tappedId,
      token,
    );

    dispatch(addInbox(dailyCheckin));
  } finally {
    dispatch(downloadingDeletingInbox(false));
  }
};
