import { connect } from 'react-redux';
import ActiveUserChart from './active-users-chart.presentation';
import { getNDaysAgo, toMoment } from '../../helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  setAnalyticsActiveUserDateRanges, setActiveUserFilterDialogVisibility, setAlertErrorMessage,
  downloadAnalyticsActiveUsersAsync, clearAnalyticsActiveUserChartData,
} from '../../redux/action';

const getActiveUsersDateRange = (state) => {
  const { activeUserDateRange } = state.uiAnalytics;

  let dateFrom = activeUserDateRange.from;
  let dateTo = activeUserDateRange.to;

  if (activeUserDateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (activeUserDateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const mapStateToProps = (state) => ({
  chartData: state.analytics.activeUser
    ? state.analytics.activeUser : [],
  totalActiveUser: state.analytics.totalActiveUser,
  downloading: state.uiAnalytics.downloadingActiveUser,
  activeUserFilterDialogVisibility:
    state.uiAnalytics.activeUserFilterDialogVisibility,
  activeUsersDateRange: getActiveUsersDateRange(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(clearAnalyticsActiveUserChartData());
  },
  onFilterIconPressed: () => {
    dispatch(setActiveUserFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setActiveUserFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsActiveUserDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsActiveUsersAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setActiveUserFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsActiveUserDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsActiveUsersAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setActiveUserFilterDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUserChart);
