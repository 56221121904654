import {
  downloadingDeletingDocumentGeneratorTemplate, setAlertErrorMessage,
  setFunctionalPageMode, setDocumentGeneratorTemplateTappedId,
  clearDocumentGeneratorTemplates,
} from '../simple-action';
import { deleteDocumentGeneratorTemplate } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadDocumentGeneratorTemplatesAsync from './downloadDocumentGeneratorTemplatesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingDocumentGeneratorTemplate(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTemplate;

    await deleteDocumentGeneratorTemplate(tappedId, token);
    dispatch(setDocumentGeneratorTemplateTappedId(''));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    dispatch(clearDocumentGeneratorTemplates());
    dispatch(downloadDocumentGeneratorTemplatesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(downloadingDeletingDocumentGeneratorTemplate(false));
  }
};
