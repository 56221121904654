import { DOWNLOADING_LEADER_BOARD } from '../action';

const initialState = {
  downloading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_LEADER_BOARD:
      return { ...state, downloading: action.status };
    default: return state;
  }
};
