import { CLEAR_SUMMARY_ONLINE_USERS, SET_SUMMARY_ONLINE_USERS } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SUMMARY_ONLINE_USERS:
      return action.data;
    case CLEAR_SUMMARY_ONLINE_USERS:
      return {};
    default: return state;
  }
};
