import {
  addWhatsapp, downloadingDeletingWhatsapp, setFunctionalPageMode,
} from '../simple-action';
import { downloadWhatsapp } from '../../../helper';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingWhatsapp(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWhatsapp;

    const task = await downloadWhatsapp(tappedId, token);
    dispatch(addWhatsapp(task));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingWhatsapp(false));
  }
};
