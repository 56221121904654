import { downloadingSummaryActiveDevices, setSummaryActiveDevices } from '../simple-action';
import { downloadSummaryActiveDevices } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryActiveDevices(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryActiveDevices(token);

    dispatch(setSummaryActiveDevices(result));
  } finally {
    dispatch(downloadingSummaryActiveDevices(false));
  }
};
