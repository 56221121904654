import { connect } from 'react-redux';
import TotalUsersByAppVersionsChart from './total-users-by-app-versions-chart.presentation';
import { getNDaysAgo, toMoment } from '../../../../helper/helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  setAnalyticsUsersAppVersionChartDataDateRanges, setAlertErrorMessage,
  setUsersAppVersionChartDataFilterDialogVisibility, downloadAnalyticsUsersByAppVersionAsync,
  clearAnalyticsUsersAppVersionChartData,
} from '../../redux/action';

const getUsersByAppVersionsDateRange = (state) => {
  const { usersAppVersionChartDataDateRange } = state.uiAnalytics;

  let dateFrom = usersAppVersionChartDataDateRange.from;
  let dateTo = usersAppVersionChartDataDateRange.to;

  if (usersAppVersionChartDataDateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (usersAppVersionChartDataDateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const mapStateToProps = (state) => ({
  appVersions: state.analytics.top5AppVersions,
  chartData: state.analytics.usersAppVersionChartData
    ? Object.values(state.analytics.usersAppVersionChartData) : [],
  chartSeries: state.analytics.top5AppsChartSeries,
  downloading: state.uiAnalytics.downloadingUsersAppVersionChartData,
  usersByAppVersionsFilterDialogVisibility:
    state.uiAnalytics.usersAppVersionChartDataFilterDialogVisibility,
  usersByAppVersionsDateRange: getUsersByAppVersionsDateRange(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(clearAnalyticsUsersAppVersionChartData());
  },
  onFilterIconPressed: () => {
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsUsersAppVersionChartDataDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsUsersByAppVersionAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsUsersAppVersionChartDataDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsUsersByAppVersionAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalUsersByAppVersionsChart);
