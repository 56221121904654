import {
  clearAnnotationDesigns,
  deletingAnnotationDesign,
  setAnnotationDesignSelectedImage,
  setAnnotationDesignTappedAnnotationId,
  setAnnotationDesigns,
} from '../simple-action';

const deleteData = (data, id) => {
  const deletedIndex = data.findIndex((x) => x.id === id);

  data.splice(deletedIndex, 1);

  return data;
};

export default () => async (dispatch, getState) => {
  const { data } = getState().annotationDesigns;
  const { tappedAnnotationId } = getState().uiAnnotationDesign;

  try {
    dispatch(deletingAnnotationDesign(true));

    const newData = deleteData(data, tappedAnnotationId);

    dispatch(clearAnnotationDesigns());
    dispatch(setAnnotationDesigns(newData));
    dispatch(setAnnotationDesignTappedAnnotationId(''));
    dispatch(setAnnotationDesignSelectedImage('', ''));
  } finally {
    dispatch(deletingAnnotationDesign(false));
  }
};
