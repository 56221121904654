import { connect } from 'react-redux';
import { isEmpty, toNumber } from 'lodash';
import UserByCountry from './user-by-country.presentation';
import { getNDaysAgo, toMoment } from '../../../../helper/helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  clearAnalyticsCountriesByUsers, setAnalyticsUserByCountryDateRanges,
  setUserByCountryFilterDialogVisibility, setAlertErrorMessage,
} from '../../redux/action/simple-action';
import downloadAnalyticsUsersByCountryAsync from '../../redux/action/async/downloadAnalyticsUsersByCountryAsync';

const getFilterDateRange = (dateRange) => {
  let dateFrom = dateRange.from;
  let dateTo = dateRange.to;

  if (dateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (dateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const getUsersByCountryMapData = (state) => {
  const { countriesByUsers } = state.analytics;
  const data = !isEmpty(countriesByUsers)
    ? countriesByUsers.map((item) => [item.country, toNumber(item.count)])
    : [];
  const chartData = [['Country', 'Users'], ...data];
  return chartData;
};

const mapStateToProps = (state) => ({
  usersByCountryMapData: getUsersByCountryMapData(state),
  usersByCountry: state.analytics.countriesByUsers,
  downloadingCountriesByUsers: state.uiAnalytics.downloadingCountriesByUsers,
  userByCountryDateRange:
    getFilterDateRange(state.uiAnalytics.userByCountryDateRange),
  userByCountryFilterDialogVisibility:
        state.uiAnalytics.userByCountryFilterDialogVisibility,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    clearAnalyticsCountriesByUsers();
  },
  onFilterIconPressed: () => {
    dispatch(setUserByCountryFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setUserByCountryFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsUserByCountryDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsUsersByCountryAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserByCountryFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsUserByCountryDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsUsersByCountryAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserByCountryFilterDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserByCountry);
