import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearCustomers, clearIndustries, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setCustomerSearchText, setCustomerSelectedPageSize,
  setCustomerSelectedIndustry, setCustomerSelectedOrderBy, setCustomerTappedId,
  setIndustrySearchText,
  addEditCustomerAsync, downloadDeleteCustomerAsync, downloadCustomersAsync,
  downloadIndustriesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_CUSTOMERS, MENUID_ACTIVITY_MANAGEMENT_CUSTOMER, PAGE_MODE_TABLE, RXFORM_CUSTOMER,
  RXFIELD_CUSTOMER_INDUSTRY,
} from '../../constant';
import { transformDropdownData, transformInitialValues } from '../../helper';
import LocalizedString from '../../../../localization';
import CustomerPage from './customer.presentation';

const getInitialValues = (state) => {
  const { customers, uiCustomer, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiCustomer;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? customers.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      { industry: !isEmpty(found.industry) ? { label: found.industry.name, value: found.industry.id } : { label: '', value: '' } })
    : { industry: { label: '', value: '' } };
  return initVal;
};

const mapStateToProps = (state) => ({
  industries: transformDropdownData(state.industries.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiCustomer.addingEditing,
  downloading: state.uiCustomer.downloading,
  downloadingDeleting: state.uiCustomer.downloadingDeleting,
  loadingIndustry: state.uiIndustry.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ACTIVITY_MANAGEMENT_CUSTOMER));
    dispatch(setCustomerSearchText(''));
    dispatch(clearCustomers());
    dispatch(setCustomerSelectedPageSize(20));
    dispatch(setCustomerSelectedOrderBy(INITIAL_ORDER_BY_CUSTOMERS));
    dispatch(downloadCustomersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CUSTOMER));
    dispatch(setCustomerSelectedIndustry(''));
  },
  onChangeIndustryText: async (text) => {
    try {
      dispatch(setIndustrySearchText(text));
      dispatch(clearIndustries());
      await dispatch(downloadIndustriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCustomersAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCustomerSelectedPageSize(pageSize));
    dispatch(downloadCustomersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteCustomerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setCustomerTappedId(''));
    dispatch(setIndustrySearchText(''));
    dispatch(clearIndustries());
    dispatch(downloadIndustriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setCustomerTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setCustomerTappedId(id));
    dispatch(downloadDeleteCustomerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onIndustryOptionSelected: (option) => {
    if (option) {
      dispatch(setCustomerSelectedIndustry(option.value));
      dispatch(change(RXFORM_CUSTOMER, RXFIELD_CUSTOMER_INDUSTRY, option));
    } else {
      dispatch(setCustomerSelectedIndustry(''));
      dispatch(change(RXFORM_CUSTOMER, RXFIELD_CUSTOMER_INDUSTRY, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setCustomerSelectedPageSize(pageSize));
    dispatch(clearCustomers());
    dispatch(downloadCustomersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ refId, name, description }) => {
    try {
      await dispatch(addEditCustomerAsync(refId, name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCustomerSearchText(text));
      dispatch(clearCustomers());
      await dispatch(downloadCustomersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCustomerSelectedOrderBy(orderBy));
    dispatch(clearCustomers());
    dispatch(downloadCustomersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ refId, name, description }) => {
    await dispatch(addEditCustomerAsync(refId, name, description));
  },
  onViewPressed: (id) => {
    dispatch(setCustomerTappedId(id));
    dispatch(downloadDeleteCustomerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
