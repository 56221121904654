import {
  enablingDisablingSubdistrict, setAlertErrorMessage,
  setSubdistrictSelectedOrderBy, setSubdistrictSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_DISTRICTS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableSubdistrict } from '../../../helper';
import downloadSubdistrictsAsync from './downloadSubdistrictsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingSubdistrict(true));

    const { token } = getState().authentication;
    const { data } = getState().subdistricts;
    const { tappedId } = getState().uiSubdistrict;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableSubdistrict(tappedId, status, token);

    dispatch(setSubdistrictSelectedPageSize(20));
    dispatch(setSubdistrictSelectedOrderBy(INITIAL_ORDER_BY_DISTRICTS));
    dispatch(downloadSubdistrictsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(enablingDisablingSubdistrict(false));
  }
};
