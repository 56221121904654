import {
  DOWNLOADING_PACKETS, ADDING_EDITING_PACKET, DOWNLOADING_DELETING_PACKET,
  SET_PACKET_SELECTED_PAGE_SIZE, SET_PACKET_TAPPED_ID, SET_PACKET_SEARCH_TEXT,
  SET_PACKET_SELECTED_ORDER_BY, SET_PACKET_SELECTED_RECIPIENT, SET_PACKET_SELECTED_RECEIVED_BY,
  SET_PACKET_SELECTED_PACKET_TYPE, SET_PACKET_SELECTED_PICKUP_LOCATION, SAVING_PACKETS,
  PICKUP_UP_PACKET, SET_PACKET_PICKUP_DIALOG_VISIBILITY, SET_PACKET_SELECTED_PICKED_UP_BY,
  SET_PACKET_SELECTED_PICKED_UP_BY_USER, SET_PICK_UP_PACKET_UPLOAD_IMAGE_METHOD,
} from '../action';
import { INITIAL_ORDER_BY_PACKETS, UPLOAD_IMAGE_METHOD_SELECT_FILE } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  pickupDialogVisibility: false,
  pickingUp: false,
  saving: false,
  selectedPageSize: 20,
  selectedPacketType: '',
  selectedPickedUpBy: '',
  selectedPickedUpByUser: '',
  selectedPickupLocation: '',
  selectedReceivedBy: '',
  selectedRecipient: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PACKETS,
  uploadImageMethod: UPLOAD_IMAGE_METHOD_SELECT_FILE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PACKETS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_PACKET:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_PACKET:
      return { ...state, downloadingDeleting: action.status };
    case SET_PACKET_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PACKET_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PACKET_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PACKET_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PACKET_SELECTED_RECIPIENT:
      return { ...state, selectedRecipient: action.option };
    case SET_PACKET_SELECTED_PACKET_TYPE:
      return { ...state, selectedPacketType: action.option };
    case SET_PACKET_SELECTED_PICKUP_LOCATION:
      return { ...state, selectedPickupLocation: action.option };
    case SET_PACKET_SELECTED_RECEIVED_BY:
      return { ...state, selectedReceivedBy: action.option };
    case SAVING_PACKETS:
      return { ...state, saving: action.status };
    case PICKUP_UP_PACKET:
      return { ...state, pickingUp: action.status };
    case SET_PACKET_PICKUP_DIALOG_VISIBILITY:
      return { ...state, pickupDialogVisibility: action.status };
    case SET_PACKET_SELECTED_PICKED_UP_BY:
      return { ...state, selectedPickedUpBy: action.option };
    case SET_PACKET_SELECTED_PICKED_UP_BY_USER:
      return { ...state, selectedPickedUpByUser: action.option };
    case SET_PICK_UP_PACKET_UPLOAD_IMAGE_METHOD:
      return { ...state, uploadImageMethod: action.method };
    default: return state;
  }
};
