import {
  downloadingAttendanceSummaryClockOutLocationTrend, setAttendanceSummaryClockOutLocationTrend,
} from '../simple-action';
import { downloadAttendanceSummaryClockOutLocationTrend } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryClockOutLocationTrend(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryClockOutLocationTrend(token);

    dispatch(setAttendanceSummaryClockOutLocationTrend(data));
  } finally {
    dispatch(downloadingAttendanceSummaryClockOutLocationTrend(false));
  }
};
