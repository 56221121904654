import { sendingNewsNotification } from '../simple-action';
import { sendNewsNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(sendingNewsNotification(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiNews;

    await sendNewsNotification(tappedId, token);
  } finally {
    dispatch(sendingNewsNotification(false));
  }
};
