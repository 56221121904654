import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PrivateRoute, NavigationPrompt } from './component';
import { persistor, store } from './redux/store';
import {
  ROUTE_NAME_LOGIN, ROUTE_NAME_DASHBOARD,
  ROUTE_NAME_SUMMARY, ROUTE_NAME_SERVICE, ROUTE_NAME_SCOPE, ROUTE_NAME_API_KEY, ROUTE_NAME_TOKEN,
  ROUTE_NAME_PERMISSION, ROUTE_NAME_ROLE, ROUTE_NAME_USER,
  ROUTE_NAME_APPLICATION, ROUTE_NAME_BANNER, ROUTE_NAME_PROFILE,
  ROUTE_NAME_PROVINCE, ROUTE_NAME_CITY, ROUTE_NAME_COMPANY, ROUTE_NAME_BRANCH, ROUTE_NAME_DISTRICT,
  ROUTE_NAME_SUBDISTRICT, ROUTE_NAME_REPORT,
} from './constant';
import {
  SplashPage,
  LoginPage,
  DashboardPage,
  SummaryPage, ServicePage, ScopePage, ApiKeyPage, TokenPage, PermissionPage, RolePage, UserPage,
  ApplicationPage, BannerPage, ProfilePage,
  ProvincePage, CityPage, CompanyPage, BranchPage, DistrictPage, SubdistrictPage,
  ReportPage,
} from './page';
import {
  ConfigActionPage, ConfigGroupPage, ConfigItemPage, ConfigValuePage, ProfileSyncPage,
  ROUTE_NAME_CONFIG_ACTION, ROUTE_NAME_CONFIG_GROUP, ROUTE_NAME_CONFIG_ITEM,
  ROUTE_NAME_CONFIG_VALUE,
  ROUTE_NAME_PROFILE_SYNC,
} from './module/configuration';
import {
  AdditionalFieldPage, AttendanceSummaryPage, HistoryPage,
  ROUTE_NAME_ADDITIONAL_FIELD, ROUTE_NAME_ATTENDANCE_SUMMARY, ROUTE_NAME_HISTORY,
} from './module/attendance';
import {
  FaqPage, FaqCategoryPage, ROUTE_NAME_FAQ, ROUTE_NAME_FAQ_CATEGORY,
} from './module/faq';
import {
  EmailPage, InboxPage, SmsPage, WhatsappPage, PushNotificationPage, NotificationTaskPage,
  ROUTE_NAME_EMAIL, ROUTE_NAME_INBOX, ROUTE_NAME_SMS, ROUTE_NAME_WHATSAPP,
  ROUTE_NAME_PUSH_NOTIFICATION, ROUTE_NAME_NOTIFICATION_TASK,
} from './module/notification';
import {
  PacketPage, PacketTypePage, PickupLocationPage, PacketSummaryPage,
  ROUTE_NAME_PACKET, ROUTE_NAME_PACKET_TYPE, ROUTE_NAME_PICKUP_LOCATION, ROUTE_NAME_PACKET_SUMMARY,
} from './module/packet';
import {
  ActivityPage, ActivityTypePage, CustomerPage, IndustryPage,
  ROUTE_NAME_ACTIVITY, ROUTE_NAME_ACTIVITY_TYPE, ROUTE_NAME_CUSTOMER, ROUTE_NAME_INDUSTRY,
} from './module/activity';
import {
  CompanyMappingPage, InfoMappingPage, ROUTE_NAME_COMPANY_MAPPING, ROUTE_NAME_INFO_MAPPING,
} from './module/bcard';
import {
  ShortlinkPage, ROUTE_NAME_SHORTLINK_SCREEN,
} from './module/shortlink';
import {
  AuditTrailPage, ROUTE_NAME_AUDIT_TRAIL,
} from './module/audit';
import {
  AchievementPage, PointPage, PointSubmissionPage, PointTransactionPage,
  PurchaseRequestPage, VoidPointRequestPage, LeaderBoardPage,
  ROUTE_NAME_POINT_POINT, ROUTE_NAME_POINT_ACHIEVEMENT,
  ROUTE_NAME_POINT_POINT_SUBMISSION, ROUTE_NAME_POINT_POINT_TRANSACTION,
  ROUTE_NAME_PURCHASE_REQUEST, ROUTE_NAME_VOID_POINT_REQUEST, ROUTE_NAME_POINT_LEADER_BOARD,
} from './module/point';
import {
  TokenRequestPage, VoucherCategoryPage, VoucherPage, VoucherTokenPage,
  VoucherTokenImportPage, VoucherPurchaseRequestPage, PromotedMerchantPage,
  ROUTE_NAME_VOUCHER_CATEGORY, ROUTE_NAME_VOUCHER_TOKEN, ROUTE_NAME_VOUCHER_TOKEN_REQUEST,
  ROUTE_NAME_VOUCHER_VOUCHER, ROUTE_NAME_VOUCHER_TOKEN_IMPORT, ROUTE_NAME_VOUCHER_PURCHASE_REQUEST,
  ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT,
} from './module/voucher';
import {
  DiscussionCommentPage, ReportedCommentPage,
  ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT, ROUTE_NAME_DISCUSSION_REPORTED_COMMENT,
} from './module/discussion';
import {
  ForumCategoryPage, ForumPostPage, ROUTE_NAME_FORUM_CATEGORY, ROUTE_NAME_FORUM_POST,
} from './module/forum';
import {
  ChallengePage, GameQRCategoryPage, ROUTE_NAME_GAMEQR_CATEGORY, ROUTE_NAME_GAMEQR_CHALLENGE,
} from './module/game-qr';
import {
  CatalogPage, ModelPage, ROUTE_NAME_CATALOG, ROUTE_NAME_MODEL,
} from './module/car-catalog';
import {
  ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST, ServiceRequestPage,
} from './module/car-service';
import { ROUTE_NAME_REGISTRATION, RegistrationPage } from './module/car-registration';
import {
  NewsPage, NewsCategoryPage, ROUTE_NAME_NEWS, ROUTE_NAME_NEWS_CATEGORY,
  ROUTE_NAME_NEWS_DASHBOARD, NewsDashboardPage,
} from './module/news';
import {
  PartnerMerchantPage, ROUTE_NAME_PARTNER_MERCHANT,
} from './module/partner';
import {
  ArticleCategoryPage, ArticlesPage,
  ROUTE_NAME_ARTICLE_CATEGORY, ROUTE_NAME_ARTICLE,
} from './module/knowledge-management';
import {
  LocationPage, EventPage, ROUTE_NAME_AGENDA_LOCATION, ROUTE_NAME_AGENDA_EVENT,
} from './module/agenda';
import {
  ROUTE_NAME_COVID_DATA, CovidDataPage, ROUTE_NAME_COVID_DASHBOARD,
  CovidDashboardPage,
} from './module/covid';
import {
  ROUTE_NAME_SURVEY_CATEGORY, ROUTE_NAME_QUIZ_CATEGORY, ROUTE_NAME_QUIZ_QUIZ,
  ROUTE_NAME_SURVEY_SURVEY, ROUTE_NAME_SURVEY_QUESTION, ROUTE_NAME_SURVEY_RESPONSE,
  ROUTE_NAME_QUIZ_QUESTION, ROUTE_NAME_QUIZ_RESPONSE,
  SurveyCategoryPage, QuizCategoryPage, QuizPage, SurveyPage, SurveyQuestionPage,
  SurveyResponsePage, QuizQuestionPage, QuizResponsePage,
} from './module/survey-and-quiz';
import {
  TemplatePage, ROUTE_DOCUMENT_GENERATOR_TEMPLATE,
  DocumentPage, ROUTE_DOCUMENT_GENERATOR_DOCUMENT,
} from './module/document-generator';
import {
  AnnotationDesignerPage, ROUTE_NAME_ANNOTATION_DESIGNER,
} from './module/pdf';
import {
  ApprovalPage, ROUTE_NAME_APPROVAL,
} from './module/approval';
import {
  ROUTE_NAME_ATTENDANCE_CORRECTION, ROUTE_NAME_PERSONAL_HOLIDAY, ROUTE_NAME_WFA,
  ROUTE_NAME_WFA_QUOTA, ROUTE_NAME_WFA_REQUEST, ROUTE_NAME_WORKING_HOUR,
  ROUTE_NAME_WORKING_HOUR_ADJUSTMENT, ROUTE_NAME_HOLIDAY,
  AttendanceCorrectionPage, PersonalHolidayPage, WfaPage, WFAQuotaPage, WfaRequestPage,
  WorkingHourAdjustmentPage, WorkingHourPage, HolidayPage,
} from './module/attendanceaddon';
import {
  JobPage, ROUTE_NAME_JOB, MyJobPage, ROUTE_NAME_MY_JOB,
} from './module/user-job';
import { ROUTE_NAME_CALENDAR, CalendarPage } from './module/calendar';
import { AnalyticsPage, ROUTE_NAME_ANALYTICS } from './module/analytics';

moment.tz.setDefault('UTC');

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <NavigationPrompt />
        <div>
          <Switch>
            <PrivateRoute path={ROUTE_NAME_DASHBOARD} component={DashboardPage} />
            <PrivateRoute path={ROUTE_NAME_ANALYTICS} component={AnalyticsPage} />
            <PrivateRoute path={ROUTE_NAME_REPORT} component={ReportPage} />
            <PrivateRoute path={ROUTE_NAME_PROFILE} component={ProfilePage} />
            <PrivateRoute path={ROUTE_NAME_PROVINCE} component={ProvincePage} />
            <PrivateRoute path={ROUTE_NAME_CITY} component={CityPage} />
            <PrivateRoute path={ROUTE_NAME_DISTRICT} component={DistrictPage} />
            <PrivateRoute path={ROUTE_NAME_SUBDISTRICT} component={SubdistrictPage} />
            <PrivateRoute path={ROUTE_NAME_COMPANY} component={CompanyPage} />
            <PrivateRoute path={ROUTE_NAME_BRANCH} component={BranchPage} />
            <PrivateRoute path={ROUTE_NAME_APPLICATION} component={ApplicationPage} />
            <PrivateRoute path={ROUTE_NAME_SUMMARY} component={SummaryPage} />
            <PrivateRoute path={ROUTE_NAME_SERVICE} component={ServicePage} />
            <PrivateRoute path={ROUTE_NAME_API_KEY} component={ApiKeyPage} />
            <PrivateRoute path={ROUTE_NAME_TOKEN} component={TokenPage} />
            <PrivateRoute path={ROUTE_NAME_PERMISSION} component={PermissionPage} />
            <PrivateRoute path={ROUTE_NAME_ROLE} component={RolePage} />
            <PrivateRoute path={ROUTE_NAME_USER} component={UserPage} />
            <PrivateRoute path={ROUTE_NAME_SERVICE} component={ServicePage} />
            <PrivateRoute path={ROUTE_NAME_SCOPE} component={ScopePage} />
            <PrivateRoute path={ROUTE_NAME_NEWS} component={NewsPage} />
            <PrivateRoute path={ROUTE_NAME_NEWS_CATEGORY} component={NewsCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_GROUP} component={ConfigGroupPage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_ITEM} component={ConfigItemPage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_VALUE} component={ConfigValuePage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_ACTION} component={ConfigActionPage} />
            <PrivateRoute path={ROUTE_NAME_PROFILE_SYNC} component={ProfileSyncPage} />
            <PrivateRoute path={ROUTE_NAME_ADDITIONAL_FIELD} component={AdditionalFieldPage} />
            <PrivateRoute path={ROUTE_NAME_ATTENDANCE_SUMMARY} component={AttendanceSummaryPage} />
            <PrivateRoute path={ROUTE_NAME_HISTORY} component={HistoryPage} />
            <PrivateRoute path={ROUTE_NAME_NOTIFICATION_TASK} component={NotificationTaskPage} />
            <PrivateRoute path={ROUTE_NAME_EMAIL} component={EmailPage} />
            <PrivateRoute path={ROUTE_NAME_SMS} component={SmsPage} />
            <PrivateRoute path={ROUTE_NAME_WHATSAPP} component={WhatsappPage} />
            <PrivateRoute path={ROUTE_NAME_PUSH_NOTIFICATION} component={PushNotificationPage} />
            <PrivateRoute path={ROUTE_NAME_FAQ} component={FaqPage} />
            <PrivateRoute path={ROUTE_NAME_FAQ_CATEGORY} component={FaqCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_BANNER} component={BannerPage} />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_CATEGORY} component={VoucherCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_VOID_POINT_REQUEST} component={VoidPointRequestPage} />
            <PrivateRoute path={ROUTE_NAME_CATALOG} component={CatalogPage} />
            <PrivateRoute path={ROUTE_NAME_MODEL} component={ModelPage} />
            <PrivateRoute path={ROUTE_NAME_ARTICLE} component={ArticlesPage} />
            <PrivateRoute path={ROUTE_NAME_ARTICLE_CATEGORY} component={ArticleCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_AGENDA_LOCATION} component={LocationPage} />
            <PrivateRoute path={ROUTE_NAME_AGENDA_EVENT} component={EventPage} />
            <PrivateRoute path={ROUTE_NAME_AUDIT_TRAIL} component={AuditTrailPage} />
            <PrivateRoute path={ROUTE_NAME_SHORTLINK_SCREEN} component={ShortlinkPage} />
            <PrivateRoute path={ROUTE_NAME_PICKUP_LOCATION} component={PickupLocationPage} />
            <PrivateRoute path={ROUTE_NAME_PACKET_TYPE} component={PacketTypePage} />
            <PrivateRoute path={ROUTE_NAME_PACKET} component={PacketPage} />
            <PrivateRoute path={ROUTE_NAME_PACKET_SUMMARY} component={PacketSummaryPage} />
            <PrivateRoute path={ROUTE_NAME_CUSTOMER} component={CustomerPage} />
            <PrivateRoute path={ROUTE_NAME_INDUSTRY} component={IndustryPage} />
            <PrivateRoute path={ROUTE_NAME_ACTIVITY} component={ActivityPage} />
            <PrivateRoute path={ROUTE_NAME_ACTIVITY_TYPE} component={ActivityTypePage} />
            <PrivateRoute path={ROUTE_NAME_COMPANY_MAPPING} component={CompanyMappingPage} />
            <PrivateRoute path={ROUTE_NAME_INFO_MAPPING} component={InfoMappingPage} />
            <PrivateRoute
              path={ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST}
              component={ServiceRequestPage}
            />
            <PrivateRoute path={ROUTE_NAME_FORUM_CATEGORY} component={ForumCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_FORUM_POST} component={ForumPostPage} />
            <PrivateRoute path={ROUTE_NAME_GAMEQR_CATEGORY} component={GameQRCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_GAMEQR_CHALLENGE} component={ChallengePage} />
            <PrivateRoute path={ROUTE_NAME_POINT_POINT} component={PointPage} />
            <PrivateRoute path={ROUTE_NAME_PURCHASE_REQUEST} component={PurchaseRequestPage} />
            <PrivateRoute
              path={ROUTE_NAME_POINT_POINT_SUBMISSION}
              component={PointSubmissionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_POINT_POINT_TRANSACTION}
              component={PointTransactionPage}
            />
            <PrivateRoute path={ROUTE_NAME_POINT_ACHIEVEMENT} component={AchievementPage} />
            <PrivateRoute path={ROUTE_NAME_POINT_LEADER_BOARD} component={LeaderBoardPage} />
            <PrivateRoute
              path={ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT}
              component={DiscussionCommentPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_DISCUSSION_REPORTED_COMMENT}
              component={ReportedCommentPage}
            />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_VOUCHER} component={VoucherPage} />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_TOKEN_REQUEST} component={TokenRequestPage} />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_TOKEN}
              component={VoucherTokenPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_TOKEN_IMPORT}
              component={VoucherTokenImportPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_PURCHASE_REQUEST}
              component={VoucherPurchaseRequestPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT}
              component={PromotedMerchantPage}
            />
            <PrivateRoute path={ROUTE_NAME_PARTNER_MERCHANT} component={PartnerMerchantPage} />
            <PrivateRoute path={ROUTE_NAME_NEWS_DASHBOARD} component={NewsDashboardPage} />
            <PrivateRoute path={ROUTE_NAME_COVID_DATA} component={CovidDataPage} />
            <PrivateRoute path={ROUTE_NAME_COVID_DASHBOARD} component={CovidDashboardPage} />
            <PrivateRoute path={ROUTE_NAME_SURVEY_CATEGORY} component={SurveyCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_QUIZ_CATEGORY} component={QuizCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_QUIZ_QUIZ} component={QuizPage} />
            <PrivateRoute path={ROUTE_NAME_SURVEY_SURVEY} component={SurveyPage} />
            <PrivateRoute path={ROUTE_NAME_SURVEY_QUESTION} component={SurveyQuestionPage} />
            <PrivateRoute path={ROUTE_NAME_SURVEY_RESPONSE} component={SurveyResponsePage} />
            <PrivateRoute path={ROUTE_NAME_QUIZ_QUESTION} component={QuizQuestionPage} />
            <PrivateRoute path={ROUTE_NAME_QUIZ_RESPONSE} component={QuizResponsePage} />
            <PrivateRoute path={ROUTE_DOCUMENT_GENERATOR_TEMPLATE} component={TemplatePage} />
            <PrivateRoute path={ROUTE_DOCUMENT_GENERATOR_DOCUMENT} component={DocumentPage} />
            <PrivateRoute
              path={ROUTE_NAME_ANNOTATION_DESIGNER}
              component={AnnotationDesignerPage}
            />
            <PrivateRoute path={ROUTE_NAME_APPROVAL} component={ApprovalPage} />
            <PrivateRoute path={ROUTE_NAME_INBOX} component={InboxPage} />
            <PrivateRoute path={ROUTE_NAME_WORKING_HOUR} component={WorkingHourPage} />
            <PrivateRoute path={ROUTE_NAME_WFA_REQUEST} component={WfaRequestPage} />
            <PrivateRoute path={ROUTE_NAME_WFA} component={WfaPage} />
            <PrivateRoute
              path={ROUTE_NAME_WORKING_HOUR_ADJUSTMENT}
              component={WorkingHourAdjustmentPage}
            />
            <PrivateRoute path={ROUTE_NAME_WFA_QUOTA} component={WFAQuotaPage} />
            <PrivateRoute
              path={ROUTE_NAME_ATTENDANCE_CORRECTION}
              component={AttendanceCorrectionPage}
            />
            <PrivateRoute path={ROUTE_NAME_MY_JOB} component={MyJobPage} />
            <PrivateRoute path={ROUTE_NAME_JOB} component={JobPage} />
            <PrivateRoute path={ROUTE_NAME_CALENDAR} component={CalendarPage} />
            <PrivateRoute path={ROUTE_NAME_HOLIDAY} component={HolidayPage} />
            <PrivateRoute path={ROUTE_NAME_PERSONAL_HOLIDAY} component={PersonalHolidayPage} />
            {/* all routes except these 3 routes below must be PrivateRoute */}
            <Route path={ROUTE_NAME_LOGIN} component={LoginPage} />
            <Route path={ROUTE_NAME_REGISTRATION} component={RegistrationPage} />
            <Route path="/" component={SplashPage} />
          </Switch>
        </div>
      </Router>
    </PersistGate>
  </Provider>
);
