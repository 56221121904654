import { SET_SUMMARY_ACTIVE_DEVICES, CLEAR_SUMMARY_ACTIVE_DEVICES } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SUMMARY_ACTIVE_DEVICES: {
      return action.data;
    }
    case CLEAR_SUMMARY_ACTIVE_DEVICES:
      return state;
    default:
      return state;
  }
};
