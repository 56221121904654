import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  APPLIED_ON, approval, COLOR_BACKGROUND, COLOR_DISABLED_ROW, COLOR_PRIMARY, ENUM_FIELD_DATE,
  ENUM_FIELD_DATE_TIME, ENUM_FIELD_FLOAT, ENUM_FIELD_INTEGER, ENUM_FIELD_OPTIONS, ENUM_FIELD_PHOTO,
  ENUM_FIELD_PICKER, ENUM_FIELD_TEXT, ENUM_FIELD_TIME, FIELD_TYPES, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_SWITCH, KEYBOARD_TYPES, LOGICAL_OPERATOR, OPTION_MODES, OPTION_VALUE_SEPARATORS,
  PAGE_MODE_VIEW, PICKER_MODE_DATE, PICKER_MODE_DATE_TIME, PICKER_MODE_TIME, PICKER_MODES,
  RXFIELD_ADDITIONAL_FIELD_APPLIED_ON, RXFIELD_ADDITIONAL_FIELD_DATE_TIME_MAX_VALUE,
  RXFIELD_ADDITIONAL_FIELD_DATE_TIME_MIN_VALUE, RXFIELD_ADDITIONAL_FIELD_DEFAULT_VALUE,
  RXFIELD_ADDITIONAL_FIELD_FLOAT_MAX_VALUE, RXFIELD_ADDITIONAL_FIELD_FLOAT_MIN_VALUE,
  RXFIELD_ADDITIONAL_FIELD_INT_MAX_VALUE, RXFIELD_ADDITIONAL_FIELD_INT_MIN_VALUE,
  RXFIELD_ADDITIONAL_FIELD_LABEL, RXFIELD_ADDITIONAL_FIELD_MUST_USE_CAMERA,
  RXFIELD_ADDITIONAL_FIELD_NAME, RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE,
  RXFIELD_ADDITIONAL_FIELD_OPTION_MODE, RXFIELD_ADDITIONAL_FIELD_OPTION_VALUE_SEPARATOR,
  RXFIELD_ADDITIONAL_FIELD_OPTION_VALUES, RXFIELD_ADDITIONAL_FIELD_ORDER,
  RXFIELD_ADDITIONAL_FIELD_PARENT, RXFIELD_ADDITIONAL_FIELD_PICKER_MODE,
  RXFIELD_ADDITIONAL_FIELD_PLACEHOLDER, RXFIELD_ADDITIONAL_FIELD_READ_ONLY,
  RXFIELD_ADDITIONAL_FIELD_REQUIRED, RXFIELD_ADDITIONAL_FIELD_SECTION_ID,
  RXFIELD_ADDITIONAL_FIELD_SECTION_LABEL,
  RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR,
  RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE, RXFIELD_ADDITIONAL_FIELD_TEXT_KEYBOARD_TYPE,
  RXFIELD_ADDITIONAL_FIELD_TEXT_MAX_LENGTH, RXFIELD_ADDITIONAL_FIELD_TEXT_MIN_LENGTH,
  RXFIELD_ADDITIONAL_FIELD_TEXT_MULTILINE, RXFIELD_ADDITIONAL_FIELD_TEXT_REGEX_VALIDATION,
  RXFIELD_ADDITIONAL_FIELD_VALIDATION_MESSAGE, RXFIELD_ADDITIONAL_FIELD_VISIBLE,
  RXSTATE_ADDITIONAL_FIELD_PAGE, RXSTATE_ADDITIONAL_FIELDS, RXFORM_ADDITIONAL_FIELD,
  STATUS_DISABLED, STATUS_ENABLED, status,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField, renderReduxFormSwitchField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../../../type';
import { rxformValidateAdditionalField } from '../../validation';

const useStyles = makeStyles(() => ({
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
}));

const renderAdditionalField = (initialValues, addingEditing, downloadingDeleting,
  onMustUseCameraOptionSelected, onOptionModeOptionSelected, onOptionValueSeparatorOptionSelected,
  onPickerModeOptionSelected, onTextKeyboardTypeOptionSelected, onTextMultilineOptionSelected,
  pageMode, selectedFieldType) => {
  const datePickerMode = selectedFieldType === ENUM_FIELD_DATE ? PICKER_MODE_DATE
    : PICKER_MODE_TIME;
  const dateTimePickerMode = selectedFieldType === ENUM_FIELD_DATE_TIME ? PICKER_MODE_DATE_TIME
    : datePickerMode;

  switch (selectedFieldType) {
    case ENUM_FIELD_DATE:
    case ENUM_FIELD_DATE_TIME:
    case ENUM_FIELD_TIME:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_DATE_TIME_MIN_VALUE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.additionalFieldScreen.placeholderDateTimeMinValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              pickerMode={dateTimePickerMode}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_DATE_TIME_MAX_VALUE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.additionalFieldScreen.placeholderDateTimeMaxValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              pickerMode={dateTimePickerMode}
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_TEXT:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_TEXT_MULTILINE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderTextMultiline}
              label={LocalizedString.additionalFieldScreen.placeholderTextMultiline}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.textMultiline}
              onOptionSelected={onTextMultilineOptionSelected}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_TEXT_KEYBOARD_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderTextKeyboardType}
              label={LocalizedString.additionalFieldScreen.placeholderTextKeyboardType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={KEYBOARD_TYPES}
              value={initialValues.textKeyboardType}
              onOptionSelected={onTextKeyboardTypeOptionSelected}
              required={selectedFieldType === ENUM_FIELD_TEXT}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_TEXT_MIN_LENGTH}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderTextMinLength}
              label={LocalizedString.additionalFieldScreen.placeholderTextMinLength}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_TEXT_MAX_LENGTH}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderTextMaxLength}
              label={LocalizedString.additionalFieldScreen.placeholderTextMaxLength}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_TEXT_REGEX_VALIDATION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderTextRegexValidation}
              label={LocalizedString.additionalFieldScreen.placeholderTextRegexValidation}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_INTEGER:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_INT_MIN_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderIntMinValue}
              label={LocalizedString.additionalFieldScreen.placeholderIntMinValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_INT_MAX_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderIntMaxValue}
              label={LocalizedString.additionalFieldScreen.placeholderIntMaxValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_FLOAT:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_FLOAT_MIN_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderFloatMinValue}
              label={LocalizedString.additionalFieldScreen.placeholderFloatMinValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_FLOAT_MAX_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderFloatMaxValue}
              label={LocalizedString.additionalFieldScreen.placeholderFloatMaxValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_PICKER:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_PICKER_MODE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderPickerMode}
              label={LocalizedString.additionalFieldScreen.placeholderPickerMode}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={PICKER_MODES}
              value={initialValues.pickerMode}
              onOptionSelected={onPickerModeOptionSelected}
              required={selectedFieldType === ENUM_FIELD_PICKER}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_OPTION_VALUES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderOptionValues}
              label={LocalizedString.additionalFieldScreen.labelOptionValues}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              required={selectedFieldType === ENUM_FIELD_PICKER}
              secondaryHelperText={LocalizedString.additionalFieldScreen.labelOptionValuesHelper}
              useHelperIcon
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_OPTIONS:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_OPTION_MODE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderOptionMode}
              label={LocalizedString.additionalFieldScreen.placeholderOptionMode}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={OPTION_MODES}
              value={initialValues.optionMode}
              onOptionSelected={onOptionModeOptionSelected}
              required={selectedFieldType === ENUM_FIELD_OPTIONS}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_OPTION_VALUES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderOptionValues}
              label={LocalizedString.additionalFieldScreen.labelOptionValues}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              required={selectedFieldType === ENUM_FIELD_OPTIONS}
              secondaryHelperText={LocalizedString.additionalFieldScreen.labelOptionValuesHelper}
              useHelperIcon
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_OPTION_VALUE_SEPARATOR}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderOptionValueSeparator}
              label={LocalizedString.additionalFieldScreen.placeholderOptionValueSeparator}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={OPTION_VALUE_SEPARATORS}
              value={initialValues.optionValueSeparator}
              onOptionSelected={onOptionValueSeparatorOptionSelected}
              required={selectedFieldType === ENUM_FIELD_OPTIONS}
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_PHOTO:
      return (
        <Grid item>
          <Field
            name={RXFIELD_ADDITIONAL_FIELD_MUST_USE_CAMERA}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.additionalFieldScreen.placeholderMustUseCamera}
            label={LocalizedString.additionalFieldScreen.placeholderMustUseCamera}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.mustUseCamera}
            onOptionSelected={onMustUseCameraOptionSelected}
            required={selectedFieldType === ENUM_FIELD_PHOTO}
          />
        </Grid>
      );
    default: return null;
  }
};

const AdditionalFieldPage = ({
  initialValues, parents,
  addingEditing, downloading, downloadingDeleting, enablingDisabling, loadingParents,
  handleSubmit, onAppear, onAppliedOnOptionSelected, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onCancelPressed, onChangePage, onChangePageSize,
  onChangeParentText, onConfirmPressed, onCreatePressed, onDeletePressed, onEditPressed,
  onEnableDisablePressed, onFieldTypeOptionSelected, onLogicalOperatorOptionSelected,
  onMustUseCameraOptionSelected, onOptionModeOptionSelected, onOptionValueSeparatorOptionSelected,
  onParentOptionSelected, onPickerModeOptionSelected, onReadOnlyOptionSelected, onRefresh,
  onResetAdvancedFilterPressed, onRequiredOptionSelected, onSavePressed, onSearchBarTextChanged,
  onSortPressed, onSubmitPressed, onTextKeyboardTypeOptionSelected, onTextMultilineOptionSelected,
  onViewPressed, onVisibleOptionSelected,
  selectedParent,
  currentAdditionalFieldStatus, pageMode, selectedFieldType, tappedId,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_ADDITIONAL_FIELDS}
      uiPage={RXSTATE_ADDITIONAL_FIELD_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.additionalFieldScreen.buttonCaptionEnable,
          disabled: currentAdditionalFieldStatus === STATUS_ENABLED || enablingDisabling,
          onPress: () => onEnableDisablePressed(tappedId,
            LocalizedString.additionalFieldScreen.msgEnableConfirmation),
        },
        {
          caption: LocalizedString.additionalFieldScreen.buttonCaptionDisable,
          disabled: currentAdditionalFieldStatus === STATUS_DISABLED || enablingDisabling,
          onPress: () => onEnableDisablePressed(tappedId,
            LocalizedString.additionalFieldScreen.msgDisableConfirmation),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.additionalFieldScreen.labelFieldType, field: 'fieldType', type: FILTER_TYPE_DROPDOWN, data: FIELD_TYPES,
        },
        {
          title: LocalizedString.additionalFieldScreen.labelAppliedOn, field: 'appliedOn', type: FILTER_TYPE_DROPDOWN, data: APPLIED_ON,
        },
        { title: LocalizedString.additionalFieldScreen.labelRequired, field: 'required', type: FILTER_TYPE_SWITCH },
        { title: LocalizedString.additionalFieldScreen.labelVisible, field: 'visible', type: FILTER_TYPE_SWITCH },
        { title: LocalizedString.additionalFieldScreen.labelReadOnly, field: 'readOnly', type: FILTER_TYPE_SWITCH },
        {
          title: LocalizedString.additionalFieldScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },

      ]}
      tableColumns={[
        {
          title: LocalizedString.additionalFieldScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.additionalFieldScreen.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.additionalFieldScreen.labelLabel, field: 'label', sorting: !downloading },
        { title: LocalizedString.additionalFieldScreen.labelAppliedOn, field: 'appliedOn', sorting: !downloading },
        { title: LocalizedString.additionalFieldScreen.labelFieldType, field: 'fieldType', sorting: !downloading },
        { title: LocalizedString.additionalFieldScreen.labelOrder, field: 'order', sorting: !downloading },
        { title: LocalizedString.additionalFieldScreen.labelVisible, field: 'visible', sorting: !downloading },
        { title: LocalizedString.additionalFieldScreen.labelRequired, field: 'required', sorting: !downloading },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmPressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      createNewButtonCaption={LocalizedString.additionalFieldScreen
        .buttonCaptionCreateNewAdditionalField}
      deleteButtonCaption={LocalizedString.additionalFieldScreen.buttonCaptionDeleteAdditionalField}
      title={LocalizedString.additionalFieldScreen.title}
      editButtonCaption={LocalizedString.additionalFieldScreen.buttonCaptionEditAdditionalField}
      useFullWidth
      usefullWidthDialog
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
    >
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_ADDITIONAL_FIELD_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.additionalFieldScreen.placeholderName}
                label={LocalizedString.additionalFieldScreen.placeholderName}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                required
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_ADDITIONAL_FIELD_LABEL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.additionalFieldScreen.placeholderLabel}
                label={LocalizedString.additionalFieldScreen.placeholderLabel}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                required
              />
            </Grid>
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_PLACEHOLDER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderPlaceholder}
              label={LocalizedString.additionalFieldScreen.placeholderPlaceholder}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={selectedFieldType === ENUM_FIELD_PHOTO}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_PARENT}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderParent}
              label={LocalizedString.additionalFieldScreen.placeholderParent}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={parents}
              value={initialValues.parent}
              loading={loadingParents}
              onChangeText={onChangeParentText}
              onOptionSelected={onParentOptionSelected}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderShowIfParentValue}
              label={LocalizedString.additionalFieldScreen.placeholderShowIfParentValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={!selectedParent.value}
              secondaryHelperText={LocalizedString.additionalFieldScreen.labelLogicalOperatorHelper}
              useHelperIcon
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderLogicalOperator}
              label={LocalizedString.additionalFieldScreen.placeholderLogicalOperator}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={LOGICAL_OPERATOR}
              value={initialValues.showIfParentValueLogicalOperator}
              onOptionSelected={onLogicalOperatorOptionSelected}
              hidden={!selectedParent.value}
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item sm md>
          {selectedFieldType === ENUM_FIELD_PHOTO ? (
            <Grid item>
              <Field
                name={RXFIELD_ADDITIONAL_FIELD_ORDER}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.additionalFieldScreen.placeholderOrder}
                label={LocalizedString.additionalFieldScreen.placeholderOrder}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                type="number"
                required
              />
            </Grid>
          ) : (
            <Grid item container>
              <Grid item sm md>
                <Field
                  name={RXFIELD_ADDITIONAL_FIELD_DEFAULT_VALUE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.additionalFieldScreen.placeholderDefaultValue}
                  label={LocalizedString.additionalFieldScreen.placeholderDefaultValue}
                  disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                  hidden={selectedFieldType === ENUM_FIELD_PHOTO}
                />
              </Grid>
              <Grid item sm md>
                <Field
                  name={RXFIELD_ADDITIONAL_FIELD_ORDER}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.additionalFieldScreen.placeholderOrder}
                  label={LocalizedString.additionalFieldScreen.placeholderOrder}
                  disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                  type="number"
                  required
                />
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_VALIDATION_MESSAGE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderValidationMessage}
              label={LocalizedString.additionalFieldScreen.placeholderValidationMessage}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_ADDITIONAL_FIELD_REQUIRED}
                component={renderReduxFormSwitchField}
                label={LocalizedString.additionalFieldScreen.placeholderRequired}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                onOptionSelected={onRequiredOptionSelected}
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_ADDITIONAL_FIELD_VISIBLE}
                component={renderReduxFormSwitchField}
                label={LocalizedString.additionalFieldScreen.placeholderVisible}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                onOptionSelected={onVisibleOptionSelected}
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_ADDITIONAL_FIELD_READ_ONLY}
                component={renderReduxFormSwitchField}
                label={LocalizedString.additionalFieldScreen.placeholderReadOnly}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                onOptionSelected={onReadOnlyOptionSelected}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.additionalFieldScreen.placeholderFieldType}
              label={LocalizedString.additionalFieldScreen.placeholderFieldType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={FIELD_TYPES}
              value={initialValues.fieldTypes}
              onOptionSelected={onFieldTypeOptionSelected}
              required
            />
          </Grid>
          {renderAdditionalField(initialValues, addingEditing, downloadingDeleting,
            onMustUseCameraOptionSelected, onOptionModeOptionSelected,
            onOptionValueSeparatorOptionSelected, onPickerModeOptionSelected,
            onTextKeyboardTypeOptionSelected, onTextMultilineOptionSelected,
            pageMode, selectedFieldType)}
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_ADDITIONAL_FIELD_SECTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.additionalFieldScreen.placeholderSectionId}
            label={LocalizedString.additionalFieldScreen.placeholderSectionId}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ADDITIONAL_FIELD_SECTION_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.additionalFieldScreen.placeholderSectionLabel}
            label={LocalizedString.additionalFieldScreen.placeholderSectionLabel}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ADDITIONAL_FIELD_APPLIED_ON}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.additionalFieldScreen.placeholderAppliedOn}
            label={LocalizedString.additionalFieldScreen.placeholderAppliedOn}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={APPLIED_ON}
            value={initialValues.appliedOn}
            onOptionSelected={onAppliedOnOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_ADDITIONAL_FIELD,
  validate: rxformValidateAdditionalField,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AdditionalFieldPage);

AdditionalFieldPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  parents: PropTypes.arrayOf(PropTypes.object).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingParents: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAppliedOnOptionSelected: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeParentText: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onFieldTypeOptionSelected: PropTypes.func.isRequired,
  onLogicalOperatorOptionSelected: PropTypes.func.isRequired,
  onMustUseCameraOptionSelected: PropTypes.func.isRequired,
  onOptionModeOptionSelected: PropTypes.func.isRequired,
  onOptionValueSeparatorOptionSelected: PropTypes.func.isRequired,
  onParentOptionSelected: PropTypes.func.isRequired,
  onPickerModeOptionSelected: PropTypes.func.isRequired,
  onReadOnlyOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRequiredOptionSelected: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onTextKeyboardTypeOptionSelected: PropTypes.func.isRequired,
  onTextMultilineOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibleOptionSelected: PropTypes.func.isRequired,
  selectedParent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  currentAdditionalFieldStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedFieldType: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
