import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, IconButton,
  CircularProgress, Dialog, LinearProgress,
} from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import LocalizedString from '../../localization';
import GlobalizedLocalizedString from '../../../../localization';
import { toCurrency, toMoment } from '../../../../helper';
import {
  COLOR_PRIMARY,
  PICKER_MODE_DATE,
  COLOR_ICON_LIGHTER,
  SIMPLE_DATE_FORMAT,
  COLOR_BODY_TEXT,
} from '../../../../constant';
import { AccentButton, DateTimePickerField } from '../../../../component';

const useStyles = makeStyles(() => ({
  w500: {
    fontWeight: '500',
  },
  mb10: {
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
    display: 'flex',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  tableHeader: {
    textTransform: 'uppercase',
    borderBottomStyle: 'dashed',
    fontWeight: 500,
  },
  loading: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  colorPrimary: {
    backgroundColor: COLOR_ICON_LIGHTER,
  },
  barColorPrimary: {
    backgroundColor: COLOR_PRIMARY,
  },
  filterButtonActions: {
    gap: '10px',
    marginRight: 8,
    marginTop: 10,
  },
  buttonOutline: {
    color: COLOR_BODY_TEXT,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
}));

const UserByDeviceModel = ({
  usersByDeviceModel, downloadingDeviceModelsByUsers,
  userByDeviceModelFilterDialogVisibility, onCloseDialogPressed,
  userByDeviceModelDateRange, onResetFilterPressed, onApplyFilterPressed,
  onFilterIconPressed,
}) => {
  const [dateRange, setDateRange] = useState(userByDeviceModelDateRange);
  const classes = useStyles();

  useEffect(() => {
    setDateRange({
      from: userByDeviceModelDateRange.from,
      to: userByDeviceModelDateRange.to,
    });
  }, [userByDeviceModelDateRange]);

  return (
    <>
      <div className={`${classes.row} ${classes.mb10}`}>
        <Typography
          variant="body1"
          className={`${classes.w500} ${classes.flex1} ${classes.mb10}`}
        >
          {`${LocalizedString.analyticsScreen.labelUsersByDeviceModel} (${toMoment(userByDeviceModelDateRange.from).format(SIMPLE_DATE_FORMAT)} - ${toMoment(userByDeviceModelDateRange.to).format(SIMPLE_DATE_FORMAT)})`}
        </Typography>
        <IconButton edge="end" onClick={onFilterIconPressed}>
          <Tune style={{ fill: COLOR_PRIMARY }} />
        </IconButton>
      </div>
      <div className={classes.mb10}>
        <div className={`${classes.row} ${classes.spaceBetween}`}>
          <div style={{ flex: 2 }}>
            <span className={classes.tableHeader}>
              {LocalizedString.analyticsScreen.labelDeviceModel}
            </span>
          </div>
          <div className={`${classes.flexEnd} ${classes.flex1}`} style={{ marginRight: 20 }}>
            <span className={classes.tableHeader}>
              {LocalizedString.analyticsScreen.labelUsers}
            </span>
          </div>
        </div>
      </div>

      {
        downloadingDeviceModelsByUsers ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
          </div>
        ) : (
          <>
            {usersByDeviceModel && usersByDeviceModel.map((data) => (
              <div className={classes.mb10} key={data.device}>
                <div className={`${classes.row} ${classes.spaceBetween}`}>
                  <Typography
                    variant="body2"
                    className={classes.w500}
                    style={{ flex: 2 }}
                  >
                    {data.device}
                  </Typography>

                  <div className={`${classes.flexEnd} ${classes.flex1}`} style={{ marginRight: 20 }}>
                    <Typography variant="body2" style={{ marginRight: 10 }}>
                      {`${toCurrency(data.count)}`}
                    </Typography>
                  </div>
                </div>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                  variant="determinate"
                  value={data.percentage}
                />
              </div>
            ))}
          </>
        )
      }

      <Dialog
        open={userByDeviceModelFilterDialogVisibility}
        onClose={onCloseDialogPressed}
        fullWidth
        maxWidth="md"
      >
        <div style={{ padding: 30 }}>
          <Typography
            variant="body1"
            className={`${classes.w500} ${classes.flex1}`}
            style={{ marginLeft: 10, marginBottom: 8 }}
          >
            {GlobalizedLocalizedString.common.labelFilter}
          </Typography>
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelStartDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingDeviceModelsByUsers}
            value={dateRange.from}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: date,
                to: dateRange.to,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelEndDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingDeviceModelsByUsers}
            value={dateRange.to}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: dateRange.from,
                to: date,
              });
            }}
            InputProps={{ readOnly: true }}
          />

          <div className={`${classes.row} ${classes.flexEnd} ${classes.filterButtonActions}`}>
            <AccentButton
              onClick={onCloseDialogPressed}
              variant="text"
              caption={GlobalizedLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              size="small"
              disabled={downloadingDeviceModelsByUsers}
            />
            <AccentButton
              onClick={onResetFilterPressed}
              variant="outlined"
              caption={GlobalizedLocalizedString.common.buttonCaptionReset}
              className={classes.buttonOutline}
              size="small"
              disabled={downloadingDeviceModelsByUsers}
            />
            <AccentButton
              type="submit"
              disableElevation
              caption={GlobalizedLocalizedString.common.buttonCaptionApply}
              size="small"
              onClick={() => onApplyFilterPressed(dateRange)}
              disabled={downloadingDeviceModelsByUsers}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UserByDeviceModel;

UserByDeviceModel.propTypes = {
  usersByDeviceModel: PropTypes.arrayOf(PropTypes.object),
  userByDeviceModelDateRange: PropTypes.object.isRequired,

  downloadingDeviceModelsByUsers: PropTypes.bool.isRequired,
  userByDeviceModelFilterDialogVisibility: PropTypes.bool.isRequired,

  onCloseDialogPressed: PropTypes.func.isRequired,
  onFilterIconPressed: PropTypes.func.isRequired,
  onResetFilterPressed: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
};

UserByDeviceModel.defaultProps = {
  usersByDeviceModel: [],
};
