import { SET_OS_NAMES, CLEAR_OS_NAMES } from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_OS_NAMES:
      return action.data;
    case CLEAR_OS_NAMES:
      return [];
    default:
      return state;
  }
};
