import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { AccentButton, FunctionalPage, SectionTitle } from '../../component';
import {
  COLOR_PRIMARY, DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT, PICKER_DATE_TIME_FORMAT, PICKER_MODE_DATE_TIME, REGISTRATION_STATUS,
  REGISTRATION_STATUS_APPROVED, REGISTRATION_STATUS_PENDING, REGISTRATION_STATUS_REJECTED,
  RXFIELD_REGISTRATION_REGISTRATION_STATUS, RXFIELD_REGISTRATION_APPROVED_ON,
  RXFIELD_REGISTRATION_CITY, RXFIELD_REGISTRATION_DISTRICT, RXFIELD_REGISTRATION_EMAIL,
  RXFIELD_REGISTRATION_FULL_NAME, RXFIELD_REGISTRATION_NIK, RXFIELD_REGISTRATION_PHONE,
  RXFIELD_REGISTRATION_PROVINCE, RXFIELD_REGISTRATION_REGISTRATION_NOTES,
  RXFIELD_REGISTRATION_REJECTED_ON, RXFIELD_REGISTRATION_SALUTATION,
  RXFIELD_REGISTRATION_STREET_ADDRESS, RXFIELD_REGISTRATION_USER_ID,
  RXFIELD_REGISTRATION_USER_NAME, RXFIELD_REGISTRATION_VEHICLE_COLOR,
  RXFIELD_REGISTRATION_VEHICLE_DEALER_ADDRESS, RXFIELD_REGISTRATION_VEHICLE_DEALER_CODE,
  RXFIELD_REGISTRATION_VEHICLE_DEALER_NAME, RXFIELD_REGISTRATION_VEHICLE_ENGINE_NO,
  RXFIELD_REGISTRATION_VEHICLE_IS_PERSONAL_ACCOUNT, RXFIELD_REGISTRATION_VEHICLE_INVOICE_NO,
  RXFIELD_REGISTRATION_VEHICLE_LICENSE_PLATE, RXFIELD_REGISTRATION_VEHICLE_MODEL,
  RXFIELD_REGISTRATION_VEHICLE_MODEL_YEAR, RXFIELD_REGISTRATION_VEHICLE_NIK,
  RXFIELD_REGISTRATION_VEHICLE_OWNER_EMAIL, RXFIELD_REGISTRATION_VEHICLE_OWNER_NAME,
  RXFIELD_REGISTRATION_VEHICLE_TRANSMISSION, RXFIELD_REGISTRATION_VEHICLE_TRIM,
  RXFIELD_REGISTRATION_VEHICLE_PERSON_ID, RXFIELD_REGISTRATION_VEHICLE_PHONE,
  RXFIELD_REGISTRATION_VEHICLE_PRICE, RXFIELD_REGISTRATION_VEHICLE_STNK,
  RXFIELD_REGISTRATION_VEHICLE_WARRANTY_END_DATE, RXFIELD_REGISTRATION_VEHICLE_WARRANTY_NOTE,
  RXFIELD_REGISTRATION_VEHICLE_WARRANTY_START_DATE, RXFIELD_REGISTRATION_VEHICLE_VIN,
  RXFORM_REGISTRATION, RXSTATE_REGISTRATION_PAGE, RXSTATE_REGISTRATIONS,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormDateTimePickerField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  multipleFabContainer: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fabContainer: {
    marginRight: 10,
  },
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
}));

const renderDialogContent = (initialValues, classes) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderUserId}
            label={LocalizedString.registrationPage.placeholderUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderUserName}
            label={LocalizedString.registrationPage.placeholderUserName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_STREET_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderStreetAddress}
            label={LocalizedString.registrationPage.placeholderStreetAddress}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_DISTRICT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderDistrict}
            label={LocalizedString.registrationPage.placeholderDistrict}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_CITY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderCity}
            label={LocalizedString.registrationPage.placeholderCity}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_PROVINCE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderProvince}
            label={LocalizedString.registrationPage.placeholderProvince}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_SALUTATION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderSalutation}
            label={LocalizedString.registrationPage.placeholderSalutation}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_FULL_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderFullName}
            label={LocalizedString.registrationPage.placeholderFullName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_NIK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderNik}
            label={LocalizedString.registrationPage.placeholderNik}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderEmail}
            label={LocalizedString.registrationPage.placeholderEmail}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderPhone}
            label={LocalizedString.registrationPage.placeholderPhone}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <Divider className={classes.divider} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_REGISTRATION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderRegistrationStatus}
            label={LocalizedString.registrationPage.placeholderRegistrationStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_REGISTRATION_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderRegistrationNotes}
            label={LocalizedString.registrationPage.placeholderRegistrationNotes}
            disabled
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_APPROVED_ON}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.registrationPage.placeholderApprovedOn}
            label={LocalizedString.registrationPage.placeholderApprovedOn}
            disabled
            hidden={initialValues.registrationStatus !== REGISTRATION_STATUS_APPROVED}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_REJECTED_ON}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.registrationPage.placeholderRejectedOn}
            label={LocalizedString.registrationPage.placeholderRejectedOn}
            disabled
            hidden={initialValues.registrationStatus !== REGISTRATION_STATUS_REJECTED}
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.registrationPage.labelVehicleInfo} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_VIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderVin}
            label={LocalizedString.registrationPage.placeholderVin}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_MODEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderModel}
            label={LocalizedString.registrationPage.placeholderModel}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_TRIM}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderTrim}
            label={LocalizedString.registrationPage.placeholderTrim}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_COLOR}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderColor}
            label={LocalizedString.registrationPage.placeholderColor}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_MODEL_YEAR}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderModelYear}
            label={LocalizedString.registrationPage.placeholderModelYear}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_TRANSMISSION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderTransmission}
            label={LocalizedString.registrationPage.placeholderTransmission}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_ENGINE_NO}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderEngineNo}
            label={LocalizedString.registrationPage.placeholderEngineNo}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_LICENSE_PLATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderLicensePlate}
            label={LocalizedString.registrationPage.placeholderLicensePlate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_PRICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderPrice}
            label={LocalizedString.registrationPage.placeholderPrice}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_WARRANTY_START_DATE}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.registrationPage.placeholderWarrantyStartDate}
            label={LocalizedString.registrationPage.placeholderWarrantyStartDate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_WARRANTY_END_DATE}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.registrationPage.placeholderWarrantyEndDate}
            label={LocalizedString.registrationPage.placeholderWarrantyEndDate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_WARRANTY_NOTE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderWarrantyNotes}
            label={LocalizedString.registrationPage.placeholderWarrantyNotes}
            disabled
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_IS_PERSONAL_ACCOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderPersonalAccount}
            label={LocalizedString.registrationPage.placeholderPersonalAccount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_PERSON_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderPersonId}
            label={LocalizedString.registrationPage.placeholderPersonId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_OWNER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderOwnerName}
            label={LocalizedString.registrationPage.placeholderOwnerName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_NIK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderNik}
            label={LocalizedString.registrationPage.placeholderNik}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_STNK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderStnk}
            label={LocalizedString.registrationPage.placeholderStnk}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderPhone}
            label={LocalizedString.registrationPage.placeholderPhone}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_OWNER_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderEmail}
            label={LocalizedString.registrationPage.placeholderEmail}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_INVOICE_NO}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderInvoiceNo}
            label={LocalizedString.registrationPage.placeholderInvoiceNo}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_DEALER_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderDealerCode}
            label={LocalizedString.registrationPage.placeholderDealerCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_DEALER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderDealerName}
            label={LocalizedString.registrationPage.placeholderDealerName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_DEALER_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationPage.placeholderDealerAddress}
            label={LocalizedString.registrationPage.placeholderDealerAddress}
            disabled
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const RegistrationPage = ({
  initialValues, cities, provinces, users,
  approving, downloading, downloadingDeleting, loadingCity, loadingProvince, loadingUser, rejecting,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onApprovePressed,
  onCancelAdvancedFilterPressed, onChangeCityText, onChangePage, onChangePageSize,
  onChangeProvinceText, onChangeUserText, onConfirmPressed, onRefresh, onRejectPressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  userPermissions,
}) => {
  const classes = useStyles();

  const renderCustomFabComponent = () => !downloadingDeleting
  && userPermissions.MYCAR_REGISTRATION_APPROVAL
  && (initialValues.registrationStatus === REGISTRATION_STATUS_PENDING) && (
    <div className={classes.multipleFabContainer}>
      <div className={classes.fabContainer}>
        <AccentButton
          variant="text"
          disabled={approving || downloadingDeleting}
          loading={rejecting}
          disableElevation
          caption={LocalizedString.registrationPage.buttonCaptionReject}
          onClick={onRejectPressed}
        />
      </div>
      <AccentButton
        disabled={rejecting || downloadingDeleting}
        loading={approving}
        disableElevation
        caption={LocalizedString.registrationPage.buttonCaptionApprove}
        onClick={onApprovePressed}
      />
    </div>
  );

  return (
    <FunctionalPage
      data={RXSTATE_REGISTRATIONS}
      uiPage={RXSTATE_REGISTRATION_PAGE}
      filterColumns={[
        {
          title: LocalizedString.registrationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.registrationPage.labelVin,
          field: 'vin',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.registrationPage.labelUser,
          field: 'user',
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUser,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.registrationPage.labelCity,
          field: 'city',
          type: FILTER_TYPE_DROPDOWN,
          data: cities,
          loading: loadingCity,
          onChangeFilterText: onChangeCityText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.registrationPage.labelProvince,
          field: 'province',
          type: FILTER_TYPE_DROPDOWN,
          data: provinces,
          loading: loadingProvince,
          onChangeFilterText: onChangeProvinceText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.registrationPage.labelStatus, field: 'registrationStatus', type: FILTER_TYPE_DROPDOWN, data: REGISTRATION_STATUS,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.registrationPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.registrationPage.labelVin, field: 'vin', sorting: !downloading },
        { title: LocalizedString.registrationPage.labelUser, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.registrationPage.labelCarModel, field: 'vehicle.model', sorting: !downloading },
        { title: LocalizedString.registrationPage.labelModelYear, field: 'vehicle.modelYear', sorting: !downloading },
        { title: LocalizedString.registrationPage.labelLicensePlate, field: 'vehicle.licensePlate', sorting: !downloading },
        { title: LocalizedString.registrationPage.labelStatus, field: 'registrationStatus', sorting: !downloading },
        {
          title: LocalizedString.registrationPage.labelApprovedOn,
          field: 'approvedOn',
          sorting: !downloading,
          render: ({ approvedOn }) => (approvedOn
            ? toMoment(approvedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.registrationPage.labelRejectedOn,
          field: 'rejectedOn',
          sorting: !downloading,
          render: ({ rejectedOn }) => (rejectedOn
            ? toMoment(rejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.registrationPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      renderCustomFabComponent={() => renderCustomFabComponent()}
      confirmationReasonRequired
      disableCreate
      disableDelete
      disableEdit
      title={LocalizedString.registrationPage.title}
      useFullWidth
      usefullWidthDialog
    >
      {renderDialogContent(initialValues, classes)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_REGISTRATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RegistrationPage);

RegistrationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  provinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  approving: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  loadingProvince: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  rejecting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onApprovePressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeProvinceText: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRejectPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  userPermissions: PropTypes.objectOf(PropTypes.string).isRequired,
};
