import { reset } from 'redux-form';
import {
  addingEditingUser, clearUsers, setAlertErrorMessage, setUserSelectedRole, setFunctionalPageMode,
} from '../simple-action';
import { editUser } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_USER } from '../../../constant';
import downloadUsersAsync from './downloadUsersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingUser(true));

    const { token } = getState().authentication;
    const { tappedId, selectedRole } = getState().uiUser;
    const { data } = getState().users;
    const found = data[tappedId];

    await editUser(tappedId, found.username, found.fullName, found.email, found.phone, found.info1,
      found.info2, found.info3, found.status, found.origin, selectedRole, token);

    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    dispatch(reset(RXFORM_USER));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserSelectedRole(''));
  } finally {
    dispatch(addingEditingUser(false));
  }
};
