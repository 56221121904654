import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearWorkingHours,
  setActiveSideMenuItem,
  setAlertConfirmationMessage,
  setAlertErrorMessage,
  setWorkingHourSearchText,
  setWorkingHourSelectedPageSize,
  setWorkingHourSelectedOrderBy,
  setWorkingHourTappedId,
  addEditWorkingHourAsync,
  downloadDeleteWorkingHourAsync,
  downloadWorkingHoursAsync,
  setWorkingHourAdvancedFilterDialogSelectedFilterString,
  setWorkingHourSelectedTimezone,
  enableDisableWorkingHourAsync,
} from '../../redux/action';
import {
  DROPDOWN_OPTION_CLEAR,
  INITIAL_ORDER_BY_WORKING_HOURS,
  MENUID_ATTENDANCE_ADDON_WORKING_HOUR,
  PAGE_MODE_TABLE,
  RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES,
  RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_WORKING_HOUR_END_AFTER_MIN,
  RXFIELD_WORKING_HOUR_END_BEFORE_MIN,
  RXFIELD_WORKING_HOUR_END_WORKING_HOUR,
  RXFIELD_WORKING_HOUR_START_AFTER_MIN,
  RXFIELD_WORKING_HOUR_START_BEFORE_MIN,
  RXFIELD_WORKING_HOUR_START_WORKING_HOUR,
  RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE,
  RXFORM_WORKING_HOUR,
  TIME_MODE_CLOCK_IN,
  TIME_MODE_CLOCK_OUT,
} from '../../constant';
import {
  convertClockInOutHoursToMinutes,
  transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import WorkingHourPage from './working-hour.presentation';

const getInitialValues = (state) => {
  const { workingHours, uiWorkingHour, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiWorkingHour;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? workingHours.data[tappedId]
    : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      workingHourTimeZone:
            found.workingHourTimeZone !== null ? found.workingHourTimeZone : '',
      earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
        found.earliestClockInDriftTimeMinutes,
        found.startWorkingHour,
        TIME_MODE_CLOCK_IN,
      ),
      autoClockOutAfterMinutes: convertClockInOutHoursToMinutes(
        found.autoClockOutAfterMinutes,
        found.endWorkingHour,
        TIME_MODE_CLOCK_OUT,
      ),
    })
    : {
      [RXFIELD_WORKING_HOUR_START_BEFORE_MIN]: 0,
      [RXFIELD_WORKING_HOUR_START_AFTER_MIN]: 0,
      [RXFIELD_WORKING_HOUR_END_BEFORE_MIN]: 0,
      [RXFIELD_WORKING_HOUR_END_AFTER_MIN]: 0,
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiWorkingHour.addingEditing,
  downloading: state.uiWorkingHour.downloading,
  downloadingDeleting: state.uiWorkingHour.downloadingDeleting,
  enablin: state.uiWorkingHour.downloadingDeleting,
  initialValues: getInitialValues(state),
  enablingDisabling: state.uiWorkingHour.enablingDisabling,
  currentWorkingHourStatus: state.workingHours.data[
    state.uiFunctionalPage.tappedId
  ]
    ? state.workingHours.data[state.uiFunctionalPage.tappedId].status
    : '',
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(
      setWorkingHourAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_WORKING_HOUR));
    dispatch(setWorkingHourSearchText(''));
    dispatch(clearWorkingHours());
    dispatch(setWorkingHourSelectedPageSize(20));
    dispatch(setWorkingHourSelectedOrderBy(INITIAL_ORDER_BY_WORKING_HOURS));
    dispatch(downloadWorkingHoursAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_WORKING_HOUR));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWorkingHoursAsync(pageNo + 1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWorkingHourSelectedPageSize(pageSize));
    dispatch(downloadWorkingHoursAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onConfirmPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteWorkingHourAsync()).catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
        break;
      default:
        dispatch(enableDisableWorkingHourAsync()).catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onCreatePressed: () => {
    dispatch(setWorkingHourTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setWorkingHourTappedId(id));
    dispatch(
      setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation),
    );
  },
  onEditPressed: (id) => {
    dispatch(setWorkingHourTappedId(id));
    dispatch(downloadDeleteWorkingHourAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onRefresh: (pageSize) => {
    dispatch(setWorkingHourSelectedPageSize(pageSize));
    dispatch(clearWorkingHours());
    dispatch(downloadWorkingHoursAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSavePressed: async (values) => {
    try {
      await dispatch(addEditWorkingHourAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWorkingHourSearchText(text));
      dispatch(clearWorkingHours());
      await dispatch(downloadWorkingHoursAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWorkingHourSelectedOrderBy(orderBy));
    dispatch(clearWorkingHours());
    dispatch(downloadWorkingHoursAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSubmitPressed: async (values) => {
    await dispatch(addEditWorkingHourAsync(values));
  },
  onViewPressed: (id) => {
    dispatch(setWorkingHourTappedId(id));
    dispatch(downloadDeleteWorkingHourAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onAdvancedFilterPressed: () => {},
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(
      setWorkingHourAdvancedFilterDialogSelectedFilterString(filterString),
    );
    dispatch(clearWorkingHours());
    dispatch(downloadWorkingHoursAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_WORKING_HOUR));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearWorkingHours());
    dispatch(downloadWorkingHoursAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onStartWorkingHourChanged: (date) => {
    if (date) {
      dispatch(
        change(
          RXFORM_WORKING_HOUR,
          RXFIELD_WORKING_HOUR_START_WORKING_HOUR,
          date,
        ),
      );
    } else {
      dispatch(
        change(RXFORM_WORKING_HOUR, RXFIELD_WORKING_HOUR_START_WORKING_HOUR, ''),
      );
    }
  },
  onEndWorkingHourChanged: (date) => {
    if (date) {
      dispatch(
        change(RXFORM_WORKING_HOUR, RXFIELD_WORKING_HOUR_END_WORKING_HOUR, date),
      );
    } else {
      dispatch(
        change(RXFORM_WORKING_HOUR, RXFIELD_WORKING_HOUR_END_WORKING_HOUR, ''),
      );
    }
  },
  onTimeZoneOptionSelected: (option) => {
    if (option !== DROPDOWN_OPTION_CLEAR) {
      dispatch(setWorkingHourSelectedTimezone(option));
      dispatch(
        change(
          RXFORM_WORKING_HOUR,
          RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE,
          option,
        ),
      );
    } else {
      dispatch(setWorkingHourSelectedTimezone(''));
      dispatch(
        change(
          RXFORM_WORKING_HOUR,
          RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE,
          '',
        ),
      );
    }
  },
  onEarliestClockInChanged: (date) => {
    if (date) {
      dispatch(
        change(
          RXFORM_WORKING_HOUR,
          RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
          date,
        ),
      );
    } else {
      dispatch(
        change(
          RXFORM_WORKING_HOUR,
          RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
          '',
        ),
      );
    }
  },
  onAutoClockOutChanged: (date) => {
    if (date) {
      dispatch(
        change(
          RXFORM_WORKING_HOUR,
          RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES,
          date,
        ),
      );
    } else {
      dispatch(
        change(
          RXFORM_WORKING_HOUR,
          RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES,
          '',
        ),
      );
    }
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setWorkingHourTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkingHourPage);
