import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { COLOR_PRIMARY, COLOR_SECONDARY } from '../constant';

const useStyles = makeStyles(() => ({
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
  heading: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    margin: '0px 0px 20px 10px',
  },
}));

const SectionTitle = ({ title, textStyle }) => {
  const classes = useStyles();

  return (
    <div>
      <Divider className={classes.divider} />
      <Typography variant="subtitle1" className={`${classes.heading} ${textStyle}`}>
        {title}
      </Typography>
    </div>
  );
};

export default SectionTitle;

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  textStyle: PropTypes.string,
};

SectionTitle.defaultProps = {
  textStyle: '',
};
