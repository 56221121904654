import {
  downloadingAttendanceSummaryTotalClockInToday, setAttendanceSummaryTotalClockInToday,
} from '../simple-action';
import { downloadAttendanceSummaryTotalClockInToday } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTotalClockInToday(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryTotalClockInToday(token);

    dispatch(setAttendanceSummaryTotalClockInToday(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTotalClockInToday(false));
  }
};
