import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  CircularProgress, Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid,
  makeStyles, MenuItem, Paper, Select, Typography, Button,
} from '@material-ui/core';
import { ExpandMore, GetApp } from '@material-ui/icons';
import {
  AccentButton, BasePage, TooltipText, UnsavedChangesDialog,
} from '../../component';
import {
  BOOLEAN_DROPDOWN_DATA_LOWERCASE, COLOR_BACKGROUND, COLOR_DANGEROUS, COLOR_PRIMARY,
  COLOR_SECONDARY, CONFIG_VALUE_DATA_TYPE_BOOLEAN, CONFIG_VALUE_DATA_TYPE_ENUM,
  CONFIG_VALUE_DATA_TYPE_FILE, RXFORM_CONFIG_VALUE, BUTTON_TYPE_OUTLINED,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../../../type';

const useStyles = makeStyles(() => ({
  title: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 10,
  },
  select: {
    border: `2px solid ${COLOR_PRIMARY}`,
    borderRadius: '40px',
    width: 200,
    paddingLeft: 20,
    color: COLOR_PRIMARY,
    fontWeight: 'bold',
    '&:focus': {
      border: `2px solid ${COLOR_PRIMARY}`,
      borderRadius: '40px',
    },
    fontSize: '14px',
  },
  selectIcon: {
    color: COLOR_PRIMARY,
    right: 15,
  },
  bodyContainer: {
    overflowY: 'auto',
  },
  bodyLoadingContainer: {
    padding: '15px 24px',
    overflowY: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityIndicator: {
    color: COLOR_SECONDARY,
  },
  sectionTitleContainer: {
    marginTop: 20,
  },
  sectionTitle: {
    color: COLOR_PRIMARY,
    fontWeight: 'bold',
    fontSize: 16,
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemWrapper: {
    margin: '2px 0px',
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '15px 24px',
    margin: '20px 0px',
  },
  saveButton: {
    borderRadius: 50,
    background: COLOR_PRIMARY,
    color: COLOR_BACKGROUND,
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: '14px',
  },
  resetButton: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
    fontSize: '14px',
  },
  expansionContainer: {
    marginBottom: 15,
  },
  expansionDetailContainer: {
    flexDirection: 'column',
  },
  sectionContainer: {
    padding: '15px 24px',
  },
  divider: {
    height: 2,
  },
  fontSize14: {
    fontSize: '14px',
  },
  uploadButtonContainer: {
    marginTop: 8,
    marginRight: 8,
    height: 38,
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  uploadFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  uploadField: {
    flex: 1,
  },
}));

const renderField = (
  item,
  onDropdownOptionSelected,
  editingConfigItems,
  classes,
  onDownloadFilePressed,
  downloadingFile,
  onUploadPressed,
  uploadingFile,
  onFileSelected,
  formValues,
  selectedFileFieldId,
) => {
  const pickerData = item.enumOptions ? item.enumOptions.split('|') : null;
  if (item.dataType === CONFIG_VALUE_DATA_TYPE_ENUM) {
    return (
      <Field
        name={item.id}
        label=""
        component={renderReduxFormSimpleDropdownField}
        data={pickerData}
        onOptionSelected={(option) => onDropdownOptionSelected(option, item.id)}
        disabled={editingConfigItems}
        helperText={item.needRestart
          ? LocalizedString.configValueScreen.helperLabelNeedRestart : null}
      />
    );
  }
  if (item.dataType === CONFIG_VALUE_DATA_TYPE_BOOLEAN) {
    return (
      <Field
        name={item.id}
        label=""
        component={renderReduxFormSimpleDropdownField}
        data={BOOLEAN_DROPDOWN_DATA_LOWERCASE}
        onOptionSelected={(option) => onDropdownOptionSelected(option, item.id)}
        disabled={editingConfigItems}
        helperText={item.needRestart
          ? LocalizedString.configValueScreen.helperLabelNeedRestart : null}
      />
    );
  }
  if (item.dataType === CONFIG_VALUE_DATA_TYPE_FILE) {
    return (
      <div className={classes.uploadFieldContainer}>
        <div style={{ flex: 1 }}>
          <Field
            name={item.id}
            label=""
            InputLabelProps={{ shrink: false }}
            component={renderReduxFormOutlinedTextField}
            disabled={editingConfigItems}
            helperText={item.needRestart
              ? LocalizedString.configValueScreen.helperLabelNeedRestart : ''}
            secureTextEntry={item.encrypted}
            multiline={item.multiLine}
            readOnly
            InputProps={{
              readOnly: true,
            }}
            rows={3}
          />
        </div>
        <Button
          variant={BUTTON_TYPE_OUTLINED}
          disabled={
            editingConfigItems
              ? true : (downloadingFile || uploadingFile) && item.id === selectedFileFieldId
          }
          className={classes.uploadButtonContainer}
          onClick={() => onDownloadFilePressed(formValues[item.id])}
        >
          {downloadingFile && item.id === selectedFileFieldId ? <CircularProgress color="inherit" size={20} /> : <GetApp />}
        </Button>
        <div>
          <Button
            variant={BUTTON_TYPE_OUTLINED}
            disabled={
              editingConfigItems
                ? true : (downloadingFile || uploadingFile) && item.id === selectedFileFieldId
            }
            className={classes.uploadButtonContainer}
            onClick={() => onUploadPressed(item.id)}
          >
            {uploadingFile && item.id === selectedFileFieldId ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <Typography>
                {GlobalLocalizedString.common.labelUpload}
              </Typography>
            )}
          </Button>
          <input
            type="file"
            id={`uploadFile-${item.id}`}
            name="uploadFile"
            style={{ visibility: 'hidden', display: 'none' }}
            onChange={(e) => onFileSelected(e.target, item.id)}
          />
        </div>
      </div>
    );
  }
  return (
    <Field
      name={item.id}
      label=""
      InputLabelProps={{ shrink: false }}
      component={renderReduxFormOutlinedTextField}
      disabled={editingConfigItems}
      helperText={item.needRestart
        ? LocalizedString.configValueScreen.helperLabelNeedRestart : ''}
      secureTextEntry={item.encrypted}
      multiline={item.multiLine}
      rows={3}
    />
  );
};

const renderGrid = (
  data,
  editingConfigItems,
  onDropdownOptionSelected,
  classes,
  onDownloadFilePressed,
  downloadingFile,
  onUploadPressed,
  uploadingFile,
  onFileSelected,
  formValues,
  selectedFileFieldId,
) => data.map((item) => (
  <Grid key={item.id} container spacing={3} className={classes.itemWrapper}>
    <Grid item xs={3} sm={3} md={3} className={classes.fieldWrapper}>
      <Typography className={classes.fontSize14}>{item.label}</Typography>
    </Grid>
    <Grid item xs={3} sm={6} md={6}>
      {renderField(
        item,
        onDropdownOptionSelected,
        editingConfigItems,
        classes,
        onDownloadFilePressed,
        downloadingFile,
        onUploadPressed,
        uploadingFile,
        onFileSelected,
        formValues,
        selectedFileFieldId,
      )}
    </Grid>
    <Grid item xs={3} sm={3} md={3} className={classes.fieldWrapper}>
      <TooltipText className={classes.fontSize14} caption={item.description} />
    </Grid>
  </Grid>
));

const renderExpansionPanel = (
  data,
  isTheLastItem,
  editingConfigItems,
  onDropdownOptionSelected,
  classes,
  onDownloadFilePressed,
  downloadingFile,
  onUploadPressed,
  uploadingFile,
  onFileSelected,
  formValues,
  selectedFileFieldId,
) => {
  if (!data.sectionName) {
    return (
      <div key="empty-section-name">
        <div className={classes.sectionContainer}>
          {renderGrid(
            data.sectionData,
            editingConfigItems,
            onDropdownOptionSelected,
            classes,
            onDownloadFilePressed,
            downloadingFile,
            onUploadPressed,
            uploadingFile,
            onFileSelected,
            formValues,
            selectedFileFieldId,
          )}
        </div>
        {!isTheLastItem && (<Divider className={classes.divider} />)}
      </div>
    );
  }
  return (
    <ExpansionPanel key={data.sectionName} className={classes.expansionContainer}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.sectionTitle}>{data.sectionName}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.expansionDetailContainer }}>
        {renderGrid(
          data.sectionData,
          editingConfigItems,
          onDropdownOptionSelected,
          classes,
          onDownloadFilePressed,
          downloadingFile,
          onUploadPressed,
          uploadingFile,
          onFileSelected,
          formValues,
          selectedFileFieldId,
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const ConfigValuePage = ({
  initialValues, formValues,
  allConfigGroupFilter, configData,
  displayUnsavedChangeDialog, downloading, editingConfigItems,
  handleSubmit, onAppear, onCloseDialog, onFilterValueSelected, onDropdownOptionSelected,
  onResetPressed, onSaveButtonPressed, onSideMenuPressed,
  selectedFilter, onDownloadFilePressed, downloadingFile, onUploadPressed, uploadingFile,
  onFileSelected, selectedFileFieldId,
}) => {
  const classes = useStyles();
  useEffect(() => onAppear(selectedFilter), [selectedFilter, onAppear]);
  const newValuesArray = formValues ? Object.values(formValues) : [];
  const initialValuesArray = initialValues ? Object.values(initialValues) : [];
  const valuesNotChanged = isEqual(newValuesArray, initialValuesArray);

  return (
    <BasePage
      onSideMenuPressed={(item, history) => onSideMenuPressed(item, history, selectedFilter)}
    >
      <div className={classes.container}>
        <UnsavedChangesDialog
          display={displayUnsavedChangeDialog}
          onClose={onCloseDialog}
        />

        <Typography variant="h4" className={classes.title}>
          {LocalizedString.configValueScreen.title}
        </Typography>

        <Paper className={classes.headerContainer} elevation={3}>
          <Grid container spacing={3}>
            <Grid item sm md>
              <Typography className={classes.boldText}>
                {LocalizedString.configValueScreen.labelFilterConfigGroup}
              </Typography>
            </Grid>
            <Grid item>
              <Select
                value={selectedFilter}
                onChange={(event) => onFilterValueSelected(event, valuesNotChanged)}
                autoWidth
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                disableUnderline
                IconComponent={ExpandMore}
              >
                {allConfigGroupFilter.map((filter) => (
                  <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Paper>

        {selectedFilter !== LocalizedString.configValueScreen.labelFilterBy && (
        <Paper
          classes={{ root: downloading ? classes.bodyLoadingContainer : classes.bodyContainer }}
          elevation={3}
        >
          {downloading
            ? (<CircularProgress className={classes.activityIndicator} />)
            : configData.map((data, i) => renderExpansionPanel(
              data,
              configData.length - 1 === i,
              editingConfigItems,
              onDropdownOptionSelected,
              classes,
              onDownloadFilePressed,
              downloadingFile,
              onUploadPressed,
              uploadingFile,
              onFileSelected,
              formValues,
              selectedFileFieldId,
            ))}
        </Paper>
        )}

        <Paper elevation={3} className={classes.footerContainer}>
          <AccentButton
            onClick={onResetPressed}
            variant="text"
            caption={GlobalLocalizedString.common.buttonCaptionReset}
            className={classes.resetButton}
            disabled={selectedFilter === LocalizedString.configValueScreen.labelFilterBy
            || configData.length === 0 || valuesNotChanged || editingConfigItems}
            size="small"
          />
          <AccentButton
            onClick={handleSubmit(onSaveButtonPressed)}
            variant="contained"
            caption={GlobalLocalizedString.common.buttonCaptionSave}
            className={classes.saveButton}
            disabled={selectedFilter === LocalizedString.configValueScreen.labelFilterBy
            || configData.length === 0 || valuesNotChanged || editingConfigItems}
            size="small"
          />
        </Paper>
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_CONFIG_VALUE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ConfigValuePage);

ConfigValuePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  formValues: FormInitialValueShape,
  allConfigGroupFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  configData: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayUnsavedChangeDialog: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  editingConfigItems: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onFilterValueSelected: PropTypes.func.isRequired,
  onDropdownOptionSelected: PropTypes.func.isRequired,
  onResetPressed: PropTypes.func.isRequired,
  onSaveButtonPressed: PropTypes.func.isRequired,
  onSideMenuPressed: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  downloadingFile: PropTypes.bool.isRequired,
  onDownloadFilePressed: PropTypes.func.isRequired,
  onUploadPressed: PropTypes.func.isRequired,
  uploadingFile: PropTypes.bool.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  selectedFileFieldId: PropTypes.string.isRequired,
};

ConfigValuePage.defaultProps = {
  formValues: {},
};
