import { addWfaRequest, downloadingWfaRequest } from '../simple-action';
import { downloadWfaRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingWfaRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWfaRequest;

    const wfaRequest = await downloadWfaRequest(tappedId, token);
    dispatch(addWfaRequest(wfaRequest));
  } finally {
    dispatch(downloadingWfaRequest(false));
  }
};
