import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Typography, makeStyles, Paper,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { BasePage, AccentButton } from '../../component';
import {
  RXFORM_NEWS_DASHBOARD, COLOR_SECONDARY, RXFIELD_NEWS_DASHBOARD_FILTER,
  MOST_VIEWED_NEWS_FILTERS, COLOR_BACKGROUND_SECONDARY,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, MostViewedNewsShape } from '../../type';

const useStyles = makeStyles(() => ({
  title: {
    color: COLOR_SECONDARY,
  },
  paper: {
    padding: 32,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));


const NewsDashboardPage = ({
  onAppear, editing, downloading, onFilterOptionSelected, initialValues,
  news, onViewPressed, history,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);
  return (
    <BasePage>
      <Paper className={classes.paper}>
        <div className={classes.topContainer}>
          <Typography variant="h6">
            {LocalizedString.newsDashboardScreen.labelTopTen}
          </Typography>
          <Field
            name={RXFIELD_NEWS_DASHBOARD_FILTER}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.newsDashboardScreen.placeholderFilter}
            label={LocalizedString.newsDashboardScreen.placeholderFilter}
            disabled={editing || downloading}
            data={MOST_VIEWED_NEWS_FILTERS}
            value={initialValues.selectedFilter}
            onOptionSelected={onFilterOptionSelected}
          />
        </div>

        <MaterialTable
          data={news}
          isLoading={downloading}
          actions={[
            {
              icon: () => (
                <AccentButton
                  caption={LocalizedString.newsDashboardScreen.buttonCaptionView}
                  variant="outlined"
                />
              ),
              onClick: (event, rowData) => onViewPressed(rowData.newsId, history),
            },
          ]}
          options={{
            actionsColumnIndex: 1,
            actionsCellStyle: {
              borderBottomWidth: 0, justifyContent: 'center',
            },
            pageSize: 10,
            paging: false,
            headerStyle: { background: COLOR_BACKGROUND_SECONDARY },
          }}
          columns={[
            {
              title: LocalizedString.newsDashboardScreen.labelNo,
              field: 'no',
              sorting: false,
              width: 40,
              cellStyle: { borderBottomWidth: 0 },
            },
            {
              title: LocalizedString.newsDashboardScreen.labelNewsTitle,
              field: 'newsTitle',
              sorting: false,
              cellStyle: { borderBottomWidth: 0 },
            },
            {
              title: LocalizedString.newsDashboardScreen.labelViewers,
              field: 'count',
              sorting: false,
              cellStyle: { borderBottomWidth: 0 },
            },
          ]}
          title=""
          components={{
            Toolbar: () => null,
          }}
        />
      </Paper>
    </BasePage>
  );
};
export default reduxForm({
  form: RXFORM_NEWS_DASHBOARD,
  //   validate: rxformValidateNewsDashboard,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(NewsDashboardPage);

NewsDashboardPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  news: PropTypes.arrayOf(MostViewedNewsShape).isRequired,

  editing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,

  history: PropTypes.object.isRequired,

  onAppear: PropTypes.func.isRequired,
  onFilterOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
