import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, FILTER_TYPE_SWITCH,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE,
  RXFIELD_POINT_AMOUNT, RXFIELD_POINT_EXPIRED_POINT_ALREADY_PROCESSED,
  RXFIELD_POINT_IS_VOIDED, RXFIELD_POINT_LABEL, RXFIELD_POINT_MERCHANT_ID,
  RXFIELD_POINT_MERCHANT_NAME, RXFIELD_POINT_MERCHANT_REFERENCE_ID, RXFIELD_POINT_TAGS,
  RXFIELD_POINT_USER, RXFIELD_POINT_VALID_UNTIL, RXFIELD_POINT_VOID_NOTES,
  RXFIELD_POINT_VOID_REFERENCE_ID, RXFIELD_POINT_VOIDED_BY, RXFIELD_POINT_VOIDED_ON,
  RXFORM_POINT, RXSTATE_POINTS, RXSTATE_POINT_PAGE,
  STATUS_DISABLED, COLOR_DISABLED_ROW, COLOR_BACKGROUND,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_POINT_USER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderUser}
          label={LocalizedString.pointPage.placeholderUser}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_MERCHANT_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderMerchantReferenceID}
          label={LocalizedString.pointPage.placeholderMerchantReferenceID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_LABEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderLabel}
          label={LocalizedString.pointPage.placeholderLabel}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_TAGS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderTags}
          label={LocalizedString.pointPage.placeholderTags}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_IS_VOIDED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderIsVoided}
          label={LocalizedString.pointPage.placeholderIsVoided}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOID_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidReferenceId}
          label={LocalizedString.pointPage.placeholderVoidReferenceId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOIDED_BY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidedBy}
          label={LocalizedString.pointPage.placeholderVoidedBy}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_POINT_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderAmount}
          label={LocalizedString.pointPage.placeholderAmount}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_MERCHANT_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderMerchantId}
          label={LocalizedString.pointPage.placeholderMerchantId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_MERCHANT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderMerchantName}
          label={LocalizedString.pointPage.placeholderMerchantName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VALID_UNTIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderValidUntil}
          label={LocalizedString.pointPage.placeholderValidUntil}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_EXPIRED_POINT_ALREADY_PROCESSED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderExpiredPointAlreadyProcessed}
          label={LocalizedString.pointPage.placeholderExpiredPointAlreadyProcessed}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOIDED_ON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidedOn}
          label={LocalizedString.pointPage.placeholderVoidedOn}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_POINT_VOID_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.pointPage.placeholderVoidNotes}
          label={LocalizedString.pointPage.placeholderVoidNotes}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const PointPage = ({
  users, downloading, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_POINTS}
    uiPage={RXSTATE_POINT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.pointPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointPage.placeholderValidUntil,
        field: RXFIELD_POINT_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointPage.placeholderMerchantName,
        field: RXFIELD_POINT_MERCHANT_NAME,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointPage.placeholderMerchantReferenceID,
        field: RXFIELD_POINT_MERCHANT_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointPage.placeholderUser,
        field: RXFIELD_POINT_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.pointPage.placeholderIsVoided,
        field: RXFIELD_POINT_IS_VOIDED,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.pointPage.placeholderVoidReferenceId,
        field: RXFIELD_POINT_VOID_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointPage.placeholderExpiredPointAlreadyProcessed,
        field: RXFIELD_POINT_EXPIRED_POINT_ALREADY_PROCESSED,
        type: FILTER_TYPE_SWITCH,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.pointPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.pointPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.pointPage.labelAmount, field: 'amount', sorting: !downloading },
      { title: LocalizedString.pointPage.labelLabel, field: 'label', sorting: !downloading },
      { title: LocalizedString.pointPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
      { title: LocalizedString.pointPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.pointPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
    rowStyle={({ status, isVoided }) => ({
      backgroundColor: isVoided || status === STATUS_DISABLED
        ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
    })}
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_POINT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PointPage);

PointPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
