import moment from 'moment';
import { rxFormValidateRequiredFields } from '../../validation';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_FLOAT, ENUM_FIELD_INTEGER, ENUM_FIELD_OPTIONS,
  ENUM_FIELD_PICKER, ENUM_FIELD_TEXT, ENUM_FIELD_TIME,
  RXFIELD_QUIZ_CATEGORY_NAME, RXFIELD_SURVEY_CATEGORY_NAME,
  RXFIELD_QUIZ_DURATION, RXFIELD_QUIZ_CATEGORY, RXFIELD_QUIZ_TITLE,
  RXFIELD_QUIZ_VALID_FROM, RXFIELD_QUIZ_VALID_UNTIL, RXFIELD_QUIZ_VISIBILITY_STATUS,
  RXFIELD_SURVEY_TITLE, RXFIELD_SURVEY_CATEGORY, RXFIELD_SURVEY_VALID_FROM,
  RXFIELD_SURVEY_VALID_UNTIL, RXFIELD_SURVEY_VISIBILITY_STATUS,
  RXFIELD_SURVEY_QUESTION_FIELD_TYPE, RXFIELD_SURVEY_QUESTION_LABEL, RXFIELD_SURVEY_QUESTION_NAME,
  RXFIELD_SURVEY_QUESTION_ORDER,
  TIME_FORMAT, RXFIELD_SURVEY_QUESTION_SURVEY,
  RXFIELD_QUIZ_QUESTION_QUIZ,
  RXFIELD_QUIZ_QUESTION_FIELD_TYPE,
  RXFIELD_QUIZ_QUESTION_LABEL,
  RXFIELD_QUIZ_QUESTION_NAME,
  RXFIELD_QUIZ_QUESTION_ORDER,
  RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER,
} from './constant';
import { toNumber } from './helper';
import GlobalLocalizedString from '../../localization';

export const rxformValidateQuizCategory = (values) => {
  const requiredFields = [
    RXFIELD_QUIZ_CATEGORY_NAME,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSurveyCategory = (values) => {
  const requiredFields = [
    RXFIELD_SURVEY_CATEGORY_NAME,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateQuiz = (values) => {
  const requiredFields = [
    RXFIELD_QUIZ_DURATION, RXFIELD_QUIZ_CATEGORY, RXFIELD_QUIZ_TITLE,
    RXFIELD_QUIZ_VALID_FROM, RXFIELD_QUIZ_VALID_UNTIL,
    RXFIELD_QUIZ_VISIBILITY_STATUS,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = GlobalLocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateSurvey = (values) => {
  const requiredFields = [
    RXFIELD_SURVEY_TITLE,
    RXFIELD_SURVEY_CATEGORY,
    RXFIELD_SURVEY_VALID_FROM,
    RXFIELD_SURVEY_VALID_UNTIL,
    RXFIELD_SURVEY_VISIBILITY_STATUS,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = GlobalLocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateSurveyQuestion = (values) => {
  const requiredFields = [
    RXFIELD_SURVEY_QUESTION_SURVEY,
    RXFIELD_SURVEY_QUESTION_FIELD_TYPE,
    RXFIELD_SURVEY_QUESTION_LABEL,
    RXFIELD_SURVEY_QUESTION_NAME,
    RXFIELD_SURVEY_QUESTION_ORDER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  switch (values.fieldType) {
    case ENUM_FIELD_DATE:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_DATE_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue, TIME_FORMAT)
          .isAfter(moment(values.dateTimeMinValue, TIME_FORMAT))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndTime;
      } break;
    case ENUM_FIELD_TEXT:
      if (!values.textKeyboardType) {
        errors.textKeyboardType = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (values.textMinLength && values.textMaxLength
        && (toNumber(values.textMinLength) > toNumber(values.textMaxLength)
        || toNumber(values.textMaxLength) < toNumber(values.textMinLength))) {
        errors.textMaxLength = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_INTEGER:
      if (values.intMinValue && values.intMaxValue
        && (toNumber(values.intMinValue) > toNumber(values.intMaxValue)
          || toNumber(values.intMaxValue) < toNumber(values.intMinValue))) {
        errors.intMaxValue = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_FLOAT:
      if (values.floatMinValue && values.floatMaxValue
        && (parseFloat(values.floatMinValue) > parseFloat(values.floatMaxValue)
        || parseFloat(values.floatMaxValue) < parseFloat(values.floatMinValue))) {
        errors.floatMaxValue = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_PICKER:
      if (!values.pickerMode) {
        errors.pickerMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      } break;
    case ENUM_FIELD_OPTIONS:
      if (!values.optionMode) {
        errors.optionMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValueSeparator) {
        errors.optionValueSeparator = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      break;
    default: break;
  }

  return errors;
};

export const rxformValidateQuizQuestion = (values) => {
  const requiredFields = [
    RXFIELD_QUIZ_QUESTION_QUIZ,
    RXFIELD_QUIZ_QUESTION_FIELD_TYPE,
    RXFIELD_QUIZ_QUESTION_LABEL,
    RXFIELD_QUIZ_QUESTION_NAME,
    RXFIELD_QUIZ_QUESTION_ORDER,
    RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  switch (values.fieldType) {
    case ENUM_FIELD_DATE:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_DATE_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue).isAfter(moment(values.dateTimeMinValue))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndDate;
      } break;
    case ENUM_FIELD_TIME:
      if (values.dateTimeMaxValue && values.dateTimeMinValue
        && !moment(values.dateTimeMaxValue, TIME_FORMAT)
          .isAfter(moment(values.dateTimeMinValue, TIME_FORMAT))) {
        errors.dateTimeMaxValue = GlobalLocalizedString.common.errMsgStartEndTime;
      } break;
    case ENUM_FIELD_TEXT:
      if (!values.textKeyboardType) {
        errors.textKeyboardType = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (values.textMinLength && values.textMaxLength
        && (toNumber(values.textMinLength) > toNumber(values.textMaxLength)
        || toNumber(values.textMaxLength) < toNumber(values.textMinLength))) {
        errors.textMaxLength = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_INTEGER:
      if (values.intMinValue && values.intMaxValue
        && (toNumber(values.intMinValue) > toNumber(values.intMaxValue)
          || toNumber(values.intMaxValue) < toNumber(values.intMinValue))) {
        errors.intMaxValue = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_FLOAT:
      if (values.floatMinValue && values.floatMaxValue
        && (parseFloat(values.floatMinValue) > parseFloat(values.floatMaxValue)
        || parseFloat(values.floatMaxValue) < parseFloat(values.floatMinValue))) {
        errors.floatMaxValue = GlobalLocalizedString.common.errMsgMinMaxValue;
      } break;
    case ENUM_FIELD_PICKER:
      if (!values.pickerMode) {
        errors.pickerMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      } break;
    case ENUM_FIELD_OPTIONS:
      if (!values.optionMode) {
        errors.optionMode = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValues) {
        errors.optionValues = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      if (!values.optionValueSeparator) {
        errors.optionValueSeparator = GlobalLocalizedString.common.errMsgEmptyRequiredFields;
      }
      break;
    default: break;
  }

  return errors;
};
