import { addSms, resendingSms } from '../simple-action';
import { resendSms, downloadSms } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingSms(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiSms;

    await resendSms(tappedId, token);
    const result = await downloadSms(tappedId, token);

    dispatch(addSms(result));
  } finally {
    dispatch(resendingSms(false));
  }
};
