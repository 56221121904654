import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_POINTS, MENUID_POINT_POINT, PAGE_MODE_TABLE,
  RXFORM_POINT,
} from '../../constant';
import {
  debounceSearch, transformInitialValues, transformDropdownData, toMoment,
} from '../../helper';
import {
  clearPoints, clearUsers, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertErrorMessage, setPointSearchText, setPointSelectedPageSize, setPointSelectedOrderBy,
  setPointTappedId, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadPointAsync, downloadPointsAsync, setPointAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import PointPage from './point.presentation';

const getInitialValues = (state) => {
  const {
    points, uiFunctionalPage, uiPoint,
  } = state;
  const { data } = points;
  const { downloadingDeleting, tappedId } = uiPoint;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user?.fullName || '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    voidedOn: found.voidedOn ? toMoment(found.voidedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    expiredPointAlreadyProcessed: false,
    label: '',
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    isVoided: false,
    voidedOn: null,
    voidReferenceId: null,
    voidNotes: null,
    voidedBy: null,
    fullName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPoint.downloading,
  initialValues: getInitialValues(state),
  users: transformDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_POINT));
    dispatch(setPointSearchText(''));
    dispatch(setPointAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPoints());
    dispatch(setPointSelectedPageSize(20));
    dispatch(setPointSelectedOrderBy(INITIAL_ORDER_BY_POINTS));
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(setPointAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPoints());
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_POINT));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPointsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPointSelectedPageSize(pageSize));
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPointSelectedPageSize(pageSize));
    dispatch(clearPoints());
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setPointAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPoints());
    dispatch(setPointSelectedOrderBy(INITIAL_ORDER_BY_POINTS));
    dispatch(downloadPointsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPointSearchText(text));
      dispatch(clearPoints());
      await dispatch(downloadPointsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPointSelectedOrderBy(orderBy));
    dispatch(clearPoints());
    dispatch(downloadPointsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setPointTappedId(id));
    dispatch(downloadPointAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PointPage);
