import {
  DOWNLOADING_SUMMARY_TOTAL_USERS, DOWNLOADING_SUMMARY_ACTIVE_USERS,
  DOWNLOADING_SUMMARY_ACTIVE_DEVICES, DOWNLOADING_SUMMARY_OS_DISTRIBUTION,
  DOWNLOADING_SUMMARY_LOGIN_USING,
  DOWNLOADING_SUMMARY_ANDROID_VERSION_DISTRIBUTION,
  DOWNLOADING_SUMMARY_IOS_VERSION_DISTRIBUTION,
  DOWNLOADING_SUMMARY_BROWSER_DISTRIBUTION,
  DOWNLOADING_SUMMARY_TIMEZONE_DISTRIBUTION,
  DOWNLOADING_SUMMARY_APP_VERSION_DISTRIBUTION,
  DOWNLOADING_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS,
  DOWNLOADING_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS,
  DOWNLOADING_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS,
  DOWNLOADING_SUMMARY_ONLINE_USERS,
} from '../action';

const initialState = {
  downloadingTotalUsers: false,
  downloadingActiveUsers: false,
  downloadingActiveDevices: false,
  downloadingOsDistribution: false,
  downloadingLoginUsing: false,
  downloadingAndroidVersionDistribution: false,
  downloadingIosVersionDistribution: false,
  downloadingBrowserDistribution: false,
  downloadingTimezoneDistribution: false,
  downloadingAppVersionDistribution: false,
  downloadingDeviceManufacturerDistribution: false,
  downloadingAndroidDeviceModelDistribution: false,
  downloadingIosDeviceModelDistribution: false,
  downloadingOnlineUsers: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SUMMARY_TOTAL_USERS:
      return { ...state, downloadingTotalUsers: action.status };
    case DOWNLOADING_SUMMARY_ACTIVE_USERS:
      return { ...state, downloadingActiveUsers: action.status };
    case DOWNLOADING_SUMMARY_ACTIVE_DEVICES:
      return { ...state, downloadingActiveDevices: action.status };
    case DOWNLOADING_SUMMARY_OS_DISTRIBUTION:
      return { ...state, downloadingOsDistribution: action.status };
    case DOWNLOADING_SUMMARY_LOGIN_USING:
      return { ...state, downloadingLoginUsing: action.status };
    case DOWNLOADING_SUMMARY_ANDROID_VERSION_DISTRIBUTION:
      return { ...state, downloadingAndroidVersionDistribution: action.status };
    case DOWNLOADING_SUMMARY_IOS_VERSION_DISTRIBUTION:
      return { ...state, downloadingIosVersionDistribution: action.status };
    case DOWNLOADING_SUMMARY_BROWSER_DISTRIBUTION:
      return { ...state, downloadingBrowserDistribution: action.status };
    case DOWNLOADING_SUMMARY_TIMEZONE_DISTRIBUTION:
      return { ...state, downloadingBrowserDistribution: action.status };
    case DOWNLOADING_SUMMARY_APP_VERSION_DISTRIBUTION:
      return { ...state, downloadingAppVersionDistribution: action.status };
    case DOWNLOADING_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS:
      return { ...state, downloadingDeviceManufacturerDistribution: action.status };
    case DOWNLOADING_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS:
      return { ...state, downloadingAndroidDeviceModelDistribution: action.status };
    case DOWNLOADING_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS:
      return { ...state, downloadingIosDeviceModelDistribution: action.status };
    case DOWNLOADING_SUMMARY_ONLINE_USERS:
      return { ...state, downloadingOnlineUsers: action.status };
    default: return state;
  }
};
