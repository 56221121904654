import { ORDER_SORT_DESCENDING } from '../../constant';
import LocalizedString from './localization';

export * from '../../constant';

export const REST_URL_DOWNLOAD_TOTAL_CLOCK_IN_TODAY = '/attendance/api/v1/Summary/TotalClockInToday?timeZone={timeZone}';
export const REST_URL_DOWNLOAD_TOTAL_CLOCK_OUT_TODAY = '/attendance/api/v1/Summary/TotalClockOutToday?timeZone={timeZone}';
export const REST_URL_DOWNLOAD_WORK_LOCATION = '/attendance/api/v1/Summary/WorkLocation?startTime={startTime}&endTime={endTime}';
export const REST_URL_DOWNLOAD_TODAY_CLOCK_IN_STATUS = '/attendance/api/v1/Summary/ClockInStatus?startTime={startTime}&endTime={endTime}';
export const REST_URL_DOWNLOAD_TODAY_CLOCK_OUT_STATUS = '/attendance/api/v1/Summary/ClockOutStatus?startTime={startTime}&endTime={endTime}';
export const REST_URL_DOWNLOAD_TODAY_CLOCK_IN_LOCATION_STATUS = '/attendance/api/v1/Summary/ClockInLocationStatus?startTime={startTime}&endTime={endTime}';
export const REST_URL_DOWNLOAD_TODAY_CLOCK_OUT_LOCATION_STATUS = '/attendance/api/v1/Summary/ClockOutLocationStatus?startTime={startTime}&endTime={endTime}';
export const REST_URL_DOWNLOAD_CLOCK_IN_STATUS_TREND = '/attendance/api/v1/Summary/ClockIn';
export const REST_URL_DOWNLOAD_CLOCK_OUT_STATUS_TREND = '/attendance/api/v1/Summary/ClockOut';
export const REST_URL_DOWNLOAD_CLOCK_IN_LOCATION_TREND = '/attendance/api/v1/Summary/ClockInLocation';
export const REST_URL_DOWNLOAD_CLOCK_OUT_LOCATION_TREND = '/attendance/api/v1/Summary/ClockOutLocation';
export const REST_URL_HISTORIES = '/attendance/api/v1/History?searchStringIncludedFields=id|profile.id|profile.fullName|profile.employeeId|clockInLocationName|clockInNotes|clockOutLocationName|clockOutNotes&pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADDITIONAL_FIELDS = '/attendance/api/v1/Attendance/AdditionalField?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_ADDITIONAL_FIELD = '/attendance/api/v1/Attendance/AdditionalField';
export const REST_URL_VIEW_DELETE_ADDITIONAL_FIELD = '/attendance/api/v1/Attendance/AdditionalField/{id}';
export const REST_URL_ENABLE_DISABLE_ADDITIONAL_FIELD = '/attendance/api/v1/Attendance/AdditionalField/Status';

export const ROUTE_NAME_ATTENDANCE_MANAGEMENT = '/attendance-management';
export const ROUTE_NAME_ATTENDANCE_SUMMARY = '/attendance-summary';
export const ROUTE_NAME_HISTORY = '/history';
export const ROUTE_NAME_ADDITIONAL_FIELD = '/additional-field';

export const RXFORM_HISTORY = 'historyPage';
export const RXFORM_ADDITIONAL_FIELD = 'additionalFieldPage';
export const RXFORM_ATTENDANCE_SUMMARY = 'attendanceSummaryPage';

export const RXFIELD_HISTORY_WORKPLACE = 'workplace';
export const RXFIELD_HISTORY_START_WORKING_HOUR = 'startWorkingHour';
export const RXFIELD_HISTORY_START_WORKING_HOUR_TIMEZONE = 'startWorkingHourTimeZone';
export const RXFIELD_HISTORY_START_WORKING_HOUR_MARGIN_BEFORE_MIN = 'startWorkingHourMarginBeforeMinutes';
export const RXFIELD_HISTORY_START_WORKING_HOUR_MARGIN_AFTER_MIN = 'startWorkingHourMarginAfterMinutes';
export const RXFIELD_HISTORY_CLOCK_IN_TIME = 'clockInTime';
export const RXFIELD_HISTORY_CLOCK_IN_DRIFT_MIN = 'clockInDriftMinutes';
export const RXFIELD_HISTORY_CLOCK_IN_LATITUDE = 'clockInLatitude';
export const RXFIELD_HISTORY_CLOCK_IN_LONGITUDE = 'clockInLongitude';
export const RXFIELD_HISTORY_CLOCK_IN_USER_REPORTED_LATITUDE = 'clockInUserReportedLatitude';
export const RXFIELD_HISTORY_CLOCK_IN_USER_REPORTED_LONGITUDE = 'clockInUserReportedLongitude';
export const RXFIELD_HISTORY_CLOCK_IN_LOCATION_ID = 'clockInLocationId';
export const RXFIELD_HISTORY_CLOCK_IN_LOCATION_NAME = 'clockInLocationName';
export const RXFIELD_HISTORY_CLOCK_IN_BODY_TEMPARATURE = 'clockInBodyTemperature';
export const RXFIELD_HISTORY_CLOCK_IN_NOTES = 'clockInNotes';
export const RXFIELD_HISTORY_CLOCK_IN_STATUS = 'clockInStatus';
export const RXFIELD_HISTORY_CLOCK_IN_LOCATION_STATUS = 'clockInLocationStatus';
export const RXFIELD_HISTORY_END_WORKING_HOUR = 'endWorkingHour';
export const RXFIELD_HISTORY_END_WORKING_HOUR_TIMEZONE = 'endWorkingHourTimeZone';
export const RXFIELD_HISTORY_END_WORKING_HOUR_MARGIN_BEFORE_MIN = 'endWorkingHourMarginBeforeMinutes';
export const RXFIELD_HISTORY_END_WORKING_HOUR_MARGIN_AFTER_MIN = 'endWorkingHourMarginAfterMinutes';
export const RXFIELD_HISTORY_CLOCK_OUT_TIME = 'clockOutTime';
export const RXFIELD_HISTORY_CLOCK_OUT_DRIFT_MIN = 'clockOutDriftMinutes';
export const RXFIELD_HISTORY_CLOCK_OUT_LATITUDE = 'clockOutLatitude';
export const RXFIELD_HISTORY_CLOCK_OUT_LONGITUDE = 'clockOutLongitude';
export const RXFIELD_HISTORY_CLOCK_OUT_USER_REPORTED_LATITUDE = 'clockOutUserReportedLatitude';
export const RXFIELD_HISTORY_CLOCK_OUT_USER_REPORTED_LONGITUDE = 'clockOutUserReportedLongitude';
export const RXFIELD_HISTORY_CLOCK_OUT_LOCATION_ID = 'clockOutLocationId';
export const RXFIELD_HISTORY_CLOCK_OUT_LOCATION_NAME = 'clockOutLocationName';
export const RXFIELD_HISTORY_CLOCK_OUT_NOTES = 'clockOutNotes';
export const RXFIELD_HISTORY_CLOCK_OUT_BODY_TEMPARATURE = 'clockOutBySystem';
export const RXFIELD_HISTORY_CLOCK_OUT_STATUS = 'clockOutStatus';
export const RXFIELD_HISTORY_CLOCK_OUT_LOCATION_STATUS = 'clockOutLocationStatus';
export const RXFIELD_HISTORY_BRANCH = 'branch';
export const RXFIELD_HISTORY_PROFILE = 'profile';
export const RXFIELD_HISTORY_FULLNAME = 'fullName';
export const RXFIELD_HISTORY_EMPLOYEE_ID = 'employeeId';
export const RXFIELD_HISTORY_ACTIVITIES = 'activities';
export const RXFIELD_HISTORY_COMPANY = 'company';
export const RXFIELD_HISTORY_DEPARTMENT = 'department';
export const RXFIELD_HISTORY_DIVISION = 'division';
export const RXFIELD_HISTORY_EMPLOYEE = 'employee';
export const RXFIELD_HISTORY_WORKING_HOUR = 'workingHour';
export const RXFIELD_HISTORY_ATTENDANCE_DATE = 'attendanceDate';
export const RXFIELD_ADDITIONAL_FIELD_NAME = 'name';
export const RXFIELD_ADDITIONAL_FIELD_LABEL = 'label';
export const RXFIELD_ADDITIONAL_FIELD_PLACEHOLDER = 'placeHolder';
export const RXFIELD_ADDITIONAL_FIELD_VALIDATION_MESSAGE = 'validationMessage';
export const RXFIELD_ADDITIONAL_FIELD_DEFAULT_VALUE = 'defaultValue';
export const RXFIELD_ADDITIONAL_FIELD_ORDER = 'order';
export const RXFIELD_ADDITIONAL_FIELD_REQUIRED = 'required';
export const RXFIELD_ADDITIONAL_FIELD_VISIBLE = 'visible';
export const RXFIELD_ADDITIONAL_FIELD_READ_ONLY = 'readOnly';
export const RXFIELD_ADDITIONAL_FIELD_TEXT_MULTILINE = 'textMultiline';
export const RXFIELD_ADDITIONAL_FIELD_TEXT_KEYBOARD_TYPE = 'textKeyboardType';
export const RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE = 'fieldType';
export const RXFIELD_ADDITIONAL_FIELD_PICKER_MODE = 'pickerMode';
export const RXFIELD_ADDITIONAL_FIELD_OPTION_MODE = 'optionMode';
export const RXFIELD_ADDITIONAL_FIELD_OPTION_VALUES = 'optionValues';
export const RXFIELD_ADDITIONAL_FIELD_OPTION_VALUE_SEPARATOR = 'optionValueSeparator';
export const RXFIELD_ADDITIONAL_FIELD_SECTION_ID = 'sectionId';
export const RXFIELD_ADDITIONAL_FIELD_SECTION_LABEL = 'sectionLabel';
export const RXFIELD_ADDITIONAL_FIELD_APPLIED_ON = 'appliedOn';
export const RXFIELD_ADDITIONAL_FIELD_TEXT_MIN_LENGTH = 'textMinLength';
export const RXFIELD_ADDITIONAL_FIELD_TEXT_MAX_LENGTH = 'textMaxLength';
export const RXFIELD_ADDITIONAL_FIELD_TEXT_REGEX_VALIDATION = 'textRegexValidation';
export const RXFIELD_ADDITIONAL_FIELD_INT_MIN_VALUE = 'intMinValue';
export const RXFIELD_ADDITIONAL_FIELD_INT_MAX_VALUE = 'intMaxValue';
export const RXFIELD_ADDITIONAL_FIELD_FLOAT_MIN_VALUE = 'floatMinValue';
export const RXFIELD_ADDITIONAL_FIELD_FLOAT_MAX_VALUE = 'floatMaxValue';
export const RXFIELD_ADDITIONAL_FIELD_DATE_TIME_MIN_VALUE = 'dateTimeMinValue';
export const RXFIELD_ADDITIONAL_FIELD_DATE_TIME_MAX_VALUE = 'dateTimeMaxValue';
export const RXFIELD_ADDITIONAL_FIELD_PARENT = 'parent';
export const RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE = 'showIfParentValue';
export const RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR = 'showIfParentValueLogicalOperator';
export const RXFIELD_ADDITIONAL_FIELD_MUST_USE_CAMERA = 'mustUseCamera';
export const RXFIELD_HISTORY_JOB_TITLE = 'jobTitle';
export const RXFIELD_HISTORY_MANAGER_NAME = 'manager.fullName';
export const RXFIELD_HISTORY_BRANCH_NAME = 'branch.name';
export const RXFIELD_HISTORY_PROFILE_PICTURE = 'profilePicture';
export const RXFIELD_HISTORY_CURRENT_BRANCH_NAME = 'currentBranchName';
export const RXFIELD_ATTENDANCE_SUMMARY_PERIOD = 'period';


export const RXSTATE_HISTORIES = 'histories';
export const RXSTATE_HISTORY_PAGE = 'uiHistory';
export const RXSTATE_ADDITIONAL_FIELDS = 'additionalFields';
export const RXSTATE_ADDITIONAL_FIELD_PAGE = 'uiAdditionalField';

export const INITIAL_ORDER_BY_HISTORIES = `lastModifiedDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_ADDITIONAL_FIELDS = `order ${ORDER_SORT_DESCENDING}`;

export const MENUID_ATTENDANCE_MANAGEMENT = 'MENUID_ATTENDANCE_MANAGEMENT';
export const MENUID_ATTENDANCE_MANAGEMENT_SUMMARY = 'MENUID_ATTENDANCE_MANAGEMENT_SUMMARY';
export const MENUID_ATTENDANCE_MANAGEMENT_HISTORY = 'MENUID_ATTENDANCE_MANAGEMENT_HISTORY';
export const MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD = 'MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD';

export const CHART_BAR_COLOR_LIGHT_GREEN = '#9CCC65';
export const CHART_BAR_COLOR_GREEN = '#249C24';
export const CHART_BAR_COLOR_ORANGE = '#FF7143';
export const CHART_BAR_COLOR_BLUE = '#42A5F5';

export const CLOCK_IN_OUT_STATUS_TREND_DATE = 'date';
export const CLOCK_IN_OUT_STATUS_TREND_EARLY = 'Early';
export const CLOCK_IN_OUT_STATUS_TREND_ONTIME = 'OnTime';
export const CLOCK_IN_OUT_STATUS_TREND_LATE = 'Late';
export const CLOCK_IN_OUT_LOCATION_TREND_INSIDE_AREA = 'InsideArea';
export const CLOCK_IN_OUT_LOCATION_TREND_OUTSIDE_AREA = 'OutsideArea';

export const APPLIED_ON_CLOCK_IN = 'ClockIn';
export const APPLIED_ON_CLOCK_OUT = 'ClockOut';

export const CLOCK_IN_OUT_STATUS_TREND_BARS = [
  {
    name: CLOCK_IN_OUT_STATUS_TREND_EARLY,
    value: CLOCK_IN_OUT_STATUS_TREND_EARLY,
    argument: CLOCK_IN_OUT_STATUS_TREND_DATE,
    barColor: CHART_BAR_COLOR_LIGHT_GREEN,
  },
  {
    name: CLOCK_IN_OUT_STATUS_TREND_ONTIME,
    value: CLOCK_IN_OUT_STATUS_TREND_ONTIME,
    argument: CLOCK_IN_OUT_STATUS_TREND_DATE,
    barColor: CHART_BAR_COLOR_GREEN,
  },
  {
    name: CLOCK_IN_OUT_STATUS_TREND_LATE,
    value: CLOCK_IN_OUT_STATUS_TREND_LATE,
    argument: CLOCK_IN_OUT_STATUS_TREND_DATE,
    barColor: CHART_BAR_COLOR_ORANGE,
  },
];
export const CLOCK_IN_OUT_LOCATION_TREND_BARS = [
  {
    name: CLOCK_IN_OUT_LOCATION_TREND_INSIDE_AREA,
    value: CLOCK_IN_OUT_LOCATION_TREND_INSIDE_AREA,
    argument: CLOCK_IN_OUT_STATUS_TREND_DATE,
    barColor: CHART_BAR_COLOR_GREEN,
  },
  {
    name: CLOCK_IN_OUT_LOCATION_TREND_OUTSIDE_AREA,
    value: CLOCK_IN_OUT_LOCATION_TREND_OUTSIDE_AREA,
    argument: CLOCK_IN_OUT_STATUS_TREND_DATE,
    barColor: CHART_BAR_COLOR_ORANGE,
  },
];
export const CLOCK_IN_OUT_STATUS = [
  CLOCK_IN_OUT_STATUS_TREND_EARLY,
  CLOCK_IN_OUT_STATUS_TREND_ONTIME,
  CLOCK_IN_OUT_STATUS_TREND_LATE,
];
export const CLOCK_IN_OUT_LOCATION_STATUS = [
  CLOCK_IN_OUT_LOCATION_TREND_INSIDE_AREA,
  CLOCK_IN_OUT_LOCATION_TREND_OUTSIDE_AREA,
];

export const APPLIED_ON = [APPLIED_ON_CLOCK_IN, APPLIED_ON_CLOCK_OUT];

export const DATE_MONTH_FORMAT = 'DD MMM';

export const GET_PROFILE_PERMISSION = 'MDATA_GET_PROFILE';

export const FILTER_PEPRIOD = [
  LocalizedString.attendanceSummaryScreen.labelToday,
  LocalizedString.attendanceSummaryScreen.labelThisWeek,
  LocalizedString.attendanceSummaryScreen.labelThisMonth,
  LocalizedString.attendanceSummaryScreen.labelThisYear,
];
