import { isNaN } from 'lodash';
import LocalizedString from '../../localization';
import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_PICKUP_LOCATION_NAME, RXFIELD_PICKUP_LOCATION_DESCRIPTION,
  RXFIELD_PACKET_TYPE_NAME, RXFIELD_PACKET_TYPE_DESCRIPTION,
  RXFIELD_PACKET_SENDER, RXFIELD_PACKET_RECIPIENT, RXFORM_PICKUP_LOCATION,
  RXFIELD_PACKET_PACKET_TYPE,
  RXFIELD_PICKUP_LOCATION_KEEP_PACKET_HOURS, RXFIELD_PACKET_TYPE_KEEP_PACKET_HOURS,
} from './constant';

export const rxformValidatePickupLocation = (values) => {
  const requiredFields = [
    RXFIELD_PICKUP_LOCATION_NAME,
    RXFIELD_PICKUP_LOCATION_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  if (values[RXFIELD_PICKUP_LOCATION_KEEP_PACKET_HOURS]
    && isNaN(Number(values[RXFIELD_PICKUP_LOCATION_KEEP_PACKET_HOURS]))) {
    errors[RXFIELD_PICKUP_LOCATION_KEEP_PACKET_HOURS] = LocalizedString.common.errMsgMustaNumber;
  }
  return errors;
};

export const rxformValidatePacketType = (values) => {
  const requiredFields = [
    RXFIELD_PACKET_TYPE_NAME,
    RXFIELD_PACKET_TYPE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  if (values[RXFIELD_PACKET_TYPE_KEEP_PACKET_HOURS]
    && isNaN(Number(values[RXFIELD_PACKET_TYPE_KEEP_PACKET_HOURS]))) {
    errors[RXFIELD_PACKET_TYPE_KEEP_PACKET_HOURS] = LocalizedString.common.errMsgMustaNumber;
  }
  return errors;
};

export const rxformValidatePacket = (values) => {
  const requiredFields = [
    RXFIELD_PACKET_SENDER,
    RXFIELD_PACKET_RECIPIENT,
    RXFORM_PICKUP_LOCATION,
    RXFIELD_PACKET_PACKET_TYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
