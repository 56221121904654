
import downloadVoucherCategoriesAsync from './downloadVoucherCategoriesAsync';
import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteVoucherCategory, downloadVoucherCategory } from '../../../helper';
import {
  addVoucherCategory, clearVoucherCategories, downloadingDeletingVoucherCategory,
  setAlertErrorMessage, setFunctionalPageMode, setVoucherCategoryTappedId,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingVoucherCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoucherCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteVoucherCategory(tappedId, token);
      dispatch(setVoucherCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearVoucherCategories());
      dispatch(downloadVoucherCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const category = await downloadVoucherCategory(tappedId, token);
      dispatch(addVoucherCategory(category));
    }
  } finally {
    dispatch(downloadingDeletingVoucherCategory(false));
  }
};
