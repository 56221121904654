import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { FunctionalPage } from '../../component';
import {
  RXFORM_COVID_DATA, RXSTATE_COVID_DATAS,
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  COLOR_DISABLED_ROW, COLOR_BACKGROUND,
  RXSTATE_COVID_DATA_PAGE, STATUS_DISABLED, EMPLOYEE_STATUS,
  PICKER_MODE_DATE, DATE_FORMAT,
  FILTER_TYPE_DATE_RANGE, PICKER_DATE_FORMAT,
  RXFIELD_COVID_DATA_DATE,
  RXFIELD_COVID_DATA_EMPLOYEE_STATUS,
  RXFIELD_COVID_DATA_HOSPITALIZED,
  RXFIELD_COVID_DATA_SELF_ISOLATION,
  RXFIELD_COVID_DATA_DEATH,
  RXFIELD_COVID_DATA_RECOVERED,
  RXFIELD_COVID_DATA_VACCINATED,
  RXFIELD_COVID_DATA_RT_ANTIBODY,
  RXFIELD_COVID_DATA_RT_ANTIGEN,
  RXFIELD_COVID_DATA_GENOSE,
  RXFIELD_COVID_DATA_SEROLOGI,
  RXFIELD_COVID_DATA_PCR,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormDateTimePickerField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';
import { rxFormValidateCovidData } from '../../validation';

const renderDialogContent = (
  initialValues, addingEditing, downloadingDeleting, pageMode,
  onEmployeeStatusSelected,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_DATE}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.covidDataPage.placeholderDate}
          label={LocalizedString.covidDataPage.placeholderDate}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          pickerMode={PICKER_MODE_DATE}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_HOSPITALIZED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderHospitalized}
          label={LocalizedString.covidDataPage.placeholderHospitalized}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_SELF_ISOLATION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderSelfIsolation}
          label={LocalizedString.covidDataPage.placeholderSelfIsolation}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_DEATH}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderDeath}
          label={LocalizedString.covidDataPage.placeholderDeath}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_RECOVERED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderRecovered}
          label={LocalizedString.covidDataPage.placeholderRecovered}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_VACCINATED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderVaccinated}
          label={LocalizedString.covidDataPage.placeholderVaccinated}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_EMPLOYEE_STATUS}
          component={renderReduxFormSimpleDropdownField}
          placeholder={LocalizedString.covidDataPage.placeholderEmployeeStatus}
          label={LocalizedString.covidDataPage.placeholderEmployeeStatus}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={EMPLOYEE_STATUS}
          value={initialValues.employeeStatus}
          onOptionSelected={onEmployeeStatusSelected}
          required
          onBlur={(e) => e.preventDefault()}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_RT_ANTIBODY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderRtAntibody}
          label={LocalizedString.covidDataPage.placeholderRtAntibody}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_RT_ANTIGEN}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderRtAntigen}
          label={LocalizedString.covidDataPage.placeholderRtAntigen}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_GENOSE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderGenose}
          label={LocalizedString.covidDataPage.placeholderGenose}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_SEROLOGI}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderSerologi}
          label={LocalizedString.covidDataPage.placeholderSerologi}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COVID_DATA_PCR}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.covidDataPage.placeholderPcr}
          label={LocalizedString.covidDataPage.placeholderPcr}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
    </Grid>
  </Grid>
);

const CovidDataPage = ({
  downloading,
  handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  addingEditing, downloadingDeleting, pageMode,
  onSavePressed, onEditPressed, onCancelPressed,
  onSubmitPressed, onDeletePressed, onConfirmDeletePressed, onCreatePressed,
  onEmployeeStatusSelected, onDownloadPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_COVID_DATAS}
    uiPage={RXSTATE_COVID_DATA_PAGE}
    filterColumns={[
      {
        title: LocalizedString.covidDataPage.placeholderDate,
        field: RXFIELD_COVID_DATA_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE,
        format: PICKER_DATE_FORMAT,
      },
      {
        title: LocalizedString.covidDataPage.placeholderEmployeeStatus,
        field: RXFIELD_COVID_DATA_EMPLOYEE_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: EMPLOYEE_STATUS,
      },
    ]}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    tableColumns={[
      {
        title: LocalizedString.covidDataPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.covidDataPage.labelDate,
        field: 'date',
        sorting: !downloading,
        render: ({ date }) => (date
          ? moment(date).format(DATE_FORMAT) : null),
      },
      { title: LocalizedString.covidDataPage.labelEmployeeStatus, field: 'employeeStatus', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelHospitalized, field: 'hospitalized', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelSelfIsolation, field: 'selfIsolation', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelDeath, field: 'death', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelRecovered, field: 'recovered', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelVaccinated, field: 'vaccinated', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelRtAntibody, field: 'rtAntibody', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelRtAntigen, field: 'rtAntigen', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelGenose, field: 'genose', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelSerologi, field: 'serologi', sorting: !downloading },
      { title: LocalizedString.covidDataPage.labelPcr, field: 'pcr', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.covidDataPage.title}
    createNewButtonCaption={LocalizedString.covidDataPage.buttonCaptionCreateCovidData}
    onCreatePressed={onCreatePressed}
    onSubmitPressed={onSubmitPressed}
    editButtonCaption={LocalizedString.covidDataPage.buttonCaptionEditCovidData}
    onEditPressed={onEditPressed}
    onSavePressed={onSavePressed}
    deleteButtonCaption={LocalizedString.covidDataPage.buttonCaptionDeleteCovidData}
    onDeletePressed={onDeletePressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onDownloadPressed={onDownloadPressed}
    enableSave
    useFullWidth
    rowStyle={(rowData) => ({
      backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
    })}
    onCancelPressed={onCancelPressed}
  >
    {renderDialogContent(
      initialValues, addingEditing, downloadingDeleting, pageMode,
      onEmployeeStatusSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_COVID_DATA,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxFormValidateCovidData,
})(CovidDataPage);

CovidDataPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  pageMode: PropTypes.string.isRequired,

  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEmployeeStatusSelected: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
