import {
  downloadingSummaryAndroidVersionDistribution,
  setSummaryAndroidVersionDistribution,
} from '../simple-action';
import { downloadSummaryAndroidVersionDistribution } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingSummaryAndroidVersionDistribution(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadSummaryAndroidVersionDistribution(token);

    dispatch(setSummaryAndroidVersionDistribution(result));
  } finally {
    dispatch(downloadingSummaryAndroidVersionDistribution(false));
  }
};
