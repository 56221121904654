import {
  addPacket, clearPackets, downloadingDeletingPacket, setAlertErrorMessage, setFunctionalPageMode,
  setPacketTappedId,
} from '../simple-action';
import { downloadPacket, deletePacket } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadPacketsAsync from './downloadPacketsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPacket(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPacket;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deletePacket(tappedId, token);
      dispatch(setPacketTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearPackets());
      dispatch(downloadPacketsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const packet = await downloadPacket(tappedId, token);
      dispatch(addPacket(packet));
    }
  } finally {
    dispatch(downloadingDeletingPacket(false));
  }
};
