import {
  DOWNLOADING_ANNOTATION_DESIGN_LISTS,
  DOWNLOADING_DELETING_ANNOTATION_DESIGN_LIST, SET_ANNOTATION_DESIGN_LIST_SELECTED_PAGE_SIZE,
  SET_ANNOTATION_DESIGN_LIST_TAPPED_ID, SET_ANNOTATION_DESIGN_LIST_SEARCH_TEXT,
  SET_ANNOTATION_DESIGN_LIST_SELECTED_ORDER_BY, SAVING_ANNOTATION_DESIGN_LISTS,
  SET_ANNOTATION_DESIGN_LIST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';
import { INITIAL_ORDER_BY_ANNOTATION_DESIGN_LISTS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_ANNOTATION_DESIGN_LISTS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ANNOTATION_DESIGN_LISTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_ANNOTATION_DESIGN_LIST:
      return { ...state, downloadingDeleting: action.status };
    case SET_ANNOTATION_DESIGN_LIST_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ANNOTATION_DESIGN_LIST_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ANNOTATION_DESIGN_LIST_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ANNOTATION_DESIGN_LIST_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SAVING_ANNOTATION_DESIGN_LISTS:
      return { ...state, saving: action.status };
    case SET_ANNOTATION_DESIGN_LIST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
