import { downloadingBranches, setBranches } from '../simple-action';
import { downloadBranches, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, branches, uiBranch } = getState();
  const { token } = authentication;
  const { meta } = branches;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiBranch;

  try {
    dispatch(downloadingBranches(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadBranches(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setBranches(list));
    }
  } finally {
    dispatch(downloadingBranches(false));
  }
};
