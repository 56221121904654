import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_SCREEN = '/screen/api/v1/Screen?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';

export const REST_URL_SHORTLINKS = '/shortlink/api/v1/ShortLink?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_SHORTLINK = '/shortlink/api/v1/ShortLink';
export const REST_URL_VIEW_DELETE_SHORTLINK = '/shortlink/api/v1/ShortLink/{id}';

export const ROUTE_NAME_SHORTLINK_MENU = '/shortlink';
export const ROUTE_NAME_SHORTLINK_SCREEN = '/shortlink-screen';

export const RXFORM_SHORTLINK = 'ShortlinkPage';

export const RXFIELD_SHORTLINK_REFERENCE_ID = 'referenceId';
export const RXFIELD_SHORTLINK_URL = 'url';
export const RXFIELD_SHORTLINK_SHORT_URL = 'shortUrl';
export const RXFIELD_SHORTLINK_DESCRIPTION = 'description';

export const RXSTATE_SHORTLINKS = 'shortlinks';
export const RXSTATE_SHORTLINK_PAGE = 'uiShortlink';

export const INITIAL_ORDER_BY_SHORTLINKS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_SHORTLINK = 'MENUID_SHORTLINK';
export const MENUID_SHORTLINK_SHORTLINK_SCREEN = 'MENUID_SHORTLINK_SHORTLINK_SCREEN';

export const UPLOAD_IMAGE_METHOD_SELECT_FILE = 'file';
export const UPLOAD_IMAGE_METHOD_TAKE_PICTURE = 'camera';

export const DEFAULT_DATE_TIME = '0001-01-01T00:00:00';
