
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    covidDataPage: {
      title: 'Covid Data',

      labelNo: 'No',
      labelDate: 'Date',
      labelEmployeeStatus: 'Employee Status',
      labelHospitalized: 'Hospitalized',
      labelSelfIsolation: 'Self Isolation',
      labelDeath: 'Death',
      labelRecovered: 'Recovered',
      labelVaccinated: 'Vaccinated',
      labelRtAntibody: 'RT Antibody',
      labelRtAntigen: 'RT Antigen',
      labelGenose: 'Genose',
      labelSerologi: 'Serologi',
      labelPcr: 'PCR',

      placeholderDate: 'Date',
      placeholderEmployeeStatus: 'Employee Status',
      placeholderHospitalized: 'Hospitalized',
      placeholderSelfIsolation: 'Self Isolation',
      placeholderDeath: 'Death',
      placeholderRecovered: 'Recovered',
      placeholderVaccinated: 'Vaccinated',
      placeholderRtAntibody: 'RT Antibody',
      placeholderRtAntigen: 'RT Antigen',
      placeholderGenose: 'Genose',
      placeholderSerologi: 'Serologi',
      placeholderPcr: 'PCR',

      buttonCaptionCreateCovidData: 'Create Covid Data',
      buttonCaptionEditCovidData: 'Edit Covid Data',
      buttonCaptionDeleteCovidData: 'Delete Covid Data',
    },
    covidDashboardPage: {
      title: 'Dashboard',

      labelCovidCase: 'Covid Case',
      labelVaccination: 'Vaccination',
      labelScreening: 'Screening',
      labelCount: 'Count',
      labelSummary: 'Summary',

      buttonCaptionData: 'Data',
      buttonCaptionVisualization: 'Visualization',

      placeholderTeam: 'Team',
      placeholderPeriod: 'Period',
    },
  },
  id: {
    covidDataPage: {
      title: 'Data Covid',

      labelNo: 'No',
      labelDate: 'Tanggal',
      labelEmployeeStatus: 'Status Karyawan',
      labelHospitalized: 'Rawat Inap',
      labelSelfIsolation: 'Isolasi Mandiri',
      labelDeath: 'Kematian',
      labelRecovered: 'Pulih',
      labelVaccinated: 'Tervaksin',
      labelRtAntibody: 'RT Antibody',
      labelRtAntigen: 'RT Antigen',
      labelGenose: 'Genose',
      labelSerologi: 'Serologi',
      labelPcr: 'PCR',


      placeholderDate: 'Tanggal',
      placeholderEmployeeStatus: 'Status Karyawan',
      placeholderHospitalized: 'Rawat Inap',
      placeholderSelfIsolation: 'Isolasi Mandiri',
      placeholderDeath: 'Kematian',
      placeholderRecovered: 'Pulih',
      placeholderVaccinated: 'Tervaksin',
      placeholderRtAntibody: 'RT Antibody',
      placeholderRtAntigen: 'RT Antigen',
      placeholderGenose: 'Genose',
      placeholderSerologi: 'Serologi',
      placeholderPcr: 'PCR',

      buttonCaptionCreateCovidData: 'Buat Data Covid',
      buttonCaptionEditCovidData: 'Ubah Data Covid',
      buttonCaptionDeleteCovidData: 'Hapus Data Covid',
    },
    covidDashboardPage: {
      title: 'Dasbor',

      labelCovidCase: 'Kasus Covid',
      labelVaccination: 'Vaksinasi',
      labelScreening: 'Penyaringan',
      labelCount: 'Jumlah',
      labelSummary: 'Rangkuman',

      buttonCaptionData: 'Data',
      buttonCaptionVisualization: 'Visualisasi',

      placeholderTeam: 'Tim',
      placeholderPeriod: 'Periode',
    },
  },
});

export default LocalizedString;
