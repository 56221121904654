import PropTypes from 'prop-types';

export * from '../../type';

export const MostViewedNewsShape = PropTypes.shape({
  newsId: PropTypes.string,
  newsTitle: PropTypes.string,
  count: PropTypes.number,
});

export const NewsTrendShape = PropTypes.shape({
  name: PropTypes.string,
  count: PropTypes.number,
});
