import {
  addForumCategory, clearForumCategories, downloadingDeletingForumCategory, setAlertErrorMessage,
  setFunctionalPageMode, setForumCategoryTappedId,
} from '../simple-action';
import { downloadForumCategory, deleteForumCategory } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadForumCategoriesAsync from './downloadForumCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingForumCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiForumCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteForumCategory(tappedId, token);
      dispatch(setForumCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearForumCategories());
      dispatch(downloadForumCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const forumCategory = await downloadForumCategory(tappedId, token);
      dispatch(addForumCategory(forumCategory));
    }
  } finally {
    dispatch(downloadingDeletingForumCategory(false));
  }
};
