import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');


export const DOWNLOADING_SHORTLINKS = 'DOWNLOADING_SHORTLINKS';
export const downloadingShortlinks = makeActionCreator(DOWNLOADING_SHORTLINKS, 'status');

export const SET_SHORTLINKS = 'SET_SHORTLINKS';
export const setShortlinks = makeActionCreator(SET_SHORTLINKS, 'data');

export const ADD_SHORTLINK = 'ADD_SHORTLINK';
export const addShortlink = makeActionCreator(ADD_SHORTLINK, 'data');

export const CLEAR_SHORTLINKS = 'CLEAR_SHORTLINKS';
export const clearShortlinks = makeActionCreator(CLEAR_SHORTLINKS);

export const ADDING_EDITING_SHORTLINK = 'ADDING_EDITING_SHORTLINK';
export const addingEditingShortlink = makeActionCreator(ADDING_EDITING_SHORTLINK, 'status');

export const DOWNLOADING_DELETING_SHORTLINK = 'DOWNLOADING_DELETING_SHORTLINK';
export const downloadingDeletingShortlink = makeActionCreator(DOWNLOADING_DELETING_SHORTLINK, 'status');


export const SET_SHORTLINK_SELECTED_PAGE_SIZE = 'SET_SHORTLINK_SELECTED_PAGE_SIZE';
export const setShortlinkSelectedPageSize = makeActionCreator(SET_SHORTLINK_SELECTED_PAGE_SIZE, 'size');

export const SET_SHORTLINK_SELECTED_ORDER_BY = 'SET_SHORTLINK_SELECTED_ORDER_BY';
export const setShortlinkSelectedOrderBy = makeActionCreator(SET_SHORTLINK_SELECTED_ORDER_BY, 'order');

export const SET_SHORTLINK_TAPPED_ID = 'SET_SHORTLINK_TAPPED_ID';
export const setShortlinkTappedId = makeActionCreator(SET_SHORTLINK_TAPPED_ID, 'id');

export const SET_SHORTLINK_SEARCH_TEXT = 'SET_SHORTLINK_SEARCH_TEXT';
export const setShortlinkSearchText = makeActionCreator(SET_SHORTLINK_SEARCH_TEXT, 'text');
