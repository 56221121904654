import {
  addFAQCategory, clearFAQCategories, downloadingDeletingFAQCategory, setAlertErrorMessage,
  setFunctionalPageMode, setFAQCategoryTappedId,
} from '../simple-action';
import { downloadFAQCategory, deleteFAQCategory } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadFAQCategoriesAsync from './downloadFAQCategoriesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingFAQCategory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiFAQCategory;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteFAQCategory(tappedId, token);
      dispatch(setFAQCategoryTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearFAQCategories());
      dispatch(downloadFAQCategoriesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const faqCategory = await downloadFAQCategory(tappedId, token);
      dispatch(addFAQCategory(faqCategory));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingFAQCategory(false));
  }
};
