import {
  DOWNLOADING_INFO_MAPPINGS, ADDING_EDITING_INFO_MAPPING, DOWNLOADING_DELETING_INFO_MAPPING,
  SET_INFO_MAPPING_SELECTED_PAGE_SIZE, SET_INFO_MAPPING_TAPPED_ID, SET_INFO_MAPPING_SEARCH_TEXT,
  SET_INFO_MAPPING_SELECTED_ORDER_BY, SET_INFO_MAPPING_SELECTED_MAPPING_OPERATOR,
  SET_INFO_MAPPING_SELECTED_TYPE,
} from '../action';
import { INITIAL_ORDER_BY_INFO_MAPPINGS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_INFO_MAPPINGS,
  selectedMappingOperator: '',
  selectedType: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_INFO_MAPPINGS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_INFO_MAPPING:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_INFO_MAPPING:
      return { ...state, downloadingDeleting: action.status };
    case SET_INFO_MAPPING_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_INFO_MAPPING_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_INFO_MAPPING_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_INFO_MAPPING_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_INFO_MAPPING_SELECTED_MAPPING_OPERATOR:
      return { ...state, selectedMappingOperator: action.option };
    case SET_INFO_MAPPING_SELECTED_TYPE:
      return { ...state, selectedType: action.option };
    default: return state;
  }
};
