import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_NEWS_CATEGORY_NAME, RXFORM_NEWS_CATEGORY, RXSTATE_NEWS_CATEGORIES,
  RXSTATE_NEWS_CATEGORY_PAGE, RXFIELD_NEWS_CATEGORY_DESCRIPTION, RXFIELD_NEWS_CATEGORY_IMAGE,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateNewsCategory } from '../../validation';
import { FormInitialValueShape } from '../../type';

const renderDialogContent = (addingEditing, pageMode, initialImage, onImageSelected) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_NEWS_CATEGORY_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.newsCategoryScreen.placeholderNewsCategoryName}
          label={LocalizedString.newsCategoryScreen.placeholderNewsCategoryName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_NEWS_CATEGORY_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.newsCategoryScreen.placeholderNewsCategoryImage}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          onImageSelected={onImageSelected}
          onBlur={(e) => e.preventDefault()}
          defaultValue={initialImage}
          useFullWidthImage
          useAvatarWithoutCropper
          fullImageWidth="100%"
          useCropper={false}
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_NEWS_CATEGORY_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.newsCategoryScreen.placeholderDescription}
          label={LocalizedString.newsCategoryScreen.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>

);

const NewsCategoryPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed, initialValues, onImageSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_NEWS_CATEGORIES}
    uiPage={RXSTATE_NEWS_CATEGORY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.newsCategoryScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.newsCategoryScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.newsCategoryScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.newsCategoryScreen.buttonCaptionCreateNewNewsCategory}
    deleteButtonCaption={LocalizedString.newsCategoryScreen.buttonCaptionDeleteNewsCategory}
    title={LocalizedString.newsCategoryScreen.title}
    editButtonCaption={LocalizedString.newsCategoryScreen.buttonCaptionEditNewsCategory}
    useFullWidth
  >
    {renderDialogContent(addingEditing, pageMode, initialValues.image, onImageSelected)}
  </FunctionalPage>
);


export default reduxForm({
  form: RXFORM_NEWS_CATEGORY,
  validate: rxformValidateNewsCategory,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(NewsCategoryPage);

NewsCategoryPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
};
