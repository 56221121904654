import { reset } from 'redux-form';
import {
  addingEditingService, clearServices, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addService, editService } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_SERVICE } from '../../../constant';
import downloadServicesAsync from './downloadServicesAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingService(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiService;

    if (tappedId) {
      await editService(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addService(name, description, token);
    }

    dispatch(reset(RXFORM_SERVICE));
    dispatch(clearServices());
    dispatch(downloadServicesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingService(false));
  }
};
