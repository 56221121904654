import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    carServiceMenu: {
      title: 'Car Service',
    },
    serviceRequestPage: {
      title: 'Service Request',

      labelNo: 'No',
      labelUser: 'User',
      labelRequestedDate: 'Requested Date',
      labelDealer: 'Dealer',
      labelRepairType: 'Repair Type',
      labelCreatedDate: 'Created Date',

      placeholderUserId: 'User ID',
      placeholderUserName: 'User Name',
      placeholderDealerId: 'Dealer ID',
      placeholderDealerName: 'Dealer Name',
      placeholderRequestedDate: 'Requested Date',
      placeholderRepairType: 'Repair Type',
      placeholderCreatedDate: 'Created Date',

      buttonCaptionDeleteServiceRequest: 'Delete Service Request',
    },
  },
  id: {
    carServiceMenu: {
      title: 'Servis Mobil',
    },
    serviceRequestPage: {
      title: 'Permintaan Servis',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelRequestedDate: 'Tanggal Permintaan',
      labelDealer: 'Penjual',
      labelRepairType: 'Tipe Reparasi',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderDealerId: 'ID Penjual',
      placeholderDealerName: 'Nama Penjual',
      placeholderRequestedDate: 'Tanggal Permintaan',
      placeholderRepairType: 'Tipe Reparasi',
      placeholderCreatedDate: 'Tanggal Pembuatan',

      buttonCaptionDeleteServiceRequest: 'Hapus Permintaan Servis',
    },
  },
});

export default LocalizedString;
