import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  RXFIELD_CONFIG_GROUP_NAME, RXFORM_CONFIG_GROUP, RXSTATE_CONFIG_GROUPS,
  RXSTATE_CONFIG_GROUP_PAGE, RXFIELD_CONFIG_GROUP_DESCRIPTION,
  RXFIELD_CONFIG_GROUP_ORDER, RXFIELD_CONFIG_GROUP_TAG,
} from '../../constant';
import { PAGE_MODE_VIEW } from '../../../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateConfigGroup } from '../../validation';

const renderDialogContent = (addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_CONFIG_GROUP_ORDER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.configGroupScreen.placeholderOrder}
          label={LocalizedString.configGroupScreen.placeholderOrder}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          type="number"
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CONFIG_GROUP_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.configGroupScreen.placeholderConfigGroupName}
          label={LocalizedString.configGroupScreen.placeholderConfigGroupName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_CONFIG_GROUP_TAG}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.configGroupScreen.placeholderTag}
          label={LocalizedString.configGroupScreen.placeholderTag}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CONFIG_GROUP_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.configGroupScreen.placeholderDescription}
          label={LocalizedString.configGroupScreen.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>
);

const ConfigGroupPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_CONFIG_GROUPS}
    uiPage={RXSTATE_CONFIG_GROUP_PAGE}
    tableColumns={[
      {
        title: LocalizedString.configGroupScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.configGroupScreen.labelConfigGroupName, field: 'name', sorting: !downloading },
      { title: LocalizedString.configGroupScreen.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.configGroupScreen.labelOrder, field: 'order', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.configGroupScreen.buttonCaptionCreateNewConfigGroup}
    deleteButtonCaption={LocalizedString.configGroupScreen.buttonCaptionDeleteConfigGroup}
    title={LocalizedString.configGroupScreen.title}
    editButtonCaption={LocalizedString.configGroupScreen.buttonCaptionEditConfigGroup}
    useFullWidth
    createPermissionName="CONFIG_CREATE_CONFIG_GROUP"
    deletePermissionName="CONFIG_DELETE_CONFIG_GROUP"
    editPermissionName="CONFIG_EDIT_CONFIG_GROUP"
  >
    {renderDialogContent(addingEditing, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CONFIG_GROUP,
  validate: rxformValidateConfigGroup,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ConfigGroupPage);

ConfigGroupPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
};
