import { reset } from 'redux-form';
import {
  addingEditingPermission, clearPermissions, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addPermission, editPermission } from '../../../helper';
import { RXFORM_PERMISSION, PAGE_MODE_TABLE } from '../../../constant';
import downloadPermissionsAsync from './downloadPermissionsAsync';

export default (name, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPermission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPermission;

    if (tappedId) {
      await editPermission(tappedId, name, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addPermission(name, description, token);
    }

    dispatch(reset(RXFORM_PERMISSION));
    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingPermission(false));
  }
};
