import { downloadVoucherPurchaseRequests, transformSearchText } from '../../../helper';
import { downloadingVoucherPurchaseRequests, setVoucherPurchaseRequests } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, voucherPurchaseRequests, uiVoucherPurchaseRequest,
    uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = voucherPurchaseRequests;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiVoucherPurchaseRequest;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(downloadingVoucherPurchaseRequests(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadVoucherPurchaseRequests(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setVoucherPurchaseRequests(list));
    }
  } finally {
    dispatch(downloadingVoucherPurchaseRequests(false));
  }
};
