import { INITIAL_ORDER_BY_WFA_REQUESTS } from '../../constant';
import {
  DOWNLOADING_WFA_REQUESTS, SET_WFA_REQUEST_SEARCH_TEXT,
  SET_WFA_REQUEST_SELECTED_ORDER_BY, SET_WFA_REQUEST_SELECTED_PAGE_SIZE,
  SET_WFA_REQUEST_TAPPED_ID, SET_WFA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  DOWNLOADING_WFA_REQUEST,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_WFA_REQUESTS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WFA_REQUESTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_WFA_REQUEST:
      return { ...state, downloadingDeleting: action.status };
    case SET_WFA_REQUEST_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WFA_REQUEST_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WFA_REQUEST_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WFA_REQUEST_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WFA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default:
      return state;
  }
};
