import { ORDER_SORT_DESCENDING, ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_NEWS = '/news/api/v1/News?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_NEWS = '/news/api/v1/News';
export const REST_URL_VIEW_DELETE_NEWS = '/news/api/v1/News/{id}';
export const REST_URL_NEWS_CATEGORIES = '/news/api/v1/NewsCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_NEWS_CATEGORY = '/news/api/v1/NewsCategory';
export const REST_URL_VIEW_DELETE_NEWS_CATEGORY = '/news/api/v1/NewsCategory/{id}';
export const REST_URL_SEND_NEWS_TO_ME = '/news/api/v1/SendToMe/{id}';
export const REST_URL_MOST_VIEWED_NEWS = '/news/api/v1/MostViewedNews?count=10&startTime={startTime}&endTime={endTime}';
export const REST_URL_DOWNLOAD_NEWS_TREND = '/news/api/v1/Summary/NewsViewerTrend?newsId={id}&startTime={startTime}&endTime={endTime}';

export const ROUTE_NAME_NEWS = '/news';
export const ROUTE_NAME_NEWS_MANAGEMENT = '/news-management';
export const ROUTE_NAME_NEWS_CATEGORY = '/news-category';
export const ROUTE_NAME_NEWS_DASHBOARD = '/news-dashboard';

export const RXFORM_NEWS = 'newsPage';
export const RXFORM_NEWS_CATEGORY = 'newsCategoryPage';
export const RXFORM_NEWS_DASHBOARD = 'newsDashboardPage';

export const RXFIELD_NEWS_TITLE = 'title';
export const RXFIELD_NEWS_PUBLISHED_DATE = 'publishedDate';
export const RXFIELD_NEWS_AUTHOR = 'author';
export const RXFIELD_NEWS_CONTENT = 'content';
export const RXFIELD_NEWS_VISIBILITY_STATUS = 'visibilityStatus';
export const RXFIELD_NEWS_MEDIA = 'media';
export const RXFIELD_NEWS_SHORT_URL = 'shortUrl';
export const RXFIELD_NEWS_SOURCE_NAME = 'sourceName';
export const RXFIELD_NEWS_SOURCE_URL = 'sourceUrl';
export const RXFIELD_NEWS_REACTION_ID = 'reactionId';
export const RXFIELD_NEWS_DISCUSSION_ID = 'discusId';
export const RXFIELD_NEWS_CATEGORY = 'category';
export const RXFIELD_NEWS_SHORT_DESCRIPTION = 'shortDescription';
export const RXFIELD_NEWS_VIEW_COUNT = 'viewCount';
export const RXFIELD_NEWS_IS_HEADLINE = 'isHeadline';
export const RXFIELD_NEWS_DEEP_LINK = 'deepLink';
export const RXFIELD_NEWS_TREND_FILTER = 'filter';
export const RXFIELD_NEWS_CATEGORY_NAME = 'name';
export const RXFIELD_NEWS_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_NEWS_CATEGORY_IMAGE = 'image';
export const RXFIELD_NEWS_TAGS = 'tags';
export const RXFIELD_NEWS_DASHBOARD_FILTER = 'filter';

export const RXSTATE_NEWS = 'news';
export const RXSTATE_NEWS_PAGE = 'uiNews';
export const RXSTATE_NEWS_CATEGORIES = 'newsCategories';
export const RXSTATE_NEWS_CATEGORY_PAGE = 'uiNewsCategory';

export const INITIAL_ORDER_BY_NEWS = `publishedDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_NEWS_CATEGORIES = `name ${ORDER_SORT_ASCENDING}`;

export const MENUID_NEWS_MANAGEMENT = 'MENUID_NEWS_MANAGEMENT';
export const MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY = 'MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY';
export const MENUID_NEWS_MANAGEMENT_NEWS = 'MENUID_NEWS_MANAGEMENT_NEWS';
export const MENUID_NEWS_MANAGEMENT_NEWS_DASHBOARD = 'MENUID_NEWS_MANAGEMENT_NEWS_DASHBOARD';

export const MOST_VIEWED_NEWS_FILTERS = ['Today', 'Yesterday', 'This Week', 'This Month', 'This Year'];

export const DAY_OF_THE_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
