import { reset } from 'redux-form';
import { RXFORM_INFO_MAPPING, PAGE_MODE_TABLE } from '../../../constant';
import { addInfoMapping, editInfoMapping } from '../../../helper';
import {
  addingEditingInfoMapping, clearInfoMappings, setAlertErrorMessage, setFunctionalPageMode,
  setInfoMappingSelectedMappingOperator, setInfoMappingSelectedType,
} from '../simple-action';
import downloadInfoMappingsAsync from './downloadInfoMappingsAsync';

export default (order, phrase, newName) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingInfoMapping(true));

    const { token } = getState().authentication;
    const { tappedId, selectedMappingOperator, selectedType } = getState().uiInfoMapping;
    const { data } = getState().infoMappings;
    const found = data[tappedId];
    const mappingOperator = selectedMappingOperator || found.mappingOperator;
    const type = selectedType || found.type;

    if (tappedId) {
      await editInfoMapping(tappedId, order, phrase, mappingOperator, newName, type, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addInfoMapping(order, phrase, mappingOperator, newName, type, token);
    }

    dispatch(reset(RXFORM_INFO_MAPPING));
    dispatch(setInfoMappingSelectedMappingOperator(''));
    dispatch(setInfoMappingSelectedType(''));
    dispatch(clearInfoMappings());
    dispatch(downloadInfoMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingInfoMapping(false));
  }
};
