import PropTypes from 'prop-types';

export * from '../../type';

export const TrendShape = PropTypes.shape({
  date: PropTypes.string,
  totalCase: PropTypes.number,
  vaccinated: PropTypes.number,
  totalScreening: PropTypes.number,
});
