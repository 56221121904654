import { reset } from 'redux-form';
import moment from 'moment';
import _ from 'lodash';
import {
  addingEditingQuizQuestion, clearQuizQuestions, setAlertErrorMessage, setFunctionalPageMode,
  setQuizQuestionSelectedFieldType,
  setQuizQuestionSelectedLogicalOperator, setQuizQuestionSelectedMustUseCamera,
  setQuizQuestionSelectedOptionMode, setQuizQuestionSelectedOptionValueSeparator,
  setQuizQuestionSelectedPickerMode,
  setQuizQuestionSelectedQuiz, setQuizQuestionSelectedTextKeyboardType,
  setQuizQuestionSelectedTextMultiline,
  setQuizQuestionSelectedCorrectAnswerLogicalOperator,
} from '../simple-action';
import {
  addQuizQuestion, editQuizQuestion, localDateToUtc, toMoment, toNumber,
} from '../../../helper';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_FLOAT, ENUM_FIELD_INTEGER, ENUM_FIELD_OPTIONS,
  ENUM_FIELD_PHOTO, ENUM_FIELD_PICKER, ENUM_FIELD_TEXT, ENUM_FIELD_TIME, PAGE_MODE_TABLE,
  REVERSED_ISO_DATE_FORMAT, RXFORM_QUIZ_QUESTION,
} from '../../../constant';
import downloadQuizQuestionsAsync from './downloadQuizQuestionsAsync';

export default (values) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingQuizQuestion(true));

    const { token } = getState().authentication;
    const {
      selectedFieldType, selectedLogicalOperator, selectedMustUseCamera,
      selectedOptionMode, selectedOptionValueSeparator, selectedParent, selectedPickerMode,
      selectedQuiz, selectedTextKeyboardType, selectedTextMultiline, tappedId,
      selectedCorrectAnswerLogicalOperator,
    } = getState().uiQuizQuestion;
    const { data } = getState().quizQuestions;
    const {
      name, label, validationMessage, defaultValue, order,
      optionValues, sectionId, sectionLabel, textMinLength, textMaxLength, textRegexValidation,
      intMinValue, intMaxValue, floatMinValue, floatMaxValue, dateTimeMinValue, dateTimeMaxValue,
      showIfParentValue, image, clearImage,
      correctAnswer, score,
    } = values;

    const found = data[tappedId];
    const isTextMultiline = found && typeof found.textMultiline === 'object' ? found.textMultiline.value : false;
    const isMustUseCamera = found && typeof found.mustUseCamera === 'object' ? found.mustUseCamera.value : false;
    let additionalBody = {};
    switch (selectedFieldType) {
      case ENUM_FIELD_DATE:
        additionalBody = {
          dateTimeMinValue: dateTimeMinValue ? moment.utc(dateTimeMinValue)
            .format(REVERSED_ISO_DATE_FORMAT) : null,
          dateTimeMaxValue: dateTimeMaxValue ? moment.utc(dateTimeMaxValue)
            .format(REVERSED_ISO_DATE_FORMAT) : null,
        }; break;
      case ENUM_FIELD_DATE_TIME:
        additionalBody = {
          dateTimeMinValue: dateTimeMinValue ? localDateToUtc(dateTimeMinValue) : null,
          dateTimeMaxValue: dateTimeMaxValue ? localDateToUtc(dateTimeMaxValue) : null,
        }; break;
      case ENUM_FIELD_TIME:
        additionalBody = {
          dateTimeMinValue: dateTimeMinValue
            ? `${toMoment().format(REVERSED_ISO_DATE_FORMAT)}T${dateTimeMinValue}` : null,
          dateTimeMaxValue: dateTimeMaxValue
            ? `${toMoment().format(REVERSED_ISO_DATE_FORMAT)}T${dateTimeMaxValue}` : null,
        }; break;
      case ENUM_FIELD_TEXT:
        additionalBody = {
          textMultiline: typeof selectedTextMultiline === 'object' ? selectedTextMultiline.value : isTextMultiline,
          textKeyboardType: selectedTextKeyboardType || found.textKeyboardType,
          textMinLength: textMinLength ? toNumber(textMinLength) : null,
          textMaxLength: textMaxLength ? toNumber(textMaxLength) : null,
          textRegexValidation,
        }; break;
      case ENUM_FIELD_INTEGER:
        additionalBody = {
          intMinValue: intMinValue ? toNumber(intMinValue) : null,
          intMaxValue: intMaxValue ? toNumber(intMaxValue) : null,
        }; break;
      case ENUM_FIELD_FLOAT:
        additionalBody = {
          floatMinValue: floatMinValue ? parseFloat(floatMinValue) : null,
          floatMaxValue: floatMaxValue ? parseFloat(floatMaxValue) : null,
        }; break;
      case ENUM_FIELD_PICKER:
        additionalBody = {
          pickerMode: selectedPickerMode || found.pickerMode,
          OptionValues: optionValues,
        }; break;
      case ENUM_FIELD_OPTIONS:
        additionalBody = {
          optionMode: selectedOptionMode || found.optionMode,
          OptionValues: optionValues,
          optionValueSeparator: selectedOptionValueSeparator || found.optionValueSeparator,
          correctAnswerLogicalOperator: selectedCorrectAnswerLogicalOperator
          || found.correctAnswerLogicalOperator,
        }; break;
      case ENUM_FIELD_PHOTO:
        additionalBody = {
          mustUseCamera: typeof selectedMustUseCamera === 'object' ? selectedMustUseCamera.value : isMustUseCamera,
        }; break;
      default: additionalBody = {};
    }

    const body = {
      id: found ? found.id : undefined,
      name,
      label,
      placeHolder: '',
      validationMessage,
      defaultValue,
      order: toNumber(order),
      appliedOn: 'ClockIn',
      required: false,
      visible: true,
      readOnly: false,
      fieldType: selectedFieldType || found.fieldType,
      sectionId,
      sectionLabel,
      ParentFieldId: typeof selectedParent === 'object' ? selectedParent.value : undefined,
      ShowIfParentValue: typeof selectedParent === 'object' && selectedParent.value ? showIfParentValue : undefined,
      showIfParentValueLogicalOperator: typeof selectedParent === 'object' && selectedParent.value ? (selectedLogicalOperator || found.showIfParentValueLogicalOperator) : undefined,
      quizId: selectedQuiz.value,
      image: clearImage || !image || image.includes('https') ? null : image,
      clearImage,
      correctAnswer,
      score: score ? toNumber(score) : 0,
      ...additionalBody,
    };
    if (tappedId) {
      await editQuizQuestion(body, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addQuizQuestion(_.omit(body, 'clearImage'), token);
    }

    dispatch(reset(RXFORM_QUIZ_QUESTION));
    dispatch(clearQuizQuestions());
    dispatch(downloadQuizQuestionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setQuizQuestionSelectedQuiz(''));
    dispatch(setQuizQuestionSelectedFieldType(''));
    dispatch(setQuizQuestionSelectedLogicalOperator(''));
    dispatch(setQuizQuestionSelectedMustUseCamera(''));
    dispatch(setQuizQuestionSelectedOptionMode(''));
    dispatch(setQuizQuestionSelectedOptionValueSeparator(''));
    dispatch(setQuizQuestionSelectedPickerMode(''));
    dispatch(setQuizQuestionSelectedTextKeyboardType(''));
    dispatch(setQuizQuestionSelectedTextMultiline(''));
    dispatch(setQuizQuestionSelectedCorrectAnswerLogicalOperator(''));
  } finally {
    dispatch(addingEditingQuizQuestion(false));
  }
};
