import { reset } from 'redux-form';
import {
  addingEditingShortlink, clearShortlinks, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addShortlink, editShortlink } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_SHORTLINK } from '../../../constant';
import downloadShortlinksAsync from './downloadShortlinksAsync';

export default (url, description) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingShortlink(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiShortlink;

    if (tappedId) {
      await editShortlink(tappedId, url, description, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addShortlink(url, description, token);
    }

    dispatch(reset(RXFORM_SHORTLINK));
    dispatch(clearShortlinks());
    dispatch(downloadShortlinksAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingShortlink(false));
  }
};
