import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import { homepage } from '../package.json';
import * as serviceWorker from './serviceWorker';
import {
  OAUTH_PROVIDER_PARAM,
  OAUTH_MICROSOFT_REDIRECT_URI_PATH, OAUTH_PROVIDER_MICROSOFT,
} from './constant';

const queryParams = new URLSearchParams(window.location.search);
const oAuthCode = queryParams.get('code');

if (oAuthCode) {
  if (window.location.pathname === OAUTH_MICROSOFT_REDIRECT_URI_PATH) {
    const redirectUrl = `${window.location.protocol}//${window.location.host}${homepage}/#/?${OAUTH_PROVIDER_PARAM}=${OAUTH_PROVIDER_MICROSOFT}&${queryParams.toString()}`;
    window.open(redirectUrl, '_self');
  }
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(`${homepage}/firebase-messaging-sw.js`);
}


ReactDOM.render(
  <HashRouter>
    <Route path="/" component={App} />
  </HashRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
