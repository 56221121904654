import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearQuizCategories, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setQuizCategorySearchText, setQuizCategorySelectedPageSize, setQuizCategorySelectedOrderBy,
  setQuizCategoryTappedId,
  addEditQuizCategoryAsync, downloadDeleteQuizCategoryAsync, downloadQuizCategoriesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_QUIZ_CATEGORIES, MENUID_QUIZ_QUIZ_CATEGORY, PAGE_MODE_TABLE,
  RXFORM_QUIZ_CATEGORY,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../../../localization';
import QuizCategoryPage from './quiz-category.presentation';

const getInitialValues = (state) => {
  const { quizCategories, uiFunctionalPage, uiQuizCategory } = state;
  const { data } = quizCategories;
  const { downloadingDeleting, tappedId } = uiQuizCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : { url: '', description: '' };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiQuizCategory.addingEditing,
  downloading: state.uiQuizCategory.downloading,
  downloadingDeleting: state.uiQuizCategory.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_QUIZ_QUIZ_CATEGORY));
    dispatch(setQuizCategorySearchText(''));
    dispatch(clearQuizCategories());
    dispatch(setQuizCategorySelectedPageSize(20));
    dispatch(setQuizCategorySelectedOrderBy(INITIAL_ORDER_BY_QUIZ_CATEGORIES));
    dispatch(downloadQuizCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadQuizCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setQuizCategorySelectedPageSize(pageSize));
    dispatch(downloadQuizCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_QUIZ_CATEGORY));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteQuizCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setQuizCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setQuizCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setQuizCategoryTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setQuizCategorySelectedPageSize(pageSize));
    dispatch(clearQuizCategories());
    dispatch(downloadQuizCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditQuizCategoryAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setQuizCategorySearchText(text));
      dispatch(clearQuizCategories());
      await dispatch(downloadQuizCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setQuizCategorySelectedOrderBy(orderBy));
    dispatch(clearQuizCategories());
    dispatch(downloadQuizCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditQuizCategoryAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setQuizCategoryTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizCategoryPage);
