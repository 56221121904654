import { ANALYTICS_DATA_LIMIT } from '../../../constant';
import { getAnalyticsDevicesByUsersData } from '../../../helper';
import { downloadingAnalyticsDevicesByUsers, setAnalyticsDevicesByUsers } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsDevicesByUsers(true));

    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { userByDeviceModelDateRange } = uiAnalytics;
    const { token } = authentication;

    const requestBody = {
      dateRanges: [{
        startDate: userByDeviceModelDateRange.from,
        endDate: userByDeviceModelDateRange.to,
      }],
      dimensions: [{ name: 'mobileDeviceModel' }],
      dimensionFilter: {
        filter: {
          fieldName: 'platform',
          inListFilter: {
            values: ['android', 'ios'],
          },
        },
      },
      metrics: [{ name: 'totalUsers' }],
      metricAggregations: ['TOTAL'],
      limit: ANALYTICS_DATA_LIMIT,
    };

    const response = await getAnalyticsDevicesByUsersData(
      myConfigItems?.core?.googleAnalyticsPropertyId, requestBody, token,
    );

    dispatch(setAnalyticsDevicesByUsers(response));
  } finally {
    dispatch(downloadingAnalyticsDevicesByUsers(false));
  }
};
