import {
  addDocumentGeneratorDocument, downloadingDeletingDocumentGeneratorDocument,
} from '../simple-action';
import { downloadDocumentGeneratorDocument } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingDocumentGeneratorDocument(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiDocument;

    const document = await downloadDocumentGeneratorDocument(tappedId, token);

    dispatch(addDocumentGeneratorDocument(document));
  } finally {
    dispatch(downloadingDeletingDocumentGeneratorDocument(false));
  }
};
