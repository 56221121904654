import {
  ADDING_POLYGON, SET_MAP_DRAWER_MARKER, SET_MAP_DRAWER_POLYGON,
  SET_MAP_DRAWER_RENDER_USER_SHAPE_AFTER_COMPLETE,
} from '../action';

const initialState = {
  addingPolygon: false,
  marker: null,
  polygon: null,
  renderUserShapeAfterCompleted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_POLYGON:
      return { ...state, addingPolygon: action.status };
    case SET_MAP_DRAWER_MARKER:
      return { ...state, marker: action.data };
    case SET_MAP_DRAWER_POLYGON:
      return { ...state, polygon: action.data };
    case SET_MAP_DRAWER_RENDER_USER_SHAPE_AFTER_COMPLETE:
      return { ...state, renderUserShapeAfterCompleted: action.status };
    default: return state;
  }
};
