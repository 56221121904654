import {
  addMyJob, clearMyJobs, downloadingDeletingMyJob, setAlertErrorMessage, setFunctionalPageMode,
  setMyJobTappedId,
} from '../simple-action';
import { downloadMyJob, deleteMyJob } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadMyJobsAsync from './downloadMyJobsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingMyJob(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiMyJob;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteMyJob(tappedId, token);
      dispatch(setMyJobTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearMyJobs());
      dispatch(downloadMyJobsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const myJob = await downloadMyJob(tappedId, token);
      dispatch(addMyJob(myJob));
    }
  } finally {
    dispatch(downloadingDeletingMyJob(false));
  }
};
