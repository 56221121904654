import {
  downloadingAttendanceSummaryTodayClockOutStatus, setAttendanceSummaryTodayClockOutStatus,
} from '../simple-action';
import { downloadAttendanceSummaryTodayClockOutStatus, getPeriodDate } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTodayClockOutStatus(true));

    const { authentication } = getState();
    const { token } = authentication;
    const { selectedPeriod } = getState().uiAttendanceSummary;


    const data = await downloadAttendanceSummaryTodayClockOutStatus(
      token,
      getPeriodDate(selectedPeriod).startTime,
      getPeriodDate(selectedPeriod).endTime,
    );

    dispatch(setAttendanceSummaryTodayClockOutStatus(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTodayClockOutStatus(false));
  }
};
