import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_LOCATIONS = '/agenda/api/v1/Location?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_LOCATION = '/agenda/api/v1/Location';
export const REST_URL_VIEW_DELETE_LOCATION = '/agenda/api/v1/Location/{id}';
export const REST_URL_EVENTS = '/agenda/api/v1/Event?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_EVENT = '/agenda/api/v1/Event';
export const REST_URL_VIEW_DELETE_EVENT = '/agenda/api/v1/Event/{id}';

export const ROUTE_NAME_AGENDA = '/agenda';
export const ROUTE_NAME_AGENDA_LOCATION = '/location';
export const ROUTE_NAME_AGENDA_EVENT = '/event';

export const RXFORM_LOCATIONS = 'locationsPage';
export const RXFORM_EVENTS = 'eventsPage';

export const RXSTATE_LOCATIONS = 'locations';
export const RXSTATE_LOCATION_PAGE = 'uiLocation';
export const RXSTATE_EVENTS = 'events';
export const RXSTATE_EVENT_PAGE = 'uiEvent';

export const RXFIELD_LOCATIONS_NAME = 'name';
export const RXFIELD_LOCATIONS_DESCRIPTION = 'description';
export const RXFIELD_EVENT_NAME = 'name';
export const RXFIELD_EVENT_REFERENCE_NUMBER = 'referenceNumber';
export const RXFIELD_EVENT_START_TIME = 'startTime';
export const RXFIELD_EVENT_END_TIME = 'endTime';
export const RXFIELD_EVENT_TIME_ZONE = 'timeZone';
export const RXFIELD_EVENT_LOCATION = 'location';
export const RXFIELD_EVENT_PARTICIPANTS = 'participants';
export const RXFIELD_EVENT_DESCRIPTION = 'description';

export const INITIAL_ORDER_BY_LOCATIONS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_EVENTS = `startTime ${ORDER_SORT_ASCENDING}`;

export const MENUID_AGENDA = 'MENUID_AGENDA';
export const MENUID_AGENDA_LOCATION = 'MENUID_AGENDA_LOCATION';
export const MENUID_AGENDA_EVENT = 'MENUID_AGENDA_EVENT';
